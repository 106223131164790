import uniqBy from "lodash/uniqBy";
import { fetchWebApi } from "../webapis/apiResource";
import { ENV_CONFIG } from "../config/env";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import { removeSpaces } from "../util";
import {
  showLoadingIndicator,
  hideLoadingIndicator
} from "../actions/common.action";
import {
  UPDATE_STORE_LIST,
  SET_SELECTED_STORE_STATE,
  CHANGE_SELECTED_STORE_STATE,
  UPDATE_COUNTRY_ID,
  CHANGE_BREAD_CRUMB,
  SET_BRANDS_DATA,
  SELECT_STORE_ID,
  RESET_STORE_ID,
  COUNTRY_LIST
} from "../constants/actions";

export const updateStores = result => ({ type: UPDATE_STORE_LIST, result });

export const setSelectedStoreState = (name, id) => ({
  type: SET_SELECTED_STORE_STATE,
  name,
  id
});

export const setBrandsData = brands => ({ type: SET_BRANDS_DATA, brands });

export const changeSelectedStore = (storeId, countryId) => ({
  type: CHANGE_SELECTED_STORE_STATE,
  storeId,
  countryId
});

export const updateCountryId = value => ({ type: UPDATE_COUNTRY_ID, value });

export const changeBreadCrumb = (selectedStoreName, selectedCountryName) => ({
  type: CHANGE_BREAD_CRUMB,
  selectedStoreName,
  selectedCountryName
});

export const setCountryList = countryList => ({
  type: COUNTRY_LIST,
  countryList
});

export const fetchStoreList = () => {
  const apiURL = `${ENV_CONFIG.commonBaseURL}${ENV_CONFIG.versionInfo}/settings`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    // dispatch(getBrandsData());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.status === 200) {
          let storeListObj = {},
            newStoreList = [];
          response.data && dispatch(setCountryList(response.data));
          response.data &&
            Array.isArray(response.data) &&
            response.data.map(country => {
              country.stores &&
                Array.isArray(country.stores) &&
                country.stores.map(store => {
                  if (
                    Object.prototype.hasOwnProperty.call(
                      storeListObj,
                      store.mid
                    )
                  ) {
                    storeListObj[store.mid]["countries"].push({
                      countryId: country.countryId,
                      name: country.countryName,
                      countryCode: country.countryCode,
                      countrySHORT: country.countrySHORT
                    });
                  } else {
                    storeListObj[store.mid] = {
                      storeId: store.mid,
                      storeName: store.name,
                      countries: [
                        {
                          countryId: country.countryId,
                          name: country.countryName,
                          countryCode: country.countryCode,
                          countrySHORT: country.countrySHORT
                        }
                      ]
                    };
                  }
                });
            });
          storeListObj &&
            Object.keys(storeListObj).length > 0 &&
            Object.keys(storeListObj).map(storeId => {
              newStoreList.push(storeListObj[storeId]);
            });
          dispatch(updateStores(newStoreList));
          const _storeId = getState().store.storeId,
            _countryId = getState().store.countryId;

          let _store =
            (newStoreList &&
              newStoreList.filter(
                item =>
                  parseInt(item.storeId) ===
                  parseInt(_storeId || newStoreList[0].storeId)
              )) ||
            [];
          let _country =
            (_store &&
              _store[0] &&
              _store[0].countries &&
              _store[0].countries.filter(item =>
                item.name.toLowerCase().includes("united arab")
              )) ||
            [];

          dispatch(
            changeSelectedStore(
              +_storeId ||
                +(_store && _store[0] && _store[0].storeId) ||
                +(_store && newStoreList[0] && newStoreList[0].storeId),
              +_countryId ||
                +(_country && _country[0] && _country[0].countryId) ||
                +(
                  newStoreList &&
                  newStoreList[0] &&
                  newStoreList[0].countries &&
                  newStoreList[0].countries[0] &&
                  newStoreList[0].countries[0].countryId
                )
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

//Note:Not used anymore
export const getBrandsData = () => {
  const apiURL = `${ENV_CONFIG.productBaseURL}${ENV_CONFIG.versionInfo}/products/brands`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          let _flattenBrandList = [];
          response.data.brands &&
            response.data.brands.map(category => {
              category &&
                category.brands &&
                Object.keys(category.brands).length > 0 &&
                Object.keys(category.brands).map(alphabet => {
                  Array.isArray(category.brands[alphabet]) &&
                    category.brands[alphabet].map(brand => {
                      _flattenBrandList.push({
                        brandId: removeSpaces(brand.name),
                        brandName: brand.name
                      });
                    });
                });
            });
          const list = uniqBy(_flattenBrandList, "brandId");
          dispatch(setBrandsData(list));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error);
        return error.response;
      });
  };
};

export const selectStoreId = storeId => ({
  type: SELECT_STORE_ID,
  storeId
});

export const resetStoreId = () => (dispatch, getState) => {
  const {
    store: { storeId }
  } = getState();
  dispatch({
    type: RESET_STORE_ID,
    storeId
  });
};
