import React, { Fragment, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";
import cn from "classnames";
import {
    LANGUAGE,
    columnTypeList,
    columnTypeValue,
    ADD_MENU_MODAL,
    EDIT_MENU_MODAL,
    customModuleRows,
    deviceTypes,
    indicatorOptions,
    indicatorOptionsMobile,
    controllerIndicatorOptions,
    autoScrollOptions,
    autoScrollSpeedOptions,
    autoPlayOptions,
} from "../../../constants";
import { columnWidthList } from "../../../util/footer"
import {
    TextField,
    IconButton, Button as MUIButton,
    BasicPopover, Tooltip, Box,
    MenuItem, Typography, Accordion, AccordionSummary, AccordionDetails,
    FormControlLabel, Checkbox, Card, CardContent, Divider
} from '../../../materialUi/components';
import { DeleteIcon, DragHandleIcon, EditIcon, SaveAsIcon, CancelIcon } from '../../../materialUi/icons';
import '../../../materialUi/fonts';
import { formatUrlString } from "../../../util";
import ProductCarouselForm from "../../../containers/productCarouselAccordion/productCarouselFormMUI";
import CustomSortableTree from "../../../containers/customSortableTreeMUI"
import SpacingControlsMUI from "../SpacingControlsMUI";
import { getDefaultMenuContentStructure } from "../../../util";
import { ColorPicker } from "../../ColorPicker";
import { ItemModal } from "../ItemModal";
import AddMenuDialog from "./AddMenuDialog";
import HTMLRowType from "./HTMLRowType";
import SingleRowType from "./SingleRowType";
import SliderRowType from "./SliderRowType";
import CustomRowType from "./CustomRowType";
import {
    addBannerRowItem, deleteRowImage, clearBannerRowImageData, addRowTypeCustomField,
    updateRowTypeCustomModuleField, updateRowTypeCustomData, removeRowTypeCustomField,
    productCarouselDataChange
} from "../../../actions/dynamicBanner.action";
import { snackbar } from "../../../actions/common.action";

const ColumnRowType = ({
    handleAddRowTypeColumn,
    handleRemoveRowTypeColumnField,
    onConfirmAddMenuModal,
    onConfirmEditMenuModal,
    onColumnDragEnd,
    handleUpdateRowTypeColumnData,
    onConfirmDragAndDropMenu,
    onConfirmDeleteMenuModal,
    handleUpdateRowTypeColumnHTMLData,
    columnData,
    deviceType,
    bannerType,
    schedulerIndex,
    pageType,
    updateCatalogPageType,
    updateCatalogPageLocation,
    flashSalePageOptions,
    defaultPath,
    rowIndex,
}) => {
    const dispatch = useDispatch();
    const [columnBannerRowIndex, setColumnBannerRowIndex] = useState(-1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showModal, setShowModal] = useState({
        [ADD_MENU_MODAL]: false,
        [EDIT_MENU_MODAL]: false,

    });
    const [expanded, setExpanded] = useState(0);
    const [nodeId, setNodeId] = useState(null);
    const [isEditColumnTitle, setIsEditColumnTitle] = useState(false);
    const [editModeColumnTitle, setEditModeColumnTitle] = useState("");
    const [addedItemIndex, setAddedItemIndex] = useState();
    const [displayColorPickerById, setDisplayColorPickerById] = useState({});
    const draggableClass = useMemo(() => `draggable-${Math.random()}`, []);

    const handleModal = (modalName, value) => {
        setShowModal({ [modalName]: value });
    }

    const handleAccordionChange = (index) => (event, newExpanded) => {
        setExpanded(newExpanded ? index : false);
        setColumnBannerRowIndex(index);
    };

    const getUpdatedColumnWidthList = (columnData, index) => {
        let maxAvailableWidth = 100;

        let currentOccupiedWidth = columnData.reduce((prev, curr) => {
            return prev + (curr.width || 0)
        }, 0);
        currentOccupiedWidth -= columnData[index].width || 0;
        maxAvailableWidth = maxAvailableWidth - currentOccupiedWidth;
        return columnWidthList(0, 100, 5).map((option) => {
            return <MenuItem key={option.value} value={option.value} disabled={option.value > maxAvailableWidth}>
                {option.label}
            </MenuItem>
        })

    }

    const getNodeData = (arr, nodeId) => {
        let node = null;
        for (const item of arr) {
            if (item.id === nodeId) {
                return cloneDeep(item);
            }
            if (item.children?.length) {
                node = getNodeData(item.children, nodeId);
                if (node) return node;
            }

        }
        return node;
    }

    const addRowItem = (itemType, columnIndex) =>
        dispatch(
            addBannerRowItem({
                path: [...defaultPath, "columns", columnIndex, "imageList"],
                itemType,
                pageType,
                updateCatalogPageType,
                updateCatalogPageLocation
            })
        );

    const handleDeleteRowImage = (imageIndex, columnIndex) =>
        dispatch(
            deleteRowImage({
                path: [...defaultPath, "columns", columnIndex, "imageList"],
                imageIndex,
                pageType,
                updateCatalogPageType,
                updateCatalogPageLocation
            })
        );


    const handleAddRowTypeCustomField = (columnIndex) => {
        dispatch(
            addRowTypeCustomField({
                path: [...defaultPath, "columns", columnIndex],
                pageType,
                updateCatalogPageType,
                updateCatalogPageLocation
            })
        );
    };

    const handleUpdateSelectedModuleTypeCustom = (value, columnIndex) => {
        const customRows = cloneDeep(customModuleRows[value]);
        dispatch(updateRowTypeCustomModuleField({
            path: [...defaultPath, "columns", columnIndex],
            value: customRows,
            pageType,
            updateCatalogPageType,
            updateCatalogPageLocation
        }))
    }

    const handleUpdateRowTypeCustomData = (index, name, value, columnIndex) => {
        dispatch(
            updateRowTypeCustomData({
                path: [...defaultPath, "columns", columnIndex],
                index,
                name,
                value,
                pageType,
                updateCatalogPageType,
                updateCatalogPageLocation
            })
        );
    };

    const updateProductCarouselData = (name, value, language, columnIndex) => {
        dispatch(
            productCarouselDataChange({
                path: [...defaultPath, "columns", columnIndex],
                value,
                language,
                name,
                pageType,
                updateCatalogPageType,
                updateCatalogPageLocation
            })
        );
    };

    const handleUrlChange = (name, value, columnIndex) => {
        updateProductCarouselData(name, formatUrlString(value), LANGUAGE.ENGLISH, columnIndex);
        updateProductCarouselData(name, formatUrlString(value), LANGUAGE.ARABIC, columnIndex);
    };

    const handleRemoveRowTypeCustomField = (index, columnIndex) => () => {
        dispatch(
            removeRowTypeCustomField({
                path: [...defaultPath, "columns", columnIndex],
                index,
                pageType,
                updateCatalogPageType,
                updateCatalogPageLocation
            })
        );
    };
    const clearRowImageData = (columnIndex) => {
        dispatch(
            clearBannerRowImageData({
                path: [...defaultPath, "columns", columnIndex],
                pageType,
                updateCatalogPageType,
                updateCatalogPageLocation
            })
        );
    };
    const handleAddRowItem = (itemType, columnIndex) => {
        addRowItem(itemType, columnIndex);
        setAddedItemIndex(columnData[columnIndex].imageList?.length);
    };

    const validateColumnTitle = (title) => {
        if (!title) {
            dispatch(snackbar({ open: true, severity: "error", text: "Column Title cannot be blank" }));
            return false;
        }
        if (title != title.trim()) {
            dispatch(snackbar({ open: true, severity: "error", text: "Please remove leading and trailing spaces from Column Title" }));
            return false;
        }
        return true;
    }

    const handlePickerClickById = (id) =>
        setDisplayColorPickerById(prevState => ({ ...prevState, [id]: !prevState[id] }));

    const getIndicatorOptions = () => {
        const options = deviceType === deviceTypes.desktop
            ? indicatorOptions
            : indicatorOptionsMobile;

        return options.map((option) =>
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        );
    }

    const getcolumnTypeOptions = () => {
        const options = cloneDeep(columnTypeList);

        options.forEach((option, index) => {
            if (option.value === columnTypeValue.CUSTOM) {
                option.disabled = true;
            }
        });

        return options.map((option) =>
            <MenuItem key={option.value} value={option.value} disabled={option.disabled || false}>
                {option.label}
            </MenuItem>
        );
    }


    return (
        <Fragment>
            <Box sx={{ "display": "flex", "marginBottom": "20px" }}>
                <MUIButton
                    onClick={handleAddRowTypeColumn}
                    variant="contained"
                    sx={{ "marginLeft": "auto" }}
                >
                    Add Column
                </MUIButton>
            </Box>
            <Box sx={{ border: "1px solid", padding: "10px", borderColor: "#e5e5e5" }}>
                {Boolean(anchorEl) &&
                    <BasicPopover open={Boolean(anchorEl)} id={columnBannerRowIndex} anchorEl={anchorEl}
                        handleCancel={() => {
                            setAnchorEl(null);
                            setColumnBannerRowIndex(-1)
                        }}
                        handleSave={() => {
                            handleRemoveRowTypeColumnField(columnBannerRowIndex);
                            setAnchorEl(null);
                            setColumnBannerRowIndex(-1)
                        }}
                        text={"Are you sure you want to delete the Column?"}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                    />}
                {showModal[ADD_MENU_MODAL] &&
                    <AddMenuDialog
                        handleSave={(data) => {
                            onConfirmAddMenuModal(data, columnBannerRowIndex)
                            handleModal(ADD_MENU_MODAL, false)
                            setColumnBannerRowIndex(-1)
                        }}
                        data={getDefaultMenuContentStructure()}
                        handleClose={() => {
                            handleModal(ADD_MENU_MODAL, false)
                            setColumnBannerRowIndex(-1)
                        }}
                        open={true}
                        title={"ADD Menu Item"}
                        dialogContext={"Please add Menu Item Details."}
                        deviceType={deviceType}
                    />
                }
                {showModal[EDIT_MENU_MODAL] &&
                    <AddMenuDialog
                        handleSave={(data) => {
                            onConfirmEditMenuModal(data, columnBannerRowIndex)
                            handleModal(EDIT_MENU_MODAL, false)
                            setColumnBannerRowIndex(-1)
                            setNodeId(null)
                        }}
                        data={getNodeData(columnData[columnBannerRowIndex].menu, nodeId)}
                        handleClose={() => {
                            handleModal(EDIT_MENU_MODAL, false)
                            setColumnBannerRowIndex(-1)
                            setNodeId(null)
                        }}
                        open={true}
                        title={"Edit Menu Item"}
                        dialogContext={"Please edit Menu Item Details."}
                        deviceType={deviceType}
                    />
                }
                <DragDropContext onDragEnd={onColumnDragEnd}>
                    <Droppable droppableId="dynamic-banner-columns">
                        {provided => (
                            <Box ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {!!columnData.length && columnData.map((item, index) => {
                                    const itemList = get(item, "imageList", []);
                                    const path = [...defaultPath, "columns", index];
                                    const backgroundColor = get(item, ["styles", "backgroundColor"], "#ffffff");
                                    return (
                                        <Draggable draggableId={item.id || item.name} index={index} key={item.id || item.name}>
                                            {provided => (
                                                <Box
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <Accordion
                                                        expanded={expanded === index} onChange={handleAccordionChange(index)}
                                                        key={index} sx={{ marginBottom: "10px" }}
                                                    >
                                                        <AccordionSummary aria-controls="panel1d-content" id={index}>
                                                            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                    {(!isEditColumnTitle || (isEditColumnTitle && columnBannerRowIndex !== index))
                                                                        && <Typography sx={{ "marginLeft": "4px" }}>{item.name}</Typography>}
                                                                    {isEditColumnTitle &&
                                                                        columnBannerRowIndex === index &&
                                                                        <TextField
                                                                            autoFocus
                                                                            id="name"
                                                                            type="text"
                                                                            value={editModeColumnTitle}
                                                                            onChange={event =>
                                                                                setEditModeColumnTitle(event.target.value || "")
                                                                            }
                                                                            size="small"
                                                                            variant="outlined"
                                                                        />
                                                                    }
                                                                    {(!isEditColumnTitle || (isEditColumnTitle && columnBannerRowIndex !== index))
                                                                        && <Tooltip title="Edit" placement="top" arrow>
                                                                            <IconButton onClick={() => {
                                                                                setEditModeColumnTitle(item.name);
                                                                                setIsEditColumnTitle(true);
                                                                                setColumnBannerRowIndex(index);
                                                                            }}
                                                                                sx={{ marginLeft: "auto" }}
                                                                            >
                                                                                <EditIcon fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>}
                                                                    {isEditColumnTitle &&
                                                                        columnBannerRowIndex === index &&
                                                                        <Tooltip title="Cancel" placement="top" arrow>
                                                                            <IconButton onClick={() => {
                                                                                setIsEditColumnTitle(false);
                                                                                setEditModeColumnTitle("");
                                                                            }}
                                                                                sx={{ marginLeft: "auto" }}
                                                                            >
                                                                                <CancelIcon fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>}
                                                                    {isEditColumnTitle &&
                                                                        columnBannerRowIndex === index &&
                                                                        <Tooltip title="Save" placement="top" arrow>
                                                                            <IconButton onClick={() => {
                                                                                if (validateColumnTitle(editModeColumnTitle)) {
                                                                                    handleUpdateRowTypeColumnData(index, "name", editModeColumnTitle)
                                                                                    setIsEditColumnTitle(false);
                                                                                    setEditModeColumnTitle("");
                                                                                }
                                                                            }}
                                                                                sx={{ marginLeft: "auto" }}
                                                                            >
                                                                                <SaveAsIcon fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>}
                                                                </Box>
                                                                <Box sx={{ marginLeft: "auto", display: "flex" }}>
                                                                    {!isEditColumnTitle
                                                                        && <Tooltip title="Delete" placement="top" arrow>
                                                                            <IconButton aria-label="delete" onClick={(event) => {
                                                                                setAnchorEl(event.currentTarget)
                                                                                setColumnBannerRowIndex(index)
                                                                            }}
                                                                            >
                                                                                <DeleteIcon fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>}
                                                                    {!isEditColumnTitle
                                                                        && <Box {...provided.dragHandleProps}>
                                                                            <IconButton>
                                                                                <DragHandleIcon fontSize="small" />
                                                                            </IconButton>
                                                                        </Box>}
                                                                </Box>
                                                            </Box>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Box sx={{ marginBottom: "20px" }}>
                                                                <TextField
                                                                    select
                                                                    label="Select Column type"
                                                                    onChange={(event) => {
                                                                        handleUpdateRowTypeColumnData(index, "columnType", event.target.value || "");
                                                                        clearRowImageData(index);
                                                                        setAddedItemIndex(null);
                                                                    }}
                                                                    placeholder="Please Select Column type"
                                                                    size="small"
                                                                    sx={{ width: "20%" }}
                                                                    value={item.columnType || ""}
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                    {/* {columnTypeList.map((option) =>
                                                                        <MenuItem key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                    )} */}
                                                                    {getcolumnTypeOptions()}
                                                                </TextField>
                                                                <TextField
                                                                    select
                                                                    label="Select Column Width"
                                                                    onChange={(event) => {
                                                                        handleUpdateRowTypeColumnData(index, "width", event.target.value || 0)
                                                                    }}
                                                                    placeholder="Please Select Column Width"
                                                                    size="small"
                                                                    sx={{ marginLeft: "10px", width: "20%" }}
                                                                    value={item.width || 0}
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                    {
                                                                        getUpdatedColumnWidthList(columnData, index)
                                                                    }
                                                                </TextField>
                                                                {item.columnType === columnTypeValue.HTML &&
                                                                    <>
                                                                        <TextField
                                                                            autoFocus
                                                                            id="showMoreContentHeight"
                                                                            label="Show More Content Height(px)"
                                                                            type="Number"
                                                                            value={item.showMoreContentHeight || 0}
                                                                            onChange={event => {
                                                                                let value = +event.target.value || 0;
                                                                                if (!Number.isInteger(value)) {
                                                                                    return;
                                                                                }
                                                                                if (value > 300) value = 300;
                                                                                handleUpdateRowTypeColumnData(index, "showMoreContentHeight", value)
                                                                            }
                                                                            }
                                                                            sx={{ marginLeft: "10px" }}
                                                                            size="small"
                                                                        />
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    key={"isShowMoreButtonVisible"}
                                                                                    name={"isShowMoreButtonVisible"}

                                                                                    checked={item.isShowMoreButtonVisible}
                                                                                    onChange={event =>
                                                                                        handleUpdateRowTypeColumnData(index, "isShowMoreButtonVisible", event.target.checked || false)
                                                                                    }
                                                                                />
                                                                            }
                                                                            sx={{ marginLeft: "10px" }}
                                                                            label={"View Show More Button(Only Responsive)"} />
                                                                    </>
                                                                }
                                                                {item.columnType === columnTypeValue.SLIDER &&
                                                                    <TextField
                                                                        select
                                                                        label="Select Auto play"
                                                                        onChange={(event) =>
                                                                            handleUpdateRowTypeColumnData(index, "autoPlay", get(event, "target.value", null))
                                                                        }
                                                                        placeholder="Please Select Auto play"
                                                                        size="small"
                                                                        sx={{ marginLeft: "10px", width: "20%" }}
                                                                        value={item.autoPlay}
                                                                        name="autoPlay"
                                                                        InputLabelProps={{ shrink: true }}
                                                                    >
                                                                        {autoPlayOptions.map((option) =>
                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>)}
                                                                    </TextField>
                                                                }
                                                            </Box>
                                                            <Box sx={{ display: "flex" }}>
                                                                <SpacingControlsMUI
                                                                    styles={{
                                                                        marginBottom: item.styles?.marginBottom || 0,
                                                                        marginTop: item.styles?.marginTop || 0,
                                                                        paddingBottom: item.styles?.paddingBottom || 0,
                                                                        paddingLeft: item.styles?.paddingLeft || 0,
                                                                        paddingRight: item.styles?.paddingRight || 0,
                                                                        paddingTop: item.styles?.paddingTop || 0,
                                                                    }}
                                                                    handleSpacingChange={(val) => {
                                                                        handleUpdateRowTypeColumnData(index, "styles", { ...item.styles, ...val })
                                                                    }}
                                                                    CommonStyle={{ display: "flex", width: "40%", marginBottom: "20px" }}
                                                                    paddingStyle={{ width: "70%" }}
                                                                    marginStyle={{ width: "30%", marginLeft: "20px" }}
                                                                />
                                                                <Box className="mui-countdown" style={{ marginLeft: "20px" }}>
                                                                    <span>Background color</span>
                                                                    <span
                                                                        onClick={() =>
                                                                            handleUpdateRowTypeColumnData(index, "styles", { ...item.styles, backgroundColor: "" })
                                                                        }
                                                                        className="countdown-delete-icon"
                                                                    />
                                                                    <Box sx={{ marginTop: "10px" }}>
                                                                        <ColorPicker
                                                                            id="background-color"
                                                                            onClick={() => handlePickerClickById("background-color")}
                                                                            background={backgroundColor}
                                                                            displayPicker={!!displayColorPickerById["background-color"]}
                                                                            onChange={changedColor =>
                                                                                handleUpdateRowTypeColumnData(index, "styles", { ...item.styles, backgroundColor: changedColor.hex })
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            {
                                                                [columnTypeValue.SLIDER, columnTypeValue.PRODUCTCAROUSEL].includes(item.columnType) &&
                                                                (
                                                                    <Box sx={{ display: "flex", marginBottom: "20px" }}>
                                                                        <Box sx={{ width: "35%" }}>
                                                                            <Card variant="outlined" sx={{ overflow: "visible" }}>
                                                                                <CardContent>
                                                                                    <Typography>
                                                                                        Indicator:
                                                                                    </Typography>
                                                                                    <Divider orientation="horizontal" variant="fullWidth" style={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                                                                    <Box sx={{ display: "flex" }}>
                                                                                        <Box sx={{ width: "40%", marginTop: "35px" }}>
                                                                                            <TextField
                                                                                                select
                                                                                                label="Select Indicator Type"
                                                                                                onChange={(event) =>
                                                                                                    handleUpdateRowTypeColumnData(index, "indicatorType", get(event, "target.value", null))
                                                                                                }
                                                                                                placeholder="Please Select Indicator type"
                                                                                                size="small"
                                                                                                sx={{ width: "100%" }}
                                                                                                value={item.indicatorType}
                                                                                                InputLabelProps={{ shrink: true }}
                                                                                            >
                                                                                                {getIndicatorOptions()}
                                                                                            </TextField>
                                                                                        </Box>
                                                                                        <Box className="mui-countdown" style={{ width: "25%", marginLeft: "10px" }}>
                                                                                            <span>Bg color</span>
                                                                                            <span
                                                                                                onClick={() =>
                                                                                                    handleUpdateRowTypeColumnData(index, "indicatorStyles", { ...item.indicatorStyles, backgroundIndicatorColor: "" })
                                                                                                }
                                                                                                className="countdown-delete-icon"
                                                                                            />
                                                                                            <Box sx={{ marginTop: "10px" }}>
                                                                                                <ColorPicker
                                                                                                    id="indicator-background-color"
                                                                                                    onClick={() => handlePickerClickById("indicator-background-color")}
                                                                                                    background={get(
                                                                                                        item.indicatorStyles,
                                                                                                        "backgroundIndicatorColor",
                                                                                                        "#ffffff"
                                                                                                    )}
                                                                                                    displayPicker={!!displayColorPickerById["indicator-background-color"]}
                                                                                                    onChange={changedColor =>
                                                                                                        handleUpdateRowTypeColumnData(index, "indicatorStyles", { ...item.indicatorStyles, backgroundIndicatorColor: changedColor.hex })
                                                                                                    }
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                        <Box className="mui-countdown" style={{ width: "25%", marginLeft: "10px" }}>
                                                                                            <span>Color</span>
                                                                                            <span
                                                                                                onClick={() =>
                                                                                                    handleUpdateRowTypeColumnData(index, "indicatorStyles", { ...item.indicatorStyles, indicatorColor: "" })

                                                                                                }
                                                                                                className="countdown-delete-icon"
                                                                                            />
                                                                                            <Box sx={{ marginTop: "10px" }}>
                                                                                                <ColorPicker
                                                                                                    id="indicator-color"
                                                                                                    onClick={() => handlePickerClickById("indicator-color")}
                                                                                                    background={get(
                                                                                                        item.indicatorStyles,
                                                                                                        "indicatorColor",
                                                                                                        "#ffffff"
                                                                                                    )}
                                                                                                    displayPicker={!!displayColorPickerById["indicator-color"]}
                                                                                                    onChange={changedColor =>
                                                                                                        handleUpdateRowTypeColumnData(index, "indicatorStyles", { ...item.indicatorStyles, indicatorColor: changedColor.hex })

                                                                                                    }
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </CardContent>
                                                                            </Card>
                                                                        </Box>
                                                                        {deviceType === deviceTypes.desktop &&
                                                                            <Box sx={{ marginLeft: "20px", width: "30%" }}>
                                                                                <Card variant="outlined" sx={{ overflow: "visible" }}>
                                                                                    <CardContent>
                                                                                        <Typography>
                                                                                            Controller:
                                                                                        </Typography>
                                                                                        <Divider orientation="horizontal" variant="fullWidth" style={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                                                                        <Box sx={{ display: "flex" }}>
                                                                                            <Box sx={{ width: "60%", marginTop: "35px" }}>
                                                                                                <TextField
                                                                                                    select
                                                                                                    label="Select Controller Type"
                                                                                                    onChange={(event) =>
                                                                                                        handleUpdateRowTypeColumnData(index, "controllerType", get(event, "target.value", null))
                                                                                                    }
                                                                                                    placeholder="Please Select Controller type"
                                                                                                    size="small"
                                                                                                    sx={{ width: "100%" }}
                                                                                                    value={item.controllerType}
                                                                                                    InputLabelProps={{ shrink: true }}
                                                                                                >
                                                                                                    {controllerIndicatorOptions.map((option) =>
                                                                                                        <MenuItem key={option.value} value={option.value}>
                                                                                                            {option.label}
                                                                                                        </MenuItem>
                                                                                                    )}
                                                                                                </TextField>
                                                                                            </Box>
                                                                                            <Box className="mui-countdown" style={{ width: "30%", marginLeft: "10px" }}>
                                                                                                <span>Bg color</span>
                                                                                                <span
                                                                                                    onClick={() =>
                                                                                                        handleUpdateRowTypeColumnData(index, "controllerStyles", { ...item.controllerStyles, backgroundControllerColor: "" })
                                                                                                    }
                                                                                                    className="countdown-delete-icon"
                                                                                                />
                                                                                                <Box sx={{ marginTop: "10px" }}>
                                                                                                    <ColorPicker
                                                                                                        id="controller-background-color"
                                                                                                        onClick={() => handlePickerClickById("controller-background-color")}
                                                                                                        background={get(
                                                                                                            item.controllerStyles,
                                                                                                            "backgroundControllerColor",
                                                                                                            "#ffffff"
                                                                                                        )}
                                                                                                        displayPicker={!!displayColorPickerById["controller-background-color"]}
                                                                                                        onChange={changedColor =>
                                                                                                            handleUpdateRowTypeColumnData(index, "controllerStyles", { ...item.controllerStyles, backgroundControllerColor: changedColor.hex })
                                                                                                        }
                                                                                                    />
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </CardContent>
                                                                                </Card>
                                                                            </Box>}
                                                                        {([columnTypeValue.PRODUCTCAROUSEL].includes(item.columnType)) &&
                                                                            <Box sx={{ marginLeft: "20px", width: "30%" }}>
                                                                                <Card variant="outlined" sx={{ overflow: "visible" }}>
                                                                                    <CardContent>
                                                                                        <Typography>
                                                                                            Autoscroll:
                                                                                        </Typography>
                                                                                        <Divider orientation="horizontal" variant="fullWidth" style={{ "marginTop": "10px", "marginBottom": "10px" }} />
                                                                                        <Box sx={{ display: "flex", marginTop: "45px" }}>
                                                                                            <Box sx={{ width: "45%" }}>
                                                                                                <TextField
                                                                                                    select
                                                                                                    label="Select Autoscroll Type"
                                                                                                    onChange={(event) =>
                                                                                                        handleUpdateRowTypeColumnData(index, "autoScroll", {
                                                                                                            ...item.autoScroll,
                                                                                                            type: get(event, "target.value", null)
                                                                                                        })
                                                                                                    }
                                                                                                    placeholder="Please Select Autoscroll type"
                                                                                                    size="small"
                                                                                                    sx={{ width: "100%" }}
                                                                                                    value={get(item.autoScroll, "type", "")}
                                                                                                    InputLabelProps={{ shrink: true }}
                                                                                                >
                                                                                                    {autoScrollOptions.map((option) =>
                                                                                                        <MenuItem key={option.value} value={option.value}>
                                                                                                            {option.label}
                                                                                                        </MenuItem>
                                                                                                    )}
                                                                                                </TextField>
                                                                                            </Box>
                                                                                            <Box sx={{ width: "45%", marginLeft: "10px" }}>
                                                                                                <TextField
                                                                                                    select
                                                                                                    label="Select Speed ( in seconds )"
                                                                                                    onChange={(event) =>
                                                                                                        handleUpdateRowTypeColumnData(index, "autoScroll", {
                                                                                                            ...item.autoScroll,
                                                                                                            speed: get(event, "target.value", null)
                                                                                                        })
                                                                                                    }
                                                                                                    placeholder="Please Select Speed ( in seconds )"
                                                                                                    size="small"
                                                                                                    sx={{ width: "100%" }}
                                                                                                    value={get(item.autoScroll, "speed", "")}
                                                                                                    InputLabelProps={{ shrink: true }}
                                                                                                >
                                                                                                    {autoScrollSpeedOptions.map((option) =>
                                                                                                        <MenuItem key={option.value} value={option.value}>
                                                                                                            {option.label}
                                                                                                        </MenuItem>
                                                                                                    )}
                                                                                                </TextField>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </CardContent>
                                                                                </Card>
                                                                            </Box>}
                                                                    </Box>
                                                                )
                                                            }
                                                            {item.columnType === columnTypeValue.TREE &&
                                                                <Box>
                                                                    <MUIButton
                                                                        onClick={() => {
                                                                            handleModal(ADD_MENU_MODAL, true)
                                                                            setColumnBannerRowIndex(index)
                                                                        }
                                                                        }
                                                                        variant="contained"
                                                                        sx={{ "marginTop": "20px", "marginLeft": "auto", "marginBottom": "20px" }}
                                                                    >
                                                                        Add Menu Item
                                                                    </MUIButton>
                                                                    <CustomSortableTree
                                                                        treeData={item.menu || []}
                                                                        columnName={index}
                                                                        updateTreeDataValue={(treeData, columnName) => {
                                                                            onConfirmDragAndDropMenu(treeData, index)
                                                                        }}
                                                                        openEditModal={(rowInfo) => {
                                                                            setNodeId(rowInfo.node.id)
                                                                            setColumnBannerRowIndex(index)
                                                                            handleModal(EDIT_MENU_MODAL, true)
                                                                        }}
                                                                        openConfirmModal={(rowInfo) => {
                                                                            onConfirmDeleteMenuModal(index, rowInfo.node.id)
                                                                        }}
                                                                    />
                                                                </Box>
                                                            }
                                                            {item.columnType === columnTypeValue.HTML &&
                                                                <HTMLRowType
                                                                    englishHTML={get(item, `html.${LANGUAGE.ENGLISH}`, "")}
                                                                    arabicHTML={get(item, `html.${LANGUAGE.ARABIC}`, "")}
                                                                    onChangeEnglishHTML={(value) => handleUpdateRowTypeColumnHTMLData(index, "html", value, LANGUAGE.ENGLISH)}
                                                                    onChangeArabicHTML={(value) => handleUpdateRowTypeColumnHTMLData(index, "html", value, LANGUAGE.ARABIC)}
                                                                />
                                                            }
                                                            {item.columnType === columnTypeValue.PRODUCTCAROUSEL &&
                                                                <Box sx={{ width: "100%", display: "flex" }}>
                                                                    <Box sx={{ width: "50%", marginRight: "10px" }}>
                                                                        <ProductCarouselForm
                                                                            content={item}
                                                                            handleTextChange={(name, value, language) => updateProductCarouselData(name, value, language, index)}
                                                                            handleUrlChange={(name, value) => handleUrlChange(name, value, index)}
                                                                            language={LANGUAGE.ENGLISH}
                                                                        />
                                                                    </Box>
                                                                    <Box sx={{ width: "50%", marginLeft: "10px" }}>
                                                                        <ProductCarouselForm
                                                                            content={item}
                                                                            handleTextChange={(name, value, language) => updateProductCarouselData(name, value, language, index)}
                                                                            handleUrlChange={(name, value) => handleUrlChange(name, value, index)}
                                                                            language={LANGUAGE.ARABIC}
                                                                        />
                                                                    </Box>
                                                                </Box>

                                                            }
                                                            {[columnTypeValue.SINGLE, columnTypeValue.SLIDER].includes(item.columnType) &&
                                                                <div className={cn(`banner-row-content ${draggableClass}`)}>
                                                                    {itemList.map((item, id) => (
                                                                        <ItemModal
                                                                            item={item}
                                                                            key={item.id}
                                                                            bannerType={bannerType}
                                                                            deviceType={deviceType}
                                                                            itemListIndex={id}
                                                                            rowIndex={rowIndex}
                                                                            schedulerIndex={schedulerIndex}
                                                                            handleDeleteImage={() => handleDeleteRowImage(id, index)}
                                                                            modalOpen={columnBannerRowIndex === index && id === addedItemIndex}
                                                                            pageType={pageType}
                                                                            updateCatalogPageType={updateCatalogPageType}
                                                                            updateCatalogPageLocation={updateCatalogPageLocation}
                                                                            path={path}
                                                                        />
                                                                    ))}
                                                                    {item.columnType === columnTypeValue.SINGLE && <SingleRowType
                                                                        handleAddRowItem={(itemType) => handleAddRowItem(itemType, index)}
                                                                    />}
                                                                    {item.columnType === columnTypeValue.SLIDER && <SliderRowType
                                                                        handleAddRowItem={(itemType) => handleAddRowItem(itemType, index)}
                                                                    />}
                                                                </div>
                                                            }
                                                            {item.columnType === columnTypeValue.CUSTOM &&
                                                                <CustomRowType
                                                                    handleAddRowTypeCustomField={() => handleAddRowTypeCustomField(index)}
                                                                    customData={item.custom || []}
                                                                    handleUpdateSelectedModuleType={(value) => handleUpdateSelectedModuleTypeCustom(value, index)}
                                                                    handleUpdateRowTypeCustomData={(customIndex, name, value) => handleUpdateRowTypeCustomData(customIndex, name, value, index)}
                                                                    handleRemoveRowTypeCustomField={(customIndex) => handleRemoveRowTypeCustomField(customIndex, index)}
                                                                    flashSalePageOptions={flashSalePageOptions}
                                                                    deviceType={deviceType}
                                                                />
                                                            }
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Box>
                                            )}

                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>

                {
                    !columnData.length &&
                    <Typography align={"center"} color={"#000000"}>
                        No Data Found
                    </Typography>
                }
            </Box>
        </Fragment >
    );
};

export default ColumnRowType;