import React, { useState } from "react";
import { useDispatch } from "react-redux";

import get from "lodash/get";

import { DynamicBannerAccordion } from "./DynamicBannerAccordion";
import { DynamicBannerTab } from "./DynamicBannerTab";

import { cloneTab } from "../../actions/dynamicBanner.action";
import { toastMsg } from "../../actions/common.action";

import { CloneTabPopupContent } from "../../containers/pagesPage/popupContent";

export const DynamicBannerContent = ({
  content,
  handleDeleteSection,
  handleExpandSection,
  handleToggleSection,
  keyIndex,
  deleteHomepageListItem,
  handleListDataChange,
  toggleHomepageListItem,
  listName,
  sliderId,
  accordionTitle,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  sectionTitle,
  accessRights
}) => {
  const dispatch = useDispatch();

  const [clonedTab, setClonedTab] = useState(null);

  const isEnabled = get(content, "enable", false);
  const isOpen = get(content, "openAccordion", false);

  const handleOpen = () => handleExpandSection(content.id);

  const handleToggle = () => handleToggleSection();

  const handleMobileModuleClone = () => {
    dispatch(
      cloneTab({
        path: keyIndex,
        tab: clonedTab,
        pageType,
        updateCatalogPageLocation,
        updateCatalogPageType
      })
    );
    dispatch(toastMsg("success", "Cloned successfully"));
  };

  const handleSetClonedTab = label => () => setClonedTab(label);

  return (
    <DynamicBannerAccordion
      sectionTitle={sectionTitle}
      content={content}
      handleDeleteSection={handleDeleteSection}
      handleOpen={handleOpen}
      handleToggle={handleToggle}
      isEnabled={isEnabled}
      isOpen={isOpen}
      keyIndex={keyIndex}
      sliderId={sliderId}
      listName={listName}
      accordionTitle={accordionTitle}
      pageType={pageType}
      updateCatalogPageType={updateCatalogPageType}
      updateCatalogPageLocation={updateCatalogPageLocation}
      accessRights={accessRights}
    >
      {clonedTab && (
        <div className={`popup${clonedTab}`} style={{ width: "28%" }}>
          <CloneTabPopupContent
            onConfirm={() => {
              handleMobileModuleClone();
              setClonedTab(null);
            }}
            rowTitle={accordionTitle}
            tab={clonedTab}
            onCancel={() => setClonedTab(null)}
          />
        </div>
      )}
      <DynamicBannerTab
        deleteHomepageListItem={deleteHomepageListItem}
        handleListDataChange={handleListDataChange}
        listName={listName}
        position={keyIndex}
        content={content}
        toggleHomepageListItem={toggleHomepageListItem}
        pageType={pageType}
        updateCatalogPageType={updateCatalogPageType}
        updateCatalogPageLocation={updateCatalogPageLocation}
        clone={handleSetClonedTab}
      />
    </DynamicBannerAccordion>
  );
};
