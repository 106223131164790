import React from "react";
import defaultImageSrc from "../images/default.png";

const defaultImage = e => {
  e.target.onerror = null;
  e.target.src = defaultImageSrc;
};

const Image = props => (
  <img
    className={props.className}
    src={props.src}
    alt={props.alt}
    onError={defaultImage}
  />
);

export default Image;
