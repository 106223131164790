import React, { useRef, useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '../../../materialUi/components';
import CKEditor from "ckeditor4-react";
import { ENV_CONFIG } from "../../../config/env";
import {
  CK_EDITOR_URL,
  STANDARD_CK_CONFIG,
} from "../../../constants";
import CKEditorNotes from "../../../component/ckEditorNotes";

CKEditor.editorUrl = CK_EDITOR_URL.url;

const HTMLViewDialog = ({ open, handleClose, handleSave, html, title, dialogContext, language }) => {
  const [modalHtml, setModalHtml] = useState(html || "");

  useEffect(() => {
    setModalHtml(html || "");
  }, [html]);
  //Note: To resolve Focusing issues in Editor Dialog
  useEffect(() => {
    const handler = (e) => {
      if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener("focusin", handler);
    return () => document.removeEventListener("focusin", handler);
  }, []);

  const handleEditorSave = () => {
    handleSave(modalHtml || "")
  }

  const handleCkTextChange = (evt) => {
    setTimeout(() => {
      setModalHtml(evt.editor.getData());
    }, 100)
  };


  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {dialogContext}
          </DialogContentText>
          <CKEditor
            activeClass="p10"
            config={{
              language,
              ...STANDARD_CK_CONFIG,
              ...{
                filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                  }/homepage/content/uploadCK?id=${language}&key=${"htmleditor"}&language=${language}&containerName=${"htmleditor"}`
              }
            }}
            data={
              modalHtml
            }
            onChange={(e) => {
              handleCkTextChange(
                e,
              );
            }}
            onKey={(e) => {
              handleCkTextChange(
                e,
              );
            }}

          />
          <CKEditorNotes noMargin={true} display={[1, 2, 3, 4]} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleEditorSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HTMLViewDialog