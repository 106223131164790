import { SET_NAV_MENU_LIST } from "../constants/actions";

const initialState = {
  navMenuList: []
};

const navMenuList = (state, { data }) => {
  return { ...state, navMenuList: data };
};

const navMenuManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAV_MENU_LIST:
      return navMenuList(state, action);
    default:
      return state;
  }
};

export default navMenuManagementReducer;
