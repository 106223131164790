export const SCHEDULER_PLACEHOLDERS = {
  english: {
    banner: "Enter scheduler HTML",
    productCarousel: "Enter Carousel Title",
    productCarouselSubtitle: "Enter Carousel SubTitle"
  },
  arabic: {
    banner: "أدخل جدولة HTML",
    productCarousel: "أدخل عنوان دائري",
    productCarouselSubtitle: "أدخل عنوان دائري"
  }
};
