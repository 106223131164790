export const FOOTER_STATIC_LANG_MAP = {
  english: "updateEnStaticContent",
  arabic: "updateArStaticContent"
};

export const FOOTER_PANEL = {
  MENU_ITEMS: "menuItems",
  STATIC_CONTENT: "staticContent"
};

export const FOOTER_PANEL_LABELS = {
  [FOOTER_PANEL.MENU_ITEMS]: "Menu Items",
  [FOOTER_PANEL.STATIC_CONTENT]: "Static Content"
};

export const FOOTER_ADD_ATTRIBUTE_FORM_FONT_COLOR_OPTIONS = [
  { label: "dark grey", value: "#5e5e5e" },
  { label: "light grey", value: "#acacac" }
];

export const FOOTER_COMPONENT_TYPES = {
  DYNAMIC_BANNER: "dynamicBannerModule"
};

export const FOOTER_PAGE = "FOOTER_PAGE";