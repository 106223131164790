import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";

import SchedulerDatePicker from "../../component/scheduler/SchedulerDatePicker";
import { toastMsg } from "../../actions/common.action";

const customStyles = {
  content: {
    width: "412px",
    height: "400px",
    borderRadius: "none",
    position: "absolute",
    top: "20%",
    left: "35%",
    right: "40px",
    bottom: "40px",
    background: "rgb(255, 255, 255)",
    overflow: "visible"
  },

  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

const SavePreviewModal = ({ modalName, onHide, confirm, message }) => {
  const dispatch = useDispatch();

  const [dates, setDates] = useState({});

  const handleDatePicker = (e, type) => {
    const timeStamp = Date.parse(e);

    setDates(dates => ({ ...dates, [type]: timeStamp }));
  };

  const handlePreviewWithDate = () => {
    if (!dates.from || !dates.to) {
      return dispatch(toastMsg("error", "Select from and to dates first"));
    }

    confirm(dates);
  };

  return (
    <div>
      <Modal
        isOpen={true}
        ariaHideApp={false}
        onRequestClose={() => onHide(modalName)}
        style={customStyles}
        closeTimeoutMS={2000}
      >
        <div className="close-btn">
          <span onClick={() => onHide(modalName)} className="close-icon" />
        </div>
        <span className="modal-text">{message}</span>
        <button className="action-btn marginTop-20 m-l-20" onClick={confirm}>
          <span className="btn-text">Yes</span>
        </button>
        <button
          className="n-action-btn marginTop-20 m-l-20"
          onClick={() => onHide(modalName)}
        >
          <span className="n-btn-text">No</span>
        </button>
        <div className="preview-datepickers">
          <SchedulerDatePicker
            type="from"
            value={dates.from}
            handleDatePicker={handleDatePicker}
            label="From"
            id="from"
            keyIndex={1}
          />
          <SchedulerDatePicker
            type="to"
            value={dates.to}
            handleDatePicker={handleDatePicker}
            label="To"
            id="to"
            keyIndex={2}
          />
          <button className="action-btn" onClick={handlePreviewWithDate}>
            <span className="btn-text">Preview</span>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SavePreviewModal;
