export const STATIC_PAGE_PANEL = {
  STATIC_ADD_PAGE_DISPLAY: "staticAddPageDisplay",
  STATIC_UPDATE_PAGE_DISPLAY: "staticUpdatePageDisplay",
  STATIC_DELETE_PAGE_DISPLAY: "StaticDeletePageDisplay",
  Html: "Normal Template",
  Accordion: "Accordion Template",
  Comb_accordion: "Combine Accordion Template"
};

export const STATIC_PAGE_PAGETYPE = {
  html: "Html",
  accordion: "Accordion",
  comb_accordion: "Comb_accordion"
};

export const STATIC_PAGE_TYPE_MAP = {
  html: "Html",
  accordion: "Accordion",
  comb_accordion: "Comb_accordion"
};

export const STATIC_PAGE_TYPE = {
  html: "Normal Template",
  accordion: "Accordion Template",
  comb_accordion: "Combine Accordion Template"
};
