import React, { useState, forwardRef, useEffect } from 'react'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const CustomizedSnackbar = ({ id, open, severity, text, vertical = 'top', horizontal = 'right', ...props }) => {
  const [_open, setOpen] = useState(open || false);

  useEffect(() => {
    setOpen(open);
  }, [open, id]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar open={_open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  )
}

export default CustomizedSnackbar