import React, { Fragment, useState } from "react";

import { CustomSelect } from "../../component";
import InputText from "../../component/InputText";

const PopupContent = ({ onConfirm, onCancel, message }) => (
  <Fragment>
    <div>{message || "Are you sure you want to delete the item?"}</div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={onConfirm}>
        <span className="btn-text">Yes</span>
      </button>
      <button className="popup-cancel" onClick={onCancel}>
        <span className="n-btn-text">No</span>
      </button>
    </div>
  </Fragment>
);

export const AddNewModulePopupContent = ({
  onConfirm,
  onCancel
}) => {
  const [data, setData] = useState({
    moduleName: "",
    moduleSlug: "",
  });
  const onDataChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <Fragment>
      <div>
        <p>Add new Module</p>
        <InputText
          placeholder="Module Name"
          name="moduleName"
          value={data.moduleName}
          onChange={onDataChange}
        />

        <InputText
          placeholder="Slug"
          name="moduleSlug"
          value={data.moduleSlug}
          onChange={onDataChange}
        />
      </div>
      <div className="popup-buttons">
        <button className="popup-confirm" onClick={() => onConfirm(data)}>
          <span className="btn-text">Yes</span>
        </button>
        <button className="popup-cancel" onClick={onCancel}>
          <span className="n-btn-text">No</span>
        </button>
      </div>
    </Fragment>
  )
};

export const AddNewSectionPopupContent = ({
  onConfirm, onCancel, moduleSlug
}) => {
  const [data, setData] = useState({
    moduleSlug: moduleSlug,
    sectionName: "",
    sectionSlug: "",
  });

  const onDataChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <Fragment>
      <div>
        <p>Add new Section</p>
        <InputText
          placeholder="Section Name"
          name="sectionName"
          value={data.sectionName}
          onChange={onDataChange}
        />
        <InputText
          placeholder="Slug"
          name="sectionSlug"
          value={data.sectionSlug}
          onChange={onDataChange}
        />
      </div>
      <div className="popup-buttons">
        <button className="popup-confirm" onClick={() => onConfirm(data)}>
          <span className="btn-text">Yes</span>
        </button>
        <button className="popup-cancel" onClick={onCancel}>
          <span className="n-btn-text">No</span>
        </button>
      </div>
    </Fragment>
  )

};

export default PopupContent;
