import React, { Component } from "react";
import Toggle from "react-toggle";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import ReactTooltip from "react-tooltip";
import Button from "../../component/button";
import { SIZE_CHART_CATEGORY_MAP, MESSAGES } from "../../constants";

class BrandsChartList extends Component {
  updateToggle = (e, item) => {
    const {
      countryId,
      selectedTab,
      apiContent,
      handleBrandToggle
    } = this.props;
    handleBrandToggle(apiContent._id, {
      countryId,
      type: selectedTab,
      enabled: e.target.checked,
      brandId: item.brandId
    });
  };

  handleSave = () => {
    const { changePageType } = this.props;
    changePageType(2);
  };

  handleEdit = item => {
    const { changeSelectedBrand, changePageType } = this.props;
    changeSelectedBrand({ brandname: item.brandName, id: item.brandId });
    changePageType(3);
  };

  _handleDelete = item => {
    const {
      countryId,
      selectedTab,
      apiContent,
      handleBrandDelete
    } = this.props;
    handleBrandDelete({
      id: apiContent._id,
      countryId,
      type: selectedTab,
      brandId: item.brandId
    });
  };

  render() {
    const { brandList, enabled, selectedTab, accessRights } = this.props;
    const _brandList = sortBy(uniqBy(brandList, "brandId"), [
      o => o.brandName.trim().toLowerCase()
    ]);
    const _isEnabled = data => enabled.some(item => item === data.brandId);
    return (
      <div className="data-wrapper position-relative">
        <span className="list-heading">List of Brands Size Chart</span>
        <span
          data-tip={accessRights.WRITE ? "" : MESSAGES.TOOLTIP_WRITE}
          data-for="brand-chart-list"
          style={{ float: "right" }}
        >
          <Button
            name={"Create Brand Size Chart"}
            onClick={accessRights.WRITE ? this.handleSave : null}
            disabled={!accessRights.WRITE}
            customClass={"float-right"}
          />
        </span>
        <div>
          <table className="table table-striped marginBottom">
            <thead>
              <tr>
                <th>Sr.</th>
                <th>Category</th>
                <th>Brand Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="center-align">
              {_brandList && !!_brandList.length ? (
                _brandList.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{SIZE_CHART_CATEGORY_MAP[selectedTab]}</td>
                    <td>{item.brandName}</td>
                    <td>
                      <span
                        className="action-toggle"
                        data-tip={
                          accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE
                        }
                        data-for="brand-chart-list"
                      >
                        <Toggle
                          checked={_isEnabled(item)}
                          icons={false}
                          onChange={
                            accessRights.UPDATE
                              ? e => this.updateToggle(e, item)
                              : null
                          }
                          disabled={!accessRights.UPDATE}
                        />
                      </span>
                      <span
                        className={`edit-icon ${
                          accessRights.UPDATE
                            ? "cursor-pointer"
                            : "cursor-pointer-disabled-permission"
                        }`}
                        onClick={
                          accessRights.UPDATE
                            ? () => this.handleEdit(item)
                            : null
                        }
                        data-tip={
                          accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE
                        }
                        data-for="brand-chart-list"
                      />
                      <span
                        className={`delete-icon ${
                          accessRights.DELETE
                            ? "cursor-pointer"
                            : "cursor-pointer-disabled-permission"
                        }`}
                        onClick={
                          accessRights.DELETE
                            ? () => this._handleDelete(item)
                            : null
                        }
                        data-tip={
                          accessRights.DELETE ? "" : MESSAGES.TOOLTIP_DELETE
                        }
                        data-for="brand-chart-list"
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="no-data">
                  <td colSpan="4">No brands size chart created</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <ReactTooltip place="top" effect="solid" id="brand-chart-list" />
      </div>
    );
  }
}

export default BrandsChartList;
