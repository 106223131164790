import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { CustomSelect } from "../../component/customSelect";
import Button from "../../component/button";
import { cloneDeep } from "lodash";
import { filterAttributeOptions, defaultAttributeValues, attributeKeyValueMapping, textInputFilterAttributes, checkboxFilterAttributes, colorFilterAttributes } from "../../constants";
import { ColorPicker } from "../../component/ColorPicker";

const customStyles = {
  content: {
    width: "700px",
    height: "600px",
    borderRadius: "none",
    position: "absolute",
    top: "5%",
    left: "35%",
    right: "40px",
    bottom: "40px",
    background: "rgb(255, 255, 255)",
    overflow: "auto"
  },

  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

const EditFilterConfigModal = ({ modalName, onHide, confirm, data = {}, filterType }) => {
  const [filterConfigData, setFilterConfigData] = useState(cloneDeep(data));
  const [selectedAttributeType, setSelectedAttributeType] = useState(null);
  const [displayColorPicker, setDisplayColorPicker] = useState({});
  const [options, setOptions] = useState([]);



  useEffect(() => {
    setFilterConfigData(cloneDeep(data));
  }, [data]);

  useEffect(() => {
    checkAndUpdateOptions();
  }, [filterType, filterConfigData, data]);

  const checkAndUpdateOptions = () => {
    const optionsList = cloneDeep(filterAttributeOptions[filterType]?.options || []);
    const updatedOptionList = optionsList.map((option) => {
      if (filterConfigData.hasOwnProperty(option.value)) {
        option.isDisabled = true;
      }
      return option;
    });
    setOptions(updatedOptionList);
  }

  const addAttributeHandler = () => {
    setFilterConfigData({ ...filterConfigData, [selectedAttributeType]: defaultAttributeValues[selectedAttributeType] });
    setSelectedAttributeType(null);
  }

  const attributeValueChangeHandler = (key, value) => {
    setFilterConfigData({ ...filterConfigData, [key]: value });
  }

  const handlePickerClick = (key) => {
    setDisplayColorPicker({ [key]: !displayColorPicker[key] });
  }

  const getRows = () => {
    const rows = [];
    for (const key in filterConfigData) {
      rows.push(
        <tr key={key}>
          <td style={{ "width": "50%" }}>{attributeKeyValueMapping[key]}</td>
          <td style={{ "width": "50%" }}>
            {textInputFilterAttributes.includes(key) &&
              <input
                className="form-control"
                name={key}
                type="text"
                onChange={event =>
                  attributeValueChangeHandler(key, event.target.value || "")
                }
                value={filterConfigData[key]}
              />
            }
            {
              checkboxFilterAttributes.includes(key) &&
              <input
                checked={filterConfigData[key]}
                name={key}
                onChange={event =>
                  attributeValueChangeHandler(key, event.target.checked || false)
                }
                type="checkbox"
              />
            }
            {
              colorFilterAttributes.includes(key) &&
              <div className="config-filter-att">
                <span
                  onClick={() =>
                    attributeValueChangeHandler(key, "#ffffff")
                  }
                  className="filter-att-delete-icon"
                />
                <ColorPicker
                  onClick={() => handlePickerClick(key)}
                  background={filterConfigData[key]}
                  displayPicker={displayColorPicker[key] || false}
                  onChange={changedColor =>
                    attributeValueChangeHandler(key, changedColor.hex || "#ffffff")
                  }
                />
              </div>
            }
          </td>
        </tr>
      );
    }
    return rows;
  }

  return (
    <div>
      <Modal
        isOpen={true}
        ariaHideApp={false}
        onRequestClose={() => onHide(modalName)}
        style={customStyles}
        closeTimeoutMS={2000}
      >
        <div className="close-btn">
          <span onClick={() => onHide(modalName)} className="close-icon" />
        </div>
        <div style={{ "minHeight": "500px" }}>
          <div>
            <div className="selection-wrapper" style={{ "display": "flex", "justifyContent": "flex-end" }}>
              <CustomSelect
                options={options}
                onChange={ev => setSelectedAttributeType(ev.value)}
                value={selectedAttributeType}
                name="attribute"
                placeholder="Add Attribute"
                className={"conf-filter-atr-box"}
              />
              <Button name="Add" disabled={!selectedAttributeType} onClick={addAttributeHandler} />
            </div>
          </div>
          <div>
            <table className="table table-striped config-popup">
              <thead>
                <tr>
                  <th scope="col">Attribute Name</th>
                  <th scope="col">Attribute Value</th>
                </tr>
              </thead>
              <tbody>
                {
                  getRows()
                }
              </tbody>
            </table>
          </div>
        </div>
        <button className="cofig-popup-act-btn marginTop-20 marginLeft-20"
          onClick={() => confirm(filterConfigData)}
          style={{ "float": "left" }}
        >
          <span className="btn-text">Save</span>
        </button>
        <button
          className="cofig-popup-act-btn marginTop-20 marginRight-20"
          onClick={() => onHide(modalName)}
          style={{ "float": "right" }}
        >
          <span className="btn-text">Cancel</span>
        </button>
      </Modal>
    </div>
  );
};

export default EditFilterConfigModal;
