import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '../../materialUi/components';
import { cloneDeep } from "lodash";
import reactImageSize from "react-image-size";
import { UploadImage } from "./uploadImage"
import { UploadVideo } from "./uploadVideo"
import { CONFIGURATIONS_MEDIA_TYPES, imageWidthOptions, imageErrors, SUCCESS_MESSAGES, ERROR_MESSAGES } from "../../constants"
import { ImageSelectionModal } from "./imageSelectionModal"
import { VideoSelectionModal } from "./videoSelectionModal"
import { useDispatch } from "react-redux";
import { snackbar } from "../../actions/common.action";

const MediaConfigDialog = ({ open, handleClose, handleSave, data, title, dialogContext, language, deviceTab }) => {
  const [modalData, setModalData] = useState(cloneDeep(data || {}));
  const [imageSelectionModal, setImageSelectionModal] = useState(false);
  const [videoSelectionModal, setVideoSelectionModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setModalData(data);
  }, [data]);

  const onChangeHandler = (key, value) => {
    setModalData({ ...modalData, [key]: value });
  }

  const handleDeleteImage = () => {
    setModalData({ ...modalData, "imageURL": "" });
  }

  const handleDeleteVideo = () => {
    setModalData({ ...modalData, "videoURL": "" });
  }

  const handleSaveImageSelectionModal = (cdnURL) => {
    reactImageSize(cdnURL)
      .then(({ width, height }) => {
        if (width > imageWidthOptions[deviceTab].max) {
          throw new Error(imageErrors[deviceTab]);
        }
        const ratio = (height / width) * 100;
        setModalData({ ...modalData, "imageURL": cdnURL, ratio, width });
        setImageSelectionModal(false);
      })
      .catch(errorMessage =>
        dispatch(snackbar({ open: true, severity: "error", text: errorMessage.message })));
  }

  const handleSaveVideoSelectionModal = (cdnURL) => {
    let video = document.createElement("video");
    video.setAttribute("src", cdnURL);

    video.onloadeddata = event => {
      const { videoHeight, videoWidth } = event.target;
      const ratio = (videoHeight / videoWidth) * 100;
      setModalData({ ...modalData, "videoURL": cdnURL, ratio });

      setVideoSelectionModal(false);
      dispatch(snackbar({ open: true, severity: "success", text: SUCCESS_MESSAGES.videoUploaded }));
    };
  }

  const validateForm = () => {

    const { redirectionLink, mediaType, imageURL, videoURL } = modalData;
    if (!redirectionLink) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.redirectionLinkReq }));
      return false;
    }
    if (redirectionLink.charAt(0) !== "/") {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.redirectionLinkStartsWith }));
      return false;
    }
    if (redirectionLink.charAt(redirectionLink.length - 1) !== "/") {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.redirectionLinkEndsWith }));
      return false;
    }
    if (redirectionLink.includes("//")) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.invalidRedirectionLink }));
      return false;
    }
    if (mediaType === CONFIGURATIONS_MEDIA_TYPES.IMAGE && !imageURL) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.imageRequired }));
      return false;
    }
    if (mediaType === CONFIGURATIONS_MEDIA_TYPES.VIDEO && !videoURL) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.videoRequired }));
      return false;
    }
    return true;
  }
  const { mediaType } = modalData;
  return (
    <>
      {imageSelectionModal &&
        <ImageSelectionModal
          title={"Upload Image"}
          language={language}
          handleClose={() => setImageSelectionModal(false)}
          handleSave={handleSaveImageSelectionModal}
          open={imageSelectionModal}
          dialogContext={"Please Add the Image Details"}
          keyName="modal"
          containerName={"configurations"}
        />
      }
      {videoSelectionModal &&
        <VideoSelectionModal
          title={"Upload Video"}
          language={language}
          handleClose={() => setVideoSelectionModal(false)}
          handleSave={handleSaveVideoSelectionModal}
          open={videoSelectionModal}
          dialogContext={"Please Add the Video Details"}
          keyName="modal"
          containerName={"configurations"}
        />
      }
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {dialogContext}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="redirectionLink"
            label="Redirection Link"
            type="text"
            fullWidth
            helperText="Must start and end with '/'"
            onChange={(ev) => onChangeHandler("redirectionLink", ev.target.value)}
            value={modalData.redirectionLink || ""}
            required
            lang={language}
          />
          {mediaType === CONFIGURATIONS_MEDIA_TYPES.IMAGE &&
            <UploadImage
              deleteImage={handleDeleteImage}
              handeImageSelectionModal={() => setImageSelectionModal(true)}
              imageURL={modalData.imageURL || ""}
              language={language}
            />}
          {mediaType === CONFIGURATIONS_MEDIA_TYPES.VIDEO &&
            <UploadVideo
              deleteVideo={handleDeleteVideo}
              handeVideoSelectionModal={() => setVideoSelectionModal(true)}
              videoURL={modalData.videoURL || ""}
              language={language}
            />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => validateForm() && handleSave(modalData)}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MediaConfigDialog