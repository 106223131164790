import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import {
  SET_CONFIGURATIONS_ACTIVE_PAGE,
  UPDATE_CONFIG_TYPE_CONFIGURATION_PAGE,
  ADD_CONFIG_TYPE_CUSTOM_FIELD,
  REMOVE_CONFIG_TYPE_CUSTOM_FIELD,
  UPDATE_CONFIG_DESC_CONFIGURATION_PAGE,
  SET_INITIAL_CONFIGURATIONS,
  TOGGLE_RESET_CONFIGURATIONS_PAGE,
  RESET_CONFIGURATIONS_CONTENT,
  RESET_CONFIGURATIONS_DATA,
  RESET_CONFIGURATIONS,
  SET_CONFIG_ROLLBACK_HISTORY,
  TOGGLE_CONFIG_ROLLBACK_SAVE,
  SAVE_CONFIG_DATA,
  SET_CONFIG_DATA,
  CLONE_TAB_CONFIGURATIONS,
  CLONE_DEVICE_CONFIGURATIONS,
  CLONE_PLATFORM_CONFIGURATIONS,
  RESET_ACTIVE_PAGE_CONFIGURATIONS,
  SWITCH_ROW_CONFIGURATION_PAGE,
} from "../constants/actions";

import { DEVICE_TYPES_CONFIG, customInputTypes, } from "../constants";

const initialState = {
  currentTab: "configurations",
  activePage: [],
  initialData: [],
  initialActiveSection: [],
  resetToggled: false,
  rollbackHistory: [],
  rollbackSave: false,
  resetData: false,
  initialActivepage: []
};

const setInitialConfigurations = (state, action) => {
  return { ...state, initialData: action.payload };
};

const setActivePage = (state, action) => {
  return {
    ...state,
    activePage: action.payload,
    initialActiveSection: action.payload
  };
};

const setPageData = (state, action) => {
  return {
    ...state,
    activePage: action.payload,
    initialActiveSection: action.payload
  };
};

const updateConfigData = (
  state,
  { index, language, device, platform, name, value, data }
) => {
  const children = cloneDeep(state.activePage.children);
  set(data, [name], value);
  if (device === DEVICE_TYPES_CONFIG[0].key) {
    set(children, [device, index, language], data);
  } else {
    set(children, [device, platform, index, language], data);
  }
  if (name === "key" && language === "english") {
    if (device === DEVICE_TYPES_CONFIG[0].key) {
      set(children, [device, index, "arabic", "key"], value);
    } else {
      set(children, [device, platform, index, "arabic", "key"], value);
    }
  }
  return {
    ...state,
    activePage: {
      ...state.activePage,
      children
    },
    resetData: true
  };
};

const switchConfigRows = (
  state,
  { sourceIndex,
    destinationIndex,
    device,
    platform, }
) => {
  const children = cloneDeep(state.activePage.children);
  let sourceRow = {};
  let destinationRow = {};
  if (device === DEVICE_TYPES_CONFIG[0].key) {
    sourceRow = cloneDeep(get(children, [device, sourceIndex]));
    destinationRow = cloneDeep(get(children, [device, destinationIndex]));

    set(children, [device, sourceIndex], destinationRow);
    set(children, [device, destinationIndex], sourceRow);
  } else {
    sourceRow = cloneDeep(get(children, [device, platform, sourceIndex]));
    destinationRow = cloneDeep(get(children, [device, platform, destinationIndex]));

    set(children, [device, platform, sourceIndex], destinationRow);
    set(children, [device, platform, destinationIndex], sourceRow);
  }
  return {
    ...state,
    activePage: {
      ...state.activePage,
      children
    },
    resetData: true
  };
};

const addRowTypeCustomField = (state, { configType, device, platform }) => {
  let children = cloneDeep(state.activePage.children);
  let data;
  if (device === DEVICE_TYPES_CONFIG[0].key) {
    data = get(children, [device], []);
  } else {
    data = get(children, [device, platform], []);
  }
  const newRow = {
    arabic: {
      key: "",
      value: configType !== customInputTypes.MEDIA ? "" : "{}",
      type: configType,
      enable: false,
    },
    english: {
      key: "",
      value: configType !== customInputTypes.MEDIA ? "" : "{}",
      type: configType,
      enable: false,
    }
  };

  if (configType === customInputTypes.FILTER) {
    newRow["arabic"]["filterType"] = "";
    newRow["english"]["filterType"] = "";
  }
  data = [
    ...data,
    newRow,
  ];
  if (device === DEVICE_TYPES_CONFIG[0].key) {
    set(children, [device], data);
  } else {
    set(children, [device, platform], data);
  }
  return {
    ...state,
    activePage: {
      ...state.activePage,
      children
    },
    resetData: true
  };
};

const removeRowTypeCustomField = (state, { index, device, platform, id }) => {
  let children = cloneDeep(state.activePage.children);
  let data;
  if (device === DEVICE_TYPES_CONFIG[0].key) {
    data = get(children, [device], []);
  } else {
    data = get(children, [device, platform], []);
  }
  data.splice(index, 1)
  if (device === DEVICE_TYPES_CONFIG[0].key) {
    set(children, [device], data);
  } else {
    set(children, [device, platform], data);
  }
  return {
    ...state,
    activePage: {
      ...state.activePage,
      children
    },
    resetData: true
  };
};

const updateConfigDescData = (state, { value }) => {
  return {
    ...state,
    activePage: {
      ...state.activePage,
      description: value
    }
  };
};

const toggleConfiguration = (state, { index }) => {
  const pagesContent = cloneDeep(state.activePage);

  return {
    ...state,
    activePage: pagesContent
  };
};

const resetConfigData = (state, { id }) => {
  const activePage = cloneDeep(state.activePage);

  return {
    ...state,
    initialActiveSection: activePage
  };
};

const toggleReset = (state, { value }) => ({
  ...state,
  resetToggled: value
});

const resetDatas = (state, { value }) => ({
  ...state,
  resetData: value
});

const resetConfigurationsContents = state => ({
  ...state,
  activePage: cloneDeep(state.initialActiveSection),
  resetData: false
});

const resetConfigurations = state => ({
  ...state,
  activePage: cloneDeep(state.initialActivePage)
});

export const setConfigAllRollbackHistory = (state, action) => {
  return {
    ...state,
    rollbackHistory: action.payload && action.payload.data
  };
};

const toggleConfigRollbackSave = (state, { value }) => {
  return {
    ...state,
    rollbackSave: value
  };
};

const cloneDynamicTabRow = (state, { path, tab, platform }) => {
  const activePage = cloneDeep(state.activePage);
  const content = cloneDeep(state.activePage.children);
  const tabDatas = get(content, tab);
  const result = set(content, platform, cloneDeep(tabDatas));
  set(activePage, "children", result);

  return {
    ...state,
    activePage,
    resetData: true
  };
};

const cloneDynamicDevice = (state, { from, to }) => {
  const activePage = cloneDeep(state.activePage);
  const content = cloneDeep(state.activePage.children);
  const deviceData = get(content, from);
  const result = set(content, to, cloneDeep(deviceData));
  set(activePage, "children", result);

  return {
    ...state,
    activePage,
    resetData: true
  };
};

const cloneDynamicPlatform = (state, { device, from, to }) => {
  const activePage = cloneDeep(state.activePage);
  const content = cloneDeep(state.activePage.children);
  const platformData = get(content, [device, from]);
  const result = set(content, [device, to], cloneDeep(platformData));
  set(activePage, "children", result);
  return {
    ...state,
    activePage,
    resetData: true
  };
};

const resetActivePageData = state => {
  return {
    ...state,
    activePage: []
  };
};

const configurationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_CONFIGURATIONS:
      return setInitialConfigurations(state, action);
    case SET_CONFIGURATIONS_ACTIVE_PAGE:
      return setActivePage(state, action);
    case UPDATE_CONFIG_TYPE_CONFIGURATION_PAGE:
      return updateConfigData(state, action);
    case ADD_CONFIG_TYPE_CUSTOM_FIELD:
      return addRowTypeCustomField(state, action);
    case REMOVE_CONFIG_TYPE_CUSTOM_FIELD:
      return removeRowTypeCustomField(state, action);
    case UPDATE_CONFIG_DESC_CONFIGURATION_PAGE:
      return updateConfigDescData(state, action);
    case TOGGLE_RESET_CONFIGURATIONS_PAGE:
      return toggleReset(state, action);
    case RESET_CONFIGURATIONS_DATA:
      return resetDatas(state, action);
    case SAVE_CONFIG_DATA:
      return resetConfigData(state, action);
    case RESET_CONFIGURATIONS_CONTENT:
      return resetConfigurationsContents(state, action);
    case RESET_CONFIGURATIONS:
      return resetConfigurations(state, action);
    case SET_CONFIG_ROLLBACK_HISTORY:
      return setConfigAllRollbackHistory(state, action);
    case TOGGLE_CONFIG_ROLLBACK_SAVE:
      return toggleConfigRollbackSave(state, action);
    case SET_CONFIG_DATA:
      return setPageData(state, action);
    case CLONE_TAB_CONFIGURATIONS:
      return cloneDynamicTabRow(state, action);
    case CLONE_DEVICE_CONFIGURATIONS:
      return cloneDynamicDevice(state, action);
    case CLONE_PLATFORM_CONFIGURATIONS:
      return cloneDynamicPlatform(state, action);
    case RESET_ACTIVE_PAGE_CONFIGURATIONS:
      return resetActivePageData(state, action);
    case SWITCH_ROW_CONFIGURATION_PAGE:
      return switchConfigRows(state, action);
    default:
      return state;
  }
};

export default configurationsReducer;
