import React, { Component, Fragment } from "react";
import isEqual from "lodash/isEqual";
import Button from "../../component/button";
import AddUpdateSubCategory from "./addUpdateSubCategory";

class BrandsSubCategory extends Component {
  state = {
    showContent: { index: 0, show: false },
    newSubCat: false
  };

  UNSAFE_componentWillMount() {
    const {
      updateBrandSizeChart,
      changeSelectedSubCategory,
      selectedTab,
      selectedBrand,
      clearSubCategoryData
    } = this.props;
    changeSelectedSubCategory([]);
    clearSubCategoryData();
    const _subCatList =
      (updateBrandSizeChart[selectedTab].brandList &&
        updateBrandSizeChart[selectedTab].brandList.filter(
          item => item.brandId === selectedBrand.id
        )) ||
      [];
    if (_subCatList && !_subCatList.length) this.setState({ newSubCat: true });
  }

  toggleContent = (index, item) => {
    const {
      updateSubCategory,
      apiContent,
      selectedTab,
      changeSelectedSubCategory,
      updateSubCategoryData,
      handleBrandSubCategoryTileChange
    } = this.props;

    if (
      !isEqual(
        updateSubCategory,
        (apiContent &&
          apiContent.brandSizeChart &&
          apiContent.brandSizeChart[selectedTab] &&
          apiContent.brandSizeChart[selectedTab].brandList.filter(
            item =>
              item.brandId === updateSubCategory.brandId &&
              item.subCategoryId === updateSubCategory.subCategoryId
          )[0]) ||
          {}
      )
    ) {
      handleBrandSubCategoryTileChange(() =>
        this.handleToggleBrandSubCategoryTileChange(index, item)
      );
    } else {
      // changeSelectedSubCategory({
      //   id: item.subCategoryId,
      //   subcatname: item.subCategoryName
      // });
      changeSelectedSubCategory([]);
      updateSubCategoryData(item);
      const { showContent } = this.state;
      if (index === showContent.index) {
        this.setState({
          showContent: { index, show: !showContent.show },
          newSubCat: false
        });
      } else {
        this.setState({ showContent: { index, show: true }, newSubCat: false });
      }
    }
  };

  handleToggleBrandSubCategoryTileChange = (index, item) => {
    const { changeSelectedSubCategory, updateSubCategoryData } = this.props;
    // changeSelectedSubCategory({
    //   id: item.subCategoryId,
    //   subcatname: item.subCategoryName
    // });
    changeSelectedSubCategory([]);
    updateSubCategoryData(item);
    const { showContent } = this.state;
    if (index === showContent.index) {
      this.setState({
        showContent: { index, show: !showContent.show },
        newSubCat: false
      });
    } else {
      this.setState({ showContent: { index, show: true }, newSubCat: false });
    }
  };

  handleAnotherCategory = () => {
    const {
      apiContent,
      selectedTab,
      updateSubCategory,
      clearSubCategoryData,
      handleBrandSubCategoryTileChange
    } = this.props;

    if (
      !isEqual(
        updateSubCategory,
        (apiContent &&
          apiContent.brandSizeChart &&
          apiContent.brandSizeChart[selectedTab] &&
          apiContent.brandSizeChart[selectedTab].brandList.filter(
            item =>
              item.brandId === updateSubCategory.brandId &&
              item.subCategoryId === updateSubCategory.subCategoryId
          )[0]) ||
          {}
      )
    ) {
      handleBrandSubCategoryTileChange(this.handleBrandSubCategoryTileChange);
    } else {
      clearSubCategoryData();
      this.setState({
        newSubCat: true,
        showContent: { index: 0, show: false }
      });
    }
  };

  handleBrandSubCategoryTileChange = () => {
    this.props.clearSubCategoryData();
    this.setState({ newSubCat: true, showContent: { index: 0, show: false } });
  };

  closeBothContent = () =>
    this.setState({ showContent: { index: 0, show: false }, newSubCat: false });

  handleDeleteSubCategory = item => {
    const {
      countryId,
      selectedTab,
      apiContent,
      handleBrandSubCategoryDelete
    } = this.props;
    handleBrandSubCategoryDelete({
      id: apiContent._id,
      countryId,
      brandId: item.brandId,
      subCategoryId: item._id,
      type: selectedTab
    });
  };

  render() {
    const { showContent, newSubCat } = this.state;
    const {
      storeId,
      countryId,
      changeSelectedSubCategory,
      subCategoryList,
      fetchSubCategoryData,
      selectedSubCategory,
      selectedBrand,
      updateSubCategory,
      selectedTab,
      apiContent,
      submitSubCategoryList,
      changeHandler,
      updateBrandSizeChart,
      clearSubCategoryData,
      handleReset
    } = this.props;
    const _subCatList =
      (updateBrandSizeChart[selectedTab].brandList &&
        updateBrandSizeChart[selectedTab].brandList.filter(
          item => item.brandId === selectedBrand.id
        )) ||
      [];
    return (
      <Fragment>
        <div className="wrapper-box">
          <div className="data-wrapper">
            <div className="english-wrapper">
              <div className="english-content">
                <div className="form-group">
                  <label>Selected Brand</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedBrand.brandname}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {_subCatList.map((item, index) => (
          <div key={index} className="wrapper-box">
            <div className="heading-wrapper flex catalog-banner-heading">
              <h3>
                {(item.subCategory &&
                  item.subCategory.length &&
                  item.subCategory[0].label) ||
                  item.subCategoryName}
              </h3>
              <span
                className="delete-btn"
                onClick={() => this.handleDeleteSubCategory(item)}
              />
              <span
                onClick={() => this.toggleContent(index, item)}
                className={`toggle-banner ${
                  showContent.index === index && showContent.show
                    ? "minus-banner"
                    : "plus-banner"
                }`}
              />
            </div>
            {showContent.index === index && showContent.show && (
              <AddUpdateSubCategory
                isUpdate
                updateContent={item}
                subCategoryList={subCategoryList}
                selectedSubCategory={selectedSubCategory}
                changeSelectedSubCategory={changeSelectedSubCategory}
                fetchSubCategoryData={fetchSubCategoryData}
                updateSubCategory={updateSubCategory}
                countryId={countryId}
                selectedTab={selectedTab}
                apiContent={apiContent}
                selectedBrand={selectedBrand}
                submitSubCategoryList={submitSubCategoryList}
                changeHandler={changeHandler}
                storeId={storeId}
                clearSubCategoryData={clearSubCategoryData}
                closeBothContent={this.closeBothContent}
                handleReset={handleReset}
              />
            )}
          </div>
        ))}
        {newSubCat && (
          <Fragment>
            <h5 className="new-category-label">
              <span>Select Sub-Category </span>
              (Select the sub-category corresponding to the selected brand & add
              the size chart related details)
            </h5>
            <div className="wrapper-box">
              <AddUpdateSubCategory
                updateContent={{}}
                subCategoryList={subCategoryList}
                selectedSubCategory={selectedSubCategory}
                changeSelectedSubCategory={changeSelectedSubCategory}
                fetchSubCategoryData={fetchSubCategoryData}
                updateSubCategory={updateSubCategory}
                countryId={countryId}
                selectedTab={selectedTab}
                apiContent={apiContent}
                selectedBrand={selectedBrand}
                submitSubCategoryList={submitSubCategoryList}
                changeHandler={changeHandler}
                storeId={storeId}
                clearSubCategoryData={clearSubCategoryData}
                closeBothContent={this.closeBothContent}
                handleReset={handleReset}
              />
            </div>
          </Fragment>
        )}
        <Button
          name={"Add Another Sub Category"}
          onClick={this.handleAnotherCategory}
          disabled={false}
          customClass="marginBottom"
        />
      </Fragment>
    );
  }
}

export default BrandsSubCategory;
