import React, { Fragment, useState } from "react";

const PopupContent = ({ onConfirm, onCancel, message }) => (
  <Fragment>
    <div>{message || "Are you sure you want to delete the item?"}</div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={onConfirm}>
        <span className="btn-text">Yes</span>
      </button>
      <button className="popup-cancel" onClick={onCancel}>
        <span className="n-btn-text">No</span>
      </button>
    </div>
  </Fragment>
);

export default PopupContent;
