export const ADD_NEED_HELP_SEO = "ADD_NEED_HELP_SEO";
export const CHANGE_CATEGORY = "CHANGE_CATEGORY";
export const HANDLE_ACCORD_TEXT_CHANGE = "HANDLE_ACCORD_TEXT_CHANGE";
export const HANDLE_ACCORD_CHECK_BOX_CHANGE = "HANDLE_ACCORD_CHECK_BOX_CHANGE";
export const HANDLE_NEED_HELP_ACCORD_TILE = "HANDLE_NEED_HELP_ACCORD_TILE";
export const REMOVE_NEED_HELP_ACCORD_TILE = "REMOVE_NEED_HELP_ACCORD_TILE";
export const UPDATE_NEED_HELP_ACCORD_TILE_POSITION =
  "UPDATE_NEED_HELP_ACCORD_TILE_POSITION";
export const UPDATE_NEED_HELP_CONTENT = "UPDATE_NEED_HELP_CONTENT";
export const RESET_NEED_HELP_CONTENT = "RESET_NEED_HELP_CONTENT";
export const ENABLE_SUBMIT_BUTTON = "ENABLE_SUBMIT_BUTTON";
export const DISABLE_SUBMIT_BUTTON = "DISABLE_SUBMIT_BUTTON";
export const TOGGLE_RESET_BUTTON = "TOGGLE_RESET_BUTTON";
export const RESET_STATIC_ADDPAGE_CONTENT = "RESET_STATIC_ADDPAGE_CONTENT";
