import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import get from "lodash/get";
import cn from "classnames";
import { toastMsg, uploadImage } from "../../actions/common.action";
import { isUrlValid } from "../../util";

const customStyles = {
  content: {
    width: "390px",
    height: "420px",
    position: "absolute",
    top: "16%",
    left: "35%",
    right: "40px",
    bottom: "40px",
    borderRadius: "none",
    background: "rgb(255, 255, 255)",
    overflow: "auto"
  },

  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

const formHeight = {
  height: "316px"
};

class ImageSelectionModal extends React.Component {
  state = {
    imageData: {
      uploadedFile: "",
      cdnUrl: "",
      validCdnUrl: false,
      redirectionUrl: ""
    },
    isSubmitDisabled: true
  };

  UNSAFE_componentWillMount() {
    const { pos, imgData } = this.props;
    const imageData = imgData.find(item => item.pos === pos);
    this.setState({
      imageData: {
        validCdnUrl: !!(imageData && imageData.imageUrl),
        cdnUrl: get(imageData, "imageUrl", ""),
        redirectionUrl: get(imageData, "redirectionUrl", "")
      },
      isSubmitDisabled: !(imageData && imageData.imageUrl)
    });
  }

  validateSubmit = () => {
    const { imageData } = this.state;
    if (
      ((imageData.cdnUrl && imageData.validCdnUrl) || imageData.uploadedFile) &&
      imageData.redirectionUrl
    ) {
      this.setState({ isSubmitDisabled: false });
    } else {
      this.setState({ isSubmitDisabled: true });
    }
  };

  changeFileHandler = e => {
    const { toastMsg } = this.props;
    if (e.target.files[0].size / 1024 > 1024 * 10) {
      toastMsg("warning", "Maximum file size allowed 10Mb");
    } else if (e.target.files[0].type.split("/")[0] !== "image") {
      toastMsg("warning", "File must be of image type");
    } else if (e.target.files[0].type.split("/")[1].indexOf("tif") !== -1) {
      toastMsg("warning", ".tiff/.tif file extensions are not allowed.");
    } else {
      this.setState(
        {
          ["imageData"]: {
            ...this.state["imageData"],
            uploadedFile: e.target.files
          }
        },
        () => this.validateSubmit()
      );
    }
  };

  clearSelectedImage = () => {
    this.setState(
      {
        ["imageData"]: {
          ...this.state["imageData"],
          uploadedFile: ""
        }
      },
      () => this.validateSubmit()
    );
  };

  handleChange = e => {
    const {
      target: { name, value }
    } = e;
    this.setState(
      {
        imageData: {
          ...this.state["imageData"],
          [name]: value,
          validCdnUrl:
            name === "cdnUrl"
              ? isUrlValid(value)
              : this.state["imageData"].validCdnUrl
        }
      },
      () => this.validateSubmit()
    );
  };

  isDataValid = () => {
    const { imageData } = this.state;

    if (
      ((!imageData.cdnUrl &&
        imageData.uploadedFile &&
        imageData.uploadedFile[0].name) ||
        (imageData.cdnUrl && imageData.validCdnUrl)) &&
      imageData.redirectionUrl
    ) {
      return true;
    } else {
      return false;
    }
  };

  onSubmit = e => {
    e.preventDefault();
    const { imageData } = this.state;
    const {
      onSuccess,
      keyName,
      storeId,
      uploadImage,
      toastMsg,
      countryId,
      containerName,
      imgLang
    } = this.props;

    if (!this.isDataValid()) {
      toastMsg(
        "warning",
        "Kindly upload a file or add a valid image link and a redirectional url."
      );
      return;
    }

    if (imageData.cdnUrl) {
      onSuccess(imageData, imgLang);
    } else if (imageData.uploadedFile && imageData.uploadedFile[0]) {
      let _formData = new FormData();
      _formData.append("file", imageData.uploadedFile[0]);
      const _storeId = `${storeId}${Date.now()}`;
      uploadImage({
        brandId: Date.now(),
        keyName,
        language: imgLang,
        storeId: _storeId,
        countryId,
        containerName,
        _formData
      })
        .then(res => {
          if (
            res.data &&
            res.data.code &&
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.result &&
            res.data.data.result.url
          ) {
            imageData.cdnUrl = res.data.data.result.url;
            onSuccess(imageData, imgLang);
          } else {
            toastMsg("error", "Unable to upload image.");
            return;
          }
        })
        .catch(() => toastMsg("error", "Unable to upload image."));
    }
  };

  render() {
    const { isSubmitDisabled, imageData } = this.state;
    const { modalName, onHide, title } = this.props;
    return (
      <div>
        <Modal
          isOpen={true}
          ariaHideApp={false}
          onRequestClose={() => onHide(modalName, null, null)}
          style={customStyles}
          closeTimeoutMS={2000}
        >
          <form style={formHeight} onSubmit={() => this.isDataValid()}>
            <div className="close-btn">
              <span
                onClick={() => onHide(modalName, null, null)}
                className="close-icon"
              />
            </div>
            <span className="modal-heading">{title || "Upload Image"} </span>
            {imageData.uploadedFile ? (
              <div className="file-upload-container">
                <input
                  type="text"
                  className="file-upload"
                  value={imageData.uploadedFile[0].name}
                  readOnly
                />
                <span
                  onClick={e => this.clearSelectedImage(e)}
                  className="file-close-icon"
                />
              </div>
            ) : (
              <div
                className={cn("input-group input-grp-height marginTop-20", {
                  "input-file-disable": imageData.cdnUrl
                })}
              >
                <div className="input-group-prepend custom-input"></div>
                <div className="custom-file custom-file-selection">
                  <input
                    type="file"
                    className="custom-file-input form-control "
                    id="uploadFile"
                    aria-describedby="inputGroupFileAddon01"
                    onChange={e => this.changeFileHandler(e)}
                  />
                  <label
                    htmlFor="inputGroupFile01"
                    className={cn("custom-file-label file-label", {
                      "input-file-disable input-disable": imageData.cdnUrl
                    })}
                  >
                    Choose file
                  </label>
                </div>
              </div>
            )}
            <div className="marginTop-20 m-b-20 text-align">
              <span className="or-content"> OR </span>
            </div>
            <input
              type="text"
              name="cdnUrl"
              className="form-control m-b-20 marginTop-20 no-border-radius "
              placeholder="Image Link"
              disabled={!!imageData.uploadedFile}
              value={imageData && imageData.cdnUrl}
              onChange={e => this.handleChange(e)}
            />
            <div className="marginTop-20 m-b-20 text-align"></div>
            <input
              type="text"
              name="redirectionUrl"
              className="form-control m-b-20 marginTop-20 no-border-radius "
              placeholder="Redirection Link"
              value={imageData && imageData.redirectionUrl}
              onChange={e => this.handleChange(e)}
            />

            <button
              className={cn("confirm-btn marginTop-20", {
                "btn-disabled": isSubmitDisabled
              })}
              disabled={isSubmitDisabled}
              onClick={e => this.onSubmit(e)}
            >
              <span className="btn-text">Submit</span>
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = {
  toastMsg,
  uploadImage
};

export default connect(null, mapDispatchToProps)(ImageSelectionModal);
