import React, { useEffect, useState } from "react";
import { LinearProgressWithLabel } from '../materialUi/components';

const CustomloadingIndicator = ({ showLoader = false, loaderClassName = "", countdown = 0, id }) => {
  const MAX_TIMER_PERCENTAGE = 100;
  const [timer, setTimer] = useState(0);
  const percentageIncPerSec = Math.floor(MAX_TIMER_PERCENTAGE / countdown);
  useEffect(() => {
    if (showLoader && timer < MAX_TIMER_PERCENTAGE && id) {
      setTimeout(() => {
        setTimer(timer + percentageIncPerSec);
      }, 1000);
    }
  }, [timer]);

  useEffect(() => {
    setTimer(0);
  }, [id]);


  useEffect(() => {
    if (showLoader && timer < MAX_TIMER_PERCENTAGE && id) {
      setTimeout(() => {
        setTimer(timer + percentageIncPerSec);
      }, 1000);
    }
  }, [id, showLoader]);

  if (!showLoader || !id) return null;

  return (
    <div className={loaderClassName}>
      <LinearProgressWithLabel value={timer} />
    </div>
  );
};

export default CustomloadingIndicator;
