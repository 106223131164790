import { combineReducers } from "redux";
import { reducer as notifications } from "react-notification-system-redux";
import common from "./common.reducer";
import authentication from "./auth.reducer";
import userManagement from "./userManagement.reducer";
import store from "./store.reducer";
import staticReducer from "./staticPage.reducer";
import urlCreation from "./urlCreation.reducer";
import megaMenu from "./megaMenu.reducer";
import footer from "./footer.reducer";
import needHelp from "./needHelp.reducer";
import sizeChart from "./sizeChart.reducer";
import selectedCountryId from "./selectedCountryId.reducer";
import selectedStoreIds from "./selectedStoreIds.reducer";
import pagesPageReducer from "./pagesPage.reducer";
import sectionsReducer from "./sections.reducer";
import configurationsReducer from "./configurations.reducer";
import roleManagement from "./roleManagement.reducer";
import permissions from "./permissions.reducer";
import navMenuManagement from "./navMenuManagement.reducer";
import flashSaleReducer from "./flashSale.reducer";
import flashSaleV2Reducer from "./flashSaleV2.reducer";

const rootReducer = combineReducers({
  notifications,
  common,
  authentication,
  store,
  userManagement,
  staticReducer,
  urlCreation,
  megaMenu,
  footer,
  needHelp,
  sizeChart,
  selectedCountryId,
  selectedStoreIds,
  pagesPageReducer,
  sectionsReducer,
  configurationsReducer,
  roleManagement,
  permissions,
  navMenuManagement,
  flashSaleReducer,
  flashSaleV2Reducer,
});

export default rootReducer;
