import * as React from "react";

export const Up = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 48 48"
      {...props}
    >
      <path d="M18.8 5C8.3 8.1 2.2 18.7 5 29.1c1.6 5.8 8.1 12.3 13.9 13.9 14.4 3.9 28-9.6 24.1-24.1-1.5-5.6-8-12.3-13.5-13.8-4.6-1.2-6.9-1.3-10.7-.1zM30 20.5c5.1 5.2 5.3 5.6 3.7 7.2-1.6 1.6-1.9 1.5-5.7-2.2l-4-3.9-4 3.9c-3.8 3.7-4.1 3.8-5.7 2.2-1.6-1.6-1.4-2 3.7-7.2 3-3 5.7-5.5 6-5.5.3 0 3 2.5 6 5.5z" />
    </svg>
  );
};
