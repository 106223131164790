import React from "react";
import { LinearProgress, Backdrop } from "../materialUi/components"
const LoadingIndicator = ({ showLoader = false }) =>
  showLoader && (
    // <div>
    //   <div className="loader-wrapper" />
    //   <div className="loader-container">
    //     <div className="loader" />
    //   </div>
    // </div>
    <Backdrop
      sx={{ opacity: "0.5", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <LinearProgress sx={{ width: "10%" }} />
    </Backdrop>
  );

export default LoadingIndicator;
