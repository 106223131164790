import React from "react";
import { connect } from "react-redux";
import { IMG_SIZE_IN_BYTES } from "../../constants";
import {
  Dialog, DialogActions, DialogContent,
  DialogTitle, Divider, Box, Button,
  TextField, InputAdornment
} from "../../materialUi/components"
import { CloseIcon, FileUploadIcon } from "../../materialUi/icons"
import { toastMsg, uploadImage } from "../../actions/common.action";

class ImageSelectionModal extends React.Component {
  inputFileRef = React.createRef();
  state = {
    uploadedFile: "",
    cdnURL: "",
    validCdnUrl: false,
    regex: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/
  };
  /**
   * Inputs(file) change handler
   */
  changeFileHandler = e => {
    const { toastMsg } = this.props;
    if (e.target.files[0].size / 1024 ** 2 > 10) {
      toastMsg("warning", "Maximum file size allowed 10Mb");
    } else if (e.target.files[0].type.split("/")[0] !== "image") {
      toastMsg("warning", "File must be of image type");
    } else if (e.target.files[0].type.split("/")[1].indexOf("tif") !== -1) {
      toastMsg("warning", ".tiff/.tif file extensions are not allowed.");
    } else {
      this.setState({
        uploadedFile: e.target.files
      });
    }
  };

  clearSelectedImage = () => {
    this.setState({
      uploadedFile: ""
    });
  };

  handleChange = e => {
    const { regex } = this.state;
    this.setState({
      cdnURL: e.target.value,
      validCdnUrl: e.target.value.match(regex)
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const { uploadedFile, cdnURL, validCdnUrl } = this.state;
    const {
      onSuccess,
      keyName,
      brandId,
      language,
      modalName,
      uploadImage,
      storeId,
      toastMsg,
      countryId,
      containerName,
      imageFormatArr
    } = this.props;
    if (!cdnURL && !(uploadedFile && uploadedFile[0].name)) {
      toastMsg("warning", "Kindly upload a file or add a image link.");
      return;
    } else if (cdnURL && !validCdnUrl) {
      toastMsg("warning", "Kindly enter a valid image link.");
      return;
    } else if (
      uploadedFile &&
      imageFormatArr.includes(uploadedFile[0].type) &&
      uploadedFile[0].size >= IMG_SIZE_IN_BYTES.PNG_SIZE
    ) {
      toastMsg("error", "Kindly upload an image size below 1MB.");
      return;
    } else if (
      uploadedFile &&
      uploadedFile[0].type === "image/gif" &&
      uploadedFile[0].size >= IMG_SIZE_IN_BYTES.GIF_SIZE
    ) {
      toastMsg("error", "Kindly upload an image size below 1MB.");
      return;
    } else if (
      uploadedFile &&
      uploadedFile[0].type === "image/svg+xml" &&
      uploadedFile[0].size >= IMG_SIZE_IN_BYTES.SVG_SIZE
    ) {
      toastMsg("error", "Kindly upload an image size less than 3KB.");
      return;
    }

    if (cdnURL) {
      onSuccess(
        keyName,
        `${cdnURL}?burst=${Date.now()}`,
        brandId,
        language,
        modalName,
        ""
      );
    } else if (
      uploadedFile &&
      imageFormatArr.includes(uploadedFile[0].type) &&
      uploadedFile[0].size <= IMG_SIZE_IN_BYTES.PNG_SIZE
    ) {
      let _formData = new FormData();
      _formData.append("file", uploadedFile[0]);

      let _brandId = brandId;
      let fileName = uploadedFile?.[0]?.name || "";
      const index = fileName.lastIndexOf('.');
      fileName = fileName.slice(0, index);
      if (containerName != "storeList") {
        _brandId = `${brandId}${+new Date()}`;
      }

      uploadImage({
        brandId: _brandId,
        keyName,
        language,
        storeId,
        countryId,
        containerName,
        _formData
      }).then(res => {
        if (
          res.data?.code == 200 &&
          res.data?.data?.result?.url
        ) {
          onSuccess(
            keyName,
            `${res.data.data.result.url}?burst=${Date.now()}`,
            brandId,
            language,
            modalName,
            fileName || ""
          );
        } else {
          toastMsg("error", "Unable to upload image.");
          return;
        }
      });
    } else if (uploadedFile &&
      !imageFormatArr.includes(uploadedFile[0].type)) {
      toastMsg("error", `Kindly upload an image of type ${imageFormatArr}`);
    }
  };

  render() {
    let { uploadedFile, cdnURL, validCdnUrl } = this.state;
    const { title, isOpen, handleClose, disableCDNuploadOption } = this.props;
    return (
      <div>
        <Dialog open={isOpen} onClose={handleClose} maxWidth={"sm"} fullWidth>
          <DialogTitle>
            {title || "Upload Image"}
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              <Box style={{ "display": "flex" }}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="fileName"
                  label="File Name"
                  type="text"
                  fullWidth
                  value={uploadedFile?.[0]?.name || ""}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <Button onClick={() => this.clearSelectedImage()}>
                          <CloseIcon />
                        </Button>
                      </InputAdornment>
                  }}
                  aria-readonly={true}
                  disabled={true}
                  placeholder="Choose file"
                />
                <TextField
                  id="uploadFile"
                  type="file"
                  fullWidth
                  inputRef={this.inputFileRef}
                  onChange={e => {
                    this.changeFileHandler(e);
                  }}
                  style={{ "display": "none" }}
                />
                <Button
                  onClick={() => {
                    this.inputFileRef.current.click()
                  }}
                  disabled={!!cdnURL}
                >
                  <FileUploadIcon fontSize="small" />
                </Button>
              </Box>
              <Divider variant="fullWidth" style={{ "marginBottom": "10px", "marginTop": "10px" }}>OR</Divider>
              <TextField
                autoFocus
                name="imageCdn"
                margin="dense"
                id="imageCdn"
                label="Image Link"
                type="text"
                fullWidth
                value={cdnURL || ""}
                onChange={this.handleChange}
                disabled={disableCDNuploadOption || !!uploadedFile}
                placeholder="Image Link"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" sx={{ "marginRight": "auto" }}>
              Close
            </Button>
            <Button
              onClick={e => {
                this.onSubmit(e);
              }}
              disabled={!!(!(cdnURL || uploadedFile) || (cdnURL && !validCdnUrl))}
              variant="outlined">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brandId: state.store.brandId || state.store.storeId,
  countryId: state.store.countryId,
  storeId: state.store.storeId
});

const mapDispatchToProps = {
  toastMsg,
  uploadImage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageSelectionModal);
