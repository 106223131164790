export const FOOTER_STATIC_CONTENT_PLACEHOLDERS = {
  arabic: {
    connect: "أدخل معلومات الاتصال الاجتماعي",
    copyRight: "أدخل معلومات حقوق النشر",
    csEmail: "أدخل البريد الإلكتروني لدعم العملاء",
    csHeading: "أدخل عنوان قسم دعم العملاء",
    csNumber: "أدخل رقم الهاتف لدعم العملاء",
    csTime: "أدخل توقيت لدعم العملاء",
    redirectLink: "أدخل رابط إعادة التوجيه للصورة الاجتماعية"
  },
  english: {
    connect: "Enter social connection info",
    copyRight: "Enter copy right info",
    csEmail: "Enter email for customer support",
    csHeading: "Enter heading of customer support section",
    csNumber: "Enter phone number for customer support",
    csTime: "Enter timings for customer support",
    redirectLink: "Enter redirection link for social image"
  }
};

export const FOOTER_ADD_ATTRIBUTE_FORM_PLACEHOLDERS = {
  arabic: {
    title: "أدخل عنوان الصفحة",
    redirectionLink: "أدخل عنوان URL لإعادة التوجيه"
  },
  english: {
    title: "Enter Page Title",
    redirectionLink: "Enter Redirection Url (must start with '/')"
  }
};
