import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import {
  IMAGE_CLASS_TYPE_LIST,
  LANGUAGE,
  MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES,
  OPTIONAL_SELECT_ATTRIBUTE_MESSAGES
} from "../../constants";
import { isObject, isArray } from "../../util";
import { findMegaMenuItem } from "../../util/megaMenu";
import {
  changeImgColType,
  handleImageType,
  updateImageData,
  removeImage,
  clearAllImages,
  addNewMegaMenuScheduler,
  deleteMegaMenuScheduler,
  handleSchedulerImageType,
  clearAllSchedulerImages,
  handleSchedulerImageData,
  updateSchedulerImageData,
  removeSchedulerImage
} from "../../actions/megaMenu.action";
import ConfirmModal from "../modal/ConfirmModal";
import ColumnImageContent from "./columnImageContent";
import MegaMenuImageModal from "./megaMenuImageModal";
import Accordion from "../../component/accordion";
import MegaMenuItem from "./megaMenuItem";
import Scheduler from "../../component/scheduler";

class OptionalSelectAttribute extends Component {
  state = {
    className: "",
    confirmModal: false,
    currenTab: "",
    disabledTab: "",
    imgClassList: [],
    imgLang: "",
    imgType: "",
    isTabChangedModal: false,
    openImageModal: false,
    openTab: "",
    pos: ""
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { columnName, updateEnglishContent, updateArabicContent } = nextProps;
    const { disabledTab } = this.state;
    let _disabledTab = "";
    if (
      isObject(updateArabicContent) &&
      isObject(updateEnglishContent) &&
      isObject(updateEnglishContent[columnName]) &&
      updateEnglishContent[columnName].imgType
    ) {
      _disabledTab = MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.ATTRIBUTE;
    } else if (
      isObject(updateArabicContent) &&
      isObject(updateEnglishContent) &&
      isArray(updateEnglishContent[columnName]) &&
      updateEnglishContent[columnName].length
    ) {
      _disabledTab = MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.IMAGE;
    }
    if (disabledTab !== _disabledTab) {
      this.disableTab(_disabledTab);
    }
  }
  UNSAFE_componentWillMount() {
    const {
      columnName,
      colValue,
      handleImageType,
      selectedImgCol,
      updateArabicContent,
      updateEnglishContent
    } = this.props;

    if (selectedImgCol !== null && colValue === selectedImgCol) {
      this.setState({
        openTab: MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.IMAGE
      });
    } else {
      this.setState({
        openTab: MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.ATTRIBUTE
      });
    }
    if (
      isObject(updateArabicContent) &&
      isObject(updateEnglishContent) &&
      updateEnglishContent[columnName]
    ) {
      const _imgType = updateEnglishContent[columnName].imgType;
      if (_imgType) {
        this.setState({
          openTab: "image",
          imgType: _imgType,
          imgClassList:
            IMAGE_CLASS_TYPE_LIST[columnName][_imgType - 1].imgClassList
        });
        this.disableTab(MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.ATTRIBUTE);
        handleImageType(
          _imgType,
          columnName,
          IMAGE_CLASS_TYPE_LIST[columnName][_imgType - 1].imgClassList
        );
      } else if (
        isObject(updateArabicContent) &&
        isObject(updateEnglishContent) &&
        isArray(updateEnglishContent[columnName]) &&
        updateEnglishContent[columnName].length
      ) {
        this.disableTab(MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.IMAGE);
      }
    }
  }

  disableTab = type => {
    this.setState({ disabledTab: type || "" });
  };

  changeTab = (tabType, colValue) => {
    const { disabledTab, openTab } = this.state;
    if (disabledTab === "") {
      this.setState({ openTab: tabType });
      this.changeImgColType(tabType, colValue);
    } else if (openTab !== tabType) {
      this.setState({
        confirmModal: true,
        isTabChangedModal: true,
        currenTab: tabType
      });
    }
  };

  changeImgColType = (tabType, colValue) => {
    const { columnName, changeImgColType } = this.props;
    if (tabType === MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.IMAGE) {
      changeImgColType(colValue, columnName);
    } else if (
      tabType === MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.ATTRIBUTE
    ) {
      changeImgColType(null, columnName);
    }
    this.setState({ openTab: tabType });
  };

  confirmModal = () => {
    const { imgType, imgClassList, isTabChangedModal, currenTab } = this.state;
    const { columnName, colValue, handleImageType } = this.props;
    if (isTabChangedModal) {
      this.changeImgColType(currenTab, colValue);
    } else {
      handleImageType(imgType, columnName, imgClassList);
    }
    this.setState({
      confirmModal: false,
      imgClassList: [],
      isTabChangedModal: false
    });
  };

  hideConfirmModal = () =>
    this.setState({ confirmModal: false, isTabChangedModal: false });

  handleDefaultImageType = ({
    englishListItem,
    arabicListItem,
    imgType,
    imgClassList
  }) => {
    const {
      handleImageType,
      columnName,
      updateEnglishContent,
      updateArabicContent
    } = this.props;
    if (
      get(englishListItem, `[${columnName}].imgType`, "undefined1") ===
        get(updateEnglishContent, `[${columnName}].imgType`, "undefined2") &&
      get(arabicListItem, `[${columnName}].imgType`, "undefined1") ===
        get(updateArabicContent, `[${columnName}].imgType`, "undefined2")
    ) {
      if (
        isEqual(
          englishListItem[columnName],
          updateEnglishContent[columnName]
        ) &&
        isEqual(arabicListItem[columnName], updateArabicContent[columnName])
      ) {
        handleImageType(imgType, columnName, imgClassList);
      } else {
        this.setState({ confirmModal: true, imgType, imgClassList });
      }
    } else if (
      get(updateEnglishContent, `[${columnName}].images.length`) ||
      get(updateArabicContent, `[${columnName}].images.length`)
    ) {
      this.setState({ confirmModal: true, imgType, imgClassList });
    } else {
      handleImageType(imgType, columnName, imgClassList);
    }
  };

  handleSchedulerImageType = ({
    englishListItem,
    arabicListItem,
    imgType,
    imgClassList,
    schedulerIndex
  }) => {
    const {
      handleSchedulerImageType,
      columnName,
      updateEnglishContent,
      updateArabicContent
    } = this.props;
    const getPropForSchedulerImgType = `[${columnName}].schedulers[${schedulerIndex}].imgType`;

    if (
      get(englishListItem, getPropForSchedulerImgType, "undefined1") ===
        get(updateEnglishContent, getPropForSchedulerImgType, "undefined2") &&
      get(arabicListItem, getPropForSchedulerImgType, "undefined1") ===
        get(updateArabicContent, getPropForSchedulerImgType, "undefined2")
    ) {
      if (
        isEqual(
          get(englishListItem, `[${columnName}].schedulers[${schedulerIndex}]`),
          get(
            updateEnglishContent,
            `[${columnName}].schedulers[${schedulerIndex}]`
          )
        ) &&
        isEqual(
          get(arabicListItem, `[${columnName}].schedulers[${schedulerIndex}]`),
          get(
            updateArabicContent,
            `[${columnName}].schedulers[${schedulerIndex}]`
          )
        )
      ) {
        handleSchedulerImageType({
          imgType,
          columnName,
          imgClassList,
          schedulerIndex
        });
      } else {
        this.setState({ confirmModal: true, imgType, imgClassList });
      }
    } else if (
      get(
        updateEnglishContent,
        `[${columnName}].schedulers[${schedulerIndex}].images.length`
      ) ||
      get(
        updateArabicContent,
        `[${columnName}].schedulers[${schedulerIndex}].images.length`
      )
    ) {
      this.setState({ confirmModal: true, imgType, imgClassList });
    } else {
      handleSchedulerImageType({
        imgType,
        columnName,
        imgClassList,
        schedulerIndex
      });
    }
  };

  handleImageType = (imgType, { imgClassList }, schedulerIndex) => {
    const {
      arabicList,
      englishList,
      updateArabicContent,
      updateEnglishContent
    } = this.props;
    const englishListItem = findMegaMenuItem(englishList, updateEnglishContent);
    const arabicListItem = findMegaMenuItem(arabicList, updateArabicContent);
    const handlingData = {
      englishListItem,
      arabicListItem,
      imgType,
      imgClassList
    };
    schedulerIndex >= 0
      ? this.handleSchedulerImageType({ ...handlingData, schedulerIndex })
      : this.handleDefaultImageType(handlingData);
  };

  updateImageData = (imageData, imgLang) => {
    const { pos, className, currentScheduler } = this.state;
    const {
      columnName,
      updateImageData,
      updateSchedulerImageData
    } = this.props;
    const imgData = {
      imageUrl: imageData.cdnUrl,
      redirectionUrl: imageData.redirectionUrl,
      pos,
      className
    };
    currentScheduler >= 0
      ? updateSchedulerImageData({
          columnName,
          imgData,
          imgLang,
          schedulerIndex: currentScheduler
        })
      : updateImageData(columnName, imgData, imgLang);
    this.handleCloseModal();
  };

  handleCloseModal = () => this.setState({ openImageModal: false });

  openUploadImageModal = (data, pos, imgLang, schedulerIndex) => {
    this.setState({
      className: data,
      imgLang,
      openImageModal: true,
      pos,
      currentScheduler: schedulerIndex
    });
  };

  removeImage = (pos, imgLang, schedulerIndex) => {
    const { columnName, removeImage, removeSchedulerImage } = this.props;
    schedulerIndex >= 0
      ? removeSchedulerImage({ pos, imgLang, schedulerIndex, columnName })
      : removeImage(columnName, pos, imgLang);
  };

  clearAllImages = schedulerIndex => {
    const { columnName, clearAllImages, clearAllSchedulerImages } = this.props;
    schedulerIndex >= 0
      ? clearAllSchedulerImages({ schedulerIndex, columnName })
      : clearAllImages(columnName);
  };

  getModalImageData = () => {
    const { imgLang, currentScheduler } = this.state;
    const {
      updateEnglishContent,
      columnName,
      updateArabicContent
    } = this.props;
    const updateData =
      imgLang === LANGUAGE.ENGLISH ? updateEnglishContent : updateArabicContent;

    if (currentScheduler >= 0) {
      return get(
        updateData,
        `[${columnName}].schedulers[${currentScheduler}].images`,
        []
      );
    }

    return get(updateData, `[${columnName}].images`, []);
  };

  render() {
    const {
      confirmModal,
      disabledTab,
      imgLang,
      openImageModal,
      openTab,
      pos
    } = this.state;
    const {
      columnName,
      colValue,
      countryId,
      imgClassList,
      selectedImgCol,
      storeId,
      updateArabicContent,
      updateEnglishContent,
      addNewMegaMenuScheduler,
      deleteMegaMenuScheduler,
      handleSchedulerImageData
    } = this.props;

    const showImageTab =
      openTab === MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.IMAGE;

    const showAttributeTab =
      openTab === MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.ATTRIBUTE ||
      (!showImageTab && selectedImgCol > colValue);

    const showTabsSelector =
      selectedImgCol === null || selectedImgCol === colValue;

    return (
      <Fragment>
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideConfirmModal}
            confirm={this.confirmModal}
            message={OPTIONAL_SELECT_ATTRIBUTE_MESSAGES.SAVED_DATA_LOSS}
          />
        )}
        {openImageModal && (
          <MegaMenuImageModal
            title="Upload Image"
            modalName="imageSelectionModal"
            keyName="imageLink"
            storeId={storeId}
            countryId={countryId}
            containerName="megaMenu"
            onHide={this.handleCloseModal}
            onSuccess={this.updateImageData}
            pos={pos}
            imgLang={imgLang}
            imgData={this.getModalImageData()}
          />
        )}
        {showTabsSelector && (
          <div className="tab">
            <button
              className={cn({
                tablinks: true,
                active: showAttributeTab,
                disabledTab:
                  disabledTab ===
                  MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.ATTRIBUTE
              })}
              onClick={() =>
                this.changeTab(
                  MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.ATTRIBUTE,
                  colValue
                )
              }
            >
              Add Menu Item
            </button>
            <button
              className={cn({
                tablinks: true,
                active: showImageTab,
                disabledTab:
                  disabledTab ===
                  MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.IMAGE
              })}
              onClick={() =>
                this.changeTab(
                  MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES.IMAGE,
                  colValue
                )
              }
            >
              Add Image
            </button>
          </div>
        )}
        {showImageTab && (
          <div className="tabcontent" style={{ minHeight: "350px" }}>
            <Accordion defaultIsOpen={true} title="Default">
              <ColumnImageContent
                selectedImgIndex={get(
                  updateEnglishContent,
                  `[${columnName}].imgType`,
                  null
                )}
                columnName={columnName}
                imageList={IMAGE_CLASS_TYPE_LIST[columnName]}
                handleImageType={this.handleImageType}
                openUploadImageModal={this.openUploadImageModal}
                removeImage={this.removeImage}
                clearAllImages={this.clearAllImages}
                imgClassList={imgClassList}
                arabicImagesContent={get(
                  updateArabicContent,
                  `[${columnName}].images`,
                  []
                )}
                englishImagesContent={get(
                  updateEnglishContent,
                  `[${columnName}].images`,
                  []
                )}
              />
            </Accordion>
            <Scheduler
              content={get(updateEnglishContent, `${columnName}`)}
              addNewScheduler={() => addNewMegaMenuScheduler({ columnName })}
              deleteScheduler={(_type, schedulerIndex) =>
                deleteMegaMenuScheduler({ columnName, schedulerIndex })
              }
              childContent={[]}
              handleToggleChange={(_type, schedulerIndex, value) =>
                handleSchedulerImageData({
                  columnName,
                  schedulerIndex,
                  dataKey: "enabled",
                  data: value
                })
              }
              handleDateChange={(schedulerIndex, id, timeStamp) =>
                handleSchedulerImageData({
                  columnName,
                  schedulerIndex,
                  dataKey: id,
                  data: timeStamp
                })
              }
              schedulerItemRenderer={schedulerIndex => (
                <ColumnImageContent
                  selectedImgIndex={get(
                    updateEnglishContent,
                    `[${columnName}].schedulers[${schedulerIndex}].imgType`,
                    null
                  )}
                  columnName={columnName}
                  imageList={IMAGE_CLASS_TYPE_LIST[columnName]}
                  handleImageType={(imgType, imgClassListData) =>
                    this.handleImageType(
                      imgType,
                      imgClassListData,
                      schedulerIndex
                    )
                  }
                  openUploadImageModal={(data, pos, imgLang) =>
                    this.openUploadImageModal(
                      data,
                      pos,
                      imgLang,
                      schedulerIndex
                    )
                  }
                  removeImage={(pos, imgLang) =>
                    this.removeImage(pos, imgLang, schedulerIndex)
                  }
                  clearAllImages={() => this.clearAllImages(schedulerIndex)}
                  imgClassList={get(
                    updateEnglishContent,
                    `[${columnName}].schedulers[${schedulerIndex}].imgClassList`,
                    null
                  )}
                  arabicImagesContent={get(
                    updateArabicContent,
                    `[${columnName}].schedulers[${schedulerIndex}].images`,
                    []
                  )}
                  englishImagesContent={get(
                    updateEnglishContent,
                    `[${columnName}].schedulers[${schedulerIndex}].images`,
                    []
                  )}
                />
              )}
            />
          </div>
        )}
        {showAttributeTab && (
          <div className="tabcontent">
            <MegaMenuItem showCopyDropdown={false} columnName={columnName} />
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  arabicList: state.megaMenu.arabic,
  countryId: state.store.countryId,
  englishList: state.megaMenu.english,
  imgClassList: state.megaMenu.imgClassList,
  selectedImgCol: state.megaMenu.selectedImgCol,
  storeId: state.store.storeId,
  updateArabicContent: state.megaMenu.updateArabicContent,
  updateEnglishContent: state.megaMenu.updateEnglishContent
});

const mapDispatchToProps = {
  changeImgColType,
  clearAllImages,
  handleImageType,
  removeImage,
  updateImageData,
  addNewMegaMenuScheduler,
  deleteMegaMenuScheduler,
  handleSchedulerImageType,
  clearAllSchedulerImages,
  handleSchedulerImageData,
  updateSchedulerImageData,
  removeSchedulerImage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionalSelectAttribute);
