export const URL_PAGE_TYPE_DROPDOWN = {
  productListing: "Product Listing",
  productCatalog: "Product Catalog",
  static: "Static",
  staticAccordion: "Static Accordion",
  flashSale: "Flash Sale"
};
export const URL_PAGE_TYPE = [
  {
    label: "Product Listing",
    value: URL_PAGE_TYPE_DROPDOWN.productListing
  },
  // {
  //   label: "Product Catalog",
  //   value: URL_PAGE_TYPE_DROPDOWN.productCatalog
  // },
  // {
  //   label: "Static",
  //   value: URL_PAGE_TYPE_DROPDOWN.static
  // },
  // {
  //   label: "Static Accordion",
  //   value: URL_PAGE_TYPE_DROPDOWN.staticAccordion
  // },
  {
    label: "Flash Sale",
    value: URL_PAGE_TYPE_DROPDOWN.flashSale
  }
];
export const URL_PAGE_PANEL = {
  URL_EDIT_PAGE_DISPLAY: "urlEditPageDisplay",
  URL_ADD_PAGE_DISPLAY: "urlAddPageDisplay",
};

export const URL_PAGE_MESSAGES = {
  en_title_miss: "English Title Missing",
  ar_title_miss: "Arabic Title Missing",
  page_title_miss: "Page Title Missing",
  page_desc_miss: "Page Description Missing",
  page_type_miss: "Please select page type",
  invalid_filter_con: "Please enter valid Filter Condition",
  invalid_url: "Please enter valid SEO Friendly URL",
  invalid_url_brands: "SEO Friendly URL shouldn't contain /brands/ or /brand/",
  invalid_url_end: "SEO Friendly URL must end with /",
  invalid_product_count_message: "Unable to fetch Product Count Info",
  page_content_miss: "Page Content Is required",
  url_in_use: "SEO Friendly URL already in use",
  en_title_seo_miss: "English Title Missing in General Seo",
  ar_title_seo_miss: "Arabic Title Missing in General Seo",
  en_meta_seo_miss: "English MetaKeyword Missing in General Seo",
  ar_meta_seo_miss: "Arabic MetaKeyword Missing in General Seo",
  en_meta_des_seo_miss: "English MetaDescription Missing in General Seo",
  ar_meta_des_seo_miss: "Arabic MetaDescription Missing in General Seo",
  en_h1_seo_miss: "English HeadingH1 Missing in General Seo",
  ar_h1_seo_miss: "Arabic HeadingH1 Missing in General Seo",
  en_h2_seo_miss: "English HeadingH2 Missing in General Seo",
  ar_h2_seo_miss: "Arabic HeadingH2 Missing in General Seo"
}

export const URL_COMPONENT_TYPES = {
  BANNER: "banner",
  PRODUCT_CAROUSEL: "product-carousel",
  IMAGE_CAROUSEL: "catalog-carousel",
  DYNAMIC_SLIDER: "dynamicSliderModule",
  DYNAMIC_BANNER: "dynamicBannerModule"
};

export const URL_TYPES = {
  CUSTOM: "custom",
  SYSGENERATED: "sysGenerated",
  PROTECTED: "protected",
};

export const urlKeyFormatMap = {
  "hierarchicalCategories.lvl0": "p_cat",
  "hierarchicalCategories.lvl1": "cat",
  "hierarchicalCategories.lvl2": "sub_cat",
  "categories-0": "p_cat",
  "categories-1": "cat",
  "categories-2": "sub_cat",
  new: "is_new",
  style: "attr_style",
  bed_size: "attr_bed_size",
  for_gift: "attr_for_gift",
  occasion: "attr_occasion",
  suit_fit: "attr_suit_fit",
  ideal_for: "attr_ideal_for",
  shirt_fit: "attr_shirt_fit",
  suit_type: "attr_suit_type",
  toe_style: "attr_toe_style",
  top_style: "attr_top_style",
  bottom_fit: "attr_bottom_fit",
  frame_type: "attr_frame_type",
  heel_style: "attr_heel_style",
  shoe_width: "attr_shoe_width",
  dress_style: "attr_dress_style",
  frame_shape: "attr_frame_shape",
  short_style: "attr_short_style",
  color_family: "attr_color_family",
  color_family: "attr_color_family",
  shoe_closure: "attr_shoe_closure",
  jumpsuit_type: "attr_jumpsuit_type",
  lens_material: "attr_lens_material",
  sleeve_length: "attr_sleeve_length",
  sweater_style: "attr_sweater_style",
  type_of_short: "attr_type_of_short",
  type_of_skirt: "attr_type_of_skirt",
  type_of_sport: "attr_type_of_sport",
  watch_feature: "attr_watch_feature",
  toys_age_group: "attr_toys_age_group",
  types_of_watch: "attr_types_of_watch",
  age_recommended: "attr_age_recommended",
  sportswear_type: "attr_sportswear_type",
  underwear_style: "attr_underwear_style",
  type_of_swimwear: "attr_type_of_swimwear",
  type_of_sleepwear: "attr_type_of_sleepwear",
  type_of_soft_toys: "attr_type_of_soft_toys",
  bag_lining_material: "attr_bag_lining_material",
  type_of_action_toys: "attr_type_of_action_toys",
  type_of_electronic_toys: "attr_type_of_electronic_toys",
  type_of_construction_toys: "attr_type_of_construction_toys",
  "color": "attr_color_family",
  "types_of_neckline_&_collar": "attr_types_of_neckline_and_collar",
  "types_of_arts_&_creative_play": "attr_types_of_arts_and_creative_play",
  "types_of_baby_&_pre-school_toys": "attr_types_of_baby_and_pre-school_toys",
  "types_of_vehicles_&_remote_control_toys": "attr_types_of_vehicles_and_remote_control_toys",
};

export const categoryUrlKeys = [
  "p_cat", "cat", "sub_cat"
]

export const URL_PAGE = "URL_PAGE";
