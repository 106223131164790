import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "../../../materialUi/components"

export const PreviewImageModal = ({ title, dialogContext, imageURL, open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {dialogContext}
        </DialogContentText>
        <img width="500" height="400" src={imageURL} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
