import React from "react";
import { connect } from "react-redux";
import {
  deleteImage,
  editImageList,
  handleImageToggle,
  schedulerDeleteImage,
  schedulerHandleImageToggle,
  schedulerEditImageList
} from "../../actions/pagesPage.action";
import { LANGUAGE } from "../../constants";
import { formatUrlString } from "../../util";
import ImageListAccordion from "../../containers/imageListAccordion";

const UpdateCatalogImageList = ({
  imageList,
  isScheduler,
  keyIndex,
  schedulerIndex,
  position,
  deleteImage,
  editImageList,
  handleImageToggle,
  schedulerDeleteImage,
  schedulerEditImageList,
  schedulerHandleImageToggle
}) => {
  const handleDelete = () => {
    if (isScheduler) {
      schedulerDeleteImage(schedulerIndex, position, keyIndex);
    } else {
      deleteImage(position, keyIndex);
    }
  };

  const handleUpdateURL = (key, value, _brandId, language) => {
    if (isScheduler) {
      schedulerEditImageList(
        schedulerIndex,
        key,
        value,
        "imageList",
        position,
        keyIndex,
        language
      );
    } else {
      editImageList(key, value, "imageList", position, keyIndex, language);
    }
  };

  const handleImageToggleChange = toggledValue => {
    if (isScheduler) {
      schedulerHandleImageToggle(
        schedulerIndex,
        position,
        keyIndex,
        !toggledValue
      );
    } else {
      handleImageToggle(position, keyIndex, !toggledValue);
    }
  };

  const handleTextChange = (fieldName, value, language) => {
    if (isScheduler) {
      schedulerEditImageList(
        schedulerIndex,
        fieldName,
        value,
        "imageList",
        position,
        keyIndex,
        language
      );
    } else {
      editImageList(
        fieldName,
        value,
        "imageList",
        position,
        keyIndex,
        language
      );
    }
  };

  const handleRedirectionLinkChange = (fieldName, value) => {
    if (isScheduler) {
      schedulerEditImageList(
        schedulerIndex,
        fieldName,
        formatUrlString(value),
        "imageList",
        position,
        keyIndex,
        LANGUAGE.ENGLISH
      );
      schedulerEditImageList(
        schedulerIndex,
        fieldName,
        formatUrlString(value),
        "imageList",
        position,
        keyIndex,
        LANGUAGE.ARABIC
      );
    } else {
      editImageList(
        fieldName,
        formatUrlString(value),
        "imageList",
        position,
        keyIndex,
        LANGUAGE.ENGLISH
      );
      editImageList(
        fieldName,
        formatUrlString(value),
        "imageList",
        position,
        keyIndex,
        LANGUAGE.ARABIC
      );
    }
  };

  return (
    <ImageListAccordion
      handleDelete={handleDelete}
      handleImageToggleChange={handleImageToggleChange}
      handleRedirectionLinkChange={handleRedirectionLinkChange}
      handleTextChange={handleTextChange}
      handleUpdateURL={handleUpdateURL}
      image={imageList}
      keyIndex={keyIndex}
    />
  );
};

const mapDispatchToProps = {
  editImageList,
  handleImageToggle,
  deleteImage,
  schedulerDeleteImage,
  schedulerHandleImageToggle,
  schedulerEditImageList
};

export default connect(null, mapDispatchToProps)(UpdateCatalogImageList);
