import { SELECT_COUNTRY_ID, RESET_COUNTRY_ID } from "../constants/actions";

const initialState = [];

const setCountryId = (state, { countryId }) => {
  return (state = [...countryId]);
};

const resetCountryId = (state, { countryId }) => {
  return (state = [countryId]);
};

const selectCountryId = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_COUNTRY_ID:
      return setCountryId(state, action);
    case RESET_COUNTRY_ID:
      return resetCountryId(state, action);
    default:
      return state;
  }
};

export default selectCountryId;
