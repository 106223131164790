import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import { generateUUID, getDefaultUrlContentStructure } from "../util";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import {
  URL_LIST,
  SET_URL_PAGE_DATA,
  DELETE_URL,
  UPDATE_TOGGLE_URL_DATA,
  UPDATE_ACTIVE_URL_PAGE_DATA,
  RESET_URL_PAGE_DATA,
  UPDATE_URL,
  URL_PAGE_OPEN_COMPONENT,
  URL_PAGE_TOGGLE_COMPONENT,
  MODULE_DATA_CHANGE_URL_PAGE,
  CLONE_MOBILE_URL_PAGE,
  TOGGLE_LIST_ITEM_URL_PAGE,
  DELETE_LIST_ITEM_URL_PAGE,
  UPDATE_LIST_DATA_URL_PAGE,
  GENERATE_IMAGES_URL_PAGE,
  UPDATE_BANNER_SECTION_DATA_STYLES_URL_PAGE,
  PUT_BANNER_DATA_STYLES_URL_PAGE,
  CLEAR_BANNER_ROW_IMAGE_DATA_URL_PAGE,
  UPDATE_BANNER_SECTION_DATA_URL_PAGE,
  CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_URL_PAGE,
  HANDLE_DATA_CHANGE_BANNER_SCHEDULER_URL_PAGE,
  HANDLE_ROW_SCHEDULER_TOGGLE_URL_PAGE,
  COPY_CONTENT_TO_SCHEDULER_URL_PAGE,
  CLONE_ROW_URL_PAGE,
  CLONE_SCHEDULER_URL_PAGE,
  HANDLE_DELETE_BANNER_SCHEDULER_URL_PAGE,
  ADD_BANNER_SCHEDULER_URL_PAGE,
  ADD_BANNER_ROW_ITEM_URL_PAGE,
  DELETE_ROW_IMAGE_URL_PAGE,
  PRODUCT_CAROUSEL_DATA_CHANGE_URL_PAGE,
  REORDER_BANNER_ROW_IMAGES_URL_PAGE,
  APPLY_GENERAL_SPACING_URL_PAGE,
  UPDATE_BANNER_ROW_IMAGE_DATA_URL_PAGE,
  CHANGE_BANNER_IMAGE_HOVER_STYLE_URL_PAGE,
  COUNTDOWN_DATA_CHANGE_URL_PAGE,
  SET_URL_ROLLBACK_HISTORY,
} from "../constants/actions";

import {
  bannerDesktopBackgroundOptions,
  bannerGradientTypes,
  bannerTypeValueConstants,
  dynamicBannerItems,
  initialDynamicBannerRow,
  rowWidthOptionsDesktop,
  rowWidthValues,
  SCREEN_TYPES
} from "../constants";

const initialState = {
  urlList: [],
  activePage: {},
  initialActivePage: {},
  urlListCount: "",
  rollbackHistory: [],
};

const urlList = (state, { data, count }) => ({
  ...state,
  urlList: data,
  urlListCount: count,
  activePage: data.length ? cloneDeep(data[0]) : {},
  initialActivePage: data.length ? cloneDeep(data[0]) : {},
});

const setActiveUrlPage = (state, { data }) => ({
  ...state,
  activePage: cloneDeep(data),
  initialActivePage: cloneDeep(data),
});

const updateActivePageUrlData = (state, { data }) => ({
  ...state,
  activePage: cloneDeep(data),
});

const deleteUrlData = (state, { id, url, index }) => {
  let urlList = cloneDeep(state.urlList);
  let activePage = state.activePage || {};
  let initialActivePage = state.initialActivePage || {};
  urlList.splice(index, 1);
  if (activePage._id === id) {
    activePage = urlList[0] || {};
    initialActivePage = cloneDeep(activePage);
  }
  return {
    ...state,
    activePage,
    initialActivePage,
    urlList
  }
}

const updateToggleUrlData = (state, { id, enabled, index }) => {
  let urlList = cloneDeep(state.urlList);
  let activePage = state.activePage || {};
  let initialActivePage = state.initialActivePage || {};
  if (urlList && urlList[index]) {
    urlList[index]["enabled"] = enabled;
  }
  if (activePage._id === id) {
    activePage["enabled"] = enabled;
    initialActivePage["enabled"] = enabled;
  }
  return {
    ...state,
    activePage,
    initialActivePage,
    urlList
  }
}

const resetUrlPageData = (state) => {
  const defaultcontent = getDefaultUrlContentStructure();
  return {
    ...state,
    activePage: { content: cloneDeep(defaultcontent) },
    initialActivePage: { content: cloneDeep(defaultcontent) },
  }
};

const updateUrlData = (state, { data: { result = {} } = {} }) => {
  let id = result._id;
  let urlList = cloneDeep(state.urlList);
  let index = urlList.findIndex((url) => url._id === id);
  let activePage = state.activePage;
  let initialActivePage = state.initialActivePage;
  if (index !== -1) {
    urlList.splice(index, 1, cloneDeep(result));
    activePage = cloneDeep(result);
    initialActivePage = cloneDeep(result);
  }
  return {
    ...state,
    urlList,
    activePage,
    initialActivePage,
  }
}

const openComponent = (state, { id, index }) => {
  const pagesContent = cloneDeep(state.activePage);

  pagesContent && pagesContent.content && pagesContent.content.map((item, itemIndex) => {
    if (id ? item.id === id : itemIndex === index) {
      item.openAccordion = !item.openAccordion;
    } else {
      item.openAccordion = false;
    }
  });

  return {
    ...state,
    activePage: pagesContent
  };
};

const toggleSection = (state, { index }) => {
  const pagesContent = cloneDeep(state.activePage);

  if (pagesContent && pagesContent.content) {
    const contentModule = pagesContent.content.find(
      (item, itemIndex) => itemIndex === index
    );
    contentModule.enable = !contentModule.enable;
  }

  return {
    ...state,
    activePage: pagesContent
  };
};

const handleModuleDataChange = (state, { path, name, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, name], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const cloneDynamicTabRow = (state, { path, tab }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path);
  const tabletData = get(row, "tablet");
  const tabData = get(row, tab.toLowerCase());

  const isDesktop = tab === SCREEN_TYPES[0].label;

  tabletData.rows = [
    ...tabData.rows.map(row => {
      const _row = cloneDeep(row);

      if (isDesktop && _row.bannerType === bannerTypeValueConstants.SLIDER) {
        _row.imageList = _row.imageList.map(image => ({
          ...image,
          english: { ...image.english, imageURL: null },
          arabic: { ...image.arabic, imageURL: null }
        }));
      }

      return {
        ..._row,
        width:
          isDesktop && _row.width === rowWidthValues.CUSTOM_WIDTH
            ? null
            : _row.width,
        id: generateUUID()
      };
    })
  ];
  tabletData.styles = {
    ...tabData.styles,
    backgroundType:
      isDesktop &&
        tabData.styles.backgroundType === bannerDesktopBackgroundOptions[2].value
        ? null
        : tabData.styles
          ? tabData.styles.backgroundType
          : null
  };
  tabletData.title = tabData.title;
  tabletData.schedulers = [...(tabData.schedulers || [])];

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const toggleListItem = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  const isEnabled = get(content, [...path, "enable"]);
  set(content, [...path, "enable"], !isEnabled);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const deleteListItem = (state, { path, listIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const updatedHomepageListData = filter(
    get(content, path, []),
    (_listItem, index) => index !== listIndex
  );
  set(content, path, updatedHomepageListData);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateListData = (state, { path, name, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, name], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const generateRows = (state, { path, numberOfRows }) => {
  const content = cloneDeep(state.activePage.content);

  const previousRows = get(content, path, []);

  const newArrayLength =
    !isEmpty(previousRows) && numberOfRows > previousRows.length
      ? numberOfRows - previousRows.length
      : numberOfRows;

  const emptyRows = Array.from(Array(newArrayLength), () => {
    return initialDynamicBannerRow();
  });

  const rowsWithData =
    numberOfRows > previousRows.length
      ? [...previousRows, ...emptyRows]
      : previousRows.slice(0, numberOfRows);

  set(content, path, rowsWithData);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateBannerSectionDataStyles = (state, { path, name, value }) => {
  const content = cloneDeep(state.activePage.content);

  const styles = get(content, [...path, "styles"], {});

  if (["backgroundImage", "backgroundColor"].includes(name)) {
    delete styles.backgroundImage;
    delete styles.backgroundColor;
  }

  set(styles, [name], value);

  if (
    [
      "primaryBackgroundColor",
      "secondaryBackgroundColor",
      "gradientType",
      "primaryLocation",
      "secondaryLocation",
      "gradientAngle"
    ].includes(name)
  ) {
    set(
      styles,
      "backgroundGradient",
      `${styles.gradientType}(${styles.gradientType === bannerGradientTypes[0].value
        ? `${styles.gradientAngle}deg`
        : "circle"
      }, ${styles.primaryBackgroundColor} ${styles.primaryLocation}%, ${styles.secondaryBackgroundColor
      } ${styles.secondaryLocation}%)`
    );
  }

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const putBannerDataStyles = (state, { value, path }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, "styles"], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const clearBannerRowImageData = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, "imageList"], []);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateBannerSection = (state, { path, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, path, value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const clearBannerSchedulerRowImageData = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, path, []);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleDataChangeBannerScheduler = (state, { path, key, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, key], value);

  const ONE_DAY_MS = 60000 * 1439;

  if (key === "from") {
    const toDate = get(content, [...path, "to"]);

    if (!toDate) set(content, [...path, "to"], value + ONE_DAY_MS);
  }

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleRowSchedulerToggle = (state, { path, key, value }) => {
  let newState = handleDataChangeBannerScheduler(state, { path, key, value });
  if (value) {
    const [listPosition, deviceType] = path;
    const schedulers = get(
      newState,
      ["activePage", "content", listPosition, deviceType, "schedulers"],
      []
    );
    schedulers.forEach(scheduler => {
      set(scheduler, "enabled", false);
    });
  }
  return newState;
};

const copyContentToScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path);

  const schedulers = get(row, "schedulers");

  const scheduler = get(schedulers, [schedulerIndex]);

  set(schedulers, schedulerIndex, {
    ...scheduler,
    ...row,
    id: scheduler.id,
    imageList: [
      ...scheduler.imageList.map(image => ({ ...image, id: generateUUID() })),
      ...cloneDeep(row).imageList.map(image => ({
        ...image,
        id: generateUUID()
      }))
    ]
  });

  delete schedulers[schedulerIndex].schedulers;

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const cloneDynamicRow = (
  state,
  { path: [contentIndex, deviceType, rows], rowIndex, moduleId }
) => {
  const content = cloneDeep(state.activePage.content);

  const targetModule = content.find(module => module.id === moduleId);

  const copiedRow = cloneDeep(
    get(content, [contentIndex, deviceType, rows, rowIndex])
  );

  copiedRow.id = generateUUID();

  set(
    targetModule,
    [deviceType, rows],
    [...get(targetModule, [deviceType, rows]), copiedRow]
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const cloneDynamicScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const copiedScheduler = cloneDeep(get(content, [...path, schedulerIndex]));

  copiedScheduler.id = generateUUID();
  copiedScheduler.enabled = false;
  set(content, path, [...get(content, path), copiedScheduler]);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleDeleteBannerScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path, []);

  set(
    row,
    "schedulers",
    get(row, "schedulers", []).filter(
      (_item, index) => index !== schedulerIndex
    )
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const addSchedulerToBannerRow = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path, {});

  set(row, "schedulers", [
    ...get(row, "schedulers", []),
    initialDynamicBannerRow()
  ]);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const addBannerRowItem = (state, { path, itemType }) => {
  const content = cloneDeep(state.activePage.content);

  const pathToRow = path.slice(0, path.length - 1);

  const row = get(content, pathToRow);

  const item = dynamicBannerItems[itemType]();

  item.styles = { ...item.styles, ...row.generalPadding };

  set(content, path, [...get(content, path, []), item]);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const deleteRowImage = (state, { path, imageIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const updatedImageList = filter(
    get(content, path, []),
    (_image, index) => index !== imageIndex
  );

  set(content, path, updatedImageList);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleProductCarouselDataChange = (
  state,
  { path, value, language, name }
) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, language, name], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const reorderBannerRowImages = (state, { path, source, destination }) => {
  const content = cloneDeep(state.activePage.content);

  const imageList = get(content, path, []);

  [imageList[destination], imageList[source]] = [
    imageList[source],
    imageList[destination]
  ];

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const applyGeneralSpacing = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path);

  row.imageList.forEach(
    item => (item.styles = { ...item.styles, ...row.generalPadding })
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateBannerRowImageData = (state, { path, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, path, value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const changeBannerImageHoverStyle = (state, { key, value, path }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, "styles", "hoverValues", key], value);

  let formattedValue;

  switch (key) {
    case "transform": {
      formattedValue = `scale(${value})`;
      break;
    }
    case "transition": {
      formattedValue = `transform ${value}s`;
      break;
    }

    default: {
      formattedValue = value;
    }
  }

  set(content, [...path, "styles", "hover", key], formattedValue);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleCountdownDataChange = (state, { path, name, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, name], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const setUrlAllRollbackHistory = (state, action) => {
  return {
    ...state,
    rollbackHistory: action?.payload?.data || []
  };
};

const urlCreationReducer = (state = initialState, action) => {
  switch (action.type) {
    case URL_LIST:
      return urlList(state, action);
    case SET_URL_PAGE_DATA:
      return setActiveUrlPage(state, action);
    case DELETE_URL:
      return deleteUrlData(state, action);
    case UPDATE_TOGGLE_URL_DATA:
      return updateToggleUrlData(state, action);
    case UPDATE_ACTIVE_URL_PAGE_DATA:
      return updateActivePageUrlData(state, action);
    case RESET_URL_PAGE_DATA:
      return resetUrlPageData(state, action);
    case UPDATE_URL:
      return updateUrlData(state, action);
    case URL_PAGE_OPEN_COMPONENT:
      return openComponent(state, action);
    case URL_PAGE_TOGGLE_COMPONENT:
      return toggleSection(state, action);
    case MODULE_DATA_CHANGE_URL_PAGE:
      return handleModuleDataChange(state, action);
    case CLONE_MOBILE_URL_PAGE:
      return cloneDynamicTabRow(state, action);
    case TOGGLE_LIST_ITEM_URL_PAGE:
      return toggleListItem(state, action);
    case DELETE_LIST_ITEM_URL_PAGE:
      return deleteListItem(state, action);
    case UPDATE_LIST_DATA_URL_PAGE:
      return updateListData(state, action);
    case GENERATE_IMAGES_URL_PAGE:
      return generateRows(state, action);
    case UPDATE_BANNER_SECTION_DATA_STYLES_URL_PAGE:
      return updateBannerSectionDataStyles(state, action);
    case PUT_BANNER_DATA_STYLES_URL_PAGE:
      return putBannerDataStyles(state, action);
    case CLEAR_BANNER_ROW_IMAGE_DATA_URL_PAGE:
      return clearBannerRowImageData(state, action);
    case UPDATE_BANNER_SECTION_DATA_URL_PAGE:
      return updateBannerSection(state, action);
    case CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_URL_PAGE:
      return clearBannerSchedulerRowImageData(state, action);
    case HANDLE_DATA_CHANGE_BANNER_SCHEDULER_URL_PAGE:
      return handleDataChangeBannerScheduler(state, action);
    case HANDLE_ROW_SCHEDULER_TOGGLE_URL_PAGE:
      return handleRowSchedulerToggle(state, action);
    case COPY_CONTENT_TO_SCHEDULER_URL_PAGE:
      return copyContentToScheduler(state, action);
    case CLONE_ROW_URL_PAGE:
      return cloneDynamicRow(state, action);
    case CLONE_SCHEDULER_URL_PAGE:
      return cloneDynamicScheduler(state, action);
    case HANDLE_DELETE_BANNER_SCHEDULER_URL_PAGE:
      return handleDeleteBannerScheduler(state, action);
    case ADD_BANNER_SCHEDULER_URL_PAGE:
      return addSchedulerToBannerRow(state, action);
    case ADD_BANNER_ROW_ITEM_URL_PAGE:
      return addBannerRowItem(state, action);
    case DELETE_ROW_IMAGE_URL_PAGE:
      return deleteRowImage(state, action);
    case PRODUCT_CAROUSEL_DATA_CHANGE_URL_PAGE:
      return handleProductCarouselDataChange(state, action);
    case REORDER_BANNER_ROW_IMAGES_URL_PAGE:
      return reorderBannerRowImages(state, action);
    case APPLY_GENERAL_SPACING_URL_PAGE:
      return applyGeneralSpacing(state, action);
    case UPDATE_BANNER_ROW_IMAGE_DATA_URL_PAGE:
      return updateBannerRowImageData(state, action);
    case CHANGE_BANNER_IMAGE_HOVER_STYLE_URL_PAGE:
      return changeBannerImageHoverStyle(state, action);
    case COUNTDOWN_DATA_CHANGE_URL_PAGE:
      return handleCountdownDataChange(state, action);
    case SET_URL_ROLLBACK_HISTORY:
      return setUrlAllRollbackHistory(state, action);
    default:
      return state;
  }
};

export default urlCreationReducer;
