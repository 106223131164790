import React from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import map from "lodash/map";
import Button from "../button";
import SchedulerContent from "./SchedulerContent";
import SchedulerTextArea from "./SchedulerTextArea";
import SchedulerProductCarousel from "./SchedulerProductCarousel";
import SchedulerImageCarousel from "./SchedulerImageCarousel";
import { SCHEDULER_TYPE, URL_PAGE, SECTIONS_PAGE, FOOTER_PAGE } from "../../constants";
import { validateSchedulerDateAndTime } from "../../util/scheduler";
import { toastMsg } from "../../actions/common.action";
import SchedulerButton from "../schedulerButton";
import _ from "lodash";

const Scheduler = ({
  name,
  addNewScheduler,
  childContent,
  children,
  content,
  deleteScheduler,
  handleDateChange,
  handleImageListChange,
  handleToggleChange,
  schedulerItemRenderer,
  schedulerTextChange,
  type,
  disableToggle,
  disableButton,
  copyContentToScheduler,
  copyParentContentToScheduler,
  clone,
  handleModuleTitleChange,
  enableTogglePopup,
  togglePopupContent,
  pageType,
  cloneBannerScheduler
}) => {
  const dispatch = useDispatch();
  const schedulers = get(content, "schedulers", []);
  const handleDatePicker = (e, type, schedulerIndex, id) => {
    const timeStamp = Date.parse(e);

    const errorMsg = validateSchedulerDateAndTime(
      schedulers,
      schedulerIndex,
      id,
      timeStamp
    );

    if (errorMsg) {
      dispatch(toastMsg("error", errorMsg));
    } else {
      handleDateChange(schedulerIndex, id, timeStamp, type);
    }
  };

  const getChildComponent = (
    language,
    placeholder,
    componentType,
    index,
    schedulerData
  ) => {
    switch (componentType) {
      case SCHEDULER_TYPE.BANNER:
        return (
          <SchedulerTextArea
            language={language}
            key={language}
            placeholder={placeholder}
            handleSchedulerTextChange={schedulerTextChange}
            type={type}
            keyIndex={index}
            schedulerData={schedulerData}
          />
        );

      case SCHEDULER_TYPE.CAROUSEL_TYPE_B:
        return (
          <SchedulerProductCarousel
            key={language}
            language={language}
            placeholder={placeholder}
            updateContent={schedulerData}
            keyIndex={index}
            handleSchedulerTextChange={schedulerTextChange}
          />
        );

      case SCHEDULER_TYPE.CAROUSEL_TYPE_A:
        return (
          <SchedulerImageCarousel
            key={language}
            language={language}
            placeholder={placeholder}
            schedulerData={schedulerData}
            keyIndex={index}
            handleSchedulerTextChange={schedulerTextChange}
          />
        );
    }
  };

  return (
    <div className={!_.isEmpty(schedulers) ? "banner-scheduler-wrapper" : ""}>
      {![SECTIONS_PAGE, URL_PAGE, FOOTER_PAGE].includes(pageType) && !disableButton && (
        <div className="header">
          <h4>{!_.isEmpty(schedulers) ? "Banner Schedulers" : ""}</h4>
          <div className="scheduler-actions">
            <SchedulerButton
              onClick={() => {
                dispatch(toastMsg("success", "New scheduler was added"));
                addNewScheduler();
              }}
              name="ADD BANNER SCHEDULER"
            />
          </div>
        </div>
      )}

      {schedulers.map((scheduler, schedulerIndex) => (
        <SchedulerContent
          name={name}
          key={schedulerIndex}
          keyIndex={schedulerIndex}
          handleModuleTitleChange={handleModuleTitleChange}
          type={type}
          handleDatePicker={handleDatePicker}
          handleToggleChange={handleToggleChange}
          schedulerData={scheduler}
          deleteScheduler={() => deleteScheduler(type, schedulerIndex)}
          disableToggle={disableToggle}
          copyContent={copyContentToScheduler}
          copyParentContentToScheduler={copyParentContentToScheduler}
          clone={clone}
          enableTogglePopup={enableTogglePopup}
          togglePopupContent={togglePopupContent}
          cloneBannerScheduler={cloneBannerScheduler}
        >
          {childContent.map(({ language, placeholder, componentType }) =>
            getChildComponent(
              language,
              placeholder,
              componentType,
              schedulerIndex,
              scheduler
            )
          )}
          {!schedulerItemRenderer &&
            map(scheduler.imageList, (image, imageIndex) =>
              React.cloneElement(children, {
                keyIndex: imageIndex,
                imageList: image,
                key: image._id || image.id || imageIndex,
                schedulerIndex,
                isScheduler: true
              })
            )}
          {typeof schedulerItemRenderer === "function" &&
            schedulerItemRenderer(schedulerIndex)}
          {!schedulerItemRenderer && get(scheduler, "imageList") && (
            <div className="d-flex justify-content-end">
              <Button
                customClass="mr-0 mt-2"
                onClick={() => handleImageListChange(schedulerIndex)}
                name="Add New Image"
              />
            </div>
          )}
        </SchedulerContent>
      ))}
    </div>
  );
};

export default Scheduler;
