import {
  fetchWebApi,
  deleteWebApi,
  putDashboardWebApi,
  postDashboardWebApi
} from "../webapis/apiResource";
import { ENV_CONFIG } from "../config/env";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  toastMsg
} from "../actions/common.action";
import {
  LIST_USERS,
  DELETE_USER,
  UPDATE_USER,
  ADD_USER,
  UPDATE_USER_PASSWORD
} from "../constants/actions";
import { SUCCESS_MESSAGES, ERROR_MESSAGES } from "../constants";

const listUserManagementData = (data, count) => ({
  type: LIST_USERS,
  data,
  count
});

export const fetchUserManagementData = (
  limit = 10,
  skip = 0,
  searchText = ""
) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/user/list?limit=${limit}&skip=${skip}&search=${searchText}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(
            listUserManagementData(response.data.data, response.data.count)
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const removeUserManagementData = data => ({ type: DELETE_USER, data });

export const deleteUserManagementData = (id, email) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/user/${id}?email=${email}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(removeUserManagementData(response.data.data));
          dispatch(toastMsg("success", SUCCESS_MESSAGES["userDeleted"]));
        } else {
          dispatch(
            toastMsg(
              "error",
              (response.data.error && response.data.error.detail) ||
                (response.data.error && response.data.error.message) ||
                ERROR_MESSAGES["userDelete"]
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(toastMsg("error", ERROR_MESSAGES["userDelete"]));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const newUserManagementData = data => ({ type: ADD_USER, data });

export const addUserManagementData = payload => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/user/create`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(newUserManagementData(response.data.data));
          dispatch(toastMsg("success", SUCCESS_MESSAGES["userAdded"]));
        } else {
          dispatch(
            toastMsg(
              "error",
              (response.data.error && response.data.error.detail) ||
                (response.data.error && response.data.error.message) ||
                ERROR_MESSAGES["userAdd"]
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(toastMsg("error", ERROR_MESSAGES["userAdd"]));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const modifyUserManagementData = data => ({ type: UPDATE_USER, data });

export const updateUserManagementData = (id, payload) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/user/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(modifyUserManagementData(response.data.data));
          dispatch(toastMsg("success", SUCCESS_MESSAGES["userUpdated"]));
        } else {
          dispatch(
            toastMsg(
              "error",
              (response.data.error && response.data.error.detail) ||
                (response.data.error && response.data.error.message) ||
                ERROR_MESSAGES["userUpdate"]
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(toastMsg("error", ERROR_MESSAGES["userUpdate"]));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const modifyUserPasswordManagementData = data => ({
  type: UPDATE_USER_PASSWORD,
  data
});

export const updateUserPasswordManagementData = (id, payload) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/user/change-password/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(modifyUserPasswordManagementData(response.data.data));
          dispatch(
            toastMsg("success", SUCCESS_MESSAGES["userPasswordUpdated"])
          );
        } else {
          dispatch(
            toastMsg(
              "error",
              (response.data.error && response.data.error.detail) ||
                (response.data.error && response.data.error.message) ||
                ERROR_MESSAGES["userPasswordUpdate"]
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(toastMsg("error", ERROR_MESSAGES["userPasswordUpdate"]));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
