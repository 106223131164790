import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import Popup from "reactjs-popup";
import FolderIcon from "../../images/folder-icon.svg";
import ArrowRightBar from "../../images/arrow-right-bar.svg";
import DocumentText from "../../images/document-text.svg";

import { CustomSelect } from "../../component";

import HomeIcon from "../../images/home-icon.svg";
import VariantIcon from "../../images/variant_icon_2.png";
import DeleteIcon from "../../images/dustbin.png";
import { VARIANTS_COUNT_LIMIT } from "../../constants";
import { cloneDeep } from "lodash";
import { generateUUID } from "../../util";
import PopupContent from "../pagesPage/popupContent";


const customStyles = {
  content: {
    width: "770px",
    padding: "0px",
    position: "absolute",
    top: "20%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "none",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    transform: "translateX(-50%)"
  },
  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

const AddVariantModal = ({
  onHide,
  onCancel,
  onSave,
  pagesRoute,
  variants,
  pagesFoldersShape
}) => {
  const popupRef = useRef(null);
  const [pageVariants, setPageVariants] = useState(cloneDeep(variants));

  useEffect(() => {
    setPageVariants(cloneDeep(variants));
  }, [variants]);

  const onAddVariantClick = () => {
    setPageVariants([...pageVariants, { _id: generateUUID(), pageId: "" }])
  }

  const onDeleteVariantClick = (id, index) => {
    if (pageVariants[index] && pageVariants[index]._id === id) {
      const newVariantList = cloneDeep(pageVariants) || [];
      newVariantList.splice(index, 1);
      setPageVariants(newVariantList);
    }
  }

  const getOptions = () => {
    const options = [];
    const { pageContainers = [] } = pagesFoldersShape || {};
    const index = pageContainers.findIndex((container) => container.type === "variant");
    const container = index > -1 ? pageContainers[index] : {};
    const folderIds = container.folderIds || [];

    folderIds.forEach((id) => {
      if (pagesFoldersShape.folders) {
        const folderTitle = pagesFoldersShape.folders[id] ? pagesFoldersShape.folders[id].title : "";
        if (pagesFoldersShape.folders[id] && pagesFoldersShape.folders[id].pages && pagesFoldersShape.folders[id].pages.length) {
          pagesFoldersShape.folders[id].pages.forEach((page = {}) => {
            const pageTitle = page.title || "";
            options.push({
              value: page._id,
              label: (<span className="scheduled-page-option">
                <img src={FolderIcon} alt="folder" />
                <span>{folderTitle}</span>
                <img src={ArrowRightBar} alt="arrow right" />
                <img src={DocumentText} alt="document text" />

                <span>{pageTitle}</span>
              </span>)
            })
          })
        }

      }


    })
    return options;
  }

  const [options, setOptions] = useState(getOptions());

  useEffect(() => {
    setOptions(getOptions())
  }, [pagesFoldersShape]);

  const handleSelectChange = (id, index) => {
    const variants = cloneDeep(pageVariants);
    variants[index].pageId = id;
    setPageVariants(variants);
  }

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      onRequestClose={() => onHide()}
      style={customStyles}
      closeTimeoutMS={2000}
    >
      <div className="modal-scheduler-container">
        <div className="scheduler-header">
          <div className="page-breadcrumbs">
            <img src={HomeIcon} alt="page breadcrumbs" />
            <span className="main-breadcrumb">
              {pagesRoute.folder.title} &gt;&nbsp;
            </span>
            <span>{pagesRoute.page.title}</span>
          </div>
          <button
            className="btn scheduler-add"
            onClick={onAddVariantClick}
            disabled={pageVariants.length >= VARIANTS_COUNT_LIMIT}
          >
            <img src={VariantIcon} alt="Add Variant" style={{ "height": "15px", "width": "15px" }} />
            <span className="btn-text">ADD VARIANT</span>
          </button>
        </div>
        <div className="scheduler-body">
          {pageVariants.length ? <ul>
            <li className="variant-popup-li">
              <div className="variant-popup-cont">
                <span style={{ "width": "20%" }}>
                  <span>VARIANT</span>
                </span>
                <span style={{ "width": "50%" }}>
                  <span>PAGE</span>
                </span>
                <span style={{ "width": "10%" }}>
                  <span>ACTION</span>
                </span>
              </div>
            </li>
            {
              pageVariants.map((variant, index) => {
                return (<li key={variant._id} className="variant-popup-li">
                  <div className="variant-popup-cont">
                    <input
                      name="variant"
                      type="text"
                      placeholder="Variant"
                      value={`VARIANT_${index + 1}`}
                      disabled={true}
                      style={{ "width": "20%", "height": "37px" }}
                      className="form-control"
                    />
                    <CustomSelect
                      options={options}
                      onChange={(val) => handleSelectChange(val.value, index)}
                      value={
                        variant.pageId
                      }
                      name="pageId"
                      placeholder="Select Variant Page"
                      className="variant-select"
                    />
                    <div style={{ "width": "10%" }}>
                      <Popup
                        ref={popupRef}
                        trigger={
                          <span>
                            <img
                              src={DeleteIcon}
                              alt="Delete Variant"
                              style={{ "height": "18px", "width": "18px", "marginTop": "8px" }}
                            />
                          </span>
                        }
                        position="right top"
                      >
                        <PopupContent
                          onConfirm={() => {
                            onDeleteVariantClick(variant._id, index);
                            popupRef.current.close();
                          }}
                          onCancel={() => popupRef.current.close()}
                        />
                      </Popup>
                    </div>
                  </div>
                </li>)
              })
            }
          </ul> :
            <span>No Variants Available</span>
          }
        </div>
        <div className="scheduler-footer">
          <button className="btn btn-cancel" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="btn btn-save"
            onClick={() => onSave(pageVariants)}
          >
            Save & Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddVariantModal;
