import React, { Component } from "react";
import AccordionTile from "./accordion-tile";

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnabled: true
    };
  }

  UNSAFE_componentWillMount() {
    const { list } = this.props;

    let flagCount = 0;

    list &&
      list.length &&
      list.map(_item => {
        if (_item.onCoverFlag) {
          flagCount = flagCount + 1;
        }
      });
    if (flagCount >= 3) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  }

  toggleContent = contentName =>
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));

  addTile = () => {
    let index = 0;

    this.props.list &&
      !!this.props.list.length &&
      this.props.list.forEach((_item, _index) => {
        if (_item.pos >= this.props.list[index].pos) {
          index = _index;
        }
      });

    this.props.addNeedHelpAccordTile(
      this.props.list[index] && this.props.list[index].pos >= 0
        ? this.props.list[index].pos + 1
        : 0
    );
  };

  removeTile = (index, cb) => {
    this.props.removeNeedHelpAccordTile(index, cb);
  };

  updateTilePositionIndex = (index, value) => {
    this.props.updateNeedHelpAccordTilePosition(index, value);
  };

  handledCheckBoxChange = () => {
    const { list } = this.props;

    let flagCount = 0;

    list &&
      list.length &&
      list.map(_item => {
        if (_item.onCoverFlag) {
          flagCount = flagCount + 1;
        }
      });
    if (flagCount >= 3) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { list } = nextProps;
    let flagCount = 0;

    list &&
      list.length &&
      list.map(_item => {
        if (_item.onCoverFlag) {
          flagCount = flagCount + 1;
        }
      });
    if (flagCount >= 3) {
      this.setState({ isEnabled: false });
    } else {
      this.setState({ isEnabled: true });
    }
  }

  render() {
    const { defaultContent, isEnabled } = this.state;
    const {
      list,
      storeId,
      countryId,
      changeHandler,
      checkBoxChangeHandler,
      needHelpKey,
      submitBtnValidation
    } = this.props;

    let accordionList =
      list &&
      list.map((_item, _index) => {
        return (
          <AccordionTile
            countryId={countryId}
            storeId={storeId}
            key={`accordion_${_index}`}
            index={_index}
            item={_item}
            remove={this.removeTile}
            updatePosition={this.updateTilePositionIndex}
            changeHandler={changeHandler}
            checkBoxChangeHandler={checkBoxChangeHandler}
            needHelpKey={needHelpKey}
            isEnabled={isEnabled}
            handledCheckBoxChange={this.handledCheckBoxChange}
            submitBtnValidation={submitBtnValidation}
            count={list.length}
          />
        );
      });

    return (
      <div className="wrapper-box">
        <div className="heading-wrapper flex catalog-banner-heading">
          <h3>FAQ Section</h3>
          <span
            onClick={() => this.toggleContent("defaultContent")}
            className={`toggle-banner ${
              defaultContent ? "minus-banner" : "plus-banner"
            }`}
          />
        </div>
        {defaultContent && accordionList}
        {defaultContent && (
          <div
            className="heading-wrapper flex catalog-banner-heading add-new-section"
            onClick={this.addTile}
          >
            <h3>Add FAQ</h3>
            <span className={`toggle-banner plus-banner`} />
          </div>
        )}
      </div>
    );
  }
}

export default Accordion;
