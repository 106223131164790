export const ADD_MEGA_MENU_DATA = "ADD_MEGA_MENU_DATA";
export const UPDATE_MEGA_MENU_DATA = "UPDATE_MEGA_MENU_DATA";
export const DELETE_MEGA_MENU_DATA = "DELETE_MEGA_MENU_DATA";
export const EDIT_MEGA_MENU_CONTENT = "EDIT_MEGA_MENU_CONTENT";
export const CLEAR_MEGA_MENU_CONTENT = "CLEAR_MEGA_MENU_CONTENT";
export const MEGA_MENU_TEXT = "MEGA_MENU_TEXT";
export const HANDLE_ATTRIB_CHANGE = "HANDLE_ATTRIB_CHANGE";
export const SAVE_ATTRIBUTE = "SAVE_ATTRIBUTE";
export const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE";
export const CLEAR_ATTRIBUTE_STATE = "CLEAR_ATTRIBUTE_STATE";
export const UPDATE_TREE_RECORDS = "UPDATE_TREE_RECORDS";
export const HANDLE_EDIT_ATTRIBUTE = "HANDLE_EDIT_ATTRIBUTE";
export const CHANGE_IMAGE_COL_TYPE = "CHANGE_IMAGE_COL_TYPE";
export const HANDLE_IMAGE_TYPE = "HANDLE_IMAGE_TYPE";
export const UPDATE_IMAGE_DATA = "UPDATE_IMAGE_DATA";
export const GET_MEGA_MENU_DATA = "GET_MEGA_MENU_DATA";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
export const CLEAR_ALL_IMAGES = "CLEAR_ALL_IMAGES";
export const HANDLE_SPS_CHANGE = "HANDLE_SPS_CHANGE";
export const HANDLE_MEGA_MENU_STATIC_TEXT = "HANDLE_MEGA_MENU_STATIC_TEXT";
export const CLEAR_MEGA_MENU_STATIC_CONTENT = "CLEAR_MEGA_MENU_STATIC_CONTENT";
export const HANDLE_CURRENT_TAB_CHANGE = "HANDLE_CURRENT_TAB_CHANGE";
export const ADD_DATA_TO_CAROUSAL_LIST = "ADD_DATA_TO_CAROUSAL_LIST";
export const DELETE_CAROUSAL_ITEM = "DELETE_CAROUSAL_ITEM";
export const MEGA_MENU_CAROUSAL_CHANGE = "MEGA_MENU_CAROUSAL_CHANGE";
export const PUT_MEGA_MENU_DATA = "PUT_MEGA_MENU_DATA";
export const PUT_MEGA_MENU_STATIC_DATA = "PUT_MEGA_MENU_STATIC_DATA";
export const DELETE_MEGA_MENU_API_DATA = "DELETE_MEGA_MENU_API_DATA";
export const ADD_MEGA_MENU_SCHEDULERS = "ADD_MEGA_MENU_SCHEDULERS";
export const DELETE_MEGA_MENU_SCHEDULERS = "DELETE_MEGA_MENU_SCHEDULERS";
export const HANDLE_SCHEDULER_IMAGE_TYPE = "HANDLE_SCHEDULER_IMAGE_TYPE";
export const CLEAR_ALL_SCHEDULERS_IMAGES = "CLEAR_ALL_SCHEDULERS_IMAGES";
export const HANDLE_SCHEDULER_IMAGE_DATA = "HANDLE_SCHEDULER_IMAGE_DATA";
export const UPDATE_SCHEDULER_IMAGE_DATA = "UPDATE_SCHEDULER_IMAGE_DATA";
export const REMOVE_SCHEDULER_IMAGE = "REMOVE_SCHEDULER_IMAGE";
