import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import set from "lodash/set";
import { cloneDeep } from "lodash";
import moment from "moment";
import { Button, Box, FormControlLabel, Checkbox, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from "../../../materialUi/components"
import SpacingControls from "../SpacingControlsMUI";
import { PAGES_PAGE, SECTIONS_PAGE, FOOTER_PAGE, deviceCheckboxes, deviceTypes } from "../../../constants";
import { updateBannerRowItemData } from "../../../actions/dynamicBanner.action";
import { toastMsg } from "../../../actions/common.action";
import { bannerCountdownSchema } from "../../../util/homepage";
import { DateTimePicker } from "../../../materialUi/datePicker";
import { TextField, MenuItem, AppBar, Tabs, Tab } from "../../../materialUi/components";

import { ColorPicker } from "../../ColorPicker";

import { BannerImage } from "./BannerImageModal";

export const countdownOptions = [
  { label: "Left", value: "left" },
  { label: "Right", value: "right" },
  { label: "Center", value: "center" }
];
export const countdownTabs = [
  { "key": "beforeCountDown", "label": "Before Countdown" },
  { "key": "afterCountDown", "label": "After Countdown" }]

export const CountdownModal = ({
  isOpen,
  handleClose,
  handleSetModalOpen,
  item,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  path,
  deviceType,
  bannerType,
}) => {
  const dispatch = useDispatch();

  const content = useSelector(
    state => {
      if (pageType === PAGES_PAGE)
        return state.pagesPageReducer.pagesContent.content
      if (pageType === SECTIONS_PAGE)
        return state.sectionsReducer.activePage.content
      if (pageType === FOOTER_PAGE)
        return state.footer.updateMenuItemContent
    }
  );

  const [countdownData, setCountdown] = useState(cloneDeep(item));
  const [currentTab, setCurrentTab] = useState(countdownTabs[0].key);

  useEffect(() => {
    setCountdown(cloneDeep(item))
  }, [item]);

  const showDeviceCheckboxes = useMemo(
    () => deviceType !== deviceTypes.desktop,
    [deviceType]
  );

  const [displayPrimaryColorPicker, setDisplayPrimaryColorPicker] = useState(
    false
  );
  const [
    displaySecondaryColorPicker,
    setDisplaySecondaryColorPicker
  ] = useState(false);

  const handleBackgroundPickerClick = () =>
    setDisplayPrimaryColorPicker(!displayPrimaryColorPicker);
  const handleColorPickerClick = () =>
    setDisplaySecondaryColorPicker(!displaySecondaryColorPicker);

  const defaultCountdownOption = get(countdownData, "countdownOption", "center");

  const handleCountdownDataChange = ({ name, value }) => {
    const _countdownData = cloneDeep(countdownData);
    set(_countdownData, [name], value);
    setCountdown(_countdownData);
  }

  const validateCountdownModalBlock = () => {
    bannerCountdownSchema
      .validate(countdownData)
      .then(() => {
        dispatch(toastMsg("success", "Successfully validated"));

        handleCountdownDataChange({ name: "validated", value: true });
        dispatch(
          updateBannerRowItemData({
            path,
            value: cloneDeep(countdownData),
            pageType,
            updateCatalogPageType,
            updateCatalogPageLocation
          })
        );
        handleSetModalOpen(false);
      })
      .catch(err => {
        dispatch(toastMsg("warning", err.errors.join(",")));
      });
  };


  const handleDatePicker = e => {
    if (Date.parse(e) < Date.now()) {
      return dispatch(toastMsg("End date cannot be less than current date"));
    }

    if (path[4] === "schedulers" || path[2] === "schedulers") {
      let scheduler = {};
      if (path[4] === "schedulers") {
        const [
          contentIndex,
          deviceType,
          rows,
          rowIndex,
          schedulers,
          schedulerIndex
        ] = path;

        scheduler = get(
          content,
          [contentIndex, deviceType, rows, rowIndex, schedulers, schedulerIndex],
          {}
        );
      } else {
        const [
          contentIndex,
          deviceType,
          schedulers,
          schedulerIndex
        ] = path;

        scheduler = get(
          content,
          [contentIndex, deviceType, schedulers, schedulerIndex],
          {}
        );
      }
      if (!scheduler.from)
        return dispatch(toastMsg("Select scheduler start date first"));

      if (!scheduler.to)
        return dispatch(toastMsg("Select scheduler end date first"));

      if (Date.parse(e) < scheduler.from || Date.parse(e) > scheduler.to)
        return dispatch(toastMsg("Countdown end date must lie in between scheduler from and to date."));
    }

    handleCountdownDataChange({
      name: "date",
      value: e
    });
  };

  const handleChangeCurrentTab = (key) => {
    setCurrentTab(key);
  }

  const handleChangeEnabled = ({ name, value }) => {
    const enabledOn = get(countdownData, `enabledOn`, []);
    !enabledOn.includes(name) && value
      ? enabledOn.push(name)
      : enabledOn.splice(enabledOn.indexOf(name), 1);
    handleCountdownDataChange({ name: "enabledOn", value: enabledOn });
  };

  const defaultPath = useMemo(() => [...path, currentTab], [
    currentTab,
    path
  ]);

  const handleModalClose = () => {
    setCountdown(cloneDeep(item));
    handleClose();
  }

  const handleBannerImageData = (value, path) => {
    const _countdownData = cloneDeep(countdownData);
    set(_countdownData, [currentTab, ...path], value);
    setCountdown(_countdownData);
  }

  const renderTabData = () => {
    return (
      <Box style={{ "marginTop": "28px" }}>
        <BannerImage
          image={countdownData[currentTab]}
          deviceType={deviceType}
          bannerType={bannerType}
          pageType={pageType}
          updateCatalogPageType={updateCatalogPageType}
          updateCatalogPageLocation={updateCatalogPageLocation}
          path={defaultPath}
          isDeviceCheckboxesRequired={false}
          handleBannerImageData={handleBannerImageData}
        />
      </Box>
    )
  }

  return (
    <Dialog open={isOpen} onClose={handleModalClose} fullWidth maxWidth={"lg"}>
      <DialogTitle>{"Countdown"}</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ "display": "flex" }}>
          <Box>
            <Box sx={{ "display": "flex", "marginBottom": "20px" }}>
              <DateTimePicker
                label="End Date"
                value={moment(countdownData.date || "")}
                onChange={handleDatePicker}
                renderInput={(params) => <TextField {...params} />
                }
                disablePast
                minDateTime={moment()}
                sx={{ "width": "48%", "marginRight": "20px" }}
                slotProps={{ textField: { size: 'small', error: false } }}
              />
              <TextField
                select
                label="Select Style type"
                onChange={(e) => {
                  handleCountdownDataChange({
                    name: "styleType",
                    value: get(e, "target.value", null)
                  })
                }}
                size="small"
                value={countdownData.styleType}
                sx={{ "width": "48%", "marginLeft": "20px" }}
              >
                {Array.from({ length: 3 }).map((_, index) => (
                  <MenuItem key={index} value={index}>{index}</MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ "display": "flex", "marginBottom": "20px" }} className="mui-countdown">
              <Box sx={{ "width": "48%", "marginRight": "20px" }}>
                <span className="mb-2">Background color</span>
                <span
                  onClick={() =>
                    handleCountdownDataChange({
                      name: "backgroundColor",
                      value: "#ffffff"
                    })
                  }
                  className="countdown-delete-icon"
                />
                <ColorPicker
                  onClick={handleBackgroundPickerClick}
                  background={get(countdownData, "backgroundColor", "#ffffff")}
                  displayPicker={displayPrimaryColorPicker}
                  onChange={changedColor =>
                    handleCountdownDataChange({
                      name: "backgroundColor",
                      value: changedColor.hex
                    })
                  }
                />
              </Box>
              <Box sx={{ "width": "48%", "marginLeft": "20px" }}>
                <span>Color</span>
                <span
                  onClick={() =>
                    handleCountdownDataChange({
                      name: "color",
                      value: "#ffffff"
                    })
                  }
                  className="countdown-delete-icon"
                />
                <ColorPicker
                  onClick={handleColorPickerClick}
                  background={get(countdownData, "color", "#ffffff")}
                  displayPicker={displaySecondaryColorPicker}
                  onChange={changedColor =>
                    handleCountdownDataChange({
                      name: "color",
                      value: changedColor.hex
                    })
                  }
                />
              </Box>
            </Box>
            <Box sx={{ "marginBottom": "20px" }}>
              <TextField
                select
                label="Select Countdown Option"
                onChange={(e) => {
                  handleCountdownDataChange({
                    name: "countdownOption",
                    value: get(e, "target.value", null)
                  })
                }}
                size="small"
                value={defaultCountdownOption}
                sx={{ "width": "48%", "marginRight": "20px" }}
                placeholder="Select countdown option"
              >
                {countdownOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ "marginBottom": "20px" }}>
              <SpacingControls
                styles={countdownData.styles}
                handleSpacingChange={styles =>
                  handleCountdownDataChange({
                    name: "styles",
                    value: styles
                  })}
                CommonStyle={{ display: "flex", width: "100%" }}
                paddingStyle={{ width: "70%" }}
                marginStyle={{ width: "30%", marginLeft: "20px" }}
              />
            </Box>
            {showDeviceCheckboxes && (
              <Box sx={{ "marginBottom": "20px" }}>
                {deviceCheckboxes.map(({ name, label }) => {
                  const enabledOn = get(countdownData, `enabledOn`, []);
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={name}
                          name={name}

                          checked={enabledOn.includes(name)}
                          onChange={e =>
                            handleChangeEnabled({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                        />
                      }
                      label={label}
                      key={name}
                    />
                  );
                })}
              </Box>
            )}
          </Box>
          <Box style={{ "marginLeft": "10px", "marginRight": "10px" }}>
            <Divider orientation="vertical" variant="fullWidth" />
          </Box>
          <Box>
            <Box>
              <AppBar position="static" sx={{ bgcolor: "#f5f5f5" }} elevation={0}>
                <Tabs
                  value={countdownTabs.findIndex(({ key }) => key === currentTab)}
                  onChange={(_, index) => {
                    handleChangeCurrentTab(countdownTabs[index].key)
                  }}
                  variant="fullWidth"
                  TabIndicatorProps={{ sx: { backgroundColor: "#000000" } }}
                >
                  {countdownTabs.map(({ key, label }, index) =>
                    <Tab
                      key={key}
                      label={label}
                      sx={{
                        bgcolor: currentTab === key ? "#FFD100" : "",
                        "color": "#000000",
                        '&.Mui-selected': {
                          color: '#000000',
                        }
                      }} />
                  )}
                </Tabs>
              </AppBar>
            </Box>
            {renderTabData()}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} variant="outlined">Cancel</Button>
        <Button onClick={validateCountdownModalBlock} variant="outlined" sx={{ "marginLeft": "auto" }}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
