import React, { useState, useRef, Fragment, useCallback } from "react";
import Toggle from "react-toggle";
import ReactTooltip from "react-tooltip";
import Popup from "reactjs-popup";
import { useDispatch } from "react-redux";

import SchedulerDatePicker from "../SchedulerDatePicker";
import { toastMsg } from "../../../actions/common.action";
import { SolidPencil } from "../../svg/SolidPencil";
import { SolidCheckMark } from "../../svg/SolidCheckMark";
import PopupContent from "../../../containers/pagesPage/popupContent";

const SchedulerContent = ({
  keyIndex,
  type,
  handleDatePicker,
  handleToggleChange,
  children,
  schedulerData,
  deleteScheduler,
  disableToggle,
  copyContent,
  clone,
  handleModuleTitleChange,
  copyParentContentToScheduler,
  enableTogglePopup,
  togglePopupContent,
  name,
  cloneBannerScheduler
}) => {
  const dispatch = useDispatch();

  const [schedulerState, setSchedulerState] = useState({
    index: 1,
    show: false
  });
  const [schedulerToggled, setSchedulerToggled] = useState(false);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const titleRef = useRef();
  const popupRef = useRef(null);
  const titleData = `Scheduler ${keyIndex + 1}`;
  const handleChangeTitle = useCallback(() => {
    if (titleRef.current.value.length >= 50)
      return dispatch(
        toastMsg(
          "error",
          `The title should be less that 50 symbols, current length: ${titleRef.current.value.length}`
        )
      );
    setIsEditingTitle(false);
    handleModuleTitleChange(keyIndex, titleRef.current.value);
  }, [dispatch, handleModuleTitleChange, keyIndex]);

  const stopPropagation = callback => e => {
    e.stopPropagation();
    callback && callback();
  };
  const toggleScheduler = index => {
    if (index === schedulerState.index) {
      setSchedulerState(prevState => ({
        index,
        show: !prevState.show
      }));
    } else {
      setSchedulerState({
        index,
        show: true
      });
    }
  };
  const handleKeyDown = useCallback(
    e => {
      switch (e.key) {
        case "Enter":
          handleChangeTitle();
          break;
        case "Escape":
          setIsEditingTitle(false);
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line
    [handleChangeTitle]
  );
  const togglePopupDisabled = !(enableTogglePopup && !schedulerData.enabled);

  return (
    <div className="wrapper-box scheduler">
      <div className="heading-wrapper flex open">
        <span
          onClick={() => toggleScheduler(keyIndex + 1)}
          className={`toggle-banner ${
            schedulerState.index === keyIndex + 1 && schedulerState.show
              ? "minus-banner"
              : "plus-banner"
          }`}
        />
        {isEditingTitle ? (
          <Fragment>
            <input
              className="module-title-input"
              //style={{ width: `${title.length}ch` }}
              name="moduleTitle"
              placeholder="Module title"
              defaultValue={schedulerData.title || titleData}
              ref={titleRef}
              onClick={stopPropagation()}
              onKeyDown={handleKeyDown}
            />
            <SolidCheckMark
              onClick={stopPropagation(handleChangeTitle)}
              className="cursor-pointer m-l-20 m-r-20"
            />
          </Fragment>
        ) : (
          <Fragment>
            <h3 onClick={stopPropagation()}>
              {schedulerData.title || titleData}
              <SolidPencil
                onClick={() => setIsEditingTitle(prev => !prev)}
                className="cursor-pointer m-l-20"
                data-tip="Edit module title"
              />
            </h3>
          </Fragment>
        )}

        {clone && (
          <span
            onClick={() => {
              clone(keyIndex);
              dispatch(toastMsg("success", "Cloned successfully"));
            }}
            data-tip={
              keyIndex ? `Clone Scheduler ${keyIndex + 1}` : "Clone Scheduler"
            }
            className="clone-btn"
          />
        )}
        {cloneBannerScheduler && (
          <span
            onClick={() => {
              cloneBannerScheduler(schedulerData);
            }}
            data-tip={
              keyIndex
                ? `Clone banner Scheduler ${keyIndex + 1}`
                : "Clone banner Scheduler"
            }
            className="banner-clone-btn"
          ></span>
        )}
        {copyContent && (
          <span
            onClick={() => copyContent(keyIndex)}
            data-tip="Copy content to scheduler"
            className="copy-content"
          />
        )}
        <span className="custom-toggle">
          <Popup
            ref={popupRef}
            trigger={
              <div>
                <Toggle
                  checked={!!schedulerData.enabled}
                  onChange={e => {
                    togglePopupDisabled
                      ? handleToggleChange(type, keyIndex, e.target.checked)
                      : setSchedulerToggled(e.target.checked);
                  }}
                  disabled={disableToggle}
                  icons={false}
                />
              </div>
            }
            position="left center"
            disabled={togglePopupDisabled}
          >
            <PopupContent
              onConfirm={() => {
                handleToggleChange(type, keyIndex, schedulerToggled);
                popupRef.current.close();
              }}
              onCancel={() => {
                setSchedulerToggled(false);
                popupRef.current.close();
              }}
              message={togglePopupContent}
            />
          </Popup>
        </span>
        <span
          className="delete-btn"
          onClick={deleteScheduler}
          data-tip="Delete scheduler"
        />
        {copyParentContentToScheduler && (
          <span
            data-tip="Copy rows content to scheduler"
            className="clone-btn"
            onClick={copyParentContentToScheduler}
          />
        )}
      </div>
      {schedulerState.index === keyIndex + 1 && schedulerState.show && (
        <div>
          <div className="data-wrapper">
            <div className="picker-wrapper">
              <SchedulerDatePicker
                keyIndex={keyIndex}
                handleDatePicker={handleDatePicker}
                type={type}
                label="From"
                id="from"
                value={schedulerData.from}
              />

              <SchedulerDatePicker
                keyIndex={keyIndex}
                handleDatePicker={handleDatePicker}
                type={type}
                label="To"
                id="to"
                value={schedulerData.to}
              />
            </div>
          </div>

          <div className="data-wrapper">{children}</div>
        </div>
      )}
      <ReactTooltip place="top" effect="solid" />
    </div>
  );
};

export default SchedulerContent;
