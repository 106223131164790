import React from "react";
import SortableTree from "react-sortable-tree";
import get from "lodash/get";
import "react-sortable-tree/style.css";

const CustomSortableTree = ({
  treeData,
  columnName,
  updateTreeDataValue,
  openConfirmModal,
  openEditModal
}) => (
  <div className="custom-sortable-tree">
    <SortableTree
      maxDepth={2}
      treeData={treeData}
      onChange={treeData => updateTreeDataValue(treeData, columnName)}
      generateNodeProps={rowInfo => {
        const nodeTitle = get(rowInfo, "node.title");
        return {
          buttons: [
            <button
              key={`edit_${nodeTitle}`}
              onClick={() => openEditModal(rowInfo)}
              className="dnd-btn"
            >
              <span className="dnd-edit-btn" />
            </button>,
            <button
              key={`delete_${nodeTitle}`}
              onClick={() => openConfirmModal(rowInfo)}
              className="dnd-btn"
            >
              <span className="dnd-delete-btn" />
            </button>
          ]
        };
      }}
    />
  </div>
);

export default CustomSortableTree;
