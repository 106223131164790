import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEqual from "lodash/isEqual";
import _ from "lodash";
import {
  NAVIGATION,
  NAVIGATION_MENUS_SLUGS,
  CONFIGURATIONS_PAGE_PANEL
} from "../../constants";
import { Paper } from "../../materialUi/components";
import RightPanel from "./rightPanel";
import SideBar from "../sideBar";
import CountryDropDown from "../countryDropdownMUI";
import ConfirmModal from "../modal/ConfirmModal";
import Subheader from "../../component/subheader"
import { changeTab } from "../../actions/common.action";
import {
  createConfiguration,
  deleteConfiguration,
  updateConfigName,
  fetchConfigurationsPages,
  setActivePage,
  resetDatas,
  getPageData,
  resetActivePage,
  setPagesRollbackHistory,
  setPageRollback,
  getAllRollbackHistory,
} from "../../actions/configurationsPage.action";

class Configurations extends Component {
  constructor() {
    super();
    this.updatePageContent = {};
    this.tempPageContent = {};
    this.state = {
      currentTab: CONFIGURATIONS_PAGE_PANEL.CONFIGURATIONS_ADD_PAGE_DISPLAY,
      confirmModal: false,
      storeTempSelectedTab: "",
      updatePageIndex: 0,
      tempPageIndex: 0,
      currentTabName: `${CONFIGURATIONS_PAGE_PANEL.CONFIGURATIONS_UPDATE_PAGE_DISPLAY}0`,
      showConfigRollback: false,
      showConfigRollbackScheduler: false
    };
  }

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.CONFIGURATIONS);
  }

  componentDidMount() {
    const {
      countryId,
      fetchConfigurationsPages,
      resetActivePage
    } = this.props;
    countryId &&
      fetchConfigurationsPages(countryId).then(res => {
        if (res.data && res.data.code && res.data.code === 200) {
          resetActivePage();
          this.initialLoad();
        }
      });
  }

  initialLoad = () => {
    const { sectionsData, getPageData } = this.props;
    if (sectionsData && sectionsData.length) {
      const data = sectionsData[0] || {};
      getPageData(data._id);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      countryId,
      fetchConfigurationsPages,
      resetActivePage
    } = this.props;
    const { CONFIGURATIONS_DELETE_PAGE_DISPLAY } = CONFIGURATIONS_PAGE_PANEL;
    let e = { target: {} };
    e["target"]["id"] = CONFIGURATIONS_DELETE_PAGE_DISPLAY;
    e["target"]["name"] = CONFIGURATIONS_DELETE_PAGE_DISPLAY;
    if (
      +countryId !== +nextProps.countryId
    ) {
      nextProps.countryId &&
        this.setState({ updatePageIndex: 0 });
      nextProps.countryId && this.changeTab(e);
      nextProps.countryId &&
        fetchConfigurationsPages(nextProps.countryId).then(res => {
          if (res.data && res.data.code && res.data.code === 200) {
            resetActivePage();
            this.initialLoad();
          }
        });
    }
  }

  changeTab = e => {
    const { currentTabName, confirmModal } = this.state;
    const targetTabName =
      typeof e.target.getAttribute === "function"
        ? e.target.getAttribute("name")
        : e.target.name;
    const { sectionsPageR } = this.props;
    const {
      CONFIGURATIONS_ADD_PAGE_DISPLAY,
      CONFIGURATIONS_DELETE_PAGE_DISPLAY
    } = CONFIGURATIONS_PAGE_PANEL;

    if (currentTabName !== targetTabName) {
      if (
        [
          CONFIGURATIONS_ADD_PAGE_DISPLAY,
          CONFIGURATIONS_DELETE_PAGE_DISPLAY
        ].indexOf(currentTabName) > -1
      ) {
        if (
          isEqual(
            sectionsPageR.initialAddPageContent,
            sectionsPageR.sectionsAddPageContent
          )
        ) {
          this.setState({
            currentTab: e.target.id,
            currentTabName: targetTabName
          });
        } else {
          this.setState({
            confirmModal: !confirmModal,
            storeTempSelectedTab: e.target.id,
            storeTempSelectedTabName: targetTabName
          });
        }
      } else {
        if (
          isEqual(
            sectionsPageR.initialSectionsPages,
            sectionsPageR.sectionsPages
          )
        ) {
          this.setState({
            currentTab: e.target.id,
            currentTabName: targetTabName
          });
        } else {
          this.setState({
            confirmModal: !confirmModal,
            storeTempSelectedTab: e.target.id,
            storeTempSelectedTabName: targetTabName
          });
        }
      }
    } else {
      if (
        [
          CONFIGURATIONS_ADD_PAGE_DISPLAY,
          CONFIGURATIONS_DELETE_PAGE_DISPLAY
        ].indexOf(currentTabName) > -1
      ) {
        if (
          isEqual(
            sectionsPageR.initialAddPageContent,
            sectionsPageR.sectionsAddPageContent
          )
        ) {
          this.setState({
            currentTab: e.target.id,
            currentTabName: targetTabName
          });
        } else {
          this.setState({
            confirmModal: !confirmModal,
            storeTempSelectedTab: e.target.id,
            storeTempSelectedTabName: targetTabName
          });
        }
      }
    }
  };

  hideModal = () =>
    this.setState(prevProps => ({
      confirmModal: !prevProps.confirmModal,
      storeTempSelectedTab: ""
    }));

  confirmModal = () => {
    const {
      confirmModal,
      storeTempSelectedTab,
      currentTab,
      storeTempSelectedTabName,
      tempPageIndex
    } = this.state;
    const { resetAddPageContent, resetUpdateStaticPage } = this.props;
    resetAddPageContent();
    resetUpdateStaticPage();
    this.setState({
      confirmModal: !confirmModal,
      currentTab: storeTempSelectedTab,
      storeTempSelectedTab: "",
      currentTabName: storeTempSelectedTabName,
      storeTempSelectedTabName: "",
      updatePageIndex: tempPageIndex
    });
    this.props.cancelAction(currentTab);
    this.updatePageContent = this.tempPageContent;
  };

  closeRollbackModal = () => {
    const { setPagesRollbackHistory } = this.props;
    setPagesRollbackHistory([]);
  };

  onConfirmRollback = (id) => {
    const { setPageRollback } = this.props;
    setPageRollback(id);
  }

  getRollbackHistory = (pageId, fromDate, toDate) => {
    const { getAllRollbackHistory } = this.props;
    getAllRollbackHistory(pageId, fromDate, toDate)
  }

  handlePageClick = (updatePageContent, index) => {
    const { currentTabName } = this.state;
    const { sectionsPageR, setActivePage, resetDatas } = this.props;
    const {
      CONFIGURATIONS_ADD_PAGE_DISPLAY,
      CONFIGURATIONS_DELETE_PAGE_DISPLAY,
      CONFIGURATIONS_UPDATE_PAGE_DISPLAY
    } = CONFIGURATIONS_PAGE_PANEL;
    this.props.getPageData(updatePageContent._id);
    if (
      [
        CONFIGURATIONS_ADD_PAGE_DISPLAY,
        CONFIGURATIONS_DELETE_PAGE_DISPLAY
      ].indexOf(currentTabName) > -1
    ) {
      if (
        isEqual(sectionsPageR.initialData, sectionsPageR.sectionsAddPageContent)
      ) {
        this.updatePageContent = updatePageContent;
        this.props.getPageData(updatePageContent._id);
        this.setState({
          currentTab: CONFIGURATIONS_UPDATE_PAGE_DISPLAY,
          updatePageIndex: index
        });
      } else {
        this.tempPageContent = updatePageContent;
        this.setState({
          currentTab: CONFIGURATIONS_ADD_PAGE_DISPLAY,
          tempPageIndex: index
        });
      }
    } else {
      if (isEqual(sectionsPageR.initialData, sectionsPageR.sectionsPages)) {
        this.updatePageContent = updatePageContent;
        this.props.getPageData(updatePageContent._id);
        this.setState({
          currentTab: CONFIGURATIONS_UPDATE_PAGE_DISPLAY,
          updatePageIndex: index
        });
      } else {
        this.tempPageContent = updatePageContent;
        this.props.getPageData(updatePageContent._id);
        this.setState({
          currentTab: CONFIGURATIONS_UPDATE_PAGE_DISPLAY,
          tempPageIndex: index
        });
      }
    }
  };

  updateContent = data => {
    this.updatePageContent = data;
  };

  render() {
    const {
      currentTab,
      confirmModal,
      updatePageIndex,
      currentTabName
    } = this.state;
    const {
      sectionsData,
      activePage,
      createConfiguration,
      deleteConfiguration,
      updateConfigName,
      resetData,
      rollbackHistory,
      user
    } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.CONFIGURATIONS]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    const breadCrumArr = [];

    if (activePage?.name)
      breadCrumArr.push(activePage.name)

    return (
      <div className="row" style={{ "backgroundColor": "rgb(242, 245, 249)", "marginLeft": 0, "marginRight": 0, "paddingTop": "10px" }}>
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={
              "All the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        <div className="component-header">
          <div className="col-sm-3" style={{ "paddingLeft": "10px", "paddingRight": "5px" }}>
            <Paper
              sx={{
                padding: "20px 15px 15px 15px",
              }}
              variant="outlined"
            >
              <CountryDropDown changeTab={this.changeTab} />
              <SideBar
                createConfiguration={createConfiguration}
                deleteConfiguration={deleteConfiguration}
                updateConfigName={updateConfigName}
                currentTab={currentTab}
                initialConfigTabName={activePage && activePage.name}
                changeTab={this.changeTab}
                configurationsData={(sectionsData && sectionsData) || ""}
                handlePageClick={this.handlePageClick}
                currentTabName={currentTabName}
                closeRollbackModal={this.closeRollbackModal}
                onConfirmRollback={this.onConfirmRollback}
                rollbackHistory={rollbackHistory}
                userRole={user.role}
                accessRights={accessRights}
                getRollbackHistory={this.getRollbackHistory}
              />
            </Paper>
          </div>
          <div className="col-sm-9" style={{ "paddingLeft": "5px", "paddingRight": "10px" }}>
            <Subheader showCountry={true} breadcrumArr={breadCrumArr} />
            <RightPanel
              currentTab={currentTab}
              updatePageContent={activePage}
              updatePageIndex={updatePageIndex}
              changeTab={this.changeTab}
              updateContent={this.updateContent}
              resetData={resetData}
              accessRights={accessRights}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeId: state.store.storeId,
  countryId: state.store.countryId,
  storeList: state.store.storeList,
  selectedStoreName: state.store.selectedStoreName,
  sectionsData: state.configurationsReducer.initialData,
  sectionsPageR: state.configurationsReducer,
  activePage: state.configurationsReducer.activePage,
  rollbackHistory: state.configurationsReducer.rollbackHistory,
  resetData: state.configurationsReducer.resetData,
  user: state.authentication.user
});

const mapDispatchToProps = dispatch => ({
  changeTab: bindActionCreators(changeTab, dispatch),
  fetchConfigurationsPages: bindActionCreators(
    fetchConfigurationsPages,
    dispatch
  ),
  getPageData: bindActionCreators(getPageData, dispatch),
  setActivePage: bindActionCreators(setActivePage, dispatch),
  createConfiguration: bindActionCreators(createConfiguration, dispatch),
  deleteConfiguration: bindActionCreators(deleteConfiguration, dispatch),
  updateConfigName: bindActionCreators(updateConfigName, dispatch),
  resetDatas: bindActionCreators(resetDatas, dispatch),
  resetActivePage: bindActionCreators(resetActivePage, dispatch),
  setPagesRollbackHistory: bindActionCreators(setPagesRollbackHistory, dispatch),
  setPageRollback: bindActionCreators(setPageRollback, dispatch),
  getAllRollbackHistory: bindActionCreators(getAllRollbackHistory, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Configurations);
