import { generateUUID } from "../../util";

export const initialDynamicSlider = () => ({
  enable: false,
  sliders: [],
  type: "dynamicSliderModule",
  id: generateUUID(),
  autoPlay: "none",
  bulletType: "none",
  bulletColor: "#cccccc"
});
