import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { isEmpty } from "lodash";
import { NAVIGATION, NAVIGATION_MENUS_SLUGS, FLASH_SALE_PAGE_PANEL, FLASH_SALE_PAGE_MESSAGES } from "../../constants";
import { TextField, IconButton, InputAdornment, TablePagination, Paper } from "../../materialUi/components"
import { SearchIcon, TuneIcon } from "../../materialUi/icons"
import RightPanel from "./rightPanel";
import SideBar from "../sideBar";
import CountryDropDown from "../countryDropdownMUI";
import Subheader from "../../component/subheader"
import { changeTab } from "../../actions/common.action";
import {
  fetchFlashSaleData,
  fetchFlashSalePageContent,
  setActiveFlashPage,
  deleteFlashSaleUrl,
  addFlashSalePage,
  updateToggleData,
  updateFlashSalePageName,
  setSelectedSaleContentIndex,
} from "../../actions/flashSaleV2.action";
import { snackbar } from "../../actions/common.action";

class FlashSale extends Component {
  state = {
    currentTab: FLASH_SALE_PAGE_PANEL.EDIT_PAGE_DISPLAY,
    searchText: "",
    pageNumber: 0,
    rowsPerPage: 15,
    createNewPageData: { slug: "", name: "" }

  };

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.FLASH_SALE_V2);
  }

  componentDidMount() {
    const { pageNumber, rowsPerPage, searchText } = this.state;
    this.loadData(pageNumber, rowsPerPage, searchText);
  }

  componentDidUpdate(prevProps) {
    const { countryId: prevCountryId, activePage: prevActivePage = {} } = prevProps;
    const { countryId, activePage = {}, fetchFlashSalePageContent, setSelectedSaleContentIndex } = this.props;
    if (prevCountryId && countryId && prevCountryId !== countryId) {
      this.loadFistPageData();
    }
    if (activePage._id && prevActivePage._id !== activePage._id) {
      setSelectedSaleContentIndex(0);
      fetchFlashSalePageContent(activePage._id, countryId);
    }

  }

  loadData = (pageNumber, rowsPerPage, searchText) => {
    const { fetchFlashSaleData, countryId } = this.props;
    const limit = rowsPerPage;
    const skip = pageNumber * rowsPerPage;
    fetchFlashSaleData(limit, skip, searchText, countryId).then(res => {
      if (res.data?.code === 200) {
        this.setState({
          pageNumber,
          rowsPerPage,
          searchText,
          currentTab: FLASH_SALE_PAGE_PANEL.EDIT_PAGE_DISPLAY,
        });
      }
    });
  };

  loadFistPageData = () => {
    const { rowsPerPage } = this.state;
    this.setState({ pageNumber: 0, searchText: "", rowsPerPage });
    this.loadData(0, rowsPerPage, "");

  }

  handlePageClick = data => {
    const { limit } = this.state;

    let selected = data.selected;
    let offset = Math.ceil(selected * limit);
    let end = selected * limit + limit;
    let start = end - 49;
    this.setState(
      { skip: offset, startPage: start, endPage: end, currentPage: selected },
      () => {
        this.loadData();
      }
    );
  };

  handleSideNavPageClick = async (data) => {
    const { setActiveFlashPage } = this.props;
    await setActiveFlashPage(data);
  };

  pressEnter = e => {
    const { rowsPerPage, searchText } = this.state;
    e.preventDefault();
    if (e.key === "Enter") {
      this.setState({ pageNumber: 0, rowsPerPage, searchText });
      this.loadData(0, rowsPerPage, searchText);
    }
  };

  searchTextChangeHandler = e => {
    this.setState(
      {
        searchText: e.target.value || ""
      }
    );
  };

  updateToggle = (id, value, index) => {
    const { updateToggleData, countryId } = this.props;
    updateToggleData(id, { enabled: value, countryId }, index);
  };

  deleteFlashSaleUrlHandler = async (id) => {
    const { deleteFlashSaleUrl } = this.props;
    await deleteFlashSaleUrl(id);
    this.loadFistPageData();
  };

  addPagePopupContentHandler = e => {
    this.setState({ createNewPageData: { ...this.state.createNewPageData, [e.target.name]: e.target.value } });
  };

  createPageValidationHandler = ({ slug = "", name = "" }) => {
    const { snackbar } = this.props
    const specialCharRegex = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/; //Note:_ and - are allowed
    if (!name || name.trim() === "") {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.name_required })
      return false;
    }

    if (specialCharRegex.test(name)) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.name_spcl_chr })
      return false;
    }

    if (name.length > 20) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.name_max_len })
      return false;
    }

    if (name.trim() !== name) {
      snackbar({ open: true, severity: "error", text: `${FLASH_SALE_PAGE_MESSAGES.extraSpace} Name` })
      return false;
    }

    if (!slug || slug.trim() === "") {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.slug_required })
      return false;
    }
    if (slug.includes(" ")) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.slug_no_space_err })
      return false;
    }
    if (specialCharRegex.test(slug)) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.slug_spcl_chr })
      return false;
    }
    if (slug.length > 20) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.slug_max_len })
      return false;
    }
    return true;

  }

  updatePageValidationHandler = ({ name = "" }) => {
    const { snackbar } = this.props;
    const specialCharRegex = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/; //Note:_ and - are allowed
    if (!name || name.trim() === "") {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.name_required })
      return false;
    }
    if (specialCharRegex.test(name)) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.name_spcl_chr })
      return false;
    }
    if (name.length > 20) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.name_max_len })
      return false;
    }
    if (name.trim() !== name) {
      snackbar({ open: true, severity: "error", text: `${FLASH_SALE_PAGE_MESSAGES.extraSpace} Name` })
      return false;
    }
    return true;

  }

  createFlashSalePageHandler = async (data) => {
    const { slug = "", name = "" } = data || {}
    const { addFlashSalePage, countryId } = this.props;
    await addFlashSalePage({ slug, name, countryId });
    this.setState({ createNewPageData: { slug: "", name: "" } });
    this.loadFistPageData();

  }

  updateFlashSalePageUrlHandler = (id, index, { name }) => {
    const { updateFlashSalePageName, countryId } = this.props;
    updateFlashSalePageName(id, index, { name, countryId });
  }

  handleChangeRowsPerPage = (event) => {
    const { searchText } = this.state;
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), pageNumber: 0 });
    this.loadData(0, parseInt(event.target.value, 10), searchText)
  };

  handleChangePage = (event, pageNumber) => {
    const { rowsPerPage, searchText } = this.state;
    this.setState({ pageNumber });
    this.loadData(pageNumber, rowsPerPage, searchText);
  }

  render() {
    const {
      user,
      flashSaleList,
      activePage,
      countryId,
      pagesContent,
      initialPagesContent,
      flashSaleListCount,
    } = this.props;
    const {
      currentTab,
      searchText,
      createNewPageData,
      rowsPerPage,
      pageNumber,
    } = this.state;

    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.FLASH_SALE_V2]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    return (
      <div className="row" style={{ "backgroundColor": "rgb(242, 245, 249)", "marginLeft": 0, "marginRight": 0, "paddingTop": "10px" }}>
        <div className="component-header">
          <div className="col-sm-3" style={{ "paddingLeft": "10px", "paddingRight": "5px" }}>
            <Paper
              sx={{
                padding: "20px 15px 15px 15px",
              }}
              variant="outlined"
            >
              <CountryDropDown />
              <TextField
                label="Search Page"
                id="outlined-start-adornment"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment:
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>,
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <TuneIcon />
                      </IconButton>
                    </InputAdornment>
                }}
                value={searchText}
                onChange={this.searchTextChangeHandler}
                onKeyUp={this.pressEnter}
                placeholder="Search..."
                sx={{
                  marginBottom: "15px",
                }}
              />
              {/* Note: If needed we need to create seperate html inside sidebar for FlashSaleV2 */}
              <SideBar
                currentTab={currentTab}
                flashSaleData={flashSaleList || []}
                handlePageClick={this.handleSideNavPageClick}
                accessRights={accessRights}
                currentActiveFlashPageId={activePage._id}
                deleteFlashSalePageHandler={this.deleteFlashSaleUrlHandler}
                updateToggleFlashSaleHandler={this.updateToggle}
                createNewFlashSalePageData={createNewPageData}
                addFlashPagePopupContentHandler={this.addPagePopupContentHandler}
                createFlashSalePageHandler={this.createFlashSalePageHandler}
                createFlashPageValidationHandler={this.createPageValidationHandler}
                updateFlashPageValidationHandler={this.updatePageValidationHandler}
                updateFlashSalePageUrlHandler={this.updateFlashSalePageUrlHandler}
              />
              {flashSaleList.length !== 0 && (
                <TablePagination
                  component="div"
                  count={flashSaleListCount}
                  page={pageNumber}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                      "marginTop": "1em",
                      "marginBottom": "1em"
                    },
                  }}
                  rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                />
              )}
            </Paper>
          </div>
          <div className="col-sm-9" style={{ "paddingLeft": "5px", "paddingRight": "10px" }}>
            <Subheader showCountry={true} breadcrumArr={pagesContent.name ? [pagesContent.name] : []} />
            {!isEmpty(activePage) ?
              <RightPanel
                currentTab={currentTab}
                accessRights={accessRights}
                pageContent={pagesContent}
                initialPageContent={initialPagesContent}
                countryId={countryId}
              /> :
              <h3>No Sales Data Available</h3>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  flashSaleList: state.flashSaleV2Reducer.flashSaleList,
  activePage: state.flashSaleV2Reducer.activePage,
  countryId: state.store.countryId,
  flashSaleListCount: state.flashSaleV2Reducer.flashSaleListCount,
  pagesContent: state.flashSaleV2Reducer.pagesContent,
  initialPagesContent: state.flashSaleV2Reducer.initialPagesContent,

});

const mapDispatchToProps = dispatch => ({
  fetchFlashSaleData: bindActionCreators(fetchFlashSaleData, dispatch),
  changeTab: bindActionCreators(changeTab, dispatch),
  setActiveFlashPage: bindActionCreators(setActiveFlashPage, dispatch),
  deleteFlashSaleUrl: bindActionCreators(deleteFlashSaleUrl, dispatch),
  updateToggleData: bindActionCreators(updateToggleData, dispatch),
  fetchFlashSalePageContent: bindActionCreators(fetchFlashSalePageContent, dispatch),
  setSelectedSaleContentIndex: bindActionCreators(setSelectedSaleContentIndex, dispatch),
  snackbar: bindActionCreators(snackbar, dispatch),
  addFlashSalePage: bindActionCreators(addFlashSalePage, dispatch),
  updateFlashSalePageName: bindActionCreators(updateFlashSalePageName, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashSale);
