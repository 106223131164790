export const PAGES_PANEL = {
  PAGES_ADD_PAGE_DISPLAY: "pagesAddPageDisplay",
  PAGES_UPDATE_PAGE_DISPLAY: "pagesUpdatePageDisplay",
  PAGES_DELETE_PAGE_DISPLAY: "pagesDeletePageDisplay"
};

export const PAGES_PAGE = "PAGES_PAGE";

export const CONTAINER_TYPE = { DEFAULT: "default", SCHEDULED: "scheduled", VARIANT: "variant" };

export const SORT_DIRECTION = { ASC: "asc", DESC: "desc" };

export const HOMEPAGE_URL = "/";

export const ITEMS_COUNT_LIMIT = 10;

export const VARIANTS_COUNT_LIMIT = 1;

export const EXTERNAL_LINK_VALUE = {
  CSS: "css",
  JAVASCRIPT: "javascript"
}

export const externalLinkList = [
  { label: "CSS", value: EXTERNAL_LINK_VALUE.CSS },
]