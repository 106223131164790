import React, { useState } from "react";
import SubMenuItem from "./subMenuItems";
import { Button, Typography } from "../../materialUi/components";
import { ArrowDropDownIcon, ArrowRightIcon } from "../../materialUi/icons";
import { NAVIGATION_SLUG_MAPPING } from "../../constants";

const MenuItem = ({ menu, currentTab, depthLevel, permissions, navigate, handleParentClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAll = () => {
    handleClose();
    handleParentClose();
  }
  return (
    <div>
      <Button
        id={menu._id}
        aria-controls={open ? menu._id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={e => {
          if (!menu.children || !menu.children.length) {
            handleCloseAll();
            navigate(menu.url, menu.slug);
          }
          else {
            handleClick(e);
          }
        }}
        sx={{
          color: "black", minWidth: "80px",
          backgroundColor: NAVIGATION_SLUG_MAPPING[currentTab] === menu.slug ? "rgb(255, 209, 0)" : "FFFFFF",
          '&:hover': { backgroundColor: "rgb(255, 209, 0)" }
        }}
        endIcon={
          !!(menu.children?.length) ?
            <ArrowDropDownIcon fontSize="small" />
            : null
        }
      >
        <Typography textTransform={"capitalize"} fontSize={"15px"}>
          {menu.name}
        </Typography>
      </Button>
      {menu.children && menu.children.length ? (
        <SubMenuItem
          menus={menu.children}
          navigate={navigate}
          depthLevel={depthLevel + 1}
          currentTab={currentTab}
          permissions={permissions}
          anchorEl={anchorEl}
          open={open}
          id={menu._id}
          handleClose={handleClose}
          handleParentClose={handleCloseAll}
        />
      ) : null}
    </div>
  );
};

export default MenuItem;
