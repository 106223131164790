import * as React from "react";

export const GreyCross = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2406 18.15H18.1604V22.6875V27.225C18.1604 28.0273 17.8415 28.7967 17.2739 29.364C16.7063 29.9313 15.9364 30.25 15.1337 30.25C14.3309 30.25 13.5611 29.9313 12.9935 29.364C12.4258 28.7967 12.1069 28.0273 12.1069 27.225V18.15H18.1604H3.02674C2.22399 18.15 1.45413 17.8313 0.88651 17.264C0.318887 16.6967 0 15.9273 0 15.125C0 14.3227 0.318887 13.5533 0.88651 12.986C1.45413 12.4187 2.22399 12.1 3.02674 12.1H18.1604H12.1069V3.025C12.1069 2.22272 12.4258 1.4533 12.9935 0.886002C13.5611 0.318704 14.3309 0 15.1337 0C15.9364 0 16.7063 0.318704 17.2739 0.886002C17.8415 1.4533 18.1604 2.22272 18.1604 3.025V12.1H27.2406C28.0434 12.1 28.8132 12.4187 29.3808 12.986C29.9485 13.5533 30.2673 14.3227 30.2673 15.125C30.2673 15.9273 29.9485 16.6967 29.3808 17.264C28.8132 17.8313 28.0434 18.15 27.2406 18.15Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};
