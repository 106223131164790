import React, { Fragment } from "react";
import get from "lodash/get";
import BrandChartHead from "./brandChartHead";
import BrandsChartList from "./brandsChartList";

const BrandsChartContainer = ({
  apiContent,
  changePageType,
  changeSelectedBrand,
  countryId,
  handleBrandDelete,
  handleBrandToggle,
  handleInputTextChange,
  selectedTab,
  storeId,
  updateBrandSizeChart,
  updateSizeChartBrandContentApi,
  accessRights
}) => (
  <Fragment>
    <BrandChartHead
      apiContent={apiContent}
      countryId={countryId}
      handleInputTextChange={handleInputTextChange}
      selectedTab={selectedTab}
      storeId={storeId}
      updateBrandSizeChart={updateBrandSizeChart}
      updateSizeChartBrandContentApi={updateSizeChartBrandContentApi}
      accessRights={accessRights}
    />
    <BrandsChartList
      apiContent={apiContent}
      brandList={get(updateBrandSizeChart, [selectedTab, "brandList"], [])}
      changePageType={changePageType}
      changeSelectedBrand={changeSelectedBrand}
      countryId={countryId}
      enabled={get(updateBrandSizeChart, [selectedTab, "enabled"], [])}
      handleBrandDelete={handleBrandDelete}
      handleBrandToggle={handleBrandToggle}
      selectedTab={selectedTab}
      accessRights={accessRights}
    />
  </Fragment>
);

export default BrandsChartContainer;
