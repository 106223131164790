import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import ImageSelectionModal from "../modal/imageSelectionModal";
import ConfirmModal from "../modal/ConfirmModal";
import {
  categoriesChangeHandlerSelectedGenericSubCategory,
  changeHandlerGenericSizeChart,
  changeSelectedGenericSubCategory,
  clearSelectedGenericSubCategory,
  handleGenericSubCategoryDelete,
  resetSizeChartGenericContent,
  submitGenericSubCategoryList,
  textChangeHandlerGenericSizeChart,
  textChangeHandlerSelectedGenericSubCategory,
  updateGenericSizeChartListOrder,
  updateSizeChartGenericContentApi
} from "../../actions/sizechart.action";
import { toastMsg } from "../../actions/common.action";
import { LANGUAGE, ERROR_MESSAGES } from "../../constants";
import Button from "../../component/button";
import InputText from "../../component/InputText";
import InputImageForm from "../../component/InputImageForm";
import CustomDragAndDrop from "../../component/customDragAndDrop";
import GenericSubCategory from "./genericSubCategory";

class genericSubCategoryList extends Component {
  state = {
    confirmModal: false,
    enable: false,
    imageSelectionModal: false,
    language: null,
    modalText: "",
    newSubCat: false,
    showContent: { index: 0, show: false }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      updateGenericSizeChart,
      selectedGenericCategoryIndex,
      selectedTab,
      apiContent
    } = nextProps;
    const data = get(
      updateGenericSizeChart,
      [selectedTab, selectedGenericCategoryIndex],
      {}
    );
    const apiData = get(
      apiContent,
      ["genericSizeChart", selectedTab, selectedGenericCategoryIndex],
      {}
    );
    const englishData = get(data, "english", {});
    const arabicData = get(data, "arabic", {});
    const dataSubCategory = get(data, "subCategory");

    const apiEnglishData = get(apiData, "english", {});
    const apiArabicData = get(apiData, "arabic", {});
    const apiSubCategory = get(apiData, "subCategory");

    if (data.id === "clothing" || data.name === "Clothing") {
      if (!isEmpty(data)) {
        this.setState({
          enable:
            !isEqual(englishData, apiEnglishData) ||
            !isEqual(arabicData, apiArabicData) ||
            !isEqual(dataSubCategory, apiSubCategory)
        });
      } else {
        this.setState({ enable: false });
      }
    } else {
      if (!isEmpty(data)) {
        this.setState({
          enable:
            !isEqual(englishData, apiEnglishData) ||
            !isEqual(arabicData, apiArabicData) ||
            !isEqual(dataSubCategory, apiSubCategory)
        });
      } else {
        this.setState({ enable: false });
      }
    }
  }

  UNSAFE_componentWillMount() {
    const {
      changeSelectedGenericSubCategory,
      selectedGenericCategoryIndex,
      selectedTab,
      updateGenericSizeChart
    } = this.props;
    changeSelectedGenericSubCategory({
      english: { html: "", heading: "" },
      arabic: { html: "", heading: "" }
    });
    const subCategoryList = get(
      updateGenericSizeChart,
      [selectedTab, selectedGenericCategoryIndex, "list"],
      []
    );
    if (isEmpty(subCategoryList)) {
      this.setState({ newSubCat: true });
    }
  }

  toggleContent = (index, item) => {
    const {
      selectedGenericSubCategory,
      updatedSelectedGenericSubCategory,
      handleSubCategoryTileChange,
      changeSelectedGenericSubCategory
    } = this.props;

    if (
      !isEqual(selectedGenericSubCategory, updatedSelectedGenericSubCategory)
    ) {
      handleSubCategoryTileChange(() =>
        this.handleToggleSubCategoryTileChange(index, item)
      );
    } else {
      changeSelectedGenericSubCategory(item);
      const { showContent } = this.state;
      if (index === showContent.index) {
        this.setState({
          showContent: { index, show: !showContent.show },
          newSubCat: false
        });
      } else {
        this.setState({
          showContent: { index, show: true },
          newSubCat: false
        });
      }
    }
  };

  handleToggleSubCategoryTileChange = (index, item) => {
    this.props.changeSelectedGenericSubCategory(item);
    const { showContent } = this.state;
    if (index === showContent.index) {
      this.setState({
        showContent: { index, show: !showContent.show },
        newSubCat: false
      });
    } else {
      this.setState({
        showContent: { index, show: true },
        newSubCat: false
      });
    }
  };

  addSubCategory = () => {
    const {
      selectedGenericSubCategory,
      updatedSelectedGenericSubCategory,
      handleSubCategoryTileChange,
      changeSelectedGenericSubCategory
    } = this.props;
    if (
      !isEqual(selectedGenericSubCategory, updatedSelectedGenericSubCategory)
    ) {
      handleSubCategoryTileChange(this.handleSubCategoryTileChange);
    } else {
      changeSelectedGenericSubCategory({
        english: { html: "", heading: "" },
        arabic: { html: "", heading: "" }
      });
      this.setState({
        newSubCat: true,
        showContent: { index: 0, show: false }
      });
    }
  };

  handleSubCategoryTileChange = () => {
    const { changeSelectedGenericSubCategory } = this.props;
    changeSelectedGenericSubCategory({
      english: { html: "", heading: "" },
      arabic: { html: "", heading: "" }
    });
    this.setState({ newSubCat: true, showContent: { index: 0, show: false } });
  };

  handleModal = (modalName, keyIndex, language) => {
    this.setState(prevState => ({
      [modalName]: !prevState[modalName],
      language
    }));
  };

  updateURL = (key, value, storeId, language, modalName) => {
    const {
      textChangeHandlerGenericSizeChart,
      selectedTab,
      selectedGenericCategoryIndex
    } = this.props;
    if (modalName) {
      this.setState(
        prevState => ({ [modalName]: !prevState[modalName], language }),
        () => {
          textChangeHandlerGenericSizeChart(
            selectedTab,
            selectedGenericCategoryIndex,
            key,
            value,
            language
          );
        }
      );
    } else {
      this.setState({ language }, () => {
        textChangeHandlerGenericSizeChart(
          selectedTab,
          selectedGenericCategoryIndex,
          key,
          value,
          language
        );
      });
    }
  };

  textChangeHandler = (e, language) => {
    const {
      textChangeHandlerGenericSizeChart,
      selectedTab,
      selectedGenericCategoryIndex
    } = this.props;
    textChangeHandlerGenericSizeChart(
      selectedTab,
      selectedGenericCategoryIndex,
      e.target.name,
      e.target.value,
      language
    );
  };

  textChangeHandlerSubCategory = (key, value, language) => {
    const { textChangeHandlerSelectedGenericSubCategory } = this.props;
    textChangeHandlerSelectedGenericSubCategory(key, value, language);
  };

  categoryChangeHandlerForSubCategory = categories => {
    const { categoriesChangeHandlerSelectedGenericSubCategory } = this.props;
    categoriesChangeHandlerSelectedGenericSubCategory(categories);
  };

  validateFormData = (formData, language) => {
    const { toastMsg } = this.props;
    const { heading = "", subHeading = "" } = formData || {};

    if (heading && (heading !== heading.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Heading`);
      return false;
    }
    if (subHeading && (subHeading !== subHeading.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Sub Heading`);
      return false;
    }

    return true;
  }

  handleSave = () => {
    const {
      updateGenericSizeChart,
      selectedTab,
      countryId,
      apiContent,
      updateSizeChartGenericContentApi,
      selectedGenericCategoryIndex
    } = this.props;
    const data = get(
      updateGenericSizeChart,
      [selectedTab, selectedGenericCategoryIndex],
      {}
    );

    const { english = {}, arabic = {} } = data || {};

    const isValidEnglishForm = this.validateFormData(english, "English");
    if (!isValidEnglishForm) return;

    const isValidArabicForm = this.validateFormData(arabic, "Arabic");
    if (!isValidArabicForm) return;


    if (data) {
      updateSizeChartGenericContentApi({
        type: selectedTab,
        id: apiContent._id,
        countryId,
        recordId: data._id,
        body: {
          english: data.english,
          arabic: data.arabic,
          subCategory: data.subCategory
        },
        name: data.name
      });
    }
  };

  handleCancel = () => {
    const {
      resetSizeChartGenericContent,
      selectedTab,
      selectedGenericCategoryIndex
    } = this.props;
    resetSizeChartGenericContent(selectedTab, selectedGenericCategoryIndex);
  };

  handleConfirmModal = (func, data = "") => {
    this.setState({
      confirmModal: true,
      tempDeleteData: data,
      deleteFunc: func,
      modalText: "Are you sure you want to cancel the changes ?"
    });
  };

  confirmModal = () => {
    const { deleteFunc, tempDeleteData } = this.state;
    const { index } = this.props;
    deleteFunc(index, tempDeleteData);
    this.hideModal();
  };

  hideModal = () => this.setState({ confirmModal: false });

  removeSubCategory = (index, item) => {
    const {
      updateGenericSizeChart,
      selectedTab,
      countryId,
      apiContent,
      handleGenericSubCategoryDelete,
      selectedGenericCategoryIndex
    } = this.props;
    const data = get(
      updateGenericSizeChart,
      [selectedTab, selectedGenericCategoryIndex],
      {}
    );
    const payload = {
      type: selectedTab,
      countryId,
      recordId: data._id,
      subCategoryId: item._id
    };
    if (data && data._id) {
      handleGenericSubCategoryDelete(apiContent._id, payload).then(res => {
        const {
          changeSelectedGenericSubCategory,
          selectedTab,
          selectedGenericCategoryIndex
        } = this.props;
        changeSelectedGenericSubCategory({
          english: { html: "", heading: "" },
          arabic: { html: "", heading: "" }
        });
        let updateGenericSizeChart = res.data.data.result.genericSizeChart;
        const _subCatList = get(
          updateGenericSizeChart,
          [selectedTab, selectedGenericCategoryIndex, "list"],
          []
        );
        this.setState({
          newSubCat: _subCatList && !_subCatList.length
        });
      });
    }
  };

  saveSubCategory = () => {
    const {
      updatedSelectedGenericSubCategory,
      updateGenericSizeChart,
      selectedTab,
      countryId,
      apiContent,
      submitGenericSubCategoryList,
      selectedGenericCategoryIndex
    } = this.props;

    const { english = {}, arabic = {} } = updatedSelectedGenericSubCategory || {};

    const isValidEnglishForm = this.validateFormData(english, "English");
    if (!isValidEnglishForm) return;

    const isValidArabicForm = this.validateFormData(arabic, "Arabic");
    if (!isValidArabicForm) return;


    const data = get(
      updateGenericSizeChart,
      [selectedTab, selectedGenericCategoryIndex],
      {}
    );
    const payload = {
      type: selectedTab,
      countryId,
      recordId: data._id,
      subCategoryObj: {
        english: get(updatedSelectedGenericSubCategory, "english", {}),
        arabic: get(updatedSelectedGenericSubCategory, "arabic", {}),
        pos: get(updatedSelectedGenericSubCategory, "pos", 0),
        subCategory: get(updatedSelectedGenericSubCategory, "subCategory", [])
      },
      name: data.name
    };
    if (
      updatedSelectedGenericSubCategory &&
      updatedSelectedGenericSubCategory._id
    ) {
      payload["subCategoryObj"]["_id"] = updatedSelectedGenericSubCategory._id;
    }
    data &&
      submitGenericSubCategoryList(apiContent._id, payload).then(res => {
        const {
          changeSelectedGenericSubCategory,
          selectedTab,
          selectedGenericCategoryIndex
        } = this.props;
        changeSelectedGenericSubCategory(updatedSelectedGenericSubCategory);
        let updateGenericSizeChart = res.data.data.result.genericSizeChart;
        const _subCatList = get(
          updateGenericSizeChart,
          [selectedTab, selectedGenericCategoryIndex, "list"],
          []
        );
        setTimeout(() => {
          this.setState({
            newSubCat: _subCatList && !_subCatList.length
          });
        }, 1000);
      });
  };

  cancelSubCategory = () => {
    const { newSubCat } = this.state;
    const {
      selectedGenericSubCategory,
      changeSelectedGenericSubCategory,
      clearSelectedGenericSubCategory
    } = this.props;
    if (!newSubCat) {
      changeSelectedGenericSubCategory(selectedGenericSubCategory);
    } else {
      clearSelectedGenericSubCategory();
    }
  };

  render() {
    const {
      confirmModal,
      enable,
      imageSelectionModal,
      language,
      modalText,
      newSubCat,
      showContent
    } = this.state;

    const {
      countryId,
      selectedGenericCategoryIndex,
      selectedGenericSubCategory,
      selectedTab,
      storeId,
      subCategoryList,
      updatedSelectedGenericSubCategory,
      updateGenericSizeChart,
      updateGenericSizeChartListOrder
    } = this.props;

    const genericCategoriesContent = get(
      updateGenericSizeChart,
      selectedTab,
      []
    );
    const content = get(
      updateGenericSizeChart,
      [selectedTab, selectedGenericCategoryIndex],
      {}
    );

    return (
      <Fragment>
        {imageSelectionModal && (
          <ImageSelectionModal
            title="Upload Image"
            modalName={"imageSelectionModal"}
            keyName={"imageUrl"}
            brandId={storeId}
            storeId={storeId}
            countryId={countryId}
            language={language}
            containerName={"genericSizeChart"}
            onHide={this.handleModal}
            onSuccess={this.updateURL}
          />
        )}
        {confirmModal && (
          <ConfirmModal
            modalName={""}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={modalText}
          />
        )}
        {content && (
          <div className="wrapper-box size-chart">
            <div className="data-wrapper">
              <div className="english-wrapper">
                <h4>English Version</h4>
                <div className="english-content">
                  <div
                    className={cn("form-group", {
                      marginBottom: !(
                        content.id === "clothing" || content.name === "Clothing"
                      )
                    })}
                  >
                    <InputText
                      name="heading"
                      label="Heading Text"
                      placeholder="Enter Heading Text"
                      value={get(content, "english.heading", "")}
                      onChange={e => this.textChangeHandler(e, "english")}
                      required
                    />
                  </div>
                  {content.id === "clothing" ||
                    (content.name === "Clothing" && (
                      <div className="form-group marginBottom">
                        <InputText
                          name="subHeading"
                          label="Sub Heading Text"
                          placeholder="Enter Sub Heading Text"
                          value={get(content, "english.subHeading", "")}
                          onChange={e => this.textChangeHandler(e, "english")}
                          required
                        />
                      </div>
                    ))}
                  <InputImageForm
                    name="imageUrl"
                    label="Add Image"
                    containerName="genericSizeChart"
                    language={LANGUAGE.ENGLISH}
                    value={get(content, "english.imageUrl", "")}
                    onChange={this.updateURL}
                    required
                  />
                </div>
              </div>
              <div className="arabic-wrapper">
                <h4>Arabic Version</h4>
                <div className="arabic-content">
                  <div
                    className={cn("form-group", {
                      marginBottom: !(
                        content.id === "clothing" || content.name === "Clothing"
                      )
                    })}
                  >
                    <InputText
                      name="heading"
                      label="Heading Text"
                      placeholder="Enter Heading Text"
                      language={LANGUAGE.ARABIC}
                      value={get(content, "arabic.heading", "")}
                      onChange={e => this.textChangeHandler(e, "arabic")}
                      required
                    />
                  </div>
                  {content.id === "clothing" ||
                    (content.name === "Clothing" && (
                      <div className="form-group marginBottom">
                        <InputText
                          name="subHeading"
                          label="Sub Heading Text"
                          placeholder="Enter Sub Heading Text"
                          language={LANGUAGE.ARABIC}
                          value={get(content, "arabic.subHeading", "")}
                          onChange={e => this.textChangeHandler(e, "arabic")}
                          required
                        />
                      </div>
                    ))}
                  <InputImageForm
                    name="imageUrl"
                    label="Add Image"
                    containerName="genericSizeChart"
                    language={LANGUAGE.ARABIC}
                    value={get(content, "arabic.imageUrl", "")}
                    onChange={this.updateURL}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <Button
          name={"Save"}
          disabled={false}
          onClick={() => {
            if (enable) this.handleSave();
          }}
          customClass={cn("marginBottom", {
            "opacity disable-btn": !enable
          })}
        />
        <Button
          name={"Cancel"}
          disabled={false}
          onClick={() => {
            if (enable) this.handleConfirmModal(this.handleCancel);
          }}
          customClass={cn("marginBottom", {
            "opacity disable-btn": !enable
          })}
        />
        <CustomDragAndDrop
          items={content.list}
          updateItemsOrder={updateGenericSizeChartListOrder}
        >
          <GenericSubCategory
            cancel={this.cancelSubCategory}
            contentId={content.id}
            contentName={content.name}
            countryId={countryId}
            handleConfirmModal={this.handleConfirmModal}
            newItem={false}
            remove={this.removeSubCategory}
            save={this.saveSubCategory}
            genericCategoriesContent={genericCategoriesContent}
            selectedGenericCategoryIndex={selectedGenericCategoryIndex}
            selectedGenericSubCategory={selectedGenericSubCategory}
            showContent={showContent}
            storeId={storeId}
            subCategoryList={subCategoryList}
            textChangeHandlerSubCategory={this.textChangeHandlerSubCategory}
            toggleContent={this.toggleContent}
            updatedSelectedGenericSubCategory={
              updatedSelectedGenericSubCategory
            }
            categoryChangeHandlerForSubCategory={
              this.categoryChangeHandlerForSubCategory
            }
          />
        </CustomDragAndDrop>
        {newSubCat && (
          <GenericSubCategory
            storeId={storeId}
            countryId={countryId}
            content={{}}
            index={0}
            contentId={content.id}
            contentName={content.name}
            newItem={true}
            remove={this.removeSubCategory}
            save={this.saveSubCategory}
            cancel={this.cancelSubCategory}
            showContent={showContent}
            toggleContent={this.toggleContent}
            subCategoryList={subCategoryList}
            genericCategoriesContent={genericCategoriesContent}
            selectedGenericCategoryIndex={selectedGenericCategoryIndex}
            textChangeHandlerSubCategory={this.textChangeHandlerSubCategory}
            selectedGenericSubCategory={selectedGenericSubCategory}
            updatedSelectedGenericSubCategory={
              updatedSelectedGenericSubCategory
            }
            categoryChangeHandlerForSubCategory={
              this.categoryChangeHandlerForSubCategory
            }
            handleConfirmModal={this.handleConfirmModal}
          />
        )}
        {content.id === "clothing" ||
          (content.name === "Clothing" && (
            <Button
              name="Add Sub Category Heading"
              customClass="marginBottom"
              onClick={this.addSubCategory}
            />
          ))}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  apiContent: state.sizeChart.apiContent,
  countryId: state.store.countryId,
  selectedBrand: state.sizeChart.selectedBrand,
  selectedCategory: state.sizeChart.selectedCategory,
  selectedGenericCategoryIndex: state.sizeChart.selectedGenericCategoryIndex,
  selectedGenericSubCategory: state.sizeChart.selectedGenericSubCategory,
  selectedTab: state.sizeChart.selectedTab,
  showNav: state.sizeChart.showNav,
  storeId: state.store.storeId,
  subCategoryList: state.sizeChart.subCategoryList,
  updatedSelectedGenericSubCategory:
    state.sizeChart.updatedSelectedGenericSubCategory,
  updateGenericSizeChart: state.sizeChart.updateGenericSizeChart
});

const mapDispatchToProps = {
  categoriesChangeHandlerSelectedGenericSubCategory,
  changeHandlerGenericSizeChart,
  changeSelectedGenericSubCategory,
  clearSelectedGenericSubCategory,
  handleGenericSubCategoryDelete,
  resetSizeChartGenericContent,
  submitGenericSubCategoryList,
  textChangeHandlerGenericSizeChart,
  textChangeHandlerSelectedGenericSubCategory,
  updateGenericSizeChartListOrder,
  updateSizeChartGenericContentApi,
  toastMsg,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(genericSubCategoryList);
