export const MEGA_MENU_LANG_MAP = {
  english: "updateEnglishContent",
  arabic: "updateArabicContent"
};

export const MEGA_MENU_ATTRIB_LANG_MAP = {
  english: "englishAttributeData",
  arabic: "arabicAttributeData"
};

export const MEGA_MENU_FONT_COLOR_OPTIONS = [
  { id: 1, value: "#ff0000", label: "red" },
  { id: 2, value: "#000000", label: "black" },
  { id: 3, value: "#acacac", label: "light grey" }
];

export const MEGA_MENU_COLUMN_KEYS = {
  col1: "Column 1",
  col2: "Column 2",
  col3: "Column 3",
  col4: "Column 4",
  col5: "Column 5",
  col6: "Column 6"
};

export const MEGA_MENU_OPTIONAL_SELECT_ATTRIBUTE_TYPES = {
  ATTRIBUTE: "attribute",
  IMAGE: "image"
};
