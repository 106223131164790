import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import { pagesFoldersShape } from "../containers/pagesPage/pagesFoldersShape";
import {
  SET_ACTIVE_PAGE,
  CHANGE_PAGES_FOLDER_NAME,
  CREATE_NEW_FOLDER,
  DELETE_FOLDER,
  DELETE_PAGE,
  SET_PAGE_DATA,
  SET_PAGES_ROUTE,
  UPDATE_OPENED_CONTAINERS,
  UPDATE_OPENED_FOLDERS,
  SET_PAGES,
  ADD_NEW_COMPONENT,
  UPDATE_PAGE_DATA,
  PAGE_OPEN_COMPONENT,
  PAGE_DELETE_COMPONENT,
  PAGE_TOGGLE_COMPONENT,
  CLONE_MODULE_PAGES_PAGE,
  REORDER_COMPONENTS,
  MODULE_DATA_CHANGE_PAGES_PAGE,
  ADD_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE,
  DELETE_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE,
  TOGGLE_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE,
  SCHEDULER_DATE_CHANGE_DEVICE_LEVEL_PAGES_PAGE,
  GENERATE_IMAGES_PAGES_PAGE,
  TOGGLE_LIST_ITEM_PAGES_PAGE,
  DELETE_LIST_ITEM_PAGES_PAGE,
  UPDATE_LIST_DATA_PAGES_PAGE,
  UPDATE_BANNER_SECTION_DATA_STYLES_PAGES_PAGE,
  PUT_BANNER_DATA_STYLES_PAGES_PAGE,
  CLEAR_BANNER_ROW_IMAGE_DATA_PAGES_PAGE,
  UPDATE_BANNER_SECTION_DATA_PAGES_PAGE,
  ADD_BANNER_SCHEDULER_PAGES_PAGE,
  HANDLE_DELETE_BANNER_SCHEDULER_PAGES_PAGE,
  HANDLE_DATA_CHANGE_BANNER_SCHEDULER_PAGES_PAGE,
  HANDLE_ROW_SCHEDULER_TOGGLE_PAGES_PAGE,
  CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_PAGES_PAGE,
  COPY_CONTENT_TO_SCHEDULER_PAGES_PAGE,
  CLONE_ROW_PAGES_PAGE,
  CLONE_SCHEDULER_PAGES_PAGE,
  ADD_BANNER_ROW_ITEM_PAGES_PAGE,
  ADD_BANNER_SLIDER_ROW_IMAGES_PAGES_PAGE,
  DELETE_ROW_IMAGE_PAGES_PAGE,
  REORDER_BANNER_ROW_IMAGES_PAGES_PAGE,
  ITEMS_PER_GROUP_CHANGE_PAGES_PAGE,
  PRODUCT_CAROUSEL_DATA_CHANGE_PAGES_PAGE,
  UPDATE_ROW_TYPE_CUSTOM_DATA_PAGES_PAGE,
  ADD_ROW_TYPE_CUSTOM_FIELD_PAGES_PAGE,
  UPDATE_ROW_TYPE_CUSTOM_MODULE_PAGES_PAGE,
  REMOVE_ROW_TYPE_CUSTOM_FIELD_PAGES_PAGE,
  ADD_ROW_TYPE_ACCORDION_FIELD_PAGES_PAGE,
  UPDATE_ROW_TYPE_ACCORDION_DATA_PAGES_PAGE,
  UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_PAGES_PAGE,
  REMOVE_ROW_TYPE_ACCORDION_FIELD_PAGES_PAGE,
  ADD_ROW_TYPE_BULLET_FIELD_PAGES_PAGE,
  UPDATE_ROW_TYPE_BULLET_DATA_PAGES_PAGE,
  UPDATE_ROW_TYPE_BULLET_HEADER_DATA_PAGES_PAGE,
  REMOVE_ROW_TYPE_BULLET_FIELD_PAGES_PAGE,
  ADD_ROW_TYPE_TAB_FIELD_PAGES_PAGE,
  UPDATE_ROW_TYPE_TAB_DATA_PAGES_PAGE,
  UPDATE_ROW_TYPE_TABS_PAGES_PAGE,
  REMOVE_ROW_TYPE_TAB_PAGES_PAGE,
  CHANGE_BANNER_IMAGE_HOVER_STYLE_PAGES_PAGE,
  UPDATE_BANNER_ROW_IMAGE_DATA_PAGES_PAGE,
  COUNTDOWN_DATA_CHANGE_PAGES_PAGE,
  UPDATE_BANNER_DEFAULT_TEXT_PAGES_PAGE,
  HANDLE_DATE_CHANGE_PAGES_PAGE,
  SCHEDULER_TEXT_CHANGE_PAGES_PAGE,
  HANDLE_TOGGLE_PAGES_PAGE,
  ADD_NEW_SCHEDULER_PAGES_PAGE,
  DELETE_SCHEDULER_PAGES_PAGE,
  ADD_IMAGE_LIST_PAGES_PAGE,
  SCHEDULER_ADD_IMAGE_LIST,
  UPDATE_SCHEDULER_BANNER_DEFAULT_TEXT_PAGES_PAGE,
  TYPE_B_UPDATE_HANDLE_DATE_CHANGE_PAGES_PAGE,
  DELETE_IMAGE_PAGES_PAGE,
  EDIT_IMAGE_LIST_PAGES_PAGE,
  HANDLE_IMAGE_TOGGLE_PAGES_PAGE,
  SCHEDULER_DELETE_IMAGE_PAGES_PAGE,
  SCHEDULER_HANDLE_IMAGE_TOGGLE_PAGES_PAGE,
  SCHEDULER_EDIT_IMAGE_LIST_PAGES_PAGE,
  UPDATE_TYPE2_TEXT_PAGES_PAGE,
  UPDATE_TYPE_B_SCHEDULER_TEXT_CHANGE_PAGES_PAGE,
  TOGGLE_RESET,
  RESET_PAGES_CONTENT,
  SELECT_NEW_PAGE_FOLDER,
  SET_NEW_PAGE_FOLDER,
  RESET_PAGES_PAGE,
  SCHEDULER_DATA_CHANGE_DEVICE_LEVEL_PAGES_PAGE,
  CLONE_MOBILE_PAGES_PAGE,
  SET_NEW_ADDED_PAGE,
  SET_NEW_ADDED_FOLDER,
  COPY_PARENT_CONTENT_TO_SCHEDULER_PAGES_PAGE,
  APPLY_GENERAL_SPACING_PAGES_PAGE,
  CLONE_DYNAMIC_MODULE_PAGES_PAGE,
  SET_PAGES_BY_COUNTRY,
  SET_PAGES_ROLLBACK_hISTORY,
  TOGGLE_ROLLBACK_SAVE,
  CLONE_BANNER_SCHEDULER_PAGES_PAGE,
  ADD_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE,
  REMOVE_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE,
  DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE,
  ADD_ROW_TYPE_COLUMN_MENU_PAGES_PAGE,
  UPDATE_ROW_TYPE_COLUMN_MENU_PAGES_PAGE,
} from "../constants/actions/";
import { mapPagesPageToFlatStructure } from "../util/reducer";
import {
  bannerDesktopBackgroundOptions,
  bannerGradientTypes,
  bannerTypeValueConstants,
  dynamicBannerItems,
  HOMEPAGE_SECTION_TYPES,
  initialBannerSection,
  initialDeviceSettings,
  initialDynamicBannerRow,
  initialDynamicBannerSection,
  initialImage,
  initialImageCarouselSection,
  initialProductCarouselSection,
  rowWidthOptionsDesktop,
  rowWidthValues,
  customModuleNameConstants,
  SCHEDULER_DATA_BY_TYPE,
  SCREEN_TYPES,
  tabBannerTypeNavigationType
} from "../constants";
import { initialDynamicSlider } from "../constants/dynamicSlider";
import { generateUUID, arrayReorder } from "../util";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";

const initialState = {
  pagesFoldersShape,
  pagesContent: {},
  initialPagesContent: {},
  newFolder: null,
  activePage: null,
  pagesRoute: { folder: {}, page: {} },
  resetToggled: false,
  openedContainerIds: [],
  openedFolderIds: [],
  newAddedFolder: null,
  newAddedPage: null,
  PagesByCountry: [],
  rollbackHistory: [],
  rollbackSave: false,
  cloneRowToBannerScheduler: false,
  rowToBannerScheduler: false
};

const setActivePage = (state, action) => {
  return { ...state, activePage: action.payload };
};

const changePagesFolderName = (state, action) => {
  const { folder, newTitle } = action.payload;

  const newPagesData = cloneDeep(state.pagesFoldersShape);
  set(newPagesData, `folders.${folder.id}.title`, newTitle);

  return { ...state, pagesFoldersShape: newPagesData };
};

const createNewFolder = (state, action) => {
  const { parent, folderName } = action.payload;

  const newPagesData = cloneDeep(state.pagesFoldersShape);
  const newFolderId = `temp-folder-${Date.now()}`;
  const newFolder = { id: newFolderId, pageIds: [], title: folderName };
  set(newPagesData, `folders.${newFolderId}`, newFolder);
  const parentIndex = newPagesData.pageContainers.findIndex(
    el => el.id === parent.id
  );
  set(newPagesData, `pageContainers[${parentIndex}].folderIds`, [
    newFolderId,
    ...parent.folderIds
  ]);

  return { ...state, pagesFoldersShape: newPagesData };
};

const deleteFolder = (state, action) => {
  const { parent, folder } = action.payload;
  const newPagesData = cloneDeep(state.pagesFoldersShape);
  const folderIndex = newPagesData.pageContainers.findIndex(
    el => el.id === parent.id
  );
  const folderIds = newPagesData.pageContainers[folderIndex].folderIds;
  const folderIndexInFolderIds = folderIds.findIndex(el => el === folder.id);
  folderIds.splice(folderIndexInFolderIds, 1);
  delete newPagesData.folders[folder.id];
  return { ...state, pagesFoldersShape: newPagesData };
};

const deletePage = (state, action) => {
  const { parent, page } = action.payload;
  const newPagesData = cloneDeep(state.pagesFoldersShape);
  const pageIndex = newPagesData.folders[parent.id].pageIds.findIndex(
    el => el === page.id
  );
  newPagesData.folders[parent.id].pageIds.splice(pageIndex, 1);
  delete newPagesData.pages[page.id];

  return { ...state, pagesFoldersShape: newPagesData };
};

const setPages = (state, action) => {
  const pagesFoldersShape = mapPagesPageToFlatStructure(action.payload);
  return {
    ...state,
    pagesFoldersShape
  };
};

const setPagesByCountry = (state, action) => {
  const PagesByCountry = mapPagesPageToFlatStructure(action.payload);
  const pages = PagesByCountry.pages;
  const pageStructure = Object.keys(pages).map(index => {
    let page = pages[index];
    return page;
  });

  return {
    ...state,
    PagesByCountry: pageStructure
  };
};

const setPageData = (state, action) => {
  return {
    ...state,
    pagesContent: action.payload,
    initialPagesContent: cloneDeep(action.payload)
  };
};

const toggleReset = (state, { value }) => ({
  ...state,
  resetToggled: value
});

const resetPagesContent = state => ({
  ...state,
  pagesContent: cloneDeep(state.initialPagesContent)
});

const selectNewPageFolder = (state, { folder }) => {
  if (folder.id === state.pagesRoute.folder.id) {
    return { ...state, newFolder: null };
  } else {
    return { ...state, newFolder: folder };
  }
};

const setNewPageFolder = (state, { newFolder }) => ({ ...state, newFolder });

const setPagesRoute = (state, action) => ({
  ...state,
  pagesRoute: action.payload
});

const updateOpenedContainers = (state, action) => {
  const { container, keepOpen, closeAllExceptPassed } = action.payload;
  const { id } = container;
  if (closeAllExceptPassed) {
    return {
      ...state,
      openedContainerIds: [...initialState.openedContainerIds, id]
    };
  }
  if (state.openedContainerIds.includes(id)) {
    if (keepOpen) {
      return { ...state };
    } else {
      return {
        ...state,
        openedContainerIds: state.openedContainerIds.filter(el => el !== id)
      };
    }
  }
  return {
    ...state,
    openedContainerIds: [...state.openedContainerIds, id]
  };
};

const updateOpenedFolders = (state, action) => {
  const { folder, keepOpen } = action.payload;
  const { id } = folder;
  if (state.openedFolderIds.includes(id)) {
    if (keepOpen) {
      return { ...state };
    } else {
      return {
        ...state,
        openedFolderIds: state.openedFolderIds.filter(el => el !== id)
      };
    }
  }
  return {
    ...state,
    openedFolderIds: [...state.openedFolderIds, id]
  };
};

const addNewComponent = (state, { componentType }) => {
  const pagesContent = cloneDeep(state.pagesContent);

  let newComponent;

  switch (componentType) {
    case HOMEPAGE_SECTION_TYPES.BANNER:
      newComponent = initialBannerSection();
      break;
    case HOMEPAGE_SECTION_TYPES.IMAGE_CAROUSEL:
      newComponent = initialImageCarouselSection();
      break;
    case HOMEPAGE_SECTION_TYPES.PRODUCT_CAROUSEL:
      newComponent = initialProductCarouselSection();
      break;
    case HOMEPAGE_SECTION_TYPES.DYNAMIC_SLIDER:
      newComponent = initialDynamicSlider();
      break;
    case HOMEPAGE_SECTION_TYPES.DYNAMIC_BANNER:
      newComponent = initialDynamicBannerSection();
      break;
    default:
      newComponent = {
        id: generateUUID(),
        type: componentType,
        enable: false
      };
  }

  set(pagesContent, "content", [
    ...get(pagesContent, "content", []),
    newComponent
  ]);

  return {
    ...state,
    pagesContent
  };
};

const updatePageData = (state, { path, value }) => {
  const pagesContent = cloneDeep(state.pagesContent);

  set(pagesContent, path, value);

  return {
    ...state,
    pagesContent
  };
};

const openComponent = (state, { id, index }) => {
  const pagesContent = cloneDeep(state.pagesContent);

  pagesContent.content.map((item, itemIndex) => {
    if (id ? item.id === id : itemIndex === index) {
      item.openAccordion = !item.openAccordion;
    } else {
      item.openAccordion = false;
    }
  });

  return {
    ...state,
    pagesContent
  };
};

const deleteComponent = (state, { index }) => {
  const pagesContent = cloneDeep(state.pagesContent);

  set(
    pagesContent,
    ["content"],
    get(pagesContent, ["content"], []).filter(
      (_component, _index) => _index !== index
    )
  );

  return {
    ...state,
    pagesContent
  };
};

const toggleSection = (state, { index }) => {
  const pagesContent = cloneDeep(state.pagesContent);

  const contentModule = pagesContent.content.find(
    (item, itemIndex) => itemIndex === index
  );

  contentModule.enable = !contentModule.enable;

  return {
    ...state,
    pagesContent
  };
};

const reorderComponents = (state, { content }) => {
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const cloneDynamicModule = (state, { path }) => {
  const content = cloneDeep(state.pagesContent.content);

  const copiedModule = cloneDeep(get(content, path));

  copiedModule.id = generateUUID();

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content: [...content, copiedModule]
    }
  };
};

const handleModuleDataChange = (state, { path, name, value }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [...path, name], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const addSchedulerDeviceLevel = (state, { listPosition, deviceType }) => {
  const content = cloneDeep(state.pagesContent.content);

  const deviceContent = get(content, [listPosition, deviceType], []);

  set(deviceContent, "schedulers", [
    ...get(deviceContent, "schedulers", []),
    initialDeviceSettings()
  ]);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const deleteSchedulerDeviceLevel = (
  state,
  { listPosition, deviceType, schedulerIndex }
) => {
  const content = cloneDeep(state.pagesContent.content);

  const deviceContent = get(content, [listPosition, deviceType], []);

  set(
    deviceContent,
    "schedulers",
    get(deviceContent, "schedulers", []).filter(
      (_scheduler, index) => index !== schedulerIndex
    )
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const toggleSchedulerDeviceLevel = (
  state,
  { listPosition, deviceType, schedulerIndex, value }
) => {
  const content = cloneDeep(state.pagesContent.content);

  const deviceContentScheduler = get(content, [
    listPosition,
    deviceType,
    "schedulers",
    schedulerIndex
  ]);

  set(deviceContentScheduler, "enabled", value);

  if (value) {
    const rows = get(content, [listPosition, deviceType, "rows"], []);

    rows.forEach((row, rowIndex) =>
      get(row, "schedulers", []).forEach((_scheduler, schedulerIndex) =>
        set(
          content,
          [
            listPosition,
            deviceType,
            "rows",
            rowIndex,
            "schedulers",
            schedulerIndex,
            "enabled"
          ],
          false
        )
      )
    );
  }

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const schedulerDateChangeDeviceLevel = (
  state,
  { listPosition, deviceType, schedulerIndex, key, value }
) => {
  const content = cloneDeep(state.pagesContent.content);

  const deviceContentScheduler = get(content, [
    listPosition,
    deviceType,
    "schedulers",
    schedulerIndex
  ]);

  set(deviceContentScheduler, key, value);

  const ONE_DAY_MS = 60000 * 1439;

  if (key === "from") {
    const toDate = get(deviceContentScheduler, "to");

    if (!toDate) set(deviceContentScheduler, "to", value + ONE_DAY_MS);
  }

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const generateRows = (state, { path, numberOfRows }) => {
  const content = cloneDeep(state.pagesContent.content);

  const previousRows = get(content, path, []);

  const newArrayLength =
    !isEmpty(previousRows) && numberOfRows > previousRows.length
      ? numberOfRows - previousRows.length
      : numberOfRows;

  const emptyRows = Array.from(Array(newArrayLength), () => {
    return initialDynamicBannerRow();
  });

  const rowsWithData =
    numberOfRows > previousRows.length
      ? [...previousRows, ...emptyRows]
      : previousRows.slice(0, numberOfRows);

  set(content, path, rowsWithData);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const toggleListItem = (state, { path }) => {
  const content = cloneDeep(state.pagesContent.content);

  const isEnabled = get(content, [...path, "enable"]);
  set(content, [...path, "enable"], !isEnabled);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const deleteListItem = (state, { path, listIndex }) => {
  const content = cloneDeep(state.pagesContent.content);

  const updatedHomepageListData = filter(
    get(content, path, []),
    (_listItem, index) => index !== listIndex
  );
  set(content, path, updatedHomepageListData);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateListData = (state, { path, name, value }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [...path, name], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateBannerSectionDataStyles = (state, { path, name, value }) => {
  const content = cloneDeep(state.pagesContent.content);

  const styles = get(content, [...path, "styles"], {});

  if (["backgroundImage", "backgroundColor"].includes(name)) {
    delete styles.backgroundImage;
    delete styles.backgroundColor;
  }

  set(styles, [name], value);

  if (
    [
      "primaryBackgroundColor",
      "secondaryBackgroundColor",
      "gradientType",
      "primaryLocation",
      "secondaryLocation",
      "gradientAngle"
    ].includes(name)
  ) {
    set(
      styles,
      "backgroundGradient",
      `${styles.gradientType}(${styles.gradientType === bannerGradientTypes[0].value
        ? `${styles.gradientAngle}deg`
        : "circle"
      }, ${styles.primaryBackgroundColor} ${styles.primaryLocation}%, ${styles.secondaryBackgroundColor
      } ${styles.secondaryLocation}%)`
    );
  }

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const putBannerDataStyles = (state, { value, path }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [...path, "styles"], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const clearBannerRowImageData = (state, { path }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [...path, "imageList"], []);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateBannerSection = (state, { path, value }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, path, value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const addSchedulerToBannerRow = (state, { path }) => {
  const content = cloneDeep(state.pagesContent.content);

  const row = get(content, path, {});

  set(row, "schedulers", [
    ...get(row, "schedulers", []),
    initialDynamicBannerRow()
  ]);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const handleDeleteBannerScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.pagesContent.content);

  const row = get(content, path, []);

  set(
    row,
    "schedulers",
    get(row, "schedulers", []).filter(
      (_item, index) => index !== schedulerIndex
    )
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const handleDataChangeBannerScheduler = (state, { path, key, value }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [...path, key], value);

  const ONE_DAY_MS = 60000 * 1439;

  if (key === "from") {
    const toDate = get(content, [...path, "to"]);

    if (!toDate) set(content, [...path, "to"], value + ONE_DAY_MS);
  }

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const handleRowSchedulerToggle = (state, { path, key, value }) => {
  let newState = handleDataChangeBannerScheduler(state, { path, key, value });
  if (value) {
    const [listPosition, deviceType] = path;
    const schedulers = get(
      newState,
      ["pagesContent", "content", listPosition, deviceType, "schedulers"],
      []
    );
    schedulers.forEach(scheduler => {
      set(scheduler, "enabled", false);
    });
  }
  return newState;
};

const clearBannerSchedulerRowImageData = (state, { path }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, path, []);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const copyContentToScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.pagesContent.content);

  const row = get(content, path);

  const schedulers = get(row, "schedulers");

  const scheduler = get(schedulers, [schedulerIndex]);

  set(schedulers, schedulerIndex, {
    ...scheduler,
    ...row,
    id: scheduler.id,
    imageList: [
      ...scheduler.imageList.map(image => ({ ...image, id: generateUUID() })),
      ...cloneDeep(row).imageList.map(image => ({
        ...image,
        id: generateUUID()
      }))
    ]
  });

  delete schedulers[schedulerIndex].schedulers;

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const cloneDynamicRow = (
  state,
  { path: [contentIndex, deviceType, rows], rowIndex, moduleId }
) => {
  const content = cloneDeep(state.pagesContent.content);

  const targetModule = content.find(module => module.id === moduleId);

  const copiedRow = cloneDeep(
    get(content, [contentIndex, deviceType, rows, rowIndex])
  );

  copiedRow.id = generateUUID();

  set(
    targetModule,
    [deviceType, rows],
    [...get(targetModule, [deviceType, rows]), copiedRow]
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const cloneDynamicScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.pagesContent.content);
  const copiedScheduler = cloneDeep(get(content, [...path, schedulerIndex]));
  copiedScheduler.id = generateUUID();
  copiedScheduler.enabled = false;

  set(content, path, [...get(content, path), copiedScheduler]);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const addBannerRowItem = (state, { path, itemType }) => {
  const content = cloneDeep(state.pagesContent.content);

  const pathToRow = path.slice(0, path.length - 1);

  const row = get(content, pathToRow);

  const item = dynamicBannerItems[itemType]();

  item.styles = { ...item.styles, ...row.generalPadding };

  set(content, path, [...get(content, path, []), item]);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const addBannerCarouselRowImages = (state, { path, itemsPerGroup }) => {
  const content = cloneDeep(state.pagesContent.content);

  const imageList = get(content, path);

  let newImageListCount = imageList.length;

  const pathToRow = path.slice(0, path.length - 1);

  const row = get(content, pathToRow);

  const cb = () => {
    const item = initialImage();

    item.styles = { ...item.styles, ...row.generalPadding };

    return item;
  };

  if (newImageListCount % itemsPerGroup !== 0) {
    while (newImageListCount % itemsPerGroup !== 0) {
      newImageListCount++;
    }

    set(content, path, [
      ...get(content, path, []),
      ...Array.from(Array(newImageListCount - imageList.length), cb)
    ]);
  } else {
    set(content, path, [
      ...get(content, path, []),
      ...Array.from(Array(itemsPerGroup), cb)
    ]);
  }

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const deleteRowImage = (state, { path, imageIndex }) => {
  const content = cloneDeep(state.pagesContent.content);

  const updatedImageList = filter(
    get(content, path, []),
    (_image, index) => index !== imageIndex
  );

  set(content, path, updatedImageList);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const reorderBannerRowImages = (state, { path, source, destination }) => {
  const content = cloneDeep(state.pagesContent.content);

  const imageList = get(content, path, []);

  [imageList[destination], imageList[source]] = [
    imageList[source],
    imageList[destination]
  ];

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const handleItemsPerGroupChange = (state, { path, itemsPerGroup }) => {
  const content = cloneDeep(state.pagesContent.content);

  const imageList = get(content, [...path, "imageList"]);

  let newImageListCount = imageList.length;

  while (newImageListCount % itemsPerGroup !== 0) {
    newImageListCount++;
  }

  set(content, [...path, "itemsPerGroup"], itemsPerGroup);
  set(
    content,
    [...path, "imageList"],
    [
      ...imageList,
      ...Array.from(Array(newImageListCount - imageList.length), () => {
        return initialImage();
      })
    ]
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const handleProductCarouselDataChange = (
  state,
  { path, value, language, name }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [...path, language, name], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateRowTypeCustomData = (state, { path, index, name, value }) => {
  const content = cloneDeep(state.pagesContent.content);

  const customField = get(content, [...path, "custom"], []).find(
    (_custom, _index) => _index === index
  );

  set(customField, name, value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const addRowTypeCustomField = (state, { path }) => {
  const content = cloneDeep(state.pagesContent.content);
  set(
    content,
    [...path, "custom"],
    [...get(content, [...path, "custom"], []), { key: "", value: "" }]
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateRowTypeCustomModuleField = (state, { path, value }) => {
  const content = cloneDeep(state.pagesContent.content);
  set(
    content,
    [...path, "custom"],
    [...value]
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const removeRowTypeCustomField = (state, { path, index }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [...path, "custom"],
    get(content, [...path, "custom"], []).filter(
      (_custom, _index) => _index !== index
    )
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const addRowTypeAccordionField = (state, { path, language }) => {
  const content = cloneDeep(state.pagesContent.content);
  set(
    content,
    [...path, "accordion", language],
    [...get(content, [...path, "accordion", language], []), { key: "", value: "" }]
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const updateRowTypeAccordionData = (state, { path, index, name, value, language }) => {
  const content = cloneDeep(state.pagesContent.content);
  const accordionField = get(content, [...path, "accordion", language], []).find(
    (_accordion, _index) => _index === index
  );

  set(accordionField, name, value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const updateRowTypeAccordionHeaderData = (state, { path, name, value, language }) => {
  const content = cloneDeep(state.pagesContent.content);
  set(
    content,
    [...path, "accordion", "headers", language, name],
    value
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const removeRowTypeAccordionField = (state, { path, index, language }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [...path, "accordion", language],
    get(content, [...path, "accordion", language], []).filter(
      (_accordion, _index) => _index !== index
    )
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const addRowTypeBulletField = (state, { path, language }) => {
  const content = cloneDeep(state.pagesContent.content);
  set(
    content,
    [...path, "bullet", language],
    [...get(content, [...path, "bullet", language], []), { key: "", value: "" }]
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}
const updateRowTypeBulletData = (state, { path, index, name, value, language }) => {
  const content = cloneDeep(state.pagesContent.content);
  const bulletField = get(content, [...path, "bullet", language], []).find(
    (_bullet, _index) => _index === index
  );

  set(bulletField, name, value);
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const updateRowTypeBulletHeaderData = (state, { path, name, value, language }) => {
  const content = cloneDeep(state.pagesContent.content);
  set(
    content,
    [...path, "bullet", "headers", language, name],
    value
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const removeRowTypeBulletField = (state, { path, index, language }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [...path, "bullet", language],
    get(content, [...path, "bullet", language], []).filter(
      (_bullet, _index) => _index !== index
    )
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const addRowTypeTabField = (state, { path }) => {
  const content = cloneDeep(state.pagesContent.content);
  const tabLength = get(content, [...path, "tab"], []).length;
  let row = {
    isDefault: false,
    english: {
      title: "", imageURL: "", imagePosition: "left", redirectionUrl: "", contentSource: "", navigationType: "", sectionSlug: "", catalogUrl: ""
    },
    arabic: {
      title: "", imageURL: "", imagePosition: "left", redirectionUrl: "", contentSource: "", navigationType: "", sectionSlug: "", catalogUrl: ""
    }
  }

  if (!tabLength) {
    row.isDefault = true;
    row.english.navigationType = tabBannerTypeNavigationType.INLINE;
    row.arabic.navigationType = tabBannerTypeNavigationType.INLINE;
  }

  set(
    content,
    [...path, "tab"],
    [...get(content, [...path, "tab"], []),
      row
    ]
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateRowTypeTabData = (state, { path, index, name, value, language }) => {
  const content = cloneDeep(state.pagesContent.content);
  const tab = get(content, [...path, "tab"], []).find(
    (_tab, _index) => _index === index
  );

  set(tab, language ? [language, name] : [name], value);
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const updateRowTypeTabs = (state, { path, value }) => {
  const content = cloneDeep(state.pagesContent.content);
  set(content, [...path, "tab"], value);
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}


const removeRowTypeTab = (state, { path, index }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [...path, "tab"],
    get(content, [...path, "tab"], []).filter(
      (_tab, _index) => _index !== index
    )
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const addRowTypeColumnField = (state, { path }) => {
  const content = cloneDeep(state.pagesContent.content);
  const columnLength = get(content, [...path, "columns"], []).length;
  let row = { id: generateUUID(), name: `Column-${columnLength + 1}`, widthType: "percentage" }
  set(
    content,
    [...path, "columns"],
    [...get(content, [...path, "columns"], []),
      row
    ]
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const removeRowTypeColumnField = (state, { path, index }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [...path, "columns"],
    get(content, [...path, "columns"], []).filter(
      (_column, _index) => _index !== index
    )
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const dragAndDropRowTypeColumnField = (state, { path, sourceIndex, destinationIndex }) => {
  const content = cloneDeep(state.pagesContent.content);
  const columns = arrayReorder(
    get(content, [...path, "columns"], []),
    sourceIndex,
    destinationIndex
  );
  set(content, [...path, "columns"], columns);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
}

const addRowTypeColumnMenu = (state, { path, index, value }) => {
  const content = cloneDeep(state.pagesContent.content);
  const menus = get(content, [...path, "columns", index, "menu"], []);
  set(
    content,
    [...path, "columns", index, "menu"],
    [...menus, { ...value }]
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateRowTypeColumnMenu = (state, { path, index, value, key }) => {
  const content = cloneDeep(state.pagesContent.content);
  set(
    content,
    [...path, "columns", index, key],
    value
  );
  if (key === "columnType" &&
    value === bannerTypeValueConstants.CUSTOM &&
    !get(content, [...path, "columns", index, "custom"], []).length) {
    set(
      content,
      [...path, "columns", index, "custom"],
      [{ key: "moduleName", value: customModuleNameConstants.STORYLY }]
    );
  }
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const changeBannerImageHoverStyle = (state, { key, value, path }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [...path, "styles", "hoverValues", key], value);

  let formattedValue;

  switch (key) {
    case "transform": {
      formattedValue = `scale(${value})`;
      break;
    }
    case "transition": {
      formattedValue = `transform ${value}s`;
      break;
    }

    default: {
      formattedValue = value;
    }
  }

  set(content, [...path, "styles", "hover", key], formattedValue);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateBannerRowImageData = (state, { path, value }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, path, value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const handleCountdownDataChange = (state, { path, name, value }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [...path, name], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateDefaultTextChange = (
  state,
  { name, value, position, language }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [position, name, language], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const handleDateChange = (
  state,
  { name, timeStamp, position, schedulerIndex }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [position, "schedulers", schedulerIndex, name], timeStamp);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const schedulerTextChange = (
  state,
  { value, position, language, schedulerIndex }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [position, "schedulers", schedulerIndex, "template", language],
    value
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const handleToggle = (state, { position, schedulerIndex, schedulerValue }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [position, "schedulers", schedulerIndex], {
    ...get(content, [position, "schedulers", schedulerIndex]),
    enabled: schedulerValue
  });

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const addNewScheduler = (state, { position, schedulerType }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [position, "schedulers"],
    [
      ...get(content, [position, "schedulers"], []),
      SCHEDULER_DATA_BY_TYPE[schedulerType]
    ]
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const deleteScheduler = (state, { position, schedulerIndex }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [position, "schedulers"],
    get(content, [position, "schedulers"]).filter(
      (_item, index) => index !== schedulerIndex
    )
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const addImageList = (state, { name, position }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [position, name],
    [
      ...get(content, [position, name], []),
      {
        id: `${Date.now()}`,
        english: {},
        arabic: {},
        enable: true
      }
    ]
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const schedulerAddImageList = (state, { schedulerIndex, name, position }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [position, "schedulers", schedulerIndex, name],
    [
      ...get(content, [position, "schedulers", schedulerIndex, name], []),
      { id: `${Date.now()}`, english: {}, arabic: {}, enable: true }
    ]
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateSchedulerDefaultTextChange = (
  state,
  { name, value, position, language, schedulerIndex }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [position, "schedulers", schedulerIndex, language, name], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateTypeBDateHandler = (
  state,
  { name, timeStamp, position, schedulerIndex }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [position, "schedulers", schedulerIndex, name], timeStamp);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const deleteImage = (state, { position, imageIndex }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [position, "imageList"],
    get(content, [position, "imageList"], []).filter(
      (_item, index) => index !== imageIndex
    )
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const editImageList = (
  state,
  { name, value, arrayName, position, imageIndex, language }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [position, arrayName, imageIndex, language, name], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const handleImageToggle = (state, { position, imageIndex, value }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [position, "imageList", imageIndex, "enable"], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const schedulerDeleteImage = (
  state,
  { schedulerIndex, position, imageIndex }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [position, "schedulers", schedulerIndex, "imageList"],
    get(
      content,
      [position, "schedulers", schedulerIndex, "imageList"],
      []
    ).filter((_item, index) => index !== imageIndex)
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const schedulerHandleImageToggle = (
  state,
  { schedulerIndex, position, imageIndex, value }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [position, "schedulers", schedulerIndex, "imageList", imageIndex, "enable"],
    value
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const schedulerEditImageList = (
  state,
  { schedulerIndex, name, value, arrayName, position, imageIndex, language }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(
    content,
    [
      position,
      "schedulers",
      schedulerIndex,
      arrayName,
      imageIndex,
      language,
      name
    ],
    value
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateType2text = (state, { name, value, position, language }) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [position, language, name], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const updateTypeBSchedulerTextChange = (
  state,
  { name, value, position, language, schedulerIndex }
) => {
  const content = cloneDeep(state.pagesContent.content);

  set(content, [position, "schedulers", schedulerIndex, language, name], value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const resetPagesPage = () => ({ ...initialState });

const schedulerDataChangeDeviceLevel = (
  state,
  { listPosition, deviceType, schedulerIndex, key, value }
) => {
  const content = cloneDeep(state.pagesContent.content);

  const deviceContentScheduler = get(content, [
    listPosition,
    deviceType,
    "schedulers",
    schedulerIndex
  ]);

  set(deviceContentScheduler, key, value);

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const cloneDynamicTabRow = (state, { path, tab }) => {
  const content = cloneDeep(state.pagesContent.content);

  const row = get(content, path);
  const tabletData = get(row, "tablet");
  const tabData = get(row, tab.toLowerCase());

  const isDesktop = tab === SCREEN_TYPES[0].label;

  tabletData.rows = [
    ...tabData.rows.map(row => {
      const _row = cloneDeep(row);

      if (isDesktop && _row.bannerType === bannerTypeValueConstants.SLIDER) {
        _row.imageList = _row.imageList.map(image => ({
          ...image,
          english: { ...image.english, imageURL: null },
          arabic: { ...image.arabic, imageURL: null }
        }));
      }

      return {
        ..._row,
        width:
          isDesktop && _row.width === rowWidthValues.CUSTOM_WIDTH
            ? null
            : _row.width,
        id: generateUUID()
      };
    })
  ];
  tabletData.styles = {
    ...tabData.styles,
    backgroundType:
      isDesktop &&
        tabData.styles.backgroundType === bannerDesktopBackgroundOptions[2].value
        ? null
        : tabData.styles.backgroundType
  };
  tabletData.title = tabData.title;
  tabletData.schedulers = [...(tabData.schedulers || [])];

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const applyGeneralSpacing = (state, { path }) => {
  const content = cloneDeep(state.pagesContent.content);

  const row = get(content, path);

  row.imageList.forEach(
    item => (item.styles = { ...item.styles, ...row.generalPadding })
  );

  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const setNewAddedPage = (state, action) => {
  return { ...state, newAddedPage: action.payload };
};

const setNewAddedFolder = (state, action) => {
  return { ...state, newAddedFolder: action.payload };
};

const copyParentContentToBannerScheduler = (
  state,
  { listPosition, deviceType }
) => {
  const content = cloneDeep(state.pagesContent.content);
  const deviceContent = get(content, [listPosition, deviceType], []);
  const rows = get(content, [listPosition, deviceType, "rows"], []);
  const schedulers = deviceContent.schedulers;
  deviceContent.rows.id = generateUUID();

  schedulers.map(
    rowData =>
    (rowData.rows = [
      ...rows.map(rowData => ({ ...rowData, id: generateUUID() }))
    ])
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

const copyBannerScheduler = (
  state,
  {
    listPosition,
    deviceType,
    schedulerData,
    updateCatalogPageType,
    updateCatalogPageLocation
  }
) => {
  const content = cloneDeep(state.pagesContent.content);
  const schedulers = get(content, [listPosition, deviceType, `schedulers`], []);
  schedulerData.rows.map(rowData => ({ ...rowData, id: generateUUID() }));
  set(
    content,
    [listPosition, deviceType, `schedulers`],
    [...schedulers, schedulerData]
  );
  return {
    ...state,
    pagesContent: {
      ...state.pagesContent,
      content
    }
  };
};

export const setPagesAllRollbackHistory = (state, action) => {
  return {
    ...state,
    rollbackHistory: action.payload && action.payload.data
  };
};

const toggleRollbackSave = (state, { value }) => {
  return {
    ...state,
    rollbackSave: value
  };
};

const pagesPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE:
      return setActivePage(state, action);
    case CHANGE_PAGES_FOLDER_NAME:
      return changePagesFolderName(state, action);
    case CREATE_NEW_FOLDER:
      return createNewFolder(state, action);
    case DELETE_FOLDER:
      return deleteFolder(state, action);
    case DELETE_PAGE:
      return deletePage(state, action);
    case SET_PAGES:
      return setPages(state, action);
    case SET_PAGES_BY_COUNTRY:
      return setPagesByCountry(state, action);
    case SET_PAGE_DATA:
      return setPageData(state, action);
    case TOGGLE_RESET:
      return toggleReset(state, action);
    case RESET_PAGES_CONTENT:
      return resetPagesContent(state, action);
    case SET_PAGES_ROUTE:
      return setPagesRoute(state, action);
    case UPDATE_OPENED_CONTAINERS:
      return updateOpenedContainers(state, action);
    case UPDATE_OPENED_FOLDERS:
      return updateOpenedFolders(state, action);
    case ADD_NEW_COMPONENT:
      return addNewComponent(state, action);
    case UPDATE_PAGE_DATA:
      return updatePageData(state, action);
    case PAGE_OPEN_COMPONENT:
      return openComponent(state, action);
    case PAGE_DELETE_COMPONENT:
      return deleteComponent(state, action);
    case PAGE_TOGGLE_COMPONENT:
      return toggleSection(state, action);
    case CLONE_MODULE_PAGES_PAGE:
      return cloneDynamicModule(state, action);
    case REORDER_COMPONENTS:
      return reorderComponents(state, action);
    case MODULE_DATA_CHANGE_PAGES_PAGE:
      return handleModuleDataChange(state, action);
    case ADD_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE:
      return addSchedulerDeviceLevel(state, action);
    case DELETE_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE:
      return deleteSchedulerDeviceLevel(state, action);
    case TOGGLE_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE:
      return toggleSchedulerDeviceLevel(state, action);
    case SCHEDULER_DATE_CHANGE_DEVICE_LEVEL_PAGES_PAGE:
      return schedulerDateChangeDeviceLevel(state, action);
    case GENERATE_IMAGES_PAGES_PAGE:
      return generateRows(state, action);
    case TOGGLE_LIST_ITEM_PAGES_PAGE:
      return toggleListItem(state, action);
    case DELETE_LIST_ITEM_PAGES_PAGE:
      return deleteListItem(state, action);
    case UPDATE_LIST_DATA_PAGES_PAGE:
      return updateListData(state, action);
    case UPDATE_BANNER_SECTION_DATA_STYLES_PAGES_PAGE:
      return updateBannerSectionDataStyles(state, action);
    case PUT_BANNER_DATA_STYLES_PAGES_PAGE:
      return putBannerDataStyles(state, action);
    case CLEAR_BANNER_ROW_IMAGE_DATA_PAGES_PAGE:
      return clearBannerRowImageData(state, action);
    case UPDATE_BANNER_SECTION_DATA_PAGES_PAGE:
      return updateBannerSection(state, action);
    case ADD_BANNER_SCHEDULER_PAGES_PAGE:
      return addSchedulerToBannerRow(state, action);
    case HANDLE_DELETE_BANNER_SCHEDULER_PAGES_PAGE:
      return handleDeleteBannerScheduler(state, action);
    case HANDLE_DATA_CHANGE_BANNER_SCHEDULER_PAGES_PAGE:
      return handleDataChangeBannerScheduler(state, action);
    case HANDLE_ROW_SCHEDULER_TOGGLE_PAGES_PAGE:
      return handleRowSchedulerToggle(state, action);
    case CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_PAGES_PAGE:
      return clearBannerSchedulerRowImageData(state, action);
    case COPY_CONTENT_TO_SCHEDULER_PAGES_PAGE:
      return copyContentToScheduler(state, action);
    case CLONE_ROW_PAGES_PAGE:
      return cloneDynamicRow(state, action);
    case CLONE_SCHEDULER_PAGES_PAGE:
      return cloneDynamicScheduler(state, action);
    case ADD_BANNER_ROW_ITEM_PAGES_PAGE:
      return addBannerRowItem(state, action);
    case ADD_BANNER_SLIDER_ROW_IMAGES_PAGES_PAGE:
      return addBannerCarouselRowImages(state, action);
    case DELETE_ROW_IMAGE_PAGES_PAGE:
      return deleteRowImage(state, action);
    case REORDER_BANNER_ROW_IMAGES_PAGES_PAGE:
      return reorderBannerRowImages(state, action);
    case ITEMS_PER_GROUP_CHANGE_PAGES_PAGE:
      return handleItemsPerGroupChange(state, action);
    case PRODUCT_CAROUSEL_DATA_CHANGE_PAGES_PAGE:
      return handleProductCarouselDataChange(state, action);
    case UPDATE_ROW_TYPE_CUSTOM_DATA_PAGES_PAGE:
      return updateRowTypeCustomData(state, action);
    case ADD_ROW_TYPE_CUSTOM_FIELD_PAGES_PAGE:
      return addRowTypeCustomField(state, action);
    case UPDATE_ROW_TYPE_CUSTOM_MODULE_PAGES_PAGE:
      return updateRowTypeCustomModuleField(state, action);
    case REMOVE_ROW_TYPE_CUSTOM_FIELD_PAGES_PAGE:
      return removeRowTypeCustomField(state, action);
    case ADD_ROW_TYPE_ACCORDION_FIELD_PAGES_PAGE:
      return addRowTypeAccordionField(state, action);
    case UPDATE_ROW_TYPE_ACCORDION_DATA_PAGES_PAGE:
      return updateRowTypeAccordionData(state, action);
    case UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_PAGES_PAGE:
      return updateRowTypeAccordionHeaderData(state, action);
    case REMOVE_ROW_TYPE_ACCORDION_FIELD_PAGES_PAGE:
      return removeRowTypeAccordionField(state, action);
    case ADD_ROW_TYPE_BULLET_FIELD_PAGES_PAGE:
      return addRowTypeBulletField(state, action);
    case UPDATE_ROW_TYPE_BULLET_DATA_PAGES_PAGE:
      return updateRowTypeBulletData(state, action);
    case UPDATE_ROW_TYPE_BULLET_HEADER_DATA_PAGES_PAGE:
      return updateRowTypeBulletHeaderData(state, action);
    case REMOVE_ROW_TYPE_BULLET_FIELD_PAGES_PAGE:
      return removeRowTypeBulletField(state, action);
    case ADD_ROW_TYPE_TAB_FIELD_PAGES_PAGE:
      return addRowTypeTabField(state, action);
    case UPDATE_ROW_TYPE_TAB_DATA_PAGES_PAGE:
      return updateRowTypeTabData(state, action);
    case UPDATE_ROW_TYPE_TABS_PAGES_PAGE:
      return updateRowTypeTabs(state, action);
    case REMOVE_ROW_TYPE_TAB_PAGES_PAGE:
      return removeRowTypeTab(state, action);
    case ADD_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE:
      return addRowTypeColumnField(state, action);
    case REMOVE_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE:
      return removeRowTypeColumnField(state, action);
    case DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE:
      return dragAndDropRowTypeColumnField(state, action);
    case ADD_ROW_TYPE_COLUMN_MENU_PAGES_PAGE:
      return addRowTypeColumnMenu(state, action);
    case UPDATE_ROW_TYPE_COLUMN_MENU_PAGES_PAGE:
      return updateRowTypeColumnMenu(state, action);
    case CHANGE_BANNER_IMAGE_HOVER_STYLE_PAGES_PAGE:
      return changeBannerImageHoverStyle(state, action);
    case UPDATE_BANNER_ROW_IMAGE_DATA_PAGES_PAGE:
      return updateBannerRowImageData(state, action);
    case COUNTDOWN_DATA_CHANGE_PAGES_PAGE:
      return handleCountdownDataChange(state, action);
    case UPDATE_BANNER_DEFAULT_TEXT_PAGES_PAGE:
      return updateDefaultTextChange(state, action);
    case HANDLE_DATE_CHANGE_PAGES_PAGE:
      return handleDateChange(state, action);
    case SCHEDULER_TEXT_CHANGE_PAGES_PAGE:
      return schedulerTextChange(state, action);
    case HANDLE_TOGGLE_PAGES_PAGE:
      return handleToggle(state, action);
    case ADD_NEW_SCHEDULER_PAGES_PAGE:
      return addNewScheduler(state, action);
    case DELETE_SCHEDULER_PAGES_PAGE:
      return deleteScheduler(state, action);
    case ADD_IMAGE_LIST_PAGES_PAGE:
      return addImageList(state, action);
    case SCHEDULER_ADD_IMAGE_LIST:
      return schedulerAddImageList(state, action);
    case UPDATE_SCHEDULER_BANNER_DEFAULT_TEXT_PAGES_PAGE:
      return updateSchedulerDefaultTextChange(state, action);
    case TYPE_B_UPDATE_HANDLE_DATE_CHANGE_PAGES_PAGE:
      return updateTypeBDateHandler(state, action);
    case DELETE_IMAGE_PAGES_PAGE:
      return deleteImage(state, action);
    case EDIT_IMAGE_LIST_PAGES_PAGE:
      return editImageList(state, action);
    case HANDLE_IMAGE_TOGGLE_PAGES_PAGE:
      return handleImageToggle(state, action);
    case SCHEDULER_DELETE_IMAGE_PAGES_PAGE:
      return schedulerDeleteImage(state, action);
    case SCHEDULER_HANDLE_IMAGE_TOGGLE_PAGES_PAGE:
      return schedulerHandleImageToggle(state, action);
    case SCHEDULER_EDIT_IMAGE_LIST_PAGES_PAGE:
      return schedulerEditImageList(state, action);
    case UPDATE_TYPE2_TEXT_PAGES_PAGE:
      return updateType2text(state, action);
    case UPDATE_TYPE_B_SCHEDULER_TEXT_CHANGE_PAGES_PAGE:
      return updateTypeBSchedulerTextChange(state, action);
    case SELECT_NEW_PAGE_FOLDER:
      return selectNewPageFolder(state, action);
    case SET_NEW_PAGE_FOLDER:
      return setNewPageFolder(state, action);
    case RESET_PAGES_PAGE:
      return resetPagesPage();
    case SET_NEW_ADDED_PAGE:
      return setNewAddedPage(state, action);
    case SET_NEW_ADDED_FOLDER:
      return setNewAddedFolder(state, action);
    case SCHEDULER_DATA_CHANGE_DEVICE_LEVEL_PAGES_PAGE:
      return schedulerDataChangeDeviceLevel(state, action);
    case CLONE_MOBILE_PAGES_PAGE:
      return cloneDynamicTabRow(state, action);
    case COPY_PARENT_CONTENT_TO_SCHEDULER_PAGES_PAGE:
      return copyParentContentToBannerScheduler(state, action);
    case APPLY_GENERAL_SPACING_PAGES_PAGE:
      return applyGeneralSpacing(state, action);
    case CLONE_DYNAMIC_MODULE_PAGES_PAGE:
      return cloneDynamicModuleToCountries(state, action);
    case SET_PAGES_ROLLBACK_hISTORY:
      return setPagesAllRollbackHistory(state, action);
    case TOGGLE_ROLLBACK_SAVE:
      return toggleRollbackSave(state, action);
    case CLONE_BANNER_SCHEDULER_PAGES_PAGE:
      return copyBannerScheduler(state, action);
    default:
      return state;
  }
};

export default pagesPageReducer;
