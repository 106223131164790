import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import find from "lodash/find";
import {
  changeHandler,
  changeSelectedBrand,
  changeSelectedGenericCategory,
  changeSelectedSubCategory,
  changeSizeChartCategory,
  changeSizeChartNav,
  clearSelectedGenericSubCategory,
  clearSubCategoryData,
  fetchBrandsData,
  fetchSizeChartData,
  fetchSubCategoryData,
  handleBrandDelete,
  handleBrandSubCategoryDelete,
  handleBrandToggle,
  handleGeneralChange,
  handleGeneralSave,
  handleInputTextChange,
  handleMetaDataSave,
  handleReset,
  submitSubCategoryList,
  updateSizeChartBrandContentApi,
  updateSubCategoryData
} from "../../actions/sizechart.action";
import { changeTab } from "../../actions/common.action";
import {
  DISCARD_CHANGES_CONFIRMATION_MESSAGE,
  NAVIGATION,
  NAVIGATION_MENUS_SLUGS,
  SIZE_CHART_PANEL
} from "../../constants";
import ConfirmModal from "../modal/ConfirmModal";
import BrandChartTab from "./brandChartTab";
import GeneralSeoContent from "./generalSeoContent";
import GenericChartTab from "./genericChartTab";
import GenericTab from "./generalTab";
import LeftPanel from "./leftPanel";

class SizeChart extends Component {
  state = {
    confirmModal: false,
    modalText: "",
    page: 1,
    tempCategory: "",
    tempDeleteData: {},
    tempKey: "",
    tempNav: ""
  };

  UNSAFE_componentWillMount() {
    const { changeTab } = this.props;
    changeTab(NAVIGATION.SIZE_CHART);
  }

  componentDidMount() {
    const {
      countryId,
      fetchBrandsData,
      fetchSizeChartData,
      fetchSubCategoryData
    } = this.props;
    fetchBrandsData();
    fetchSubCategoryData();
    fetchSizeChartData(countryId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      changeTab,
      clearSelectedGenericSubCategory,
      clearSubCategoryData,
      countryId,
      fetchSizeChartData,
      selectedCategory,
      selectedTab
    } = this.props;

    if (countryId !== nextProps.countryId) {
      changeTab(NAVIGATION.SIZE_CHART);
      fetchSizeChartData(nextProps.countryId);
    }

    if (
      selectedTab !== nextProps.selectedTab ||
      selectedCategory !== nextProps.selectedCategory
    ) {
      clearSubCategoryData();
      clearSelectedGenericSubCategory();
    }
  }

  changePageType = selectedPage => {
    this.setState({ page: selectedPage });
  };

  changeTab = (key, category = "") => {
    const {
      apiContent,
      selectedCategory,
      selectedGenericSubCategory,
      selectedTab,
      updateBrandSizeChart,
      updatedSelectedGenericSubCategory,
      updateGeneral,
      updateGenericSizeChart,
      updateMeta,
      updateSubCategory
    } = this.props;

    let prevData = null;
    let updatedData = null;

    if (selectedTab === "seo") {
      prevData = apiContent.meta || {};
      updatedData = updateMeta || {};
    } else if (selectedTab === "general") {
      prevData = apiContent.general || {};
      updatedData = updateGeneral || {};
    } else if (["men", "women", "kids"].includes(selectedTab)) {
      if (selectedCategory === "brandSizeChart") {
        prevData = apiContent.brandSizeChart || {};
        updatedData = updateBrandSizeChart || {};
      } else if (selectedCategory === "genericSizeChart") {
        prevData = apiContent.genericSizeChart || {};
        updatedData = updateGenericSizeChart || {};
      }
    }

    if (
      isEqual(prevData, updatedData) &&
      !["men", "women", "kids"].includes(selectedTab)
    ) {
      this.confirmChangeModal(key, category);
    } else if (
      isEqual(prevData, updatedData) &&
      selectedCategory === "genericSizeChart" &&
      ["men", "women", "kids"].includes(selectedTab) &&
      isEqual(selectedGenericSubCategory, updatedSelectedGenericSubCategory)
    ) {
      this.confirmChangeModal(key, category);
    } else if (
      isEqual(prevData, updatedData) &&
      selectedCategory === "brandSizeChart" &&
      ["men", "women", "kids"].includes(selectedTab) &&
      isEqual(
        updateSubCategory,
        find(get(apiContent, ["brandSizeChart", selectedTab, "brandList"]), {
          brandId: updateSubCategory.brandId,
          subCategoryId: updateSubCategory.subCategoryId
        })
      )
    ) {
      this.confirmChangeModal(key, category);
    } else {
      this.setState({ tempKey: key, tempCategory: category }, () => {
        this.confirmChangeModal();
      });
    }
  };

  changeNav = e => {
    e.stopPropagation();
    const {
      apiContent,
      selectedCategory,
      selectedGenericSubCategory,
      selectedTab,
      updateBrandSizeChart,
      updatedSelectedGenericSubCategory,
      updateGeneral,
      updateGenericSizeChart,
      updateMeta,
      updateSubCategory
    } = this.props;

    let prevData = null;
    let updatedData = null;

    if (selectedTab === "seo") {
      prevData = apiContent.meta || {};
      updatedData = updateMeta || {};
    } else if (selectedTab === "general") {
      prevData = apiContent.general || {};
      updatedData = updateGeneral || {};
    } else if (["men", "women", "kids"].includes(selectedTab)) {
      if (selectedCategory === "brandSizeChart") {
        prevData = apiContent.brandSizeChart || {};
        updatedData = updateBrandSizeChart || {};
      } else if (selectedCategory === "genericSizeChart") {
        prevData = apiContent.genericSizeChart || {};
        updatedData = updateGenericSizeChart || {};
      }
    }

    if (
      isEqual(prevData, updatedData) &&
      !["men", "women", "kids"].includes(selectedTab)
    ) {
      this.confirmNavChangeModal(e.target.id);
    } else if (
      isEqual(prevData, updatedData) &&
      selectedCategory === "genericSizeChart" &&
      ["men", "women", "kids"].includes(selectedTab) &&
      isEqual(selectedGenericSubCategory, updatedSelectedGenericSubCategory) &&
      isEqual(prevData, updatedData)
    ) {
      this.confirmNavChangeModal(e.target.id);
    } else if (
      isEqual(prevData, updatedData) &&
      selectedCategory === "brandSizeChart" &&
      ["men", "women", "kids"].includes(selectedTab) &&
      isEqual(
        updateSubCategory,
        find(get(apiContent, ["brandSizeChart", selectedTab, "brandList"]), {
          brandId: updateSubCategory.brandId,
          subCategoryId: updateSubCategory.subCategoryId
        })
      )
    ) {
      this.confirmNavChangeModal(e.target.id);
    } else {
      this.setState({ tempNav: e.target.id }, () => {
        this.confirmNavChangeModal();
      });
    }
  };

  handleBrandDelete = data => {
    this.setState({
      confirmModal: true,
      tempDeleteData: data,
      deleteFunc: this.props.handleBrandDelete,
      modalText: "Are you sure you want to delete the data ?"
    });
  };

  handleSubCategoryDelete = data => {
    this.setState({
      confirmModal: true,
      tempDeleteData: data,
      deleteFunc: this.props.handleBrandSubCategoryDelete,
      modalText: "Are you sure you want to delete the data ?"
    });
  };

  handleReset = () => {
    this.setState({
      confirmModal: true,
      tempDeleteData: "",
      deleteFunc: this.props.handleReset,
      modalText: "Are you sure you want to cancel the changes ?"
    });
  };

  handleSubCategoryTileChange = deleteFunc => {
    this.setState({
      confirmModal: true,
      tempDeleteData: "",
      deleteFunc: deleteFunc,
      modalText: DISCARD_CHANGES_CONFIRMATION_MESSAGE
    });
  };

  confirmModal = () => {
    const { tempDeleteData, deleteFunc } = this.state;
    deleteFunc(tempDeleteData);
    this.hideModal();
  };

  hideModal = () =>
    this.setState({
      confirmModal: false,
      tempKey: "",
      tempCategory: "",
      tempNav: ""
    });

  confirmChangeModal = (key, category) => {
    const { tempKey, tempCategory } = this.state;
    const {
      changeSizeChartCategory,
      changeSelectedGenericCategory
    } = this.props;

    changeSizeChartCategory(key || tempKey, category || tempCategory);
    this.changePageType(1);
    changeSelectedGenericCategory(-1);

    this.setState({ confirmModal: false, tempKey: "", tempCategory: "" });
  };

  confirmNavChangeModal = nav => {
    const { tempNav } = this.state;
    const { changeSizeChartNav, changeSelectedGenericCategory } = this.props;

    changeSizeChartNav(nav || tempNav);
    changeSelectedGenericCategory(-1);

    this.setState({ confirmModal: false, tempNav: "" });
  };

  renderContent = () => {
    const { page } = this.state;
    const {
      apiContent,
      brandsList,
      changeHandler,
      changeSelectedBrand,
      changeSelectedSubCategory,
      clearSubCategoryData,
      countryId,
      fetchBrandsData,
      fetchSubCategoryData,
      handleBrandToggle,
      handleGeneralChange,
      handleGeneralSave,
      handleInputTextChange,
      handleMetaDataSave,
      selectedBrand,
      selectedCategory,
      selectedGenericCategoryIndex,
      selectedSubCategory,
      selectedTab,
      storeId,
      subCategoryList,
      submitSubCategoryList,
      updateBrandSizeChart,
      updateGeneral,
      updateMeta,
      updateSizeChartBrandContentApi,
      updateSubCategory,
      updateSubCategoryData,
      user
    } = this.props;

    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.SIZE_CHART]: { accessRights = {} } = {}
      } = {}
    } = user || {};

    switch (selectedTab) {
      case SIZE_CHART_PANEL.SEO:
        return (
          <GeneralSeoContent
            apiContent={apiContent}
            countryId={countryId}
            handleGeneralChange={handleGeneralChange}
            handleMetaDataSave={handleMetaDataSave}
            updateMeta={updateMeta}
            accessRights={accessRights}
          />
        );
      case SIZE_CHART_PANEL.GENERAL:
        return (
          <GenericTab
            apiContent={apiContent}
            countryId={countryId}
            handleGeneralChange={handleGeneralChange}
            handleGeneralSave={handleGeneralSave}
            updateGeneral={updateGeneral}
            accessRights={accessRights}
          />
        );
    }

    switch (selectedCategory) {
      case SIZE_CHART_PANEL.BRAND_SIZE_CHART:
        return (
          <BrandChartTab
            apiContent={apiContent}
            brandsList={brandsList}
            changeHandler={changeHandler}
            changePageType={this.changePageType}
            changeSelectedBrand={changeSelectedBrand}
            changeSelectedSubCategory={changeSelectedSubCategory}
            clearSubCategoryData={clearSubCategoryData}
            countryId={countryId}
            fetchBrandsData={fetchBrandsData}
            fetchSubCategoryData={fetchSubCategoryData}
            handleBrandDelete={this.handleBrandDelete}
            handleBrandSubCategoryDelete={this.handleSubCategoryDelete}
            handleBrandSubCategoryTileChange={this.handleSubCategoryTileChange}
            handleBrandToggle={handleBrandToggle}
            handleInputTextChange={handleInputTextChange}
            handleReset={this.handleReset}
            page={page}
            selectedBrand={selectedBrand}
            selectedSubCategory={selectedSubCategory}
            selectedTab={selectedTab}
            storeId={storeId}
            subCategoryList={subCategoryList}
            submitSubCategoryList={submitSubCategoryList}
            updateBrandSizeChart={updateBrandSizeChart}
            updateSizeChartBrandContentApi={updateSizeChartBrandContentApi}
            updateSubCategory={updateSubCategory}
            updateSubCategoryData={updateSubCategoryData}
            accessRights={accessRights}
          />
        );
      case SIZE_CHART_PANEL.GENERIC_SIZE_CHART:
        return (
          <GenericChartTab
            handleSubCategoryTileChange={this.handleSubCategoryTileChange}
            selectedGenericCategoryIndex={selectedGenericCategoryIndex}
            accessRights={accessRights}
          />
        );
    }

    return null;
  };

  render() {
    const { confirmModal, modalText } = this.state;
    const { selectedCategory, selectedTab, showNav } = this.props;

    return (
      <div className="row m-t-10">
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={modalText}
          />
        )}
        <div className="header-container component-header">
          <div className="col-sm-3">
            <LeftPanel
              changeNav={this.changeNav}
              changeTab={this.changeTab}
              currentTab={selectedTab}
              isSizeChart
              selectedCategory={selectedCategory}
              showNav={showNav}
            />
          </div>
          <div className="col-sm-9">
            <div>{this.renderContent()}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = reduxState => ({
  user: reduxState.authentication.user,
  apiContent: reduxState.sizeChart.apiContent,
  brandsList: reduxState.sizeChart.brandsList,
  countryId: reduxState.store.countryId,
  selectedBrand: reduxState.sizeChart.selectedBrand,
  selectedCategory: reduxState.sizeChart.selectedCategory,
  selectedGenericCategoryIndex:
    reduxState.sizeChart.selectedGenericCategoryIndex,
  selectedGenericSubCategory: reduxState.sizeChart.selectedGenericSubCategory,
  selectedSubCategory: reduxState.sizeChart.selectedSubCategory,
  selectedTab: reduxState.sizeChart.selectedTab,
  showNav: reduxState.sizeChart.showNav,
  storeId: reduxState.store.storeId,
  subCategoryList: reduxState.sizeChart.subCategoryList,
  updateBrandSizeChart: reduxState.sizeChart.updateBrandSizeChart,
  updatedSelectedGenericSubCategory:
    reduxState.sizeChart.updatedSelectedGenericSubCategory,
  updateGeneral: reduxState.sizeChart.updateGeneral,
  updateGenericSizeChart: reduxState.sizeChart.updateGenericSizeChart,
  updateMeta: reduxState.sizeChart.updateMeta,
  updateSubCategory: reduxState.sizeChart.updateSubCategory
});

const mapDispatchToProps = {
  changeHandler,
  changeSelectedBrand,
  changeSelectedGenericCategory,
  changeSelectedSubCategory,
  changeSizeChartCategory,
  changeSizeChartNav,
  changeTab,
  clearSelectedGenericSubCategory,
  clearSubCategoryData,
  fetchBrandsData,
  fetchSizeChartData,
  fetchSubCategoryData,
  handleBrandDelete,
  handleBrandSubCategoryDelete,
  handleBrandToggle,
  handleGeneralChange,
  handleGeneralSave,
  handleInputTextChange,
  handleMetaDataSave,
  handleReset,
  submitSubCategoryList,
  updateSizeChartBrandContentApi,
  updateSubCategoryData
};

export default connect(mapStateToProps, mapDispatchToProps)(SizeChart);
