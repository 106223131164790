import {
  webapiGet,
  webapiPut,
  webapiPatch,
  webapiPost,
  webapiAuthPost,
  webapiDelete
} from "./core";

export function fetchWebApi(accessToken, url) {
  return webapiGet(accessToken, url).request;
}

export function putDashboardWebApi(accessToken, url, bodyObject, publish) {
  return webapiPut(accessToken, url, bodyObject, publish).request;
}

export function patchDashboardWebApi(accessToken, url, bodyObject, publish) {
  return webapiPatch(accessToken, url, bodyObject, publish).request;
}

export function postDashboardWebApi(accessToken, url, bodyObject, extraHeadersObj) {
  return webapiPost(accessToken, url, bodyObject, extraHeadersObj).request;
}

export function postAuthApi(url, bodyObject) {
  return webapiAuthPost(url, bodyObject).request;
}

export function deleteWebApi(accessToken, url, bodyObject) {
  return webapiDelete(accessToken, url, bodyObject).request;
}
