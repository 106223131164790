export const SECTIONS_PAGE_PANEL = {
  SECTIONS_ADD_PAGE_DISPLAY: "sectionsAddPageDisplay",
  SECTIONS_UPDATE_PAGE_DISPLAY: "sectionsUpdatePageDisplay",
  SECTIONS_DELETE_PAGE_DISPLAY: "sectionsDeletePageDisplay"
};
export const SECTIONS_PAGE = "SECTIONS_PAGE";

export const SECTIONS_PAGE_PAGETYPE = {
  header: "Header Sections",
  product_description_page: "PDP Sections",
  Checkout: "Checkout Sections"
};

export const SECTIONS_PAGE_TYPE_MAP = {
  header: "Header Sections",
  product_description_page: "PDP Sections",
  Checkout: "Checkout Sections"
};

export const SECTIONS_PAGE_TYPE = {
  header: "Header Sections",
  product_description_page: "PDP Sections",
  Checkout: "Checkout Sections"
};

export const SECTIONS_COMPONENT_TYPES = {
  BANNER: "banner",
  PRODUCT_CAROUSEL: "product-carousel",
  IMAGE_CAROUSEL: "catalog-carousel",
  DYNAMIC_SLIDER: "dynamicSliderModule",
  DYNAMIC_BANNER: "dynamicBannerModule"
};

export const SECTIONS_SECTION_TITLES = {
  DYNAMIC_BANNER: "Dynamic banner module",
  DYNAMIC_SLIDER: "Dynamic slider module"
};

export const SECTIONS_TYPE_SELECT_OPTIONS = [
  { label: "Banner", value: SECTIONS_COMPONENT_TYPES.BANNER },
  { label: "Image Carousel", value: SECTIONS_COMPONENT_TYPES.IMAGE_CAROUSEL },
  {
    label: "Product Carousel",
    value: SECTIONS_COMPONENT_TYPES.PRODUCT_CAROUSEL
  },
  {
    label: "Dynamic Banner Module",
    value: SECTIONS_COMPONENT_TYPES.DYNAMIC_BANNER
  },
  {
    label: "Dynamic Slider Module",
    value: SECTIONS_COMPONENT_TYPES.DYNAMIC_SLIDER
  }
];
