import React, { useState } from "react";
import { useDispatch } from "react-redux";
import cssValidator from 'w3c-css-validator';
import CodeMirror from '@uiw/react-codemirror';
import { css } from '@codemirror/lang-css';
import { okaidia } from '@uiw/codemirror-theme-okaidia';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '../../../materialUi/components';
import { cloneDeep } from "lodash";
import { snackbar } from "../../../actions/common.action";


const InlineCSSDialog = ({ open, handleClose, handleSave, title, dialogContext, data }) => {
  const dispatch = useDispatch();

  const [modalData, setModalData] = useState(data || {});


  const handleValidate = async (callback) => {
    if (!modalData.inlineStyle) {
      callback(cloneDeep(modalData));
      return;
    }
    const { valid, errors } = await cssValidator.validateText(modalData.inlineStyle || "");
    if (!valid) {
      dispatch(snackbar({ open: true, severity: "error", text: `Invalid CSS.${JSON.stringify(errors)}` }))
      return;
    }
    callback(cloneDeep(modalData));
    return;
  }

  const handleUpdateModalData = (key, value) => {
    setModalData({ ...modalData, [key]: value })
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {dialogContext}
          </DialogContentText>
          <CodeMirror
            value={modalData.inlineStyle || ""}
            height="400px"
            extensions={[css()]}
            theme={okaidia}
            onChange={(value) =>
              handleUpdateModalData("inlineStyle", value || "")
            } />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleValidate(handleSave)}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InlineCSSDialog