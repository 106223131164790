import React, { Fragment } from "react";
import BrandsChartContainer from "./brandsChartContainer";
import SearchBrandContainer from "./searchBrandContainer";
import BrandsSubCategory from "./brandsSubCategory";
import { SIZE_CHART_CATEGORY_MAP } from "../../constants";

const BrandChartTab = ({
  apiContent,
  brandsList,
  changeHandler,
  changePageType,
  changeSelectedBrand,
  changeSelectedSubCategory,
  clearSubCategoryData,
  countryId,
  fetchBrandsData,
  fetchSubCategoryData,
  handleBrandDelete,
  handleBrandSubCategoryDelete,
  handleBrandSubCategoryTileChange,
  handleBrandToggle,
  handleInputTextChange,
  handleReset,
  page,
  selectedBrand,
  selectedSubCategory,
  selectedTab,
  storeId,
  subCategoryList,
  submitSubCategoryList,
  updateBrandSizeChart,
  updateSizeChartBrandContentApi,
  updateSubCategory,
  updateSubCategoryData,
  accessRights
}) => {
  let _content;
  switch (page) {
    case 1:
      _content = (
        <BrandsChartContainer
          apiContent={apiContent}
          changePageType={changePageType}
          changeSelectedBrand={changeSelectedBrand}
          countryId={countryId}
          handleBrandDelete={handleBrandDelete}
          handleBrandToggle={handleBrandToggle}
          handleInputTextChange={handleInputTextChange}
          selectedTab={selectedTab}
          storeId={storeId}
          updateBrandSizeChart={updateBrandSizeChart}
          updateSizeChartBrandContentApi={updateSizeChartBrandContentApi}
          accessRights={accessRights}
        />
      );
      break;
    case 2:
      _content = (
        <SearchBrandContainer
          brandsList={brandsList}
          changePageType={changePageType}
          changeSelectedBrand={changeSelectedBrand}
          fetchBrandsData={fetchBrandsData}
          selectedBrand={selectedBrand}
        />
      );
      break;
    case 3:
      _content = (
        <BrandsSubCategory
          apiContent={apiContent}
          changeHandler={changeHandler}
          changePageType={changePageType}
          changeSelectedSubCategory={changeSelectedSubCategory}
          clearSubCategoryData={clearSubCategoryData}
          countryId={countryId}
          fetchSubCategoryData={fetchSubCategoryData}
          handleBrandSubCategoryDelete={handleBrandSubCategoryDelete}
          handleBrandSubCategoryTileChange={handleBrandSubCategoryTileChange}
          handleReset={handleReset}
          selectedBrand={selectedBrand}
          selectedSubCategory={selectedSubCategory}
          selectedTab={selectedTab}
          storeId={storeId}
          subCategoryList={subCategoryList}
          submitSubCategoryList={submitSubCategoryList}
          updateBrandSizeChart={updateBrandSizeChart}
          updateSubCategory={updateSubCategory}
          updateSubCategoryData={updateSubCategoryData}
        />
      );
      break;
    default:
      _content = null;
  }

  return (
    <Fragment>
      <h2 className="page-main-heading">
        Brand Size Chart - {SIZE_CHART_CATEGORY_MAP[selectedTab]}
      </h2>
      {_content}
    </Fragment>
  );
};

export default BrandChartTab;
