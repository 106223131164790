import React, { useCallback } from "react";
import { connect } from "react-redux";

import { LANGUAGE } from "../../constants";
import Accordion from "../../component/accordion";
import GeneralSeoContentForm from "./generalSeoContentForm";

const UpdateGeneralSeoContent = ({ seoContent, pageType, handleSeoUpdate }) => {

  return (
    <Accordion
      accordionId={pageType}
      headingWrapperClassName="catalog-banner-heading"
      sectionTitle="General SEO"
      title="General SEO"
      required
    >
      <GeneralSeoContentForm
        seoContent={seoContent || {}}
        handleUpdate={handleSeoUpdate}
        language={LANGUAGE.ENGLISH}
      />
      <GeneralSeoContentForm
        seoContent={seoContent || {}}
        handleUpdate={handleSeoUpdate}
        language={LANGUAGE.ARABIC}
      />
    </Accordion>
  );
};

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(UpdateGeneralSeoContent);
