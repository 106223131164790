import React from "react";
import Accordion from "../../component/accordion";
import Scheduler from "../../component/scheduler";
import {
  SCHEDULER_TYPE,
  LANGUAGE,
  SCHEDULER_PLACEHOLDERS
} from "../../constants";
import ProductCarouselForm from "./productCarouselForm";

const SCHEDULER_CHILD_CONTENT = [
  {
    language: LANGUAGE.ENGLISH,
    placeholder: SCHEDULER_PLACEHOLDERS[LANGUAGE.ENGLISH].productCarousel,
    componentType: SCHEDULER_TYPE.CAROUSEL_TYPE_B
  },
  {
    language: LANGUAGE.ARABIC,
    placeholder: SCHEDULER_PLACEHOLDERS[LANGUAGE.ARABIC].productCarousel,
    componentType: SCHEDULER_TYPE.CAROUSEL_TYPE_B
  }
];

const ProductCarouselAccordion = ({
  addNewScheduler,
  content,
  deleteProductCarousel,
  deleteScheduler,
  handleOpen,
  handleSchedulerDateChange,
  handleSchedulerTextChange,
  handleSchedulerToggleChange,
  handleTextChange,
  handleToggle,
  handleUrlChange,
  isEnabled,
  isOpen,
  productCarouselId = "pruduct_carousel",
  keyIndex
}) => (
  <Accordion
    accordionId={productCarouselId}
    title="Product Carousel"
    headingWrapperClassName="catalog-banner-heading"
    handleDelete={deleteProductCarousel}
    handleToggle={handleToggle}
    handleOpen={handleOpen}
    isToggled={isEnabled}
    isOpen={isOpen}
    isControlled={Boolean(handleOpen)}
    required
    content={content}
    keyIndex={keyIndex}
    withDragNDrop
  >
    <ProductCarouselForm
      content={content}
      handleTextChange={handleTextChange}
      handleUrlChange={handleUrlChange}
      language={LANGUAGE.ENGLISH}
    />
    <ProductCarouselForm
      content={content}
      handleTextChange={handleTextChange}
      handleUrlChange={handleUrlChange}
      language={LANGUAGE.ARABIC}
    />
    <Scheduler
      content={content}
      childContent={SCHEDULER_CHILD_CONTENT}
      addNewScheduler={addNewScheduler}
      deleteScheduler={deleteScheduler}
      handleDateChange={handleSchedulerDateChange}
      schedulerTextChange={handleSchedulerTextChange}
      handleToggleChange={handleSchedulerToggleChange}
    />
  </Accordion>
);

export default ProductCarouselAccordion;
