import { createSelector } from "reselect";

export const selectSectionsReducer = state => state.sectionsReducer;

export const selectActiveSectionsPage = createSelector(
  [selectSectionsReducer],
  sectionsReducer => sectionsReducer.activePage
);

export const selectActivePageContent = createSelector(
  [selectSectionsReducer],
  sectionsReducer => sectionsReducer.activePage.content
);
