import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import replace from "lodash/replace";
import { inputDirHelper } from "../util/input";

const InputText = ({
  alignRight,
  className,
  disabled,
  disableWhitespaces,
  textarea,
  label,
  language,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  rows,
  value,
  wrapperClassName,
  type,
  max,
  min,
  step
}) => {
  const handleOnChange = e => {
    if (disableWhitespaces) {
      e.target.value = replace(e.target.value, / /g, "");
    }
    onChange(e);
  };

  return (
    <div className={cn("form-group", wrapperClassName)}>
      {textarea ? (
        <textarea
          className={cn("form-control", className, {
            "text-right": alignRight && inputDirHelper(language)
          })}
          dir={alignRight ? "" : inputDirHelper(language)}
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange}
          placeholder={placeholder}
          rows={rows}
          value={value}
        />
      ) : (
        <input
          className={cn("form-control", className, {
            "text-right": alignRight && inputDirHelper(language)
          })}
          //dir={alignRight ? '' : inputDirHelper(language)}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange}
          placeholder={placeholder}
          type={type}
          value={value}
          max={max}
          min={min}
          step={step}
        />
      )}
      {label && (
        <label>
          {required && <span className="required" />}
          {label}
        </label>
      )}
    </div>
  );
};

InputText.propTypes = {
  /** alignRight changes the text alignment based on language without changing the text direction */
  alignRight: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  textarea: PropTypes.bool,
  label: PropTypes.string,
  language: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  wrapperClassName: PropTypes.string
};

InputText.defaultProps = {
  alignRight: false,
  disabled: false,
  label: "",
  onBlur: () => {},
  onChange: () => {},
  placeholder: "",
  required: false,
  rows: 12,
  textarea: false,
  type: "text",
  value: ""
};

export default InputText;
