import React from "react";

export const Clock = props => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.1872 18.6155C14.8757 18.6155 18.6765 14.8271 18.6765 10.1539C18.6765 5.48074 14.8757 1.69238 10.1872 1.69238C5.49868 1.69238 1.69788 5.48074 1.69788 10.1539C1.69788 14.8271 5.49868 18.6155 10.1872 18.6155Z"
        stroke="#3F3F3F"
        strokeWidth="1.69231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1871 5.0769V10.1538L13.5829 11.8461"
        stroke="#3F3F3F"
        strokeWidth="1.69231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
