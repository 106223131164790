import { cloneDeep } from "lodash";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import PageContentForm from "./pageContentForm";
import {
  URL_PAGE_TYPE_DROPDOWN,
  URL_PAGE_PANEL,
  URL_PAGE_MESSAGES,
  URL_COMPONENT_TYPES,
  URL_PAGE
} from "../../constants";
import {
  updateActiveUrlPage,
  validateUrlData,
  filterConditionProductCount,
  openComponent,
  toggleSection
} from "../../actions/urlCreation.action";
import { ENV_CONFIG } from "../../config/env";
import { snackbar } from "../../actions/common.action";
import { _urlFilterMap } from "../../util";
import replace from "lodash/replace";
import queryString from "query-string";
import UpdateGeneralSeoContent from "./updateGeneralSeoContent";
import { DynamicBannerContent } from "../../component";
import ProductCountModal from "./productCountModal";
import { Paper } from "../../materialUi/components";

class UpdatePageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openProductCountModal: false,
      productCountData: [],
      selectedCountry: null
    };
  }

  componentDidUpdate(prevProps) {
    const { activePage: { _id: prevActivePageId = "" } = {} } = prevProps;
    const { activePage: { _id = "" } = {}, countryList = [] } = this.props;
    const { selectedCountry } = this.state;
    if (countryList && countryList.length && !selectedCountry) {
      const { countrySHORT } = countryList[0];
      this.setState({ selectedCountry: { countrySHORT } });
    } else if (prevActivePageId !== _id && countryList && countryList.length) {
      const { countrySHORT } = countryList[0];
      this.setState({ selectedCountry: { countrySHORT } });
    }
  }

  handlePageTypeChange = (id, value) => {
    const { activePage = {}, updateActiveUrlPage } = this.props;
    if (activePage._id !== id) return;
    let updatedActivePage = cloneDeep(activePage);
    updatedActivePage["pageType"] = value;
    if (
      value === URL_PAGE_TYPE_DROPDOWN.static ||
      value === URL_PAGE_TYPE_DROPDOWN.staticAccordion ||
      value === URL_PAGE_TYPE_DROPDOWN.flashSale
    ) {
      updatedActivePage["filterCondition"] = "";
      updatedActivePage["filterCondition_rfc"] = "";
    }
    updateActiveUrlPage(updatedActivePage);
  };

  handleCountryChange = countrySHORT => {
    this.setState({ selectedCountry: { countrySHORT } });
  };

  handleChange = (id, key, value) => {
    const { activePage = {}, updateActiveUrlPage } = this.props;
    if (activePage._id !== id) return;
    if (key === "url" && value.includes("//")) {
      return;
    }
    let updatedActivePage = cloneDeep(activePage);
    updatedActivePage[key] = value;
    updateActiveUrlPage(updatedActivePage);
  };

  handleIntBtnClick = (countryCode = "", addFilterConditionInUrl = false) => {
    const { activePage } = this.props;
    let _url = addFilterConditionInUrl ? activePage.filterCondition || "" : "";
    window.open(
      `${ENV_CONFIG.redirectionUrl}-${countryCode.toLowerCase()}/list?${_url}`,
      "_blank"
    );
  };

  validateUrl = (filterCondition = "") => {
    const {
      validateUrlData,
      filterConditionProductCount,
      currentTab,
      activePage,
      snackbar
    } = this.props;
    let url = activePage.url || "";
    let pageType = activePage.pageType || "";

    if (!pageType) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.page_type_miss
      });
      return false;
    }
    if (
      [
        URL_PAGE_TYPE_DROPDOWN.productListing,
        URL_PAGE_TYPE_DROPDOWN.productCatalog
      ].includes(pageType) &&
      (filterCondition.charAt(0) === "?" ||
        filterCondition.charAt(0) === " " ||
        /^\s*$/.test(filterCondition))
    ) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.invalid_filter_con
      });
      return false;
    }
    const filteredMap = replace(_urlFilterMap(filterCondition), "+", "%2B");
    let filter = queryString.parse(filteredMap);
    for (let key in filter) {
      if (["min", "max"].indexOf(key) < 0 && filter[key])
        filter[key] = filter[key].split(",");
    }
    if (url.charAt(0) !== "/" || url.includes("//")) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.invalid_url
      });
      return false;
    }
    if (
      currentTab === URL_PAGE_PANEL.URL_ADD_PAGE_DISPLAY &&
      url.charAt(url.length - 1) !== "/"
    ) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.invalid_url_end
      });
      return false;
    }
    if (
      currentTab === URL_PAGE_PANEL.URL_ADD_PAGE_DISPLAY &&
      (url.includes("/brands/") || url.includes("/brand/"))) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.invalid_url_brands
      });

      return false;
    }
    if (currentTab === URL_PAGE_PANEL.URL_EDIT_PAGE_DISPLAY) {
      if (!filterCondition) return true;
      filterConditionProductCount({ condition: filter }).then(res => {
        if (res.data && Array.isArray(res.data)) {
          this.setState({
            openProductCountModal: true,
            productCountData: res.data || []
          });
          return true;
        } else {
          snackbar({
            open: true,
            severity: "error",
            text: URL_PAGE_MESSAGES.invalid_product_count_message
          });
          return false;
        }
      });
    } else {
      if (filterCondition) {
        validateUrlData({ url, pageType, filterCondition }).then(res => {
          if (res.data && res.data.code && res.data.code === 200) {
            filterConditionProductCount({ condition: filter }).then(res => {
              if (res.data && Array.isArray(res.data)) {
                this.setState({
                  openProductCountModal: true,
                  productCountData: res.data || []
                });
                return true;
              } else {
                snackbar({
                  open: true,
                  severity: "error",
                  text: URL_PAGE_MESSAGES.invalid_product_count_message
                });
                return false;
              }
            });
          } else {
            snackbar({
              open: true,
              severity: "error",
              text: URL_PAGE_MESSAGES.url_in_use
            });
            return false;
          }
        });
      } else {
        validateUrlData({ url, pageType, filterCondition }).then(res => {
          if (res.data && res.data.code && res.data.code === 200) {
            return true;
          } else {
            snackbar({
              open: true,
              severity: "error",
              text: URL_PAGE_MESSAGES.url_in_use
            });
            return false;
          }
        });
      }
    }
    return true;
  };

  handleSeoUpdate = (key, value, language) => {
    const { activePage = {}, updateActiveUrlPage } = this.props;
    let updatedActivePage = cloneDeep(activePage);
    if (!updatedActivePage.meta) updatedActivePage.meta = {};
    if (!updatedActivePage.meta[language])
      updatedActivePage.meta[language] = {};
    updatedActivePage.meta[language][key] = value;
    updateActiveUrlPage(updatedActivePage);
  };

  handleComponentOpen = (id, index) => {
    const { openComponent } = this.props;
    openComponent({ id, index });
  };

  handleToggleSection = index => {
    const { toggleSection } = this.props;
    toggleSection({ index });
  };

  getComponent = (type, { index, pageContent }) => {
    const { accessRights } = this.props;
    if (pageContent) {
      switch (type) {
        case URL_COMPONENT_TYPES.DYNAMIC_BANNER: {
          return (
            <DynamicBannerContent
              sectionTitle={pageContent.title || ""}
              content={pageContent}
              handleExpandSection={() =>
                this.handleComponentOpen(pageContent.id, index)
              }
              handleToggleSection={() => this.handleToggleSection(index)}
              keyIndex={index}
              listName="imageList"
              sliderId="dynamic_banner"
              accordionTitle={pageContent.title || ""}
              pageType={URL_PAGE}
              accessRights={accessRights}
            />
          );
        }
      }
    }
  };

  hideProductCountModal = () => {
    this.setState({ openProductCountModal: false, productCountData: [] });
  };

  render() {
    const {
      currentTab,
      activePage,
      isUrlValidatedHandler,
      countryList
    } = this.props;
    const { selectedCountry } = this.state;
    const { openProductCountModal, productCountData } = this.state;
    if (openProductCountModal) {
      return (
        <ProductCountModal
          onHide={this.hideProductCountModal}
          productCountData={productCountData}
          handleIntBtnClick={this.handleIntBtnClick}
          open={openProductCountModal}
          handleClose={this.hideProductCountModal}
          title={"Product Count"}
          dialogContext={""}
        />
      );
    }
    return (
      <Fragment>
        <div className="banner-catalog-dnd">
          <Paper
            variant="outlined"
            sx={{ padding: "20px 15px 15px 15px", marginBottom: "10px" }}
          >
            <PageContentForm
              content={activePage}
              currentTab={currentTab}
              handlePageTypeChange={this.handlePageTypeChange}
              handleChange={this.handleChange}
              validateUrlHandler={() => {
                isUrlValidatedHandler(
                  this.validateUrl(activePage.filterCondition)
                );
              }}
              validateFilterConditionRfc={() => {
                this.validateUrl(activePage.filterCondition_rfc);
              }}
              countryList={countryList || []}
              handleCountryChange={this.handleCountryChange}
              handleIntBtnClick={this.handleIntBtnClick}
              selectedCountry={selectedCountry || {}}
            />
          </Paper>
          <div
            className="url-generation-form-container"
            style={{ backgroundColor: "fff" }}
          >
            <DragDropContext>
              <Droppable droppableId="droppable">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {activePage &&
                      (activePage.pageType ===
                        URL_PAGE_TYPE_DROPDOWN.productListing ||
                        activePage.pageType ===
                        URL_PAGE_TYPE_DROPDOWN.flashSale) ? (
                      <UpdateGeneralSeoContent
                        seoContent={activePage.meta || {}}
                        pageType={URL_PAGE}
                        handleSeoUpdate={this.handleSeoUpdate}
                      />
                    ) : null}
                    {_.map(activePage.content || [], (pageContent, index) => (
                      <div className={pageContent.type} key={index}>
                        {this.getComponent(pageContent.type, {
                          pageContent,
                          index
                        })}
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.store.countryList,

});

const mapDispatchToProps = dispatch => ({
  updateActiveUrlPage: bindActionCreators(updateActiveUrlPage, dispatch),
  snackbar: bindActionCreators(snackbar, dispatch),
  validateUrlData: bindActionCreators(validateUrlData, dispatch),
  filterConditionProductCount: bindActionCreators(
    filterConditionProductCount,
    dispatch
  ),
  openComponent: bindActionCreators(openComponent, dispatch),
  toggleSection: bindActionCreators(toggleSection, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePageContent);
