import qs from "query-string";
import includes from "lodash/includes";
import { ENV_CONFIG } from "../config/env";
import {
  SET_ACTIVE_PAGE,
  CHANGE_PAGES_FOLDER_NAME,
  CREATE_NEW_FOLDER,
  SET_PAGE_DATA,
  SET_PAGES_ROUTE,
  UPDATE_OPENED_CONTAINERS,
  UPDATE_OPENED_FOLDERS,
  SET_PAGES,
  ADD_NEW_COMPONENT,
  UPDATE_PAGE_DATA,
  PAGE_OPEN_COMPONENT,
  PAGE_DELETE_COMPONENT,
  PAGE_TOGGLE_COMPONENT,
  REORDER_COMPONENTS,
  UPDATE_BANNER_DEFAULT_TEXT_PAGES_PAGE,
  HANDLE_DATE_CHANGE_PAGES_PAGE,
  SCHEDULER_TEXT_CHANGE_PAGES_PAGE,
  HANDLE_TOGGLE_PAGES_PAGE,
  ADD_NEW_SCHEDULER_PAGES_PAGE,
  DELETE_SCHEDULER_PAGES_PAGE,
  ADD_IMAGE_LIST_PAGES_PAGE,
  SCHEDULER_ADD_IMAGE_LIST,
  UPDATE_SCHEDULER_BANNER_DEFAULT_TEXT_PAGES_PAGE,
  TYPE_B_UPDATE_HANDLE_DATE_CHANGE_PAGES_PAGE,
  DELETE_IMAGE_PAGES_PAGE,
  EDIT_IMAGE_LIST_PAGES_PAGE,
  HANDLE_IMAGE_TOGGLE_PAGES_PAGE,
  SCHEDULER_DELETE_IMAGE_PAGES_PAGE,
  SCHEDULER_HANDLE_IMAGE_TOGGLE_PAGES_PAGE,
  SCHEDULER_EDIT_IMAGE_LIST_PAGES_PAGE,
  UPDATE_TYPE2_TEXT_PAGES_PAGE,
  UPDATE_TYPE_B_SCHEDULER_TEXT_CHANGE_PAGES_PAGE,
  TOGGLE_RESET,
  RESET_PAGES_CONTENT,
  SELECT_NEW_PAGE_FOLDER,
  SET_NEW_PAGE_FOLDER,
  RESET_PAGES_PAGE,
  SET_NEW_ADDED_PAGE,
  SET_NEW_ADDED_FOLDER,
  SET_PAGES_BY_COUNTRY,
  SET_PAGES_ROLLBACK_hISTORY,
  TOGGLE_ROLLBACK_SAVE
} from "../constants/actions";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
  snackbar
} from "./common.action";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import { getSchedulerMainData } from "../util/scheduler";
import {
  fetchWebApi,
  patchDashboardWebApi,
  postDashboardWebApi,
  putDashboardWebApi,
  deleteWebApi
} from "../webapis/apiResource";
import { getPageRoute } from "../util";
import { mapPagesPageToFlatStructure } from "../util/reducer";
import {
  ERROR_MESSAGES,
  PAGES_PAGE_ACTIVITIES,
  SUCCESS_MESSAGES
} from "../constants";

export const setActivePage = page => ({
  type: SET_ACTIVE_PAGE,
  payload: page
});

export const changePagesFolderName = (folder, newTitle) => ({
  type: CHANGE_PAGES_FOLDER_NAME,
  payload: { folder, newTitle }
});

export const createNewFolder = (parent, folderName) => ({
  type: CREATE_NEW_FOLDER,
  payload: { parent, folderName }
});

const setPages = pages => ({
  type: SET_PAGES,
  payload: pages
});

const setPagesByCountry = pages => ({
  type: SET_PAGES_BY_COUNTRY,
  payload: pages
});

export const setPagesRoute = pageRoute => ({
  type: SET_PAGES_ROUTE,
  payload: pageRoute
});

export const updateOpenedContainers = payload => ({
  type: UPDATE_OPENED_CONTAINERS,
  payload
});

export const updateOpenedFolders = payload => ({
  type: UPDATE_OPENED_FOLDERS,
  payload
});

export const addNewComponent = componentType => ({
  type: ADD_NEW_COMPONENT,
  componentType
});

export const updatePageData = (path, value) => ({
  type: UPDATE_PAGE_DATA,
  path,
  value
});

export const openComponent = ({ id, index }) => ({
  type: PAGE_OPEN_COMPONENT,
  id,
  index
});

export const deleteComponent = ({ index }) => ({
  type: PAGE_DELETE_COMPONENT,
  index
});

export const toggleSection = ({ index }) => ({
  type: PAGE_TOGGLE_COMPONENT,
  index
});

export const reorderComponents = ({ content }) => ({
  type: REORDER_COMPONENTS,
  content
});

export const updateBannerDefaultText = (name, value, position, language) => ({
  type: UPDATE_BANNER_DEFAULT_TEXT_PAGES_PAGE,
  name,
  value,
  position,
  language
});

export const handleDateChange = (
  name,
  schedulerIndex,
  timeStamp,
  position
) => ({
  type: HANDLE_DATE_CHANGE_PAGES_PAGE,
  name,
  schedulerIndex,
  timeStamp,
  position
});

export const schedulerTextChange = (
  value,
  position,
  language,
  schedulerIndex
) => ({
  type: SCHEDULER_TEXT_CHANGE_PAGES_PAGE,
  value,
  position,
  language,
  schedulerIndex
});

export const handleToggle = (position, schedulerIndex, schedulerValue) => ({
  type: HANDLE_TOGGLE_PAGES_PAGE,
  position,
  schedulerIndex,
  schedulerValue
});

export const addNewScheduler = (position, schedulerType) => ({
  type: ADD_NEW_SCHEDULER_PAGES_PAGE,
  position,
  schedulerType
});

export const deleteScheduler = (position, schedulerIndex) => ({
  type: DELETE_SCHEDULER_PAGES_PAGE,
  position,
  schedulerIndex
});

export const addImageList = (name, position) => ({
  type: ADD_IMAGE_LIST_PAGES_PAGE,
  name,
  position
});

export const schedulerAddImageList = (schedulerIndex, name, position) => ({
  type: SCHEDULER_ADD_IMAGE_LIST,
  schedulerIndex,
  name,
  position
});

export const updateSchedulerDefaultText = (
  name,
  value,
  position,
  language,
  schedulerIndex
) => ({
  type: UPDATE_SCHEDULER_BANNER_DEFAULT_TEXT_PAGES_PAGE,
  name,
  value,
  position,
  language,
  schedulerIndex
});

export const updateTypeBHandleDateChange = (
  name,
  schedulerIndex,
  timeStamp,
  position
) => ({
  type: TYPE_B_UPDATE_HANDLE_DATE_CHANGE_PAGES_PAGE,
  name,
  schedulerIndex,
  timeStamp,
  position
});

export const deleteImage = (position, imageIndex) => ({
  type: DELETE_IMAGE_PAGES_PAGE,
  position,
  imageIndex
});

export const editImageList = (
  name,
  value,
  arrayName,
  position,
  imageIndex,
  language
) => ({
  type: EDIT_IMAGE_LIST_PAGES_PAGE,
  name,
  value,
  arrayName,
  position,
  imageIndex,
  language
});

export const handleImageToggle = (position, imageIndex, value) => ({
  type: HANDLE_IMAGE_TOGGLE_PAGES_PAGE,
  position,
  imageIndex,
  value
});

export const schedulerDeleteImage = (schedulerIndex, position, imageIndex) => ({
  type: SCHEDULER_DELETE_IMAGE_PAGES_PAGE,
  schedulerIndex,
  position,
  imageIndex
});

export const schedulerHandleImageToggle = (
  schedulerIndex,
  position,
  imageIndex,
  value
) => ({
  type: SCHEDULER_HANDLE_IMAGE_TOGGLE_PAGES_PAGE,
  schedulerIndex,
  position,
  imageIndex,
  value
});

export const schedulerEditImageList = (
  schedulerIndex,
  name,
  value,
  arrayName,
  position,
  imageIndex,
  language
) => ({
  type: SCHEDULER_EDIT_IMAGE_LIST_PAGES_PAGE,
  schedulerIndex,
  name,
  value,
  arrayName,
  position,
  imageIndex,
  language
});

export const updateType2Text = (name, value, position, language) => ({
  type: UPDATE_TYPE2_TEXT_PAGES_PAGE,
  name,
  value,
  position,
  language
});

export const updateTypeBSchedulerTextChange = (
  name,
  value,
  position,
  language,
  schedulerIndex
) => ({
  type: UPDATE_TYPE_B_SCHEDULER_TEXT_CHANGE_PAGES_PAGE,
  name,
  value,
  position,
  language,
  schedulerIndex
});

export const toggleReset = value => ({ type: TOGGLE_RESET, value });

export const resetPagesContent = () => ({ type: RESET_PAGES_CONTENT });

export const selectNewPageFolder = folder => ({
  type: SELECT_NEW_PAGE_FOLDER,
  folder
});

export const setNewPageFolder = folder => ({
  type: SET_NEW_PAGE_FOLDER,
  folder
});

export const setPagesRollbackHistory = history => ({
  type: SET_PAGES_ROLLBACK_hISTORY,
  payload: history
});

export const toggleRollbackSave = value => ({
  type: TOGGLE_ROLLBACK_SAVE,
  value
});

const manageRequestPromise = (requestPromise, dispatch, activityType) =>
  requestPromise
    .then(response => {
      const { data } = response;
      if (data.code === 200) {
        dispatch(setPages(data.data));
        activityType &&
          dispatch(snackbar({
            open: true, severity: "success",
            text: SUCCESS_MESSAGES[activityType]
          }));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message ||
            response.data.error.detail ||
            ERROR_MESSAGES[activityType]
        }));
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });

export const getPages = () => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;

  // dispatch(showLoadingIndicator());
  dispatch(resetPagesPage());
  return manageRequestPromise(
    fetchWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages`,
        query: { storeId, countryId: countryId || 236 }
      })
    ),
    dispatch
  );
};

export const getPagesByCountry = countryId => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { storeId } = store;

  return fetchWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/pages`,
      query: { storeId, countryId }
    })
  )
    .then(response => {
      const {
        data: { code, data }
      } = response;

      dispatch(setPagesByCountry(data));

      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      //dispatch(hideLoadingIndicator());
    });
};

export const getPageData = pageId => (dispatch, getState) => {
  const state = getState();
  const { store, pagesPageReducer } = state;
  const { countryId, storeId } = store;
  const { pagesContent } = pagesPageReducer;
  if (includes(pagesContent, el => el._id === pageId)) {
    return null;
  }
  // dispatch(showLoadingIndicator());

  return fetchWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/pages/${pageId}`,
      query: { storeId, countryId }
    })
  )
    .then(response => {
      const {
        data: { code, data }
      } = response;

      if (code === 200) {
        dispatch({
          type: SET_PAGE_DATA,
          payload: data
        });
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

export const updateFolder = (parent, folder, title) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  const { isScheduler, isVariant } = parent;
  dispatch(showLoadingIndicator());
  const bodyData = { title };
  return manageRequestPromise(
    patchDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages/folder/${folder.id}`,
        query: { storeId, countryId, isScheduler, isVariant }
      }),
      bodyData
    ),
    dispatch,
    PAGES_PAGE_ACTIVITIES.FOLDER_RENAMED
  );
};

export const createFolder = (parent, title) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  const { isScheduler, isVariant } = parent;
  dispatch(showLoadingIndicator());
  const bodyData = { title };
  return manageRequestPromise(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages/folder/`,
        query: { storeId, countryId, isScheduler, isVariant }
      }),
      bodyData
    ),
    dispatch,
    PAGES_PAGE_ACTIVITIES.FOLDER_CREATED
  );
};

export const deleteFolder = (parent, folder) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  const { isScheduler, isVariant } = parent;
  dispatch(showLoadingIndicator());
  return manageRequestPromise(
    deleteWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages/folder/${folder.id}`,
        query: { storeId, countryId, isScheduler, isVariant }
      })
    ),
    dispatch,
    PAGES_PAGE_ACTIVITIES.FOLDER_DELETED
  );
};

export const addSchedulersToPage = (schedulers, page) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  dispatch(showLoadingIndicator());
  const shapedSchedulers = schedulers.map(
    ({ scheduledPage, ...restOfProps }) => ({
      _id: scheduledPage,
      ...getSchedulerMainData(restOfProps)
    })
  );
  const bodyData = {
    schedulers: shapedSchedulers
  };
  dispatch(updatePageData("schedulers", shapedSchedulers));
  return manageRequestPromise(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages/${page.id}/schedule`,
        query: { storeId, countryId }
      }),
      bodyData
    ),
    dispatch
  );
};

export const clonePage = (pageId, folderId, { isScheduler, isVariant }) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  dispatch(showLoadingIndicator());
  return manageRequestPromise(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages/clone/${pageId}`,
        query: { storeId, countryId, folderId, isScheduler, isVariant }
      })
    ),
    dispatch,
    PAGES_PAGE_ACTIVITIES.PAGE_CLONED
  );
};

export const cloneDynamicModulePage = (moduleId, destinationPageId) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { store } = state;
  const { countryId } = store;

  const pageId = getState().pagesPageReducer.activePage.id;
  const resetToggled = getState().pagesPageReducer.resetToggled;

  if (!resetToggled) {
    dispatch(showLoadingIndicator());
    return manageRequestPromise(
      postDashboardWebApi(
        getAccessToken(getState),
        qs.stringifyUrl({
          url: `${ENV_CONFIG.configBaseURL}/pages/copyModule/${pageId}`,
          query: {
            countryId,
            destinationPageId,
            moduleId
          }
        })
      ),
      dispatch,
      PAGES_PAGE_ACTIVITIES.DYNAMIC_MODULE_COPIED
    );
  } else {
    dispatch(snackbar({
      open: true, severity: "error",
      text: "Please save all the changes before you clone"
    }));
  }
};

export const createNewPage = (parent, page) => (dispatch, getState) => {
  const state = getState();
  const { store, selectedCountryId } = state;
  const { countryId, storeId } = store;
  const bodyData = {
    countryIds: selectedCountryId,
    folderTitle: parent.title,
    data: page
  };
  dispatch(showLoadingIndicator());

  return manageRequestPromise(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages`,
        query: { storeId, countryId, isScheduler: parent.scheduled, isVariant: parent.variant }
      }),
      bodyData
    ),
    dispatch,
    PAGES_PAGE_ACTIVITIES.PAGE_CREATED
  );
};

export const updatePage = (page, tempsave) => (dispatch, getState) => {
  const state = getState();
  const {
    store,
    selectedCountryId,
    pagesPageReducer: {
      pagesRoute: { folder },
      newFolder
    }
  } = state;
  const { countryId } = store;

  const currentFolder = newFolder || folder;

  const bodyData = {
    countryIds: selectedCountryId,
    folderTitle: folder.title,
    newFolderTitle: newFolder ? newFolder.title : null,
    data: {
      ...page,
      content: page.content
    }
  };

  dispatch(showLoadingIndicator());

  return putDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/pages/${page._id}`,
      query: { countryId, isScheduler: currentFolder.scheduled, isVariant: currentFolder.variant }
    }),
    bodyData,
    tempsave
  )
    .then(response => {
      const { data } = response;
      if (data.code === 200) {
        dispatch(setPages(data.data));
        dispatch(getPageData(page._id));
        const pagesFoldersShape = mapPagesPageToFlatStructure(data.data);
        const pageRouteObj = getPageRoute(pagesFoldersShape, page._id);
        dispatch(setPagesRoute(pageRouteObj));
        dispatch(toggleRollbackSave(false));
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

export const deletePage = (parent, page) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  dispatch(showLoadingIndicator());
  return manageRequestPromise(
    deleteWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages/${page.id}`,
        query: { storeId, countryId, isScheduler: parent.scheduled, isVariant: parent.variant }
      })
    ),
    dispatch,
    PAGES_PAGE_ACTIVITIES.PAGE_DELETED
  );
};

export const updateVariant = ({ isScheduler, isVariant }, pageId, variants = []) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  dispatch(showLoadingIndicator());
  const bodyData = { variants };
  dispatch(updatePageData("variants", variants));
  return manageRequestPromise(
    patchDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages/variant/${pageId}`,
        query: { storeId, countryId, isScheduler, isVariant }//Needs to be updated
      }),
      bodyData
    ),
    dispatch,
    PAGES_PAGE_ACTIVITIES.VARIANTS_UPDATED
  );
};

export const resetPagesPage = () => ({
  type: RESET_PAGES_PAGE
});

export const setNewAddedPage = newPage => ({
  type: SET_NEW_ADDED_PAGE,
  payload: newPage
});

export const setNewAddedFolder = newFolder => ({
  type: SET_NEW_ADDED_FOLDER,
  payload: newFolder
});

export const setPageOnTop = (parent, page) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  const { isScheduler, isVariant } = parent;
  dispatch(showLoadingIndicator());

  return manageRequestPromise(
    patchDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages/keepOnTop/${page.id}`,
        query: { storeId, countryId, folderId: parent.id, isScheduler, isVariant }
      }),
      {}
    ),
    dispatch
  );
};

export const sortPages = (folder, direction) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  const { scheduled, variant } = folder;
  dispatch(showLoadingIndicator());

  return manageRequestPromise(
    fetchWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/pages`,
        query: {
          storeId,
          countryId,
          folderId: folder.id,
          sortBy: "title",
          direction,
          isScheduler: scheduled,
          isVariant: variant
        }
      })
    ),
    dispatch
  );
};

//Rollback
export const getAllRollbackHistory = (id, fromDate, toDate) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;

  dispatch(showLoadingIndicator());

  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/history/pages/${id}?countryId=${countryId}`
    }),
    { fromDate, toDate }
  )
    .then(response => {
      dispatch(setPagesRollbackHistory(response.data));
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

export const getRollbackCompareVersionData = (id, versions) => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/history/pages/content?referenceId=${id}`
    }),
    { versions }
  )
    .then(response => {
      if (!response || response.status !== 200 ||
        !response.data || !response.data.data ||
        response.data.data.length < 2) {
        dispatch(snackbar({ open: true, severity: "error", text: "Error while fetching versions Info " }));
        return []
      } else {
        return response.data.data
      }
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

const manageRequestPromiseRollback = (requestPromise, dispatch, activityType) =>
  requestPromise
    .then(response => {
      const { data } = response;
      if (data.code === 200) {
        dispatch({
          type: SET_PAGE_DATA,
          payload: data.data
        });
        dispatch(snackbar({
          open: true, severity: "success",
          text: "Rollback successful"
        }));
        dispatch(toggleRollbackSave(true));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message ||
            response.data.error.detail ||
            ERROR_MESSAGES[activityType]
        }));
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });

export const setPageRollback = id => (dispatch, getState) => {
  const state = getState();
  dispatch(showLoadingIndicator());
  return manageRequestPromiseRollback(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/history/pages/rollback`
      }),
      { versionId: id }
    ),
    dispatch
  );
};
