export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const CHANGE_PAGES_FOLDER_NAME = "CHANGE_PAGES_FOLDER_NAME";
export const CREATE_NEW_FOLDER = "CREATE_NEW_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_PAGE = "DELETE_PAGE";
export const SET_PAGES = "SET_PAGES";
export const RESET_PAGES_PAGE = "RESET_PAGES_PAGE";
export const SET_NEW_ADDED_PAGE = "SET_NEW_ADDED_PAGE";
export const SET_NEW_ADDED_FOLDER = "SET_NEW_ADDED_FOLDER";

export const ADD_NEW_COMPONENT = "ADD_NEW_COMPONENT";
export const UPDATE_PAGE_DATA = "UPDATE_PAGE_DATA";
export const PAGE_OPEN_COMPONENT = "PAGE_OPEN_COMPONENT";
export const PAGE_DELETE_COMPONENT = "PAGE_DELETE_COMPONENT";
export const PAGE_TOGGLE_COMPONENT = "PAGE_TOGGLE_COMPONENT";
export const REORDER_COMPONENTS = "REORDER_COMPONENTS";
export const CLONE_MODULE_PAGES_PAGE = "CLONE_MODULE_PAGES_PAGE";
export const MODULE_DATA_CHANGE_PAGES_PAGE = "MODULE_DATA_CHANGE_PAGES_PAGE";
export const ADD_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE =
  "ADD_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE";
export const DELETE_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE =
  "DELETE_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE";
export const TOGGLE_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE =
  "TOGGLE_SCHEDULER_DEVICE_LEVEL_PAGES_PAGE";
export const SCHEDULER_DATE_CHANGE_DEVICE_LEVEL_PAGES_PAGE =
  "SCHEDULER_DATE_CHANGE_DEVICE_LEVEL_PAGES_PAGE";
export const GENERATE_IMAGES_PAGES_PAGE = "GENERATE_IMAGES_PAGES_PAGE";
export const TOGGLE_LIST_ITEM_PAGES_PAGE = "TOGGLE_LIST_ITEM_PAGES_PAGE";
export const DELETE_LIST_ITEM_PAGES_PAGE = "DELETE_LIST_ITEM_PAGES_PAGE";
export const UPDATE_LIST_DATA_PAGES_PAGE = "UPDATE_LIST_DATA_PAGES_PAGE";
export const UPDATE_BANNER_SECTION_DATA_STYLES_PAGES_PAGE =
  "UPDATE_BANNER_SECTION_DATA_STYLES_PAGES_PAGE";
export const PUT_BANNER_DATA_STYLES_PAGES_PAGE =
  "PUT_BANNER_DATA_STYLES_PAGES_PAGE";
export const CLEAR_BANNER_ROW_IMAGE_DATA_PAGES_PAGE =
  "CLEAR_BANNER_ROW_IMAGE_DATA_PAGES_PAGE";
export const UPDATE_BANNER_SECTION_DATA_PAGES_PAGE =
  "UPDATE_BANNER_SECTION_DATA_PAGES_PAGE";
export const ADD_BANNER_SCHEDULER_PAGES_PAGE =
  "ADD_BANNER_SCHEDULER_PAGES_PAGE";
export const HANDLE_DELETE_BANNER_SCHEDULER_PAGES_PAGE =
  "HANDLE_DELETE_BANNER_SCHEDULER_PAGES_PAGE";
export const HANDLE_DATA_CHANGE_BANNER_SCHEDULER_PAGES_PAGE =
  "HANDLE_DATA_CHANGE_BANNER_SCHEDULER_PAGES_PAGE";
export const HANDLE_ROW_SCHEDULER_TOGGLE_PAGES_PAGE =
  "HANDLE_ROW_SCHEDULER_TOGGLE_PAGES_PAGE";
export const CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_PAGES_PAGE =
  "CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_PAGES_PAGE";
export const COPY_CONTENT_TO_SCHEDULER_PAGES_PAGE =
  "COPY_CONTENT_TO_SCHEDULER_PAGES_PAGE";
export const CLONE_ROW_PAGES_PAGE = "CLONE_ROW_PAGES_PAGE";
export const CLONE_SCHEDULER_PAGES_PAGE = "CLONE_SCHEDULER_PAGES_PAGE";
export const ADD_BANNER_ROW_ITEM_PAGES_PAGE = "ADD_BANNER_ROW_ITEM_PAGES_PAGE";
export const ADD_BANNER_SLIDER_ROW_IMAGES_PAGES_PAGE =
  "ADD_BANNER_SLIDER_ROW_IMAGES_PAGES_PAGE";
export const DELETE_ROW_IMAGE_PAGES_PAGE = "DELETE_ROW_IMAGE_PAGES_PAGE";
export const REORDER_BANNER_ROW_IMAGES_PAGES_PAGE =
  "REORDER_BANNER_ROW_IMAGES_PAGES_PAGE";
export const ITEMS_PER_GROUP_CHANGE_PAGES_PAGE =
  "ITEMS_PER_GROUP_CHANGE_PAGES_PAGE";
export const PRODUCT_CAROUSEL_DATA_CHANGE_PAGES_PAGE =
  "PRODUCT_CAROUSEL_DATA_CHANGE_PAGES_PAGE";
export const UPDATE_ROW_TYPE_CUSTOM_DATA_PAGES_PAGE =
  "UPDATE_ROW_TYPE_CUSTOM_DATA_PAGES_PAGE";
export const ADD_ROW_TYPE_CUSTOM_FIELD_PAGES_PAGE =
  "ADD_ROW_TYPE_CUSTOM_FIELD_PAGES_PAGE";
export const UPDATE_ROW_TYPE_CUSTOM_MODULE_PAGES_PAGE =
  "UPDATE_ROW_TYPE_CUSTOM_MODULE_PAGES_PAGE";
export const REMOVE_ROW_TYPE_CUSTOM_FIELD_PAGES_PAGE =
  "REMOVE_ROW_TYPE_CUSTOM_FIELD_PAGES_PAGE";
export const ADD_ROW_TYPE_ACCORDION_FIELD_PAGES_PAGE =
  "ADD_ROW_TYPE_ACCORDION_FIELD_PAGES_PAGE";
export const UPDATE_ROW_TYPE_ACCORDION_DATA_PAGES_PAGE =
  "UPDATE_ROW_TYPE_ACCORDION_DATA_PAGES_PAGE";
export const UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_PAGES_PAGE =
  "UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_PAGES_PAGE";
export const REMOVE_ROW_TYPE_ACCORDION_FIELD_PAGES_PAGE =
  "REMOVE_ROW_TYPE_ACCORDION_FIELD_PAGES_PAGE";
export const ADD_ROW_TYPE_BULLET_FIELD_PAGES_PAGE =
  "ADD_ROW_TYPE_BULLET_FIELD_PAGES_PAGE";
export const UPDATE_ROW_TYPE_BULLET_DATA_PAGES_PAGE =
  "UPDATE_ROW_TYPE_BULLET_DATA_PAGES_PAGE";
export const UPDATE_ROW_TYPE_BULLET_HEADER_DATA_PAGES_PAGE =
  "UPDATE_ROW_TYPE_BULLET_HEADER_DATA_PAGES_PAGE";
export const REMOVE_ROW_TYPE_BULLET_FIELD_PAGES_PAGE =
  "REMOVE_ROW_TYPE_BULLET_FIELD_PAGES_PAGE";
export const ADD_ROW_TYPE_TAB_FIELD_PAGES_PAGE =
  "ADD_ROW_TYPE_TAB_FIELD_PAGES_PAGE";
export const UPDATE_ROW_TYPE_TAB_DATA_PAGES_PAGE =
  "UPDATE_ROW_TYPE_TAB_DATA_PAGES_PAGE";
export const UPDATE_ROW_TYPE_TABS_PAGES_PAGE =
  "UPDATE_ROW_TYPE_TABS_PAGES_PAGE";
export const REMOVE_ROW_TYPE_TAB_PAGES_PAGE =
  "REMOVE_ROW_TYPE_TAB_PAGES_PAGE";
export const CHANGE_BANNER_IMAGE_HOVER_STYLE_PAGES_PAGE =
  "CHANGE_BANNER_IMAGE_HOVER_STYLE_PAGES_PAGE";
export const UPDATE_BANNER_ROW_IMAGE_DATA_PAGES_PAGE =
  "UPDATE_BANNER_ROW_IMAGE_DATA_PAGES_PAGE";
export const COUNTDOWN_DATA_CHANGE_PAGES_PAGE =
  "COUNTDOWN_DATA_CHANGE_PAGES_PAGE";
export const SCHEDULER_DATA_CHANGE_DEVICE_LEVEL_PAGES_PAGE =
  "SCHEDULER_DATA_CHANGE_DEVICE_LEVEL_PAGES_PAGE";
export const CLONE_MOBILE_PAGES_PAGE = "CLONE_MOBILE_PAGES_PAGE";
export const CLONE_DYNAMIC_MODULE_PAGES_PAGE =
  "CLONE_DYNAMIC_MODULE_PAGES_PAGE";
export const CLONE_BANNER_SCHEDULER_PAGES_PAGE =
  "CLONE_BANNER_SCHEDULER_PAGES_PAGE";
/* Old modules */
export const UPDATE_BANNER_DEFAULT_TEXT_PAGES_PAGE =
  "UPDATE_BANNER_DEFAULT_TEXT_PAGES_PAGE";
export const HANDLE_DATE_CHANGE_PAGES_PAGE = "HANDLE_DATE_CHANGE_PAGES_PAGE";
export const SCHEDULER_TEXT_CHANGE_PAGES_PAGE =
  "SCHEDULER_TEXT_CHANGE_PAGES_PAGE";
export const HANDLE_TOGGLE_PAGES_PAGE = "HANDLE_TOGGLE_PAGES_PAGE";
export const ADD_NEW_SCHEDULER_PAGES_PAGE = "ADD_NEW_SCHEDULER_PAGES_PAGE";
export const DELETE_SCHEDULER_PAGES_PAGE = "DELETE_SCHEDULER_PAGES_PAGE";
export const ADD_IMAGE_LIST_PAGES_PAGE = "ADD_IMAGE_LIST_PAGES_PAGE";
export const SCHEDULER_ADD_IMAGE_LIST = "SCHEDULER_ADD_IMAGE_LIST";
export const UPDATE_SCHEDULER_BANNER_DEFAULT_TEXT_PAGES_PAGE =
  "UPDATE_SCHEDULER_BANNER_DEFAULT_TEXT_PAGES_PAGE";
export const TYPE_B_UPDATE_HANDLE_DATE_CHANGE_PAGES_PAGE =
  "TYPE_B_UPDATE_HANDLE_DATE_CHANGE_PAGES_PAGE";
export const DELETE_IMAGE_PAGES_PAGE = "DELETE_IMAGE_PAGES_PAGE";
export const EDIT_IMAGE_LIST_PAGES_PAGE = "EDIT_IMAGE_LIST_PAGES_PAGE";
export const HANDLE_IMAGE_TOGGLE_PAGES_PAGE = "HANDLE_IMAGE_TOGGLE_PAGES_PAGE";
export const SCHEDULER_DELETE_IMAGE_PAGES_PAGE =
  "SCHEDULER_DELETE_IMAGE_PAGES_PAGE";
export const SCHEDULER_HANDLE_IMAGE_TOGGLE_PAGES_PAGE =
  "SCHEDULER_HANDLE_IMAGE_TOGGLE_PAGES_PAGE";
export const SCHEDULER_EDIT_IMAGE_LIST_PAGES_PAGE =
  "SCHEDULER_EDIT_IMAGE_LIST_PAGES_PAGE";
export const UPDATE_TYPE2_TEXT_PAGES_PAGE = "UPDATE_TYPE2_TEXT_PAGES_PAGE";
export const UPDATE_TYPE_B_SCHEDULER_TEXT_CHANGE_PAGES_PAGE =
  "UPDATE_TYPE_B_SCHEDULER_TEXT_CHANGE_PAGES_PAGE";

export const SET_PAGE_DATA = "SET_PAGE_DATA";
export const SET_PAGES_ROUTE = "SET_PAGES_ROUTE";
export const UPDATE_OPENED_CONTAINERS = "UPDATE_OPENED_CONTAINERS";
export const UPDATE_OPENED_FOLDERS = "UPDATE_OPENED_FOLDERS";
export const TOGGLE_RESET = "TOGGLE_RESET";
export const RESET_PAGES_CONTENT = "RESET_PAGES_CONTENT";
export const SELECT_NEW_PAGE_FOLDER = "SELECT_NEW_PAGE_FOLDER";
export const SET_NEW_PAGE_FOLDER = "SET_NEW_PAGE_FOLDER";

export const COPY_PARENT_CONTENT_TO_SCHEDULER_PAGES_PAGE =
  "COPY_PARENT_CONTENT_TO_SCHEDULER_PAGES_PAGE";
export const APPLY_GENERAL_SPACING_PAGES_PAGE =
  "APPLY_GENERAL_SPACING_PAGES_PAGE";
export const SET_PAGES_BY_COUNTRY = "SET_PAGES_BY_COUNTRY";

export const SET_PAGES_ROLLBACK_hISTORY = "SET_PAGES_ROLLBACK_hISTORY";
export const TOGGLE_ROLLBACK_SAVE = "TOGGLE_ROLLBACK_SAVE";
export const ADD_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE = "ADD_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE";
export const REMOVE_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE = "REMOVE_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE";
export const ADD_ROW_TYPE_COLUMN_MENU_PAGES_PAGE = "ADD_ROW_TYPE_COLUMN_MENU_PAGES_PAGE";
export const UPDATE_ROW_TYPE_COLUMN_MENU_PAGES_PAGE = "UPDATE_ROW_TYPE_COLUMN_MENU_PAGES_PAGE";
export const DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE = "DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_PAGES_PAGE";
