import React, { Component } from "react";
class SubListing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOnClick = (e, page, index, section) => {
    const { handlePageClick, changeTab } = this.props;
    handlePageClick(page, index, section);
    changeTab(e);
  };

  render() {
    const {
      pageContent,
      id,
      section,
    } = this.props;

    return (
      <div>
        <ul className="sub_listing">
          {pageContent.map((page, index) =>
            page.title ? (
              <li
                onClick={e => this.handleOnClick(e, page, index, section)}
                id={id}
                name={id + index + section}
                key={index}
              >
                {page.title}

              </li>
            ) : null
          )}
        </ul>
      </div>
    );
  }
}

export default SubListing;
