/* eslint-disable max-len */
export const UNSAVED_CHANGES_WARNING_MESSAGE =
  "There are some unsaved changes, Kindly save it otherwise all the unsaved changes will be lost. Do you want to continue ?";

export const SEE_CHANGES_MESSAGE = "Do you want to see the changes?";

export const DISCARD_CHANGES_CONFIRMATION_MESSAGE =
  "All the unsaved changes will be lost. Do you want to continue ?";

export const CANCEL_CHANGES_MESSAGE =
  "Are you sure you want to cancel the changes ?";

export const DELETE_ITEM_CONFIRMATION_MESSAGE =
  "Are you sure you want to delete this item? You can always undo your changes before saving";
