import React, { Fragment, useState } from "react";
import InputText from "../../component/InputText";

const PopupContent = ({ onConfirm, onCancel, message }) => (
  <Fragment>
    <div>{message || "Are you sure you want to delete the item?"}</div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={onConfirm}>
        <span className="btn-text">Yes</span>
      </button>
      <button className="popup-cancel" onClick={onCancel}>
        <span className="n-btn-text">No</span>
      </button>
    </div>
  </Fragment>
);

export const AddNewFlashPagePopupContent = ({
  onChangeHandler,
  data,
  onConfirm,
  onCancel,
  title,
}) => (
  <Fragment>
    <div>
      <p>{title}</p>
      <InputText
        placeholder="Page Name"
        name="name"
        value={data.name}
        onChange={onChangeHandler}
      />
      <InputText
        placeholder="Page Slug"
        name="slug"
        value={data.slug}
        onChange={onChangeHandler}
      />
    </div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={onConfirm}>
        <span className="btn-text">Yes</span>
      </button>
      <button className="popup-cancel" onClick={onCancel}>
        <span className="n-btn-text">No</span>
      </button>
    </div>
  </Fragment>
);

export const UpdateFlashPagePopupContent = ({
  data,
  onConfirm,
  onCancel,
  title,
}) => {

  const [slug, setSlug] = useState(data.slug || "");
  const [name, setName] = useState(data.name || "");
  const onChangeHandler = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    }
  }

  return (<Fragment>
    <div>
      <p>{title}</p>
      <InputText
        placeholder="Page Name"
        name="name"
        value={name}
        onChange={onChangeHandler}
      />
      <InputText
        placeholder="Page Slug"
        name="slug"
        value={slug}
        disabled={true}
      />
    </div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={() => onConfirm(data._id, data.index, name)}>
        <span className="btn-text">Yes</span>
      </button>
      <button className="popup-cancel" onClick={onCancel}>
        <span className="n-btn-text">No</span>
      </button>
    </div>
  </Fragment>)
};

export default PopupContent;
