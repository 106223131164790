import React, { Component } from "react";
import Select from "react-select";
// import "react-select/dist/react-select.css";

class SearchDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayResult: false,
      selectedValue: props.selectedData || ""
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedData } = this.props;
    if (selectedData !== nextProps.selectedData) {
      this.setState({ selectedValue: nextProps.selectedData || "" });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ displayResult: false });
    }
  };

  handleToggle = () => this.setState({ displayResult: true });

  handleList = data => {
    let _data = null;
    const isMultiData = Array.isArray(data);
    if (data && !isMultiData) {
      const { searchList } = this.props;
      _data = searchList.find(item => item.id === data.value);
    }
    this.props.changeSelectedData(
      data && isMultiData ? data : data && !isMultiData ? _data : null
    );
    this.setState({ selectedValue: data, displayResult: false });
  };

  render() {
    const { selectedValue } = this.state;
    const { searchList, title, isMulti = true } = this.props;
    const options =
      searchList &&
      searchList.length &&
      searchList.map(item => {
        return {
          label: isMulti
            ? `${item.name} > ${item.pcatname} > ${item.catname} > ${item.subcatname}`
            : `${item.brandname}`,
          value: item.id
        };
      });
    return (
      <div
        className="english-content search-wrapper"
        ref={node => (this.wrapperRef = node)}
      >
        <div className="form-group">
          {title && (
            <label>
              <span className="required" />
              {title}
            </label>
          )}
          <Select
            value={
              selectedValue &&
              selectedValue.length &&
              Array.isArray(selectedValue)
                ? selectedValue.filter(value => value)
                : selectedValue
            }
            options={options}
            isMulti={isMulti}
            onChange={this.handleList}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      </div>
    );
  }
}

export default SearchDropDown;
