import React, { Fragment } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { STATIC_PAGE_PAGETYPE, STATIC_PAGE_PANEL } from "../../constants";
import CKEditor from "ckeditor4-react";
import CKEditorNotes from "./../../component/ckEditorNotes";
import UpdateSEO from "./updateSEO";
import Accordion from "./accordion";
import isEqual from "lodash/isEqual";
import { Tooltip, IconButton } from "../../materialUi/components";
import { DeleteIcon } from "../../materialUi/icons";
import { setSubmitState } from "../../actions/common.action";
import {
  updateCkStaticContent,
  updateCkStaticAccordTile,
  updateCkStaticAccordContent,
  updateRemoveCkStaticAccordTile,
  updatePageType,
  resetUpdateStaticPage,
  deleteStaticPage
} from "../../actions/staticPage.action";
import {
  STATIC_PAGE_TYPE,
  CK_CONFIG_ENGLISH,
  CK_CONFIG_ARABIC,
  CK_EDITOR_URL,
  STANDARD_CK_CONFIG,
  MESSAGES
} from "../../constants";
import { addNewPage } from "./validation";
import { withRouter } from "react-router-dom";
import { toastMsg } from "../../actions/common.action";
import ConfirmModal from "../modal/ConfirmModal";
import { ENV_CONFIG } from "../../config/env";
import { _validateRedirectionUrl } from "../../util";
CKEditor.editorUrl = CK_EDITOR_URL.url;

class AddPageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      componentPosition: 1,
      active: false,
      seo: false,
      englishTextEditor: false,
      arabicTextEditor: false,
      invalidUrlMessage: false,
      removeUrlConfirmModal: false,
      accordion: false
    };
  }

  toggleContent = contentName => {
    // CONTENT TOGGLE
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));
  };

  UNSAFE_componentWillMount() {
    this.props.resetUpdateStaticPage();
  }

  componentDidUpdate(props) {
    const {
      updatePageContent,
      updatePageIndex,
      staticPages,
      updateStaticPageContent
    } = this.props;
    const isValidated = addNewPage(
      updateStaticPageContent[STATIC_PAGE_PAGETYPE[updatePageContent.pageType]][
      updatePageIndex
      ]
    );
    props.setSubmitState(
      isValidated &&
      !isEqual(staticPages.initialStaticPages, staticPages.staticPages)
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.updatePageIndex !== this.props.updatePageIndex ||
      (nextProps.updatePageContent && nextProps.updatePageContent.pageType) !==
      (this.props.updatePageContent && this.props.updatePageContent.pageType)
    ) {
      this.setState({
        seo: false,
        englishTextEditor: false,
        arabicTextEditor: false,
        accordion: false
      });
    }
  }

  handleCkTextChange = (evt, lang, pageType, pageIndex) => {
    setTimeout(() => {
      let value = evt.editor.getData();
      this.props.updateCkStaticContent(
        "html",
        value,
        lang,
        STATIC_PAGE_PAGETYPE[pageType],
        pageIndex
      );
    }, 100);
  };

  handleAccordTextChange = (key, value, lang, pos) => {
    const { updatePageContent, updatePageIndex } = this.props;
    this.props.updateCkStaticAccordContent(
      key,
      value,
      lang,
      pos,
      STATIC_PAGE_PAGETYPE[updatePageContent.pageType],
      updatePageIndex
    );
  };
  handleCkStaticAccordTile = pos => {
    const { updatePageContent, updatePageIndex } = this.props;
    this.props.updateCkStaticAccordTile(
      pos,
      STATIC_PAGE_PAGETYPE[updatePageContent.pageType],
      updatePageIndex
    );
  };
  handleRemoveCkStaticAccordTile = pos => {
    const { updatePageContent, updatePageIndex } = this.props;
    this.props.updateRemoveCkStaticAccordTile(
      pos,
      STATIC_PAGE_PAGETYPE[updatePageContent.pageType],
      updatePageIndex
    );
  };
  handleTextChange = e => {
    const { updatePageContent, updatePageIndex } = this.props;
    let url = "";
    if (e.target.name === "url") {
      if (_validateRedirectionUrl(e.target.value)) {
        url = e.target.value.toLowerCase();
      } else {
        url = `/${e.target.value}`;
      }
    }
    this.setState({ invalidUrlMessage: false });
    this.props.updatePageType(
      e.target.name,
      url || e.target.value,
      STATIC_PAGE_PAGETYPE[updatePageContent.pageType],
      updatePageIndex
    );
  };
  handleDelete = e => {
    e.persist();
    const { STATIC_DELETE_PAGE_DISPLAY } = STATIC_PAGE_PANEL;
    e.target.id = STATIC_DELETE_PAGE_DISPLAY;
    e.target.name = STATIC_DELETE_PAGE_DISPLAY;
    const {
      deleteStaticPage,
      changeTab,
      toastMsg,
      updateStaticPageContent,
      updatePageIndex,
      updatePageContent
    } = this.props;
    const allContent = updateStaticPageContent[
      STATIC_PAGE_PAGETYPE[updatePageContent.pageType]
    ]
      ? updateStaticPageContent[
      STATIC_PAGE_PAGETYPE[updatePageContent.pageType]
      ][updatePageIndex]
      : "";
    deleteStaticPage(allContent._id, allContent.countryId)
      .then(() => {
        changeTab(e);
        this.handleModal("removeUrlConfirmModal");
        toastMsg("success", "Static Page has been successfully deleted");
      })
      .catch(() => {
        toastMsg("error", "Unable to delete the static page.");
      });
  };

  handleModal = modalName => {
    this.setState(prevState => ({ [modalName]: !prevState[modalName] }));
  };

  render() {
    const { STATIC_DELETE_PAGE_DISPLAY } = STATIC_PAGE_PANEL;
    const {
      updatePageContent,
      updatePageIndex,
      updateStaticPageContent,
      storeId,
      countryId,
      accessRights
    } = this.props;
    const allContent =
      updateStaticPageContent[
        STATIC_PAGE_PAGETYPE[updatePageContent.pageType]
      ] &&
        updateStaticPageContent[STATIC_PAGE_PAGETYPE[updatePageContent.pageType]]
          .length > 0
        ? updateStaticPageContent[
        STATIC_PAGE_PAGETYPE[updatePageContent.pageType]
        ][updatePageIndex]
        : null;
    const {
      englishTextEditor,
      arabicTextEditor,
      invalidUrlMessage,
      removeUrlConfirmModal,
      seo,
      accordion
    } = this.state;
    return (
      <Fragment>
        {removeUrlConfirmModal ? (
          <ConfirmModal
            modalName={"removeUrlConfirmModal"}
            onHide={this.handleModal}
            confirm={this.handleDelete}
            message={"Are you sure you want to delete ?"}
          />
        ) : null}
        {/* STATIC_PAGE_TYPE */}
        <Tooltip title={accessRights.DELETE ? "Delete" : MESSAGES.TOOLTIP_DELETE} placement="top" arrow>
          <span>
            <IconButton
              aria-label="delete"
              onClick={() => {
                this.handleModal("removeUrlConfirmModal");
              }}
              disabled={!accessRights.DELETE}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <div>
          <div className="form-group page_information">
            <input
              name="title"
              className="form-control"
              type="text"
              placeholder="Enter Page Name"
              value={allContent ? allContent.title : ""}
              onChange={this.handleTextChange}
            />

            <div className="catalog-url">
              <input
                name="url"
                className="form-control"
                type="text"
                placeholder="Enter Page URL (must start with '/')"
                value={allContent ? allContent.url : ""}
                onChange={this.handleTextChange}
              />
              <span className="invalid-url-message">
                {invalidUrlMessage ? `Kindly enter valid URL` : null}
              </span>
            </div>

            <div className="dropdown top_custom_dropdown disable-pointer-events">
              <span className="dropbtn">
                {allContent
                  ? STATIC_PAGE_TYPE[allContent.pageType]
                  : "Select Static page type"}
              </span>
              {/*<div className="dropdown-content">{dropdown}</div>*/}
            </div>
          </div>
        </div>

        {/* SEO */}
        <UpdateSEO
          pageType={updatePageContent.pageType}
          pageIndex={updatePageIndex}
          isOpen={seo}
        />
        {/* ENGLISH TEXT_EDITOR */}
        <div className="cms-wrapper">
          <div className="wrapper-box">
            <div className="heading-wrapper flex catalog-banner-heading">
              <h3>
                <span className="required" />
                {`English Text Editor`}
              </h3>
              <span
                onClick={() => this.toggleContent("englishTextEditor")}
                className={`toggle-banner ${englishTextEditor ? "minus-banner" : "plus-banner"
                  }`}
              />
            </div>
            {englishTextEditor && allContent && (
              <Fragment>
                <CKEditor
                  activeClass="p10"
                  config={{
                    ...CK_CONFIG_ENGLISH,
                    ...STANDARD_CK_CONFIG,
                    ...{
                      filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                        }/homepage/content/uploadCK?id=${storeId}&key=${"customImage"}&language=${"english"}&storeId=${storeId}&countryId=${countryId}&containerName=${"staticPage"}`
                    }
                  }}
                  data={
                    allContent &&
                    allContent.content &&
                    allContent.content.english.html
                  }
                  onChange={e => {
                    this.handleCkTextChange(
                      e,
                      "english",
                      updatePageContent.pageType,
                      updatePageIndex
                    );
                  }}
                  onKey={e => {
                    this.handleCkTextChange(
                      e,
                      "english",
                      updatePageContent.pageType,
                      updatePageIndex
                    );
                  }}
                />
                <CKEditorNotes noMargin={true} display={[1, 2, 3, 4]} />
              </Fragment>
            )}
          </div>
        </div>
        {/* ARABIC TEXT_EDITOR */}
        <div className="cms-wrapper">
          <div className="wrapper-box">
            <div className="heading-wrapper flex catalog-banner-heading">
              <h3>
                <span className="required" />
                {`Arabic Text Editor`}
              </h3>
              <span
                onClick={() => this.toggleContent("arabicTextEditor")}
                className={`toggle-banner ${arabicTextEditor ? "minus-banner" : "plus-banner"
                  }`}
              />
            </div>
            {arabicTextEditor && allContent && (
              <Fragment>
                <CKEditor
                  name="html"
                  activeClass="p10"
                  config={{
                    ...CK_CONFIG_ARABIC,
                    ...STANDARD_CK_CONFIG,
                    ...{
                      filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                        }/homepage/content/uploadCK?id=${storeId}&key=${"customImage"}&language=${"arabic"}&storeId=${storeId}&countryId=${countryId}&containerName=${"staticPage"}`
                    }
                  }}
                  data={
                    allContent &&
                    allContent.content &&
                    allContent.content.arabic.html
                  }
                  onChange={e => {
                    this.handleCkTextChange(
                      e,
                      "arabic",
                      updatePageContent.pageType,
                      updatePageIndex
                    );
                  }}
                  onKey={e => {
                    this.handleCkTextChange(
                      e,
                      "arabic",
                      updatePageContent.pageType,
                      updatePageIndex
                    );
                  }}
                />
                <CKEditorNotes noMargin={true} display={[1, 2, 3, 4]} />
              </Fragment>
            )}
          </div>
        </div>
        {allContent &&
          allContent.pageType !== "html" &&
          allContent.content &&
          allContent.content.accord.length !== 0 && (
            <Accordion
              list={allContent.content.accord}
              handleAccordTextChange={this.handleAccordTextChange}
              addCkStaticAccordTile={this.handleCkStaticAccordTile}
              removeCkStaticAccordTile={this.handleRemoveCkStaticAccordTile}
              pageType={updatePageContent.pageType}
              pageIndex={updatePageIndex}
              storeId={storeId}
              countryId={countryId}
              isOpen={accordion}
            />
          )}
        <ReactTooltip place="top" effect="solid" id="static-page-content" />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  updateStaticPageContent: state.staticReducer.staticPages,
  staticPages: state.staticReducer,
  storeId: state.store.storeId,
  countryId: state.store.countryId
});

const mapDispatchToProps = {
  deleteStaticPage,
  resetUpdateStaticPage,
  setSubmitState,
  toastMsg,
  updateCkStaticAccordContent,
  updateCkStaticAccordTile,
  updateCkStaticContent,
  updatePageType,
  updateRemoveCkStaticAccordTile
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddPageContent)
);
