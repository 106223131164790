import React from "react";
import map from "lodash/map";
import {
  SCHEDULER_TYPE,
  LANGUAGE,
  SCHEDULER_PLACEHOLDERS
} from "../../constants";
import Scheduler from "../../component/scheduler";
import Accordion from "../../component/accordion";
import Button from "../../component/button";
import ImageCarouselForm from "./imageCarouselForm";

const SCHEDULER_CHILD_CONTENT = [
  {
    language: LANGUAGE.ENGLISH,
    placeholder: SCHEDULER_PLACEHOLDERS[LANGUAGE.ENGLISH].productCarousel,
    subTitlePlaceholder:
      SCHEDULER_PLACEHOLDERS[LANGUAGE.ENGLISH].productCarouselSubtitle,
    componentType: SCHEDULER_TYPE.CAROUSEL_TYPE_A
  },
  {
    language: LANGUAGE.ARABIC,
    placeholder: SCHEDULER_PLACEHOLDERS[LANGUAGE.ARABIC].productCarousel,
    subTitlePlaceholder:
      SCHEDULER_PLACEHOLDERS[LANGUAGE.ARABIC].productCarouselSubtitle,
    componentType: SCHEDULER_TYPE.CAROUSEL_TYPE_A
  }
];

const ImageCarouselAccordion = ({
  addNewImage,
  addNewScheduler,
  changeImageKeyIndex,
  changeSchedulerImageKeyIndex,
  children,
  content,
  deleteImageCarousel,
  deleteScheduler,
  handleOpen,
  handleSchedulerDateChange,
  handleSchedulerTextChange,
  handleSchedulerToggleChange,
  handleTextChange,
  handleToggle,
  imageCarouselId = "image_carousel",
  isEnabled,
  isOpen,
  schedulerAddImageList,
  showImageAccordion,
  showSchedulerImageAccordion,
  keyIndex
}) => (
  <Accordion
    accordionId={imageCarouselId}
    title="Image Carousel"
    headingWrapperClassName="catalog-banner-heading"
    handleDelete={deleteImageCarousel}
    handleToggle={handleToggle}
    handleOpen={handleOpen}
    isToggled={isEnabled}
    isOpen={isOpen}
    isControlled={Boolean(handleOpen)}
    required
    content={content}
    keyIndex={keyIndex}
    withDragNDrop
  >
    <ImageCarouselForm
      content={content}
      language={LANGUAGE.ENGLISH}
      handleTextChange={handleTextChange}
    />
    <ImageCarouselForm
      content={content}
      language={LANGUAGE.ARABIC}
      handleTextChange={handleTextChange}
    />
    {map(content.imageList, (image, index) =>
      /* Adding additional props to ImageListAccordion component that is inserted via children */
      React.cloneElement(children, {
        showContent: showImageAccordion,
        keyIndex: index,
        changeKeyIndex: changeImageKeyIndex,
        imageList: image,
        key: image._id || image.id || index
      })
    )}
    <div className="d-flex justify-content-end">
      <Button
        customClass="mr-0 mt-2"
        onClick={addNewImage}
        name="Add New Image"
      />
    </div>
    <Scheduler
      content={content}
      handleImageListChange={schedulerAddImageList}
      childContent={SCHEDULER_CHILD_CONTENT}
      handleDateChange={handleSchedulerDateChange}
      schedulerTextChange={handleSchedulerTextChange}
      handleToggleChange={handleSchedulerToggleChange}
      addNewScheduler={addNewScheduler}
      deleteScheduler={deleteScheduler}
    >
      {/* Adding additional props to ImageListAccordion component that is inserted via children */}
      {React.cloneElement(children, {
        changeKeyIndex: changeSchedulerImageKeyIndex,
        showContent: showSchedulerImageAccordion
      })}
    </Scheduler>
  </Accordion>
);

export default ImageCarouselAccordion;
