import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { toastMsg, uploadImage } from "../../actions/common.action";
import { IMG_SIZE_IN_BYTES } from "../../constants";

const customStyles = {
  content: {
    width: "390px",
    height: "364px",
    position: "absolute",
    top: "20%",
    left: "35%",
    right: "40px",
    bottom: "40px",
    borderRadius: "none",
    background: "rgb(255, 255, 255)",
    overflow: "auto"
  },

  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

const formHeight = {
  height: "316px"
};

class ImageSelectionModal extends React.Component {
  state = {
    uploadedFile: "",
    cdnURL: "",
    validCdnUrl: false,
    regex: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/
  };
  /**
   * Inputs(file) change handler
   */
  changeFileHandler = e => {
    const { toastMsg } = this.props;
    if (e.target.files[0].size / 1024 ** 2 > 10) {
      toastMsg("warning", "Maximum file size allowed 10Mb");
    } else if (e.target.files[0].type.split("/")[0] !== "image") {
      toastMsg("warning", "File must be of image type");
    } else if (e.target.files[0].type.split("/")[1].indexOf("tif") !== -1) {
      toastMsg("warning", ".tiff/.tif file extensions are not allowed.");
    } else {
      this.setState({
        uploadedFile: e.target.files
      });
    }
  };

  clearSelectedImage = () => {
    this.setState({
      uploadedFile: ""
    });
  };

  handelChange = e => {
    const { regex } = this.state;
    this.setState({
      cdnURL: e.target.value,
      validCdnUrl: e.target.value.match(regex)
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const { uploadedFile, cdnURL, validCdnUrl } = this.state;

    const {
      onSuccess,
      keyName,
      brandId,
      language,
      modalName,
      uploadImage,
      storeId,
      toastMsg,
      countryId,
      containerName
    } = this.props;
    if (!cdnURL && !(uploadedFile && uploadedFile[0].name)) {
      toastMsg("warning", "Kindly upload a file or add a image link.");
      return;
    } else if (cdnURL && !validCdnUrl) {
      toastMsg("warning", "Kindly enter a valid image link.");
    } else if (
      uploadedFile &&
      (uploadedFile[0].type === "image/jpg" ||
        uploadedFile[0].type === "image/jpeg" ||
        uploadedFile[0].type === "image/png" ||
        uploadedFile[0].type === "image/gif" ||
        uploadedFile[0].type === "image/webp") &&
      uploadedFile[0].size >= IMG_SIZE_IN_BYTES.PNG_SIZE
    ) {
      toastMsg("error", "Kindly upload an image size below 1MB.");
    } else if (
      uploadedFile &&
      uploadedFile[0].type === "image/gif" &&
      uploadedFile[0].size >= IMG_SIZE_IN_BYTES.GIF_SIZE
    ) {
      toastMsg("error", "Kindly upload an image size below 1MB.");
    }

    if (cdnURL) {
      onSuccess(
        keyName,
        `${cdnURL}?burst=${Date.now()}`,
        brandId,
        language,
        modalName
      );
    } else if (
      uploadedFile &&
      (uploadedFile[0].type === "image/jpg" ||
        uploadedFile[0].type === "image/jpeg" ||
        uploadedFile[0].type === "image/png" ||
        uploadedFile[0].type === "image/gif" ||
        uploadedFile[0].type === "image/webp") &&
      uploadedFile[0].size <= IMG_SIZE_IN_BYTES.PNG_SIZE
    ) {
      let _formData = new FormData();
      _formData.append("file", uploadedFile[0]);

      let _brandId = brandId;

      if (containerName != "storeList") {
        _brandId = `${brandId}${+new Date()}`;
      }

      uploadImage({
        brandId: _brandId,
        keyName,
        language,
        storeId,
        countryId,
        containerName,
        _formData
      }).then(res => {
        if (
          res.data &&
          res.data.code &&
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.result &&
          res.data.data.result.url
        ) {
          onSuccess(
            keyName,
            `${res.data.data.result.url}?burst=${Date.now()}`,
            brandId,
            language,
            modalName
          );
        } else {
          toastMsg("error", "Unable to upload image.");
          return;
        }
      });
    }
  };

  render() {
    let { uploadedFile, cdnURL, validCdnUrl } = this.state;
    const { modalName, onHide, title } = this.props;
    return (
      <div>
        <Modal
          isOpen={true}
          ariaHideApp={false}
          onRequestClose={() => onHide(modalName, null, null)}
          style={customStyles}
          closeTimeoutMS={2000}
        >
          <form
            style={formHeight}
            onSubmit={e => {
              this.onSubmit(e);
            }}
          >
            <div className="close-btn">
              <span
                onClick={() => onHide(modalName, null, null)}
                className="close-icon"
              />
            </div>
            <span className="modal-heading">{title || "Upload Image"} </span>

            {uploadedFile ? (
              <div className="file-upload-container">
                <input
                  type="text"
                  className="file-upload"
                  value={uploadedFile[0].name}
                  readOnly
                />
                <span
                  onClick={() => this.clearSelectedImage()}
                  className="file-close-icon"
                />
              </div>
            ) : (
              <div
                className={`input-group input-grp-height marginTop-20 ${
                  !cdnURL ? "" : "input-file-disable"
                }`}
              >
                <div className="input-group-prepend custom-input"></div>
                <div className="custom-file custom-file-selection">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="uploadFile"
                    aria-describedby="inputGroupFileAddon01"
                    onChange={e => {
                      this.changeFileHandler(e);
                    }}
                  />
                  <label
                    htmlFor="inputGroupFile01"
                    className={`custom-file-label file-label ${
                      !cdnURL ? "" : "input-file-disable input-disable"
                    }`}
                  >
                    Choose file
                  </label>
                </div>
              </div>
            )}

            <div className="marginTop-20 m-b-20 text-align">
              <span className="or-content"> OR </span>
            </div>

            <input
              id="imageCdn"
              type="text"
              name="imageCdn"
              className="form-control m-b-20 marginTop-20 no-border-radius "
              placeholder="Image Link"
              disabled={!!uploadedFile}
              onChange={this.handelChange}
            />
            <button
              className={`confirm-btn marginTop-20 ${
                !(cdnURL || uploadedFile) || (cdnURL && !validCdnUrl)
                  ? "btn-disabled"
                  : ""
              }`}
              disabled={!(cdnURL || uploadedFile) || (cdnURL && !validCdnUrl)}
              onClick={e => {
                this.onSubmit(e);
              }}
            >
              <span className="btn-text">Submit</span>
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brandId: state.store.brandId || state.store.storeId,
  countryId: state.store.countryId,
  storeId: state.store.storeId
});

const mapDispatchToProps = {
  toastMsg,
  uploadImage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageSelectionModal);
