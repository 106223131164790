import {
  fetchWebApi,
  putDashboardWebApi,
  deleteWebApi
} from "../webapis/apiResource";
import { ENV_CONFIG } from "../config/env";
import { getAccessToken } from "../util/storeHelper";
import { generateUUID } from "../util";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  toastMsg
} from "../actions/common.action";
import { handleFetchError } from "../util/errorHandler";
import {
  EDIT_MEGA_MENU_CONTENT,
  CLEAR_MEGA_MENU_CONTENT,
  MEGA_MENU_TEXT,
  HANDLE_ATTRIB_CHANGE,
  SAVE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  CLEAR_ATTRIBUTE_STATE,
  UPDATE_TREE_RECORDS,
  HANDLE_EDIT_ATTRIBUTE,
  CHANGE_IMAGE_COL_TYPE,
  HANDLE_IMAGE_TYPE,
  UPDATE_IMAGE_DATA,
  GET_MEGA_MENU_DATA,
  REMOVE_IMAGE,
  CLEAR_ALL_IMAGES,
  HANDLE_SPS_CHANGE,
  HANDLE_MEGA_MENU_STATIC_TEXT,
  CLEAR_MEGA_MENU_STATIC_CONTENT,
  HANDLE_CURRENT_TAB_CHANGE,
  ADD_DATA_TO_CAROUSAL_LIST,
  DELETE_CAROUSAL_ITEM,
  MEGA_MENU_CAROUSAL_CHANGE,
  ADD_MEGA_MENU_SCHEDULERS,
  DELETE_MEGA_MENU_SCHEDULERS,
  HANDLE_SCHEDULER_IMAGE_TYPE,
  CLEAR_ALL_SCHEDULERS_IMAGES,
  HANDLE_SCHEDULER_IMAGE_DATA,
  UPDATE_SCHEDULER_IMAGE_DATA,
  REMOVE_SCHEDULER_IMAGE
} from "../constants/actions";
import { resetCountryId } from "./selectedCountyId.action";
import { resetStoreId } from "./store.action";

export const fetchMegaMenuData = (storeId, countryId) => {
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/mega-menu/?storeId=${storeId}&countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), urlType)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getMegaMenuData(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateMegaMenuDetails = (
  countryId,
  storeId,
  englishData,
  arabicData,
  tempsave,
  selectedCountryId,
  selectedStoreId
) => {
  const uid = generateUUID();
  if (!Object.prototype.hasOwnProperty.call(englishData, "uid")) {
    englishData.uid = uid;
  }
  if (!Object.prototype.hasOwnProperty.call(arabicData, "uid")) {
    arabicData.uid = uid;
  }
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/mega-menu/`;
  const bodyData = {
    storeId,
    countryId,
    english: englishData,
    arabic: arabicData,
    selectedCountryId: selectedCountryId || [countryId],
    selectedStoreId: selectedStoreId || [storeId]
  };
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(
      getAccessToken(getState),
      urlType,
      bodyData,
      tempsave
    )
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getMegaMenuData(response.data.data));
          dispatch(toastMsg("success", "Mega Menu updated successfully."));
        }
        dispatch(hideLoadingIndicator());
        // dispatch(disableSubmit());
        if (!tempsave) {
          dispatch(resetCountryId());
          dispatch(resetStoreId());
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(
          toastMsg("error", "Something went wrong while updating mega menu.")
        );
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const deleteMegaMenuDetails = (countryId, storeId, engUid, arbUid) => {
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/mega-menu/?storeId=${storeId}&countryId=${countryId}&engUid=${engUid}&arbUid=${arbUid}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), urlType)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getMegaMenuData(response.data.data));
          dispatch(clearMegaMenuContent());
          dispatch(
            toastMsg("success", "Mega Menu record deleted successfully.")
          );
        }
        dispatch(hideLoadingIndicator());
        // dispatch(disableSubmit());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(
          toastMsg(
            "error",
            "Something went wrong while deleting mega menu record."
          )
        );

        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateMegaMenuStaticDetails = (
  countryId,
  storeId,
  englishData,
  arabicData,
  tempsave,
  selectedCountryId
) => {
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/mega-menu/static-content`;
  const bodyData = {
    countryId,
    storeId,
    english: englishData,
    arabic: arabicData,
    selectedCountryId: selectedCountryId || [countryId]
  };
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(
      getAccessToken(getState),
      urlType,
      bodyData,
      tempsave
    )
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getMegaMenuData(response.data.data));
          dispatch(
            toastMsg(
              "success",
              "Mega menu static records has been successfully updated."
            )
          );
        }
        dispatch(hideLoadingIndicator());
        if (!tempsave) {
          dispatch(resetCountryId());
          dispatch(resetStoreId());
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        dispatch(
          toastMsg("error", "something went wrong while updating the records.")
        );
        return error.response;
      });
  };
};

export const handleMegaMenuTextChange = (name, value, keyType) => ({
  type: HANDLE_MEGA_MENU_STATIC_TEXT,
  name,
  value,
  keyType
});

export const clearMegaMenuStaticContent = () => ({
  type: CLEAR_MEGA_MENU_STATIC_CONTENT
});

export const handleCurrentTabChange = value => ({
  type: HANDLE_CURRENT_TAB_CHANGE,
  value
});

const getMegaMenuData = ({ result }) => ({ type: GET_MEGA_MENU_DATA, result });

export const editMegaMenuContent = (
  updateEnglishContent,
  updateArabicContent
) => ({
  type: EDIT_MEGA_MENU_CONTENT,
  updateEnglishContent,
  updateArabicContent
});

export const clearMegaMenuContent = () => ({ type: CLEAR_MEGA_MENU_CONTENT });

export const megaMenuText = (name, value, obj) => ({
  type: MEGA_MENU_TEXT,
  name,
  value,
  obj
});

export const megaMenuCarousalChange = (name, value, keyIndex, obj) => ({
  type: MEGA_MENU_CAROUSAL_CHANGE,
  name,
  value,
  keyIndex,
  obj
});

export const handleAttributesChange = (contentType, name, value) => ({
  type: HANDLE_ATTRIB_CHANGE,
  contentType,
  name,
  value
});

export const saveAttribute = colName => ({ type: SAVE_ATTRIBUTE, colName });

export const updateAttribute = colName => ({
  type: UPDATE_ATTRIBUTE,
  colName
});

export const clearAttributeState = () => ({ type: CLEAR_ATTRIBUTE_STATE });

export const updateRecords = colName => ({
  type: UPDATE_TREE_RECORDS,
  colName
});

export const changeImgColType = (value, columnName) => ({
  type: CHANGE_IMAGE_COL_TYPE,
  value,
  columnName
});

export const updateImageData = (columnName, imgData, imgLang) => ({
  type: UPDATE_IMAGE_DATA,
  columnName,
  imgData,
  imgLang
});

export const updateSchedulerImageData = ({
  columnName,
  imgData,
  imgLang,
  schedulerIndex
}) => ({
  type: UPDATE_SCHEDULER_IMAGE_DATA,
  columnName,
  imgData,
  imgLang,
  schedulerIndex
});

export const removeImage = (columnName, pos, imgLang) => ({
  type: REMOVE_IMAGE,
  columnName,
  pos,
  imgLang
});

export const clearAllImages = columnName => ({
  type: CLEAR_ALL_IMAGES,
  columnName
});

export const clearAllSchedulerImages = ({ columnName, schedulerIndex }) => ({
  type: CLEAR_ALL_SCHEDULERS_IMAGES,
  columnName,
  schedulerIndex
});

export const handleImageType = (imgType, columnName, imgClassList) => ({
  type: HANDLE_IMAGE_TYPE,
  imgType,
  columnName,
  imgClassList
});

export const handleSpsChange = value => ({ type: HANDLE_SPS_CHANGE, value });

export const handleEditAttribute = ({ englishData, arabicData }) => ({
  type: HANDLE_EDIT_ATTRIBUTE,
  englishData,
  arabicData
});

export const addDataToCarousalList = () => ({
  type: ADD_DATA_TO_CAROUSAL_LIST
});

export const deleteCarousalItem = keyIndex => ({
  type: DELETE_CAROUSAL_ITEM,
  keyIndex
});

export const addNewMegaMenuScheduler = ({ columnName }) => ({
  type: ADD_MEGA_MENU_SCHEDULERS,
  columnName
});

export const deleteMegaMenuScheduler = ({ columnName, schedulerIndex }) => ({
  type: DELETE_MEGA_MENU_SCHEDULERS,
  schedulerIndex,
  columnName
});

export const handleSchedulerImageType = ({
  imgType,
  columnName,
  imgClassList,
  schedulerIndex
}) => ({
  type: HANDLE_SCHEDULER_IMAGE_TYPE,
  columnName,
  imgClassList,
  imgType,
  schedulerIndex
});

export const handleSchedulerImageData = ({
  columnName,
  schedulerIndex,
  dataKey,
  data
}) => ({
  type: HANDLE_SCHEDULER_IMAGE_DATA,
  columnName,
  schedulerIndex,
  dataKey,
  data
});

export const removeSchedulerImage = ({
  columnName,
  pos,
  imgLang,
  schedulerIndex
}) => ({
  type: REMOVE_SCHEDULER_IMAGE,
  columnName,
  pos,
  imgLang,
  schedulerIndex
});
