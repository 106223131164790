export const HOMEPAGE_SCHEDULER_DEFAULT_DATA = {
  from: null,
  to: null,
  enabled: false,
  template: {
    english: "",
    arabic: ""
  }
};

export const SCHDEDULER_VALIDATION_ERR_MSG = {
  comparisonError: "The 'To date' must be greater than the 'From date'.",
  coincideError: "Date and Time coincide with other scheduler's date and time."
};

export const TYPE_OF_DATE = {
  to: "to",
  from: "from"
};
