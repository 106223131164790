import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { LANGUAGE } from "../../constants";
import Accordion from "../../component/accordion";
import ImageSelectionModal from "../modal/imageSelectionModal";
import ImageListForm from "./imageListForm";

const ImageListAccordion = ({
  countryId,
  handleDelete,
  handleImageToggleChange,
  handleRedirectionLinkChange,
  handleTextChange,
  handleUpdateURL,
  image,
  keyIndex,
  storeId
}) => {
  const [imageSelectionModal, setImageSelectionModal] = useState(false);
  const [language, setLanguage] = useState(null);

  const handleModal = language => {
    setImageSelectionModal(prevState => !prevState);
    setLanguage(language);
  };

  const handleImageUploadSuccess = (...args) => {
    handleModal();
    handleUpdateURL(...args);
  };

  return (
    <Fragment>
      {imageSelectionModal && (
        <ImageSelectionModal
          title="Upload Image"
          modalName={"imageSelectionModal"}
          keyName={"imageLink"}
          brandId={storeId}
          storeId={storeId}
          countryId={countryId}
          language={language}
          containerName={"imageList"}
          onHide={handleModal}
          onSuccess={handleImageUploadSuccess}
        />
      )}
      <Accordion
        className="catalog-image-list"
        title={`Image ${keyIndex + 1}`}
        isToggled={image.enable}
        handleToggle={() => handleImageToggleChange(image.enable)}
        handleDelete={handleDelete}
      >
        <ImageListForm
          handleModal={handleModal}
          handleRedirectionLinkChange={handleRedirectionLinkChange}
          handleTextChange={handleTextChange}
          handleUpdateURL={handleUpdateURL}
          image={image}
          language={LANGUAGE.ENGLISH}
        />
        <ImageListForm
          handleModal={handleModal}
          handleRedirectionLinkChange={handleRedirectionLinkChange}
          handleTextChange={handleTextChange}
          handleUpdateURL={handleUpdateURL}
          image={image}
          language={LANGUAGE.ARABIC}
        />
      </Accordion>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  countryId: state.store.countryId,
  storeId: state.store.brandId || state.store.storeId
});

export default connect(mapStateToProps)(ImageListAccordion);
