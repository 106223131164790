export const FLASH_SALE_PAGE_PANEL = {
  EDIT_PAGE_DISPLAY: "flashSaleEditPageDisplay",
  ADD_PAGE_DISPLAY: "flashSaleAddPageDisplay",
};

export const FLASH_SALE_PAGE_MESSAGES = {
  name_required: "Name is required",
  name_spcl_chr: "Special Character not allowed in Name",
  name_max_len: "Max 20 characters allowed in Name",
  slug_max_len: "Max 20 characters allowed in Slug",
  slug_spcl_chr: "Special Character not allowed in Slug",
  url_required: "Url is required",
  url_start_err: "Url should start with /",
  url_contain_double_slash: "Url should not contain //",
  url_no_space_err: "Url should not contain any space",
  url_end_err: "Url should end with /",
  from_date_miss: "From date is missing for",
  to_date_miss: "To date is missing for",
  to_dt_gt_frm_err: "To date should be greater than From Date for",
  slug_required: "Slug is required",
  slug_no_space_err: "Slug should not contain any space",
  no_data_found_csv: "No Data found in CSV",
  err_parse_csv: "Error While Parsing CSV Data",
  no_rel_data_found_csv: "No relevant data found in CSV",
  invalid_dis_price: "Discounted Price cannot be greater than Price",
  invalid_min_discount_price: "Discounted Price cannot be negative",
  from_to_overlap: "From and To date is overlapping with other sales",
  confirm_popup: "All the unsaved changes of current sale will be lost. Do you want to continue ?",
  ar_title_miss: "Arabic Title missing",
  en_title_miss: "English Title missing",
  en_title_spcl_char: "Special Characters not allowed in English Title",
  ar_title_spcl_char: "Special Characters not allowed in Arabic Title",
  extraSpace: "Please remove Leading and Trailing spaces from",

}

export const FLASH_SALE_PLACEHOLDERS = {
  page_title_en: "Enter Tab Title",
  page_subtitle_en: "Enter Tab Subtitle",
  page_title_ar: "أدخل عنوان علامة التبويب",
  page_subtitle_ar: "أدخل عنوان فرعي لعلامة التبويب",
  page_pids_sel: "Type pid and press enter..."
}

export const FLASH_PAGE = "FLASH_PAGE";

export const BATCH_SIZE = 50;
