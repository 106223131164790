import compareAsc from "date-fns/compareAsc";
import { SCHDEDULER_VALIDATION_ERR_MSG, TYPE_OF_DATE } from "../constants";

export const validateSchedulerDateAndTime = (
  schedulers,
  currentSchedulerIndex,
  typeOfDate,
  currentValue
) => {
  let errorMsg = "";
  let _index = 0;
  schedulers.forEach((item, index) => {
    if (currentSchedulerIndex !== index) {
      if (
        currentValue > (schedulers[index] && schedulers[index].from) &&
        currentValue < (schedulers[index] && schedulers[index].to)
      ) {
        errorMsg = SCHDEDULER_VALIDATION_ERR_MSG.coincideError;
      }
    } else {
      _index = index;
      if (typeOfDate === TYPE_OF_DATE.to) {
        if (currentValue <= (schedulers[index] && schedulers[index].from)) {
          errorMsg = SCHDEDULER_VALIDATION_ERR_MSG.comparisonError;
        }
      } else {
        if (
          schedulers[index] &&
          schedulers[index].to &&
          currentValue >= schedulers[index].to
        ) {
          errorMsg = SCHDEDULER_VALIDATION_ERR_MSG.comparisonError;
        }
      }
    }
  });

  schedulers.forEach((item, index) => {
    if (currentSchedulerIndex !== index && !errorMsg) {
      if (typeOfDate === TYPE_OF_DATE.to) {
        if (
          (schedulers[_index] && schedulers[_index].from) <=
            (schedulers[index] && schedulers[index].from) &&
          currentValue >= (schedulers[index] && schedulers[index].to)
        ) {
          errorMsg = SCHDEDULER_VALIDATION_ERR_MSG.coincideError;
        }
      } else {
        if (
          (schedulers[_index] && schedulers[_index].to) >=
            (schedulers[index] && schedulers[index].to) &&
          currentValue <= (schedulers[index] && schedulers[index].from)
        ) {
          errorMsg = SCHDEDULER_VALIDATION_ERR_MSG.coincideError;
        }
      }
    }
  });

  return errorMsg;
};

export const getSchedulerMainData = ({ from, to, enabled }) => ({
  from,
  to,
  enabled
});

export const getSchedulerId = index => `scheduler-${index + 1}`;

export const getMappedSchedulers = schedulers =>
  schedulers.reduce((acc, scheduler, index) => {
    const schedulerId = getSchedulerId(index);
    acc[schedulerId] = {
      id: schedulerId,
      name: `Scheduler ${index + 1}`,
      from: scheduler.from,
      to: scheduler.to,
      scheduledPage: scheduler._id,
      enabled: scheduler.enabled,
      saved: !!scheduler._id
    };
    return acc;
  }, {});

export const validateTimeOrdering = (timeStamp, type, schedulerSettings) => {
  const settings = {
    ...schedulerSettings,
    [type]: timeStamp
  };
  const { from, to } = settings;
  const isValid = !from || !to || compareAsc(from, to) <= 0;
  return isValid;
};

export const getNewSchedulerSetting = schedulerSettings => {
  const newSchedulerIndex =
    Math.max(
      ...Object.keys(schedulerSettings).map(key =>
        parseInt(key.replace(/scheduler-/gi, ""))
      ),
      0
    ) + 1;
  const schedulerId = `scheduler-${newSchedulerIndex}`;

  return {
    [schedulerId]: {
      id: schedulerId,
      name: `Scheduler ${newSchedulerIndex}`,
      from: null,
      to: null,
      scheduledPage: null,
      enabled: false,
      saved: false
    }
  };
};

export const getSchedulerTimeString = (scheduler, timePoint) => {
  const headerDateOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  };
  const ms = scheduler[timePoint];
  if (!ms) return "INVALID DATE";
  const dateObject = new Date(ms);

  const humanDateFormat = dateObject.toLocaleString("en-US", headerDateOptions);
  //const [monthDay, year, time] = humanDateFormat.split(',');
  //return `${monthDay} ${year}, ${time}`;
  return `${humanDateFormat}`;
};
