import React, { Component, Fragment } from "react";
import CountryDropDown from "../../containers/countryDropdown";
import { SIZE_CHART_PANEL } from "../../constants";

class LeftPanel extends Component {
  render() {
    const {
      currentTab,
      changeTab,
      changeNav,
      selectedCategory,
      showNav,
      isSizeChart = false
    } = this.props;
    return (
      <div className="side-bar">
        <div
          className={
            isSizeChart
              ? "apply-for-dropdown size-chart-label"
              : "apply-for-dropdown"
          }
        >
          <Fragment>
            {isSizeChart ? <p>Size Chart</p> : <p>Select Country</p>}
            <CountryDropDown isSizeChart={isSizeChart} changeTab={changeTab} />
          </Fragment>
        </div>
        <div className="nav-child">
          <Fragment>
            <ul className="border-radius-0">
              <li
                id={SIZE_CHART_PANEL.SEO}
                className={`${currentTab === SIZE_CHART_PANEL.SEO && "active"}`}
                onClick={e => changeTab(e.target.id)}
              >
                SEO
              </li>
              <li
                id={SIZE_CHART_PANEL.GENERAL}
                className={`${
                  currentTab === SIZE_CHART_PANEL.GENERAL && "active"
                }`}
                onClick={e => changeTab(e.target.id)}
              >
                General
              </li>
            </ul>
            <span
              id={SIZE_CHART_PANEL.BRAND_SIZE_CHART}
              className={`open-nav
                  ${
                    selectedCategory === SIZE_CHART_PANEL.BRAND_SIZE_CHART &&
                    showNav
                      ? "minus-icon-nav"
                      : "plus-icon-nav"
                  }`}
              onClick={changeNav}
            >
              Brand Size Chart
            </span>
            {selectedCategory === SIZE_CHART_PANEL.BRAND_SIZE_CHART && showNav && (
              <ul className="border-radius-0">
                <li
                  id={SIZE_CHART_PANEL.MEN}
                  className={`${
                    currentTab === SIZE_CHART_PANEL.MEN && "active"
                  }`}
                  onClick={e =>
                    changeTab(e.target.id, SIZE_CHART_PANEL.BRAND_SIZE_CHART)
                  }
                >
                  Men
                </li>
                <li
                  id={SIZE_CHART_PANEL.WOMEN}
                  className={`${
                    currentTab === SIZE_CHART_PANEL.WOMEN && "active"
                  }`}
                  onClick={e =>
                    changeTab(e.target.id, SIZE_CHART_PANEL.BRAND_SIZE_CHART)
                  }
                >
                  Women
                </li>
                <li
                  id={SIZE_CHART_PANEL.KIDS}
                  className={`${
                    currentTab === SIZE_CHART_PANEL.KIDS && "active"
                  }`}
                  onClick={e =>
                    changeTab(e.target.id, SIZE_CHART_PANEL.BRAND_SIZE_CHART)
                  }
                >
                  Kids
                </li>
              </ul>
            )}
            <span
              id={SIZE_CHART_PANEL.GENERIC_SIZE_CHART}
              className={`open-nav
                  ${
                    selectedCategory === SIZE_CHART_PANEL.GENERIC_SIZE_CHART &&
                    showNav
                      ? "minus-icon-nav"
                      : "plus-icon-nav"
                  }`}
              onClick={changeNav}
            >
              Generic Size Chart
            </span>
            {selectedCategory === SIZE_CHART_PANEL.GENERIC_SIZE_CHART &&
              showNav && (
                <ul>
                  <li
                    id={SIZE_CHART_PANEL.MEN}
                    className={`${
                      currentTab === SIZE_CHART_PANEL.MEN && "active"
                    }`}
                    onClick={e =>
                      changeTab(
                        e.target.id,
                        SIZE_CHART_PANEL.GENERIC_SIZE_CHART
                      )
                    }
                  >
                    Men
                  </li>
                  <li
                    id={SIZE_CHART_PANEL.WOMEN}
                    className={`${
                      currentTab === SIZE_CHART_PANEL.WOMEN && "active"
                    }`}
                    onClick={e =>
                      changeTab(
                        e.target.id,
                        SIZE_CHART_PANEL.GENERIC_SIZE_CHART
                      )
                    }
                  >
                    Women
                  </li>
                  <li
                    id={SIZE_CHART_PANEL.KIDS}
                    className={`${
                      currentTab === SIZE_CHART_PANEL.KIDS && "active"
                    }`}
                    onClick={e =>
                      changeTab(
                        e.target.id,
                        SIZE_CHART_PANEL.GENERIC_SIZE_CHART
                      )
                    }
                  >
                    Kids
                  </li>
                </ul>
              )}
          </Fragment>
        </div>
      </div>
    );
  }
}

export default LeftPanel;
