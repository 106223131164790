import React, { Fragment } from "react";
import CustomSortableTree from "../customSortableTree";
import Button from "../../component/button";

const ColumnMenu = ({
  treeData = [],
  handleModal,
  updateTreeData,
  openEditModal,
  openConfirmModal
}) => {
  return (
    <Fragment>
      <Button
        name="Add Footer Item"
        customClass="marginBottom"
        onClick={() => handleModal(true)}
      />
      <CustomSortableTree
        treeData={treeData}
        columnName={""}
        updateTreeDataValue={updateTreeData}
        openEditModal={openEditModal}
        openConfirmModal={openConfirmModal}
      />
    </Fragment>
  );
};

export default ColumnMenu;
