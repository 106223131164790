import React from "react";
import get from "lodash/get";

import { paddingInputs } from "../../constants/stylingModal";
import { Box, TextField, Typography, Button } from "../../materialUi/components"

const GeneralSpacing = ({
  styles,
  updateRowData,
  applyGeneralSpacing,
  CommonStyle,
}) => {
  return (
    <Box sx={{ ...CommonStyle }}>
      <Typography sx={{ width: "100%", "marginBottom": "10px" }}>General Padding</Typography>
      <Box sx={{ width: "100%", display: "flex" }}>
        {paddingInputs.map(({ name, label }, idx) => (
          <TextField
            type="Number"
            key={idx}
            label={label}
            name={name}
            placeholder={label}
            value={styles[name]}
            onChange={event => {
              let value = +get(event, "target.value", 0);
              if (!Number.isInteger(value)) {
                return;
              }
              if (value > 30) value = 30;
              else if (value < 0) value = 0;

              updateRowData("generalPadding", {
                ...styles,
                [name]: value
              });
            }}
            size="small"
            sx={{ width: `${Math.floor(100 / paddingInputs.length)}%`, "marginRight": "5px" }}
            InputLabelProps={{ shrink: true }}
          />
        ))}
        <Button variant="contained" size="small" onClick={applyGeneralSpacing}>
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default GeneralSpacing;
