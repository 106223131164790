import React, { Fragment, useState } from "react";
import ReactTooltip from "react-tooltip";
import ArrowDown from "../../images/arrow-dd.svg";
import DeleteIcon from "../../images/delete-grey.svg";
import AddIcon from "../../images/icon-plus-black.svg";
import UpdateIcon from "../../images/edit-icon.svg";
import { MESSAGES, NAVIGATION_MENUS_SLUGS } from "../../constants";

import cn from "classnames";

const NavMenuChild = ({
  menuId,
  navMenus,
  handleSubMenuModal,
  accessRights
}) => {
  const [toggledItems, setToggledItems] = useState({});

  const handleChildDataView = (index, data) => {
    setToggledItems({ ...toggledItems, [data._id]: !toggledItems[data._id] });
  };

  const addNewSubMenuEntry = (index, navItem, activeRowDetails) => {
    setToggledItems({ ...toggledItems, [navItem._id]: true });
    handleSubMenuModal(navItem, "addNewSubMenuModal", activeRowDetails);
  };

  const updateSubMenuEntry = (index, navItem, activeRowDetails) => {
    handleSubMenuModal(navItem, "updateMenuModal", activeRowDetails);
  };

  const deleteMenuEntry = (index, navItem, activeRowDetails) => {
    setToggledItems({ ...toggledItems, [navItem._id]: false });
    handleSubMenuModal(navItem, "deleteMenuModal", activeRowDetails);
  };

  return (
    <div className="row">
      <div className="user-management-table">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Sl.No</th>
              <th scope="col">Name</th>
              <th scope="col">Slug</th>
              <th scope="col">Url</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {navMenus &&
              navMenus.map((navItem, index) => (
                <Fragment key={navItem._id}>
                  <tr>
                    <td>
                      <span
                        onClick={() => handleChildDataView(index, navItem)}
                        data-tip
                        data-for="add-sub-menu"
                      >
                        <img
                          src={ArrowDown}
                          className={cn("nav-arrow-down", {
                            opened: toggledItems[navItem._id]
                          })}
                          alt="arrow down"
                        />
                      </span>
                      {index + 1}
                    </td>
                    <td>{navItem.name}</td>
                    <td>{navItem.slug}</td>
                    <td>{navItem.url}</td>
                    <td>
                      <span
                        data-tip={
                          accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE
                        }
                        data-for="nav-child-list"
                      >
                        <button
                          className="nav-btn"
                          data-tip="Update"
                          data-for="nav-child-list"
                          onClick={
                            accessRights.UPDATE
                              ? () => {
                                updateSubMenuEntry(index, navItem, {
                                  menuId,
                                  subMenuId: navItem._id,
                                  data: navItem
                                });
                              }
                              : undefined
                          }
                          disabled={!accessRights.UPDATE}
                        >
                          <img
                            className="nav-img"
                            src={UpdateIcon}
                            alt="Update"
                          />
                        </button>
                      </span>
                      {(navItem.slug === NAVIGATION_MENUS_SLUGS.USER_MANAGEMENT ||
                        navItem.slug === NAVIGATION_MENUS_SLUGS.NAV_MENU_MANAGEMENT) ?
                        null :
                        <span
                          data-tip={
                            accessRights.DELETE ? "" : MESSAGES.TOOLTIP_DELETE
                          }
                          data-for="nav-child-list"
                        >
                          <button
                            className="nav-btn"
                            data-tip="Delete"
                            data-for="nav-child-list"
                            onClick={
                              accessRights.DELETE
                                ? () => {
                                  deleteMenuEntry(index, navItem, {
                                    menuId,
                                    subMenuId: navItem._id,
                                    data: null
                                  });
                                }
                                : undefined
                            }
                            disabled={!accessRights.DELETE}
                          >
                            <img
                              className="nav-img"
                              src={DeleteIcon}
                              alt="Delete"
                            />
                          </button>
                        </span>}
                      <span
                        data-tip={
                          accessRights.WRITE ? "" : MESSAGES.TOOLTIP_WRITE
                        }
                        data-for="nav-child-list"
                      >
                        <button
                          className="nav-btn"
                          onClick={
                            accessRights.WRITE
                              ? () => {
                                addNewSubMenuEntry(index, navItem, {
                                  menuId,
                                  subMenuId: navItem._id,
                                  data: null
                                });
                              }
                              : undefined
                          }
                          data-tip="Add"
                          data-for="nav-child-list"
                          disabled={!accessRights.WRITE}
                        >
                          <img
                            className="nav-img"
                            src={AddIcon}
                            alt="Add page"
                          />
                        </button>
                      </span>
                    </td>
                  </tr>
                  {toggledItems[navItem._id] && (
                    <tr>
                      <td colSpan={4}>
                        <NavMenuChild
                          navMenus={navItem.children || []}
                          handleSubMenuModal={handleSubMenuModal}
                          menuId={menuId}
                          accessRights={accessRights}
                        />
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
          </tbody>
          <ReactTooltip place="top" effect="solid" id="nav-child-list" />
        </table>
      </div>
    </div>
  );
};

export default NavMenuChild;
