import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import get from "lodash/get";
import map from "lodash/map";
import isEqual from "lodash/isEqual";
import { cloneDeep } from "lodash";
import some from "lodash/some";
import Toggle from "react-toggle";
import { ENV_CONFIG } from "../../config/env";
import {
  MEGA_MENU_LANG_MAP,
  LANGUAGE,
  ADD_MEGA_MENU_ITEM_MESSAGES,
  MEGA_MENU_COLUMN_KEYS,
  MESSAGES,
  ERROR_MESSAGES
} from "../../constants";
import { _validateRedirectionUrl, getNumberFromString } from "../../util";
import Button from "../../component/button";
import {
  addDataToCarousalList,
  clearAttributeState,
  clearMegaMenuContent,
  deleteCarousalItem,
  deleteMegaMenuDetails,
  megaMenuCarousalChange,
  megaMenuText,
  updateMegaMenuDetails
} from "../../actions/megaMenu.action";
import { toastMsg } from "../../actions/common.action";
import ImageSelectionModal from "../modal/imageSelectionModal";
import ConfirmModal from "../modal/ConfirmModal";
import ColumnMenu from "./columnMenu";
import TopBanner from "./topBanner";
import AddMegaMenuItemForm from "./addMegaMenuItemForm";

class AddMegaMenuItem extends Component {
  state = {
    confirmModal: false,
    confirmPreviewModal: false,
    confirmPreviewSubmitModal: false,
    disableSubmit: true,
    imageSelectionModal: false,
    language: null,
    showContent: { index: 1, show: false }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentTab !== nextProps.currentTab) {
      this.setState({ showContent: { index: 1, show: false } });
    }
    const {
      arabicContent,
      arabicMenuData,
      englishContent,
      englishMenuData,
      isEdit
    } = nextProps;

    if (
      englishMenuData &&
      arabicMenuData &&
      englishMenuData.headerTitle &&
      englishMenuData.redirectionLink &&
      englishMenuData.pos &&
      englishMenuData.imageUrl &&
      englishMenuData.hoverImageUrl &&
      arabicMenuData.headerTitle &&
      arabicMenuData.redirectionLink &&
      arabicMenuData.pos &&
      arabicMenuData.imageUrl &&
      arabicMenuData.hoverImageUrl
    ) {
      this.setState({ disableSubmit: false });
    } else {
      this.setState({ disableSubmit: true });
    }

    if (isEdit) {
      if (
        isEqual(arabicMenuData, arabicContent) &&
        isEqual(englishMenuData, englishContent)
      ) {
        this.setState({ disableSubmit: true });
      } else {
        this.setState({ disableSubmit: false });
      }
    }
  }

  hideConfirmModal = () => this.setState({ confirmModal: false });

  changeKeyIndex = index => {
    const { showContent } = this.state;
    if (index === showContent.index) {
      this.setState({ showContent: { show: !showContent.show, index } });
    } else {
      this.props.clearAttributeState();
      this.setState({ showContent: { index, show: true } });
    }
  };

  toogleShowContent = () => {
    const { showContent } = this.state;

    this.setState({ showContent: { ...showContent, show: !showContent.show } });
  };

  handleModal = (modalName, language) => {
    this.setState(prevState => ({
      [modalName]: !prevState[modalName],
      language
    }));
  };

  validatePage = (megaMenuDetails, language) => {
    const { toastMsg } = this.props;
    const { headerTitle = "", redirectionLink = "" } = megaMenuDetails || {}
    if (headerTitle && (headerTitle !== headerTitle.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Menu Item Name`);
      return false;
    }
    if (redirectionLink && (redirectionLink !== redirectionLink.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Redirection Link`);
      return false;
    }

    return true;
  }

  recursiveArrayFunc = (arabicContentObj = {}, englishContentObj = {}) => {
    if (arabicContentObj.uid === englishContentObj.uid) {
      arabicContentObj["en_subTitle"] = englishContentObj.subTitle;
      if (arabicContentObj?.children?.length && englishContentObj?.children?.length) {
        arabicContentObj.children.forEach((content, index) => {
          this.recursiveArrayFunc(arabicContentObj.children[index], englishContentObj.children[index])
        })
      }
    }
  }

  addEnglishTitleInArabicContent = (updateEnglishContent, updateArabicContent) => {
    const englishContent = cloneDeep(updateEnglishContent);
    const arabicContent = cloneDeep(updateArabicContent);
    const columnNames = Object.keys(MEGA_MENU_COLUMN_KEYS) || [];
    columnNames.forEach((colName) => {
      if (Array.isArray(arabicContent[colName]) && Array.isArray(englishContent[colName])) {
        const englishArr = englishContent[colName] || [];
        const arabicArr = arabicContent[colName] || [];
        arabicArr.forEach((content, index) => {
          this.recursiveArrayFunc(arabicArr[index], englishArr[index])
        })
      }
    })
    return { englishContent, arabicContent }
  }

  handleSubmit = (tempsave = false) => {
    const {
      arabicContent,
      arabicMenuData,
      countryId,
      englishContent,
      englishMenuData,
      selectedCountryId,
      storeId,
      updateArabicContent,
      updateEnglishContent,
      updateMegaMenuDetails,
      selectedStoreId
    } = this.props;

    const isEnglishContentValid = this.validatePage(updateEnglishContent, "English");
    if (!isEnglishContentValid) return;

    const isArabicContentValid = this.validatePage(updateArabicContent, "Arabic");
    if (!isArabicContentValid) return;

    if (
      !tempsave &&
      (!isEqual(arabicMenuData, arabicContent) ||
        !isEqual(englishMenuData, englishContent))
    ) {
      this.setState({ confirmPreviewSubmitModal: true });
    } else {
      const { englishContent, arabicContent } = this.addEnglishTitleInArabicContent(updateEnglishContent, updateArabicContent);
      updateMegaMenuDetails(
        countryId,
        storeId,
        updateEnglishContent,
        { ...arabicContent, en_headerTitle: updateEnglishContent?.headerTitle || "" },
        tempsave,
        selectedCountryId,
        selectedStoreId
      ).then(resp => {
        if (resp.data.code === 200 && tempsave) {
          this.setState({ confirmPreviewModal: true });
        }
      });
    }
  };

  handleDelete = () => {
    this.setState({ confirmModal: true });
  };

  confirmModal = () => {
    const {
      countryId,
      deleteMegaMenuDetails,
      storeId,
      updateArabicContent,
      updateEnglishContent
    } = this.props;
    deleteMegaMenuDetails(
      countryId,
      storeId,
      updateEnglishContent.uid,
      updateArabicContent.uid
    );
    this.setState({ confirmModal: false });
  };

  handleMegaMenuTextChange = (name, value, language) => {
    const { megaMenuText } = this.props;
    if (value.length > 50) {
      return null;
    }
    megaMenuText(name, value, MEGA_MENU_LANG_MAP[language]);
  };

  handleMegaMenuRedirectionLinkChange = (name, value) => {
    const validatedValue = _validateRedirectionUrl(value) ? value : `/${value}`;
    this.handleMegaMenuTextChange(name, validatedValue, LANGUAGE.ENGLISH);
    this.handleMegaMenuTextChange(name, validatedValue, LANGUAGE.ARABIC);
  };

  handleMegaMenuPositionChange = (name, value) => {
    const { englishList, arabicList, toastMsg } = this.props;
    if (
      englishList.some(o => o.pos === value) ||
      arabicList.some(o => o.pos === value)
    ) {
      return toastMsg(
        "warning",
        ADD_MEGA_MENU_ITEM_MESSAGES.POSITION_ALREADY_ASSIGNED
      );
    }
    this.handleMegaMenuTextChange(name, value, LANGUAGE.ENGLISH);
    this.handleMegaMenuTextChange(name, value, LANGUAGE.ARABIC);
  };

  handleMegaMenuUrlChange = (key, value, brandId, language, modalName) => {
    const { megaMenuText } = this.props;
    if (modalName) {
      this.setState(
        prevState => ({
          [modalName]: !prevState[modalName],
          language
        }),
        () => megaMenuText(key, value, MEGA_MENU_LANG_MAP[language])
      );
    } else {
      this.setState({ language }, () => {
        megaMenuText(key, value, MEGA_MENU_LANG_MAP[language]);
      });
    }
  };

  megaMenuCarousalHandler = (name, value, keyIndex, language) => {
    const { megaMenuCarousalChange } = this.props;
    megaMenuCarousalChange(name, value, keyIndex, MEGA_MENU_LANG_MAP[language]);
  };

  hidePreviewModal = () => {
    const { clearMegaMenuContent, isEdit } = this.props;
    this.setState(prevProps => ({
      confirmPreviewModal: !prevProps.confirmPreviewModal
    }));
    if (!isEdit) {
      clearMegaMenuContent();
    }
  };

  hidePreviewSubmitModal = () => {
    this.setState(prevProps => ({
      confirmPreviewSubmitModal: !prevProps.confirmPreviewSubmitModal
    }));
  };

  confirmPreviewModal = () => {
    const { confirmPreviewModal } = this.state;
    const {
      clearMegaMenuContent,
      countryId,
      isEdit,
      storeId,
      storeList
    } = this.props;
    let selectedCountry = null,
      defaultCountry = null;
    storeList.forEach(store => {
      if (store.storeId === storeId) {
        store.countries.forEach(country => {
          if (country.countryId === countryId) {
            selectedCountry = country;
          }
          if (country.countrySHORT.toLowerCase() === "ae") {
            defaultCountry = country;
          }
        });
      }
    });
    this.setState({ confirmPreviewModal: !confirmPreviewModal }, () => {
      window.open(
        `${ENV_CONFIG.redirectionUrl}-${selectedCountry
          ? selectedCountry.countrySHORT.toLowerCase()
          : defaultCountry.countrySHORT.toLowerCase()
        }/?prevMode=true`,
        "_blank"
      );
      if (!isEdit) {
        clearMegaMenuContent();
      }
    });
  };

  allowMakeSuperPriceShop = () => {
    const {
      arabicList,
      arabicMenuData,
      englishList,
      englishMenuData
    } = this.props;
    return (
      !some(arabicList, { isSPS: true }) &&
      !some(englishList, { isSPS: true }) &&
      !(arabicMenuData.uid && englishMenuData.uid)
    );
  };

  confirmPreviewSubmitModal = (tempsave = false) => {
    const {
      countryId,
      selectedCountryId,
      storeId,
      updateArabicContent,
      updateEnglishContent,
      updateMegaMenuDetails,
      selectedStoreId
    } = this.props;
    updateMegaMenuDetails(
      countryId,
      storeId,
      updateEnglishContent,
      updateArabicContent,
      tempsave,
      selectedCountryId,
      selectedStoreId
    ).then(() => {
      this.setState({ confirmPreviewSubmitModal: false });
    });
  };

  handleToggleSuperPriceStore = e => {
    this.handleMegaMenuTextChange("isSPS", e.target.checked, LANGUAGE.ENGLISH);
    this.handleMegaMenuTextChange("isSPS", e.target.checked, LANGUAGE.ARABIC);
  };

  handleEnableSuperPriceStore = e => {
    this.handleMegaMenuTextChange(
      "isEnabledSPS",
      e.target.checked,
      LANGUAGE.ENGLISH
    );
    this.handleMegaMenuTextChange(
      "isEnabledSPS",
      e.target.checked,
      LANGUAGE.ARABIC
    );
  };

  render() {
    const {
      addDataToCarousalList,
      arabicList,
      arabicMenuData,
      countryId,
      deleteCarousalItem,
      englishList,
      englishMenuData,
      isEdit,
      selectedImgCol,
      storeId,
      accessRights
    } = this.props;
    const {
      confirmModal,
      confirmPreviewModal,
      confirmPreviewSubmitModal,
      disableSubmit,
      hoverImageSelectionModal,
      imageSelectionModal,
      language,
      showContent
    } = this.state;

    return (
      <Fragment>
        {confirmModal && (
          <ConfirmModal
            onHide={this.hideConfirmModal}
            confirm={this.confirmModal}
            message={ADD_MEGA_MENU_ITEM_MESSAGES.SAVED_DATA_LOSS}
          />
        )}
        {imageSelectionModal && (
          <ImageSelectionModal
            title="Upload Category Image"
            modalName="imageSelectionModal"
            keyName="imageUrl"
            brandId={storeId}
            storeId={storeId}
            countryId={countryId}
            language={language}
            containerName="imageList"
            onHide={this.handleModal}
            onSuccess={this.handleMegaMenuUrlChange}
          />
        )}
        {hoverImageSelectionModal && (
          <ImageSelectionModal
            title="Upload Mobile App Image"
            modalName="hoverImageSelectionModal"
            keyName="hoverImageUrl"
            brandId={storeId}
            storeId={storeId}
            countryId={countryId}
            language={language}
            containerName="imageList"
            onHide={this.handleModal}
            onSuccess={this.handleMegaMenuUrlChange}
          />
        )}
        {confirmPreviewModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewModal}
            confirm={this.confirmPreviewModal}
            message={ADD_MEGA_MENU_ITEM_MESSAGES.VIEW_CHANGES}
          />
        )}
        {confirmPreviewSubmitModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewSubmitModal}
            confirm={() => this.confirmPreviewSubmitModal(false)}
            message={ADD_MEGA_MENU_ITEM_MESSAGES.UNSAVED_CHANGES}
          />
        )}
        <div className="wrapper-box">
          <div className="data-wrapper">
            {this.allowMakeSuperPriceShop() && (
              <Fragment>
                <div className="form-group super-price-container">
                  <label>Make Super Price Store</label>
                  <Toggle
                    checked={
                      englishMenuData &&
                      englishMenuData.isSPS &&
                      arabicMenuData &&
                      arabicMenuData.isSPS
                    }
                    icons={false}
                    onChange={this.handleToggleSuperPriceStore}
                  />
                </div>
                <hr />
              </Fragment>
            )}
            {englishMenuData.isSPS && arabicMenuData.isSPS && (
              <Fragment>
                <div className="form-group super-price-container">
                  <label>Enable Super Price Store</label>
                  <Toggle
                    checked={
                      englishMenuData &&
                      englishMenuData.isEnabledSPS &&
                      arabicMenuData &&
                      arabicMenuData.isEnabledSPS
                    }
                    icons={false}
                    onChange={this.handleEnableSuperPriceStore}
                  />
                </div>
                <hr />
              </Fragment>
            )}
            <AddMegaMenuItemForm
              handleMegaMenuPositionChange={this.handleMegaMenuPositionChange}
              handleMegaMenuRedirectionLinkChange={
                this.handleMegaMenuRedirectionLinkChange
              }
              handleMegaMenuTextChange={this.handleMegaMenuTextChange}
              handleMegaMenuUrlChange={this.handleMegaMenuUrlChange}
              handleModal={this.handleModal}
              isEdit={isEdit}
              language={LANGUAGE.ENGLISH}
              menuData={englishMenuData}
              menuItemList={englishList}
            />
            <AddMegaMenuItemForm
              handleMegaMenuPositionChange={this.handleMegaMenuPositionChange}
              handleMegaMenuRedirectionLinkChange={
                this.handleMegaMenuRedirectionLinkChange
              }
              handleMegaMenuTextChange={this.handleMegaMenuTextChange}
              handleMegaMenuUrlChange={this.handleMegaMenuUrlChange}
              handleModal={this.handleModal}
              isEdit={isEdit}
              language={LANGUAGE.ARABIC}
              menuData={arabicMenuData}
              menuItemList={arabicList}
            />
            <TopBanner
              storeId={storeId}
              countryId={countryId}
              englishMenuData={englishMenuData || []}
              arabicMenuData={arabicMenuData || []}
              addDataToCarousalList={addDataToCarousalList}
              deleteCarousalItem={deleteCarousalItem}
              megaMenuCarousalHandler={this.megaMenuCarousalHandler}
            />
          </div>
        </div>
        {map(MEGA_MENU_COLUMN_KEYS, (value, key) => (
          <ColumnMenu
            key={key}
            columnName={key}
            showContent={showContent}
            englishData={get(englishMenuData, key, [])}
            arabicData={get(arabicMenuData, key, [])}
            colValue={getNumberFromString(value)}
            changeKeyIndex={this.changeKeyIndex}
            selectedImgCol={selectedImgCol}
            toogleShowContent={this.toogleShowContent}
          />
        ))}
        <div className="marginBottom">
          <span
            data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
            data-for="mega-menu"
          >
            <Button
              name="Save & Preview"
              customClass={classnames({ "btn-disabled": disableSubmit })}
              disabled={disableSubmit || !accessRights.UPDATE}
              onClick={
                accessRights.UPDATE
                  ? () => {
                    if (!disableSubmit) {
                      this.handleSubmit(true);
                    }
                  }
                  : null
              }
            />
          </span>
          <Fragment>
            <span
              data-tip={accessRights.DELETE ? "" : MESSAGES.TOOLTIP_DELETE}
              data-for="mega-menu"
            >
              {isEdit && (
                <Button
                  name="Delete"
                  customClass="delete-megamenu-btn"
                  borderColor={"#000000"}
                  onClick={accessRights.DELETE ? this.handleDelete : null}
                  disabled={!accessRights.DELETE}
                />
              )}
            </span>
            <span
              data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
              data-for="mega-menu"
            >
              {isEdit && (
                <Button
                  name="Publish"
                  onClick={
                    accessRights.UPDATE ? () => this.handleSubmit() : null
                  }
                  disabled={englishMenuData.isPublished || !accessRights.UPDATE}
                  customClass={classnames({
                    opacity: englishMenuData.isPublished
                  })}
                />
              )}
            </span>
          </Fragment>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  arabicList: state.megaMenu.arabic,
  countryId: state.store.countryId,
  englishList: state.megaMenu.english,
  isEdit: state.megaMenu.isEdit,
  selectedCountryId: state.selectedCountryId,
  selectedImgCol: state.megaMenu.selectedImgCol,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  updateArabicContent: state.megaMenu.updateArabicContent,
  updateEnglishContent: state.megaMenu.updateEnglishContent,
  selectedStoreId: state.selectedStoreIds
});

const mapDispatchToProps = {
  addDataToCarousalList,
  clearAttributeState,
  clearMegaMenuContent,
  deleteCarousalItem,
  deleteMegaMenuDetails,
  megaMenuCarousalChange,
  megaMenuText,
  toastMsg,
  updateMegaMenuDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMegaMenuItem);
