import {
  CHANGE_DEVICE_TAB,
  CHANGE_TAB,
  COMMON_SET_SUBMIT_STATE,
  HIDE_LOADER,
  SHOW_LOADER,
  HIDE_CUSTOM_LOADER,
  SHOW_CUSTOM_LOADER,
  CHANGE_PLATFORM_TAB,
  SHOW_SNACKBAR
} from "../constants/actions";
import { deviceTypes, devicePlatformTypes } from "../constants";

const initialState = {
  showLoader: false,
  showCustomLoader: false,
  currentTab: "home-page",
  isSubmitEnabled: false,
  deviceTab: deviceTypes.desktop,
  devicePlatformTab: devicePlatformTypes.resp,
  snackbar: {
    id: "",
    open: false,
    severity: "",
    text: "",
    vertical: 'top',
    horizontal: 'right'
  }
};

const showLoader = state => ({ ...state, showLoader: true });

const hideLoader = state => ({ ...state, showLoader: false });

const showCustomLoader = state => ({ ...state, showCustomLoader: true });

const hideCustomLoader = state => ({ ...state, showCustomLoader: false });

const changeTab = (state, { currentTab }) => ({ ...state, currentTab });

const setSubmitState = (state, { submitState }) => ({
  ...state,
  isSubmitEnabled: submitState
});

const changeDeviceTab = (state, { deviceType }) => ({
  ...state,
  deviceTab: deviceType
});
const changePlatformTab = (state, { deviceType }) => ({
  ...state,
  devicePlatformTab: deviceType
});
const showSnackbar = (state, { options }) => {
  return {
    ...state,
    snackbar: {
      ...state.snackbar,
      id: Date.now(),
      ...options,
    }
  }
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return showLoader(state);
    case HIDE_LOADER:
      return hideLoader(state);
    case SHOW_CUSTOM_LOADER:
      return showCustomLoader(state);
    case HIDE_CUSTOM_LOADER:
      return hideCustomLoader(state);
    case CHANGE_TAB:
      return changeTab(state, action);
    case COMMON_SET_SUBMIT_STATE:
      return setSubmitState(state, action);
    case CHANGE_DEVICE_TAB:
      return changeDeviceTab(state, action);
    case CHANGE_PLATFORM_TAB:
      return changePlatformTab(state, action);
    case SHOW_SNACKBAR:
      return showSnackbar(state, action);
    default:
      return state;
  }
};

export default commonReducer;
