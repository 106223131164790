import { generateUUID } from "../../util";

export const initialImageCarouselSection = () => ({
  english: {
    title: "",
    subTitle: ""
  },
  arabic: {
    title: "",
    subTitle: ""
  },
  imageList: [],
  schedulers: [],
  enable: false,
  type: "catalog-carousel",
  id: generateUUID(),
  openAccordion: true
});
