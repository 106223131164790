import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import find from "lodash/find";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import includes from "lodash/includes";
import { selectCountryId } from "../../actions/selectedCountyId.action";
import { DropDownList } from "../applyDropDown/dropDownList";

class MultiCountryDropDown extends Component {
  state = {
    storeList: [],
    countryList: [],
    isAllCountries: false
  };

  componentDidMount() {
    const { countryId, storeId, storeList } = this.props;
    if (!isEmpty(storeList) && (countryId || storeId)) {
      this.handleInitCountryDropDown(countryId, storeId, storeList);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryList = [] } = this.state;
    const { storeId, countryId, storeList, selectedCountryId } = this.props;
    if (
      get(storeList, "length") !== get(nextProps, "storeList.length") ||
      countryId !== nextProps.countryId ||
      storeId !== nextProps.storeId
    ) {
      this.setState({ isAllCountries: false }, () => {
        this.handleInitCountryDropDown(
          nextProps.countryId,
          nextProps.storeId,
          nextProps.storeList
        );
      });
    }
    if (!isEqual(selectedCountryId, nextProps.selectedCountryId)) {
      this.setState(prevState => ({
        countryList: prevState.countryList.map(country => ({
          ...country,
          isChecked: includes(nextProps.selectedCountryId, country.countryId)
        })),
        isAllCountries:
          nextProps.selectedCountryId.length === countryList.length
      }));
    }
  }

  handleInitCountryDropDown = (countryId, storeId, storeList) => {
    const { selectCountryId } = this.props;
    const selectedStore = find(storeList, store => store.storeId === storeId);
    const countryList = map(get(selectedStore, "countries"), country =>
      parseInt(countryId) === parseInt(country.countryId)
        ? { ...country, isChecked: true, isDisabled: true }
        : { ...country, isChecked: false }
    );
    this.setState({
      storeList,
      countryList
    });
    selectCountryId([countryId]);
  };

  handleSelectCountry = (item, isAllCountries) => {
    const { countryList } = this.state;
    const { selectCountryId } = this.props;
    let updatedCountryList = [];
    this.setState({ isAllCountries });
    if (isAllCountries) {
      updatedCountryList = countryList.map(country => {
        if (!country.isDisabled) {
          return { ...country, isChecked: true };
        } else {
          return { ...country };
        }
      });
    } else {
      if (item && item.countryId) {
        updatedCountryList = countryList.map(country => {
          if (country.countryId === item.countryId && !item.isDisabled) {
            return { ...country, isChecked: !country.isChecked };
          } else {
            return { ...country };
          }
        });
      } else {
        updatedCountryList = countryList.map(country => {
          if (!country.isDisabled) {
            return { ...country, isChecked: !country.isChecked };
          } else {
            return { ...country };
          }
        });
      }
    }
    const updatedCountry = updatedCountryList.filter(
      country => country.isChecked
    );
    const updatedCountryID = updatedCountry.map(country => country.countryId);
    this.setState({ countryList: updatedCountryList });
    selectCountryId(updatedCountryID);
  };

  render() {
    const { storeList, countryList, isAllCountries } = this.state;
    // const selectedCountriesString = reduce(
    //   countryList,
    //   (acc, country) => {
    //     if (country.isChecked) {
    //       acc += `${acc ? ', ' : ''}${country.countryCode}`;
    //     }
    //     return acc;
    //   },
    //   '',
    // );

    return (
      !isEmpty(storeList) && (
        <DropDownList
          listData={countryList}
          handleSelect={this.handleSelectCountry}
          isAll={isAllCountries}
          selectedName="Apply For"
          nameKey="name"
          idKey="countryId"
        />
      )
    );
  }
}

const mapStateToProps = state => ({
  countryId: state.store.countryId,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  selectedCountryId: state.selectedCountryId
});

const mapDispatchToProps = { selectCountryId };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiCountryDropDown);
