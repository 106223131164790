import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  URL_PAGE_TYPE,
  URL_PAGE_TYPE_DROPDOWN,
  URL_PAGE_PANEL,
  URL_TYPES,
} from "../../constants";
import { convertOldFilterCondition } from "../../util/url"
import {
  TextField,
  MenuItem,
  IconButton,
  Button,
  AntSwitch,
  Typography,
  Stack,
  Box,
  Tooltip,
  Badge
} from "../../materialUi/components";
import { LanguageIcon, PersonIcon, LoopIcon } from "../../materialUi/icons";
import AllowedUsersModal from "./allowedUsersModal";
import { fetchFlashSalePagesList } from "../../actions/flashSaleV2.action";

const PageContentForm = ({
  content,
  currentTab,
  handlePageTypeChange,
  handleChange,
  validateUrlHandler,
  validateFilterConditionRfc,
  countryList,
  handleCountryChange,
  selectedCountry,
  handleIntBtnClick
}) => {
  const ALLOWED_USERS_MODAL = "ALLOWED_USERS_MODAL";
  const dispatch = useDispatch();
  const [flashSalePageOptions, setFlashSalePageOptions] = useState([]);
  const [showModal, setShowModal] = useState({
    [ALLOWED_USERS_MODAL]: false,
  });
  const getFlashSalePagesList = async () => {
    const response = await dispatch(fetchFlashSalePagesList(236));
    if (response?.data?.code === 200) {
      const data = response.data.data || [];
      const options = data.map(value => ({
        label: value.name,
        value: value.slug
      }));
      setFlashSalePageOptions(options);
    }
  };

  useEffect(() => {
    if (content.pageType === URL_PAGE_TYPE_DROPDOWN.flashSale) {
      getFlashSalePagesList();
    }
  }, [content.pageType]);

  const handleModal = (modalName, value) => {
    setShowModal({ [modalName]: value });
  }

  return (
    <Box>
      {showModal[ALLOWED_USERS_MODAL] && <AllowedUsersModal
        open={true}
        data={content.allowedUsers || []}
        handleClose={() =>
          handleModal(ALLOWED_USERS_MODAL, false)
        }
        handleSave={(data) => {
          handleChange(content._id, "allowedUsers", data);
          handleModal(ALLOWED_USERS_MODAL, false);
        }
        }
        title={"Allowed Users Details"}
        dialogContext={""}
      />}
      <Box className="url-generation-form">
        <Box className="url-width-50 url-right-pd">
          <TextField
            select
            label="Select Page type"
            onChange={event => {
              handlePageTypeChange(content._id, event.target.value);
            }}
            value={content.pageType || ""}
            size="small"
            sx={{ width: "100%" }}
          >
            {URL_PAGE_TYPE.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="url-width-50 url-left-pd">
          <TextField
            type="text"
            name="url"
            value={content.url || ""}
            autoComplete="off"
            placeholder="SEO friendly URL"
            label="SEO friendly URL"
            onChange={e => handleChange(content._id, "url", e.target.value)}
            size="small"
            sx={{ width: "100%" }}
            disabled={currentTab === URL_PAGE_PANEL.URL_EDIT_PAGE_DISPLAY}
          />
        </Box>
      </Box>
      <Box className="url-generation-form">
        {(content.pageType === URL_PAGE_TYPE_DROPDOWN.productListing ||
          content.pageType === URL_PAGE_TYPE_DROPDOWN.productCatalog) && (
            <>
              <Box style={{ width: "20%" }}>
                <TextField
                  select
                  label="Select Country"
                  onChange={event => {
                    handleCountryChange(event.target.value);
                  }}
                  value={selectedCountry.countrySHORT || ""}
                  name="country"
                  placeholder="Select Country"
                  size="small"
                  sx={{ width: "100%" }}
                >
                  {countryList.map(option => (
                    <MenuItem
                      key={option.countrySHORT}
                      value={option.countrySHORT}
                    >
                      {option.countryName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <IconButton
                  disabled={
                    !(
                      content.pageType ===
                      URL_PAGE_TYPE_DROPDOWN.productListing ||
                      content.pageType === URL_PAGE_TYPE_DROPDOWN.productCatalog
                    )
                  }
                  onClick={() =>
                    handleIntBtnClick(selectedCountry.countrySHORT, false)
                  }
                >
                  <LanguageIcon />
                </IconButton>
              </Box>
              <Box style={{ width: "60%" }} sx={{ display: "flex" }}>
                <TextField
                  type="text"
                  name="filterCondition"
                  value={content.filterCondition || ""}
                  autoComplete="off"
                  placeholder="Enter Filter Condition"
                  label="Filter Condition"
                  onChange={e =>
                    handleChange(content._id, "filterCondition", e.target.value)
                  }
                  size="small"
                  sx={{ width: "100%" }}
                  disabled={
                    !(
                      content.pageType ===
                      URL_PAGE_TYPE_DROPDOWN.productListing ||
                      content.pageType === URL_PAGE_TYPE_DROPDOWN.productCatalog
                    )
                  }
                />
                <Tooltip title="Generate FilterConditionV2" placement="top" arrow>
                  <span>
                    <IconButton
                      disabled={!content.filterCondition || content.urlType !== URL_TYPES.CUSTOM}
                      onClick={() => {
                        let filterCondition_rfc = convertOldFilterCondition(content.filterCondition)
                        handleChange(content._id, "filterCondition_rfc", filterCondition_rfc)
                      }
                      }
                    >
                      <LoopIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </>
          )}
        {content.pageType === URL_PAGE_TYPE_DROPDOWN.flashSale && (
          <Box style={{ width: "20%" }}>
            <TextField
              select
              label="Select Flash Sale Page Content"
              onChange={event => {
                handleChange(content._id, "pageContent", event.target.value);
              }}
              value={content.pageContent || ""}
              name="pageContent"
              placeholder="Select Flash Sale Page Content"
              size="small"
              sx={{ width: "100%" }}
            >
              {flashSalePageOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        <Box style={{ marginLeft: "auto" }}>
          <Button onClick={validateUrlHandler} variant="contained">
            {content.pageType === URL_PAGE_TYPE_DROPDOWN.productListing ||
              content.pageType === URL_PAGE_TYPE_DROPDOWN.productCatalog
              ? "Get Product count"
              : "Verify Url"}
          </Button>
        </Box>
      </Box>
      <Box className="url-generation-form">
        {(content.pageType === URL_PAGE_TYPE_DROPDOWN.productListing ||
          content.pageType === URL_PAGE_TYPE_DROPDOWN.productCatalog) && (
            <>
              <Box style={{ marginLeft: "23.5%", width: "60%" }}>
                <TextField
                  type="text"
                  name="filterCondition_rfc"
                  value={content.filterCondition_rfc || ""}
                  autoComplete="off"
                  placeholder="Enter Filter Condition"
                  label="Filter Condition Version 2"
                  onChange={e =>
                    handleChange(content._id, "filterCondition_rfc", e.target.value)
                  }
                  size="small"
                  sx={{ width: "100%" }}
                  disabled={
                    !(
                      content.pageType === URL_PAGE_TYPE_DROPDOWN.productListing ||
                      content.pageType === URL_PAGE_TYPE_DROPDOWN.productCatalog
                    )
                  }
                />
              </Box>
              <Box style={{ marginLeft: "auto" }}>
                <Button onClick={validateFilterConditionRfc} variant="contained">
                  {content.pageType === URL_PAGE_TYPE_DROPDOWN.productListing ||
                    content.pageType === URL_PAGE_TYPE_DROPDOWN.productCatalog
                    ? "Get Product count"
                    : "Verify Url"}
                </Button>
              </Box>
            </>
          )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <AntSwitch
              checked={content.isIndexable || false}
              onChange={e => {
                handleChange(content._id, "isIndexable", e.target.checked);
              }}
            />
            <Typography variant="h6">Indexable</Typography>
          </Stack>
        </Box>
        {content.urlType === URL_TYPES.PROTECTED &&
          <Tooltip title={(content.allowedUsers && content.allowedUsers.length)
            ? "Edit Allowed Users"
            : "Add Allowed Users"} placement="top" arrow>
            <span>
              <IconButton
                sx={{ marginLeft: "20px" }}
                onClick={() => {
                  handleModal(ALLOWED_USERS_MODAL, true)
                }}>
                <Badge showZero={false} badgeContent={(content?.allowedUsers?.length) ? content.allowedUsers.length : 0} color="error" size="small" variant="standard">
                  <PersonIcon fontSize="medium" />
                </Badge>
              </IconButton>
            </span>
          </Tooltip>}
      </Box>
    </Box>
  );
};

export default PageContentForm;
