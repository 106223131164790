import axios, { CancelToken } from "axios";
import { getUserStorage } from "../util/browserStorage";

function getConfig(accessToken, publish) {
  let cancel, config;

  // Return the cancel function and add it to the header.
  // See: https://github.com/mzabriskie/axios#cancellation
  config = {
    cancelToken: new CancelToken(c => {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    }),
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/json",
      "x-access-token": accessToken
    }
  };

  if (publish && config["headers"]) {
    config["headers"]["tempsave"] = true;
  }

  return {
    cancel,
    config
  };
}

export function webapiGet(accessToken, url) {
  const config = getConfig(accessToken);
  return {
    request: axios.get(url, config.config),
    cancel: config.cancel
  };
}

export function webapiPut(accessToken, url, options, tempsave) {
  const config = getConfig(accessToken, tempsave);
  return {
    request: axios.put(url, options, config.config),
    cancel: config.cancel
  };
}

export function webapiPatch(accessToken, url, options, tempsave) {
  const config = getConfig(accessToken, tempsave);
  return {
    request: axios.patch(url, options, config.config),
    cancel: config.cancel
  };
}

export function webapiPost(accessToken, url, options, extraHeadersObj) {
  const config = getConfig(accessToken);
  if (extraHeadersObj && Object.keys(extraHeadersObj).length) {//For Adding extra Headers
    config.config.headers = { ...config.config.headers, ...extraHeadersObj }
  }
  return {
    request: axios.post(url, options, config.config),
    cancel: config.cancel
  };
}

export function webapiDelete(accessToken, url, options) {
  const config = getConfig(accessToken);
  if (options) config.config.data = options;
  return {
    request: axios.delete(url, config.config),
    cancel: config.cancel
  };
}

export function webapiAuthPost(url, options) {
  return {
    request: axios.post(url, options)
  };
}

const oSend = XMLHttpRequest.prototype.send;
XMLHttpRequest.prototype.send = function (config) {
  if (!(this.headers && this.headers["x-access-token"])) {
    this.setRequestHeader("x-access-token", getUserStorage());
  }
  oSend.call(this, config);
};

const oSetRequestHeaders = XMLHttpRequest.prototype.setRequestHeader;
XMLHttpRequest.prototype.setRequestHeader = function (header, value) {
  this.headers = this.headers || {};
  this.headers[header] = value;
  oSetRequestHeaders.call(this, header, value);
};
