import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Notifications from "../containers/Notifications";
import AuthContentContainer from "./routeManagement";
import UnAuthContent from "./routeManagement/unAuthRoutes";
import { getUserStorage } from "../util/browserStorage";
import { tokenGenerator } from "../actions/authConfig.action";
import LoadingIndicator from "../component/loadingIndicator";
import {
  showLoadingIndicator,
  hideLoadingIndicator
} from "../actions/common.action";
import { Snackbar } from '../materialUi/components'
import { AdapterMoment, LocalizationProvider } from "../materialUi/datePicker";

class Gatekeeper extends Component {
  state = {
    accessToken: ""
  };

  UNSAFE_componentWillMount() {
    const { createToken } = this.props;
    const accessToken = getUserStorage();
    this.setState({ accessToken });
    if (accessToken) {
      createToken(accessToken);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isUserLoggedIn } = this.props;
    if (nextProps.isUserLoggedIn !== isUserLoggedIn) {
      this.setState({ accessToken: nextProps.isUserLoggedIn });
    }
  }

  render() {
    const { accessToken } = this.state;
    const { isUserLoggedIn, showLoader, snackbar } = this.props;
    const showContent =
      isUserLoggedIn || accessToken ? (
        <AuthContentContainer />
      ) : (
        <UnAuthContent />
      );
    return (
      <Fragment>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Notifications />
          <Snackbar {...snackbar} />
          <LoadingIndicator showLoader={showLoader} />
          {showContent}
        </LocalizationProvider>
      </Fragment>
    );
  }
}

Gatekeeper.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  createToken: PropTypes.func.isRequired,
  showLoading: PropTypes.func.isRequired,
  hideLoading: PropTypes.func.isRequired
};

const mapStateToProps = reduxState => ({
  isUserLoggedIn: reduxState.authentication.userLoggedIn,
  showLoader: reduxState.common.showLoader,
  snackbar: reduxState.common.snackbar,
});

const mapDispatchToProps = dispatch => ({
  createToken(accessToken) {
    dispatch(tokenGenerator(accessToken));
  },
  showLoading() {
    dispatch(showLoadingIndicator());
  },
  hideLoading() {
    dispatch(hideLoadingIndicator());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Gatekeeper);
