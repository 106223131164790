export const IMAGE_LIST_ACCORDION_PLACEHOLDERS = {
  arabic: {
    title: "أدخل عنوان الصورة",
    subTitle: "أدخل صورة العنوان الفرعي",
    GtmTag: "أدخل علامة GTM",
    redirectionLink: "أدخل رابط إعادة التوجيه"
  },
  english: {
    title: "Enter Image Title",
    subTitle: "Enter Image SubTitle",
    GtmTag: "Enter GTM Tag",
    redirectionLink: "Enter Redirection Link (must start with '/')"
  }
};
