import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPagesByCountry } from "../../actions/pagesPage.action";
import { CustomSelect } from "../../component";
import _ from "lodash";

const PopupContent = ({ onConfirm, onCancel, message }) => (
  <Fragment>
    <div>{message || "Are you sure you want to delete the item?"}</div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={onConfirm}>
        <span className="btn-text">Yes</span>
      </button>
      <button className="popup-cancel" onClick={onCancel}>
        <span className="n-btn-text">No</span>
      </button>
    </div>
  </Fragment>
);

export const CloneTabPopupContent = ({
  onConfirm,
  onCancel,
  rowTitle,
  tab
}) => (
  <Fragment>
    <div style={{ fontSize: "14px" }}>
      Are you sure you want to copy {rowTitle} - {tab} ? You can always undo
      your changes before saving
    </div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={onConfirm}>
        <span className="btn-text">Yes</span>
      </button>
      <button className="popup-cancel" onClick={onCancel}>
        <span className="n-btn-text">No</span>
      </button>
    </div>
  </Fragment>
);

export const UnemptyFolderPopupContent = ({ onConfirm }) => (
  <Fragment>
    <div>You should delete all the pages before deleting the entire folder</div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={onConfirm}>
        <span className="btn-text">OK</span>
      </button>
    </div>
  </Fragment>
);

export const CloneRowPopupContent = ({
  onConfirm,
  onCancel,
  modules,
  rowTitle
}) => {
  const [selectedModule, setSelectedModule] = useState();

  return (
    <Fragment>
      <div>
        <p>Clone {rowTitle} into:</p>
        <CustomSelect
          className="banner-control-select"
          options={modules.map(module => ({
            label: module.title,
            value: module.id
          }))}
          onChange={ev => setSelectedModule(ev.value)}
          value={selectedModule}
          name="module"
          placeholder="Select module"
        />
      </div>
      <div className="popup-buttons">
        <button
          className="popup-confirm"
          onClick={() => selectedModule && onConfirm(selectedModule)}
        >
          <span className="btn-text">Clone</span>
        </button>
        <button className="popup-cancel" onClick={onCancel}>
          <span className="n-btn-text">Close</span>
        </button>
      </div>
    </Fragment>
  );
};

export const CloneCountryPopupContent = ({
  onConfirm,
  onCancel,
  modules,
  countryList
}) => {
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState();
  const [selectedCountry, setSelectedCountry] = useState();

  const handleCountryChange = ev => {
    setSelectedCountry(ev.value);
    dispatch(getPagesByCountry(ev.value));
  };
  const PagesByCountry = useSelector(
    store => store.pagesPageReducer.PagesByCountry
  );

  useEffect(() => {
    PagesByCountry;
  }, [PagesByCountry]);

  return (
    <Fragment>
      <div>
        <h4>Clone Dynamic banner to country</h4>
      </div>
      <div>
        <p>Select Country:</p>
        <CustomSelect
          className="banner-control-select"
          options={countryList.map(country => ({
            label: country.countryName,
            value: country.countryId
          }))}
          onChange={ev => handleCountryChange(ev)}
          value={selectedCountry}
          name="module"
          placeholder="Select Country"
        />
      </div>

      {!_.isEmpty(PagesByCountry) && (
        <div>
          <p>Select Page:</p>
          <CustomSelect
            className="banner-control-select"
            options={
              !_.isEmpty(PagesByCountry) &&
              PagesByCountry.map(page => ({
                label: page.title,
                value: page.id
              }))
            }
            onChange={ev => setSelectedPage(ev.value)}
            value={selectedPage}
            name="page"
            placeholder="Select Page"
          />
        </div>
      )}
      <div className="popup-buttons">
        <button
          className="popup-confirm"
          onClick={() => onConfirm(modules.id, selectedPage)}
        >
          <span className="btn-text">Clone</span>
        </button>
        <button className="popup-cancel" onClick={onCancel}>
          <span className="n-btn-text">Close</span>
        </button>
      </div>
    </Fragment>
  );
};

export default PopupContent;
