import { success, error } from "react-notification-system-redux";
import capitalise from "lodash/capitalize";
import notificationOpts from "../util/toastrHandler";
import {
  CHANGE_TAB,
  COMMON_SET_SUBMIT_STATE,
  HIDE_LOADER,
  SHOW_LOADER,
  SET_ACCORDION_STATE,
  SHOW_CUSTOM_LOADER,
  HIDE_CUSTOM_LOADER,
  SHOW_SNACKBAR
} from "../constants/actions";
import { ENV_CONFIG } from "../config/env";
import { postDashboardWebApi } from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";

export const showLoadingIndicator = () => ({ type: SHOW_LOADER });

export const hideLoadingIndicator = () => ({ type: HIDE_LOADER });

export const showCustomLoadingIndicator = () => ({ type: SHOW_CUSTOM_LOADER });

export const hideCustomLoadingIndicator = () => ({ type: HIDE_CUSTOM_LOADER });

export const changeTab = currentTab => ({ type: CHANGE_TAB, currentTab });

export const toastMsg = (toastType, successMsg) => {
  if (toastType === "success") {
    return dispatch =>
      dispatch(success(notificationOpts(capitalise(toastType), successMsg)));
  } else {
    return dispatch =>
      dispatch(error(notificationOpts(capitalise(toastType), successMsg)));
  }
};

export const snackbar = (options) => ({
  type: SHOW_SNACKBAR,
  options
});

export const setSubmitState = submitState => ({
  type: COMMON_SET_SUBMIT_STATE,
  submitState
});

export const setAccordionState = sectionId => ({
  type: SET_ACCORDION_STATE,
  sectionId
});

export const uploadImage = payload => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/homepage/content/upload?id=${payload.brandId}&key=${payload.keyName}&language=${payload.language}&storeId=${payload.storeId}&countryId=${payload.countryId}&containerName=${payload.containerName}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      payload._formData
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
