import React, { Component, Fragment } from "react";
import CKEditor from "ckeditor4-react";
import cn from "classnames";
import forEach from "lodash/forEach";
import get from "lodash/get";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import reduce from "lodash/reduce";
import uniq from "lodash/uniq";
import {
  CK_CONFIG_ENGLISH,
  CK_CONFIG_ARABIC,
  CK_EDITOR_URL,
  STANDARD_CK_CONFIG,
  LANGUAGE
} from "../../constants";
import Button from "../../component/button";
import { ENV_CONFIG } from "../../config/env";
import CKEditorNotes from "./../../component/ckEditorNotes";
import InputText from "../../component/InputText";
import SearchSubCategories from "./searchDropDown";

CKEditor.editorUrl = CK_EDITOR_URL.url;

class GenericSubCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enable: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      updatedSelectedGenericSubCategory,
      selectedGenericSubCategory
    } = nextProps;
    if (
      updatedSelectedGenericSubCategory.english &&
      updatedSelectedGenericSubCategory.english.heading &&
      updatedSelectedGenericSubCategory.english.html &&
      updatedSelectedGenericSubCategory.arabic &&
      updatedSelectedGenericSubCategory.arabic.heading &&
      updatedSelectedGenericSubCategory.arabic.html &&
      !isEmpty(updatedSelectedGenericSubCategory.subCategory)
    ) {
      this.setState({
        enable: !isEqual(
          updatedSelectedGenericSubCategory,
          selectedGenericSubCategory
        )
      });
    } else {
      this.setState({ enable: false });
    }
  }

  onChange = (evt, language) => {
    const { textChangeHandlerSubCategory } = this.props;
    const value = evt.editor.getData();
    textChangeHandlerSubCategory("html", value, language);
  };

  onChangeText = (evt, language) => {
    const { textChangeHandlerSubCategory } = this.props;
    const value = evt.target.value;
    const name = evt.target.name;
    textChangeHandlerSubCategory(name, value, language);
  };

  changeSelectedSubCategory = subCategory => {
    const { categoryChangeHandlerForSubCategory } = this.props;
    categoryChangeHandlerForSubCategory(subCategory);
  };

  ckEditorConfig = language => {
    const { storeId, countryId } = this.props;
    return {
      ...(language === LANGUAGE.ARABIC ? CK_CONFIG_ARABIC : CK_CONFIG_ENGLISH),
      ...STANDARD_CK_CONFIG,
      removeButtons: STANDARD_CK_CONFIG.removeButtons,
      filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
        }/homepage/content/uploadCK?id=${storeId}&key=${"html"}&language=${language}&storeId=${storeId}&countryId=${countryId}&containerName=${"sizeChart"}`
    };
  };

  render() {
    const { enable } = this.state;
    const {
      cancel,
      contentId,
      contentName,
      content,
      genericCategoriesContent,
      handleConfirmModal,
      index,
      newItem,
      save,
      showContent,
      subCategoryList,
      toggleContent,
      updatedSelectedGenericSubCategory,
      selectedGenericCategoryIndex
    } = this.props;
    let item = {
      english: { html: "", heading: "" },
      arabic: { html: "", heading: "" },
      ...content
    };
    if (content._id === updatedSelectedGenericSubCategory._id) {
      item = { ...item, ...updatedSelectedGenericSubCategory };
    }

    /* find all unique subcategory ids that are already 
    selected in other generic size chart categories */
    const subCategoriesToFilterOut = uniq(
      reduce(
        filter(
          genericCategoriesContent,
          (_genericCategory, index) => index !== selectedGenericCategoryIndex
        ),
        (accum, genericCategory) => {
          forEach(genericCategory.list, listItem => {
            accum.push(...map(listItem.subCategory, subCat => subCat.value));
          });
          return accum;
        },
        []
      )
    );

    const filteredSubCategoryList = filter(
      subCategoryList,
      subCat => !subCategoriesToFilterOut.includes(subCat.id)
    );

    return (
      <Fragment>
        <div className="wrapper-box">
          {!newItem && (
            <div className="heading-wrapper flex catalog-banner-heading">
              <h3>{get(item, "english.heading", "")}</h3>
              <span
                onClick={() => toggleContent(index, item)}
                className={cn("toggle-banner", {
                  "minus-banner":
                    showContent.index === index && showContent.show,
                  "plus-banner": !(
                    showContent.index === index && showContent.show
                  )
                })}
              />
              {contentId === "clothing" ||
                (contentName === "Clothing" && (
                  <span
                    className="delete-btn"
                    onClick={() => handleConfirmModal(this.props.remove, item)}
                  />
                ))}
            </div>
          )}
          {((showContent.index === index && showContent.show) || newItem) && (
            <div className="data-wrapper">
              <div className="">
                <div className="english-content">
                  <div className="form-group">
                    <SearchSubCategories
                      title="Sub Category"
                      placeHolder="Please Select Sub Category"
                      searchList={filteredSubCategoryList}
                      changeSelectedData={this.changeSelectedSubCategory}
                      selectedData={item.subCategory}
                    />
                    <InputText
                      name="heading"
                      label="Heading (English)"
                      language={LANGUAGE.ENGLISH}
                      placeholder="Enter Heading"
                      value={get(item, "english.heading", "")}
                      onChange={e => this.onChangeText(e, "english")}
                      required
                    />
                    <label>
                      <span className="required" />
                      HTML Text Editor(English)
                    </label>
                    <CKEditor
                      activeClass="p10"
                      config={this.ckEditorConfig(LANGUAGE.ENGLISH)}
                      data={get(item, "english.html", "")}
                      onChange={e => this.onChange(e, "english")}
                      onKey={e => this.onChange(e, "english")}
                    />
                    <CKEditorNotes display={[2]} />
                    <InputText
                      name="heading"
                      label="Heading (Arabic)"
                      language={LANGUAGE.ARABIC}
                      placeholder="Enter Heading"
                      value={get(item, "arabic.heading", "")}
                      onChange={e => this.onChangeText(e, "arabic")}
                      required
                    />
                    <label>
                      <span className="required" />
                      HTML Text Editor(Arabic)
                    </label>
                    <CKEditor
                      activeClass="p10"
                      config={this.ckEditorConfig(LANGUAGE.ARABIC)}
                      data={get(item, "arabic.html", "")}
                      onChange={e => this.onChange(e, "arabic")}
                      onKey={e => this.onChange(e, "arabic")}
                    />
                    <CKEditorNotes noMargin={true} display={[2]} />
                  </div>
                </div>
              </div>
              <Button
                name={"Save"}
                disabled={false}
                onClick={() => {
                  if (enable) save(index);
                }}
                customClass={enable ? "" : "opacity disable-btn"}
              />
              <Button
                name={"Cancel"}
                disabled={false}
                onClick={() => {
                  if (enable) handleConfirmModal(cancel);
                }}
                customClass={enable ? "" : "opacity disable-btn"}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default GenericSubCategory;
