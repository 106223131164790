import React from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import capitalize from "lodash/capitalize";
import {
  SLIDER_IMAGE_ACCORDION_PLACEHOLDERS,
  sliderCheckboxes
} from "../../constants";
import InputText from "../InputText";
import InputCheckbox from "../InputCheckbox";

export const SliderForm = ({ handleTextChange, item, language }) => {
  const placeholders = SLIDER_IMAGE_ACCORDION_PLACEHOLDERS[language];
  return (
    <div className={`${language}-wrapper`}>
      <h4>{upperFirst(language)} Version</h4>
      <div className={`${language}-content`}>
        <InputText
          alignRight={false}
          label={`${capitalize(language)} Version HTML`}
          name="defaultTemplate"
          placeholder={placeholders.defaultTemplate}
          value={get(item, `${language}.defaultTemplate`, "")}
          onChange={e =>
            handleTextChange(e.target.name, e.target.value, language)
          }
          textarea
        />

        <div className="slider-checkboxes">
          {sliderCheckboxes.map(({ name, label }) => {
            return (
              <InputCheckbox
                key={name}
                name={name}
                label={label}
                value={get(item, `${language}.${name}`, false)}
                onChange={e =>
                  handleTextChange(e.target.name, e.target.checked, language)
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
