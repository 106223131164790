import {
  ADD_STATIC_PAGE_TYPE,
  ADD_STATIC_SEO,
  ADD_CK_STATIC_CONTENT,
  STATIC_FETCH_PAGES,
  RESET_STATIC_PAGES,
  RESET_STATIC_ADDPAGE_CONTENT,
  SEO_UPDATE_STATIC,
  ADD_CK_STATIC_ACCORD_CONTENT,
  ADD_CK_STATIC_ACCORD_TILE,
  REMOVE_CK_STATIC_ACCORD_TILE,
  STATIC_TOGGLE_RESET,
  STATIC_CANCEL_ACTION,
  UPDATE_STATIC_SEO,
  UPDATE_CK_STATIC_CONTENT,
  UPDATE_CK_STATIC_ACCORD_CONTENT,
  UPDATE_CK_STATIC_ACCORD_TILE,
  UPDATE_REMOVE_CK_STATIC_ACCORD_TILE,
  UPDATE_STATIC_PAGE_TYPE,
  RESET_UPDATE_STATIC_PAGE
} from "../constants/actions";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
  toastMsg
} from "./common.action";
import {
  deleteWebApi,
  fetchWebApi,
  postDashboardWebApi,
  putDashboardWebApi
} from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import { ENV_CONFIG } from "../config/env";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../constants";
import { setSubmitState } from "./common.action";

export const addPageType = (name, value) => {
  return { type: ADD_STATIC_PAGE_TYPE, name, value };
};
export const updatePageType = (name, value, pageType, pageLocation) => {
  return { type: UPDATE_STATIC_PAGE_TYPE, name, value, pageType, pageLocation };
};

export const addStaticSeo = (name, value, lang) => {
  return { type: ADD_STATIC_SEO, name, value, lang };
};
export const updateStaticSeo = (name, value, lang, pageType, pageLocation) => {
  return { type: UPDATE_STATIC_SEO, name, value, lang, pageType, pageLocation };
};

export const addCkStaticContent = (name, value, lang) => {
  return { type: ADD_CK_STATIC_CONTENT, name, value, lang };
};
export const updateCkStaticContent = (
  name,
  value,
  lang,
  pageType,
  pageLocation
) => {
  return {
    type: UPDATE_CK_STATIC_CONTENT,
    name,
    value,
    lang,
    pageType,
    pageLocation
  };
};

export const addCkStaticAccordContent = (name, value, lang, pos) => {
  return { type: ADD_CK_STATIC_ACCORD_CONTENT, name, value, lang, pos };
};
export const updateCkStaticAccordContent = (
  name,
  value,
  lang,
  pos,
  pageType,
  pageLocation
) => {
  return {
    type: UPDATE_CK_STATIC_ACCORD_CONTENT,
    name,
    value,
    lang,
    pos,
    pageType,
    pageLocation
  };
};

export const addCkStaticAccordTile = pos => {
  return { type: ADD_CK_STATIC_ACCORD_TILE, pos };
};
export const updateCkStaticAccordTile = (pos, pageType, pageLocation) => {
  return { type: UPDATE_CK_STATIC_ACCORD_TILE, pos, pageType, pageLocation };
};

export const removeCkStaticAccordTile = pos => {
  return { type: REMOVE_CK_STATIC_ACCORD_TILE, pos };
};
export const updateRemoveCkStaticAccordTile = (pos, pageType, pageLocation) => {
  return {
    type: UPDATE_REMOVE_CK_STATIC_ACCORD_TILE,
    pos,
    pageType,
    pageLocation
  };
};

export const filterPage = (
  pageName,
  pageObj,
  componentToUpdate = "staticAddPageContent"
) => {
  return { type: STATIC_FETCH_PAGES, pageName, pageObj, componentToUpdate };
};

export const resetStaticPages = () => {
  return { type: RESET_STATIC_PAGES };
};
export const resetUpdateStaticPage = () => {
  return { type: RESET_UPDATE_STATIC_PAGE };
};
export const toggleReset = value => ({ type: STATIC_TOGGLE_RESET, value });

export const fetchStaticPages = countryId => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/static?&countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    // dispatch(resetAddPageContent());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.status === 200) {
          filterAllPages(response.data.data, dispatch);
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const filterAllPages = ({ result }, dispatch) => {
  dispatch(resetStaticPages());
  result.map(page => {
    page.pageType === "html"
      ? dispatch(filterPage("Html", page))
      : page.pageType === "accordion"
      ? dispatch(filterPage("Accordion", page))
      : page.pageType.match(/comb/gi)
      ? dispatch(filterPage("Comb_accordion", page))
      : null;
  });
};

export const resetAddPageContent = () => {
  return { type: RESET_STATIC_ADDPAGE_CONTENT };
};

export const submitStaticPage = (currentTab, bodyData, countryId) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/static/add`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(toastMsg("success", SUCCESS_MESSAGES[currentTab]));
          // dispatch(resetAddPageContent());
          dispatch(setSubmitState(false));
          dispatch(toggleReset(true));
          dispatch(fetchStaticPages(countryId));
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message ||
                response.data.error.detail ||
                ERROR_MESSAGES[currentTab]
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateStaticPage = (
  currentTab,
  id,
  bodyData,
  countryId,
  tempsave
) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/static/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      bodyData,
      tempsave
    )
      .then(response => {
        if (response.data.code === 200) {
          dispatch(setSubmitState(false));
          dispatch(fetchStaticPages(countryId));
          dispatch(toastMsg("success", SUCCESS_MESSAGES[currentTab]));
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message ||
                response.data.error.detail ||
                ERROR_MESSAGES[currentTab]
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

// const removeStaticPage = (data, count) => ({type: DELETE_STATIC_PAGE, data});

export const deleteStaticPage = (id, CountryId) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/static/${id}?countryId=${CountryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        dispatch(hideLoadingIndicator());
        dispatch(fetchStaticPages(CountryId));
        dispatch(resetAddPageContent());
        dispatch(setSubmitState(false));
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const seoUpdateStatic = (
  name,
  value,
  language,
  pageType,
  pageLocation
) => {
  return {
    type: SEO_UPDATE_STATIC,
    name,
    value,
    language,
    pageType,
    pageLocation
  };
};

export const cancelAction = currentTab => {
  return { type: STATIC_CANCEL_ACTION, currentTab };
};
