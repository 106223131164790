import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import { ENV_CONFIG } from "../../config/env";
import {
  SECTIONS_PAGE_PAGETYPE,
  MESSAGES
} from "../../constants";
import { Button, Tooltip, Box } from "../../materialUi/components";
import UpdatePageContent from "./updatePageContent";
import ConfirmModal from "../modal/ConfirmModal";
import SavePreviewModal from "../modal/SavePreviewModal";
import { ApplyDropDown } from "../../component/applyDropDown";
import Footer from "../../component/footer";
import {
  addNewComponent,
  resetSectionsContent,
  toggleReset,
  updateSection
} from "../../actions/sectionsPage.action";
import { resetCountryId } from "../../actions/selectedCountyId.action";

class Content extends Component {
  state = {
    confirmModal: false,
    confirmPreviewModal: false,
    confirmPreviewSubmitModal: false
  };

  UNSAFE_componentWillReceiveProps({
    toggleReset,
    updatePageContent,
    initialActiveSection,
    resetToggled
  }) {
    let clonedPagesContent = cloneDeep(updatePageContent);
    let clonedInitialSectionsContent = cloneDeep(initialActiveSection);

    if (updatePageContent.content) {
      clonedPagesContent.content = clonedPagesContent.content.map(section =>
        omit(section, ["openAccordion"])
      );

      clonedInitialSectionsContent.content = clonedInitialSectionsContent.content.map(
        section => omit(section, ["openAccordion"])
      );
    }

    if (
      !isEqual(clonedPagesContent, clonedInitialSectionsContent) &&
      !resetToggled
    ) {
      toggleReset(true);
    } else if (
      isEqual(clonedPagesContent, clonedInitialSectionsContent) &&
      resetToggled
    ) {
      toggleReset(false);
    }
  }

  handleReset = () => {
    const { confirmModal } = this.state;
    this.setState({ confirmModal: !confirmModal });
  };

  hideModal = () =>
    this.setState(prevProps => ({
      confirmModal: !prevProps.confirmModal,
      storeTempSelectedTab: ""
    }));

  hidePreviewModal = () => {
    const { resetSectionsContent } = this.props;
    this.setState(prevProps => ({
      confirmPreviewModal: !prevProps.confirmPreviewModal
    }));
    resetSectionsContent();
  };

  hidePreviewSubmitModal = () =>
    this.setState(prevProps => ({
      confirmPreviewSubmitModal: !prevProps.confirmPreviewSubmitModal
    }));

  confirmModal = () => {
    const { confirmModal } = this.state;
    const { resetSectionsContent } = this.props;

    resetSectionsContent();

    this.setState({ confirmModal: !confirmModal });
  };

  confirmPreviewModal = () => {
    const { confirmPreviewModal } = this.state;
    const {
      staticAddPageContent,
      updatePageContent,
      resetAddPageContent,
      storeList,
      storeId,
      countryId
    } = this.props;
    let selectedCountry = null,
      defaultCountry = null;
    storeList.forEach(store => {
      if (store.storeId === storeId) {
        store.countries.forEach(country => {
          if (country.countryId === countryId) {
            selectedCountry = country;
          }
          if (country.countrySHORT.toLowerCase() === "ae") {
            defaultCountry = country;
          }
        });
      }
    });
    this.setState({ confirmPreviewModal: !confirmPreviewModal }, () => {
      window.open(
        `${ENV_CONFIG.redirectionUrl}-${selectedCountry
          ? selectedCountry.countrySHORT.toLowerCase()
          : defaultCountry.countrySHORT.toLowerCase()
        }${(staticAddPageContent && staticAddPageContent.url) ||
        updatePageContent.url
        }?prevMode=true`,
        "_blank"
      );
    });
  };

  confirmPreviewSubmitModal = (tempsave = false, currentTab) => {
    const {
      countryId,
      updateStaticPage,
      updatePageContent,
      staticPages,
      updatePageIndex,
      updateContent
    } = this.props;
    updateStaticPage(
      currentTab,
      updatePageContent._id || updatePageContent.id,
      staticPages[SECTIONS_PAGE_PAGETYPE[updatePageContent.pageType]][
      updatePageIndex
      ],
      countryId,
      tempsave
    ).then(resp => {
      if (resp.data.code === 200) {
        updateContent(resp.data.data.result);
      }
      this.setState({ confirmPreviewSubmitModal: false });
    });
  };

  handleUpdatePage = (tempsave = false) => {
    const { updatePageContent, updateSection, resetCountryId } = this.props;

    updateSection(updatePageContent, tempsave);
    resetCountryId();
  };
  handleSubmit = (tempsave = false) => {
    this.handleUpdatePage(tempsave);
    this.setState({ confirmPreviewModal: tempsave });
  };

  render() {
    const {
      confirmModal,
      confirmPreviewModal,
      confirmPreviewSubmitModal
    } = this.state;
    const {
      currentTab,
      updatePageContent,
      updatePageIndex,
      resetToggled,
      changeTab,
      selectedCountryId,
      accessRights
    } = this.props;

    let contentToDisplay;

    // switch (currentTab) {
    //   case SECTIONS_PAGE_PANEL.SECTIONS_ADD_PAGE_DISPLAY:
    //   case SECTIONS_PAGE_PANEL.SECTIONS_DELETE_PAGE_DISPLAY:
    //     contentToDisplay = <AddPageContent />;
    //     break;
    //   case SECTIONS_PAGE_PANEL.SECTIONS_UPDATE_PAGE_DISPLAY:
    //     contentToDisplay = (
    //       <UpdatePageContent
    //         updatePageContent={updatePageContent}
    //         updatePageIndex={updatePageIndex}
    //         changeTab={changeTab}
    //       />
    //     );
    // }
    return (
      <div className="content-container-wrapper">
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={"Are you sure you want to cancel ?"}
          />
        )}
        {confirmPreviewModal && (
          <SavePreviewModal
            modalName={{}}
            onHide={this.hidePreviewModal}
            confirm={this.confirmPreviewModal}
            message={"Do you want to see the changes?"}
          />
        )}
        {/* {confirmPreviewModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewModal}
            confirm={this.confirmPreviewModal}
            message={'Do you want to see the changes?'}
          />
        )} */}
        {confirmPreviewSubmitModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewSubmitModal}
            confirm={() => this.confirmPreviewSubmitModal(false, currentTab)}
            message={
              "There are some unsaved changes, Kindly save it otherwise all the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        {/* <div>{contentToDisplay && contentToDisplay}</div> */}
        <div>
          {!_.isEmpty(updatePageContent) && (
            <UpdatePageContent
              updatePageContent={updatePageContent}
              updatePageIndex={updatePageIndex}
              changeTab={changeTab}
              accessRights={accessRights}
            />
          )}
        </div>
        <Footer>
          <Box sx={{ "display": "flex" }}>
            <Box className="footer" sx={{ "width": "50%" }}>
              <Box className="footer-wrapper catalog">
                <ApplyDropDown onlyCountries style={{ "justifyContent": "flex-start" }} />
              </Box>
            </Box>
            <Button
              onClick={this.handleReset}
              disabled={!resetToggled}
              sx={{ "marginLeft": "auto" }}
            >
              Cancel
            </Button>
            <Tooltip title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
              <span>
                <Button
                  onClick={
                    () => this.handleSubmit(true)
                  }
                  disabled={
                    (!resetToggled && selectedCountryId.length === 1) ||
                    !accessRights.UPDATE
                  }
                  variant="contained"
                  sx={{ "marginLeft": "20px", "marginRight": "20px" }}
                >
                  Save & Preview
                </Button>
              </span>
            </Tooltip>
            <Tooltip title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
              <span>
                <Button
                  onClick={
                    () => this.handleSubmit(false)
                  }
                  disabled={
                    updatePageContent.isPublished || !accessRights.UPDATE
                  }
                  variant="contained"
                >
                  Publish
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeId: state.store.storeId,
  countryId: state.store.countryId,
  storeList: state.store.storeList,
  isSubmitEnabled: state.common.isSubmitEnabled,
  staticAddPageContent: state.sectionsReducer.initialData,
  staticPages: state.sectionsReducer.initialData,
  resetDisabled: state.sectionsReducer.resetDisabled,
  staticPageR: state.sectionsReducer,
  activePage: state.sectionsReducer.activePage,
  initialActiveSection: state.sectionsReducer.initialActiveSection,
  resetToggled: state.sectionsReducer.resetToggled,
  selectedCountryId: state.selectedCountryId
});

const mapDispatchToProps = dispatch => ({
  // cancelAction: bindActionCreators(cancelAction, dispatch),
  // submitStaticPage: bindActionCreators(submitStaticPage, dispatch),
  // updateStaticPage: bindActionCreators(updateStaticPage, dispatch),
  // resetAddPageContent: bindActionCreators(resetAddPageContent, dispatch),
  // resetUpdateStaticPage: bindActionCreators(resetUpdateStaticPage, dispatch),
  resetSectionsContent: bindActionCreators(resetSectionsContent, dispatch),
  toggleReset: bindActionCreators(toggleReset, dispatch),
  addNewComponent: bindActionCreators(addNewComponent, dispatch),
  updateSection: bindActionCreators(updateSection, dispatch),
  resetCountryId: bindActionCreators(resetCountryId, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
