import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { MESSAGES, ERROR_MESSAGES, DEVICE_TYPES_CONFIG, customInputTypes } from "../../constants";
import { Button, Tooltip, Box } from "../../materialUi/components";
import UpdatePageContent from "./updatePageContent";
import ConfirmModal from "../modal/ConfirmModal";

import { ApplyDropDown } from "../../component/applyDropDown";
import Footer from "../../component/footer";
import {
  updateConfiguration,
  toggleReset,
  resetConfigurationsContent,
  resetConfigurations,
  resetDatas
} from "../../actions/configurationsPage.action";
import { resetCountryId } from "../../actions/selectedCountyId.action";
import { toastMsg } from "../../actions/common.action";

class Content extends Component {
  state = {
    confirmModal: false,
    confirmPreviewModal: false,
    confirmPreviewSubmitModal: false
  };

  UNSAFE_componentWillReceiveProps({
    initialActiveSection,
    activePage,
    resetData
  }) {
    let clonedPagesContent = cloneDeep(activePage);
    let clonedInitialSectionsContent = cloneDeep(initialActiveSection);

    if (
      !isEqual(clonedPagesContent, clonedInitialSectionsContent) &&
      !resetData
    ) {
      //toggleReset(true);
      resetDatas(true);
    } else if (
      isEqual(clonedPagesContent, clonedInitialSectionsContent) &&
      resetData
    ) {
      //toggleReset(false);
      resetDatas(false);
    }
  }

  handleReset = () => {
    const { confirmModal } = this.state;
    this.setState({ confirmModal: !confirmModal });
  };

  hideModal = () =>
    this.setState(prevProps => ({
      confirmModal: !prevProps.confirmModal,
      storeTempSelectedTab: ""
    }));

  // hidePreviewModal = () => {
  //   const { resetAddPageContent } = this.props;
  //   this.setState(prevProps => ({
  //     confirmPreviewModal: !prevProps.confirmPreviewModal
  //   }));
  //   resetAddPageContent();
  // };

  // hidePreviewSubmitModal = () =>
  //   this.setState(prevProps => ({
  //     confirmPreviewSubmitModal: !prevProps.confirmPreviewSubmitModal
  //   }));

  confirmModal = () => {
    const { confirmModal } = this.state;
    const { resetConfigurationsContent } = this.props;
    resetConfigurationsContent();

    this.setState({ confirmModal: !confirmModal });
  };

  // confirmPreviewModal = () => {
  //   const { confirmPreviewModal } = this.state;
  //   const {
  //     staticAddPageContent,
  //     updatePageContent,
  //     resetAddPageContent,
  //     storeList,
  //     storeId,
  //     countryId
  //   } = this.props;
  //   let selectedCountry = null,
  //     defaultCountry = null;
  //   storeList.forEach(store => {
  //     if (store.storeId === storeId) {
  //       store.countries.forEach(country => {
  //         if (country.countryId === countryId) {
  //           selectedCountry = country;
  //         }
  //         if (country.countrySHORT.toLowerCase() === "ae") {
  //           defaultCountry = country;
  //         }
  //       });
  //     }
  //   });
  //   this.setState({ confirmPreviewModal: !confirmPreviewModal }, () => {
  //     window.open(
  //       `${ENV_CONFIG.redirectionUrl}-${selectedCountry
  //         ? selectedCountry.countrySHORT.toLowerCase()
  //         : defaultCountry.countrySHORT.toLowerCase()
  //       }${(staticAddPageContent && staticAddPageContent.url) ||
  //       updatePageContent.url
  //       }?prevMode=true`,
  //       "_blank"
  //     );
  //     resetAddPageContent();
  //   });
  // };

  // confirmPreviewSubmitModal = (tempsave = false, currentTab) => {
  //   const {
  //     countryId,
  //     updateStaticPage,
  //     updatePageContent,
  //     staticPages,
  //     updatePageIndex,
  //     updateContent
  //   } = this.props;
  //   updateStaticPage(
  //     currentTab,
  //     updatePageContent._id || updatePageContent.id,
  //     staticPages[CONFIGURATIONS_PAGE_PANEL[updatePageContent.pageType]][
  //     updatePageIndex
  //     ],
  //     countryId,
  //     tempsave
  //   ).then(resp => {
  //     if (resp.data.code === 200) {
  //       updateContent(resp.data.data.result);
  //     }
  //     this.setState({ confirmPreviewSubmitModal: false });
  //   });
  // };

  handleUpdatePage = tempsave => {
    const { resetCountryId, updateConfiguration } = this.props;
    updateConfiguration(tempsave);
    resetCountryId();
  };

  isValidPageData = () => {
    const { activePage: { children = {} } = {} } = this.props;
    for (let device in children) {
      if (device === DEVICE_TYPES_CONFIG[0].key) {
        const isInValid =
          children[device] &&
          children[device].some(
            deviceData =>
              !deviceData.english.key ||
              (!deviceData.english.value && deviceData.english.type != customInputTypes.MEDIA) ||
              !deviceData.arabic.key ||
              (!deviceData.arabic.value && deviceData.arabic.type != customInputTypes.MEDIA) ||
              (deviceData.english.type === customInputTypes.MEDIA && !JSON.parse(deviceData.english.value)) ||
              (deviceData.arabic.type === customInputTypes.MEDIA && !JSON.parse(deviceData.arabic.value)) ||
              (deviceData.english.type === customInputTypes.MEDIA && !JSON.parse(deviceData.english.value).redirectionLink) ||
              (deviceData.arabic.type === customInputTypes.MEDIA && !JSON.parse(deviceData.arabic.value).redirectionLink)
          )
        if (isInValid) return false;
      } else {
        for (let platform in children[device]) {
          const isInValid =
            children[device] &&
            children[device][platform] &&
            children[device][platform].some(
              deviceData =>
                !deviceData.english.key ||
                (!deviceData.english.value && deviceData.english.type != customInputTypes.MEDIA) ||
                !deviceData.arabic.key ||
                (!deviceData.arabic.value && deviceData.arabic.type != customInputTypes.MEDIA) ||
                (deviceData.english.type === customInputTypes.MEDIA && !JSON.parse(deviceData.english.value)) ||
                (deviceData.arabic.type === customInputTypes.MEDIA && !JSON.parse(deviceData.arabic.value)) ||
                (deviceData.english.type === customInputTypes.MEDIA && !JSON.parse(deviceData.english.value).redirectionLink) ||
                (deviceData.arabic.type === customInputTypes.MEDIA && !JSON.parse(deviceData.arabic.value).redirectionLink)
            );
          if (isInValid) return false;
        }
      }
    }
    return true;
  };

  handleSubmit = (tempsave = false) => {
    const { toastMsg } = this.props;
    const isValidPage = this.isValidPageData();
    if (isValidPage) {
      this.handleUpdatePage(tempsave);
    } else {
      toastMsg("error", ERROR_MESSAGES.configurationPageValidation);
    }
  };

  render() {
    const {
      confirmModal,
      confirmPreviewModal,
      confirmPreviewSubmitModal
    } = this.state;
    const {
      currentTab,
      updatePageContent,
      updatePageIndex,
      changeTab,
      selectedCountryId,
      resetData,
      rollbackSave,
      accessRights
    } = this.props;

    return (
      <div className="content-container-wrapper">
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={"Are you sure you want to cancel ?"}
          />
        )}
        {/* {confirmPreviewModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewModal}
            confirm={this.confirmPreviewModal}
            message={"Do you want to see the changes?"}
          />
        )} */}
        {/* {confirmPreviewSubmitModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewSubmitModal}
            confirm={() => this.confirmPreviewSubmitModal(false, currentTab)}
            message={
              "There are some unsaved changes, Kindly save it otherwise all the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )} */}
        {!_.isEmpty(updatePageContent) && (
          <UpdatePageContent
            updatePageContent={updatePageContent}
            updatePageIndex={updatePageIndex}
            changeTab={changeTab}
            accessRights={accessRights}
          />
        )}
        <Footer>
          <Box sx={{ "display": "flex" }}>
            <Box className="footer" sx={{ "width": "50%" }}>
              <Box className="footer-wrapper catalog">
                <ApplyDropDown onlyCountries style={{ "justifyContent": "flex-start" }} />
              </Box>
            </Box>
            <Button
              onClick={this.handleReset}
              disabled={!resetData}
              sx={{ "marginLeft": "auto","marginRight":"20px" }}
            >
              Cancel
            </Button>
            <Tooltip title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
              <span>
                <Button
                  onClick={
                    () => this.handleSubmit(true)
                  }
                  disabled={
                    (!resetData &&
                      selectedCountryId.length === 1 &&
                      !rollbackSave) ||
                    !accessRights.UPDATE
                  }
                  variant="contained"
                >
                  Save Changes
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeId: state.store.storeId,
  countryId: state.store.countryId,
  storeList: state.store.storeList,
  isSubmitEnabled: state.common.isSubmitEnabled,
  staticPageR: state.configurationsReducer,
  activePage: state.configurationsReducer.activePage,
  initialActiveSection: state.configurationsReducer.initialActiveSection,
  initialActivePage: state.configurationsReducer.initialActivePage,
  resetToggled: state.configurationsReducer.resetToggled,
  selectedCountryId: state.selectedCountryId,
  resetData: state.configurationsReducer.resetData,
  rollbackSave: state.configurationsReducer.rollbackSave
});

const mapDispatchToProps = dispatch => ({
  toggleReset: bindActionCreators(toggleReset, dispatch),
  resetDatas: bindActionCreators(resetDatas, dispatch),
  updateConfiguration: bindActionCreators(updateConfiguration, dispatch),
  resetConfigurationsContent: bindActionCreators(
    resetConfigurationsContent,
    dispatch
  ),
  resetConfigurations: bindActionCreators(resetConfigurations, dispatch),
  resetCountryId: bindActionCreators(resetCountryId, dispatch),
  toastMsg: bindActionCreators(toastMsg, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
