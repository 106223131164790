import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  fetchNavMenuManagementData,
  addNavMenuData,
  updateNavMenuData,
  deleteNavMenuData
} from "../../actions/navMenuManagement.action";
import { toastMsg, changeTab } from "../../actions/common.action";
import NavMenuList from "./navMenuList";
import MenuForm from "./menuForm";
import ConfirmModal from "../modal/ConfirmModal";
import { NAVIGATION, NAVIGATION_MENUS_SLUGS, MESSAGES } from "../../constants";

const NavMenuManagement = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState({});

  const [activeRowDetails, setActiveRowDetails] = useState({
    menuId: "",
    subMenuId: "",
    data: null
  });

  const [slugDetails, setSlugDetails] = useState({});

  const navMenuList = useSelector(state => state.navMenuManagement.navMenuList);

  const user = useSelector(state => state.authentication.user);

  const {
    permissions: {
      [NAVIGATION_MENUS_SLUGS.NAV_MENU_MANAGEMENT]: { accessRights = {} } = {}
    } = {}
  } = user || {};

  const toggleModal = modalName => {
    setShowModal({
      ...showModal,
      [modalName]: !showModal[modalName]
    });
  };

  const handleSubMenuModal = (navItem, modalName, activeRowInfo) => {
    setActiveRowDetails({ ...activeRowDetails, ...activeRowInfo });
    toggleModal(modalName);
  };

  const loadData = () => {
    dispatch(fetchNavMenuManagementData());
  };

  const createSlugObject = (navMenuList = [], slugObject = {}) => {
    for (let i = 0; i < navMenuList.length; i++) {
      slugObject[navMenuList[i].slug] = navMenuList[i]._id;
      createSlugObject(navMenuList[i].children, slugObject);
    }
    return slugObject;
  };

  useEffect(() => {
    if (!navMenuList || !navMenuList.length) loadData();
    dispatch(changeTab(NAVIGATION.NAV_MENU_MANAGEMENT));
  }, []);

  useEffect(() => {
    let slugObject = createSlugObject(navMenuList, {});
    setSlugDetails({ ...slugObject });
  }, [navMenuList]);

  const addNewNavMenu = data => {
    const { slug } = data || {};
    if (slugDetails[slug]) {
      return dispatch(toastMsg("error", "Slug name already exist"));
    }
    dispatch(addNavMenuData(data));
    toggleModal("addNewNavMenuModal");
  };

  const getUpdatedSubNavMenuData = (
    subMenuId,
    data,
    navMenuList = [],
    mode
  ) => {
    let result = false;
    for (let i = 0; i < navMenuList.length; i++) {
      if (navMenuList[i]._id === subMenuId) {
        if (mode === "addSubMenu") {
          if (!navMenuList[i].children) navMenuList[i].children = [];
          navMenuList[i].children.push({ ...data });
        } else if (mode === "update") {
          navMenuList[i] = { ...navMenuList[i], ...data };
        } else if (mode === "delete") {
          navMenuList.splice(i, 1);
        }
        return true;
      } else {
        result = getUpdatedSubNavMenuData(
          subMenuId,
          data,
          navMenuList[i].children,
          mode
        );
        if (result) return true;
      }
    }
    return false;
  };

  const getUpdatedNavMenuData = (
    menuId,
    subMenuId,
    data,
    navMenuList = [],
    mode
  ) => {
    let navMenuListCloned = JSON.parse(JSON.stringify(navMenuList));
    let index = navMenuListCloned.findIndex(list => {
      return list._id === menuId;
    });
    if (index !== -1) {
      if (menuId === subMenuId) {
        if (mode === "addSubMenu") {
          if (!navMenuListCloned[index].children)
            navMenuListCloned[index].children = [];
          navMenuListCloned[index].children.push({ ...data });
        } else if (mode === "update") {
          navMenuListCloned[index] = { ...navMenuListCloned[index], ...data };
        } else if (mode === "delete") {
          // navMenuListCloned.splice(index, 1);
        }
      } else {
        getUpdatedSubNavMenuData(
          subMenuId,
          data,
          navMenuListCloned[index].children,
          mode
        );
      }
      return navMenuListCloned[index];
    }
    return null;
  };

  const addNewSubMenu = data => {
    const { slug } = data || {};
    if (slugDetails[slug]) {
      return dispatch(toastMsg("error", "Slug name already exist"));
    }
    const { menuId, subMenuId } = activeRowDetails;
    let finalData = getUpdatedNavMenuData(
      menuId,
      subMenuId,
      data,
      navMenuList,
      "addSubMenu"
    );
    if (finalData) {
      dispatch(updateNavMenuData(finalData, "Sub menu created successfully"));
    }
    toggleModal("addNewSubMenuModal");
    setActiveRowDetails({
      menuId: "",
      subMenuId: "",
      data: null
    });
  };

  const updateMenu = data => {
    const { slug } = data || {};
    const { menuId, subMenuId } = activeRowDetails;
    if (slugDetails[slug] && slugDetails[slug] !== subMenuId) {
      return dispatch(toastMsg("error", "Slug name already exist"));
    }
    let finalData = getUpdatedNavMenuData(
      menuId,
      subMenuId,
      data,
      navMenuList,
      "update"
    );
    if (finalData) {
      dispatch(updateNavMenuData(finalData, "Menu updated successfully"));
    }
    toggleModal("updateMenuModal");
    setActiveRowDetails({
      menuId: "",
      subMenuId: "",
      data: null
    });
  };

  const deleteMenu = () => {
    const { menuId, subMenuId } = activeRowDetails;
    if (menuId === subMenuId) {
      dispatch(deleteNavMenuData(menuId));
    } else {
      let finalData = getUpdatedNavMenuData(
        menuId,
        subMenuId,
        null,
        navMenuList,
        "delete"
      );
      if (finalData) {
        dispatch(updateNavMenuData(finalData, "Deleted successfully"));
      }
    }
    toggleModal("deleteMenuModal");
    setActiveRowDetails({
      menuId: "",
      subMenuId: "",
      data: null
    });
  };

  return (
    <Fragment>
      {showModal["addNewNavMenuModal"] && (
        <MenuForm
          modalName={"addNewNavMenuModal"}
          handleModal={toggleModal}
          submitHandler={addNewNavMenu}
          headerText={"Add New Nav Menu"}
          ConfirmButtonText={"Create Nav Menu"}
          data={{}}
        />
      )}
      {showModal["addNewSubMenuModal"] && (
        <MenuForm
          modalName={"addNewSubMenuModal"}
          handleModal={toggleModal}
          submitHandler={addNewSubMenu}
          headerText={"Add New Sub Menu"}
          ConfirmButtonText={"Create Sub Menu"}
          data={{}}
        />
      )}
      {showModal["updateMenuModal"] && (
        <MenuForm
          modalName={"updateMenuModal"}
          handleModal={toggleModal}
          submitHandler={updateMenu}
          headerText={"Update Menu"}
          ConfirmButtonText={"Update Menu"}
          data={activeRowDetails.data || {}}
        />
      )}
      {showModal["deleteMenuModal"] && (
        <ConfirmModal
          modalName={"deleteMenuModal"}
          onHide={toggleModal}
          confirm={deleteMenu}
          message={"Are you sure you want to delete ?"}
        />
      )}
      <div className="row user-management-container">
        <div className="col-lg-12 m-b-5">
          <span className="total-user-text col-sm-6">Nav Menu Management</span>
          <span
            className="col-sm-2 pull-right"
            data-tip={accessRights.WRITE ? "" : MESSAGES.TOOLTIP_WRITE}
            data-for="nav-menu-management"
          >
            <button
              className="round-btn"
              onClick={
                accessRights.WRITE
                  ? () => toggleModal("addNewNavMenuModal")
                  : null
              }
              disabled={!accessRights.WRITE}
            >
              <span className="btn-text">{"Add"}</span>
            </button>
          </span>
          <div className="col-sm-2 pull-right"></div>
        </div>
        <NavMenuList
          navMenus={navMenuList}
          handleSubMenuModal={handleSubMenuModal}
          accessRights={accessRights}
        />
        <ReactTooltip place="top" effect="solid" id="nav-menu-management" />
      </div>
    </Fragment>
  );
};

export default NavMenuManagement;
