import React, { useState, useRef } from "react";
import {
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Button,
  TextField, InputAdornment, Divider,
} from '../../materialUi/components';

import { ERROR_MESSAGES, IMG_SIZE_IN_BYTES } from '../../constants'
import { FileUploadIcon, CloseIcon } from '../../materialUi/icons'
import { useDispatch, useSelector } from "react-redux";
import { uploadImage, snackbar } from "../../actions/common.action";

export const ImageSelectionModal = ({ open, handleClose, handleSave, title, dialogContext, language, ...props }) => {
  const regex = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;
  const [uploadedFile, setUploadedFile] = useState("");
  const [cdnURL, setCdnURL] = useState("");
  const [validCdnUrl, setValidCdnUrl] = useState(false);
  const brandId = useSelector(state => state.store.brandId || state.store.storeId);
  const storeId = useSelector(state => state.store.storeId);
  const countryId = useSelector(state => state.store.countryId);
  const inputFileRef = useRef();
  const dispatch = useDispatch();
  const handleFiles = (event) => {
    if (!(event?.target?.files?.[0])) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.fileUploadError }));
    }
    else if (event.target.files[0].size / 1024 ** 2 > 10) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.fileMaxSize }));
    } else if (event.target.files[0].type.split("/")[0] !== "image") {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.imageFileTypeError }));
    } else if (event.target.files[0].type.split("/")[1].indexOf("tif") !== -1) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.tiffFormatError }));
    } else {
      setUploadedFile(event.target.files);
    }
  }
  const handleChange = (event) => {
    setCdnURL(event.target.value);
    setValidCdnUrl(event.target.value.match(regex))
  }
  const onSave = () => {
    const {
      keyName,
      containerName
    } = props;

    if (!cdnURL && !(uploadedFile && uploadedFile[0].name)) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.noImageFileError }));

      return;
    } else if (cdnURL && !validCdnUrl) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.invalidImageLink }));
    } else if (
      uploadedFile &&
      (uploadedFile[0].type === "image/jpg" ||
        uploadedFile[0].type === "image/jpeg" ||
        uploadedFile[0].type === "image/png" ||
        uploadedFile[0].type === "image/gif" ||
        uploadedFile[0].type === "image/webp") &&
      uploadedFile[0].size >= IMG_SIZE_IN_BYTES.PNG_SIZE
    ) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.imageSizeError }));
    } else if (
      uploadedFile &&
      uploadedFile[0].type === "image/gif" &&
      uploadedFile[0].size >= IMG_SIZE_IN_BYTES.GIF_SIZE
    ) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.imageSizeError }));
    }

    if (cdnURL) {
      handleSave(
        `${cdnURL}?burst=${Date.now()}`,
        language,
      );
    } else if (
      uploadedFile &&
      (uploadedFile[0].type === "image/jpg" ||
        uploadedFile[0].type === "image/jpeg" ||
        uploadedFile[0].type === "image/png" ||
        uploadedFile[0].type === "image/gif" ||
        uploadedFile[0].type === "image/webp") &&
      uploadedFile[0].size <= IMG_SIZE_IN_BYTES.PNG_SIZE
    ) {
      let _formData = new FormData();
      _formData.append("file", uploadedFile[0]);

      let _brandId = brandId;

      if (containerName != "storeList") {
        _brandId = `${brandId}${+new Date()}`;
      }

      dispatch(uploadImage({
        brandId: _brandId,
        keyName,
        language,
        storeId,
        countryId,
        containerName,
        _formData
      })).then(res => {
        if (
          res.data &&
          res.data.code &&
          res.data.code == 200 &&
          res.data.data &&
          res.data.data.result &&
          res.data.data.result.url
        ) {
          handleSave(
            `${res.data.data.result.url}?burst=${Date.now()}`,
            language,
          );
        } else {
          dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.unableToUploadImg }));
          return;
        }
      });
    }
  }
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {dialogContext}
          </DialogContentText>
          <div style={{ "display": "flex" }}>
            <TextField
              autoFocus
              margin="dense"
              id="fileName"
              label="File Name"
              type="text"
              fullWidth
              value={uploadedFile?.[0]?.name || ""}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <Button onClick={() => setUploadedFile("")}>
                      <CloseIcon />
                    </Button>
                  </InputAdornment>
              }}
              aria-readonly={true}
              disabled={true}
            />
            <TextField
              id="uploadFile"
              type="file"
              fullWidth
              inputRef={inputFileRef}
              onChange={handleFiles}
              style={{ "display": "none" }}
            />
            <Button
              onClick={() => {
                inputFileRef.current.click()
              }}
              disabled={!!cdnURL}
            >
              <FileUploadIcon fontSize="large" />
            </Button>
          </div>
          <Divider variant="fullWidth" style={{ "marginBottom": "10px", "marginTop": "10px" }}>OR</Divider>
          <TextField
            autoFocus
            margin="dense"
            id="cdn"
            label="Image Link"
            type="text"
            fullWidth
            value={cdnURL || ""}
            onChange={handleChange}
            disabled={!!uploadedFile}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onSave}
            disabled={!!(!(cdnURL || uploadedFile) || (cdnURL && !validCdnUrl))}
          >Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}