import React from "react";
import { paddingInputs, marginInputs } from "../../constants/stylingModal";
import { TextField, Box, Typography } from "../../materialUi/components"

const SpacingControls = ({
  styles,
  handleSpacingChange,
  paddingStyle,
  marginStyle,
  CommonStyle
}) => {
  const updateStyles = (name, value) => {
    if (!Number.isInteger(value)) {
      return;
    }
    if (value > 30) value = 30;
    else if (value < 0) value = 0;

    handleSpacingChange({
      ...styles,
      [name]: value
    });
  };

  return (
    <Box sx={{ ...CommonStyle }}>
      <Box sx={{ ...paddingStyle }}>
        <Typography sx={{ width: "100%", "marginBottom": "10px" }}>Padding</Typography>
        <Box sx={{ width: "100%", display: "flex" }}>
          {paddingInputs.map(({ name, label }, idx) => (
            <TextField
              type="Number"
              key={idx}
              label={label}
              name={name}
              placeholder={label}
              value={styles[name]}
              onChange={e => updateStyles(name, +e.target.value)}
              size="small"
              sx={{ width: `${Math.floor(100 / paddingInputs.length)}%`, "marginRight": "5px" }}
              InputLabelProps={{ shrink: true }}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ ...marginStyle }}>
        <Typography sx={{ width: "100%", "marginBottom": "10px" }}>Margin</Typography>
        <Box sx={{ width: "100%", display: "flex" }}>
          {marginInputs.map(({ name, label }, idx) => (
            <TextField
              type="Number"
              key={idx}
              label={label}
              name={name}
              placeholder={label}
              value={styles[name]}
              onChange={e => updateStyles(name, +e.target.value)}
              size="small"
              sx={{ width: `${Math.floor(100 / marginInputs.length)}%`, "marginRight": "5px" }}
              InputLabelProps={{ shrink: true }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SpacingControls;
