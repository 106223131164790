import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  NEED_HELP_CATEGORY,
  NEED_HELP_PANEL,
  NEED_HELP_PANEL_MAP,
  NAVIGATION,
  NAVIGATION_MENUS_SLUGS,
  NEED_HELP_PANEL_API_MAP
} from "../../constants";
import RightPanel from "./rightPanel";
import { changeTab } from "../../actions/common.action";
import LeftPanel from "./leftPanel";
import { ApplyDropDown } from "../../component/applyDropDown";

import {
  changeCategory,
  handelRemoveNeedHelpAccordTile,
  handleAccordTextChange,
  handleNeedHelpAccordTile,
  handleAccordCheckBoxChange,
  fetchNeedHelpData,
  submitNeedHelpPage,
  resetNeedHelpContent,
  disableSubmitBtn,
  enableSubmitBtn,
  toggleResetBtn,
  handelUpdateNeedHelpAccordTilePosition
} from "../../actions/needHelp.action";
import isEqual from "lodash/isEqual";
import ConfirmModal from "../modal/ConfirmModal";
import { ENV_CONFIG } from "../../config/env";

class NeedHelp extends Component {
  state = {
    showContent: true,
    currentTab: NEED_HELP_PANEL.ORDER_ISSUES,
    category: NEED_HELP_PANEL.ORDER_ISSUES,
    confirmModal: false,
    storeTempSelectedTab: "",
    confirmPreviewModal: false,
    confirmPreviewSubmitModal: false
  };

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.NEED_HELP);
  }

  componentDidMount() {
    const { countryId, fetchNeedHelpData } = this.props;
    countryId && fetchNeedHelpData(countryId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryId, fetchNeedHelpData } = this.props;
    if (+nextProps.countryId !== +countryId) {
      nextProps.countryId && fetchNeedHelpData(nextProps.countryId);
    }
  }

  submitBtnValidation = () => {
    const {
      needHelpObject,
      needHelpKey,
      enableSubmitBtn,
      disableSubmitBtn,
      toggleResetBtn
    } = this.props;

    if (
      needHelpObject &&
      needHelpObject[needHelpKey] &&
      needHelpObject[needHelpKey].meta &&
      needHelpObject[needHelpKey].meta.english &&
      needHelpObject[needHelpKey].meta.english.title &&
      needHelpObject[needHelpKey].meta.english.metaKeyword &&
      needHelpObject[needHelpKey].meta.english.metaDescription &&
      needHelpObject[needHelpKey].meta.english.headingH1 &&
      needHelpObject[needHelpKey].meta.english.headingH2 &&
      needHelpObject[needHelpKey].meta.arabic &&
      needHelpObject[needHelpKey].meta.arabic.title &&
      needHelpObject[needHelpKey].meta.arabic.metaKeyword &&
      needHelpObject[needHelpKey].meta.arabic.metaDescription &&
      needHelpObject[needHelpKey].meta.arabic.headingH1 &&
      needHelpObject[needHelpKey].meta.arabic.headingH2 &&
      needHelpObject[needHelpKey].content &&
      needHelpObject[needHelpKey].content.length > 0
    ) {
      needHelpObject[needHelpKey].content.map(item => {
        if (
          item.english &&
          item.english.title &&
          item.english.longTitle &&
          item.english.answer &&
          item.arabic &&
          item.arabic.title &&
          item.arabic.longTitle &&
          item.arabic.answer
        ) {
          enableSubmitBtn();
        } else {
          disableSubmitBtn();
        }
      });
    } else {
      disableSubmitBtn();
    }

    if (
      isEqual(
        needHelpObject[needHelpKey],
        needHelpObject.apiContent[needHelpKey] ||
          needHelpObject[`${needHelpKey}Static`]
      ) &&
      isEqual(
        needHelpObject[needHelpKey].content,
        (needHelpObject.apiContent[needHelpKey] &&
          needHelpObject.apiContent[needHelpKey].content) ||
          (needHelpObject[`${needHelpKey}Static`] &&
            needHelpObject[`${needHelpKey}Static`].content)
      )
    ) {
      disableSubmitBtn();
      toggleResetBtn(false);
    } else {
      toggleResetBtn(true);
    }
  };

  changeTab = (e, key) => {
    const { needHelpObject, needHelpKey, changeCategory } = this.props;
    const { confirmModal } = this.state;
    if (
      !isEqual(
        needHelpObject[needHelpKey],
        needHelpObject.apiContent[needHelpKey] ||
          needHelpObject[`${needHelpKey}Static`]
      )
    ) {
      this.setState({
        confirmModal: !confirmModal,
        storeTempSelectedTab: e.target.id
      });
    } else {
      this.setState({
        currentTab:
          e && e.target && e.target.id
            ? e.target.id === "generalSeoDisplay"
              ? NEED_HELP_PANEL.ORDER_ISSUES
              : e.target.id
            : NEED_HELP_PANEL.ORDER_ISSUES
      });
      changeCategory(key);
      this.setState({ category: key || NEED_HELP_PANEL.ORDER_ISSUES });
    }
  };

  changeNav = () =>
    this.setState(prevState => ({ showContent: !prevState.showContent }));

  changeHandler = (index, needHelpKey, language, key, value) => {
    this.props.handleAccordTextChange(index, needHelpKey, language, key, value);
  };

  checkBoxChangeHandler = (index, needHelpKey, key, value) => {
    this.props.handleAccordCheckBoxChange(index, needHelpKey, key, value);
  };

  addNeedHelpAccordTile = pos => {
    const { needHelpKey } = this.props;
    this.props.handleNeedHelpAccordTile(pos, needHelpKey);
    setTimeout(() => {
      this.submitBtnValidation();
    }, 0);
  };

  removeNeedHelpAccordTile = (pos, cb) => {
    const { needHelpKey } = this.props;
    this.props.handelRemoveNeedHelpAccordTile(pos, needHelpKey);
    cb && cb();
    setTimeout(() => {
      this.submitBtnValidation();
    }, 0);
  };

  updateNeedHelpAccordTilePosition = (pos, indexValue) => {
    const { needHelpKey } = this.props;
    this.props.handelUpdateNeedHelpAccordTilePosition(
      pos,
      needHelpKey,
      indexValue
    );
    setTimeout(() => {
      this.submitBtnValidation();
    }, 300);
  };

  updateNeedHelpData = (tempsave = false) => {
    const {
      submitNeedHelpPage,
      needHelpKey,
      needHelpObject,
      countryId,
      disableSubmitBtn,
      toggleResetBtn,
      selectedCountryId
    } = this.props;

    if (
      !tempsave &&
      !isEqual(
        needHelpObject[needHelpKey],
        needHelpObject.apiContent[needHelpKey] ||
          needHelpObject[`${needHelpKey}Static`]
      )
    ) {
      this.setState({ confirmPreviewSubmitModal: true });
    } else {
      submitNeedHelpPage(
        countryId,
        needHelpKey,
        needHelpObject[needHelpKey],
        tempsave,
        selectedCountryId
      ).then(resp => {
        if (resp.data.code === 200 && tempsave) {
          disableSubmitBtn();
          toggleResetBtn(false);
          this.setState({ confirmPreviewModal: true });
        }
      });
    }
  };

  handleReset = () => {
    const { needHelpKey } = this.props;
    this.props.resetNeedHelpContent(needHelpKey);
    setTimeout(() => {
      this.submitBtnValidation();
    }, 0);
  };

  hideModal = () =>
    this.setState(prevProps => ({
      confirmModal: !prevProps.confirmModal,
      storeTempSelectedTab: ""
    }));

  confirmModal = () => {
    const { confirmModal, storeTempSelectedTab } = this.state;
    const { changeCategory } = this.props;
    this.setState({
      confirmModal: !confirmModal,
      currentTab: storeTempSelectedTab,
      storeTempSelectedTab: ""
    });
    changeCategory(storeTempSelectedTab);
    this.setState({ category: storeTempSelectedTab });
    this.handleReset();
  };

  hidePreviewModal = () =>
    this.setState(prevProps => ({
      confirmPreviewModal: !prevProps.confirmPreviewModal
    }));

  hidePreviewSubmitModal = () =>
    this.setState(prevProps => ({
      confirmPreviewSubmitModal: !prevProps.confirmPreviewSubmitModal
    }));

  confirmPreviewModal = () => {
    const { confirmPreviewModal } = this.state;
    const { needHelpKey, storeList, storeId, countryId } = this.props;
    let selectedCountry = null,
      defaultCountry = null;
    storeList.forEach(store => {
      if (store.storeId === storeId) {
        store.countries.forEach(country => {
          if (country.countryId === countryId) {
            selectedCountry = country;
          }
          if (country.countrySHORT.toLowerCase() === "ae") {
            defaultCountry = country;
          }
        });
      }
    });
    this.setState({ confirmPreviewModal: !confirmPreviewModal }, () => {
      window.open(
        `${ENV_CONFIG.redirectionUrl}-${
          selectedCountry
            ? selectedCountry.countrySHORT.toLowerCase()
            : defaultCountry.countrySHORT.toLowerCase()
        }/need-help/${NEED_HELP_PANEL_API_MAP[needHelpKey]}/?prevMode=true`,
        "_blank"
      );
    });
  };

  confirmPreviewSubmitModal = (tempsave = false) => {
    const {
      submitNeedHelpPage,
      needHelpKey,
      needHelpObject,
      countryId,
      selectedCountryId
    } = this.props;

    submitNeedHelpPage(
      countryId,
      needHelpKey,
      needHelpObject[needHelpKey],
      tempsave,
      selectedCountryId
    ).then(() => {
      this.setState({ confirmPreviewSubmitModal: false });
    });
  };

  render() {
    const {
      currentTab,
      showContent,
      category,
      confirmModal,
      confirmPreviewModal,
      confirmPreviewSubmitModal
    } = this.state;
    const {
      needHelpKey,
      needHelpObject,
      isSubmit,
      resetDisabled,
      storeId,
      countryId,
      selectedCountryName,
      user
    } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.NEED_HELP]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    let breadCrumb =
      (selectedCountryName &&
        `Need-help >  ${
          NEED_HELP_CATEGORY[NEED_HELP_PANEL_MAP[category]]
        }, ${selectedCountryName}`) ||
      `Need-help >  ${NEED_HELP_CATEGORY[NEED_HELP_PANEL_MAP[category]]}`;
    return (
      <Fragment>
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={
              "All the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        {confirmPreviewModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewModal}
            confirm={this.confirmPreviewModal}
            message={"Do you want to see the changes?"}
          />
        )}
        {confirmPreviewSubmitModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewSubmitModal}
            confirm={() => this.confirmPreviewSubmitModal(false)}
            message={
              "There are some unsaved changes, Kindly save it otherwise all the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        <div className="row m-t-10">
          <div className="component-header header-container">
            <div className="col-sm-3">
              <LeftPanel
                currentTab={currentTab}
                showContent={showContent}
                changeTab={this.changeTab}
                changeNav={this.changeNav}
                englishList={[]}
                arabicList={[]}
              />
            </div>
            <div className="col-sm-9">
              <h2 className="page-main-heading">
                <div className="row">
                  <div className="col-sm-5">{breadCrumb}</div>
                  <div className="col-sm-7">
                    <ApplyDropDown onlyCountries />
                  </div>
                </div>
              </h2>
              <RightPanel
                currentTab={currentTab}
                countryId={countryId}
                storeId={storeId}
                changeTab={this.changeTab}
                needHelpKey={needHelpKey}
                needHelpObject={needHelpObject}
                changeHandler={this.changeHandler}
                checkBoxChangeHandler={this.checkBoxChangeHandler}
                addNeedHelpAccordTile={this.addNeedHelpAccordTile}
                removeNeedHelpAccordTile={this.removeNeedHelpAccordTile}
                updateNeedHelpAccordTilePosition={
                  this.updateNeedHelpAccordTilePosition
                }
                updateNeedHelpData={this.updateNeedHelpData}
                handleReset={this.handleReset}
                isSubmit={isSubmit}
                resetDisabled={resetDisabled}
                submitBtnValidation={this.submitBtnValidation}
                accessRights={accessRights}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  needHelpKey: state.needHelp.needHelpKey,
  needHelpObject: state.needHelp,
  countryId: state.store.countryId,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  isSubmit: state.needHelp.isSubmit,
  resetDisabled: state.needHelp.resetDisabled,
  selectedCountryName: state.store.selectedCountryName,
  selectedCountryId: state.selectedCountryId
});

const mapDispatchToProps = dispatch => ({
  changeTab: bindActionCreators(changeTab, dispatch),
  changeCategory: bindActionCreators(changeCategory, dispatch),
  handleAccordTextChange: bindActionCreators(handleAccordTextChange, dispatch),
  handleAccordCheckBoxChange: bindActionCreators(
    handleAccordCheckBoxChange,
    dispatch
  ),
  handleNeedHelpAccordTile: bindActionCreators(
    handleNeedHelpAccordTile,
    dispatch
  ),
  handelRemoveNeedHelpAccordTile: bindActionCreators(
    handelRemoveNeedHelpAccordTile,
    dispatch
  ),
  handelUpdateNeedHelpAccordTilePosition: bindActionCreators(
    handelUpdateNeedHelpAccordTilePosition,
    dispatch
  ),
  fetchNeedHelpData: bindActionCreators(fetchNeedHelpData, dispatch),
  submitNeedHelpPage: bindActionCreators(submitNeedHelpPage, dispatch),
  resetNeedHelpContent: bindActionCreators(resetNeedHelpContent, dispatch),
  enableSubmitBtn: bindActionCreators(enableSubmitBtn, dispatch),
  disableSubmitBtn: bindActionCreators(disableSubmitBtn, dispatch),
  toggleResetBtn: bindActionCreators(toggleResetBtn, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NeedHelp);
