export const imageWidthOptions = {
  desktop: {
    min: 1500,
    max: 2500
  },
  mobile: {
    min: 200,
    max: 1000
  },
  tablet: {
    min: 800,
    max: 1500
  }
};

export const deviceTypes = {
  desktop: "desktop",
  mobile: "mobile",
  tablet: "tablet"
};

export const devicePlatformTypes = {
  web: "web",
  app: "app",
  resp: "resp",
};

export const imageErrors = {
  desktop: `Desktop image width should be between ${imageWidthOptions.desktop.min}  px and ${imageWidthOptions.desktop.max} px`,
  mobile: `Mobile image width should be between ${imageWidthOptions.mobile.min}  px and ${imageWidthOptions.mobile.max} px`,
  tablet: `Tablet image width should be between ${imageWidthOptions.tablet.min}  px and ${imageWidthOptions.tablet.max} px`
};

export const imageTypes = [
  {
    type: deviceTypes.desktop,
    name: "Desktop",
    tooltipText: `Image width should be between ${imageWidthOptions.desktop.min}  px and ${imageWidthOptions.desktop.max} px`
  },
  {
    type: deviceTypes.mobile,
    name: "Mobile",
    tooltipText: `Image width should be between ${imageWidthOptions.mobile.min}  px and ${imageWidthOptions.mobile.max} px`
  },
  {
    type: deviceTypes.tablet,
    name: "Tablet",
    tooltipText: `Image width should be between ${imageWidthOptions.tablet.min}  px and ${imageWidthOptions.tablet.max} px`
  }
];

export const deviceCheckboxes = [
  { name: "resp", label: "Show on Resp" },
  { name: "app", label: "Show on Mobile" }
];

export const sliderCheckboxes = [
  { name: "showOnResp", label: "Show on Resp" },
  { name: "showOnMobile", label: "Show on Mobile" }
];

export const autoPlayOptions = [
  { label: "None", value: "none", disabled: false },
  { label: "1 second", value: 1000, disabled: false },
  { label: "2 seconds", value: 2000, disabled: false },
  { label: "3 seconds", value: 3000, disabled: false },
  { label: "4 seconds", value: 4000, disabled: false },
  { label: "5 seconds", value: 5000, disabled: false }
];

export const bulletOptions = [
  { label: "None", value: "none", disabled: false },
  { label: "Bullet", value: "bullet", disabled: false },
  { label: "Rectangle", value: "rectangle", disabled: false }
];

export const buttonStyleClasses = [
  { label: "Class1", value: "class1", disabled: false },
  { label: "Class2", value: "class2", disabled: false },
  { label: "Class3", value: "class3", disabled: false }
];

export const buttonPositions = [
  { label: "Right", value: "right", disabled: false },
  { label: "Left", value: "left", disabled: false },
  { label: "Center", value: "center", disabled: false }
];

export const imageFormatArr = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/webp"];

export const svgImageFormatArr = ["image/svg+xml"]

