import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEqual from "lodash/isEqual";
import {
  STATIC_PAGE_PANEL,
  NAVIGATION,
  NAVIGATION_MENUS_SLUGS
} from "../../constants";
import { Paper } from "../../materialUi/components"
import CountryDropDown from "../countryDropdownMUI";
import Subheader from "../../component/subheader"
import SideBar from "../sideBar";
import RightPanel from "./rightPanel";
import ConfirmModal from "../modal/ConfirmModal";
import { changeTab } from "../../actions/common.action";
import {
  toggleReset,
  cancelAction,
  resetAddPageContent,
  resetUpdateStaticPage,
  fetchStaticPages
} from "../../actions/staticPage.action";

class StaticPage extends Component {
  constructor() {
    super();
    this.updatePageContent = {};
    this.tempPageContent = {};
    this.state = {
      currentTab: STATIC_PAGE_PANEL.STATIC_ADD_PAGE_DISPLAY,
      confirmModal: false,
      storeTempSelectedTab: "",
      updatePageIndex: 0,
      tempPageIndex: 0,
      currentTabName: `${STATIC_PAGE_PANEL.STATIC_UPDATE_PAGE_DISPLAY}0`
    };
  }

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.STATIC);
  }

  componentDidMount() {
    const { countryId, fetchStaticPages } = this.props;
    countryId && fetchStaticPages(countryId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { storeId, countryId, fetchStaticPages } = this.props;
    const { STATIC_DELETE_PAGE_DISPLAY } = STATIC_PAGE_PANEL;
    let e = { target: {} };
    e["target"]["id"] = STATIC_DELETE_PAGE_DISPLAY;
    e["target"]["name"] = STATIC_DELETE_PAGE_DISPLAY;
    if (
      +storeId !== +nextProps.storeId ||
      +countryId !== +nextProps.countryId
    ) {
      nextProps.storeId &&
        nextProps.countryId &&
        this.setState({ updatePageIndex: 0 });
      nextProps.storeId && nextProps.countryId && this.changeTab(e);
      nextProps.countryId && fetchStaticPages(nextProps.countryId);
    }
  }

  changeTab = e => {
    const { currentTabName, confirmModal } = this.state;
    const targetTabName =
      typeof e.target.getAttribute === "function"
        ? e.target.getAttribute("name")
        : e.target.name;
    const { staticPageR } = this.props;
    const {
      STATIC_ADD_PAGE_DISPLAY,
      STATIC_DELETE_PAGE_DISPLAY
    } = STATIC_PAGE_PANEL;

    if (currentTabName !== targetTabName) {
      if (
        [STATIC_ADD_PAGE_DISPLAY, STATIC_DELETE_PAGE_DISPLAY].indexOf(
          currentTabName
        ) > -1
      ) {
        if (
          isEqual(
            staticPageR.initialAddPageContent,
            staticPageR.staticAddPageContent
          )
        ) {
          this.setState({
            currentTab: e.target.id,
            currentTabName: targetTabName
          });
        } else {
          this.setState({
            confirmModal: !confirmModal,
            storeTempSelectedTab: e.target.id,
            storeTempSelectedTabName: targetTabName
          });
        }
      } else {
        if (isEqual(staticPageR.initialStaticPages, staticPageR.staticPages)) {
          this.setState({
            currentTab: e.target.id,
            currentTabName: targetTabName
          });
        } else {
          this.setState({
            confirmModal: !confirmModal,
            storeTempSelectedTab: e.target.id,
            storeTempSelectedTabName: targetTabName
          });
        }
      }
    } else {
      if (
        [STATIC_ADD_PAGE_DISPLAY, STATIC_DELETE_PAGE_DISPLAY].indexOf(
          currentTabName
        ) > -1
      ) {
        if (
          isEqual(
            staticPageR.initialAddPageContent,
            staticPageR.staticAddPageContent
          )
        ) {
          this.setState({
            currentTab: e.target.id,
            currentTabName: targetTabName
          });
        } else {
          this.setState({
            confirmModal: !confirmModal,
            storeTempSelectedTab: e.target.id,
            storeTempSelectedTabName: targetTabName
          });
        }
      }
    }
  };

  hideModal = () =>
    this.setState(prevProps => ({
      confirmModal: !prevProps.confirmModal,
      storeTempSelectedTab: ""
    }));

  confirmModal = () => {
    const {
      confirmModal,
      storeTempSelectedTab,
      currentTab,
      storeTempSelectedTabName,
      tempPageIndex
    } = this.state;
    const { resetAddPageContent, resetUpdateStaticPage } = this.props;
    resetAddPageContent();
    resetUpdateStaticPage();
    this.setState({
      confirmModal: !confirmModal,
      currentTab: storeTempSelectedTab,
      storeTempSelectedTab: "",
      currentTabName: storeTempSelectedTabName,
      storeTempSelectedTabName: "",
      updatePageIndex: tempPageIndex
    });
    this.props.cancelAction(currentTab);
    this.updatePageContent = this.tempPageContent;
  };

  handlePageClick = (updatePageContent, index) => {
    const { currentTabName } = this.state;
    const { staticPageR } = this.props;
    const {
      STATIC_ADD_PAGE_DISPLAY,
      STATIC_DELETE_PAGE_DISPLAY,
      STATIC_UPDATE_PAGE_DISPLAY
    } = STATIC_PAGE_PANEL;
    if (
      [STATIC_ADD_PAGE_DISPLAY, STATIC_DELETE_PAGE_DISPLAY].indexOf(
        currentTabName
      ) > -1
    ) {
      if (
        isEqual(
          staticPageR.initialAddPageContent,
          staticPageR.staticAddPageContent
        )
      ) {
        this.updatePageContent = updatePageContent;
        this.setState({
          currentTab: STATIC_UPDATE_PAGE_DISPLAY,
          updatePageIndex: index
        });
      } else {
        this.tempPageContent = updatePageContent;
        this.setState({
          currentTab: STATIC_ADD_PAGE_DISPLAY,
          tempPageIndex: index
        });
      }
    } else {
      if (isEqual(staticPageR.initialStaticPages, staticPageR.staticPages)) {
        this.updatePageContent = updatePageContent;
        this.setState({
          currentTab: STATIC_UPDATE_PAGE_DISPLAY,
          updatePageIndex: index
        });
      } else {
        this.tempPageContent = updatePageContent;
        this.setState({
          currentTab: STATIC_UPDATE_PAGE_DISPLAY,
          tempPageIndex: index
        });
      }
    }
  };

  updateContent = data => {
    this.updatePageContent = data;
  };

  render() {
    const {
      currentTab,
      confirmModal,
      updatePageIndex,
      currentTabName
    } = this.state;
    const { selectedCountryName, staticData, user } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.STATIC_PAGE]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    const breadCrumArr = [];
    if (this.updatePageContent?.title) {
      breadCrumArr.push(this.updatePageContent.title);
    }
    return (
      <div className="row" style={{ "backgroundColor": "rgb(242, 245, 249)", "marginLeft": 0, "marginRight": 0, "paddingTop": "10px" }}>
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={
              "All the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        <div className="component-header">
          <div className="col-sm-3" style={{ "paddingLeft": "10px", "paddingRight": "5px" }}>
            <Paper
              sx={{
                padding: "20px 15px 15px 15px",
              }}
              variant="outlined"
            >
              <CountryDropDown />
              <SideBar
                currentTab={currentTab}
                changeTab={this.changeTab}
                staticData={staticData || ""}
                handlePageClick={this.handlePageClick}
                currentTabName={currentTabName}
                accessRights={accessRights}
              />
            </Paper>
          </div>
          <div className="col-sm-9" style={{ "paddingLeft": "5px", "paddingRight": "10px" }}>
            <Subheader showCountry={true} breadcrumArr={breadCrumArr} />
            <RightPanel
              currentTab={currentTab}
              updatePageContent={this.updatePageContent || ""}
              updatePageIndex={updatePageIndex}
              changeTab={this.changeTab}
              updateContent={this.updateContent}
              accessRights={accessRights}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  storeId: state.store.storeId,
  countryId: state.store.countryId,
  storeList: state.store.storeList,
  selectedStoreName: state.store.selectedStoreName,
  selectedCountryName: state.store.selectedCountryName,
  staticData: state.staticReducer.initialStaticPages,
  staticPageR: state.staticReducer
});

const mapDispatchToProps = dispatch => ({
  changeTab: bindActionCreators(changeTab, dispatch),
  cancelAction: bindActionCreators(cancelAction, dispatch),
  toggleReset: bindActionCreators(toggleReset, dispatch),
  resetAddPageContent: bindActionCreators(resetAddPageContent, dispatch),
  resetUpdateStaticPage: bindActionCreators(resetUpdateStaticPage, dispatch),
  fetchStaticPages: bindActionCreators(fetchStaticPages, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(StaticPage);
