import React, { Component } from "react";

import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";

export default class JSONEditorReact extends Component {
  componentDidMount() {
    const options = Object.assign({}, this.props);
    delete options.json;
    delete options.text;

    this.jsoneditor = new JSONEditor(this.container, options);

    if ("text" in this.props) {
      this.jsoneditor.setText(this.props.text);
    }
    if ("mode" in this.props) {
      this.jsoneditor.setMode(this.props.mode);
    }
  }

  componentDidUpdate() {

    if ("text" in this.props) {
      this.jsoneditor.updateText(this.props.text);
    }

  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  render() {
    return (
      <div
        className="jsoneditor-react-container"
        style={{ width: "100%", height: "100%" }}
        ref={(elem) => (this.container = elem)}
      />
    );
  }
}
