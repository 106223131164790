import React, { useEffect } from "react";
import { connect } from "react-redux";

import { NAVIGATION } from "../../constants";
import { changeTab } from "../../actions/common.action";
import Content from "./content";
import {
  selectPagesContent
} from "../../selectors";

const PageContent = ({ currentTab, pagesContent, accessRights }) => {

  useEffect(() => {
    changeTab(NAVIGATION.CATALOG);
  }, []);

  return (
      <div className="right-wrapper">
        <Content
          currentTab={currentTab}
          updatePageContent={pagesContent}
          accessRights={accessRights}
        />
    </div>
  );
};

const mapStateToProps = state => ({
  pagesContent: selectPagesContent(state)
});

const mapDispatchToProps = {
  changeTab
};

export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
