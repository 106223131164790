import React from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import InputText from "../../component/InputText";
import { IMAGE_CAROUSEL_ACCORDION_PLACEHOLDERS } from "../../constants";

const ImageCarouselAccordionForm = ({
  content,
  handleTextChange,
  language
}) => {
  const placeholders = IMAGE_CAROUSEL_ACCORDION_PLACEHOLDERS[language];
  return (
    <div className={`${language}-wrapper`}>
      <h4>{upperFirst(language)} Version</h4>
      <div className={`${language}-content`}>
        <InputText
          label="Title"
          name="title"
          language={language}
          placeholder={placeholders.title}
          value={get(content, `${language}.title`, "")}
          onChange={e =>
            handleTextChange(e.target.name, e.target.value, language)
          }
          required
        />
        <InputText
          label="Sub Title"
          name="subTitle"
          language={language}
          placeholder={placeholders.subTitle}
          value={get(content, `${language}.subTitle`, "")}
          onChange={e =>
            handleTextChange(e.target.name, e.target.value, language)
          }
          required
        />
      </div>
    </div>
  );
};

export default ImageCarouselAccordionForm;
