import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  fetchRoleManagementData,
  fetchPermissionsByRole,
  updatePermissionsData,
  updatePermissionsByRole
} from "../../actions/permissions.action";

import { changeTab } from "../../actions/common.action";
import { NAVIGATION, NAVIGATION_MENUS_SLUGS, MESSAGES } from "../../constants";
import PermissionList from "./permissionList";

import Button from "../../component/button";

const createMenuMapping = (menu = {}, mappingObject) => {
  const { _id, name, children = [] } = menu;
  mappingObject[_id] = name;
  children.forEach(child => {
    createMenuMapping(child, mappingObject);
  });
};

const Permissions = () => {
  const roleList = useSelector(state => state.permissions.roleList);
  const permissionsByRole = useSelector(
    state => state.permissions.permissionsByRole
  );

  const navMenuList = useSelector(state => state.navMenuManagement.navMenuList);

  const user = useSelector(state => state.authentication.user);

  const [idMenuMapping, setIdMenuMapping] = useState({});
  const [selectedRole, setSelectedRole] = useState("");

  useEffect(() => {
    let mappingObject = {};
    if (navMenuList && navMenuList.length) {
      navMenuList.forEach(menu => {
        createMenuMapping(menu, mappingObject);
      });
      setIdMenuMapping({ ...mappingObject });
    }
  }, [navMenuList]);

  const dispatch = useDispatch();

  const loadData = () => {
    dispatch(fetchRoleManagementData());
  };

  const handleRoleChange = roleId => {
    setSelectedRole(roleId);
    dispatch(fetchPermissionsByRole(roleId));
  };

  const onChangeAccessRights = (
    name,
    value,
    menuIndex,
    itemId,
    roleId,
    menuId
  ) => {
    dispatch(
      updatePermissionsData({
        name,
        value,
        menuIndex,
        itemId,
        roleId,
        menuId
      })
    );
  };
  const handleSubmit = () => {
    dispatch(updatePermissionsByRole(permissionsByRole, selectedRole));
  };

  useEffect(() => {
    loadData();
    dispatch(changeTab(NAVIGATION.PERMISSIONS));
  }, []);

  useEffect(() => {
    if (roleList.length) {
      handleRoleChange(roleList[0]._id);
    }
  }, [roleList]);

  const {
    permissions: {
      [NAVIGATION_MENUS_SLUGS.PERMISSIONS]: { accessRights = {} } = {}
    } = {}
  } = user || {};

  return (
    <Fragment>
      <div className="row permissions-container">
        <div className="col-lg-12 m-b-5">
          <span className="total-user-text col-sm-6">Permissions</span>

          <div className="col-sm-2 pull-right">
            <select
              id="selectedRole"
              className="form-control user-type"
              value={selectedRole}
              name="selectedRole"
              onChange={event => handleRoleChange(event.target.value)}
            >
              {roleList ? null : <option value="">Select Role</option>}
              {roleList.map(role => (
                <option value={role._id} key={role._id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <PermissionList
          permissionList={permissionsByRole}
          onChangeAccessRights={onChangeAccessRights}
          idMenuMapping={idMenuMapping}
        />
      </div>
      <footer className="permissions-footer">
        <div className="footer-wrapper ">
          <div
            data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
            data-for="permissions"
          >
            <Button
              name={"Save Changes"}
              onClick={accessRights.UPDATE ? () => handleSubmit() : null}
              className="ml-5"
              customStyle={{ marginLeft: "10px" }}
              disabled={!accessRights.UPDATE}
            />
          </div>
        </div>
        <ReactTooltip place="top" effect="solid" id="permissions" />
      </footer>
    </Fragment>
  );
};

export default Permissions;
