import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import {
  FOOTER_PANEL, MESSAGES
} from "../../constants";
import UpdatePageContent from "./updatePageContent";
import { ApplyDropDown } from "../../component/applyDropDown";
import { Button, Tooltip, Box } from "../../materialUi/components";
import Footer from "../../component/footer";

class Content extends Component {

  handleSubmit = (tempsave = false) => {
    const {
      updateFooterDetails,
    } = this.props;
    updateFooterDetails(tempsave);
  };

  render() {
    const {
      updateMenuItemContent,
      menuItemContent,
      currentTab,
      accessRights,
      updateFooterDetails,
      isPublished,
    } = this.props;
    const disableSubmitButton = isEqual(menuItemContent, updateMenuItemContent);
    return (
      <div className="content-container-wrapper">
        {currentTab === FOOTER_PANEL.MENU_ITEMS && (
          <UpdatePageContent
            updatePageContent={updateMenuItemContent}
            menuItemContent={menuItemContent}
            updateFooterDetails={updateFooterDetails}
            accessRights={accessRights}
          />
        )}
        {currentTab === FOOTER_PANEL.STATIC_CONTENT && (
          <h2>This Page is Under Maintainance</h2>
        )}
        <Footer>
          <Box sx={{ "display": "flex" }}>
            <Box className="footer" sx={{ "width": "50%" }}>
              <Box className="footer-wrapper catalog">
                <ApplyDropDown onlyCountries style={{ "justifyContent": "flex-start" }} />
              </Box>
            </Box>
            <Tooltip title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
              <span style={{ "marginLeft": "auto", "marginRight": "20px" }}>
                <Button
                  onClick={() => this.handleSubmit(true)}
                  disabled={disableSubmitButton || !accessRights.UPDATE}
                  variant="contained"
                >
                  Save & Preview
                </Button>
              </span>
            </Tooltip>
            <Tooltip title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
              <span>
                <Button
                  onClick={() => this.handleSubmit(false)}
                  disabled={isPublished || !accessRights.UPDATE}
                  variant="contained"
                >
                  Publish
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isPublished: state.footer.isPublished
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
