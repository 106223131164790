import React, { useState } from "react";
import get from "lodash/get";
import map from "lodash/map";
import cn from "classnames";
import CarouselList from "./carouselList";

const TopBanner = ({
  englishMenuData,
  arabicMenuData,
  addDataToCarousalList,
  deleteCarousalItem,
  megaMenuCarousalHandler,
  storeId,
  countryId
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleToggle = () => setIsOpen(io => !io);
  return (
    <div className="wrapper-box">
      <div className={`heading-wrapper flex open`}>
        <h3>Category Carousal</h3>
        <span
          onClick={handleToggle}
          className={cn({
            "toggle-banner": true,
            "minus-banner": isOpen,
            "plus-banner": !isOpen
          })}
        />
      </div>
      {isOpen && (
        <div className="data-wrapper">
          {map(get(englishMenuData, "catCarousalList"), (item, idx) => (
            <CarouselList
              key={idx}
              keyIndex={idx}
              englishListItem={item}
              arabicListItem={arabicMenuData.catCarousalList[idx]}
              deleteCarousalItem={deleteCarousalItem}
              megaMenuCarousalHandler={megaMenuCarousalHandler}
              storeId={storeId}
              countryId={countryId}
            />
          ))}
          <div className="marginTop-20 marginField">
            <span className="add-btn">Add Item</span>
            <span
              id="arrivalList"
              className="add-btn float-right"
              onClick={addDataToCarousalList}
            >
              Add
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBanner;
