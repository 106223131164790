export const CATALOG_PAGE_CAROUSEL_TYPE_B_PLACEHOLDERS = {
  english: {
    searchUrl: "Enter Carousel Search URL (must start with ' / ')",
    title: "Enter Carousel Title"
  },
  arabic: {
    searchUrl: "Enter Carousel Search URL (must start with ' / ')",
    title: "أدخل عنوان دائري"
  }
};

export const CATALOG_FLASH_SALE_PAGE_CAROUSEL_TYPE_B_PLACEHOLDERS = {
  english: {
    redirectionUrl: "Enter Current Flash Sale Page Url",
    title: "Enter Carousel Title",
    pageName: "Please Select Flash Sale Content",
  },
  arabic: {
    redirectionUrl: "Enter Current Flash Sale Page Url",
    title: "Enter Carousel Title",
    pageName: "Please Select Flash Sale Content"

  }
};

export const CATALOG_PAGE_GENERAL_SEO_CONTENT_PLACEHOLDERS = {
  english: {
    title: "Enter Page Title",
    metaKeyword: "Enter Meta Keywords",
    metaDescription: "Enter Meta Description",
    headingH1: "Enter Heading H1",
    headingH2: "Enter Heading H2"
  },
  arabic: {
    title: "أدخل عنوان الصفحة",
    metaKeyword: "أدخل الكلمات الرئيسية ميتا",
    metaDescription: "أدخل الوصف الوصفى",
    headingH1: "أدخل العنوان H1",
    headingH2: "أدخل العنوان H2"
  }
};
