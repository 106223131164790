import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffd100',
    }
  },
});

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const BasicTabs = ({ value, handleChange, tabList, ...props }) => {

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <ThemeProvider theme={theme}>
          <Tabs
            value={value} onChange={(e, newValue) => handleChange(newValue)}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            {
              tabList.map((tab, index) => {
                return (<Tab key={tab.value} label={tab.label} {...a11yProps(index)} />)
              })
            }
          </Tabs>
        </ThemeProvider>
      </Box>
    </Box>
  );
}

export default BasicTabs;