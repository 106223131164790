import React, { Fragment, useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import map from "lodash/map";
import { arrayReorder } from "../util";

const CustomDragAndDrop = ({ children, items, updateItemsOrder }) => {
  const [expandedAccordionId, setExpandedAccordionId] = useState(null);
  const [tempItems, setTempItems] = useState([]);
  useEffect(() => {
    setTempItems(items);
  }, [items]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const _items = arrayReorder(
      items,
      result.source.index,
      result.destination.index
    );
    setTempItems(_items);
    updateItemsOrder(_items).catch(() => {
      setTempItems(items);
    });
  };

  const handleExpandAccordion = accordionId => {
    setExpandedAccordionId(prevAccordionId =>
      prevAccordionId === accordionId ? null : accordionId
    );
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {map(tempItems, (item, index) => {
                const draggableId = `${item.id || item.title}_${index}`;
                return (
                  <Draggable
                    draggableId={draggableId}
                    index={index}
                    key={draggableId}
                  >
                    {provided => (
                      <Fragment>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {React.cloneElement(children, {
                            expandedAccordionId,
                            handleExpandAccordion,
                            index,
                            keyIndex: index,
                            content: item
                          })}
                        </div>
                        {provided.placeholder}
                      </Fragment>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CustomDragAndDrop;
