export const ENDPOINT = {
  generalSeoDisplay: "meta",
  bannerDisplay: "topbanner",
  uspDisplay: "usp",
  storeDisplay: "shopbystore",
  newestArrivalDisplay: "newestarrival",
  topCategoryDisplay: "topcategories",
  shopByBrandsDisplay: "shopbybrands"
};

export const UPDATE_REDUCER_KEYS = {
  updateGeneralSeo: "generalSeo",
  updateTopBanner: "topBannerContent",
  updateUsp: "uspContent",
  updateShopByStore: "shopByStore",
  updateNewestArrival: "newestArrival",
  updateTopCategories: "topCategories",
  updateShopByBrands: "shopByBrands",
  updateMeta: "meta"
};

export const SUCCESS_MESSAGES = {
  homepageUpdated: "Homepage has been successfully updated.",
  userDeleted: "User has been deleted successfully.",
  userAdded: "User has been added successfully.",
  userUpdated: "User information has been updated successfully.",
  roleAdded: "Role has been added successfully.",
  userPasswordUpdated: "User's password has been updated successfully.",
  catalogAddPageDisplay: "Catalog Page has been successfully added",
  catalogUpdatePageDisplay: "Catalog Page has been successfully updated",
  staticUpdatePageDisplay: "Static Page has been successfully updated",
  staticAddPageDisplay: "Static Page has been successfully added",
  urlAdded: "URL has been added successfully.",
  urlDeleted: "URL has been deleted successfully.",
  urlUpdated: "URL has been updated successfully.",
  urlToggleUpdated: "Friendly URL has been enabled successfully.",
  urlToggleDisabled: "Friendly URL has been disabled successfully.",
  urlToggleEnabledIndexation: "Friendly URL has been enabled for indexation.",
  urlToggleDisabledIndexation: "Friendly URL has been disabled for indexation.",
  urlDataUpdated: "URL data has been updated successfully.",
  catalogPageDeleted: "Catalog Page has been successfully deleted",
  staticPageDeleted: "Static Page has been successfully deleted",
  urlDataValidated: "URL Data has been validated successfully",
  filterConditionCountUpdated:
    "Filter Condition Count has been Updated successfully.",
  orderIssues: "Order Issue data has been successfully updated.",
  loyaltyProgram: "Loyalty Program data has been successfully updated.",
  paymentAndVouchers:
    "Payment And Vouchers data has been successfully updated.",
  trackingShippingAndDelivery:
    "Tracking, shipping And Delivery data has been successfully updated.",
  productAndStock: "Product And Stock data has been successfully updated.",
  general: "General data has been successfully updated.",
  folderCreated: "New folder has been created successfully.",
  folderRenamed: "Folder has been renamed successfully.",
  folderDeleted: "Folder has been deleted successfully.",
  pageCreated: "Page has been created successfully.",
  pageRenamed: "Page has been renamed successfully.",
  pageDeleted: "Page has been deleted successfully.",
  pageUpdated: "Page has been updated successfully.",
  pageCopied: "Page has been copied to Scheduled pages successfully.",
  moduleDeleted: "Deleted Successfully",
  moduleCreated: "Created Successfully",
  variantsUpdated: "Variants Updated Successfully",
  productDeleted: "Product has been deleted successfully.",
  videoUploaded: "Video was successfully uploaded",

};
export const ERROR_MESSAGES = {
  homepageUpdate: "Unable to update the homepage.",
  userDelete: "Unable to delete the user.",
  userAdd: "Unable to add a new user.",
  userUpdate: "Unable to update the user information.",
  userPasswordUpdate: "Unable to update the user's password.",
  userLogIn: "Unable to logIn into the system.",
  roleAdd: "Unable to add a new role.",
  roleDelete: "Unable to delete role.",
  urlAdd: "Unable to add a new URL.",
  urlDelete: "Unable to delete the URL.",
  urlUpdate: "Unable to update the URL.",
  urlDataUpdate: "Unable to update the URL data.",
  catalogPageDelete: "Unable to delete the catalog page.",
  staticPageDelete: "Unable to delete the static page.",
  urlDataValidate: "Unable to validate URL data.",
  filterConditionCountUpdate: "Unable to update filter condition count.",
  catalogAddPageDisplay: "URL already exists",
  staticUpdatePageDisplay: "URL already exists",
  orderIssues: "Unable to update Order Issue data",
  loyaltyProgram: "Unable to update Loyalty Program data",
  paymentAndVouchers: "Unable to update Payment And Vouchers data",
  trackingShippingAndDelivery:
    "Unable to update Tracking, shipping And Delivery data",
  productAndStock: "Unable to update Product And Stock data",
  general: "Unable to update General data",
  folderCreated: "New folder creation failed.",
  folderRenamed: "Folder renaming failed.",
  folderDeleted: "Folder deletion failed.",
  pageCreated: "Page creation failed.",
  pageRenamed: "Page renaming failed.",
  pageDeleted: "Page deletion failed.",
  pageUpdated: "Page Updation failed.",
  pageCopied: "Page copy failed.",
  configurationPageValidation:
    "Key Value pair or Media Details cannot be empty.Please check all tabs.",
  redirectionlinkStartsWith: 'Redirection link should starts with "/"',
  redirectionlinkEndsWith: 'Redirection link should ends with "/"',
  redirectionlinkWithParams: 'Redirection link should have "/" before "?"',
  invalidRedirectionlink: "Invalid Redirection link",
  variantsUpdated: "Error while updating Variants",
  extraSpace: "Please remove Leading and Trailing spaces from",
  productDeleted: "Product deletion failed.",
  fileUploadError: "Error while uploading file",
  fileMaxSize: "Maximum file size allowed 10Mb",
  videoFileTypeError: "File must be of video type",
  imageFileTypeError: "File must be of image type",
  tiffFormatError: ".tiff/.tif file extensions are not allowed.",
  noVideoFileError: "Kindly upload a file or add a video link.",
  noImageFileError: "Kindly upload a file or add a image link.",
  invalidVideoLink: "Kindly enter a valid video link.",
  invalidImageLink: "Kindly enter a valid image link.",
  uploadVideoError: "Unable to upload video.",
  imageSizeError: "Kindly upload an image size below 1MB.",
  unableToUploadImg: "Unable to upload image.",
  redirectionLinkReq: "Redirection Link is required",
  redirectionLinkStartsWith: "Redirection Link must start with '/'",
  redirectionLinkEndsWith: "Redirection Link must end with '/'",
  invalidRedirectionLink: "Invalid Redirection Link",
  imageRequired: "Image is required",
  videoRequired: "Video is required",
  JSONRequired: "JSON REQUIRED",
  removeErrors: "Please remove errors before saving",
};
export const MESSAGES = {
  TOKEN_COPIED: "Token copied to clipboard !",
  ERROR: "Something went wrong. Please try again later",
  APPLICATION_NAME_VALIDATION: "Application name can't be null",
  EMPTY_EMAIL_VALIDATION: "Email Id can't be null",
  INVALID_EMAIL_VALIDATION: "Invalid email address.",
  ROLE_VALIDATION: "Invalid user role",
  NOT_REMOVE_QUEUED_ORDER: "You can not remove queued order",
  NOT_CANCEL_ORDER: "You can not cancel this order",
  NOT_RETURN_ORDER: "You can not return this order",
  TOOLTIP_UPDATE: "Please check your UPDATE Permission",
  TOOLTIP_WRITE: "Please check your WRITE Permission",
  TOOLTIP_DELETE: "Please check your DELETE Permission",
  URL_COPIED: "URL copied to clipboard !",
};
export const USER_ROLE = ["ADMIN", "USER"];

export const IMAGE_CLASS_TYPE_LIST = {
  col4: [
    {
      imgName: "col4-img1",
      imgClassList: [
        {
          imgClass: "v-3-1",
          toolTipMsg: "Recommended size of image is 192px * 425px."
        },
        {
          imgClass: "v-3-1",
          toolTipMsg: "Recommended size of image is 192px * 425px."
        },
        {
          imgClass: "v-3-1",
          toolTipMsg: "Recommended size of image is 192px * 425px."
        }
      ]
    },
    {
      imgName: "col4-img2",
      imgClassList: [
        {
          imgClass: "v-3-2",
          toolTipMsg: "Recommended size of image is 407px * 425px."
        },
        {
          imgClass: "v-3-1",
          toolTipMsg: "Recommended size of image is 192px * 425px."
        }
      ]
    },
    {
      imgName: "col4-img3",
      imgClassList: [
        {
          imgClass: "v-3-1",
          toolTipMsg: "Recommended size of image is 192px * 425px."
        },
        {
          imgClass: "v-3-2",
          toolTipMsg: "Recommended size of image is 407px * 425px."
        }
      ]
    },
    {
      imgName: "col4-img4",
      imgClassList: [
        {
          imgClass: "h-1-3",
          toolTipMsg: "Recommended size of image is 620px * 135px."
        },
        {
          imgClass: "h-1-3",
          toolTipMsg: "Recommended size of image is 620px * 135px."
        },
        {
          imgClass: "h-1-3",
          toolTipMsg: "Recommended size of image is 620px * 135px."
        }
      ]
    },
    {
      imgName: "col4-img5",
      imgClassList: [
        {
          imgClass: "h-2-3",
          toolTipMsg: "Recommended size of image is 620px * 288px."
        },
        {
          imgClass: "h-1-3",
          toolTipMsg: "Recommended size of image is 620px * 135px."
        }
      ]
    },
    {
      imgName: "col4-img6",
      imgClassList: [
        {
          imgClass: "h-1-3",
          toolTipMsg: "Recommended size of image is 620px * 135px."
        },
        {
          imgClass: "h-2-3",
          toolTipMsg: "Recommended size of image is 620px * 288px."
        }
      ]
    },
    {
      imgName: "col4-img7",
      imgClassList: [
        {
          imgClass: "h-3-3",
          toolTipMsg: "Recommended size of image is 620px * 425px."
        }
      ]
    }
  ],
  col5: [
    {
      imgName: "col5-img1",
      imgClassList: [
        {
          imgClass: "v-3-1",
          toolTipMsg: "Recommended size of image is 192px * 425px."
        },
        {
          imgClass: "v-3-1",
          toolTipMsg: "Recommended size of image is 192px * 425px."
        }
      ]
    },
    {
      imgName: "col5-img2",
      imgClassList: [
        {
          imgClass: "v-3-2",
          toolTipMsg: "Recommended size of image is 407px * 425px."
        }
      ]
    },
    {
      imgName: "col5-img3",
      imgClassList: [
        {
          imgClass: "v-1-2",
          toolTipMsg: "Recommended size of image is 416px * 131px."
        },
        {
          imgClass: "v-1-2",
          toolTipMsg: "Recommended size of image is 416px * 131px."
        },
        {
          imgClass: "v-1-2",
          toolTipMsg: "Recommended size of image is 416px * 131px."
        }
      ]
    },
    {
      imgName: "col5-img4",
      imgClassList: [
        {
          imgClass: "v-1-2",
          toolTipMsg: "Recommended size of image is 416px * 131px."
        },
        {
          imgClass: "v-2-2",
          toolTipMsg: "Recommended size of image is 416px * 274px."
        }
      ]
    },
    {
      imgName: "col5-img5",
      imgClassList: [
        {
          imgClass: "v-2-2",
          toolTipMsg: "Recommended size of image is 416px * 274px."
        },
        {
          imgClass: "v-1-2",
          toolTipMsg: "Recommended size of image is 416px * 131px."
        }
      ]
    }
  ],
  col6: [
    {
      imgName: "col6-img1",
      imgClassList: [
        {
          imgClass: "v-1-3",
          toolTipMsg: "Recommended size of image is 213px * 425px."
        }
      ]
    }
  ]
};

export const LANGUAGE = {
  ARABIC: "arabic",
  ENGLISH: "english"
};

export const STORES = {
  TCHIBO: "Tchibo",
  BRANDS_FOR_LESS: "Brands For Less",
  TOYS_FOR_LESS: "Toys For Less",
  MUMUSO: "Mumuso",
  HOMES_FOR_LESS: "Homes For Less",
  MUY_MUCHO: "Muy Mucho"
};

export const IMG_SIZE_IN_BYTES = {
  PNG_SIZE: 512000,
  GIF_SIZE: 768000,
  SVG_SIZE: 3000
};

export const PAGES_PAGE_ACTIVITIES = {
  FOLDER_CREATED: "folderCreated",
  FOLDER_RENAMED: "folderRenamed",
  FOLDER_DELETED: "folderDeleted",
  PAGE_CREATED: "pageCreated",
  PAGE_RENAMED: "pageRenamed",
  PAGE_DELETED: "pageDeleted",
  PAGE_COPIED: "pageCopied",
  DYNAMIC_MODULE_COPIED: "dynamicModuleCopied",
  VARIANTS_UPDATED: "variantsUpdated",
};

export const SECTIONS_PAGE_ACTIVITIES = {
  MODULE_CREATED: "moduleCreated",
  MODULE_RENAMED: "moduleRenamed",
  MODULE_DELETED: "moduleDeleted",
  SECTIONS_CREATED: "sectionsCreated",
  SECTIONS_RENAMED: "sectionsRenamed",
  SECTIONS_DELETED: "sectionsDeleted"
};

export const ACCES_TYPES = {
  READ: "READ",
  WRITE: "WRITE",
  UPDATE: "UPDATE",
  DELETE: "DELETE"
};

export const ROLES = {
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN"
};

export const ROLLBACK_TAB_LIST = [
  { label: "Logs", value: "logs" },
  { label: "Compare", value: "compare" },
]

export const routeNameMap = {
  users: "Users",
  footer: "Footer",
  "footer-new": "Footer New",
  "home-page": "Stores",
  "catalog-page": "Catalog Pages",
  "static-page": "Static Pages",
  "url-creation": "URL Creation",
  "mega-menu": "Mega Menu",
  "need-help": "Need Help",
  "size-chart": "Size Chart",
  "pages-page": "Catalog Pages",
  sections: "Sections",
  configurations: "Configurations",
  roles: "Roles",
  permissions: "Permissions",
  "nav-menu-management": "Nav Menu Management",
  "flash-sale": "Flash Sale",
  "flash-sale-v2": "Flash Sale V2",
};
