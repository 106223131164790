import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import ReactTooltip from "react-tooltip";
import CustomSidePanel from "../../component/customSidePanel";
import {
  editMegaMenuContent,
  clearMegaMenuContent,
  fetchMegaMenuData,
  handleCurrentTabChange
} from "../../actions/megaMenu.action";
import { changeTab } from "../../actions/common.action";
import { initialState } from "../../reducers/megaMenu.reducer";
import {
  NAVIGATION,
  NAVIGATION_MENUS_SLUGS,
  MEGA_MENU_MESSAGES
} from "../../constants";
import {
  findMegaMenuItem,
  findMegaMenuItemHeaderTitle
} from "../../util/megaMenu";
import ConfirmModal from "../modal/ConfirmModal";
import { ApplyDropDown } from "../../component/applyDropDown";
import AddMegaMenuItem from "./addMegaMenuItem";

class MegaMenu extends Component {
  state = {
    breadCrumb: "",
    confirmModal: false,
    nextUpdateArabic: {},
    nextUpdateEnglish: {},
    showContent: true
  };

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.MEGAMENU);
  }

  componentDidMount() {
    const { storeId, countryId, fetchMegaMenuData } = this.props;
    if (storeId && countryId) {
      fetchMegaMenuData(storeId, countryId);
    }
    this.setBreadCrumb();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryId, fetchMegaMenuData, storeId } = this.props;
    if (storeId !== nextProps.storeId || countryId !== nextProps.countryId) {
      fetchMegaMenuData(nextProps.storeId, nextProps.countryId);
      this.setBreadCrumb();
    }
  }

  componentDidUpdate(prevProps) {
    const { updateArabicContent, updateEnglishContent } = this.props;
    if (
      get(updateEnglishContent, "uid") !==
      get(prevProps, "updateEnglishContent.uid") &&
      get(updateArabicContent, "uid") !==
      get(prevProps, "updateArabicContent.uid")
    ) {
      this.setBreadCrumb();
    }
  }

  componentWillUnmount() {
    this.props.handleCurrentTabChange("");
    this.props.clearMegaMenuContent();
  }

  setBreadCrumb = () => {
    const {
      arabic,
      english,
      selectedCountryName,
      selectedStoreName,
      updateArabicContent,
      updateEnglishContent
    } = this.props;
    const englishHeaderTitle = findMegaMenuItemHeaderTitle(
      english,
      updateEnglishContent
    );
    const arabicHeaderTitle = findMegaMenuItemHeaderTitle(
      arabic,
      updateArabicContent
    );
    let breadCrumb = "MegaMenu";
    if (selectedStoreName) {
      breadCrumb += `, ${selectedStoreName}`;
    }
    if (selectedCountryName) {
      breadCrumb += `, ${selectedCountryName}`;
    }
    if (englishHeaderTitle && arabicHeaderTitle) {
      breadCrumb += ` - ${englishHeaderTitle} / ${arabicHeaderTitle}`;
    }
    this.setState({ breadCrumb });
  };

  hideConfirmModal = () => this.setState({ confirmModal: false });

  confirmModal = () => {
    const { nextUpdateEnglish, nextUpdateArabic } = this.state;
    const {
      editMegaMenuContent,
      clearMegaMenuContent,
      handleCurrentTabChange
    } = this.props;
    if (get(nextUpdateEnglish, "uid")) {
      editMegaMenuContent(nextUpdateEnglish, nextUpdateArabic);
    } else {
      clearMegaMenuContent();
    }
    this.setState({
      confirmModal: false,
      nextUpdateEnglish: {},
      nextUpdateArabic: {}
    });
    handleCurrentTabChange(get(nextUpdateEnglish, "uid", ""));
  };

  changeTab = (englishMenuData, arabicMenuData) => {
    const {
      updateEnglishContent,
      updateArabicContent,
      english,
      arabic,
      handleCurrentTabChange
    } = this.props;
    const englishContent = findMegaMenuItem(english, updateEnglishContent);
    const arabicContent = findMegaMenuItem(arabic, updateArabicContent);
    const isContentUnchanged =
      isEqual(englishContent, updateEnglishContent) &&
      isEqual(arabicContent, updateArabicContent);
    const isContentInitial =
      isEqual(initialState.updateEnglishContent, updateEnglishContent) &&
      isEqual(initialState.updateArabicContent, updateArabicContent);
    if (isContentUnchanged || isContentInitial) {
      this.props.editMegaMenuContent(englishMenuData, arabicMenuData);
      handleCurrentTabChange(englishMenuData.uid);
    } else {
      this.setState({
        confirmModal: true,
        nextUpdateEnglish: englishMenuData,
        nextUpdateArabic: arabicMenuData
      });
    }
  };

  clearMegaMenuContent = () => {
    const {
      updateEnglishContent,
      updateArabicContent,
      english,
      arabic,
      handleCurrentTabChange
    } = this.props;
    const englishContent = findMegaMenuItem(english, updateEnglishContent);
    const arabicContent = findMegaMenuItem(arabic, updateArabicContent);
    const isContentUnchanged =
      isEqual(englishContent, updateEnglishContent) &&
      isEqual(arabicContent, updateArabicContent);
    const isContentInitial =
      isEqual(initialState.updateEnglishContent, updateEnglishContent) &&
      isEqual(initialState.updateArabicContent, updateArabicContent);
    if (isContentUnchanged || isContentInitial) {
      this.props.clearMegaMenuContent();
      handleCurrentTabChange("");
    } else {
      this.setState({ confirmModal: true });
    }
  };

  changeNav = () =>
    this.setState(prevState => ({ showContent: !prevState.showContent }));

  render() {
    const { confirmModal, showContent, breadCrumb } = this.state;
    const {
      arabic,
      arabicContent,
      currentTab,
      english,
      englishContent,
      updateArabicContent,
      updateEnglishContent,
      user
    } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.MEGA_MENU]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    return (
      <div className="row m-t-10">
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideConfirmModal}
            confirm={this.confirmModal}
            message={MEGA_MENU_MESSAGES.UNSAVED_CHANGES}
          />
        )}
        <div className="component-header header-container">
          <div className="col-sm-3">
            <CustomSidePanel
              isMegaMenu
              currentTab={currentTab}
              showContent={showContent}
              changeTab={this.changeTab}
              changeNav={this.changeNav}
              clearMenuContent={this.clearMegaMenuContent}
              englishList={english}
              arabicList={arabic}
              showCountryDropdown={true}
            />
          </div>
          <div className="col-sm-9">
            <h2 className="page-main-heading">
              <div className="row">
                <div className="col-sm-5">{breadCrumb}</div>
                <div className="col-sm-7">
                  <ApplyDropDown />
                </div>
              </div>
            </h2>
          </div>
          <div className="col-sm-9">
            <AddMegaMenuItem
              englishMenuData={updateEnglishContent}
              arabicMenuData={updateArabicContent}
              englishContent={englishContent}
              arabicContent={arabicContent}
              currentTab={currentTab}
              accessRights={accessRights}
            />
          </div>
        </div>
        <ReactTooltip place="top" effect="solid" id="mega-menu" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  arabic: state.megaMenu.arabic,
  arabicContent: state.megaMenu.arabicContent,
  arStaticContent: state.megaMenu.arStaticContent,
  countryId: state.store.countryId,
  currentTab: state.megaMenu.currentTab,
  english: state.megaMenu.english,
  englishContent: state.megaMenu.englishContent,
  enStaticContent: state.megaMenu.enStaticContent,
  selectedCountryName: state.store.selectedCountryName,
  selectedStoreName: state.store.selectedStoreName,
  storeId: state.store.storeId,
  updateArabicContent: state.megaMenu.updateArabicContent,
  updateEnglishContent: state.megaMenu.updateEnglishContent
});

const mapDispatchToProps = {
  changeTab,
  clearMegaMenuContent,
  editMegaMenuContent,
  fetchMegaMenuData,
  handleCurrentTabChange
};

export default connect(mapStateToProps, mapDispatchToProps)(MegaMenu);
