import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { toastMsg } from "../../actions/common.action";
import { ERROR_MESSAGES } from "../../constants";

const MenuForm = ({
  data,
  modalName,
  handleModal,
  submitHandler,
  ConfirmButtonText,
  headerText
}) => {
  const customStyles = {
    content: {
      width: "600px",
      height: "500px",
      position: "absolute",
      top: "20%",
      left: "35%",
      right: "40px",
      bottom: "40px",
      borderRadius: "none",
      background: "rgb(255, 255, 255)",
      overflow: "auto"
    },

    overlay: {
      zIndex: "5",
      backgroundColor: "rgba(0, 0, 0, 0.6)"
    }
  };
  const [menuForm, setMenuForm] = useState({
    name: data.name || "",
    slug: data.slug || "",
    description: data.description || "",
    url: data.url || ""
    // children: [],
  });
  const [disableSubmit, setDisableSubmit] = useState(true);
  const dispatch = useDispatch();

  const changeHandler = e => {
    setMenuForm({ ...menuForm, [e.target.name]: e.target.value });
    const isInValid = !(menuForm.name && menuForm.slug && menuForm.url);
    setDisableSubmit(isInValid);
  };

  /**
   * validate field (name, slug)
   */
  const checkIfExists = (e, action, payload) => {
    e.preventDefault();
    const { name = "", slug = "", url = "", description = "" } = menuForm;
    if (!(name && slug && url)) {
      dispatch(toastMsg("error", "Please fill all input fields."));
    } else if (name !== name.trim()) {
      dispatch(toastMsg("error", `${ERROR_MESSAGES.extraSpace} Name`));
    } else if (slug !== slug.trim()) {
      dispatch(toastMsg("error", `${ERROR_MESSAGES.extraSpace} Slug`));
    } else if (url !== url.trim()) {
      dispatch(toastMsg("error", `${ERROR_MESSAGES.extraSpace} Url`));
    } else if (description !== description.trim()) {
      dispatch(toastMsg("error", `${ERROR_MESSAGES.extraSpace} Description`));
    } else {
      action(payload);
    }
  };
  return (
    <div>
      <Modal
        isOpen={true}
        ariaHideApp={false}
        onRequestClose={() => handleModal(modalName)}
        style={customStyles}
        closeTimeoutMS={2000}
      >
        <form
          onSubmit={e => {
            checkIfExists(e, submitHandler, menuForm);
          }}
        >
          <div className="close-btn">
            <span
              onClick={() => handleModal(modalName)}
              className="close-icon"
            />
          </div>
          <span className="modal-heading">{headerText}</span>
          <input
            id="name"
            type="text"
            name="name"
            value={menuForm.name}
            className="form-control m-b-20 no-border-radius column-height marginTop-20"
            placeholder="Name"
            onChange={changeHandler}
          />
          <input
            id="slug"
            type="text"
            name="slug"
            value={menuForm.slug}
            className="form-control m-b-20 no-border-radius column-height"
            placeholder="Slug"
            onChange={changeHandler}
          />
          <input
            id="url"
            type="text"
            name="url"
            value={menuForm.url}
            className="form-control m-b-20 no-border-radius column-height"
            placeholder="Url"
            onChange={changeHandler}
          />
          <textarea
            id="description"
            name="description"
            value={menuForm.description}
            className="form-control m-b-20 no-border-radius "
            placeholder="Description"
            style={{ height: "70px" }}
            onChange={changeHandler}
          />
          <button
            className={`confirm-btn marginTop-20 ${disableSubmit ? "btn-disabled" : ""
              }`}
            disabled={disableSubmit || false}
            onClick={e => {
              checkIfExists(e, submitHandler, menuForm);
            }}
          >
            <span className="btn-text">{ConfirmButtonText}</span>
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default MenuForm;
