export const SLIDER_IMAGE_ACCORDION_PLACEHOLDERS = {
  arabic: {
    defaultTemplate: "أدخل HTML الافتراضي",
    redirectionLink: "أدخل رابط إعادة التوجيه"
  },
  english: {
    defaultTemplate: "Enter Default HTML",
    redirectionLink: "Enter Redirection Link (must start with '/')"
  }
};
