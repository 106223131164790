import React, { useState, useEffect, useRef, useCallback } from "react";

const EditableSpan = ({
  className,
  onChange,
  children,
  onEditingEnd,
  inputProps
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState(children);
  const ref = useRef(null);

  const handleChange = e => {
    onChange && onChange(e);
    setValue(e.currentTarget.value);
  };

  const disableEditable = update => {
    if (update && onEditingEnd) {
      const newValue = value.trim();
      if (value !== newValue) {
        setValue(newValue);
      }
      onEditingEnd(newValue);
    }
    setIsEditable(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        disableEditable(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, [value, ref.current]);

  const handleKeyDown = useCallback(
    e => {
      switch (e.key) {
        case "Enter":
          disableEditable(true);
          break;
        case "Escape":
          disableEditable();
          setValue(children);
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line
    [children, value]
  );

  const clickHandler = useCallback(e => {
    e.stopPropagation();
    setIsEditable(true);
  }, []);
  return isEditable ? (
    <input
      ref={ref}
      type="text"
      className={className}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onClick={e => e.stopPropagation()}
      {...inputProps}
    />
  ) : (
    <span className={className} onClick={clickHandler}>
      {value}
    </span>
  );
};

export default EditableSpan;
