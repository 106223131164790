import React from "react";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import { formatUrlString } from "../../../util";
import InputText from "../../InputText";
import { LANGUAGE } from "../../../constants";

const SchedulerProductCarousel = ({
  handleSchedulerTextChange,
  keyIndex,
  language,
  placeholder,
  updateContent
}) => {
  const handleSearchUrl = (e, language) => {
    e.target.value = formatUrlString(e.target.value);
    handleSchedulerTextChange(e, language, keyIndex);
  };

  return (
    <div className={`${language}-wrapper`}>
      <h4>{`${capitalize(language)} Version`}</h4>
      <div className={`${language}-content`}>
        <InputText
          required
          label="Title"
          name="title"
          placeholder={placeholder}
          language={language}
          value={get(updateContent[language], "title")}
          onChange={e => handleSchedulerTextChange(e, language, keyIndex)}
        />
        <InputText
          required
          label="Search Url (must start with '/')"
          name="searchUrl"
          placeholder="Enter Carousel Search URL (must start with '/')"
          language={language}
          value={get(updateContent[language], "searchUrl")}
          onChange={e => {
            handleSearchUrl(e, "english");
            handleSearchUrl(e, "arabic");
          }}
          disabled={language === LANGUAGE.ARABIC}
        />
      </div>
    </div>
  );
};

export default SchedulerProductCarousel;
