import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import cn from "classnames";
import Button from "../../component/button";
import { handleTxtChange } from "../../actions/footer.action";
import { _validateRedirectionUrl } from "../../util";
import { LANGUAGE } from "../../constants";
import AddAttributeForm from "./addAttributeForm";

const customStyles = {
  content: {
    background: "rgb(255, 255, 255)",
    borderRadius: "none",
    height: "540px",
    left: "50%",
    overflow: "auto",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "980px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: "5"
  }
};

const AddAttributeModal = ({
  clearFooterContent,
  handleSaveContent,
  onHide,
  updateArabicContent,
  updateEnglishContent
}) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    onHide(false);
    clearFooterContent();
  };

  const handleTextChange = (language, key, value) => {
    dispatch(handleTxtChange(language, key, value));
  };

  const handleUrlChange = value => {
    const validatedValue = _validateRedirectionUrl(value) ? value : `/${value}`;
    dispatch(
      handleTxtChange(LANGUAGE.ENGLISH, "redirectionLink", validatedValue)
    );
    dispatch(
      handleTxtChange(LANGUAGE.ARABIC, "redirectionLink", validatedValue)
    );
  };

  const disableSubmit = useMemo(() => {
    const isFormValid =
      updateEnglishContent &&
      updateEnglishContent.title &&
      updateEnglishContent.redirectionLink &&
      updateEnglishContent.color.value &&
      updateArabicContent &&
      updateArabicContent.title &&
      updateArabicContent.redirectionLink &&
      updateArabicContent.color.value;
    return !isFormValid;
  }, [updateEnglishContent, updateArabicContent]);

  return (
    <Modal
      isOpen={true}
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      style={customStyles}
      closeTimeoutMS={2000}
    >
      <form onSubmit={handleSaveContent}>
        <div className="close-btn">
          <span onClick={handleCloseModal} className="close-icon r-15" />
        </div>
        <span className="modal-heading">Footer Content</span>
        <div className="wrapper-box">
          <div className="data-wrapper">
            <AddAttributeForm
              content={updateEnglishContent}
              handleTextChange={handleTextChange}
              handleUrlChange={handleUrlChange}
              language={LANGUAGE.ENGLISH}
            />
            <AddAttributeForm
              content={updateArabicContent}
              handleTextChange={handleTextChange}
              handleUrlChange={handleUrlChange}
              language={LANGUAGE.ARABIC}
            />
          </div>
        </div>
        <Button
          customClass={cn({ "btn-disabled": disableSubmit })}
          disabled={disableSubmit || false}
          name="Save"
          onClick={handleSaveContent}
        />
      </form>
    </Modal>
  );
};

export default AddAttributeModal;
