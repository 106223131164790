export const IMAGE_CAROUSEL_ACCORDION_PLACEHOLDERS = {
  arabic: {
    title: "أدخل عنوان دائري",
    subTitle: "أدخل عنوان دائري"
  },
  english: {
    title: "Enter Carousel Title",
    subTitle: "Enter Carousel SubTitle"
  }
};
