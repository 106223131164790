import React, { Fragment } from "react";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import InputText from "../../InputText";
import { SCHEDULER_PLACEHOLDERS } from "../../../constants";

const SchedulerImageCarousel = ({
  language,
  placeholder,
  schedulerData,
  keyIndex,
  handleSchedulerTextChange
}) => {
  return (
    <Fragment>
      <div className={`${language}-wrapper`}>
        <h4>{capitalize(language)} Version </h4>
        <div className={`${language}-content`}>
          <InputText
            required
            label="Title"
            name="title"
            placeholder={placeholder}
            language={language}
            value={get(schedulerData[language], "title", "")}
            onChange={e =>
              handleSchedulerTextChange(
                e.target.name,
                e.target.value,
                language,
                keyIndex
              )
            }
          />
          <InputText
            required
            label="Sub Title"
            name="subTitle"
            placeholder={
              SCHEDULER_PLACEHOLDERS[language].productCarouselSubtitle
            }
            language={language}
            value={get(schedulerData[language], "subTitle", "")}
            onChange={e =>
              handleSchedulerTextChange(
                e.target.name,
                e.target.value,
                language,
                keyIndex
              )
            }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SchedulerImageCarousel;
