import * as React from "react";
import { useMemo } from "react";

const defaultPinsCoords = [
  {
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "#E3E3E3",
    fillOpacity: "0.5"
  },
  {
    x: "5.23828",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "#E3E3E3",
    fillOpacity: "0.5"
  },
  {
    x: "10.4766",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "#E3E3E3",
    fillOpacity: "0.5"
  },
  {
    x: "16.0469",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "#E3E3E3",
    fillOpacity: "0.5"
  },
  {
    y: "4.19043",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "#E3E3E3",
    fillOpacity: "0.5"
  },
  {
    x: "5.23828",
    y: "4.19043",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "#E3E3E3",
    fillOpacity: "0.5"
  },
  {
    x: "10.4766",
    y: "4.19043",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "#E3E3E3",
    fillOpacity: "0.5"
  },
  {
    x: "16.0469",
    y: "4.19043",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "#E3E3E3",
    fillOpacity: "0.5"
  }
];

const Pin = props => <rect {...props} />;

export const DragPins = ({ pinsConfigMatrix, ...props }) => {
  const pinsCoords = (props.pinsCoords?.length) ? props.pinsCoords : defaultPinsCoords;
  const configuredPins = useMemo(
    () =>
      pinsConfigMatrix
        ? pinsConfigMatrix.map(
          (pinEnabledFlag, index) =>
            pinEnabledFlag && <Pin key={index} {...pinsCoords[index]} />
        )
        : pinsCoords.map((pin, index) => <Pin key={index} {...pin} />),
    [pinsConfigMatrix]
  );
  return (
    <svg
      width="19"
      height="7"
      viewBox="0 0 19 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {configuredPins}
    </svg>
  );
};
