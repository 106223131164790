import React, { Component } from "react";
import { addNeedHelpSeo } from "../../actions/needHelp.action";
import connect from "react-redux/es/connect/connect";

class SeoSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      componentPosition: 1,
      active: false,
      seo: false,
      englishTextEditor: false,
      arabicTextEditor: false
    };
  }

  toggleContent = contentName => {
    // CONTENT TOGGLE
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));
  };

  render() {
    const { seo } = this.state;
    const {
      needHelpKey,
      needHelpObject,
      addNeedHelpSeo,
      submitBtnValidation
    } = this.props;
    let categoryObject = needHelpObject[needHelpKey];
    return (
      <div className="cms-wrapper">
        <div className="wrapper-box border-none">
          <div className="heading-wrapper flex catalog-banner-heading">
            <h3>{`SEO`} </h3>
            <span
              onClick={() => this.toggleContent("seo")}
              className={`toggle-banner ${
                seo ? "minus-banner" : "plus-banner"
              }`}
            />
          </div>
          {seo && (
            <div className="cms-wrapper">
              <div className="wrapper-box">
                <div className="data-wrapper">
                  <div className="english-wrapper">
                    <h4>English Version</h4>
                    <div className="english-content">
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Page Title
                        </label>
                        <input
                          name="title"
                          className="form-control date-calender"
                          type="text"
                          placeholder="Enter Page Title"
                          value={
                            categoryObject && categoryObject.meta.english.title
                          }
                          onChange={e => {
                            addNeedHelpSeo(
                              needHelpKey,
                              e.target.name,
                              e.target.value,
                              "english"
                            ),
                              setTimeout(() => {
                                submitBtnValidation();
                              }, 0);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Meta Keywords
                        </label>
                        <input
                          name="metaKeyword"
                          className="form-control date-calender"
                          type="text"
                          placeholder="Enter Meta Keywords"
                          value={
                            categoryObject &&
                            categoryObject.meta.english.metaKeyword
                          }
                          onChange={e => {
                            addNeedHelpSeo(
                              needHelpKey,
                              e.target.name,
                              e.target.value,
                              "english"
                            ),
                              setTimeout(() => {
                                submitBtnValidation();
                              }, 0);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Meta Description
                        </label>
                      </div>
                      <textarea
                        name="metaDescription"
                        rows="12"
                        cols="50"
                        placeholder="Enter Meta Description"
                        value={
                          categoryObject &&
                          categoryObject.meta.english.metaDescription
                        }
                        onChange={e => {
                          addNeedHelpSeo(
                            needHelpKey,
                            e.target.name,
                            e.target.value,
                            "english"
                          ),
                            setTimeout(() => {
                              submitBtnValidation();
                            }, 0);
                        }}
                      />
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Heading H1
                        </label>
                        <input
                          name="headingH1"
                          className="form-control date-calender"
                          type="text"
                          placeholder="Enter Heading H1"
                          value={
                            categoryObject &&
                            categoryObject.meta.english.headingH1
                          }
                          onChange={e => {
                            addNeedHelpSeo(
                              needHelpKey,
                              e.target.name,
                              e.target.value,
                              "english"
                            ),
                              setTimeout(() => {
                                submitBtnValidation();
                              }, 0);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Heading H2
                        </label>
                        <input
                          name="headingH2"
                          className="form-control date-calender"
                          type="text"
                          placeholder="Enter Heading H2"
                          value={
                            categoryObject &&
                            categoryObject.meta.english.headingH2
                          }
                          onChange={e => {
                            addNeedHelpSeo(
                              needHelpKey,
                              e.target.name,
                              e.target.value,
                              "english"
                            ),
                              setTimeout(() => {
                                submitBtnValidation();
                              }, 0);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="arabic-wrapper">
                    <h4>Arabic Version</h4>
                    <div className="arabic-content">
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Page Title
                        </label>
                        <input
                          name="title"
                          className="form-control date-calender"
                          type="text"
                          dir="rtl"
                          placeholder="أدخل عنوان الصفحة"
                          value={
                            categoryObject && categoryObject.meta.arabic.title
                          }
                          onChange={e => {
                            addNeedHelpSeo(
                              needHelpKey,
                              e.target.name,
                              e.target.value,
                              "arabic"
                            ),
                              setTimeout(() => {
                                submitBtnValidation();
                              }, 0);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Meta Keywords
                        </label>
                        <input
                          name="metaKeyword"
                          className="form-control date-calender"
                          type="text"
                          dir="rtl"
                          placeholder="أدخل الكلمات الرئيسية ميتا"
                          value={
                            categoryObject &&
                            categoryObject.meta.arabic.metaKeyword
                          }
                          onChange={e => {
                            addNeedHelpSeo(
                              needHelpKey,
                              e.target.name,
                              e.target.value,
                              "arabic"
                            ),
                              setTimeout(() => {
                                submitBtnValidation();
                              }, 0);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Meta Description
                        </label>
                      </div>
                      <textarea
                        name="metaDescription"
                        rows="12"
                        cols="50"
                        value={
                          categoryObject &&
                          categoryObject.meta.arabic.metaDescription
                        }
                        placeholder="أدخل الوصف الوصفى"
                        onChange={e => {
                          addNeedHelpSeo(
                            needHelpKey,
                            e.target.name,
                            e.target.value,
                            "arabic"
                          ),
                            setTimeout(() => {
                              submitBtnValidation();
                            }, 0);
                        }}
                      />
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Heading H1
                        </label>
                        <input
                          name="headingH1"
                          className="form-control date-calender"
                          type="text"
                          dir="rtl"
                          placeholder="أدخل العنوان H1"
                          value={
                            categoryObject &&
                            categoryObject.meta.arabic.headingH1
                          }
                          onChange={e => {
                            addNeedHelpSeo(
                              needHelpKey,
                              e.target.name,
                              e.target.value,
                              "arabic"
                            ),
                              setTimeout(() => {
                                submitBtnValidation();
                              }, 0);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <span className="required" />
                          Heading H2
                        </label>
                        <input
                          name="headingH2"
                          className="form-control date-calender"
                          type="text"
                          dir="rtl"
                          placeholder="أدخل العنوان H2"
                          value={
                            categoryObject &&
                            categoryObject.meta.arabic.headingH2
                          }
                          onChange={e => {
                            addNeedHelpSeo(
                              needHelpKey,
                              e.target.name,
                              e.target.value,
                              "arabic"
                            ),
                              setTimeout(() => {
                                submitBtnValidation();
                              }, 0);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeId: state.store.storeId,
  countryId: state.store.countryId,
  seoContent: state.staticReducer.staticAddPageContent.meta,
  ckContent: state.staticReducer.staticAddPageContent.content,
  staticPageR: state.staticReducer
});

const mapDispatchToProps = {
  addNeedHelpSeo
};

export default connect(mapStateToProps, mapDispatchToProps)(SeoSection);
