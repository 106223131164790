import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { routeNameMap } from "../../constants"

const SubHeader = ({
  changeTab,
  showSubHeader,
  selectedStoreName,
  selectedCountryName,
  selectedRouteName
}) => (
  <Fragment>
    {showSubHeader && (
      <div className="row wrapper">
        <div className="col-lg-12">
          <ol className="sub-header">
            <li className="cursor-pointer">
              <span className="home-icon" />
            </li>
            <li className="cursor-pointer">
              <Link to="/">
                <div onClick={() => changeTab("home-page")}>
                  <span>
                    <strong> Home</strong>
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <span>
                <strong>{`> ${routeNameMap[selectedRouteName]} `}</strong>
              </span>
            </li>
            {["home-page", "mega-menu", "catalog-page, pages-page"].includes(
              selectedRouteName
            ) && (
                <li>
                  <span>
                    <strong>
                      {`> ${selectedStoreName}, ${selectedCountryName} `}
                    </strong>
                  </span>
                </li>
              )}
            {[
              "footer",
              "need-help",
              "static-page",
              "sections",
              "configurations",
              "roles",
              "permissions"
            ].includes(selectedRouteName) && (
                <li>
                  <span>
                    <strong>{`> ${selectedCountryName} `}</strong>
                  </span>
                </li>
              )}
          </ol>
        </div>
      </div>
    )}
  </Fragment>
);

export default withRouter(SubHeader);
