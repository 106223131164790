import React, { Fragment, useState } from "react";
import cn from "classnames";
import { IMAGE_SIZE_AND_TYPE_TOOLTIP } from "../constants";
import infoIcon from "../images/icon-info.svg";
import Image from "../component/Image";
import ImageSelectionModal from "../containers/modal/imageSelectionModal";

const InputImageForm = ({
  containerName,
  label,
  language,
  name,
  onChange,
  required,
  value
}) => {
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);

  const toggleImageUploadModal = () =>
    setShowImageUploadModal(prevState => !prevState);

  const handleSuccess = (...args) => {
    onChange(...args);
    toggleImageUploadModal();
  };

  return (
    <Fragment>
      {showImageUploadModal && (
        <ImageSelectionModal
          title={`Upload ${label}`}
          containerName={containerName}
          keyName={name}
          language={language}
          onHide={toggleImageUploadModal}
          onSuccess={handleSuccess}
        />
      )}
      <div className={cn("form-group", "img-preview", "mt-10")}>
        <span className="info img-info">
          <img src={infoIcon} alt="info icon" />
          <p className="tooltiptext">{IMAGE_SIZE_AND_TYPE_TOOLTIP}</p>
        </span>
        <div className="form-group preview hover-icon mr-4">
          <span className="img-title">
            {required && <span className="required" />}
            {label}
          </span>
          {value ? (
            <Fragment>
              <span>
                <span className="img-overlay" />
                <span
                  className="icon-close"
                  onClick={() => onChange(name, "", "", language)}
                />
              </span>
              <span
                className="hover-camera-icon"
                onClick={toggleImageUploadModal}
              />
              <span className="uploaded-image">
                <Image className="img-dimension" alt="No Image" src={value} />
              </span>
            </Fragment>
          ) : (
            <span className="camera-icon" onClick={toggleImageUploadModal} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

InputImageForm.propTypes = {};

export default InputImageForm;
