import React from "react";
import {
  Dialog, DialogActions, DialogContent, Box,
  DialogContentText, DialogTitle, Button, Card, CardActionArea, CardMedia, CardContent, Typography
} from '../../../materialUi/components';


const StylePreviewDialog = ({ open, handleClose, data, title, dialogContext }) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {dialogContext}
          </DialogContentText>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {
              data.map(({ label, URL }) => {
                return <Card
                  sx={{ maxWidth: 200, marginRight: "20px", marginTop: "20px", marginBottom: "20px" }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={URL}
                      alt={label}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {label}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              })
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default StylePreviewDialog