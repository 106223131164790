import React from "react";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { InfoIcon, CameraAltIcon, DeleteIcon, ContentCopyIcon } from "../../materialUi/icons";
import { Tooltip, Typography, Paper, Box } from "../../materialUi/components"
import { IMAGE_SIZE_AND_TYPE_TOOLTIP, MESSAGES } from "../../constants";
import { snackbar } from "../../actions/common.action";


export const UploadImage = ({
  imageURL,
  handeImageSelectionModal,
  deleteImage,
  language,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div style={{ "display": "flex" }}>
        <Tooltip title={IMAGE_SIZE_AND_TYPE_TOOLTIP} placement="right" arrow>
          <InfoIcon fontSize="small" sx={{ marginTop: "10px" }} />
        </Tooltip>
        <Typography sx={{ p: 1 }}>
          Image
        </Typography>
      </div>
      <Box
        sx={{
          '& > :not(style)': {
            width: 128,
            height: 128,
            background: "#eee"
          },
        }}
      >
        <Paper elevation={3}
          className="mui-hover-icon"
        >
          {!imageURL && <CameraAltIcon fontSize="large" sx={{ "margin": "48px" }} onClick={handeImageSelectionModal} />}
          {!!imageURL &&
            <>
              <span className="mui-img-overlay" />
              <Tooltip arrow title="Copy Image URL" placement="top">
                <span>
                  <CopyToClipboard
                    text={
                      imageURL.split('?')[0]
                    }
                    onCopy={() =>

                      dispatch(snackbar({ open: true, severity: "success", text: MESSAGES.URL_COPIED }))
                    }>
                    <ContentCopyIcon className="mui-icon-copy" fontSize="medium" disabled={
                      !imageURL
                    } />
                  </CopyToClipboard>
                </span>
              </Tooltip>
              <DeleteIcon className="mui-icon-close" fontSize="medium" onClick={deleteImage} />
              <CameraAltIcon fontSize="large" onClick={handeImageSelectionModal}
                className="mui-camera-icon"
              />
              <img
                className="img-dimension"
                src={imageURL || ""}
              />
            </>
          }
        </Paper>
      </Box>
    </>
  );
};