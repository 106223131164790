import React from "react";
import PropTypes from "prop-types";

const Button = ({
  customStyle,
  borderColor,
  customClass,
  onClick,
  disabled,
  name,
  id
}) => (
  <button
    id={id}
    style={{
      border: borderColor ? `1px solid ${borderColor}` : "none",
      backgroundColor: disabled ? "grey" : "black",
      ...customStyle
    }}
    className={`custom-btn ${customClass}`}
    onClick={onClick}
    disabled={disabled}
  >
    {name || "Button"}
  </button>
);

Button.propTypes = {
  name: PropTypes.string.isRequired
};

export default Button;
