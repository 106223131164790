import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  fetchRoleManagementData,
  addRoleManagementData,
  updateRoleManagementData,
  deleteRoleManagementData
} from "../../actions/roleManagement.action";
import { changeTab } from "../../actions/common.action";
import { getDateTime } from "../../util";
import AddNewRoleModal from "./addNewRole";
import AddUserModal from "../modal/addUser";
import EditRoleModal from "./editRole";
import ConfirmModal from "../modal/ConfirmModal";
import { NAVIGATION, NAVIGATION_MENUS_SLUGS, MESSAGES } from "../../constants";
class Roles extends Component {
  state = {
    selectedRole: null,
    addNewRoleModal: false,
    removeRoleConfirmModal: false,
    editRoleModal: false
  };

  componentDidMount() {
    this.loadData();
    this.props.changeTab(NAVIGATION.ROLES);
  }

  loadData = () => {
    const { fetchRoleManagementData } = this.props;
    fetchRoleManagementData();
  };

  addRole = role => {
    const { addRoleManagementData } = this.props;
    let payload = {
      name: role.name,
      slug: role.slug,
      description: role.description
    };

    addRoleManagementData(payload).then(res => {
      if (res.data && res.data.code && res.data.code === 200) {
        this.loadData();
      }
      this.handleModal("addNewRoleModal");
    });
  };

  editRole = (name, slug, description) => {
    const { selectedRole } = this.state;
    const { updateRoleManagementData } = this.props;

    updateRoleManagementData(selectedRole._id, {
      name,
      slug,
      description
    }).then(res => {
      if (res.data && res.data.code && res.data.code === 200) {
        this.loadData();
      }
      this.handleModal("editRoleModal");
    });
  };

  deleteRole = () => {
    const { selectedRole } = this.state;
    const { deleteRoleManagementData } = this.props;

    deleteRoleManagementData(selectedRole._id).then(res => {
      if (res.data && res.data.code && res.data.code === 200) {
        this.loadData();
      }
      this.handleModal("removeRoleConfirmModal");
    });
  };

  handleModal = (modalName, role = null) => {
    this.setState(prevState => ({
      [modalName]: !prevState[modalName],
      selectedRole: role
    }));
  };
  render() {
    const {
      addUserModal,
      addNewRoleModal,
      editRoleModal,
      removeRoleConfirmModal,
      selectedRole
    } = this.state;
    const { roleList, user } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.ROLES]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    return (
      <Fragment>
        {addUserModal && (
          <AddUserModal
            modalName={"addUserModal"}
            onHide={this.handleModal}
            addUser={this.addUser}
          />
        )}
        {addNewRoleModal && (
          <AddNewRoleModal
            modalName={"addNewRoleModal"}
            onHide={this.handleModal}
            addRole={this.addRole}
          />
        )}
        {removeRoleConfirmModal && (
          <ConfirmModal
            modalName={"removeRoleConfirmModal"}
            onHide={this.handleModal}
            confirm={this.deleteRole}
            message={"Are you sure you want to delete ?"}
          />
        )}
        {editRoleModal && (
          <EditRoleModal
            modalName={"editRoleModal"}
            onHide={this.handleModal}
            editRole={this.editRole}
            role={selectedRole}
          />
        )}
        <div className="row user-management-container">
          <div className="col-lg-12 m-b-5">
            <span className="total-user-text col-sm-6">Total Roles</span>
            <span
              className="col-sm-2 pull-right"
              data-tip={accessRights.WRITE ? "" : MESSAGES.TOOLTIP_WRITE}
              data-for="roles"
            >
              <button
                className="round-btn"
                onClick={
                  accessRights.WRITE
                    ? () => this.handleModal("addNewRoleModal")
                    : null
                }
                disabled={!accessRights.WRITE}
              >
                <span className="plus-icon" />
                <span className="btn-text">{"Add New Role"}</span>
              </button>
            </span>
          </div>
          <div className="row">
            <div className="user-management-table">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Role</th>
                    <th scope="col">Slug</th>
                    <th scope="col">Description</th>
                    <th scope="col">Last Modification</th>
                    <th scope="col">Modified by</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {roleList &&
                    roleList.map(item => (
                      <tr key={item._id}>
                        <td>{item.name}</td>
                        <td>{item.slug}</td>
                        <td>{item.description}</td>
                        <td>{getDateTime(item.updatedAt)}</td>
                        <td>{item.createdBy.name}</td>
                        <td>
                          <span
                            className={`edit-icon ${
                              accessRights.UPDATE
                                ? "cursor-pointer"
                                : "cursor-pointer-disabled-permission"
                            }`}
                            onClick={
                              accessRights.UPDATE
                                ? () => this.handleModal("editRoleModal", item)
                                : null
                            }
                            data-tip={
                              accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE
                            }
                            data-for="roles-list"
                          />
                          <span
                            className={`delete-icon ${
                              accessRights.DELETE
                                ? "cursor-pointer"
                                : "cursor-pointer-disabled-permission"
                            }`}
                            onClick={
                              accessRights.DELETE
                                ? () =>
                                    this.handleModal(
                                      "removeRoleConfirmModal",
                                      item
                                    )
                                : null
                            }
                            data-tip={
                              accessRights.DELETE ? "" : MESSAGES.TOOLTIP_DELETE
                            }
                            data-for="roles-list"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
                <ReactTooltip place="top" effect="solid" id="roles-list" />
              </table>
            </div>
          </div>
          <ReactTooltip place="top" effect="solid" id="roles" />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  roleList: state.roleManagement.roleList
});

const mapDispatchToProps = {
  fetchRoleManagementData,
  addRoleManagementData,
  updateRoleManagementData,
  deleteRoleManagementData,
  changeTab
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
