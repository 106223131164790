import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import ReactTooltip from "react-tooltip";
import Button from "../../component/button";
import Image from "../../component/Image";
import infoIcon from "../../images/icon-info.svg";
import ImageSelectionModal from "../modal/imageSelectionModal";
import { MESSAGES, ERROR_MESSAGES } from "../../constants";
import { toastMsg } from "../../actions/common.action";

class BrandChartHead extends Component {
  state = {
    imageSelectionModal: false,
    language: null,
    enable: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updateBrandSizeChart, selectedTab, apiContent } = nextProps;
    if (
      updateBrandSizeChart[selectedTab] &&
      updateBrandSizeChart[selectedTab].english &&
      updateBrandSizeChart[selectedTab].english.heading &&
      updateBrandSizeChart[selectedTab].english &&
      updateBrandSizeChart[selectedTab].english.imageUrl &&
      updateBrandSizeChart[selectedTab].arabic &&
      updateBrandSizeChart[selectedTab].arabic.heading &&
      updateBrandSizeChart[selectedTab].arabic &&
      updateBrandSizeChart[selectedTab].arabic.imageUrl
    ) {
      this.setState({
        enable: !isEqual(
          apiContent &&
          apiContent.brandSizeChart &&
          apiContent.brandSizeChart[selectedTab],
          updateBrandSizeChart[selectedTab]
        )
          ? true
          : false
      });
    } else {
      this.setState({ enable: false });
    }
  }

  handleModal = (modalName, keyIndex, language) => {
    this.setState(prevState => ({
      [modalName]: !prevState[modalName],
      language
    }));
  };

  updateURL = (key, value, storeId, language, modalName) => {
    const { handleInputTextChange, selectedTab } = this.props;
    if (modalName) {
      this.setState(
        prevState => ({ [modalName]: !prevState[modalName], language }),
        () => {
          handleInputTextChange(
            "updateBrandSizeChart",
            selectedTab,
            language,
            key,
            value
          );
        }
      );
    } else {
      this.setState({ language }, () => {
        handleInputTextChange(
          "updateBrandSizeChart",
          selectedTab,
          language,
          key,
          value
        );
      });
    }
  };

  onTextChange = (language, key, value) => {
    const { handleInputTextChange, selectedTab } = this.props;
    handleInputTextChange(
      "updateBrandSizeChart",
      selectedTab,
      language,
      key,
      value
    );
  };

  validateFormData = (formData, language) => {
    const { toastMsg } = this.props;
    const { heading = "" } = formData || {};

    if (heading && (heading !== heading.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Heading`);
      return false;
    }

    return true;
  }

  handleSave = () => {
    const {
      updateBrandSizeChart,
      selectedTab,
      countryId,
      apiContent,
      updateSizeChartBrandContentApi
    } = this.props;

    const { english = {}, arabic = {} } = updateBrandSizeChart[selectedTab] || {};

    const isValidEnglishForm = this.validateFormData(english, "English");
    if (!isValidEnglishForm) return;

    const isValidArabicForm = this.validateFormData(arabic, "Arabic");
    if (!isValidArabicForm) return;


    updateSizeChartBrandContentApi({
      type: selectedTab,
      id: apiContent._id,
      countryId,
      body: {
        english:
          updateBrandSizeChart[selectedTab] &&
          updateBrandSizeChart[selectedTab].english,
        arabic:
          updateBrandSizeChart[selectedTab] &&
          updateBrandSizeChart[selectedTab].arabic
      }
    });
  };

  render() {
    const { imageSelectionModal, language, enable } = this.state;
    const {
      storeId,
      countryId,
      selectedTab,
      updateBrandSizeChart,
      accessRights
    } = this.props;
    return (
      <Fragment>
        {imageSelectionModal ? (
          <ImageSelectionModal
            title="Upload Image"
            modalName={"imageSelectionModal"}
            keyName={"imageUrl"}
            brandId={storeId}
            storeId={storeId}
            countryId={countryId}
            language={language}
            containerName={"updateBrandSizeChart"}
            onHide={this.handleModal}
            onSuccess={this.updateURL}
          />
        ) : null}
        <div className="wrapper-box">
          <div className="data-wrapper">
            <div className="english-wrapper">
              <h4>English Version</h4>
              <div className="english-content">
                <div className="form-group marginBottom">
                  <label>
                    <span className="required" />
                    Heading
                  </label>
                  <input
                    type="text"
                    name="heading"
                    className="form-control"
                    placeholder="Heading Text"
                    value={
                      updateBrandSizeChart[selectedTab]
                        ? updateBrandSizeChart[selectedTab].english &&
                        updateBrandSizeChart[selectedTab].english.heading
                        : ""
                    }
                    onChange={e =>
                      this.onTextChange(
                        "english",
                        e.target.name,
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="form-group img-preview">
                  <span className="info img-info">
                    <img src={infoIcon} alt="info icon" />
                    <p className="tooltiptext">
                      Max size of image should be 2MB and supported formats are
                      .jpeg, .png.
                    </p>
                  </span>
                  <div className="form-group preview hover-icon m-r-20">
                    <span className="img-title">
                      <span className="required" />
                      Image
                    </span>
                    {updateBrandSizeChart[selectedTab] &&
                      updateBrandSizeChart[selectedTab].english &&
                      updateBrandSizeChart[selectedTab].english.imageUrl && (
                        <span>
                          <span className="img-overlay" />
                          <span
                            className="icon-close"
                            onClick={() =>
                              this.updateURL("imageUrl", "", "", "english")
                            }
                          />
                        </span>
                      )}
                    {updateBrandSizeChart[selectedTab] &&
                      updateBrandSizeChart[selectedTab].english &&
                      updateBrandSizeChart[selectedTab].english.imageUrl && (
                        <span
                          className="hover-camera-icon"
                          onClick={() =>
                            this.handleModal(
                              "imageSelectionModal",
                              storeId,
                              "english"
                            )
                          }
                        />
                      )}
                    {!(
                      updateBrandSizeChart[selectedTab] &&
                      updateBrandSizeChart[selectedTab].english &&
                      updateBrandSizeChart[selectedTab].english.imageUrl
                    ) && (
                        <span
                          className="camera-icon"
                          onClick={() =>
                            this.handleModal(
                              "imageSelectionModal",
                              storeId,
                              "english"
                            )
                          }
                        />
                      )}
                    {updateBrandSizeChart[selectedTab] &&
                      updateBrandSizeChart[selectedTab].english &&
                      updateBrandSizeChart[selectedTab].english.imageUrl && (
                        <span className="uploaded-image">
                          <Image
                            className="img-dimension"
                            alt="No Image"
                            src={
                              updateBrandSizeChart[selectedTab].english &&
                              updateBrandSizeChart[selectedTab].english.imageUrl
                            }
                          />
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="arabic-wrapper">
              <h4>Arabic Version</h4>
              <div className="arabic-content">
                <div className="form-group marginBottom">
                  <label>
                    <span className="required" />
                    Heading
                  </label>
                  <input
                    dir="rtl"
                    type="text"
                    name="heading"
                    className="form-control"
                    placeholder="Heading Text"
                    value={
                      updateBrandSizeChart[selectedTab]
                        ? updateBrandSizeChart[selectedTab].arabic &&
                        updateBrandSizeChart[selectedTab].arabic.heading
                        : ""
                    }
                    onChange={e =>
                      this.onTextChange("arabic", e.target.name, e.target.value)
                    }
                  />
                </div>
                <div className="form-group img-preview">
                  <span className="info img-info">
                    <img src={infoIcon} alt="info icon" />
                    <p className="tooltiptext">
                      Max size of image should be 2MB and supported formats are
                      .jpeg, .png.
                    </p>
                  </span>
                  <div className="form-group preview hover-icon m-r-20">
                    <span className="img-title">
                      <span className="required" />
                      Image
                    </span>
                    {updateBrandSizeChart[selectedTab] &&
                      updateBrandSizeChart[selectedTab].arabic &&
                      updateBrandSizeChart[selectedTab].arabic.imageUrl && (
                        <span>
                          <span className="img-overlay" />
                          <span
                            className="icon-close"
                            onClick={() =>
                              this.updateURL("imageUrl", "", "", "arabic")
                            }
                          />
                        </span>
                      )}
                    {updateBrandSizeChart[selectedTab] &&
                      updateBrandSizeChart[selectedTab].arabic &&
                      updateBrandSizeChart[selectedTab].arabic.imageUrl && (
                        <span
                          className="hover-camera-icon"
                          onClick={() =>
                            this.handleModal(
                              "imageSelectionModal",
                              storeId,
                              "arabic"
                            )
                          }
                        />
                      )}
                    {!(
                      updateBrandSizeChart[selectedTab] &&
                      updateBrandSizeChart[selectedTab].arabic &&
                      updateBrandSizeChart[selectedTab].arabic.imageUrl
                    ) && (
                        <span
                          className="camera-icon"
                          onClick={() =>
                            this.handleModal(
                              "imageSelectionModal",
                              storeId,
                              "arabic"
                            )
                          }
                        />
                      )}
                    {updateBrandSizeChart[selectedTab] &&
                      updateBrandSizeChart[selectedTab].arabic &&
                      updateBrandSizeChart[selectedTab].arabic.imageUrl && (
                        <span className="uploaded-image">
                          <Image
                            className="img-dimension"
                            alt="No Image"
                            src={
                              updateBrandSizeChart[selectedTab].arabic &&
                              updateBrandSizeChart[selectedTab].arabic.imageUrl
                            }
                          />
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
          data-for="brand-chart-head"
        >
          <Button
            name={"Save"}
            onClick={enable && accessRights.UPDATE ? this.handleSave : null}
            disabled={!accessRights.UPDATE}
            customClass={enable ? "" : "opacity disable-btn"}
          />
        </span>
        <ReactTooltip place="top" effect="solid" id="brand-chart-head" />
      </Fragment>
    );
  }
}

const mapStateToProps = reduxState => ({
});

const mapDispatchToProps = {
  toastMsg,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandChartHead);