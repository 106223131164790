import React from "react";
import GenericCategoryList from "./genericCategoryList";
import GenericSubCategoryList from "./genericSubCategoryList";

const GenericChartTab = ({
  selectedGenericCategoryIndex,
  handleSubCategoryTileChange,
  accessRights
}) => {
  const isSubCategory = selectedGenericCategoryIndex > -1;

  return (
    <div>
      <h2 className="page-main-heading">
        {isSubCategory
          ? "Generic Size Chart SubCategories"
          : "List Generic Size Chart Categories"}
      </h2>
      {isSubCategory ? (
        <GenericSubCategoryList
          handleSubCategoryTileChange={handleSubCategoryTileChange}
        />
      ) : (
        <GenericCategoryList accessRights={accessRights} />
      )}
    </div>
  );
};

export default GenericChartTab;
