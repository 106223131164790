import React, { Fragment, useState, useEffect } from "react";
import InputText from "../../component/InputText";
import { useDispatch, useSelector } from "react-redux";
import { CustomSelect } from "../../component";
import { copyToOtherCountries } from "../../actions/configurationsPage.action";

export const ConfigPopupContentDelete = ({ onConfirm, onCancel, message }) => (
  <Fragment>
    <div>{message || "Are you sure you want to delete the item?"}</div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={onConfirm}>
        <span className="btn-text">Yes</span>
      </button>
      <button className="popup-cancel" onClick={onCancel}>
        <span className="n-btn-text">No</span>
      </button>
    </div>
  </Fragment>
);

export const AddNewConfigPopupContent = ({ onConfirm, onCancel }) => {
  const [configName, setConfigName] = useState();
  const [slug, setSlug] = useState();

  return (
    <Fragment>
      <div>
        <p>Add new Config</p>
        <InputText
          placeholder="Config Name"
          name="config"
          value={configName}
          onChange={e => setConfigName(e.target.value)}
        />
        <InputText
          placeholder="Slug"
          name="slug"
          value={slug}
          onChange={e => setSlug(e.target.value)}
        />
      </div>
      <div className="popup-buttons">
        <button
          className="popup-confirm"
          onClick={() => onConfirm(configName, slug)}
        >
          <span className="btn-text">Yes</span>
        </button>
        <button className="popup-cancel" onClick={onCancel}>
          <span className="n-btn-text">No</span>
        </button>
      </div>
    </Fragment>
  );
};

export const ConfigPopupContent = ({
  data,
  userInfo,
  updateDesc,
  onConfirm,
  onCancel
}) => (
  <Fragment>
    {userInfo === "SUPERADMIN" ? (
      <div>
        <div className="config-popup">
          <label>
            <span>Add Details</span>
          </label>

          <textarea
            placeholder="Description"
            name="Description"
            value={data}
            onChange={updateDesc}
          />
        </div>
        <div className="popup-buttons">
          <button className="popup-confirm" onClick={onConfirm}>
            <span className="btn-text">Save</span>
          </button>
          <button className="popup-cancel" onClick={onCancel}>
            <span className="n-btn-text">Close</span>
          </button>
        </div>
      </div>
    ) : (
      <div className="config-popup">
        <h4>Details</h4>
        <h5>{data}</h5>
      </div>
    )}
  </Fragment>
);

export const UpdateConfigPopupContent = ({
  configDatas,
  onConfirm,
  onCancel
}) => {
  const [data, setData] = useState({
    name: configDatas.name,
    slug: configDatas.slug,
    _id: configDatas._id
  });

  const onDataChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      <div>
        <p>Update Config</p>
        <InputText
          placeholder="Config Name"
          name="name"
          value={data.name}
          onChange={onDataChange}
        />
        <InputText
          placeholder="Slug"
          name="slug"
          value={data.slug}
          onChange={onDataChange}
        />
      </div>
      <div className="popup-buttons">
        <button className="popup-confirm" onClick={() => onConfirm(data)}>
          <span className="btn-text">Yes</span>
        </button>
        <button className="popup-cancel" onClick={onCancel}>
          <span className="n-btn-text">No</span>
        </button>
      </div>
    </Fragment>
  );
};

export const CloneCountryPopupContent = ({
  onCancel,
  countryList,
  content,
  deviceTab,
  platformTab
}) => {
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState();

  const handleCountryChange = ev => {
    setSelectedCountry(ev.value);
  };
  const data = useSelector(store => store.configurationsReducer.activePage);

  useEffect(() => {
    data;
  }, [data]);
  const handleClone = () => {
    dispatch(
      copyToOtherCountries(
        deviceTab,
        platformTab,
        content,
        data,
        selectedCountry
      )
    );
    onCancel();
  };
  return (
    <Fragment>
      <div>
        <h4>Clone to other country</h4>
      </div>
      <div>
        <p>Select Country:</p>
        <CustomSelect
          className="banner-control-select"
          options={countryList.map(country => ({
            label: country.countryName,
            value: country.countryId
          }))}
          onChange={ev => handleCountryChange(ev)}
          value={selectedCountry}
          name="module"
          placeholder="Select module"
        />
      </div>

      <div className="popup-buttons">
        <button className="popup-confirm" onClick={handleClone}>
          <span className="btn-text">Clone</span>
        </button>
        <button className="popup-cancel" onClick={onCancel}>
          <span className="n-btn-text">Close</span>
        </button>
      </div>
    </Fragment>
  );
};
export const CloneTabPopupContent = ({
  onConfirm,
  onCancel,
  rowTitle,
  tab
}) => (
  <Fragment>
    <div style={{ fontSize: "14px" }}>
      Are you sure you want to copy {tab} - {rowTitle} ? You can always undo
      your changes before saving
    </div>
    <div className="popup-buttons">
      <button className="popup-confirm" onClick={onConfirm}>
        <span className="btn-text">Yes</span>
      </button>
      <button className="popup-cancel" onClick={onCancel}>
        <span className="n-btn-text">No</span>
      </button>
    </div>
  </Fragment>
);
