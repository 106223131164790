import React from "react";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import InputText from "../../../component/InputText";

const SchedulerTextArea = ({
  language,
  placeholder,
  handleSchedulerTextChange,
  type,
  keyIndex,
  schedulerData
}) => {
  return (
    <div className={`${language}-wrapper`}>
      <div className={`${language}-content`}>
        <InputText
          label={`${capitalize(language)} Version HTML`}
          name="template"
          placeholder={placeholder}
          value={get(schedulerData, ["template", language], "")}
          onChange={e =>
            handleSchedulerTextChange(e.target.value, type, language, keyIndex)
          }
          textarea
        />
      </div>
    </div>
  );
};

export default SchedulerTextArea;
