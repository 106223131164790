import React, { Component, Fragment } from "react";
import CKEditor from "ckeditor4-react";
import {
  CK_CONFIG_ENGLISH,
  CK_CONFIG_ARABIC,
  CK_EDITOR_URL,
  STANDARD_CK_CONFIG
} from "../../constants";
import { ENV_CONFIG } from "../../config/env";
import ConfirmModal from "../modal/ConfirmModal";
import CKEditorNotes from "./../../component/ckEditorNotes";

CKEditor.editorUrl = CK_EDITOR_URL.url;

class AccordionTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configEnglish: { language: "en" },
      configArabic: { language: "ar" },
      removeTileConfirmModal: false
    };
  }

  toggleContent = contentName =>
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));

  onChange = (evt, language) => {
    const {
      index,
      changeHandler,
      needHelpKey,
      submitBtnValidation
    } = this.props;

    let value = evt.editor.getData();
    changeHandler(index, needHelpKey, language, "answer", value);
    setTimeout(() => {
      submitBtnValidation();
    }, 300);
  };

  onChangeText = (evt, language) => {
    const {
      index,
      changeHandler,
      needHelpKey,
      submitBtnValidation
    } = this.props;

    let value = evt.target.value,
      name = evt.target.name;
    changeHandler(index, needHelpKey, language, name, value);
    setTimeout(() => {
      submitBtnValidation();
    }, 300);
  };

  onChangePosition = evt => {
    const { count, index, updatePosition, submitBtnValidation } = this.props;

    let value = evt.target.value;
    if (value > count || value < 1) {
      return;
    }
    updatePosition(index, value);
    setTimeout(() => {
      submitBtnValidation();
    }, 300);
  };

  onClickPosition = val => {
    const { count, index, updatePosition, submitBtnValidation } = this.props;

    if (val > count || val < 1) {
      return;
    }
    updatePosition(index, val);
    setTimeout(() => {
      submitBtnValidation();
    }, 300);
  };

  onChangeCheckBox = evt => {
    const {
      index,
      checkBoxChangeHandler,
      needHelpKey,
      handledCheckBoxChange,
      submitBtnValidation
    } = this.props;

    let value = evt.target.checked,
      name = evt.target.name;
    checkBoxChangeHandler(index, needHelpKey, name, value);
    handledCheckBoxChange();
    setTimeout(() => {
      submitBtnValidation();
    }, 300);
  };

  handleModal = (modalName, index = null) => {
    this.setState(prevState => ({
      [modalName]: !prevState[modalName],
      index: index
    }));
  };

  render() {
    const { defaultContent, removeTileConfirmModal } = this.state;
    const {
      count,
      index,
      remove,
      item,
      storeId,
      countryId,
      isEnabled
    } = this.props;

    let _list = [];

    for (let i = 1; i <= count; i++) {
      _list.push(
        <li
          onClick={() => {
            this.onClickPosition(i);
          }}
        >
          {i}
        </li>
      );
    }

    return (
      <Fragment>
        {removeTileConfirmModal ? (
          <ConfirmModal
            modalName={"removeTileConfirmModal"}
            onHide={() => {
              this.handleModal("removeTileConfirmModal");
            }}
            confirm={() =>
              remove(index, () => {
                this.handleModal("removeTileConfirmModal");
              })
            }
            message={"Are you sure you want to delete ?"}
          />
        ) : null}
        <div className="wrapper-box">
          <div className="heading-wrapper flex open content">
            <h3>{item.english.longTitle || `FAQ ${index + 1}`}</h3>
            <div className="section-ordering">
              Display Order :{" "}
              <input
                className="position-input center-align"
                type="number"
                min="1"
                max={count}
                id={`${`faq_${index}`}`}
                value={item.position || " -- "}
                placeholder="--"
                onChange={this.onChangePosition}
              />
              <ul>{_list}</ul>
            </div>
            <span
              onClick={() => this.toggleContent("defaultContent")}
              className={`toggle-banner ${defaultContent ? "minus-banner" : "plus-banner"
                }`}
            />
            <span
              className="delete-btn"
              onClick={() => this.handleModal("removeTileConfirmModal", index)}
            />
          </div>

          {defaultContent && (
            <div className="data-wrapper">
              <div className="heading-wrapper flex open content sub-wrapper">
                <h3>Display the FAQ on :</h3>
                <div className="title-checkbox">
                  <div className="checkbox_wrap title">
                    <input
                      type="checkbox"
                      className="custom_checkbox"
                      name="faqFlag"
                      checked={item.faqFlag}
                      onChange={e => this.onChangeCheckBox(e)}
                    />
                    <label className="checkbox_value">FAQ Listing</label>
                  </div>

                  <div className="checkbox_wrap longTitle">
                    <input
                      type="checkbox"
                      className="custom_checkbox"
                      name="onCoverFlag"
                      checked={item.onCoverFlag}
                      onChange={e => this.onChangeCheckBox(e)}
                      disabled={!isEnabled && !item.onCoverFlag}
                    />
                    <label className="checkbox_value">On Tile Hover</label>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="english-content">
                  <div className="form-group">
                    <label>
                      <span className="required" />
                      Short Title (English)
                    </label>
                    <input
                      name="title"
                      className="form-control date-calender"
                      type="text"
                      placeholder="Enter Title"
                      value={item.english.title}
                      onChange={e => {
                        this.onChangeText(e, "english");
                      }}
                    />
                    <label>
                      <span className="required" />
                      Long Title (English)
                    </label>
                    <input
                      name="longTitle"
                      className="form-control date-calender"
                      type="text"
                      placeholder="Enter Title"
                      value={item.english.longTitle}
                      onChange={e => {
                        this.onChangeText(e, "english");
                      }}
                    />
                    <label>
                      <span className="required" />
                      Text Editor(English)
                    </label>
                    <CKEditor
                      activeClass="p10"
                      config={{
                        ...CK_CONFIG_ENGLISH,
                        ...STANDARD_CK_CONFIG,
                        ...{
                          filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                            }/homepage/content/uploadCK?id=${storeId}&key=${"customImage"}&language=${"english"}&storeId=${storeId}&countryId=${countryId}&containerName=${"needHelp"}`
                        },
                        removeButtons: STANDARD_CK_CONFIG.removeButtons
                      }}
                      data={item.english.answer}
                      onChange={e => {
                        this.onChange(e, "english");
                      }}
                      onKey={e => {
                        this.onChange(e, "english");
                      }}
                    />
                    <CKEditorNotes display={[1, 2]} />
                    <label>
                      <span className="required" />
                      Short Title (Arabic)
                    </label>
                    <input
                      name="title"
                      className="form-control date-calender"
                      type="text"
                      dir="rtl"
                      placeholder="Enter Title"
                      value={item.arabic.title}
                      onChange={e => {
                        this.onChangeText(e, "arabic");
                      }}
                    />
                    <label>
                      <span className="required" />
                      Long Title (Arabic)
                    </label>
                    <input
                      name="longTitle"
                      className="form-control date-calender"
                      type="text"
                      dir="rtl"
                      placeholder="Enter Title"
                      value={item.arabic.longTitle}
                      onChange={e => {
                        this.onChangeText(e, "arabic");
                      }}
                    />
                    <label>
                      <span className="required" />
                      Text Editor(Arabic)
                    </label>
                    <CKEditor
                      activeClass="p10"
                      config={{
                        ...CK_CONFIG_ARABIC,
                        ...STANDARD_CK_CONFIG,
                        ...{
                          filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                            }/homepage/content/uploadCK?id=${storeId}&key=${"customImage"}&language=${"arabic"}&storeId=${storeId}&countryId=${countryId}&containerName=${"needHelp"}`
                        },
                        removeButtons: STANDARD_CK_CONFIG.removeButtons
                      }}
                      data={item.arabic.answer}
                      onChange={e => {
                        this.onChange(e, "arabic");
                      }}
                      onKey={e => {
                        this.onChange(e, "arabic");
                      }}
                    />
                    <CKEditorNotes noMargin={true} display={[1, 2]} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default AccordionTile;
