import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const InputCheckbox = ({
  className,
  disabled,
  label,
  name,
  onChange,
  value,
  wrapperClassName
}) => (
  <div className={cn("form-group", wrapperClassName)}>
    <label>{label}</label>
    <div
      className={cn("custom-checkbox", {
        checked: value
      })}
    >
      <input
        className={className}
        checked={value}
        disabled={disabled}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
    </div>
  </div>
);

InputCheckbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  wrapperClassName: PropTypes.string
};

InputCheckbox.defaultProps = {
  value: false,
  onChange: () => {},
  disabled: false
};

export default InputCheckbox;
