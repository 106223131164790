export const SET_CONFIGURATIONS_ACTIVE_PAGE = "SET_CONFIGURATIONS_ACTIVE_PAGE";
export const UPDATE_CONFIG_TYPE_CONFIGURATION_PAGE =
  "UPDATE_CONFIG_TYPE_CONFIGURATION_PAGE";
export const ADD_CONFIG_TYPE_CUSTOM_FIELD = "ADD_CONFIG_TYPE_CUSTOM_FIELD";
export const REMOVE_CONFIG_TYPE_CUSTOM_FIELD =
  "REMOVE_CONFIG_TYPE_CUSTOM_FIELD";
export const UPDATE_CONFIG_DESC_CONFIGURATION_PAGE =
  "UPDATE_CONFIG_DESC_CONFIGURATION_PAGE";
export const SET_INITIAL_CONFIGURATIONS = "SET_INITIAL_CONFIGURATIONS";
export const TOGGLE_RESET_CONFIGURATIONS_PAGE =
  "TOGGLE_RESET_CONFIGURATIONS_PAGE";
export const RESET_CONFIGURATIONS_DATA = "RESET_CONFIGURATIONS_DATA";
export const RESET_CONFIGURATIONS_CONTENT = "RESET_CONFIGURATIONS_CONTENT";
export const RESET_CONFIGURATIONS = "RESET_CONFIGURATIONS";
export const SET_CONFIG_ROLLBACK_HISTORY = "SET_CONFIG_ROLLBACK_HISTORY";
export const TOGGLE_CONFIG_ROLLBACK_SAVE = "TOGGLE_CONFIG_ROLLBACK_SAVE";
export const SAVE_CONFIG_DATA = "SAVE_CONFIG_DATA";
export const SET_CONFIG_DATA = "SET_CONFIG_DATA";
export const CLONE_TAB_CONFIGURATIONS = "CLONE_TAB_CONFIGURATIONS";
export const CLONE_DEVICE_CONFIGURATIONS = "CLONE_DEVICE_CONFIGURATIONS";
export const CLONE_PLATFORM_CONFIGURATIONS = "CLONE_PLATFORM_CONFIGURATIONS";
export const RESET_ACTIVE_PAGE_CONFIGURATIONS =
  "RESET_ACTIVE_PAGE_CONFIGURATIONS";
export const SWITCH_ROW_CONFIGURATION_PAGE =
  "SWITCH_ROW_CONFIGURATION_PAGE";
