import {
  ADD_STATIC_PAGE_TYPE,
  ADD_STATIC_SEO,
  ADD_CK_STATIC_CONTENT,
  STATIC_FETCH_PAGES,
  RESET_STATIC_PAGES,
  RESET_STATIC_ADDPAGE_CONTENT,
  SEO_UPDATE_STATIC,
  ADD_CK_STATIC_ACCORD_CONTENT,
  ADD_CK_STATIC_ACCORD_TILE,
  REMOVE_CK_STATIC_ACCORD_TILE,
  STATIC_TOGGLE_RESET,
  STATIC_CANCEL_ACTION,
  UPDATE_STATIC_SEO,
  UPDATE_CK_STATIC_CONTENT,
  UPDATE_CK_STATIC_ACCORD_CONTENT,
  UPDATE_CK_STATIC_ACCORD_TILE,
  UPDATE_REMOVE_CK_STATIC_ACCORD_TILE,
  UPDATE_STATIC_PAGE_TYPE,
  RESET_UPDATE_STATIC_PAGE
} from "../constants/actions";

const initialState = {
  addPageContent: {
    addComponent: []
  },
  staticAddPageContent: {
    url: "",
    title: "",
    pageType: "",
    storeId: "",
    countryId: "",
    isPublish: false,
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    },
    content: {
      english: {
        html: ""
      },
      arabic: {
        html: ""
      },
      accord: [
        {
          english: { title: "", html: "" },
          arabic: { title: "", html: "" },
          pos: 1
        }
      ]
    }
  },
  initialAddPageContent: {
    url: "",
    title: "",
    pageType: "",
    storeId: "",
    countryId: "",
    isPublish: false,
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    },
    content: {
      english: {
        html: ""
      },
      arabic: {
        html: ""
      },
      accord: [
        {
          english: { title: "", html: "" },
          arabic: { title: "", html: "" },
          pos: 1
        }
      ]
    }
  },
  staticComponentStructure: {},
  staticPages: {
    Html: [],
    Accordion: [],
    Comb_accordion: []
  },
  initialStaticPages: {
    Html: [],
    Accordion: [],
    Comb_accordion: []
  },
  resetDisabled: true,
  closeDropDowns: false
};
const addPageType = (state, { name, value }) => ({
  ...state,
  staticAddPageContent: {
    ...state.staticAddPageContent,
    [name]: value
  },
  closeDropDowns: false
});
const updatePageType = (state, { name, value, pageType, pageLocation }) => ({
  ...state,
  staticPages: {
    ...state.staticPages,
    [pageType]: [
      ...state.staticPages[pageType].slice(0, pageLocation),
      { ...state.staticPages[pageType][pageLocation], [name]: value },
      ...state.staticPages[pageType].slice(pageLocation + 1)
    ]
  },
  closeDropDowns: false
});
const addCkStaticContent = (state, { name, value, lang }) => ({
  ...state,
  staticAddPageContent: {
    ...state.staticAddPageContent,
    content: {
      ...state.staticAddPageContent.content,
      [lang]: {
        ...state.staticAddPageContent.content[lang],
        [name]: value
      }
    }
  },
  closeDropDowns: false
});
const updateCkStaticContent = (
  state,
  { name, value, lang, pageType, pageLocation }
) => ({
  ...state,
  staticPages: {
    ...state.staticPages,
    [pageType]: [
      ...state.staticPages[pageType].slice(0, pageLocation),
      {
        ...state.staticPages[pageType][pageLocation],
        content: {
          ...state.staticPages[pageType][pageLocation].content,
          [lang]: {
            ...state.staticPages[pageType][pageLocation].content[lang],
            [name]: value
          }
        }
      },
      ...state.staticPages[pageType].slice(pageLocation + 1)
    ]
  },
  closeDropDowns: false
});
const addCkStaticAccordContent = (state, { name, value, lang, pos }) => ({
  ...state,
  staticAddPageContent: {
    ...state.staticAddPageContent,
    content: {
      ...state.staticAddPageContent.content,
      accord: [
        ...state.staticAddPageContent.content.accord.slice(0, pos),
        {
          ...state.staticAddPageContent.content.accord[pos],
          [lang]: {
            ...state.staticAddPageContent.content.accord[pos][lang],
            [name]: value
          }
        },
        ...state.staticAddPageContent.content.accord.slice(pos + 1)
      ]
    }
  },
  closeDropDowns: false
});
const updateCkStaticAccordContent = (
  state,
  { name, value, lang, pos, pageType, pageLocation }
) => ({
  ...state,
  staticPages: {
    ...state.staticPages,
    [pageType]: [
      ...state.staticPages[pageType].slice(0, pageLocation),
      {
        ...state.staticPages[pageType][pageLocation],
        content: {
          ...state.staticPages[pageType][pageLocation].content,
          accord: [
            ...state.staticPages[pageType][pageLocation].content.accord.slice(
              0,
              pos
            ),
            {
              ...state.staticPages[pageType][pageLocation].content.accord[pos],
              [lang]: {
                ...state.staticPages[pageType][pageLocation].content.accord[
                  pos
                ][lang],
                [name]: value
              }
            },
            ...state.staticPages[pageType][pageLocation].content.accord.slice(
              pos + 1
            )
          ]
        }
      },
      ...state.staticPages[pageType].slice(pageLocation + 1)
    ]
  },
  closeDropDowns: false
});
const addCkStaticAccordTile = (state, { pos }) => {
  const addNewEntry = {
    english: { title: "", html: "" },
    arabic: { title: "", html: "" },
    pos: pos
  };
  return {
    ...state,
    staticAddPageContent: {
      ...state.staticAddPageContent,
      content: {
        ...state.staticAddPageContent.content,
        accord: [...state.staticAddPageContent.content.accord, addNewEntry]
      }
    },
    closeDropDowns: false
  };
};
const updateCkStaticAccordTile = (state, { pos, pageType, pageLocation }) => {
  const addNewEntry = {
    english: { title: "", html: "" },
    arabic: { title: "", html: "" },
    pos: pos
  };
  return {
    ...state,
    staticPages: {
      ...state.staticPages,
      [pageType]: [
        ...state.staticPages[pageType].slice(0, pageLocation),
        {
          ...state.staticPages[pageType][pageLocation],
          content: {
            ...state.staticPages[pageType][pageLocation].content,
            accord: [
              ...state.staticPages[pageType][pageLocation].content.accord,
              addNewEntry
            ]
          }
        },
        ...state.staticPages[pageType].slice(pageLocation + 1)
      ]
    },
    closeDropDowns: false
  };
};
const removeCkStaticAccordTile = (state, { pos }) => {
  return {
    ...state,
    staticAddPageContent: {
      ...state.staticAddPageContent,
      content: {
        ...state.staticAddPageContent.content,
        accord: [
          ...state.staticAddPageContent.content.accord.slice(0, pos),
          ...state.staticAddPageContent.content.accord.slice(pos + 1)
        ]
      }
    },
    closeDropDowns: false
  };
};

const updateRemoveCkStaticAccordTile = (
  state,
  { pos, pageType, pageLocation }
) => ({
  ...state,
  staticPages: {
    ...state.staticPages,
    [pageType]: [
      ...state.staticPages[pageType].slice(0, pageLocation),
      {
        ...state.staticPages[pageType][pageLocation],
        content: {
          ...state.staticPages[pageType][pageLocation].content,
          accord: [
            ...state.staticPages[pageType][pageLocation].content.accord.slice(
              0,
              pos
            ),
            ...state.staticPages[pageType][pageLocation].content.accord.slice(
              pos + 1
            )
          ]
        }
      },
      ...state.staticPages[pageType].slice(pageLocation + 1)
    ]
  },
  closeDropDowns: false
});
const fetchStaticPages = (state, { pageName, pageObj }) => ({
  ...state,
  staticPages: {
    ...state.staticPages,
    [pageName]: [...state.staticPages[pageName], pageObj]
  },
  initialStaticPages: {
    ...state.initialStaticPages,
    [pageName]: [...state.initialStaticPages[pageName], pageObj]
  },
  closeDropDowns: false
});

const resetStaticPages = state => ({
  ...state,
  staticPages: {
    ...state.staticPages,
    Html: initialState.staticPages.Html,
    Accordion: initialState.staticPages.Accordion,
    Comb_accordion: initialState.staticPages.Comb_accordion
  },
  initialStaticPages: {
    ...state.initialStaticPages,
    Html: initialState.initialStaticPages.Html,
    Accordion: initialState.initialStaticPages.Accordion,
    Comb_accordion: initialState.initialStaticPages.Comb_accordion
  },
  closeDropDowns: false
});
const resetUpdateStaticPage = state => ({
  ...state,
  staticPages: {
    ...state.staticPages,
    Html: state.initialStaticPages.Html,
    Accordion: state.initialStaticPages.Accordion,
    Comb_accordion: state.initialStaticPages.Comb_accordion
  },
  closeDropDowns: true
});
const addStaticSeo = (state, { name, value, lang }) => {
  return {
    ...state,
    staticAddPageContent: {
      ...state.staticAddPageContent,
      meta: {
        ...state.staticAddPageContent.meta,
        [lang]: {
          ...state.staticAddPageContent.meta[lang],
          [name]: value
        }
      }
    },
    closeDropDowns: false
  };
};
const updateStaticSeo = (
  state,
  { name, value, lang, pageType, pageLocation }
) => ({
  ...state,
  staticPages: {
    ...state.staticPages,
    [pageType]: [
      ...state.staticPages[pageType].slice(0, pageLocation),
      {
        ...state.staticPages[pageType][pageLocation],
        meta: {
          ...state.staticPages[pageType][pageLocation].meta,
          [lang]: {
            ...state.staticPages[pageType][pageLocation].meta[lang],
            [name]: value
          }
        }
      },
      ...state.staticPages[pageType].slice(pageLocation + 1)
    ]
  },
  closeDropDowns: false
});
const resetAddPageContent = state => ({
  ...state,
  staticAddPageContent: initialState.staticAddPageContent,
  closeDropDowns: true
});

const seoUpdateStatic = (
  state,
  { name, value, language, pageType, pageLocation }
) => ({
  ...state,
  staticPages: {
    ...state.staticPages,
    [pageType]: [
      ...state.staticPages[pageType].slice(0, pageLocation),
      {
        ...state.staticPages[pageType][pageLocation],
        meta: {
          ...state.staticPages[pageType][pageLocation].meta,
          [language]: {
            ...state.staticPages[pageType][pageLocation].meta[language],
            [name]: value
          }
        }
      },
      ...state.staticPages[pageType].slice(pageLocation + 1)
    ]
  },
  closeDropDowns: false
});

const toggleReset = (state, { value }) => {
  if (state.resetDisabled !== value) {
    return { ...state, resetDisabled: value };
  } else {
    return { ...state };
  }
};

const handleCancelAction = state => {
  return { ...state };
};

const staticReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STATIC_PAGE_TYPE:
      return addPageType(state, action);
    case UPDATE_STATIC_PAGE_TYPE:
      return updatePageType(state, action);
    case ADD_STATIC_SEO:
      return addStaticSeo(state, action);
    case ADD_CK_STATIC_CONTENT:
      return addCkStaticContent(state, action);
    case ADD_CK_STATIC_ACCORD_CONTENT:
      return addCkStaticAccordContent(state, action);
    case STATIC_FETCH_PAGES:
      return fetchStaticPages(state, action);
    case RESET_STATIC_PAGES:
      return resetStaticPages(state);
    case RESET_STATIC_ADDPAGE_CONTENT:
      return resetAddPageContent(state, action);
    case SEO_UPDATE_STATIC:
      return seoUpdateStatic(state, action);
    case ADD_CK_STATIC_ACCORD_TILE:
      return addCkStaticAccordTile(state, action);
    case REMOVE_CK_STATIC_ACCORD_TILE:
      return removeCkStaticAccordTile(state, action);
    case STATIC_TOGGLE_RESET:
      return toggleReset(state, action);
    case STATIC_CANCEL_ACTION:
      return handleCancelAction(state, action);
    case UPDATE_STATIC_SEO:
      return updateStaticSeo(state, action);
    case UPDATE_CK_STATIC_CONTENT:
      return updateCkStaticContent(state, action);
    case UPDATE_CK_STATIC_ACCORD_CONTENT:
      return updateCkStaticAccordContent(state, action);
    case UPDATE_CK_STATIC_ACCORD_TILE:
      return updateCkStaticAccordTile(state, action);
    case UPDATE_REMOVE_CK_STATIC_ACCORD_TILE:
      return updateRemoveCkStaticAccordTile(state, action);
    case RESET_UPDATE_STATIC_PAGE:
      return resetUpdateStaticPage(state, action);
    default:
      return state;
  }
};

export default staticReducer;
