import React from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import {
  CATALOG_PAGE_CAROUSEL_TYPE_B_PLACEHOLDERS,
  LANGUAGE
} from "../../constants";

import { Box, Typography, TextField } from "../../materialUi/components";

const ProductCarouselFormMUI = ({
  content,
  handleTextChange,
  handleUrlChange,
  language
}) => {
  const placeholders = CATALOG_PAGE_CAROUSEL_TYPE_B_PLACEHOLDERS[language];
  const editUrlDisabled = language === LANGUAGE.ARABIC;
  return (
    <Box sx={{ width: "100%" }}>
      <Typography sx={{ marginBottom: "20px" }}>
        {upperFirst(language)} Version
      </Typography>
      <TextField
        autoFocus
        id="title"
        label="Title"
        type="text"
        name="title"
        value={get(content, `${language}.title`, "")}
        onChange={event =>
          handleTextChange(event.target.name, event.target.value || "", language)
        }
        style={{ width: "100%", "marginBottom": "20px" }}
        size="small"
        InputLabelProps={{ shrink: true }}
        placeholder={placeholders.title}
      />
      <TextField
        autoFocus
        id="searchUrl"
        label="Search Url (must start with '/')"
        type="text"
        name="searchUrl"
        value={get(content, `${language}.searchUrl`, "")}
        onChange={event => {
          if (!editUrlDisabled) {
            handleUrlChange(event.target.name, event.target.value || "");
          }
        }}
        style={{ width: "100%" }}
        size="small"
        InputLabelProps={{ shrink: true }}
        placeholder={placeholders.searchUrl}
        disabled={editUrlDisabled}
      />
    </Box>
  );
};

export default ProductCarouselFormMUI;
