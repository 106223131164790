export const CK_CONFIG_ENGLISH = {
  language: "en"
};

export const CK_CONFIG_ARABIC = {
  language: "ar"
};

export const CK_EDITOR_URL = {
  url: "https://cdn.ckeditor.com/4.11.3/full/ckeditor.js",
  standardUrl: "https://cdn.ckeditor.com/4.11.3/standard/ckeditor.js"
};

export const CK_CONFIG = {
  pasteFromWordRemoveStyles: true,
  allowedContent: true,
  extraAllowedContent:
    "div(*);a(documentation);abbr[title];code,font,colorbutton,iframe;*(*);*{*}",
  extraPlugins: "colorbutton,iframe",
  filebrowserUploadUrl: "/",
  colorButton_colors:
    "000,800000,8B4513,2F4F4F,008080,000080,4B0082,696969," +
    "B22222,A52A2A,DAA520,006400,40E0D0,0000CD,800080,808080," +
    "F00,FF8C00,FFD700,008000,0FF,00F,EE82EE,A9A9A9," +
    "FFA07A,FFA500,FFFF00,00FF00,AFEEEE,ADD8E6,DDA0DD,D3D3D3," +
    "FFF0F5,FAEBD7,FFFFE0,F0FFF0,F0FFFF,F0F8FF,E6E6FA,FFF",
  toolBar: [
    {
      name: "basicstyles",
      items: [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "Subscript",
        "Superscript",
        "-",
        "CopyFormatting",
        "RemoveFormat"
      ]
    },
    {
      name: "paragraph",
      items: [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
        "CreateDiv",
        "-",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock",
        "-",
        "BidiLtr",
        "BidiRtl",
        "Language"
      ]
    },
    { name: "links", items: ["Link", "Unlink", "Anchor"] },
    { name: "document", items: ["Source"] },
    {
      name: "clipboard",
      items: [
        "Cut",
        "Copy",
        "Paste",
        "PasteText",
        "PasteFromWord",
        "-",
        "Undo",
        "Redo"
      ]
    },
    {
      name: "insert",
      items: [
        "Image",
        "Flash",
        "Table",
        "HorizontalRule",
        "Smiley",
        "SpecialChar",
        "PageBreak",
        "Iframe"
      ]
    },
    {
      name: "editing",
      items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"]
    },
    {
      name: "forms",
      items: [
        "Form",
        "Checkbox",
        "Radio",
        "TextField",
        "Textarea",
        "Select",
        "Button",
        "ImageButton",
        "HiddenField"
      ]
    },
    { name: "styles", items: ["Styles", "Format"] },
    { name: "colors", items: ["TextColor", "BGColor"] },
    { name: "tools", items: ["Maximize", "ShowBlocks"] },
    { name: "about", items: ["About"] }
  ],
  toolbarGroups: [
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
    },
    { name: "links", groups: ["links"] },
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    { name: "insert", groups: ["insert"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"]
    },
    { name: "forms", groups: ["forms"] },
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] }
  ],
  removeButtons: "About"
};

export const STANDARD_CK_CONFIG = {
  pasteFromWordRemoveStyles: true,
  allowedContent: true,
  extraAllowedContent:
    "div(*);a(documentation);abbr[title];code,colorbutton,iframe;*(*);*{*}",
  filebrowserUploadUrl: "/",
  toolbarGroups: [
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "forms" },
    { name: "basicstyles", groups: ["basicstyles"] },
    { name: "paragraph", groups: ["list", "align"] },
    { name: "links", groups: ["Link", "Unlink"] },
    { name: "insert" },
    { name: "styles", groups: ["styles"] },
    { name: "editing", groups: ["spellchecker"] }
  ],
  removeButtons:
    "Save,Templates,Cut,Undo,Find,SelectAll,Form,ImageButton,CopyFormatting,Outdent,Blockquote,BidiLtr,Anchor,Flash,Smiley,PageBreak,Iframe,Styles,FontSize,Font,TextColor,Maximize,About"
};
