import React from "react";
import cn from "classnames";
import { MEGA_MENU_COLUMN_KEYS } from "../../constants";
import MegaMenuItem from "./megaMenuItem";
import OptionalSelectAttribute from "./optionalSelectAttribute";

const ColoumnMenu = ({
  columnName,
  colValue,
  changeKeyIndex,
  showContent,
  selectedImgCol
}) => {
  const isOpened = showContent.index === colValue && showContent.show;
  const isDisabled = selectedImgCol && colValue > selectedImgCol;
  const showMegaMenuItem = isOpened && colValue < 4;
  const showOptionalSelectAttribute = isOpened && colValue > 3;

  return (
    <div className="wrapper-box">
      <div
        className={cn({
          "heading-wrapper flex open": true,
          "column-header-disabled": isDisabled
        })}
      >
        <h3>{MEGA_MENU_COLUMN_KEYS[columnName]}</h3>
        <span
          onClick={() => changeKeyIndex(colValue, columnName)}
          className={cn({
            "toggle-banner": true,
            "disable-pointer-events": isDisabled,
            "minus-banner": isOpened,
            "plus-banner": !isOpened
          })}
        />
      </div>
      {showMegaMenuItem && <MegaMenuItem columnName={columnName} />}
      {showOptionalSelectAttribute && (
        <OptionalSelectAttribute columnName={columnName} colValue={colValue} />
      )}
    </div>
  );
};

export default ColoumnMenu;
