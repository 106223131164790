import React, { useState, useRef, useEffect } from "react";
import { CustomSelect } from "../../component/customSelect";
import Button from "../../component/button";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { customConfigureType, customConfigureTypeForFilterPage, PLATFORM_TYPES_CONFIG, DEVICE_TYPES_CONFIG, customInputTypes } from "../../constants";
import {
  updateConfigTypeCustomData,
  addRowTypeCustomField,
  removeRowTypeCustomField,
  cloneDevice,
  clonePlatform,
  switchConfigRows,
} from "../../actions/configurationsPage.action";
import { toastMsg } from "../../actions/common.action";
import { changePlatformTab, changeDeviceTab } from "../../actions/dynamicBanner.action";
import BannerTabContent from "./bannerTabContent";
import { CloneTabPopupContent } from "./ConfigPopupContent";
function UpdatePageContent({ updatePageContent, accessRights }) {
  const dispatch = useDispatch();
  const popupRef = useRef();

  const [configType, setConfigType] = useState(customInputTypes.TEXT);
  const configData = _.get(updatePageContent, "children", []);
  const [clonedPlatform, setClonedPlatform] = useState(null);
  const [clonedDevice, setClonedDevice] = useState(null);
  const [isFilterPage, setIsFilterPage] = useState(false);

  useEffect(() => {
    if (updatePageContent.slug === "filterConditions") {
      setIsFilterPage(true);
      setConfigType(customInputTypes.FILTER);
    } else {
      setIsFilterPage(false);
      setConfigType(customInputTypes.TEXT);
    }
  }, [updatePageContent._id]);

  const handleSetClonedPlatform = platform => {
    setClonedPlatform(platform);
  };
  const handleSetClonedDevice = device => {
    setClonedDevice(device);
  };

  const handleDeviceClone = () => {
    dispatch(
      cloneDevice({
        from: clonedDevice,
        to: DEVICE_TYPES_CONFIG[2].key
      })
    );
    dispatch(toastMsg("success", "Cloned successfully"));
  };

  const handlePlatformClone = (device) => {
    dispatch(
      clonePlatform({
        device,
        from: clonedPlatform,
        to: PLATFORM_TYPES_CONFIG[1].key
      })
    );
    dispatch(toastMsg("success", "Cloned successfully"));
  };

  const handleUpdateConfigTypeCustomData = (
    index,
    device,
    platform,
    language,
    name,
    value,
    data,
    id
  ) => {
    dispatch(
      updateConfigTypeCustomData({
        index,
        device,
        platform,
        language,
        name,
        value: name == "key" ? value.trim() : value,
        data,
        id
      })
    );
  };

  const handleAddRowTypeCustomField = () => {
    setConfigType(configType);
    dispatch(
      addRowTypeCustomField({
        configType: configType,
        device: deviceTab,
        platform: platformTab,
      })
    );
  };

  const handleRemoveRowTypeCustomField = (index, device, platform, id) => {
    dispatch(
      removeRowTypeCustomField({
        index,
        device,
        platform,
        id
      })
    );
    popupRef.current.close();
  };

  const platformTab = useSelector(
    ({ common: { devicePlatformTab } }) => devicePlatformTab
  );

  const deviceTab = useSelector(
    ({ common: { deviceTab } }) => deviceTab
  );

  const handleChangeTab = key => () => {
    dispatch(changePlatformTab({ deviceType: key }));
  };

  const handleChangeDevice = key => () => {
    dispatch(changeDeviceTab({ deviceType: key }));
    dispatch(changePlatformTab({ deviceType: PLATFORM_TYPES_CONFIG[0].key }));
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!source || !destination) return;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    dispatch(switchConfigRows({ sourceIndex, destinationIndex, device: deviceTab, platform: platformTab }));
  }

  return (
    <div>
      <div className="dynamicBannerModule">
        <div className="wrapper-box">
          <div className="data-wrapper">
            <div className="st st-theme-default">
              <ul className="nav">
                {DEVICE_TYPES_CONFIG.map(device => (
                  <li key={device.key} id="config-device-nav">
                    <a
                      onClick={
                        handleChangeDevice(device.key)
                      }
                      style={
                        device.key === deviceTab
                          ? { background: "#FFD100" }
                          : {}
                      }
                      className="nav-link d-flex justify-content-center"
                    >
                      {device.label}
                      {device.key == DEVICE_TYPES_CONFIG[1].key && (
                        <span
                          className="clone-btn-config-tab"
                          data-tip={`Copy content to ${DEVICE_TYPES_CONFIG[2].label}`}
                          data-for="config-tooltip"
                          onClick={
                            () => handleSetClonedDevice(device.key)
                          }
                        />
                      )}
                    </a>
                  </li>
                ))}
                {clonedDevice && (
                  <div className={`popup${clonedDevice}`} style={{ width: "28%" }}>
                    <CloneTabPopupContent
                      onConfirm={() => {
                        handleDeviceClone();
                        setClonedDevice(null);
                      }}
                      rowTitle={
                        DEVICE_TYPES_CONFIG[2].label
                      }
                      tab={DEVICE_TYPES_CONFIG[1].label}
                      onCancel={() => setClonedDevice(null)}
                    />
                  </div>
                )}
              </ul>
              <ul className="nav">
                {(deviceTab !== DEVICE_TYPES_CONFIG[0].key) && PLATFORM_TYPES_CONFIG.map(platform => (
                  <li key={platform.label} id="config-platform-nav">
                    <a
                      onClick={handleChangeTab(platform.key)}
                      style={
                        platform.key === platformTab
                          ? { background: "#adc4f7" }
                          : {}
                      }
                      className="nav-link d-flex justify-content-center"
                    >
                      {platform.label}
                      {platform.key == PLATFORM_TYPES_CONFIG[0].key && (
                        <span
                          className="clone-btn-config-tab"
                          data-tip={`Copy content to ${PLATFORM_TYPES_CONFIG[1].label}`}
                          data-for="config-tooltip"
                          onClick={
                            () => handleSetClonedPlatform(platform.key)
                          }
                        />
                      )}
                    </a>
                  </li>
                ))}
                {clonedPlatform && (
                  <div className={`popup${clonedPlatform}`} style={{ width: "28%" }}>
                    <CloneTabPopupContent
                      onConfirm={() => {
                        handlePlatformClone(deviceTab);
                        setClonedPlatform(null);
                      }}
                      rowTitle={
                        PLATFORM_TYPES_CONFIG[1].label
                      }
                      tab={PLATFORM_TYPES_CONFIG[0].label}
                      onCancel={() => setClonedPlatform(null)}
                    />
                  </div>
                )}
              </ul>
              <div className="tab-content">
                <div className="tab-pane active">
                  <div className="selection-wrapper">
                    <CustomSelect
                      options={!isFilterPage ? customConfigureType : customConfigureTypeForFilterPage}
                      onChange={ev => setConfigType(ev.value)}
                      value={configType}
                      name="value"
                      placeholder="Configuration Type"
                      className={"conf-select-box"}
                    />
                    <Button name="Add" onClick={handleAddRowTypeCustomField} />
                  </div>
                  <BannerTabContent
                    configData={
                      deviceTab === DEVICE_TYPES_CONFIG[0].key ?
                        configData[deviceTab] :
                        configData[deviceTab][platformTab]}
                    handleUpdateConfigTypeCustomData={
                      handleUpdateConfigTypeCustomData
                    }
                    handleRemoveRowTypeCustomField={
                      handleRemoveRowTypeCustomField
                    }
                    onDragEnd={onDragEnd}
                    popupRef={popupRef}
                    deviceTab={deviceTab}
                    platformTab={platformTab}
                    accessRights={accessRights}
                    isFilterPage={isFilterPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip place="top" effect="solid" id="config-tooltip" />
    </div>
  );
}

export default UpdatePageContent;
