import React, { useState } from "react";
import { useDispatch } from "react-redux";
import JSONEditor from "./jsoneditor";
import { ERROR_MESSAGES } from "../constants";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '../materialUi/components';
import { snackbar } from "../actions/common.action";

const JsonConfigDialog = ({ open, handleClose, handleSave, value, title, dialogContext }) => {
  const dispatch = useDispatch();
  const [modalValue, setmodalValue] = useState(value || "{}");

  const validateForm = () => {
    let isError = false;
    if (!modalValue) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.JSONRequired }));
      return false;
    }
    try {
      JSON.parse(modalValue);
    }
    catch (error) {
      isError = true;
    }
    finally {
      if (isError) {
        dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.removeErrors }));
        return false;
      } else {
        return true;
      }
    }
  }
  const onChangeText = (text) => {
    setmodalValue(text);
  }
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {dialogContext}
          </DialogContentText>
          <JSONEditor
            text={modalValue}
            mode="code"
            modes={["code"]}
            indentation={4}
            onChangeText={onChangeText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => validateForm() && handleSave(modalValue)}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default JsonConfigDialog