import {
  GENERATE_IMAGES,
  DELETE_LIST_ITEM,
  TOGGLE_LIST_ITEM,
  UPDATE_LIST_DATA,
  UPDATE_BANNER_SECTION_DATA_STYLES,
  PUT_BANNER_DATA_STYLES,
  CLEAR_BANNER_ROW_IMAGE_DATA,
  UPDATE_BANNER_SECTION_DATA,
  ADD_BANNER_SCHEDULER,
  HANDLE_DELETE_BANNER_SCHEDULER,
  HANDLE_DATA_CHANGE_BANNER_SCHEDULER,
  HANDLE_ROW_SCHEDULER_TOGGLE,
  CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA,
  CHANGE_BANNER_IMAGE_HOVER_STYLE,
  ADD_BANNER_ROW_ITEM,
  ADD_BANNER_SLIDER_ROW_IMAGES,
  DELETE_ROW_IMAGE,
  GENERATE_BANNER_ROW_IMAGES,
  GENERATE_BANNER_SCHEDULER_IMAGES,
  REORDER_BANNER_ROW_IMAGES,
  UPDATE_BANNER_ROW_IMAGE_DATA,
  UPDATE_SCHEDULER_BANNER_ROW_IMAGE_DATA,
  COUNTDOWN_DATA_CHANGE,
  ITEMS_PER_GROUP_CHANGE,
  PRODUCT_CAROUSEL_DATA_CHANGE,
  ADD_SCHEDULER_DEVICE_LEVEL,
  DELETE_SCHEDULER_DEVICE_LEVEL,
  TOGGLE_SCHEDULER_DEVICE_LEVEL,
  SCHEDULER_DATE_CHANGE_DEVICE_LEVEL,
  SCHEDULER_DATA_CHANGE_DEVICE_LEVEL,
  COPY_CONTENT_TO_SCHEDULER,
  COPY_PARENT_CONTENT_TO_SCHEDULER,
  MODULE_DATA_CHANGE,
  CLONE_MODULE,
  CLONE_ROW,
  CLONE_SCHEDULER,
  CLONE_MOBILE,
  CHANGE_DEVICE_TAB,
  UPDATE_ROW_TYPE_CUSTOM_DATA,
  ADD_ROW_TYPE_CUSTOM_FIELD,
  UPDATE_ROW_TYPE_CUSTOM_MODULE,
  REMOVE_ROW_TYPE_CUSTOM_FIELD,
  APPLY_GENERAL_SPACING,
  CHANGE_PLATFORM_TAB,
  CLONE_BANNER_SCHEDULER,
  ADD_ROW_TYPE_ACCORDION_FIELD,
  UPDATE_ROW_TYPE_ACCORDION_DATA,
  UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA,
  REMOVE_ROW_TYPE_ACCORDION_FIELD,
  ADD_ROW_TYPE_BULLET_FIELD,
  UPDATE_ROW_TYPE_BULLET_DATA,
  UPDATE_ROW_TYPE_BULLET_HEADER_DATA,
  REMOVE_ROW_TYPE_BULLET_FIELD,
  ADD_ROW_TYPE_TAB_FIELD,
  UPDATE_ROW_TYPE_TAB_DATA,
  UPDATE_ROW_TYPE_TABS,
  REMOVE_ROW_TYPE_TAB,
  ADD_ROW_TYPE_COLUMN_FIELD,
  REMOVE_ROW_TYPE_COLUMN_FIELD,
  ADD_ROW_TYPE_COLUMN_MENU,
  UPDATE_ROW_TYPE_COLUMN_MENU,
  DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD,
} from "../constants/actions";
import { ENV_CONFIG } from "../config/env";
import {
  fetchWebApi,
} from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";

export const generateImages = ({
  path,
  numberOfRows,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${GENERATE_IMAGES}_${pageType}`,
  path,
  numberOfRows,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const toggleListItem = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${TOGGLE_LIST_ITEM}_${pageType}`,
  path,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const deleteListItem = ({
  path,
  listIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${DELETE_LIST_ITEM}_${pageType}`,
  path,
  listIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const updateHomepageListData = ({
  path,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${UPDATE_LIST_DATA}_${pageType}`,
  path,
  name,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const updateBannerSectionDataStyles = ({
  path,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${UPDATE_BANNER_SECTION_DATA_STYLES}_${pageType}`,
  path,
  name,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const putBannerDataStyles = ({
  value,
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${PUT_BANNER_DATA_STYLES}_${pageType}`,
  value,
  path,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const clearBannerRowImageData = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${CLEAR_BANNER_ROW_IMAGE_DATA}_${pageType}`,
  path,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const updateBannerSectionData = ({
  path,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${UPDATE_BANNER_SECTION_DATA}_${pageType}`,
  path,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const addNewBannerScheduler = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${ADD_BANNER_SCHEDULER}_${pageType}`,
  path,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const handleDeleteBannerScheduler = ({
  path,
  schedulerIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${HANDLE_DELETE_BANNER_SCHEDULER}_${pageType}`,
  path,
  schedulerIndex,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const handleDataChangeBannerScheduler = ({
  path,
  key,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${HANDLE_DATA_CHANGE_BANNER_SCHEDULER}_${pageType}`,
  path,
  key,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const handleRowSchedulerToggle = ({ path, key, value, pageType }) => ({
  type: `${HANDLE_ROW_SCHEDULER_TOGGLE}_${pageType}`,
  path,
  key,
  value
});

export const clearBannerSchedulerRowImageData = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA}_${pageType}`,
  path,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const changeBannerImageHoverStyle = ({
  key,
  value,
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${CHANGE_BANNER_IMAGE_HOVER_STYLE}_${pageType}`,
  key,
  value,
  path,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const addBannerRowItem = ({
  path,
  pageType,
  itemType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${ADD_BANNER_ROW_ITEM}_${pageType}`,
  path,
  itemType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const addBannerCarouselRowImages = ({
  path,
  itemsPerGroup,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${ADD_BANNER_SLIDER_ROW_IMAGES}_${pageType}`,
  path,
  itemsPerGroup,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const deleteRowImage = ({
  path,
  imageIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${DELETE_ROW_IMAGE}_${pageType}`,
  path,
  imageIndex,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const generateBannerRowImages = ({
  name,
  numberOfImages,
  keyIndex,
  listName,
  listIndex,
  deviceType,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${GENERATE_BANNER_ROW_IMAGES}_${pageType}`,
  numberOfImages,
  keyIndex,
  listName,
  listIndex,
  deviceType,
  name,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const handleImageListChangeBannerScheduler = ({
  schedulerIndex,
  dataKey,
  data,
  listPosition,
  deviceType,
  rowIndex,
  listName = "rows",
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${GENERATE_BANNER_SCHEDULER_IMAGES}_${pageType}`,
  schedulerIndex,
  dataKey,
  data,
  listPosition,
  deviceType,
  listName,
  rowIndex,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const reorderBannerRowImages = ({
  path,
  source,
  destination,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${REORDER_BANNER_ROW_IMAGES}_${pageType}`,
  path,
  source,
  destination,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const updateBannerRowItemData = ({
  path,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${UPDATE_BANNER_ROW_IMAGE_DATA}_${pageType}`,
  path,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const updateSchedulerBannerRowItemData = ({
  name,
  value,
  deviceType,
  rowIndex,
  itemListIndex,
  language,
  listPosition,
  schedulerIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${UPDATE_SCHEDULER_BANNER_ROW_IMAGE_DATA}_${pageType}`,
  name,
  value,
  deviceType,
  rowIndex,
  itemListIndex,
  language,
  listPosition,
  schedulerIndex,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const countdownDataChange = ({
  path,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${COUNTDOWN_DATA_CHANGE}_${pageType}`,
  path,
  name,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const moduleDataChange = ({
  path,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${MODULE_DATA_CHANGE}_${pageType}`,
  path,
  name,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const itemsPerGroupChange = ({
  path,
  itemsPerGroup,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${ITEMS_PER_GROUP_CHANGE}_${pageType}`,
  path,
  itemsPerGroup,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const productCarouselDataChange = ({
  path,
  value,
  language,
  name,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${PRODUCT_CAROUSEL_DATA_CHANGE}_${pageType}`,
  path,
  value,
  language,
  name,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const addSchedulerDeviceLevel = ({
  listPosition,
  deviceType,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${ADD_SCHEDULER_DEVICE_LEVEL}_${pageType}`,
  listPosition,
  deviceType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const deleteSchedulerDeviceLevel = ({
  listPosition,
  deviceType,
  schedulerIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${DELETE_SCHEDULER_DEVICE_LEVEL}_${pageType}`,
  listPosition,
  deviceType,
  schedulerIndex,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const copyParentContentToBannerScheduler = ({
  listPosition,
  deviceType,
  schedulerIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${COPY_PARENT_CONTENT_TO_SCHEDULER}_${pageType}`,
  listPosition,
  deviceType,
  schedulerIndex,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const toggleSchedulerDeviceLevel = ({
  listPosition,
  deviceType,
  schedulerIndex,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${TOGGLE_SCHEDULER_DEVICE_LEVEL}_${pageType}`,
  listPosition,
  deviceType,
  schedulerIndex,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const schedulerDateChangeDeviceLevel = ({
  listPosition,
  deviceType,
  schedulerIndex,
  key,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${SCHEDULER_DATE_CHANGE_DEVICE_LEVEL}_${pageType}`,
  listPosition,
  deviceType,
  schedulerIndex,
  key,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const cloneBannerScheduler = ({
  listPosition,
  deviceType,
  schedulerData,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${CLONE_BANNER_SCHEDULER}_${pageType}`,

  listPosition,
  deviceType,
  schedulerData,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const schedulerDataChangeDeviceLevel = ({
  listPosition,
  deviceType,
  schedulerIndex,
  key,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${SCHEDULER_DATA_CHANGE_DEVICE_LEVEL}_${pageType}`,
  listPosition,
  deviceType,
  schedulerIndex,
  key,
  value,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const copyRowContentToScheduler = ({
  path,
  schedulerIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${COPY_CONTENT_TO_SCHEDULER}_${pageType}`,
  schedulerIndex,
  path,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const cloneModule = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${CLONE_MODULE}_${pageType}`,
  path,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const cloneRow = ({
  path,
  rowIndex,
  moduleId,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${CLONE_ROW}_${pageType}`,
  path,
  rowIndex,
  moduleId,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const cloneScheduler = ({
  path,
  schedulerIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${CLONE_SCHEDULER}_${pageType}`,
  path,
  schedulerIndex,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const cloneTab = ({
  path,
  tab,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${CLONE_MOBILE}_${pageType}`,
  path,
  tab,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const changeDeviceTab = ({ deviceType }) => ({
  type: CHANGE_DEVICE_TAB,
  deviceType
});

export const changePlatformTab = ({ deviceType }) => ({
  type: CHANGE_PLATFORM_TAB,
  deviceType
});

export const updateRowTypeCustomData = ({
  path,
  index,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${UPDATE_ROW_TYPE_CUSTOM_DATA}_${pageType}`,
  path,
  index,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const addRowTypeCustomField = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${ADD_ROW_TYPE_CUSTOM_FIELD}_${pageType}`,
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const updateRowTypeCustomModuleField = ({
  path,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${UPDATE_ROW_TYPE_CUSTOM_MODULE}_${pageType}`,
  path,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const removeRowTypeCustomField = ({
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${REMOVE_ROW_TYPE_CUSTOM_FIELD}_${pageType}`,
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const addRowTypeAccordionField = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
}) => ({
  type: `${ADD_ROW_TYPE_ACCORDION_FIELD}_${pageType}`,
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
});

export const updateRowTypeAccordionData = ({
  path,
  index,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
}) => ({
  type: `${UPDATE_ROW_TYPE_ACCORDION_DATA}_${pageType}`,
  path,
  index,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
});

export const updateRowTypeAccordionHeaderData = ({
  path,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
}) => ({
  type: `${UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA}_${pageType}`,
  path,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
});

export const removeRowTypeAccordionField = ({
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
}) => ({
  type: `${REMOVE_ROW_TYPE_ACCORDION_FIELD}_${pageType}`,
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
});

export const addRowTypeBulletField = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
}) => ({
  type: `${ADD_ROW_TYPE_BULLET_FIELD}_${pageType}`,
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
});

export const updateRowTypeBulletData = ({
  path,
  index,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
}) => ({
  type: `${UPDATE_ROW_TYPE_BULLET_DATA}_${pageType}`,
  path,
  index,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
});

export const updateRowTypeBulletHeaderData = ({
  path,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
}) => ({
  type: `${UPDATE_ROW_TYPE_BULLET_HEADER_DATA}_${pageType}`,
  path,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
});

export const removeRowTypeBulletField = ({
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
}) => ({
  type: `${REMOVE_ROW_TYPE_BULLET_FIELD}_${pageType}`,
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
});

export const addRowTypeTabField = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${ADD_ROW_TYPE_TAB_FIELD}_${pageType}`,
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const updateRowTypeTabData = ({
  path,
  index,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
}) => ({
  type: `${UPDATE_ROW_TYPE_TAB_DATA}_${pageType}`,
  path,
  index,
  name,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  language,
});

export const updateRowTypeTabs = ({
  path,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
}) => ({
  type: `${UPDATE_ROW_TYPE_TABS}_${pageType}`,
  path,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
});


export const removeRowTypeTab = ({
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
}) => ({
  type: `${REMOVE_ROW_TYPE_TAB}_${pageType}`,
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
});

export const addRowTypeColumn = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${ADD_ROW_TYPE_COLUMN_FIELD}_${pageType}`,
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const removeRowTypeColumn = ({
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
}) => ({
  type: `${REMOVE_ROW_TYPE_COLUMN_FIELD}_${pageType}`,
  path,
  index,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
});

export const addRowTypeColumnMenu = ({
  path,
  index,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
}) => ({
  type: `${ADD_ROW_TYPE_COLUMN_MENU}_${pageType}`,
  path,
  index,
  value,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
});

export const dragAndDropRowTypeColumn = ({
  path,
  sourceIndex,
  destinationIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
}) => ({
  type: `${DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD}_${pageType}`,
  path,
  sourceIndex,
  destinationIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
});

export const updateRowTypeColumnMenu = ({
  path,
  index,
  value,
  key,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
}) => ({
  type: `${UPDATE_ROW_TYPE_COLUMN_MENU}_${pageType}`,
  path,
  index,
  value,
  key,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
});

export const applyGeneralSpacing = ({
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => ({
  type: `${APPLY_GENERAL_SPACING}_${pageType}`,
  path,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
});

export const fetchRedirectionUrlList = (limit = 10, skip = 0, searchText = "") => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/url/list?limit=${limit}&skip=${skip}&search=${searchText}`;
  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchRedirectionCatalogUrlList = (limit = 10, skip = 0, searchText = "") => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/url/list-catalog?limit=${limit}&skip=${skip}&search=${searchText}`;
  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
