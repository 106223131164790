import React from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import { CATALOG_PAGE_GENERAL_SEO_CONTENT_PLACEHOLDERS, LANGUAGE } from "../../constants";

const GeneralSeoContentForm = ({ handleUpdate, seoContent, language }) => {
  const placeholders = CATALOG_PAGE_GENERAL_SEO_CONTENT_PLACEHOLDERS[language];
  return (
    <div className={`${language}-wrapper`}>
      <h4>{upperFirst(language)} Version</h4>
      <div className={`${language}-content`}>
        <div className="form-group">
          <label>
            <span className="required" />
            Page Title
          </label>
          <textarea
            name="title"
            rows="4"
            cols="50"
            dir={language === LANGUAGE.ARABIC ? "rtl" : ""}
            className="form-control date-calender"
            placeholder={placeholders.title}
            value={get(seoContent, `${language}.title`, "")}
            onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          />
        </div>
        <div className="form-group">
          <label>
            <span className="required" />
            Meta Keywords
          </label>
          <input
            name="metaKeyword"
            dir={language === LANGUAGE.ARABIC ? "rtl" : ""}
            className="form-control date-calender"
            type="text"
            placeholder={placeholders.metaKeyword}
            value={get(seoContent, `${language}.metaKeyword`, "")}
            onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          />
        </div>
        <div className="form-group">
          <label>
            <span className="required" />
            Meta Description
          </label>
          <textarea
            name="metaDescription"
            dir={language === LANGUAGE.ARABIC ? "rtl" : ""}
            rows="6"
            cols="50"
            placeholder={placeholders.metaDescription}
            value={get(seoContent, `${language}.metaDescription`, "")}
            onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          />
        </div>
        <div className="form-group">
          <label>
            <span className="required" />
            Heading H1
          </label>
          <input
            name="headingH1"
            dir={language === LANGUAGE.ARABIC ? "rtl" : ""}
            className="form-control date-calender"
            type="text"
            placeholder={placeholders.headingH1}
            value={get(seoContent, `${language}.headingH1`, "")}
            onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          />
        </div>
        <div className="form-group">
          <label>
            <span className="required" />
            Heading H2
          </label>
          <input
            name="headingH2"
            dir={language === LANGUAGE.ARABIC ? "rtl" : ""}
            className="form-control date-calender"
            type="text"
            placeholder={placeholders.headingH2}
            value={get(seoContent, `${language}.headingH2`, "")}
            onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralSeoContentForm;
