import qs from "query-string";

import {
  fetchWebApi,
  deleteWebApi,
  putDashboardWebApi,
  postDashboardWebApi
} from "../webapis/apiResource";
import { ENV_CONFIG } from "../config/env";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  toastMsg
} from "../actions/common.action";
import { SET_NAV_MENU_LIST } from "../constants/actions";
import { fetchUserPermission } from "./user.action";
import { SUCCESS_MESSAGES, ERROR_MESSAGES } from "../constants";

const setNavMenuManagementData = data => ({
  type: SET_NAV_MENU_LIST,
  data
});

export const fetchNavMenuManagementData = () => {
  let apiURL = `${ENV_CONFIG.configBaseURL}/nav-menus`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(setNavMenuManagementData(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const addNavMenuData = data => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  const state = getState();

  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/nav-menus`
    }),
    data
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(toastMsg("success", "Nav menu created successfully"));
          dispatch(setNavMenuManagementData(response.data.data));
          dispatch(fetchUserPermission());
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message || "Something went wrong"
            )
          );
        }
      }
      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);
      return error.response;
    });
};

export const updateNavMenuData = (data, message) => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  const state = getState();

  return putDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/nav-menus/${data._id}`
    }),
    data
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(toastMsg("success", message));
          dispatch(setNavMenuManagementData(response.data.data));
          dispatch(fetchUserPermission());
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message || "Something went wrong"
            )
          );
        }
      }
      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);
      return error.response;
    });
};

export const deleteNavMenuData = id => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  const state = getState();

  return deleteWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/nav-menus/${id}`
    })
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(toastMsg("success", "Deleted successfully"));
          dispatch(setNavMenuManagementData(response.data.data));
          dispatch(fetchUserPermission());
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message || "Something went wrong"
            )
          );
        }
      }
      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);
      return error.response;
    });
};
