import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _, { cloneDeep } from "lodash";
import Toggle from "react-toggle";
import Popup from "reactjs-popup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CustomizedMenus, IconButton, Tooltip, Button } from '../../materialUi/components';
import { DeleteIcon as MUIDeleteIcon, VisibilityIcon, ContentCopyIcon } from '../../materialUi/icons';
import DeleteIcon from "../../images/dustbin.png";
import { customInputTypes, MESSAGES, filterOptions, CONFIGURATIONS_PINS_CONFIG_MATRIX, CONFIGURATIONS_PINS_COORDS, CONFIGURATIONS_MEDIA_OPTIONS, CONFIGURATIONS_MEDIA_TYPES } from "../../constants";
import { CustomSelect } from "../../component/customSelect";
import penIcon from "../../images/pen-icon.svg";
import { DragPins } from "../../component/svg/DragPins";
import {
  CloneCountryPopupContent,
  ConfigPopupContentDelete
} from "./ConfigPopupContent";
import EditFilterConfigModal from './editFilterConfigModal';
import MediaConfigDialog from './mediaConfigDialog';
import JsonConfigDialog from '../../component/jsonConfigDialogV2';
import { PreviewImageModal } from './PreviewImageModal'
import { PreviewVideoModal } from './PreviewVideoModal'
import { snackbar } from "../../actions/common.action";
import { CopyToClipboard } from 'react-copy-to-clipboard';


function BannerTabContent({
  configData,
  handleUpdateConfigTypeCustomData,
  popupRef,
  handleRemoveRowTypeCustomField,
  deviceTab,
  platformTab,
  accessRights,
  isFilterPage,
  onDragEnd,
}) {
  const EN_EDIT_FILTER_CONFIG_MODAL = "en_editFilterConfigModal";
  const AR_EDIT_FILTER_CONFIG_MODAL = "ar_editFilterConfigModal";
  const EN_MEDIA_DETAIL_MODAL = "en_mediaDetailModal";
  const AR_MEDIA_DETAIL_MODAL = "ar_mediaDetailModal";
  const PREVIEW_IMAGE_MODAL = "previewImageModal";
  const PREVIEW_VIDEO_MODAL = "previewVideoModal";
  const EN_JSON_MODAL = "en_jsonModal";
  const AR_JSON_MODAL = "ar_jsonModal";
  const dispatch = useDispatch();
  const countryList = useSelector(store => store.store.countryList);
  const [showModal, setShowModal] = useState({
    [EN_EDIT_FILTER_CONFIG_MODAL]: false,
    [AR_EDIT_FILTER_CONFIG_MODAL]: false,
    [EN_MEDIA_DETAIL_MODAL]: false,
    [AR_MEDIA_DETAIL_MODAL]: false,
    [PREVIEW_IMAGE_MODAL]: false,
    [PREVIEW_VIDEO_MODAL]: false,
    [EN_JSON_MODAL]: false,
    [AR_JSON_MODAL]: false,
  });
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [previewUrl, setPreviewUrl] = useState("");
  const radioChangeEnglish = (
    e,
    index,
    deviceTab,
    platformTab,
    language,
    value,
    item,
    _id
  ) => {
    handleUpdateConfigTypeCustomData(
      index,
      deviceTab,
      platformTab,
      language,
      value,
      e.currentTarget.value,
      item,
      _id
    );
  };
  const radioChangeArabic = (
    e,
    index,
    deviceTab,
    platformTab,
    language,
    value,
    item,
    _id
  ) => {
    handleUpdateConfigTypeCustomData(
      index,
      deviceTab,
      platformTab,
      language,
      value,
      e.currentTarget.value,
      item,
      _id
    );
  };
  const handleModal = (modalName, value, index) => {
    setShowModal({ [modalName]: value });
    setSelectedIndex(index);
  }

  const OnConfirmEditFilterConfigModal = (index, language, key, value) => {
    handleUpdateConfigTypeCustomData(
      index,
      deviceTab,
      platformTab,
      language,
      key,
      value,
      configData[index][language],
      configData[index]._id
    );
  }

  const menuClickHandler = (
    menu, menuIndex, index,
    deviceTab, platformTab, language,
    key, item, _id, modalName) => {
    let value = JSON.parse(item.value);
    if (menu.value === CONFIGURATIONS_MEDIA_TYPES.IMAGE || menu.value === CONFIGURATIONS_MEDIA_TYPES.VIDEO) {
      value["mediaType"] = menu.value;
      value = JSON.stringify(value);
      handleUpdateConfigTypeCustomData(
        index,
        deviceTab,
        platformTab,
        language,
        key,
        value,
        item,
        _id
      );
    }
    handleModal(modalName, true, index);
  }

  const menuDeleteButtonHandler = (index, deviceTab, platformTab, language, key, item, _id) => {

    handleUpdateConfigTypeCustomData(
      index,
      deviceTab,
      platformTab,
      language,
      key,
      "{}",
      item,
      _id
    );

  }

  const getMenuRows = (mediaType) => {
    return CONFIGURATIONS_MEDIA_OPTIONS.map((menu) => {
      let disabled = false;
      if (mediaType && menu.value !== "edit") {
        disabled = true;
      } else if (!mediaType && menu.value === "edit") {
        disabled = true;
      }
      return { ...menu, disabled }
    })
  }

  const OnConfirmMediaConfigModal = (index, language, key, value) => {
    const item = configData[index][language];
    const _id = configData[index]._id;
    handleUpdateConfigTypeCustomData(
      index,
      deviceTab,
      platformTab,
      language,
      key,
      value,
      item,
      _id
    );
  }

  const OnConfirmJsonConfigModal = (index, language, key, value) => {
    const item = configData[index][language];
    const _id = configData[index]._id;
    handleUpdateConfigTypeCustomData(
      index,
      deviceTab,
      platformTab,
      language,
      key,
      value,
      item,
      _id
    );
  }

  const OnCloseMediaConfigModal = (index, language, key) => {
    const item = configData[index][language];
    const _id = configData[index]._id;
    const value = JSON.parse(item.value);
    if (value.mediaType && !value.redirectionLink) {
      handleUpdateConfigTypeCustomData(
        index,
        deviceTab,
        platformTab,
        language,
        key,
        "{}",
        item,
        _id
      );
    }

  }
  const handlePreviewUrl = (mediaDetails = {}) => {
    if (mediaDetails.mediaType === CONFIGURATIONS_MEDIA_TYPES.IMAGE && mediaDetails.imageURL) {
      setPreviewUrl(mediaDetails.imageURL);
    } else if (mediaDetails.mediaType === CONFIGURATIONS_MEDIA_TYPES.VIDEO && mediaDetails.videoURL) {
      setPreviewUrl(mediaDetails.videoURL);
    }
  }
  const getCopyText = (mediaDetails = {}) => {
    let copiedText = "";
    if (mediaDetails.mediaType === CONFIGURATIONS_MEDIA_TYPES.IMAGE && mediaDetails.imageURL) {
      copiedText = mediaDetails.imageURL;
    } else if (mediaDetails.mediaType === CONFIGURATIONS_MEDIA_TYPES.VIDEO && mediaDetails.videoURL) {
      copiedText = mediaDetails.videoURL;
    }
    return copiedText;
  }

  return (
    <div>
      {showModal[EN_EDIT_FILTER_CONFIG_MODAL] &&
        <EditFilterConfigModal
          modalName={EN_EDIT_FILTER_CONFIG_MODAL}
          onHide={() => { handleModal(EN_EDIT_FILTER_CONFIG_MODAL, false, -1) }}
          confirm={(data) => {
            OnConfirmEditFilterConfigModal(selectedIndex, "english", "additionalConfig", data)
            handleModal(EN_EDIT_FILTER_CONFIG_MODAL, false, -1)
          }}
          data={configData[selectedIndex].english.additionalConfig || {}}
          filterType={configData[selectedIndex].english.filterType}
        />
      }
      {showModal[AR_EDIT_FILTER_CONFIG_MODAL] &&
        <EditFilterConfigModal
          modalName={AR_EDIT_FILTER_CONFIG_MODAL}
          onHide={() => { handleModal(AR_EDIT_FILTER_CONFIG_MODAL, false, -1) }}
          confirm={(data) => {
            OnConfirmEditFilterConfigModal(selectedIndex, "arabic", "additionalConfig", data)
            handleModal(AR_EDIT_FILTER_CONFIG_MODAL, false, -1)
          }}
          data={configData[selectedIndex].arabic.additionalConfig || {}}
          filterType={configData[selectedIndex].arabic.filterType}
        />
      }
      {showModal[EN_MEDIA_DETAIL_MODAL] &&
        <MediaConfigDialog
          handleSave={(data) => {
            OnConfirmMediaConfigModal(selectedIndex, "english", "value", JSON.stringify(data))
            handleModal(EN_MEDIA_DETAIL_MODAL, false, -1)
          }}
          data={JSON.parse(configData[selectedIndex].english.value)}
          handleClose={() => {
            OnCloseMediaConfigModal(selectedIndex, "english", "value")
            handleModal(EN_MEDIA_DETAIL_MODAL, false, -1)
          }}
          open={true}
          title={"Media Configuration"}
          dialogContext={"Please Configure Media Details here."}
          language={"english"}
          deviceTab={deviceTab}
        />
      }
      {showModal[AR_MEDIA_DETAIL_MODAL] &&
        <MediaConfigDialog
          handleSave={(data) => {
            OnConfirmMediaConfigModal(selectedIndex, "arabic", "value", JSON.stringify(data))
            handleModal(AR_MEDIA_DETAIL_MODAL, false, -1)
          }}
          data={JSON.parse(configData[selectedIndex].arabic.value)}
          handleClose={() => {
            OnCloseMediaConfigModal(selectedIndex, "arabic", "value")
            handleModal(AR_MEDIA_DETAIL_MODAL, false, -1)
          }}
          open={true}
          title={"Media Configuration"}
          dialogContext={"Please Configure Media Details here."}
          language={"arabic"}
          deviceTab={deviceTab}
        />
      }
      {showModal[EN_JSON_MODAL] &&
        <JsonConfigDialog
          handleSave={(data) => {
            OnConfirmJsonConfigModal(selectedIndex, "english", "value", data)
            handleModal(EN_JSON_MODAL, false, -1)
          }}
          value={configData[selectedIndex].english.value || ""}
          handleClose={() => {
            handleModal(EN_JSON_MODAL, false, -1)
          }}
          open={true}
          title={"JSON Configuration"}
          dialogContext={"Please Configure JSON Details here."}
        />
      }
      {showModal[AR_JSON_MODAL] &&
        <JsonConfigDialog
          handleSave={(data) => {
            OnConfirmJsonConfigModal(selectedIndex, "arabic", "value", data)
            handleModal(AR_JSON_MODAL, false, -1)
          }}
          value={configData[selectedIndex].arabic.value || ""}
          handleClose={() => {
            handleModal(AR_JSON_MODAL, false, -1)
          }}
          open={true}
          title={"JSON Configuration"}
          dialogContext={"Please Configure JSON Details here."}
        />
      }
      {showModal[PREVIEW_IMAGE_MODAL] &&
        <PreviewImageModal
          imageURL={previewUrl}
          handleClose={() => {
            handleModal(PREVIEW_IMAGE_MODAL, false, -1)
            setPreviewUrl("");
          }}
          dialogContext={"Please Preview the below Image"}
          open={true}
          title={"Image Preview"}
        />

      }
      {showModal[PREVIEW_VIDEO_MODAL] &&
        <PreviewVideoModal
          videoURL={previewUrl}
          handleClose={() => {
            handleModal(PREVIEW_VIDEO_MODAL, false, -1);
            setPreviewUrl("");
          }}
          dialogContext={"Please Preview the below Video"}
          open={true}
          title={"Video Preview"}
        />
      }
      <div className="config-content-version">
        <div className="config-content">
          <h4>English version</h4>
        </div>
        <div className="config-content">
          <h4>Arabic version</h4>
        </div>
      </div>

      <div className={isFilterPage ? "config-table" : ""}>
        <DragDropContext onDragEnd={onDragEnd}>
          <table className="table table-striped">
            <thead>
              <tr>
                {isFilterPage && <th></th>}
                <th>{isFilterPage ? "Name" : "Key"}</th>
                <th scope="col">{isFilterPage ? "Attribute" : "Value"}</th>
                {isFilterPage && <th scope="col">Filter Type</th>}
                <th scope="col" className="config-border">
                  Enable/Disable
                </th>
                <th>{isFilterPage ? "Name" : "Key"}</th>
                <th scope="col">{isFilterPage ? "Attribute" : "Value"}</th>
                {isFilterPage && <th scope="col">Filter Type</th>}
                <th scope="col" className="config-border">
                  Enable/Disable
                </th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <Droppable droppableId={`droppable-configuration-${deviceTab}`}>
              {providedDroppable => (
                <tbody
                  {...providedDroppable.droppableProps}
                  ref={providedDroppable.innerRef}
                >
                  {!_.isEmpty(configData) &&
                    configData.map((item, index) => (
                      <Draggable
                        draggableId={index + ''}
                        index={index}
                        isDragDisabled={!isFilterPage}
                        key={index}
                      >
                        {provided => (
                          <tr  {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            {isFilterPage && <td>
                              <div className="drag-ping" {...provided.dragHandleProps}>
                                <DragPins pinsConfigMatrix={CONFIGURATIONS_PINS_CONFIG_MATRIX} pinsCoords={CONFIGURATIONS_PINS_COORDS} />
                              </div>
                            </td>}
                            <td>
                              <input
                                className="form-control"
                                name="key"
                                placeholder="Key"
                                type="text"
                                onChange={event =>
                                  handleUpdateConfigTypeCustomData(
                                    index,
                                    deviceTab,
                                    platformTab,
                                    "english",
                                    "key",
                                    _.get(event, "target.value", null),
                                    item.english,
                                    item._id
                                  )
                                }
                                value={item.english.key}
                              />
                            </td>
                            <td>
                              {(item.english.type === customInputTypes.TEXT || item.english.type === customInputTypes.FILTER) && (
                                <input
                                  className="form-control"
                                  name="value"
                                  placeholder="Value"
                                  type="text"
                                  onChange={event =>
                                    handleUpdateConfigTypeCustomData(
                                      index,
                                      deviceTab,
                                      platformTab,
                                      "english",
                                      "value",
                                      _.get(event, "target.value", null),
                                      item.english,
                                      item._id
                                    )
                                  }
                                  value={item.english.value}
                                />
                              )}
                              {item.english.type === customInputTypes.TEXT_AREA && (
                                <textarea
                                  className="form-control textarea"
                                  name="value"
                                  placeholder="value"
                                  value={item.english.value}
                                  onChange={event =>
                                    handleUpdateConfigTypeCustomData(
                                      index,
                                      deviceTab,
                                      platformTab,
                                      "english",
                                      "value",
                                      _.get(event, "target.value", null),
                                      item.english,
                                      item._id
                                    )
                                  }
                                />
                              )}
                              {item.english.type === customInputTypes.RADIO && (
                                <div>
                                  <input
                                    type="radio"
                                    value="true"
                                    checked={item.english.value === "true"}
                                    onChange={event =>
                                      radioChangeEnglish(
                                        event,
                                        index,
                                        deviceTab,
                                        platformTab,
                                        "english",
                                        "value",
                                        item.english,
                                        item._id
                                      )
                                    }
                                  />
                                  <label className="ml-2 mr-4">Yes</label>
                                  <input
                                    type="radio"
                                    value="false"
                                    checked={item.english.value === "false"}
                                    onChange={event =>
                                      radioChangeEnglish(
                                        event,
                                        index,
                                        deviceTab,
                                        platformTab,
                                        "english",
                                        "value",
                                        item.english,
                                        item._id
                                      )
                                    }
                                  />
                                  <label className="ml-2 mr-4">No</label>
                                </div>
                              )}
                              {item.english.type === customInputTypes.MEDIA && (
                                <div style={{ "display": "flex" }}>
                                  <CustomizedMenus
                                    menuLabel={JSON.parse(item.english.value).mediaType ? "EDIT MEDIA" : "ADD MEDIA"}
                                    menuItems={getMenuRows(JSON.parse(item.english.value).mediaType)}
                                    menuClickHandler={(menu, menuIndex) => {
                                      menuClickHandler(menu, menuIndex, index,
                                        deviceTab, platformTab, "english",
                                        "value", item.english,
                                        item._id, EN_MEDIA_DETAIL_MODAL)
                                    }}
                                    id="config-en-media" />
                                  <Tooltip arrow title="Delete Media" placement="top">
                                    <span>
                                      <IconButton aria-label="delete"
                                        disabled={!JSON.parse(item.english.value).mediaType}
                                        onClick={
                                          () => menuDeleteButtonHandler(index,
                                            deviceTab, platformTab, "english",
                                            "value", item.english,
                                            item._id)}>
                                        <MUIDeleteIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                  <Tooltip arrow title="Preview" placement="top">
                                    <span>
                                      <IconButton
                                        disabled={
                                          (!JSON.parse(item.english.value)) ||
                                          (!JSON.parse(item.english.value).imageURL &&
                                            !JSON.parse(item.english.value).videoURL)
                                        }
                                        onClick={
                                          () => {
                                            handleModal(
                                              JSON.parse(item.english.value).mediaType === CONFIGURATIONS_MEDIA_TYPES.IMAGE ?
                                                PREVIEW_IMAGE_MODAL : PREVIEW_VIDEO_MODAL, true, index);
                                            handlePreviewUrl(JSON.parse(item.english.value))
                                          }}
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                  <Tooltip arrow title="Copy Media URL" placement="top">
                                    <span>
                                      <CopyToClipboard text={getCopyText(JSON.parse(item.english.value))} onCopy={() =>

                                        dispatch(snackbar({ open: true, severity: "success", text: MESSAGES.URL_COPIED }))
                                      }>
                                        <IconButton
                                          disabled={
                                            (!JSON.parse(item.english.value)) ||
                                            (!JSON.parse(item.english.value).imageURL &&
                                              !JSON.parse(item.english.value).videoURL)
                                          }
                                        >
                                          <ContentCopyIcon />
                                        </IconButton>
                                      </CopyToClipboard>
                                    </span>
                                  </Tooltip>

                                </div>
                              )}
                              {item.english.type === customInputTypes.JSON && (
                                <Button
                                  onClick={() => handleModal(EN_JSON_MODAL, true, index)}
                                  variant="contained"
                                >
                                  {item.english.value ? "EDIT JSON" : "ADD JSON"}
                                </Button>
                              )}
                            </td>
                            {isFilterPage && <td>
                              <div style={{ "display": "flex" }}>
                                <CustomSelect
                                  options={filterOptions}
                                  onChange={event =>
                                    handleUpdateConfigTypeCustomData(
                                      index,
                                      deviceTab,
                                      platformTab,
                                      "english",
                                      "filterType",
                                      event.value,
                                      item.english,
                                      item._id
                                    )
                                  }
                                  value={item.english.filterType}
                                  name="filtertype-en"
                                  placeholder="Filter Type"
                                  className="cofig-filter-type"
                                />
                                <button
                                  className="sidebar-btn"
                                  disabled={!item.english.filterType}
                                  onClick={() => { handleModal(EN_EDIT_FILTER_CONFIG_MODAL, true, index) }}
                                >
                                  <img
                                    src={penIcon}
                                    alt="Additional config"
                                    className="update-config"
                                  />
                                </button>
                              </div>
                            </td>}
                            <td className="config-border text-center">
                              <Toggle
                                checked={item.english.enable}
                                className="config-toggle"
                                icons={false}
                                onChange={event =>
                                  handleUpdateConfigTypeCustomData(
                                    index,
                                    deviceTab,
                                    platformTab,
                                    "english",
                                    "enable",
                                    _.get(
                                      event,
                                      "enable",
                                      (item.english.enable = !item.english.enable)
                                    ),
                                    item.english,
                                    item._id
                                  )
                                }
                              />
                            </td>
                            {/* =============ARABIC CONTENT ============= */}
                            <td>
                              <input
                                className="form-control"
                                name="key"
                                placeholder="Key"
                                type="text"
                                onChange={event =>
                                  handleUpdateConfigTypeCustomData(
                                    index,
                                    deviceTab,
                                    platformTab,
                                    "arabic",
                                    "key",
                                    _.get(event, "target.value", null),
                                    item.arabic,
                                    item._id
                                  )
                                }
                                disabled={true}
                                value={item.arabic.key}
                              />
                            </td>
                            <td>
                              {(item.arabic.type === customInputTypes.TEXT || item.english.type === customInputTypes.FILTER) && (
                                <input
                                  className="form-control"
                                  name="value"
                                  placeholder="Value"
                                  type="text"
                                  onChange={event =>
                                    handleUpdateConfigTypeCustomData(
                                      index,
                                      deviceTab,
                                      platformTab,
                                      "arabic",
                                      "value",
                                      _.get(event, "target.value", null),
                                      item.arabic,
                                      item._id
                                    )
                                  }
                                  value={item.arabic.value}
                                />
                              )}
                              {item.arabic.type === customInputTypes.TEXT_AREA && (
                                <textarea
                                  className="form-control textarea"
                                  name="value"
                                  placeholder="value"
                                  value={item.arabic.value}
                                  onChange={event =>
                                    handleUpdateConfigTypeCustomData(
                                      index,
                                      deviceTab,
                                      platformTab,
                                      "arabic",
                                      "value",
                                      _.get(event, "target.value", null),
                                      item.arabic,
                                      item._id
                                    )
                                  }
                                />
                              )}
                              {item.arabic.type === customInputTypes.RADIO && (
                                <div>
                                  <input
                                    type="radio"
                                    value="true"
                                    checked={item.arabic.value === "true"}
                                    onChange={event =>
                                      radioChangeArabic(
                                        event,
                                        index,
                                        deviceTab,
                                        platformTab,
                                        "arabic",
                                        "value",
                                        item.arabic,
                                        item._id
                                      )
                                    }
                                  />
                                  <label className="ml-2 mr-4">Yes</label>
                                  <input
                                    type="radio"
                                    value="false"
                                    checked={item.arabic.value === "false"}
                                    onChange={event =>
                                      radioChangeArabic(
                                        event,
                                        index,
                                        deviceTab,
                                        platformTab,
                                        "arabic",
                                        "value",
                                        item.arabic,
                                        item._id
                                      )
                                    }
                                  />
                                  <label className="ml-2 mr-4">No</label>
                                </div>
                              )}
                              {item.arabic.type === customInputTypes.MEDIA && (
                                <div style={{ "display": "flex" }}>
                                  <CustomizedMenus
                                    menuLabel={JSON.parse(item.arabic.value).mediaType ? "EDIT MEDIA" : "ADD MEDIA"}
                                    menuItems={getMenuRows(JSON.parse(item.arabic.value).mediaType)}
                                    menuClickHandler={(menu, menuIndex) => {
                                      menuClickHandler(menu, menuIndex, index,
                                        deviceTab, platformTab, "arabic",
                                        "value", item.arabic,
                                        item._id, AR_MEDIA_DETAIL_MODAL)
                                    }}
                                    id="config-ar-media"
                                  />
                                  <Tooltip arrow title="Delete Media" placement="top">
                                    <span>
                                      <IconButton aria-label="delete"
                                        disabled={!JSON.parse(item.arabic.value).mediaType}
                                        onClick={
                                          () => menuDeleteButtonHandler(index,
                                            deviceTab, platformTab, "arabic",
                                            "value", item.arabic,
                                            item._id)}
                                      >
                                        <MUIDeleteIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                  <Tooltip arrow title="Preview" placement="top">
                                    <span>
                                      <IconButton
                                        disabled={
                                          (!JSON.parse(item.arabic.value)) ||
                                          (!JSON.parse(item.arabic.value).imageURL &&
                                            !JSON.parse(item.arabic.value).videoURL)
                                        }
                                        onClick={
                                          () => {
                                            handleModal(
                                              JSON.parse(item.arabic.value).mediaType === CONFIGURATIONS_MEDIA_TYPES.IMAGE ?
                                                PREVIEW_IMAGE_MODAL : PREVIEW_VIDEO_MODAL, true, index);
                                            handlePreviewUrl(JSON.parse(item.arabic.value))
                                          }}
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                  <Tooltip arrow title="Copy Media URL" placement="top">
                                    <span>
                                      <CopyToClipboard text={getCopyText(JSON.parse(item.arabic.value))}
                                        onCopy={() =>
                                          dispatch(snackbar({ open: true, severity: "success", text: MESSAGES.URL_COPIED }))
                                        }>
                                        <IconButton
                                          disabled={
                                            (!JSON.parse(item.arabic.value)) ||
                                            (!JSON.parse(item.arabic.value).imageURL &&
                                              !JSON.parse(item.arabic.value).videoURL)
                                          }
                                        >
                                          <ContentCopyIcon />
                                        </IconButton>
                                      </CopyToClipboard>
                                    </span>
                                  </Tooltip>
                                </div>
                              )}
                              {item.arabic.type === customInputTypes.JSON && (
                                <Button
                                  onClick={() => handleModal(AR_JSON_MODAL, true, index)}
                                  variant="contained"
                                >
                                  {item.arabic.value ? "EDIT JSON" : "ADD JSON"}
                                </Button>
                              )}
                            </td>
                            {isFilterPage && <td>
                              <div style={{ "display": "flex" }}>
                                <CustomSelect
                                  options={filterOptions}
                                  onChange={event =>
                                    handleUpdateConfigTypeCustomData(
                                      index,
                                      deviceTab,
                                      platformTab,
                                      "arabic",
                                      "filterType",
                                      event.value,
                                      item.arabic,
                                      item._id
                                    )
                                  }
                                  value={item.arabic.filterType}
                                  name="filtertype-en"
                                  placeholder="Filter Type"
                                  className="cofig-filter-type"
                                />
                                <button
                                  className="sidebar-btn"
                                  disabled={!item.arabic.filterType}
                                  onClick={() => { handleModal(AR_EDIT_FILTER_CONFIG_MODAL, true, index) }}
                                >
                                  <img
                                    src={penIcon}
                                    alt="Additional config"
                                    className="update-config"
                                  />
                                </button>
                              </div>
                            </td>}
                            <td className="config-border text-center">
                              <Toggle
                                checked={item.arabic.enable}
                                className="config-toggle"
                                icons={false}
                                onChange={event =>
                                  handleUpdateConfigTypeCustomData(
                                    index,
                                    deviceTab,
                                    platformTab,
                                    "arabic",
                                    "enable",
                                    _.get(
                                      event,
                                      "enable",
                                      (item.arabic.enable = !item.arabic.enable)
                                    ),
                                    item.arabic
                                  )
                                }
                              />
                            </td>
                            <td>
                              <div>
                                <Popup
                                  ref={popupRef}
                                  trigger={
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete module"
                                      className="delete-icon-cnf"
                                      data-tip="delete"
                                      data-for="config-tooltip"
                                    />
                                  }
                                  position="top right"
                                >
                                  <ConfigPopupContentDelete
                                    onConfirm={() =>
                                      handleRemoveRowTypeCustomField(
                                        index,
                                        deviceTab,
                                        platformTab,
                                        item._id
                                      )
                                    }
                                    onCancel={() => popupRef.current.close()}
                                  />
                                </Popup>
                              </div>
                              <div>
                                <Popup
                                  ref={popupRef}
                                  trigger={
                                    <span
                                      className="clone-btn-config"
                                      data-tip={accessRights.UPDATE ? "Copy banner to other country" : MESSAGES.TOOLTIP_UPDATE}
                                      data-for="config-tooltip"
                                      style={{ opacity: accessRights.UPDATE ? "0.7" : "0.3" }}
                                    />
                                  }
                                  position="top right"
                                  closeOnDocumentClick={false}
                                  style={{ width: "300px" }}
                                  disabled={!accessRights.UPDATE}
                                >
                                  <CloneCountryPopupContent
                                    onCancel={() => popupRef.current.close()}
                                    content={item}
                                    countryList={countryList}
                                    deviceTab={deviceTab}
                                    platformTab={platformTab}
                                  />
                                </Popup>
                              </div>
                            </td>
                            {/* =============ARABIC CONTENT -END-============= */}
                          </tr>

                        )}

                      </Draggable>
                    ))}
                  {providedDroppable.placeholder}
                </tbody>
              )}

            </Droppable>
          </table>
        </DragDropContext>
      </div>
    </div >
  );
}

export default BannerTabContent;
