import { fetchWebApi, putDashboardWebApi } from "../webapis/apiResource";
import { ENV_CONFIG } from "../config/env";
import { getAccessToken } from "../util/storeHelper";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  toastMsg
} from "../actions/common.action";
import { handleFetchError } from "../util/errorHandler";
import {
  CLEAR_FOOTER_CONTENT,
  CREATE_FOOTER_TREE_RECORDS_INITIAL,
  DELETE_FOOTER_ATTRIBUTE,
  GET_FOOTER_DATA,
  HANDLE_ADD_FOOTER_SOCIAL_MEDIA_LINK,
  HANDLE_FOOTER_EDIT_ATTRIBUTE,
  HANDLE_FOOTER_STATIC_TEXT,
  HANDLE_FOOTER_TEXT_CHANGE,
  HANDLE_REMOVE_FOOTER_SOCIAL_MEDIA_LINK,
  HANDLE_UPDATE_FOOTER_SOCIAL_MEDIA_LINK,
  SAVE_FOOTER_CONTENT,
  UPDATE_FOOTER_ON_TREE_CHANGE,
  UPDATE_FOOTER_TREE_RECORDS,
  UPDATE_TREE_DATA,
  FOOTER_PAGE_OPEN_COMPONENT,
  FOOTER_PAGE_TOGGLE_COMPONENT,
} from "../constants/actions";
import { resetCountryId } from "./selectedCountyId.action";
import { resetStoreId } from "./store.action";

export const fetchFooterData = (storeId, countryId) => {
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/footer/?storeId=${storeId}&countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), urlType)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getFooterData(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchFooterDataV2 = (storeId, countryId) => {
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/footerV2/?storeId=${storeId}&countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), urlType)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getFooterData(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateFooterDetails = (
  countryId,
  storeId,
  englishData,
  arabicData,
  tempsave,
  selectedCountryId
) => {
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/footer/menu-item`;
  const bodyData = {
    storeId,
    countryId,
    english: englishData,
    arabic: arabicData,
    selectedCountryId: selectedCountryId || [countryId]
  };
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(
      getAccessToken(getState),
      urlType,
      bodyData,
      tempsave
    )
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getFooterData(response.data.data));
          dispatch(
            toastMsg("success", "Footer records has been successfully updated.")
          );
        }
        dispatch(hideLoadingIndicator());
        // dispatch(disableSubmit());
        if (!tempsave) {
          dispatch(resetCountryId());
          dispatch(resetStoreId());
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        dispatch(
          toastMsg("error", "something went wrong while updating the records.")
        );
        return error.response;
      });
  };
};

export const updateFooterDetailsV2 = (
  countryId,
  storeId,
  content,
  tempsave,
  selectedCountryId
) => {
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/footerV2/menu-item`;
  const bodyData = {
    storeId,
    countryId,
    content,
    selectedCountryId: selectedCountryId || [countryId]
  };
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(
      getAccessToken(getState),
      urlType,
      bodyData,
      tempsave
    )
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getFooterData(response.data.data));
          dispatch(
            toastMsg("success", "Footer records has been successfully updated.")
          );
        }
        dispatch(hideLoadingIndicator());
        // dispatch(disableSubmit());
        if (!tempsave) {
          dispatch(resetCountryId());
          dispatch(resetStoreId());
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        dispatch(
          toastMsg("error", "something went wrong while updating the records.")
        );
        return error.response;
      });
  };
};

export const updateFooterStaticDetails = (
  countryId,
  englishData,
  arabicData,
  tempsave,
  selectedCountryId
) => {
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/footer/static-content`;
  const bodyData = {
    countryId,
    english: englishData,
    arabic: arabicData,
    selectedCountryId: selectedCountryId || [countryId]
  };
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(
      getAccessToken(getState),
      urlType,
      bodyData,
      tempsave
    )
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getFooterData(response.data.data));
          dispatch(
            toastMsg("success", "Footer records has been successfully updated.")
          );
        }
        dispatch(hideLoadingIndicator());
        // dispatch(disableSubmit());
        if (!tempsave) {
          dispatch(resetCountryId());
          dispatch(resetStoreId());
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        dispatch(
          toastMsg("error", "something went wrong while updating the records.")
        );
        return error.response;
      });
  };
};

const getFooterData = ({ result }) => ({ type: GET_FOOTER_DATA, result });

export const handleFooterTextChange = (name, value, keyType) => ({
  type: HANDLE_FOOTER_STATIC_TEXT,
  name,
  value,
  keyType
});

export const handleAddFooterSocialMediaLink = languageMap => ({
  type: HANDLE_ADD_FOOTER_SOCIAL_MEDIA_LINK,
  keyType: languageMap
});

export const handleRemoveFooterSocialMediaLink = (index, languageMap) => ({
  type: HANDLE_REMOVE_FOOTER_SOCIAL_MEDIA_LINK,
  index,
  keyType: languageMap
});

export const handleUpdateFooterSocialMediaLink = (
  name,
  value,
  index,
  languageMap
) => ({
  type: HANDLE_UPDATE_FOOTER_SOCIAL_MEDIA_LINK,
  name,
  value,
  index,
  keyType: languageMap
});

export const clearFooterContent = () => ({ type: CLEAR_FOOTER_CONTENT });

export const updateRecords = colName => ({
  type: UPDATE_FOOTER_TREE_RECORDS,
  colName
});

export const handleFooterEditAttribute = ({ englishData, arabicData }) => ({
  type: HANDLE_FOOTER_EDIT_ATTRIBUTE,
  englishData,
  arabicData
});

export const handleTxtChange = (language, key, value) => ({
  type: HANDLE_FOOTER_TEXT_CHANGE,
  language,
  key,
  value
});

export const saveFooterContent = (
  updateEnglishContent,
  updateArabicContent
) => ({
  type: SAVE_FOOTER_CONTENT,
  updateEnglishContent,
  updateArabicContent
});

export const updateTreeData = data => ({ type: UPDATE_TREE_DATA, data });

export const deleteFooterAttribute = selectedAttributeData => ({
  type: DELETE_FOOTER_ATTRIBUTE,
  selectedAttributeData
});

export const updateFooterOnTreeChange = treeData => ({
  type: UPDATE_FOOTER_ON_TREE_CHANGE,
  treeData
});

export const createFooterTreeRecordInitial = () => ({
  type: CREATE_FOOTER_TREE_RECORDS_INITIAL
});

export const openComponent = ({ id, index }) => ({
  type: FOOTER_PAGE_OPEN_COMPONENT,
  id,
  index
});

export const toggleSection = ({ index }) => ({
  type: FOOTER_PAGE_TOGGLE_COMPONENT,
  index
});