import React from "react";
import { Paper } from "../materialUi/components";


const Footer = ({ children, ...props }) => {
    return (
        <Paper
            sx={{
                padding: "20px 15px 15px 15px",
                position: "fixed",
                bottom: 0,
                width: "74%"
            }}
            variant="outlined"
            {...props}
        >
            {children}
        </Paper>
    );
};

export default Footer;
