import React, { Component, Fragment } from "react";
import ReactTooltip from "react-tooltip";
import Accordion from "./accordion";
import SeoSection from "./seoSection";
import Button from "../../component/button";
import { MESSAGES } from "../../constants";

class RightPanel extends Component {
  render() {
    const {
      needHelpObject,
      needHelpKey,
      changeHandler,
      checkBoxChangeHandler,
      addNeedHelpAccordTile,
      removeNeedHelpAccordTile,
      updateNeedHelpData,
      handleReset,
      resetDisabled,
      isSubmit,
      submitBtnValidation,
      storeId,
      countryId,
      updateNeedHelpAccordTilePosition,
      accessRights
    } = this.props;
    return (
      <Fragment>
        <SeoSection
          needHelpKey={needHelpKey}
          needHelpObject={needHelpObject}
          submitBtnValidation={submitBtnValidation}
        />
        <Accordion
          countryId={countryId}
          storeId={storeId}
          list={
            needHelpObject[needHelpKey] && needHelpObject[needHelpKey].content
          }
          changeHandler={changeHandler}
          checkBoxChangeHandler={checkBoxChangeHandler}
          needHelpKey={needHelpKey}
          addNeedHelpAccordTile={addNeedHelpAccordTile}
          removeNeedHelpAccordTile={removeNeedHelpAccordTile}
          updateNeedHelpAccordTilePosition={updateNeedHelpAccordTilePosition}
          submitBtnValidation={submitBtnValidation}
        />
        <div className="marginField">
          <span
            data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
            data-for="need-help"
          >
            <Button
              name={"Save & Preview"}
              onClick={
                isSubmit && accessRights.UPDATE
                  ? () => updateNeedHelpData(true)
                  : null
              }
              disabled={!isSubmit || !accessRights.UPDATE}
              customClass={isSubmit ? "" : "opacity"}
            />
          </span>
          <span>
            <Button
              name={"Cancel"}
              color={"#000000"}
              backgroundColor={"#ffffff"}
              borderColor={"#000000"}
              onClick={handleReset}
              disabled={!resetDisabled}
              customClass={resetDisabled ? "" : "opacity disable-btn"}
            />
          </span>
          <span
            data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
            data-for="need-help"
          >
            <Button
              name={"Publish"}
              onClick={
                (needHelpObject[needHelpKey] &&
                  needHelpObject[needHelpKey].isPublished) ||
                !needHelpObject[needHelpKey] ||
                !needHelpObject.apiContent._id ||
                !accessRights.UPDATE
                  ? null
                  : () => updateNeedHelpData()
              }
              disabled={
                (needHelpObject[needHelpKey] &&
                  needHelpObject[needHelpKey].isPublished) ||
                !needHelpObject[needHelpKey] ||
                !needHelpObject.apiContent._id ||
                !accessRights.UPDATE
              }
              customClass={
                !(
                  (needHelpObject[needHelpKey] &&
                    needHelpObject[needHelpKey].isPublished) ||
                  !needHelpObject[needHelpKey] ||
                  !needHelpObject.apiContent._id
                )
                  ? ""
                  : "opacity"
              }
            />
          </span>
        </div>
        <ReactTooltip place="top" effect="solid" id="need-help" />
      </Fragment>
    );
  }
}

export default RightPanel;
