import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";

export const validateUpdateStaticContentData = updateStaticContent =>
  updateStaticContent &&
  updateStaticContent.connect &&
  updateStaticContent.copyRight &&
  updateStaticContent.csHeading &&
  updateStaticContent.csNumber &&
  updateStaticContent.csTime &&
  updateStaticContent.csEmail &&
  updateStaticContent.socialMediaLinks &&
  Array.isArray(updateStaticContent.socialMediaLinks) &&
  updateStaticContent.socialMediaLinks.every(
    sml => sml.img && sml.redirectLink
  );

const socialMediaLinksComparisonFunctor = (sml1, sml2) =>
  sml1.every((item, index) => isEqual(item, sml2[index]));

export const isUpdateStaticContentDataChanged = (
  updateStaticContent,
  staticContent
) =>
  !(
    isEqual(updateStaticContent, staticContent) &&
    isEqualWith(
      updateStaticContent.socialMediaLinks,
      staticContent.socialMediaLinks,
      socialMediaLinksComparisonFunctor
    )
  );

export const columnWidthList = (minValue, maxValue, diff) => {
  const arr = [];
  let value = minValue;
  while (value <= maxValue) {
    arr.push({ label: `${value}%`, value: value });
    value += diff;
  }
  return arr;
}  