import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { bindActionCreators, compose } from "redux";
import { PANEL } from "../../constants";
import ConfirmModal from "../modal/ConfirmModal";
import { MenuItem, TextField } from "../../materialUi/components";
import {
  updateCountryId,
  changeSelectedStore
} from "../../actions/store.action";
import {
  handleCurrentTabChange,
  clearMegaMenuContent
} from "../../actions/megaMenu.action";

const getNewUrl = (countryId, bId, location) => {
  const { pathname } = location;

  return queryString.stringifyUrl({
    url: pathname,
    query: { storeId: bId, countryId }
  });
};

class CountryDropdown extends Component {
  state = { confirmModal: false };

  UNSAFE_componentWillMount() {
    const { storeList, storeId, countryId, location } = this.props;
    const urlParams = new URLSearchParams(location.search);
    if (+urlParams.get("countryId")) {
      this.props.updateCountryId(+urlParams.get("countryId"));
    } else if (!countryId) {
      let _store =
        (storeList &&
          storeList.filter(
            item => parseInt(item.storeId) === parseInt(storeId)
          )) ||
        [];
      let _country =
        (_store?._store[0]?._store[0].countries &&
          _store[0].countries.filter(item =>
            item.name.toLowerCase().includes("united arab")
          )) ||
        [];
      _country?.[0] && this.props.updateCountryId(_country[0].countryId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { storeId, countryId, updateCountryId } = this.props;
    if (
      +storeId !== +nextProps.storeId ||
      +countryId !== +nextProps.countryId
    ) {
      nextProps.storeId &&
        nextProps.countryId &&
        updateCountryId(nextProps.countryId);
    }
  }

  toggleModal = (countryId, bId) => {
    countryId &&
      bId &&
      this.setState(prevState => ({
        confirmModal: !prevState.confirmModal,
        countryId,
        bId
      }));
    countryId &&
      !bId &&
      this.setState(prevState => ({ confirmModal: !prevState.confirmModal }));
  };

  confirmModal = () => {
    const { countryId, bId } = this.state;
    const {
      clearMegaMenuContent,
      handleCurrentTabChange,
      isMegaMenuPage,
      history,
      location
    } = this.props;
    countryId && bId && this.props.updateCountryId(countryId);
    this.setState({ confirmModal: false });
    if (countryId && bId) {
      const newUrl = getNewUrl(countryId, bId, location);
      history.push(newUrl);
    }
    if (isMegaMenuPage) {
      handleCurrentTabChange("");
      clearMegaMenuContent();
    }
  };

  changeCountry = (countryId, bId) => {
    const { location, history } = this.props;
    let isDataEqual = true;

    if (!isDataEqual) {
      this.toggleModal(countryId, bId);
    } else {
      countryId && this.props.updateCountryId(countryId);
      this.props.changeTab && this.props.changeTab({ target: { id: PANEL.GENERAL_SEO_DISPLAY } });
      if (countryId) {
        const newUrl = getNewUrl(countryId, bId, location);
        history.push(newUrl);
      }
    }
  };

  changeCountryFromFooter = (countryId, bId) => {
    const { location, history } = this.props;
    countryId && this.props.updateCountryId(countryId);
    if (countryId) {
      const newUrl = getNewUrl(countryId, bId, location);
      history.push(newUrl);
    }
  };

  changeCountryFromMegaMenu = (countryId, bId) => {
    const {
      megaMenu,
      clearMegaMenuContent,
      handleCurrentTabChange,
      history,
      location
    } = this.props;
    let isDataEqual = true;
    const enApiData = megaMenu.english.find(
      item => item.uid === megaMenu.updateEnglishContent.uid
    );
    const arApiData = megaMenu.arabic.find(
      item => item.uid === megaMenu.updateArabicContent.uid
    );
    if (enApiData && arApiData) {
      if (
        !(
          isEqual(megaMenu.updateEnglishContent, enApiData) &&
          isEqual(megaMenu.updateArabicContent, arApiData)
        )
      ) {
        isDataEqual = false;
      }
    } else if (
      !(
        isEqual(megaMenu.updateEnStaticContent, megaMenu.enStaticContent) &&
        isEqual(megaMenu.updateArStaticContent, megaMenu.arStaticContent)
      )
    ) {
      isDataEqual = false;
    } else if (
      !enApiData &&
      !arApiData &&
      !(
        isEqual(megaMenu.updateEnglishContent, megaMenu.englishContent) &&
        isEqual(megaMenu.updateArabicContent, megaMenu.arabicContent)
      )
    ) {
      isDataEqual = false;
    }

    if (!isDataEqual) {
      this.toggleModal(countryId, bId);
    } else {
      countryId && this.props.updateCountryId(countryId);
      if (countryId) {
        const newUrl = getNewUrl(countryId, bId, location);
        history.push(newUrl);
        clearMegaMenuContent();
        handleCurrentTabChange();
      }
    }
  };
  handleChange = (event) => {
    const { isFooterPage, storeId, isMegaMenuPage } = this.props;
    const countryId = event.target.value;
    if (isFooterPage) {
      this.changeCountryFromFooter(countryId, storeId);
    } else if (isMegaMenuPage) {
      this.changeCountryFromMegaMenu(countryId, storeId);
    } else {
      this.changeCountry(countryId, storeId)
    }
  }

  render() {
    const {
      storeId,
      countryId,
      storeList,
      isSizeChart,
    } = this.props;
    const { confirmModal } = this.state;
    const selectedStore =
      storeList && storeList.find(store => store.storeId === storeId);
    const selectedCountry =
      selectedStore &&
      selectedStore.countries.find(country => country.countryId === countryId);
    const countryList = (selectedStore?.countries?.length && selectedStore.countries) || [];
    return (
      <div>
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.toggleModal}
            confirm={this.confirmModal}
            message={
              "All the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        {!isSizeChart &&
          <TextField
            select
            label="Select Country"
            onChange={this.handleChange}
            size="small"
            value={selectedCountry?.countryId || ""}
            sx={{ "width": "100%", "marginBottom": "15px" }}
          >
            {countryList.map(country => (
              <MenuItem key={country.countryId} value={country.countryId}>{country.name}</MenuItem>
            ))}
          </TextField>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countryId: state.store.countryId,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  megaMenu: state.megaMenu
});

const mapDispatchToProps = dispatch => ({
  updateCountryId: bindActionCreators(updateCountryId, dispatch),
  changeSelectedStore: bindActionCreators(changeSelectedStore, dispatch),
  clearMegaMenuContent: bindActionCreators(clearMegaMenuContent, dispatch),
  handleCurrentTabChange: bindActionCreators(handleCurrentTabChange, dispatch)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CountryDropdown);
