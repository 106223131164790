import React, { Fragment } from "react";
import get from "lodash/get";
import { FOOTER_STATIC_CONTENT_PLACEHOLDERS } from "../../../constants";
import InputText from "../../../component/InputText";

const Connect = ({ handleFooterText, staticContent, language }) => {
  const placeholders = FOOTER_STATIC_CONTENT_PLACEHOLDERS[language];
  return (
    <Fragment>
      <InputText
        name="connect"
        label="Connect"
        language={language}
        placeholder={placeholders.connect}
        value={get(staticContent, "connect", "")}
        onChange={e =>
          handleFooterText(e.target.name, e.target.value, language)
        }
        required
      />
      <InputText
        name="copyRight"
        label="Copy Right Label"
        language={language}
        placeholder={placeholders.copyRight}
        value={get(staticContent, "copyRight", "")}
        onChange={e =>
          handleFooterText(e.target.name, e.target.value, language)
        }
        required
      />
    </Fragment>
  );
};

export default Connect;
