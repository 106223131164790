import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEqual from "lodash/isEqual";
import { STATIC_PAGE_PANEL, STATIC_PAGE_PAGETYPE, MESSAGES, ERROR_MESSAGES } from "../../constants";
import { Button, Tooltip, Box } from "../../materialUi/components";
import { validateExtraSpace } from "../../util";
import ConfirmModal from "../modal/ConfirmModal";
import AddPageContent from "./addPageContent";
import UpdatePageContent from "./updatePageContent";
import Footer from "../../component/footer";

import {
  cancelAction,
  submitStaticPage,
  updateStaticPage,
  toggleReset,
  resetAddPageContent,
  resetUpdateStaticPage
} from "../../actions/staticPage.action";
import { ENV_CONFIG } from "../../config/env";
import {
  toastMsg
} from "../../actions/common.action";

class Content extends Component {
  state = {
    confirmModal: false,
    confirmPreviewModal: false,
    confirmPreviewSubmitModal: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { toggleReset, staticPageR } = nextProps;
    if (
      !isEqual(staticPageR.staticPages, staticPageR.initialStaticPages) ||
      !isEqual(
        staticPageR.initialAddPageContent,
        staticPageR.staticAddPageContent
      )
    ) {
      if (nextProps.resetDisabled !== false) toggleReset(false);
    } else if (
      isEqual(staticPageR.staticPages, staticPageR.initialStaticPages) ||
      isEqual(
        staticPageR.initialAddPageContent,
        staticPageR.staticAddPageContent
      )
    ) {
      if (nextProps.resetDisabled !== true) toggleReset(true);
    }
  }

  handleReset = () => {
    const { confirmModal } = this.state;
    this.setState({ confirmModal: !confirmModal });
  };

  hideModal = () =>
    this.setState(prevProps => ({
      confirmModal: !prevProps.confirmModal,
      storeTempSelectedTab: ""
    }));

  hidePreviewModal = () => {
    const { resetAddPageContent } = this.props;
    this.setState(prevProps => ({
      confirmPreviewModal: !prevProps.confirmPreviewModal
    }));
    resetAddPageContent();
  };

  hidePreviewSubmitModal = () =>
    this.setState(prevProps => ({
      confirmPreviewSubmitModal: !prevProps.confirmPreviewSubmitModal
    }));

  confirmModal = () => {
    const { confirmModal } = this.state;
    const {
      currentTab,
      cancelAction,
      resetAddPageContent,
      resetUpdateStaticPage
    } = this.props;
    resetAddPageContent();
    resetUpdateStaticPage();
    this.setState({ confirmModal: !confirmModal });
    cancelAction(currentTab);
  };

  confirmPreviewModal = () => {
    const { confirmPreviewModal } = this.state;
    const {
      staticAddPageContent,
      updatePageContent,
      resetAddPageContent,
      storeList,
      storeId,
      countryId
    } = this.props;
    let selectedCountry = null,
      defaultCountry = null;
    storeList.forEach(store => {
      if (store.storeId === storeId) {
        store.countries.forEach(country => {
          if (country.countryId === countryId) {
            selectedCountry = country;
          }
          if (country.countrySHORT.toLowerCase() === "ae") {
            defaultCountry = country;
          }
        });
      }
    });
    this.setState({ confirmPreviewModal: !confirmPreviewModal }, () => {
      window.open(
        `${ENV_CONFIG.redirectionUrl}-${selectedCountry
          ? selectedCountry.countrySHORT.toLowerCase()
          : defaultCountry.countrySHORT.toLowerCase()
        }${(staticAddPageContent && staticAddPageContent.url) ||
        updatePageContent.url
        }?prevMode=true`,
        "_blank"
      );
      resetAddPageContent();
    });
  };

  confirmPreviewSubmitModal = (tempsave = false, currentTab) => {
    const {
      countryId,
      updateStaticPage,
      updatePageContent,
      staticPages,
      updatePageIndex,
      updateContent
    } = this.props;
    updateStaticPage(
      currentTab,
      updatePageContent._id || updatePageContent.id,
      staticPages[STATIC_PAGE_PAGETYPE[updatePageContent.pageType]][
      updatePageIndex
      ],
      countryId,
      tempsave
    ).then(resp => {
      if (resp.data.code === 200) {
        updateContent(resp.data.data.result);
      }
      this.setState({ confirmPreviewSubmitModal: false });
    });
  };

  handleSubmit = (currentTab, tempsave = false) => {
    const {
      submitStaticPage,
      staticAddPageContent,
      countryId,
      updateStaticPage,
      updatePageContent,
      staticPages,
      updatePageIndex,
      updateContent,
      toastMsg,
    } = this.props;
    const { staticPageR } = this.props;
    if (
      [
        STATIC_PAGE_PANEL.STATIC_ADD_PAGE_DISPLAY,
        STATIC_PAGE_PANEL.STATIC_DELETE_PAGE_DISPLAY
      ].indexOf(currentTab) > -1
    ) {
      if (staticAddPageContent && staticAddPageContent.title) {
        const isValid = validateExtraSpace(staticAddPageContent.title);
        if (!isValid)
          return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Page Name`)
      }
      if (staticAddPageContent && staticAddPageContent.url) {
        const isValid = validateExtraSpace(staticAddPageContent.url);
        if (!isValid)
          return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Page Url`)
      }
      submitStaticPage(
        currentTab,
        staticAddPageContent,
        countryId,
        tempsave
      ).then(resp => {
        if (resp.data.code === 200 && tempsave) {
          this.setState({ confirmPreviewModal: true });
        }
      });
    } else {
      if (
        !tempsave &&
        !isEqual(staticPageR.staticPages, staticPageR.initialStaticPages)
      ) {
        this.setState({ confirmPreviewSubmitModal: true });
      } else {
        const { title = "", url = "" } = staticPages[STATIC_PAGE_PAGETYPE[updatePageContent.pageType]][
          updatePageIndex
        ] || {};
        if (title) {
          const isValid = validateExtraSpace(title);
          if (!isValid)
            return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Page Name`)
        }
        if (url) {
          const isValid = validateExtraSpace(url);
          if (!isValid)
            return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Page Url`)
        }
        updateStaticPage(
          currentTab,
          updatePageContent._id || updatePageContent.id,
          staticPages[STATIC_PAGE_PAGETYPE[updatePageContent.pageType]][
          updatePageIndex
          ],
          countryId,
          tempsave
        ).then(resp => {
          if (resp.data.code === 200) {
            updateContent(resp.data.data.result);
            if (tempsave) {
              this.setState({ confirmPreviewModal: true });
            }
          }
        });
      }
    }
  };

  render() {
    const {
      confirmModal,
      confirmPreviewModal,
      confirmPreviewSubmitModal
    } = this.state;
    const {
      currentTab,
      isSubmitEnabled,
      updatePageContent,
      updatePageIndex,
      resetDisabled,
      changeTab,
      accessRights
    } = this.props;
    let contentToDisplay;
    switch (currentTab) {
      case STATIC_PAGE_PANEL.STATIC_ADD_PAGE_DISPLAY:
      case STATIC_PAGE_PANEL.STATIC_DELETE_PAGE_DISPLAY:
        contentToDisplay = <AddPageContent />;
        break;
      case STATIC_PAGE_PANEL.STATIC_UPDATE_PAGE_DISPLAY:
        contentToDisplay = (
          <UpdatePageContent
            updatePageContent={updatePageContent}
            updatePageIndex={updatePageIndex}
            changeTab={changeTab}
            accessRights={accessRights}
          />
        );
    }
    return (
      <div className="content-container-wrapper">
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={"Are you sure you want to cancel ?"}
          />
        )}
        {confirmPreviewModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewModal}
            confirm={this.confirmPreviewModal}
            message={"Do you want to see the changes?"}
          />
        )}
        {confirmPreviewSubmitModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hidePreviewSubmitModal}
            confirm={() => this.confirmPreviewSubmitModal(false, currentTab)}
            message={
              "There are some unsaved changes, Kindly save it otherwise all the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        <div>{contentToDisplay && contentToDisplay}</div>
        <Footer>
          <Box sx={{ "display": "flex" }}>
            <Button
              onClick={this.handleReset}
              disabled={resetDisabled}
            >
              Cancel
            </Button>
            <Tooltip title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
              <span style={{ "marginLeft": "auto" }}>
                <Button
                  onClick={
                    () => this.handleSubmit(currentTab, true)
                  }
                  disabled={
                    !isSubmitEnabled || !accessRights.UPDATE
                  }
                  variant="contained"
                >
                  Save & Preview
                </Button>
              </span>
            </Tooltip>
            {[
              STATIC_PAGE_PANEL.STATIC_ADD_PAGE_DISPLAY,
              STATIC_PAGE_PANEL.STATIC_DELETE_PAGE_DISPLAY
            ].includes(currentTab) ? null :
              <Tooltip title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                <span style={{ "marginLeft": "20px" }}>
                  <Button
                    onClick={
                      () => this.handleSubmit(currentTab)
                    }
                    disabled={updatePageContent.isPublished || !accessRights.UPDATE}
                    variant="contained"
                  >
                    Publish
                  </Button>
                </span>
              </Tooltip>
            }
          </Box>
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeId: state.store.storeId,
  countryId: state.store.countryId,
  storeList: state.store.storeList,
  isSubmitEnabled: state.common.isSubmitEnabled,
  staticAddPageContent: state.staticReducer.staticAddPageContent,
  staticPages: state.staticReducer.staticPages,
  resetDisabled: state.staticReducer.resetDisabled,
  staticPageR: state.staticReducer
});

const mapDispatchToProps = dispatch => ({
  cancelAction: bindActionCreators(cancelAction, dispatch),
  submitStaticPage: bindActionCreators(submitStaticPage, dispatch),
  updateStaticPage: bindActionCreators(updateStaticPage, dispatch),
  toggleReset: bindActionCreators(toggleReset, dispatch),
  resetAddPageContent: bindActionCreators(resetAddPageContent, dispatch),
  resetUpdateStaticPage: bindActionCreators(resetUpdateStaticPage, dispatch),
  toastMsg: bindActionCreators(toastMsg, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
