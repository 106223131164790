import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

export const validatePageUrl = url => {
  if (url === "/") {
    return {
      success: false,
      error: "Backslash is only available for a homepage"
    };
  }

  if (url.charAt(0) !== "/" || url.charAt(url.length - 1) !== "/") {
    return {
      success: false,
      error: "Page URL must have backslashes(/) at first and last positions"
    };
  }

  return { success: true };
};

export const getBannerSchedulersAllLevels = (content, pathArray) => {
  const bannerSchedulers = get(content, [...pathArray, "schedulers"], null);
  const rows = get(content, [...pathArray, "rows"]);
  let rowsSchedules =
    rows && rows.flatMap(row => (row.schedulers ? row.schedulers : []));
  rowsSchedules = isEmpty(rowsSchedules) ? null : rowsSchedules;
  return { bannerSchedulers, rowsSchedules };
};

export const isSchedulerPopUpEnabled = schedulers =>
  schedulers ? schedulers.some(scheduler => scheduler.enabled) : false;
