import React from "react";
import { Toolbar, alpha, Typography, Tooltip, IconButton } from "../../materialUi/components";
import { DeleteIcon } from "../../materialUi/icons";

const EnhancedProductTableToolbar = (props) => {
  const { numSelected, deleteHandler } = props;

  return (
    <Toolbar
      sx={{
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{ "minHeight": "50px", "paddingLeft": "16px", "paddingRight": "16px" }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Products
        </Typography>
      )}

      {!!numSelected && (
        <Tooltip title="Delete" arrow placement="top">
          <IconButton onClick={deleteHandler}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default EnhancedProductTableToolbar;
