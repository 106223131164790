import React, { Fragment } from "react";

import { Clock } from "./svg/Clock";

const SchedulerButton = ({ onClick, name }) => (
  <Fragment>
    <button className="scheduler-add-btn" onClick={onClick}>
      <Clock />
      {name || "Button"}
    </button>
  </Fragment>
);

export default SchedulerButton;
