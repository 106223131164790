import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import ReactTooltip from "react-tooltip";
import Button from "../../component/button";
import { MESSAGES, ERROR_MESSAGES } from "../../constants";
import { toastMsg } from "../../actions/common.action";


class GeneralSeoTab extends Component {
  state = { enable: false };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updateMeta, apiContent } = nextProps;
    if (
      updateMeta.english &&
      updateMeta.english.title &&
      updateMeta.english &&
      updateMeta.english.metaKeyword &&
      updateMeta.english &&
      updateMeta.english.metaDescription &&
      updateMeta.english &&
      updateMeta.english.headingH1 &&
      updateMeta.english &&
      updateMeta.english.headingH2 &&
      updateMeta.arabic &&
      updateMeta.arabic.title &&
      updateMeta.arabic &&
      updateMeta.arabic.metaKeyword &&
      updateMeta.arabic &&
      updateMeta.arabic.metaDescription &&
      updateMeta.arabic &&
      updateMeta.arabic.headingH1 &&
      updateMeta.arabic &&
      updateMeta.arabic.headingH2
    ) {
      this.setState({
        enable: !isEqual(apiContent.meta, updateMeta) ? true : false
      });
    } else {
      this.setState({ enable: false });
    }
  }

  validateFormData = (formData, language) => {
    const { toastMsg } = this.props;
    const { title = "", metaKeyword = "", metaDescription = "", headingH1 = "", headingH2 = "", } = formData || {};
    if (title && (title !== title.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Page Title`);
      return false;
    }
    if (metaKeyword && (metaKeyword !== metaKeyword.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Meta Keywords`);
      return false;
    }
    if (metaDescription && (metaDescription !== metaDescription.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Meta Description`);
      return false;
    }
    if (headingH1 && (headingH1 !== headingH1.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Heading H1`);
      return false;
    }
    if (headingH2 && (headingH2 !== headingH2.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Heading H2`);
      return false;
    }

    return true;
  }

  handleSave = () => {
    const {
      handleMetaDataSave,
      updateMeta,
      countryId,
      apiContent
    } = this.props;

    const { english = {}, arabic = {} } = updateMeta || {};

    const isValidEnglishForm = this.validateFormData(english, "English");
    if (!isValidEnglishForm) return;

    const isValidArabicForm = this.validateFormData(arabic, "Arabic");
    if (!isValidArabicForm) return;

    handleMetaDataSave(apiContent._id, countryId, updateMeta);
  };

  render() {
    const { updateMeta, handleGeneralChange, accessRights } = this.props;
    const { enable } = this.state;
    return (
      <div>
        <h2 className="page-main-heading">SEO Content</h2>
        <div className="wrapper-box">
          <div className="data-wrapper">
            <div className="english-wrapper">
              <h4>English Version</h4>
              <div className="english-content">
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Page Title
                  </label>
                  <input
                    name="title"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Page Title"
                    value={
                      updateMeta &&
                      updateMeta.english &&
                      updateMeta.english.title
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "english",
                        "updateMeta"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Meta Keywords
                  </label>
                  <input
                    name="metaKeyword"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Meta Keywords"
                    value={
                      updateMeta &&
                      updateMeta.english &&
                      updateMeta.english.metaKeyword
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "english",
                        "updateMeta"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Meta Description
                  </label>
                  <textarea
                    name="metaDescription"
                    rows="12"
                    cols="50"
                    placeholder="Enter Meta Description"
                    value={
                      updateMeta &&
                      updateMeta.english &&
                      updateMeta.english.metaDescription
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "english",
                        "updateMeta"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading H1
                  </label>
                  <input
                    name="headingH1"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Heading H1"
                    value={
                      updateMeta &&
                      updateMeta.english &&
                      updateMeta.english.headingH1
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "english",
                        "updateMeta"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading H2
                  </label>
                  <input
                    name="headingH2"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Heading H2"
                    value={
                      updateMeta &&
                      updateMeta.english &&
                      updateMeta.english.headingH2
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "english",
                        "updateMeta"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="arabic-wrapper">
              <h4>Arabic Version</h4>
              <div className="arabic-content">
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Page Title
                  </label>
                  <input
                    dir="rtl"
                    name="title"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Page Title"
                    value={
                      updateMeta && updateMeta.arabic && updateMeta.arabic.title
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        "updateMeta"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Meta Keywords
                  </label>
                  <input
                    dir="rtl"
                    name="metaKeyword"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Meta Keywords"
                    value={
                      updateMeta &&
                      updateMeta.arabic &&
                      updateMeta.arabic.metaKeyword
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        "updateMeta"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Meta Description
                  </label>
                  <textarea
                    dir="rtl"
                    name="metaDescription"
                    rows="12"
                    cols="50"
                    placeholder="أدخل الوصف الوصفى"
                    value={
                      updateMeta &&
                      updateMeta.arabic &&
                      updateMeta.arabic.metaDescription
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        "updateMeta"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading H1
                  </label>
                  <input
                    dir="rtl"
                    name="headingH1"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Heading H1"
                    value={
                      updateMeta &&
                      updateMeta.arabic &&
                      updateMeta.arabic.headingH1
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        "updateMeta"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading H2
                  </label>
                  <input
                    dir="rtl"
                    name="headingH2"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Heading H2"
                    value={
                      updateMeta &&
                      updateMeta.arabic &&
                      updateMeta.arabic.headingH2
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        "updateMeta"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
          data-for="size-chart-seo"
        >
          <Button
            name={"Save"}
            onClick={enable && accessRights.UPDATE ? this.handleSave : null}
            disabled={!accessRights.UPDATE}
            customClass={
              enable ? "marginBottom" : "opacity disable-btn marginBottom"
            }
          />
        </span>
        <ReactTooltip place="top" effect="solid" id="size-chart-seo" />
      </div>
    );
  }
}

const mapStateToProps = reduxState => ({
});

const mapDispatchToProps = {
  toastMsg,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSeoTab);