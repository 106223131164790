import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { STATIC_PAGE_PAGETYPE } from "../../constants";
import { updateStaticSeo } from "../../actions/staticPage.action";

class UpdateSEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: { index: 0, show: true },
      defaultContent: props.isOpen || false
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ defaultContent: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.pageIndex !== this.props.pageIndex ||
      nextProps.pageType !== this.props.pageType
    ) {
      this.setState({ defaultContent: nextProps.isOpen });
    }
  }

  toggleContent = contentName =>
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));

  render() {
    const { staticPages, updateStaticSeo, pageType, pageIndex } = this.props;
    const { defaultContent } = this.state;
    const seoContent =
      staticPages[STATIC_PAGE_PAGETYPE[pageType]] &&
      staticPages[STATIC_PAGE_PAGETYPE[pageType]][pageIndex]
        ? staticPages[STATIC_PAGE_PAGETYPE[pageType]][pageIndex].meta
        : "";
    return (
      <div className="wrapper-box">
        <div className="heading-wrapper flex catalog-banner-heading">
          <h3>
            <span className="required" />
            General SEO
          </h3>
          <span
            onClick={() => this.toggleContent("defaultContent")}
            className={`toggle-banner ${
              defaultContent ? "minus-banner" : "plus-banner"
            }`}
          />
        </div>
        {defaultContent && (
          <div className="data-wrapper">
            <div className="english-wrapper">
              <h4>English Version</h4>
              <div className="english-content">
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Page Title
                  </label>
                  <input
                    name="title"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Page Title"
                    value={seoContent ? seoContent.english.title : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "english",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Meta Keywords
                  </label>
                  <input
                    name="metaKeyword"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Meta Keywords"
                    value={seoContent ? seoContent.english.metaKeyword : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "english",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Meta Description
                  </label>
                  <textarea
                    name="metaDescription"
                    rows="12"
                    cols="50"
                    placeholder="Enter Meta Description"
                    value={seoContent ? seoContent.english.metaDescription : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "english",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading H1
                  </label>
                  <input
                    name="headingH1"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Heading H1"
                    value={seoContent ? seoContent.english.headingH1 : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "english",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading H2
                  </label>
                  <input
                    name="headingH2"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Heading H2"
                    value={seoContent ? seoContent.english.headingH2 : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "english",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="arabic-wrapper">
              <h4>Arabic Version</h4>
              <div className="arabic-content">
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Page Title
                  </label>
                  <input
                    name="title"
                    className="form-control date-calender"
                    type="text"
                    dir="rtl"
                    placeholder="أدخل عنوان الصفحة"
                    value={seoContent ? seoContent.arabic.title : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Meta Keywords
                  </label>
                  <input
                    name="metaKeyword"
                    className="form-control date-calender"
                    type="text"
                    dir="rtl"
                    placeholder="أدخل الكلمات الرئيسية ميتا"
                    value={seoContent ? seoContent.arabic.metaKeyword : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Meta Description
                  </label>
                  <textarea
                    name="metaDescription"
                    rows="12"
                    cols="50"
                    dir="rtl"
                    placeholder="أدخل الوصف الوصفى"
                    value={seoContent ? seoContent.arabic.metaDescription : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading H1
                  </label>
                  <input
                    name="headingH1"
                    className="form-control date-calender"
                    type="text"
                    dir="rtl"
                    placeholder="أدخل العنوان H1"
                    value={seoContent ? seoContent.arabic.headingH1 : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading H2
                  </label>
                  <input
                    name="headingH2"
                    className="form-control date-calender"
                    type="text"
                    dir="rtl"
                    placeholder="أدخل العنوان H2"
                    value={seoContent ? seoContent.arabic.headingH2 : ""}
                    onChange={e =>
                      updateStaticSeo(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        STATIC_PAGE_PAGETYPE[pageType],
                        pageIndex
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  staticPages: state.staticReducer.staticPages
});

const mapDispatchToProps = dispatch => ({
  updateStaticSeo: bindActionCreators(updateStaticSeo, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateSEO);
