import { createSelector } from "reselect";

export const selectPagesPageReducer = state => state.pagesPageReducer;

export const selectPagesFoldersShape = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.pagesFoldersShape
);

export const selectPagesContent = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.pagesContent
);

export const selectActivePage = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.activePage
);

export const selectNewAddedPage = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.newAddedPage
);

export const selectNewAddedFolder = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.newAddedFolder
);

export const selectOpenedContainerIds = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.openedContainerIds
);

export const selectOpenedFolderIds = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.openedFolderIds
);

export const selectPagesRoute = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.pagesRoute
);

export const selectResetToggled = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.resetToggled
);

export const selectInitialPagesContent = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.initialPagesContent
);

export const selectPagesRouteFolder = createSelector(
  [selectPagesRoute],
  pagesRoute => pagesRoute.folder
);

export const selectNewFolder = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.newFolder
);

export const selectFoldersFromShape = createSelector(
  [selectPagesFoldersShape],
  pagesFoldersShape => pagesFoldersShape.folders
);

export const selectFoldersByRouteFolder = createSelector(
  [selectFoldersFromShape, selectPagesRouteFolder],
  (folders, routedFolder) =>
    Object.values(folders).filter(
      folder => folder.scheduled === routedFolder.scheduled
    )
);

export const selectHomePage = createSelector(
  [selectPagesFoldersShape],
  selectPagesFoldersShape => selectPagesFoldersShape.homePage
);

export const selectIsActivePageHome = createSelector(
  [selectActivePage, selectHomePage],
  (activePage, homePage) =>
    activePage && homePage && activePage.id === homePage.id
);

export const selectAllRollbackHistory = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.rollbackHistory
);

export const selectToggleRollbackSave = createSelector(
  [selectPagesPageReducer],
  pagesPageReducer => pagesPageReducer.rollbackSave
);
