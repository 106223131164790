import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import filter from "lodash/filter";
import Popup from "reactjs-popup";
import PageSchedulerModal from "../modal/PageSchedulerModal";
import AddVariantModal from "../modal/AddVariantModal";
import { PAGES_PANEL, ROLES, MESSAGES, NAVIGATION } from "../../constants";
import PopupContent from "./popupContent";
import {
  setActivePage,
  getPageData,
  addSchedulersToPage,
  setPageOnTop,
  getAllRollbackHistory,
  updateVariant,
  setPageRollback,
  setPagesRollbackHistory,
} from "../../actions/pagesPage.action";
import {
  selectActivePage,
  selectHomePage,
  selectNewAddedPage,
  selectPagesFoldersShape,
  selectPagesRoute,
  selectResetToggled,
  selectAllRollbackHistory
} from "../../selectors";
import ConfirmModal from "../modal/ConfirmModal";
import { useIsInViewport } from "../../hooks";
import { getActivePageRoute, scrollIntoViewWithOffset } from "../../util";
import RollbackModalMUI from "../modal/RollbackModalMUI";
import { snackbar } from "../../actions/common.action";
import {
  DeleteIcon, HomeIcon, HistoryIcon, AccessTimeIcon,
  AbcIcon, DragIndicatorIcon, FolderIcon,
  StartIcon, NotesIcon
} from "../../materialUi/icons";
import { Tooltip, IconButton, Typography, Badge } from "../../materialUi/components";

const getNewScheduler = () => ({
  from: null,
  to: null,
  _id: null,
  enabled: false
});


const Page = ({
  page,
  index,
  activePage,
  setActivePage,
  deletePageHandler,
  schedulerCount = 0,
  history,
  pagesRoute,
  getPageData,
  pagesFoldersShape,
  addSchedulersToPage,
  setCurrentTab,
  resetToggled,
  addedPageId,
  setPageOnTop,
  homePage,
  rollbackHistory,
  toastMsg,
  snackbar,
  updateVariant,
  setPageRollback,
  getAllRollbackHistory,
  setPagesRollbackHistory,
  accessRights
}) => {
  const [activeModals, setActiveModals] = useState({
    scheduler: false,
    schedulerActiveConfirm: false,
    activePageChangeConfirm: false,
    rollbackModal: false,
    addVariantModal: false,
    variantModalActiveConfirm: false,
  });
  const [pinMode, setPinMode] = useState(false);
  const userRole = useSelector(state => state.authentication.user.role);

  const [pageSchedulers, setPageSchedulers] = useState(page.schedulers);
  const popupRef = useRef(null);
  const pageRef = useRef(null);
  const isIntersecting = useIsInViewport(pageRef);

  const onPageClick = () => {
    if (activePage && activePage.id === page.id) return null;

    const activePageRoute = getActivePageRoute(page.id, history);

    setActivePage(page);
    getPageData(page.id);

    setCurrentTab(PAGES_PANEL.PAGES_UPDATE_PAGE_DISPLAY);

    history.push(activePageRoute);
  };

  const onSchedulerClick = () => {
    if (activePage.id !== page.id) {
      onPageClick();
      return;
    }
    if (!schedulerCount) {
      setPageSchedulers([getNewScheduler([])]);
    } else {
      setPageSchedulers(page.schedulers);
    }

    setActiveModals({ ...activeModals, scheduler: true });
  };

  const onAddVariantClick = () => {
    if (activePage.id !== page.id) {
      onPageClick();
      return;
    }
    setActiveModals({ ...activeModals, addVariantModal: true });
  };

  const closeSchedulerModal = () => {
    setActiveModals({ ...activeModals, scheduler: false });
  };

  const closeAddVariantModal = () => {
    setActiveModals({ ...activeModals, addVariantModal: false });
  };

  const closeRollbackModal = () => {
    setActiveModals({ ...activeModals, rollbackModal: false });
    setPagesRollbackHistory([]);
  };

  const showModal = (e, modalName) => {
    setActiveModals({
      ...activeModals,
      [modalName]: true
    });
  };

  const hideModal = modalName => {
    setActiveModals({
      ...activeModals,
      [modalName]: false
    });
  };

  const onConfirmRollback = (id) => {
    setPageRollback(id);
  }

  const getRollbackHistory = (pageId, fromDate, toDate) => {
    getAllRollbackHistory(pageId, fromDate, toDate)
  }

  const scheduledPages = filter(pagesFoldersShape.pages, "scheduledPage").map(
    page => ({
      value: page.id,
      label: (
        <span className="scheduled-page-option">
          <FolderIcon />
          <span>{pagesFoldersShape.folders[page.folderId].title}</span>
          <StartIcon />
          <NotesIcon />
          <span>{page.title}</span>
        </span>
      )
    })
  );

  const onSchedulerSave = schedulers => {
    addSchedulersToPage(schedulers, page);
    closeSchedulerModal();
  };

  const onAddVariantSave = (variants = []) => {
    const index = variants.findIndex(({ pageId }) => !pageId);
    if (index > -1) {
      snackbar({ open: true, severity: "error", text: "All Variants need to be mapped" })
      return;
    }
    updateVariant({ isScheduler: false, isVariant: false }, page.id, variants);
    closeAddVariantModal();
  };

  const onPageDelete = e => {
    const { search, pathname } = history.location;
    if (page.variantPage) {
      const defaultPageFolderIds = pagesFoldersShape.pageContainers[0].folderIds || [];
      for (let folderId of defaultPageFolderIds) {
        if (pagesFoldersShape && pagesFoldersShape.folders && pagesFoldersShape.folders[folderId]) {
          const pages = pagesFoldersShape.folders[folderId].pages || [];
          for (let pageData of pages) {
            const variants = pageData.variants || [];
            const index = variants.findIndex(({ pageId }) => pageId === page.id);
            if (index > -1) {
              return snackbar({ open: true, severity: "error", text: `Cannot delete this page.Page is variant of ${pagesFoldersShape.folders[folderId].title}>${pageData.title}` })
            }
          }
        }
      }
    }
    deletePageHandler(e, page).then(() => {
      let { ...parsedSearch } = queryString.parse(search);
      if (activePage.id === page.id) {
        const newHistory = queryString.stringifyUrl({
          url: pathname,
          query: { ...parsedSearch }
        });
        history.push(newHistory);
        setCurrentTab();
        setActivePage(null);
      }
    });
  };

  let modal = null;
  if (!page.scheduledPage && !page.variantPage && activeModals.scheduler) {
    modal = (
      <PageSchedulerModal
        showModal={activeModals.scheduler}
        onHide={closeSchedulerModal}
        pageSchedulers={pageSchedulers}
        onSave={onSchedulerSave}
        onCancel={closeSchedulerModal}
        pagesRoute={pagesRoute}
        scheduledPages={scheduledPages}
      />
    );
  }
  if (activeModals.activePageChangeConfirm) {
    modal = (
      <ConfirmModal
        modalName="PageClick"
        onHide={() => {
          hideModal("activePageChangeConfirm");
        }}
        confirm={() => {
          hideModal("activePageChangeConfirm");
          onPageClick();
        }}
        message={
          "All the unsaved changes will be lost. Do you want to continue ?"
        }
      />
    );
  }
  if (activeModals.schedulerActiveConfirm) {
    modal = (
      <ConfirmModal
        modalName="SchedulerClick"
        onHide={() => {
          hideModal("schedulerActiveConfirm");
        }}
        confirm={() => {
          hideModal("schedulerActiveConfirm");
          onSchedulerClick();
        }}
        message={
          "All the unsaved changes will be lost. Do you want to continue ?"
        }
      />
    );
  }
  if (activeModals.rollbackModal) {
    modal = (
      <RollbackModalMUI
        pageId={page.id}
        history={rollbackHistory}
        open={activeModals.rollbackModal}
        dialogContentText={""}
        handleClose={closeRollbackModal}
        onConfirmRollback={onConfirmRollback}
        getRollbackHistory={getRollbackHistory}
        route={[pagesRoute?.folder?.title, pagesRoute?.page?.title]}
        pageType={NAVIGATION.PAGES}
      />
    );
  }
  if (activeModals.variantModalActiveConfirm) {
    modal = (
      <ConfirmModal
        modalName="VariantClick"
        onHide={() => {
          hideModal("variantModalActiveConfirm");
        }}
        confirm={() => {
          hideModal("variantModalActiveConfirm");
          onAddVariantClick();
        }}
        message={
          "All the unsaved changes will be lost. Do you want to continue ?"
        }
      />
    );
  }
  if (!page.scheduledPage && !page.variantPage && activeModals.addVariantModal) {
    modal = (
      <AddVariantModal
        onHide={closeAddVariantModal}
        variants={page.variants || []}
        onSave={onAddVariantSave}
        onCancel={closeAddVariantModal}
        pagesRoute={pagesRoute}
        pagesFoldersShape={pagesFoldersShape}
      />
    );
  }

  const hasUnsavedChanges =
    activePage && page.id !== activePage.id && resetToggled;
  const enablePinMode = e => {
    if (e.shiftKey) {
      setPinMode(true);
    }
  };
  const disablePinMode = () => {
    if (pinMode) {
      setPinMode(false);
    }
  };

  const pinPage = () => {
    setPageOnTop(pagesFoldersShape.folders[page.folderId], page);
  };

  useEffect(() => {
    const shouldBeScrolled =
      activePage && page.id === activePage.id && !isIntersecting;

    if (shouldBeScrolled) {
      scrollIntoViewWithOffset(pageRef.current);
    }
    // eslint-disable-next-line
  }, [activePage]);


  const isHomePage = useMemo(() => homePage.id === page.id, [homePage, page]);

  return (
    <div>
      {modal}
      <Draggable
        draggableId={`draggable-${page.id}`}
        index={index}
        isDragDisabled={pinMode}
      >
        {provided => (
          <li
            className="page"
            {...provided.draggableProps}
            ref={provided.innerRef}
            onClick={
              hasUnsavedChanges
                ? e => showModal(e, "activePageChangeConfirm")
                : onPageClick
            }
            onMouseEnter={enablePinMode}
            onMouseLeave={disablePinMode}
          >

            {
              <div
                {...provided.dragHandleProps}>
                <Tooltip title={"Fix page on top"} placement="top" arrow>
                  <span>
                    <IconButton
                      onClick={
                        pinPage
                      }
                      sx={{ cursor: "grab" }}
                    >
                      <DragIndicatorIcon sx={{ color: "white" }} fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            }
            {isHomePage && (
              <IconButton>
                <HomeIcon sx={{ color: "white" }} fontSize="small" />
              </IconButton>

            )}
            <span
              className={cn("page-title", {
                "active-page": page.id === (activePage || {}).id,
                "added-page": page.id === addedPageId
              })}
              ref={pageRef}
              style={{ "marginTop": "8px" }}
            >
              <Typography>
                {!isHomePage && <span>{index + 1 + ". "}</span>}
                {page.title}
              </Typography>
            </span>
            <div
              className={cn("page-icons-container", {
                "d-flex justify-content-end": isHomePage
              })}
              onClick={e => {
                e.stopPropagation();
              }}
              style={{ "marginLeft": "auto" }}
            >
              {activePage.id === page.id && userRole == ROLES.SUPERADMIN && (
                <Tooltip title={accessRights.UPDATE ? "Rollback" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                  <span>
                    <IconButton
                      disabled={!accessRights.UPDATE}
                      onClick={
                        accessRights.UPDATE
                          ? e => showModal(e, "rollbackModal")
                          : null
                      }
                    >
                      <HistoryIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {!page.scheduledPage && !page.variantPage && (
                <Tooltip title={accessRights.UPDATE ? "Scheduler" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                  <span>
                    <IconButton
                      disabled={!accessRights.UPDATE}
                      onClick={
                        accessRights.UPDATE
                          ? hasUnsavedChanges
                            ? e => showModal(e, "schedulerActiveConfirm")
                            : onSchedulerClick
                          : null
                      }
                    >
                      <Badge badgeContent={schedulerCount} color="error" size="small" variant="standard">
                        <AccessTimeIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                      </Badge>
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {!isHomePage ? (
                activePage.id !== page.id ? (
                  <Popup
                    ref={popupRef}
                    trigger={
                      <Tooltip title={accessRights.DELETE ? "Delete Page" : MESSAGES.TOOLTIP_DELETE} placement="top" arrow>
                        <span>
                          <IconButton
                            disabled={!accessRights.DELETE}
                          >
                            <DeleteIcon sx={{ color: "white", opacity: accessRights.DELETE ? 1 : 0.3 }} fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    }
                    position="right top"
                    disabled={!accessRights.DELETE}
                  >
                    <PopupContent
                      onConfirm={(e) => {
                        onPageDelete(e);
                        popupRef.current.close();
                      }}
                      onCancel={() => popupRef.current.close()}
                    />
                  </Popup>
                ) : (
                  <Tooltip title={"Active page can't be Delete"} placement="top" arrow>
                    <span>
                      <IconButton
                        disabled={true}
                      >
                        <DeleteIcon sx={{ color: "white", opacity: 0.3 }} fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )
              ) : null}
              {!page.scheduledPage && !page.variantPage && (
                <Tooltip title={accessRights.UPDATE ? "Add A/B Test Variant" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                  <span>
                    <IconButton
                      disabled={!accessRights.UPDATE}
                      onClick={
                        accessRights.UPDATE
                          ? hasUnsavedChanges
                            ? e => showModal(e, "variantModalActiveConfirm")
                            : onAddVariantClick
                          : null
                      }
                    >
                      <Badge badgeContent={page.variants?.length} color="error" size="small" variant="standard">
                        <AbcIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                      </Badge>
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </div>
          </li>
        )}
      </Draggable>
    </div>
  );
};

const mapStateToProps = state => ({
  activePage: selectActivePage(state),
  pagesRoute: selectPagesRoute(state),
  pagesFoldersShape: selectPagesFoldersShape(state),
  resetToggled: selectResetToggled(state),
  addedPageId: selectNewAddedPage(state),
  homePage: selectHomePage(state),
  rollbackHistory: selectAllRollbackHistory(state)
});

const mapDispatchToProps = {
  setActivePage,
  getPageData,
  addSchedulersToPage,
  setPageOnTop,
  getAllRollbackHistory,
  snackbar,
  updateVariant,
  setPageRollback,
  setPagesRollbackHistory,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Page);
