import React, { Fragment, useState } from "react";
import ArrowDown from "../../images/arrow-dd.svg";
import cn from "classnames";

import PermissionChild from "./permissionChild";
import { ACCES_TYPES } from "../../constants";

const PermissionList = ({
  permissionList,
  onChangeAccessRights,
  idMenuMapping
}) => {
  const [toggledItems, setToggledItems] = useState({});
  const handleChildDataView = (index, data) => {
    setToggledItems({ ...toggledItems, [data._id]: !toggledItems[data._id] });
  };
  return (
    <div className="row">
      <div className="user-management-table">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Sl.No</th>
              <th scope="col">Menu</th>
              <th scope="col">Role</th>
              <th scope="col">Read</th>
              <th scope="col">Write</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {permissionList &&
              permissionList.map((item, index) => (
                <Fragment key={item._id}>
                  <tr key={item._id}>
                    <td>
                      <span
                        onClick={() => handleChildDataView(index, item)}
                        data-tip
                        data-for="sub-menu-permissions"
                      >
                        <img
                          src={ArrowDown}
                          className={cn("nav-arrow-down", {
                            opened: toggledItems[item._id]
                          })}
                          alt="arrow down"
                        />
                      </span>
                      {index + 1}
                    </td>
                    <td>{item.menu && item.menu.name}</td>
                    <td>{item.role && item.role.name}</td>
                    <td>
                      <input
                        checked={item.accessRights.READ}
                        name={ACCES_TYPES.READ}
                        onChange={event =>
                          onChangeAccessRights(
                            ACCES_TYPES.READ,
                            event.target.checked,
                            index,
                            item._id,
                            item.role._id,
                            item._id
                          )
                        }
                        type="checkbox"
                        value={item.accessRights.READ}
                      />
                    </td>
                    <td>
                      <input
                        checked={item.accessRights.WRITE}
                        name={ACCES_TYPES.WRITE}
                        onChange={event =>
                          onChangeAccessRights(
                            ACCES_TYPES.WRITE,
                            event.target.checked,
                            index,
                            item._id,
                            item.role._id,
                            item._id
                          )
                        }
                        type="checkbox"
                        disabled={!item.accessRights.READ}
                      />
                    </td>
                    <td>
                      <input
                        checked={item.accessRights.UPDATE}
                        name={ACCES_TYPES.UPDATE}
                        onChange={event =>
                          onChangeAccessRights(
                            ACCES_TYPES.UPDATE,
                            event.target.checked,
                            index,
                            item._id,
                            item.role._id,
                            item._id
                          )
                        }
                        type="checkbox"
                        disabled={!item.accessRights.READ}
                      />
                    </td>
                    <td>
                      <input
                        checked={item.accessRights.DELETE}
                        name={ACCES_TYPES.DELETE}
                        onChange={event =>
                          onChangeAccessRights(
                            ACCES_TYPES.DELETE,
                            event.target.checked,
                            index,
                            item._id,
                            item.role._id,
                            item._id
                          )
                        }
                        type="checkbox"
                        disabled={!item.accessRights.READ}
                      />
                    </td>
                  </tr>
                  {toggledItems[item._id] &&
                  item.children &&
                  item.children.length ? (
                    <tr>
                      <td colSpan={4}>
                        <PermissionChild
                          permissionList={item.children}
                          menuId={item._id}
                          menuIndex={index}
                          roleId={item.role._id}
                          roleName={item.role.name}
                          onChangeAccessRights={onChangeAccessRights}
                          idMenuMapping={idMenuMapping}
                        />
                      </td>
                    </tr>
                  ) : null}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PermissionList;
