import React, { Component } from "react";
import Content from "./content";

class RightPanel extends Component {
  render() {
    const {
      currentTab,
      activePage,
      accessRights,
      initialActivePage,
      loadFirstPageDataHandler
    } = this.props;

    return (
      <div className="right-wrapper">
        <Content
          currentTab={currentTab}
          activePage={activePage || {}}
          accessRights={accessRights}
          initialActivePage={initialActivePage || {}}
          loadFirstPageDataHandler={loadFirstPageDataHandler}
        />
      </div>
    );
  }
}

export default RightPanel;
