import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastMsg } from "../../actions/common.action";
import { validateEmail } from "../../util/storeHelper";
import { ERROR_MESSAGES } from "../../constants";

const customStyles = {
  content: {
    width: "390px",
    height: "440px",
    position: "absolute",
    top: "20%",
    left: "35%",
    right: "40px",
    bottom: "40px",
    borderRadius: "none",
    background: "rgb(255, 255, 255)",
    overflow: "auto"
  },

  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

class AddUserModal extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    role: "",
    disableSubmit: true
  };

  /**
   * validate field (name, password, & email)
   */
  checkIfExists = (e, action, payload) => {
    e.preventDefault();
    const { name, email, password, role } = this.state;
    const { toastMsg } = this.props;
    if (!(name && email && password && role)) {
      toastMsg("error", "Please fill all input fields.");
    }else if (name!==name.trim()) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} Name`);
    } else if (!validateEmail(email)) {
      toastMsg("error", "Invalid user email.");
    }else if (email!==email.trim()) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} Email`);
    } else {
      action(payload);
    }
  };

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const { name, email, password, role } = this.state;
      const isInValid = !(name && password && email && role);
      this.setState({
        disableSubmit: isInValid
      });
    });
  };

  render() {
    const { name, email, password, role, disableSubmit } = this.state;
    const { modalName, onHide, addUser, roleList } = this.props;
    let _user = { name, password, email, role };

    return (
      <div>
        <Modal
          isOpen={true}
          ariaHideApp={false}
          onRequestClose={() => onHide(modalName)}
          style={customStyles}
          closeTimeoutMS={2000}
        >
          <form
            onSubmit={e => {
              this.checkIfExists(e, addUser, _user);
            }}
          >
            <div className="close-btn">
              <span onClick={() => onHide(modalName)} className="close-icon" />
            </div>
            <span className="modal-heading">Add New User</span>
            <input
              id="name"
              type="text"
              name="name"
              value={name}
              className="form-control m-b-20 no-border-radius column-height marginTop-20"
              placeholder="Full Name"
              onChange={this.changeHandler}
            />
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              className="form-control m-b-20 no-border-radius column-height"
              placeholder="Email ID"
              onChange={this.changeHandler}
            />
            <input
              id="password"
              type="password"
              name="password"
              value={password}
              className="form-control m-b-20 no-border-radius column-height"
              placeholder="Password"
              onChange={this.changeHandler}
            />
            <select
              id="role"
              className="form-control user-type"
              value={role}
              name="role"
              onChange={this.changeHandler}
            >
              {role ? null : <option value="">Select Role</option>}
              {roleList.map(role => (
                <option value={role.slug} key={role._id}>
                  {role.name}
                </option>
              ))}
            </select>
            <button
              className={`confirm-btn marginTop-20 ${
                disableSubmit ? "btn-disabled" : ""
              }`}
              disabled={disableSubmit || false}
              onClick={e => {
                this.checkIfExists(e, addUser, _user);
              }}
            >
              <span className="btn-text">Create User</span>
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  roleList: state.permissions.roleList
});

const mapDispatchToProps = dispatch => ({
  toastMsg: bindActionCreators(toastMsg, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
