import React from "react";
import get from "lodash/get";
import {
  SCHEDULER_TYPE,
  LANGUAGE,
  SCHEDULER_PLACEHOLDERS
} from "../../constants";
import Accordion from "../../component/accordion";
import InputText from "../../component/InputText";
import Scheduler from "../../component/scheduler";
import BannerNotes from "./bannerNotes";

const SCHEDULER_CHILD_CONTENT = [
  {
    language: LANGUAGE.ENGLISH,
    placeholder: SCHEDULER_PLACEHOLDERS[LANGUAGE.ENGLISH].banner,
    componentType: SCHEDULER_TYPE.BANNER
  },
  {
    language: LANGUAGE.ARABIC,
    placeholder: SCHEDULER_PLACEHOLDERS[LANGUAGE.ARABIC].banner,
    componentType: SCHEDULER_TYPE.BANNER
  }
];

const BannerAccordion = ({
  addNewScheduler,
  bannerId,
  content,
  deleteBanner,
  deleteScheduler,
  handleSchedulerDateChange,
  handleSchedulerTextChange,
  handleSchedulerToggleChange,
  handleTextChange,
  handleToggle,
  handleOpen,
  isEnabled,
  isOpen,
  type,
  keyIndex
}) => (
  <Accordion
    accordionId={bannerId || "banner"}
    title="Banner"
    headingWrapperClassName="catalog-banner-heading"
    handleDelete={deleteBanner}
    handleToggle={handleToggle}
    handleOpen={handleOpen}
    isToggled={isEnabled}
    isOpen={isOpen}
    isControlled={Boolean(handleOpen)}
    noDataWrapper
    required
    keyIndex={keyIndex}
    content={content}
    withDragNDrop
  >
    <div className="heading-wrapper">
      <h3>
        <span className="required" />
        Default HTML - This content will be displayed when there is no scheduler
      </h3>
    </div>
    <div className="data-wrapper">
      <div className="english-wrapper">
        <h4>English Version</h4>
        <div className="english-content">
          <InputText
            label="Default HTML"
            name="defaultTemplate"
            value={get(content, "defaultTemplate.english", "")}
            onChange={e => handleTextChange(e, LANGUAGE.ENGLISH)}
            textarea
            required
          />
        </div>
      </div>
      <div className="arabic-wrapper">
        <h4>Arabic Version</h4>
        <div className="arabic-content">
          <InputText
            label="Default HTML"
            name="defaultTemplate"
            value={get(content, "defaultTemplate.arabic", "")}
            onChange={e => handleTextChange(e, LANGUAGE.ARABIC)}
            textarea
            required
          />
        </div>
      </div>
      <BannerNotes />
      <Scheduler
        addNewScheduler={addNewScheduler}
        childContent={SCHEDULER_CHILD_CONTENT}
        content={content}
        deleteScheduler={deleteScheduler}
        handleDateChange={handleSchedulerDateChange}
        handleToggleChange={handleSchedulerToggleChange}
        schedulerTextChange={handleSchedulerTextChange}
        type={type}
      />
    </div>
  </Accordion>
);

export default BannerAccordion;
