import React, { Component } from "react";
import { connect } from "react-redux";
import { ENV_CONFIG } from "../../config/env";
import {
  FOOTER_PANEL,
  FOOTER_PANEL_LABELS,
  NAVIGATION,
  NAVIGATION_MENUS_SLUGS,
  SEE_CHANGES_MESSAGE,
} from "../../constants";
import { Paper } from "../../materialUi/components";
import RightPanel from "./rightPanel";
import CountryDropDown from "../countryDropdownMUI";
import ConfirmModal from "../modal/ConfirmModal";
import Subheader from "../../component/subheader"
import CustomSidePanel from "../../component/customSidePanel";
import {
  fetchFooterDataV2,
  updateFooterDetailsV2,
} from "../../actions/footer.action";
import { changeTab } from "../../actions/common.action";

class Footer extends Component {
  state = {
    showContent: true,
    currentTab: FOOTER_PANEL.MENU_ITEMS,
    confirmModal: false,
    confirmPreviewModal: false,
    confirmPreviewSubmitModal: false
  };

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.FOOTERNEW);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryId, fetchFooterDataV2 } = this.props;
    if (nextProps.countryId !== countryId) {
      fetchFooterDataV2(nextProps.storeId, nextProps.countryId);
    }
  }

  componentDidMount() {
    const { storeId, countryId, fetchFooterDataV2 } = this.props;
    if (storeId && countryId) {
      fetchFooterDataV2(storeId, countryId);
    }
  }

  changeTab = e => this.setState({ currentTab: e.target.id });

  updateFooterDetails = tempsave => {
    const {
      countryId,
      storeId,
      updateFooterDetailsV2,
      selectedCountryId,
      updateMenuItemContent,
    } = this.props;
    updateFooterDetailsV2(
      countryId,
      storeId,
      updateMenuItemContent,
      tempsave,
      selectedCountryId
    ).then(resp => {
      if (resp.data.code === 200 && tempsave) {
        this.setState({ confirmPreviewModal: true });
      }
    });
  };

  changeNav = () =>
    this.setState(prevState => ({ showContent: !prevState.showContent }));

  hidePreviewModal = () =>
    this.setState(prevProps => ({
      confirmPreviewModal: !prevProps.confirmPreviewModal
    }));

  confirmPreviewModal = () => {
    const { confirmPreviewModal } = this.state;
    const { storeList, storeId, countryId } = this.props;
    let selectedCountry = null,
      defaultCountry = null;
    storeList.forEach(store => {
      if (store.storeId === storeId) {
        store.countries.forEach(country => {
          if (country.countryId === countryId) {
            selectedCountry = country;
          }
          if (country.countrySHORT.toLowerCase() === "ae") {
            defaultCountry = country;
          }
        });
      }
    });
    this.setState({ confirmPreviewModal: !confirmPreviewModal }, () => {
      window.open(
        `${ENV_CONFIG.redirectionUrl}-${selectedCountry
          ? selectedCountry.countrySHORT.toLowerCase()
          : defaultCountry.countrySHORT.toLowerCase()
        }/?prevMode=true`,
        "_blank"
      );
    });
  };

  render() {
    const {
      currentTab,
      showContent,
      confirmPreviewModal,
    } = this.state;
    const {
      menuItemContent,
      updateMenuItemContent,
      user
    } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.FOOTER]: { accessRights = {} } = {}
      } = {}
    } = user || {};

    let breadcrumArr = [];
    if (currentTab) {
      breadcrumArr.push(FOOTER_PANEL_LABELS[currentTab])
    }
    return (
      <div className="row" style={{ "backgroundColor": "rgb(242, 245, 249)", "marginLeft": 0, "marginRight": 0, "paddingTop": "10px" }}>
        {confirmPreviewModal && (
          <ConfirmModal
            onHide={this.hidePreviewModal}
            confirm={this.confirmPreviewModal}
            message={SEE_CHANGES_MESSAGE}
          />
        )}
        <div className="component-header">
          <div className="col-sm-3" style={{ "paddingLeft": "10px", "paddingRight": "5px" }}>
            <Paper
              sx={{
                padding: "20px 15px 15px 15px",
              }}
              variant="outlined"
            >
              <CountryDropDown
                isFooterPage={true}
              />
              <CustomSidePanel
                isFooter
                isNewFooter
                currentTab={currentTab}
                panel={FOOTER_PANEL}
                heading="Footer"
                showContent={showContent}
                changeTab={this.changeTab}
                changeNav={this.changeNav}
                showCountryDropdown={false}
              />
            </Paper>
          </div>
          <div className="col-sm-9" style={{ "paddingLeft": "5px", "paddingRight": "10px" }}>
            <Subheader showCountry={true} breadcrumArr={breadcrumArr} />
            <RightPanel
              currentTab={currentTab}
              accessRights={accessRights}
              updateFooterDetails={this.updateFooterDetails}
              menuItemContent={menuItemContent}
              updateMenuItemContent={updateMenuItemContent}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  countryId: state.store.countryId,
  selectedCountryId: state.selectedCountryId,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  menuItemContent: state.footer.menuItemContent,
  updateMenuItemContent: state.footer.updateMenuItemContent
});

const mapDispatchToProps = {
  changeTab,
  fetchFooterDataV2,
  updateFooterDetailsV2
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
