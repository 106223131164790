import {
  ADD_NEED_HELP_SEO,
  CHANGE_CATEGORY,
  DISABLE_SUBMIT_BUTTON,
  ENABLE_SUBMIT_BUTTON,
  HANDLE_ACCORD_CHECK_BOX_CHANGE,
  HANDLE_ACCORD_TEXT_CHANGE,
  HANDLE_NEED_HELP_ACCORD_TILE,
  REMOVE_NEED_HELP_ACCORD_TILE,
  RESET_NEED_HELP_CONTENT,
  TOGGLE_RESET_BUTTON,
  UPDATE_NEED_HELP_CONTENT,
  UPDATE_NEED_HELP_ACCORD_TILE_POSITION
} from "../constants/actions";

const initialState = {
  apiContent: {},
  orderIssues: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  loyaltyProgram: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  paymentAndVouchers: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  trackingShippingAndDelivery: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  productAndStock: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  general: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  orderIssuesStatic: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  loyaltyProgramStatic: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  paymentAndVouchersStatic: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  trackingShippingAndDeliveryStatic: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  productAndStockStatic: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  generalStatic: {
    content: [],
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  },
  isSubmit: false,
  resetDisabled: false,
  needHelpKey: "orderIssues"
};
const enableSubmit = state => ({ ...state, isSubmit: true });

const disableSubmit = state => ({ ...state, isSubmit: false });

const toggleReset = (state, { value }) => {
  if (state.resetDisabled !== value) {
    return { ...state, resetDisabled: value };
  } else {
    return { ...state };
  }
};

const addNeedHelpSeo = (state, { key, name, value, lang }) => {
  return {
    ...state,
    [key]: {
      ...state[key],
      meta: {
        ...state[key].meta,
        [lang]: {
          ...state[key].meta[lang],
          [name]: value
        }
      }
    }
  };
};

const changeCategory = (state, { key }) => {
  return {
    ...state,
    needHelpKey: key
  };
};

const handleTextChange = (
  state,
  { index, needHelpKey, language, key, value }
) => {
  return {
    ...state,
    [needHelpKey]: {
      ...state[needHelpKey],
      content: [
        ...state[needHelpKey].content.slice(0, index),
        {
          ...state[needHelpKey].content[index],
          [language]: {
            ...state[needHelpKey].content[index][language],
            [key]: value
          }
        },
        ...state[needHelpKey].content.slice(index + 1)
      ]
    }
  };
};

const handleCheckBoxChange = (state, { index, needHelpKey, key, value }) => {
  return {
    ...state,
    [needHelpKey]: {
      ...state[needHelpKey],
      content: [
        ...state[needHelpKey].content.slice(0, index),
        {
          ...state[needHelpKey].content[index],
          [key]: value
        },
        ...state[needHelpKey].content.slice(index + 1)
      ]
    }
  };
};

export const addTile = (state, { pos, needHelpKey }) => {
  const addNewEntry = {
    english: { title: "", longTitle: "", answer: "" },
    arabic: { title: "", longTitle: "", answer: "" },
    pos: pos,
    position: pos + 1,
    onCoverFlag: false,
    faqFlag: false
  };
  return {
    ...state,
    [needHelpKey]: {
      ...state[needHelpKey],
      content: [...state[needHelpKey].content, addNewEntry]
    }
  };
};

export const removeTile = (state, { pos, needHelpKey }) => {
  return {
    ...state,
    [needHelpKey]: {
      ...state[needHelpKey],
      content: [
        ...state[needHelpKey].content.slice(0, pos),
        ...state[needHelpKey].content.slice(pos + 1)
      ]
    }
  };
};

export const updateNeedHelpAccordTilePosition = (
  state,
  { pos, needHelpKey, index }
) => {
  return {
    ...state,
    [needHelpKey]: {
      ...state[needHelpKey],
      content: [
        ...state[needHelpKey].content.slice(0, pos),
        { ...state[needHelpKey].content[pos], position: index },
        ...state[needHelpKey].content.slice(pos + 1)
      ]
    }
  };
};

export const updateNeedHelpData = (state, { result, currentTab }) => {
  if (Object.keys(result) && Object.keys(result).length) {
    result["orderIssues"] = result["orderIssues"] || state.orderIssuesStatic;
    result["loyaltyProgram"] =
      result["loyaltyProgram"] || state.loyaltyProgramStatic;
    result["paymentAndVouchers"] =
      result["paymentAndVouchers"] || state.paymentAndVouchersStatic;
    result["trackingShippingAndDelivery"] =
      result["trackingShippingAndDelivery"] ||
      state.trackingShippingAndDeliveryStatic;
    result["productAndStock"] =
      result["productAndStock"] || state.productAndStockStatic;
    result["general"] = result["general"] || state.generalStatic;
    result["orderIssues"].content =
      (result["orderIssues"] &&
        result["orderIssues"].content &&
        result["orderIssues"].content.sort((a, b) => {
          return a.position - b.position;
        })) ||
      [];
    result["loyaltyProgram"].content =
      (result["loyaltyProgram"] &&
        result["loyaltyProgram"].content &&
        result["loyaltyProgram"].content.sort((a, b) => {
          return a.position - b.position;
        })) ||
      [];
    result["paymentAndVouchers"].content =
      (result["paymentAndVouchers"] &&
        result["paymentAndVouchers"].content &&
        result["paymentAndVouchers"].content.sort((a, b) => {
          return a.position - b.position;
        })) ||
      [];
    result["trackingShippingAndDelivery"].content =
      (result["trackingShippingAndDelivery"] &&
        result["trackingShippingAndDelivery"].content &&
        result["trackingShippingAndDelivery"].content.sort((a, b) => {
          return a.position - b.position;
        })) ||
      [];
    result["productAndStock"].content =
      (result["productAndStock"] &&
        result["productAndStock"].content &&
        result["productAndStock"].content.sort((a, b) => {
          return a.position - b.position;
        })) ||
      [];
    result["general"].content =
      (result["general"] &&
        result["general"].content &&
        result["general"].content.sort((a, b) => {
          return a.position - b.position;
        })) ||
      [];
  }

  return {
    ...state,
    apiContent: result,
    orderIssues: result.orderIssues || initialState.orderIssuesStatic,
    loyaltyProgram: result.loyaltyProgram || initialState.loyaltyProgramStatic,
    paymentAndVouchers:
      result.paymentAndVouchers || initialState.paymentAndVouchersStatic,
    trackingShippingAndDelivery:
      result.trackingShippingAndDelivery ||
      initialState.trackingShippingAndDeliveryStatic,
    productAndStock:
      result.productAndStock || initialState.productAndStockStatic,
    general: result.general || initialState.generalStatic,
    needHelpKey: currentTab || "orderIssues"
  };
};

const resetNeedHelpData = (state, { needHelpKey }) => {
  return {
    ...state,
    [needHelpKey]: state.apiContent[needHelpKey]
  };
};

const needHelpReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEED_HELP_SEO:
      return addNeedHelpSeo(state, action);
    case CHANGE_CATEGORY:
      return changeCategory(state, action);
    case HANDLE_ACCORD_TEXT_CHANGE:
      return handleTextChange(state, action);
    case HANDLE_ACCORD_CHECK_BOX_CHANGE:
      return handleCheckBoxChange(state, action);
    case HANDLE_NEED_HELP_ACCORD_TILE:
      return addTile(state, action);
    case REMOVE_NEED_HELP_ACCORD_TILE:
      return removeTile(state, action);
    case UPDATE_NEED_HELP_ACCORD_TILE_POSITION:
      return updateNeedHelpAccordTilePosition(state, action);
    case UPDATE_NEED_HELP_CONTENT:
      return updateNeedHelpData(state, action);
    case RESET_NEED_HELP_CONTENT:
      return resetNeedHelpData(state, action);
    case ENABLE_SUBMIT_BUTTON:
      return enableSubmit(state, action);
    case DISABLE_SUBMIT_BUTTON:
      return disableSubmit(state, action);
    case TOGGLE_RESET_BUTTON:
      return toggleReset(state, action);
    default:
      return state;
  }
};

export default needHelpReducer;
