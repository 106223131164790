export const NEED_HELP_PANEL = {
  ORDER_ISSUES: "orderIssues",
  LOYALTY_PROGRAM: "loyaltyProgram",
  PAYMENT_AND_VOUCHERS: "paymentAndVouchers",
  TRACKING_SHIPPING_AND_DELIVERY: "trackingShippingAndDelivery",
  PRODUCT_AND_STOCK: "productAndStock",
  General: "general"
};

export const NEED_HELP_PANEL_MAP = {
  orderIssues: "ORDER_ISSUES",
  loyaltyProgram: "LOYALTY_PROGRAM",
  paymentAndVouchers: "PAYMENT_AND_VOUCHERS",
  trackingShippingAndDelivery: "TRACKING_SHIPPING_AND_DELIVERY",
  productAndStock: "PRODUCT_AND_STOCK",
  general: "General"
};

export const NEED_HELP_PANEL_API_MAP = {
  orderIssues: "order-issues",
  loyaltyProgram: "loyalty-program",
  paymentAndVouchers: "payment-and-vouchers",
  trackingShippingAndDelivery: "tracking-shipping-and-delivery",
  productAndStock: "product-and-stock",
  general: "general"
};

export const NEED_HELP_CATEGORY = {
  ORDER_ISSUES: "Order Issues",
  LOYALTY_PROGRAM: "Loyalty Program",
  PAYMENT_AND_VOUCHERS: "Payment And Vouchers",
  TRACKING_SHIPPING_AND_DELIVERY: "Tracking, Shipping And Delivery",
  PRODUCT_AND_STOCK: "Products And Stock",
  General: "General"
};
