import React, { Fragment, useState } from "react";
import cn from "classnames";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import Image from "../../component/Image";
import Button from "../../component/button";
import infoIcon from "../../images/icon-info.svg";
import {
  COLUMN_IMAGE_CONTENT,
  IMAGE_SIZE_AND_TYPE_TOOLTIP,
  LANGUAGE
} from "../../constants";
import ConfirmModal from "../modal/ConfirmModal";

const ColumnImageContent = ({
  imageList,
  handleImageType,
  selectedImgIndex,
  openUploadImageModal,
  imgClassList,
  arabicImagesContent,
  englishImagesContent,
  removeImage,
  clearAllImages
}) => {
  const sortedArabicImagesContent = sortBy(arabicImagesContent, "pos");
  const sortedEnglishImagesContent = sortBy(englishImagesContent, "pos");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const toggleConfirmModal = () => setShowConfirmModal(scm => !scm);

  const handleConfirmModal = () => {
    clearAllImages();
    setShowConfirmModal(false);
  };

  const isSelectedImageType = index => selectedImgIndex === index + 1;

  return (
    <Fragment>
      {showConfirmModal && (
        <ConfirmModal
          onHide={toggleConfirmModal}
          confirm={handleConfirmModal}
          message={COLUMN_IMAGE_CONTENT.SAVED_DATA_LOSS}
        />
      )}
      <div className="imag-list-wrapper">
        <div className="row">
          {imageList.map((item, index) => (
            <div
              key={item.imgName}
              className="col-sm-2 img-container center-align"
              onClick={() => {
                if (!isSelectedImageType(index)) {
                  handleImageType(index + 1, item);
                }
              }}
            >
              <Image
                className={cn({
                  "selected-img": isSelectedImageType(index)
                })}
                src={require(`../../images/${item.imgName}.png`)}
              />
              {isSelectedImageType(index) && (
                <span className="fa fa-check btn-cust-color" />
              )}
            </div>
          ))}
        </div>

        {!isEmpty(imgClassList) && (
          <div className="imageHeading">
            <div className="row">
              <h3 className="col-sm-6">English Images</h3>
              <h3 className="col-sm-6">Arabic Images</h3>
            </div>

            {map(imgClassList, (imageClass, i) => (
              <div className={`row imageRow ${`imageRow${i}`}`} key={i}>
                <div className="form-group img-preview col-sm-6">
                  <span className="info img-info">
                    <Image src={infoIcon} alt="info icon" />
                    <p className="tooltiptext">
                      {`${imageClass.toolTipMsg} ${IMAGE_SIZE_AND_TYPE_TOOLTIP}`}
                    </p>
                  </span>
                  <div className="form-group preview hover-icon m-r-20">
                    <span className="img-title">
                      <span className="required" />
                      Image
                    </span>
                    {get(sortedEnglishImagesContent, `[${i}].imageUrl`) ? (
                      <Fragment>
                        <span>
                          <span className="img-overlay" />
                          <span
                            className="icon-close"
                            onClick={() =>
                              removeImage(
                                sortedEnglishImagesContent[i].pos,
                                LANGUAGE.ENGLISH
                              )
                            }
                          />
                        </span>
                        <span
                          className="hover-camera-icon"
                          onClick={() =>
                            openUploadImageModal(
                              imageClass.imgClass,
                              i + 1,
                              LANGUAGE.ENGLISH
                            )
                          }
                        />
                        <span>
                          <Image
                            className="img-dimension object-fit-scale-down"
                            alt="No Image"
                            src={get(
                              sortedEnglishImagesContent,
                              `[${i}].imageUrl`,
                              ""
                            )}
                          />
                        </span>
                      </Fragment>
                    ) : (
                      <span
                        className="camera-icon"
                        onClick={() =>
                          openUploadImageModal(
                            imageClass.imgClass,
                            i + 1,
                            LANGUAGE.ENGLISH
                          )
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="form-group img-preview col-sm-6">
                  <span className="info img-info">
                    <Image src={infoIcon} alt="info icon" />
                    <p className="tooltiptext">
                      {`${imageClass.toolTipMsg} ${IMAGE_SIZE_AND_TYPE_TOOLTIP}`}
                    </p>
                  </span>
                  <div className="form-group preview hover-icon m-r-20">
                    <span className="img-title">
                      <span className="required" />
                      Image
                    </span>
                    {get(sortedArabicImagesContent, `[${i}].imageUrl`) ? (
                      <Fragment>
                        <span>
                          <span className="img-overlay" />
                          <span
                            className="icon-close"
                            onClick={() =>
                              removeImage(
                                sortedArabicImagesContent[i].pos,
                                LANGUAGE.ARABIC
                              )
                            }
                          />
                        </span>
                        <span
                          className="hover-camera-icon"
                          onClick={() =>
                            openUploadImageModal(
                              imageClass.imgClass,
                              i + 1,
                              LANGUAGE.ARABIC
                            )
                          }
                        />
                        <span>
                          <Image
                            className="img-dimension object-fit-scale-down"
                            alt="No Image"
                            src={get(
                              sortedArabicImagesContent,
                              `[${i}].imageUrl`,
                              ""
                            )}
                          />
                        </span>
                      </Fragment>
                    ) : (
                      <span
                        className="camera-icon"
                        onClick={() =>
                          openUploadImageModal(
                            imageClass.imgClass,
                            i + 1,
                            LANGUAGE.ARABIC
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {selectedImgIndex && selectedImgIndex > 0 && (
          <Button name="Clear" onClick={toggleConfirmModal} />
        )}
      </div>
    </Fragment>
  );
};

export default ColumnImageContent;
