export const SIZE_CHART_ENDPOINT = {
  generalSeoDisplay: "meta",
  generalDisplay: "general",
  brandSizeChartDisplay: "brandSizeChart",
  genericSizeChartDisplay: "genericSizeChart"
};

export const SUBMIT_SIZE_CHART_DATA = {
  generalSeoDisplay: "updateMeta",
  generalDisplay: "updateGeneral",
  brandSizeChartDisplay: "updateBrandSizeChart",
  genericSizeChartDisplay: "updateGenericSizeChart"
};

export const SIZE_CHART_API_KEYS = {
  generalDisplay: "general",
  genericSizeChartDisplay: "genericSizeChart",
  brandSizeChartDisplay: "brandSizeChart",
  generalSeoDisplay: "meta"
};

export const SIZE_CHART_UPDATE_REDUCER_KEYS = {
  updateGeneral: "general",
  updateBrandSizeChart: "brandSizeChart",
  updateGenericSizeChart: "genericSizeChart",
  updateMeta: "meta"
};

export const SIZE_CHART_PANEL = {
  SEO: "seo",
  GENERAL: "general",
  BRAND_SIZE_CHART: "brandSizeChart",
  GENERIC_SIZE_CHART: "genericSizeChart",
  MEN: "men",
  WOMEN: "women",
  KIDS: "kids"
};

export const SIZE_CHART_CATEGORY_MAP = {
  men: "Men",
  women: "Women",
  kids: "Kids",
  home: "Home"
};
