import qs from "query-string";

import {
  fetchWebApi,
  deleteWebApi,
  putDashboardWebApi,
  postDashboardWebApi
} from "../webapis/apiResource";
import { ENV_CONFIG } from "../config/env";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  toastMsg
} from "../actions/common.action";
import {
  LIST_ROLES,
  LIST_PERMISSIONS,
  LIST_PERMISSIONS_BY_ROLE,
  UPDATE_PERMISSIONS_DATA
} from "../constants/actions";
import { fetchUserPermission } from "./user.action";

const listPermissionsData = data => ({
  type: LIST_PERMISSIONS,
  data
});

export const fetchPermissionsData = () => {
  let apiURL = `${ENV_CONFIG.configBaseURL}/user-permissions`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(listPermissionsData(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const listRoleManagementData = data => ({
  type: LIST_ROLES,
  data
});

export const fetchRoleManagementData = () => {
  let apiURL = `${ENV_CONFIG.configBaseURL}/roles`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(listRoleManagementData(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const listPermissionsByRole = data => ({
  type: LIST_PERMISSIONS_BY_ROLE,
  data
});

export const updatePermissionsData = ({
  name,
  value,
  menuIndex,
  itemId,
  roleId,
  menuId
}) => ({
  type: `${UPDATE_PERMISSIONS_DATA}`,
  name,
  value,
  menuIndex,
  itemId,
  roleId,
  menuId
});

export const fetchPermissionsByRole = id => {
  let apiURL = `${ENV_CONFIG.configBaseURL}/user-permissions/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(listPermissionsByRole(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updatePermissionsByRole = (data, roleId) => (
  dispatch,
  getState
) => {
  dispatch(showLoadingIndicator());
  return putDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/user-permissions/update-by-role/${roleId}`
    }),
    data
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(toastMsg("success", "Updated successfully"));
          dispatch(listPermissionsByRole(response.data.data));
          dispatch(fetchUserPermission());
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message || "Something went wrong"
            )
          );
        }
      }

      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);

      return error.response;
    });
};
