import React from "react";
import MenuItem from "./menuItems";
import { ACCES_TYPES } from "../../constants";
import { Menu, Divider } from "../../materialUi/components";
const SubMenuItem = ({
  menus,
  navigate,
  depthLevel,
  currentTab,
  permissions,
  anchorEl,
  open,
  id,
  handleClose,
  handleParentClose,
}) => {
  let menuList = menus.filter(({ slug }) => {
    const hasPermission =
      permissions[slug] &&
        permissions[slug]["accessRights"] &&
        permissions[slug]["accessRights"][ACCES_TYPES.READ]
        ? true
        : false;
    return hasPermission;
  });

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': id,
      }}
    >
      <ul
        style={{
          listStyleType: "none",
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingBottom: 0,
          paddingTop: 0
        }}
      >
        {menuList.map((menu, index) => {
          return (
            <li key={menu._id}>
              <MenuItem
                menu={menu}
                key={menu._id}
                currentTab={currentTab}
                navigate={navigate}
                depthLevel={depthLevel + 1}
                permissions={permissions}
                handleParentClose={handleParentClose}
              />
              {index !== menuList.length - 1 &&
                <Divider />
              }
            </li>
          );
        })}
      </ul>
    </Menu>

  )
};

export default SubMenuItem;
