import { LIST_ROLES } from "../constants/actions";

const initialState = {
  roleList: []
};

const roleList = (state, { data }) => {
  return { ...state, roleList: data };
};
const roleManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ROLES:
      return roleList(state, action);
    default:
      return state;
  }
};

export default roleManagementReducer;
