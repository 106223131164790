import { fetchWebApi, postAuthApi } from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { ENV_CONFIG } from "../config/env";
import {
  USER_INFO_RECEIVED,
  UPDATE_USER_PERMISSIONS
} from "../constants/actions";
import { tokenGenerator, logOutUser } from "./authConfig.action";
import { setUserStorage, removeUserStorage } from "../util/browserStorage";
import { handleFetchError } from "../util/errorHandler";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  toastMsg
} from "../actions/common.action";
import { ERROR_MESSAGES } from "../constants";

export const setToken = ({ token }) => {
  return dispatch => {
    dispatch(tokenGenerator(token));
    setUserStorage(token);
  };
};

export const setUserInfo = user => ({ type: USER_INFO_RECEIVED, user });

export const fetchUserAndLoadData = () => {
  const url = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/user/me`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), url)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(setUserInfo(response.data.data));
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message || ERROR_MESSAGES["userLogIn"]
            )
          );
          dispatch(logOut());
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const logOut = () => {
  removeUserStorage();
  return dispatch => {
    dispatch(logOutUser());
  };
};

export const login = body => {
  const url = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/auth/local`;
  return dispatch => {
    dispatch(showLoadingIndicator());
    return postAuthApi(url, body)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(setToken(response.data.data));
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message || ERROR_MESSAGES["userLogIn"]
            )
          );
          dispatch(logOut());
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const setUserPermissions = permissions => ({
  type: UPDATE_USER_PERMISSIONS,
  permissions
});

export const fetchUserPermission = () => {
  let apiURL = `${ENV_CONFIG.baseURL}/user-permissions/role/all`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            dispatch(setUserPermissions(response.data.data));
          } else {
            dispatch(
              toastMsg(
                "error",
                response.data.error.message || "Something went wrong"
              )
            );
          }
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
