import React, { Component } from "react";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import ConfirmModal from "../modal/ConfirmModal";
import CustomSidePanel from "../../component/customSidePanel";
import AddFooterItem from "./addFooterItem";
import StaticContent from "./staticContent";
import {
  fetchFooterData,
  updateFooterDetails,
  clearFooterContent
} from "../../actions/footer.action";
import { changeTab } from "../../actions/common.action";
import {
  FOOTER_PANEL,
  NAVIGATION,
  NAVIGATION_MENUS_SLUGS,
  SEE_CHANGES_MESSAGE,
  DISCARD_CHANGES_CONFIRMATION_MESSAGE,
  UNSAVED_CHANGES_WARNING_MESSAGE
} from "../../constants";
import { ENV_CONFIG } from "../../config/env";
import { ApplyDropDown } from "../../component/applyDropDown";
import isEqual from "lodash/isEqual";

class Footer extends Component {
  state = {
    showContent: true,
    currentTab: FOOTER_PANEL.MENU_ITEMS,
    confirmModal: false,
    confirmPreviewModal: false,
    confirmPreviewSubmitModal: false
  };

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.FOOTER);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { countryId, fetchFooterData } = this.props;
    if (nextProps.countryId !== countryId) {
      fetchFooterData(nextProps.storeId, nextProps.countryId);
    }
  }

  componentDidMount() {
    const { storeId, countryId, fetchFooterData } = this.props;
    if (storeId && countryId) {
      fetchFooterData(storeId, countryId);
    }
  }

  hideModal = () =>
    this.setState(prevProps => ({ confirmModal: !prevProps.confirmModal }));

  confirmModal = () => {
    const { confirmModal } = this.state;
    this.setState({ confirmModal: !confirmModal });
  };

  changeTab = e => this.setState({ currentTab: e.target.id });

  clearFooterContent = () => { };

  recursiveArrayFunc = (arabicContentObj = {}, englishContentObj = {}) => {
    if (arabicContentObj.uid === englishContentObj.uid) {
      arabicContentObj["en_title"] = englishContentObj.title;
      if (arabicContentObj?.children?.length && englishContentObj?.children?.length) {
        arabicContentObj.children.forEach((content, index) => {
          this.recursiveArrayFunc(arabicContentObj.children[index], englishContentObj.children[index])
        })
      }
    }
  }

  addEnglishTitleInArabicContent = (updateEnglishList, updateArabicList) => {
    const englishArr = cloneDeep(updateEnglishList) || [];
    const arabicArr = cloneDeep(updateArabicList) || [];

    arabicArr.forEach((content, index) => {
      this.recursiveArrayFunc(arabicArr[index], englishArr[index])
    })

    return { englishArr, arabicArr }
  }

  updateFooterDetails = tempsave => {
    const {
      countryId,
      storeId,
      updateArabicList,
      updateEnglishList,
      updateFooterDetails,
      selectedCountryId,
      arabic,
      english
    } = this.props;
    if (
      !tempsave &&
      (!isEqual(updateArabicList, arabic) ||
        !isEqual(updateEnglishList, english))
    ) {
      this.setState({ confirmPreviewSubmitModal: true });
    } else {
      const { englishArr, arabicArr } = this.addEnglishTitleInArabicContent(updateEnglishList, updateArabicList);
      updateFooterDetails(
        countryId,
        storeId,
        englishArr,
        arabicArr,
        tempsave,
        selectedCountryId
      ).then(resp => {
        if (resp.data.code === 200 && tempsave) {
          this.setState({ confirmPreviewModal: true });
        }
      });
    }
  };

  changeNav = () =>
    this.setState(prevState => ({ showContent: !prevState.showContent }));

  hidePreviewModal = () =>
    this.setState(prevProps => ({
      confirmPreviewModal: !prevProps.confirmPreviewModal
    }));

  hidePreviewSubmitModal = () =>
    this.setState(prevProps => ({
      confirmPreviewSubmitModal: !prevProps.confirmPreviewSubmitModal
    }));

  confirmPreviewModal = () => {
    const { confirmPreviewModal } = this.state;
    const { storeList, storeId, countryId } = this.props;
    let selectedCountry = null,
      defaultCountry = null;
    storeList.forEach(store => {
      if (store.storeId === storeId) {
        store.countries.forEach(country => {
          if (country.countryId === countryId) {
            selectedCountry = country;
          }
          if (country.countrySHORT.toLowerCase() === "ae") {
            defaultCountry = country;
          }
        });
      }
    });
    this.setState({ confirmPreviewModal: !confirmPreviewModal }, () => {
      window.open(
        `${ENV_CONFIG.redirectionUrl}-${selectedCountry
          ? selectedCountry.countrySHORT.toLowerCase()
          : defaultCountry.countrySHORT.toLowerCase()
        }/?prevMode=true`,
        "_blank"
      );
    });
  };

  confirmPreviewSubmitModal = (tempsave = false) => {
    const {
      countryId,
      storeId,
      updateArabicList,
      updateEnglishList,
      updateFooterDetails,
      selectedCountryId
    } = this.props;
    updateFooterDetails(
      countryId,
      storeId,
      updateEnglishList,
      updateArabicList,
      tempsave,
      selectedCountryId
    ).then(() => {
      this.setState({ confirmPreviewSubmitModal: false });
    });
  };

  render() {
    const {
      confirmModal,
      currentTab,
      showContent,
      confirmPreviewModal,
      confirmPreviewSubmitModal
    } = this.state;
    const {
      updateEnglishContent,
      updateArabicContent,
      arabic,
      english,
      treeData,
      selectedCountryName,
      user
    } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.FOOTER]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    const breadCrumb =
      (selectedCountryName && `Footer, ${selectedCountryName}`) || "Footer";
    return (
      <div className="row m-t-10">
        {confirmModal && (
          <ConfirmModal
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={DISCARD_CHANGES_CONFIRMATION_MESSAGE}
          />
        )}
        {confirmPreviewModal && (
          <ConfirmModal
            onHide={this.hidePreviewModal}
            confirm={this.confirmPreviewModal}
            message={SEE_CHANGES_MESSAGE}
          />
        )}
        {confirmPreviewSubmitModal && (
          <ConfirmModal
            onHide={this.hidePreviewSubmitModal}
            confirm={() => this.confirmPreviewSubmitModal(false)}
            message={UNSAVED_CHANGES_WARNING_MESSAGE}
          />
        )}
        <div className="component-header header-container">
          <div className="col-sm-3">
            <CustomSidePanel
              isFooter
              currentTab={currentTab}
              panel={FOOTER_PANEL}
              heading="Footer"
              showContent={showContent}
              changeTab={this.changeTab}
              changeNav={this.changeNav}
              clearMenuContent={this.clearFooterContent}
              englishList={[]}
              arabicList={[]}
              showCountryDropdown={true}
            />
          </div>

          <div className="col-sm-9">
            <h2 className="page-main-heading">
              <div className="row">
                <div className="col-sm-3">{breadCrumb}</div>
                <div className="col-sm-9">
                  <ApplyDropDown onlyCountries />
                </div>
              </div>
            </h2>
            {currentTab === FOOTER_PANEL.MENU_ITEMS && (
              <AddFooterItem
                englishMenuData={updateEnglishContent}
                arabicMenuData={updateArabicContent}
                treeData={treeData}
                englishList={english}
                arabicList={arabic}
                updateFooterDetails={this.updateFooterDetails}
                accessRights={accessRights}
              />
            )}
            {currentTab === FOOTER_PANEL.STATIC_CONTENT && (
              <StaticContent accessRights={accessRights} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  arabic: state.footer.arabic,
  countryId: state.store.countryId,
  english: state.footer.english,
  selectedCountryId: state.selectedCountryId,
  selectedCountryName: state.store.selectedCountryName,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  treeData: state.footer.treeData,
  updateArabicContent: state.footer.updateArabicContent,
  updateArabicList: state.footer.updateArabicList,
  updateEnglishContent: state.footer.updateEnglishContent,
  updateEnglishList: state.footer.updateEnglishList
});

const mapDispatchToProps = {
  changeTab,
  clearFooterContent,
  fetchFooterData,
  updateFooterDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
