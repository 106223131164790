export const ADD_MEGA_MENU_ITEM_PLACEHOLDERS = {
  arabic: {
    headerTitle: "أدخل اسم عنصر القائمة",
    redirectionLink: "أدخل رابط إعادة التوجيه",
    selectOption: "حدد الخيار"
  },
  english: {
    headerTitle: "Enter Menu Item Name",
    redirectionLink: "Enter Redirection Link (must start with '/')",
    selectOption: "Select option"
  }
};

export const ADD_MENU_ITEM_MODAL_PLACEHOLDERS = {
  arabic: {
    groupBy: "أدخل المجموعة بواسطة",
    redirectUrl: "أدخل عنوان URL لإعادة التوجيه",
    subTitle: "أدخل العنوان الفرعي"
  },
  english: {
    groupBy: "Enter Group By",
    redirectUrl: "Enter Redirection Url (must start with '/')",
    subTitle: "Enter Sub Title"
  }
};

export const CAROUSEL_LIST_PLACEHOLDERS = {
  arabic: {
    redirectUrl: "أدخل عنوان URL لإعادة التوجيه"
  },
  english: {
    redirectUrl: "Enter Redirection Link (must start with '/')"
  }
};
