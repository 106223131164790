import { createSelector } from "reselect";

export const selectUrlReducer = state => state.urlCreation;

export const selectActiveUrlPage = createSelector(
  [selectUrlReducer],
  urlCreation => urlCreation.activePage
);

export const selectActiveUrlPageContent = createSelector(
  [selectUrlReducer],
  urlCreation => urlCreation.activePage.content
);
