import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";

import {
  addSchedulerDeviceLevel,
  deleteSchedulerDeviceLevel,
  schedulerDateChangeDeviceLevel,
  toggleSchedulerDeviceLevel,
  schedulerDataChangeDeviceLevel,
  copyParentContentToBannerScheduler,
  cloneBannerScheduler
} from "../../../actions/dynamicBanner.action";
import { BannerTabContent } from "./BannerTabContent";
import Scheduler from "../../scheduler";
import { toastMsg } from "../../../actions/common.action";
import CustomModal from "../../../containers/modal/ConfirmModal";
import { DELETE_ITEM_CONFIRMATION_MESSAGE } from "../../../constants";
import {
  getBannerSchedulersAllLevels,
  isSchedulerPopUpEnabled
} from "../../../util/pagesHandlers";

export const BannerTab = ({
  listPosition,
  content,
  deviceType,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation
}) => {
  const dispatch = useDispatch();

  const addScheduler = () =>
    dispatch(
      addSchedulerDeviceLevel({
        listPosition,
        deviceType,
        pageType,
        updateCatalogPageLocation,
        updateCatalogPageType
      })
    );

  const handleDeleteScheduler = schedulerIndex =>
    dispatch(
      deleteSchedulerDeviceLevel({
        listPosition,
        deviceType,
        schedulerIndex,
        pageType,
        updateCatalogPageLocation,
        updateCatalogPageType
      })
    );
  const copyParentContentToScheduler = schedulerIndex => {
    dispatch(
      copyParentContentToBannerScheduler({
        listPosition,
        deviceType,
        schedulerIndex,
        pageType,
        updateCatalogPageLocation,
        updateCatalogPageType
      })
    );
    dispatch(toastMsg("success", "Copied content to scheduler"));
  };
  const handleSchedulerDataChange = (schedulerIndex, key, value) =>
    dispatch(
      schedulerDataChangeDeviceLevel({
        listPosition,
        deviceType,
        schedulerIndex,
        key,
        value,
        pageType,
        updateCatalogPageLocation,
        updateCatalogPageType
      })
    );

  const handleSchedulerToggleChange = (_type, schedulerIndex, value) => {
    if (
      !value ||
      (content[deviceType]["schedulers"][schedulerIndex].from &&
        content[deviceType]["schedulers"][schedulerIndex].to)
    ) {
      dispatch(
        toggleSchedulerDeviceLevel({
          listPosition,
          deviceType,
          schedulerIndex,
          value,
          pageType,
          updateCatalogPageLocation,
          updateCatalogPageType
        })
      );
    } else {
      dispatch(toastMsg("error", "Missing from and to dates"));
    }
  };

  const handleSchedulerDateChange = (schedulerIndex, key, value) =>
    dispatch(
      schedulerDateChangeDeviceLevel({
        listPosition,
        deviceType,
        schedulerIndex,
        key,
        value,
        pageType,
        updateCatalogPageLocation,
        updateCatalogPageType
      })
    );

  const cloneHandleBannerScheduler = schedulerData => {
    schedulerData.enabled = false;
    dispatch(
      cloneBannerScheduler({
        listPosition,
        deviceType,
        schedulerData,
        pageType,
        updateCatalogPageLocation,
        updateCatalogPageType
      })
    );
    dispatch(toastMsg("success", "Copied content to scheduler"));
  };

  const deviceContent = get(content, deviceType, {});

  const [
    deleteSchedulerModalVisible,
    setDeleteSchedulerModalVisible
  ] = useState(false);
  const [schedulerIdx, setSchedulerIdx] = useState(null);

  const [cloneSchedulerModalVisible, setCloneSchedulerModalVisible] = useState(
    null
  );
  const handleDeleteSchedulerData = schedulerIndex => {
    setDeleteSchedulerModalVisible(true);
    setSchedulerIdx(schedulerIndex);
  };

  const handleCloneSchedulerData = schedulerIndex => {
    setCloneSchedulerModalVisible(true);
    setSchedulerIdx(schedulerIndex);
  };

  const bannerSchedulers = getBannerSchedulersAllLevels(content, [deviceType]);
  const enableTogglePopup = isSchedulerPopUpEnabled(
    bannerSchedulers.rowsSchedules
  );

  return (
    <Fragment>
      {deleteSchedulerModalVisible && (
        <CustomModal
          modalName="row-confirmation-modal"
          onHide={() => setDeleteSchedulerModalVisible(false)}
          confirm={() => {
            handleDeleteScheduler(schedulerIdx);
            setSchedulerIdx(null);
            setDeleteSchedulerModalVisible(false);
          }}
          message={DELETE_ITEM_CONFIRMATION_MESSAGE}
        />
      )}

      {cloneSchedulerModalVisible && (
        <CustomModal
          modalName="row-confirmation-modal"
          onHide={() => setCloneSchedulerModalVisible(false)}
          confirm={() => {
            copyParentContentToScheduler(schedulerIdx);
            setSchedulerIdx(null);
            setCloneSchedulerModalVisible(false);
          }}
          message={`Are you sure you want to clone parent rows to scheduler? You can always undo your changes before saving`}
        />
      )}

      <BannerTabContent
        id={content.id}
        listPosition={listPosition}
        content={deviceContent}
        deviceType={deviceType}
        pageType={pageType}
        updateCatalogPageType={updateCatalogPageType}
        updateCatalogPageLocation={updateCatalogPageLocation}
      />
      <Scheduler
        pageType={pageType}
        addNewScheduler={addScheduler}
        childContent={[]}
        content={deviceContent}
        deleteScheduler={(_type, schedulerIndex) =>
          handleDeleteSchedulerData(schedulerIndex)
        }
        cloneBannerScheduler={cloneHandleBannerScheduler}
        copyParentContentToScheduler={(_type, schedulerIndex) =>
          handleCloneSchedulerData(schedulerIndex)
        }
        handleModuleTitleChange={(schedulerIndex, value) =>
          handleSchedulerDataChange(schedulerIndex, "title", value)
        }
        handleDateChange={handleSchedulerDateChange}
        handleToggleChange={handleSchedulerToggleChange}
        enableTogglePopup={enableTogglePopup}
        togglePopupContent={
          "Enabling banner scheduler will disable all the row level schedulers. Continue?"
        }
        schedulerItemRenderer={schedulerIndex => {
          const schedulerContent = get(
            deviceContent,
            `schedulers.${schedulerIndex}`,
            {}
          );

          return (
            <BannerTabContent
              id={content.id}
              isScheduler
              schedulerIndex={schedulerIndex}
              listPosition={listPosition}
              content={schedulerContent}
              deviceType={deviceType}
              pageType={pageType}
              updateCatalogPageType={updateCatalogPageType}
              updateCatalogPageLocation={updateCatalogPageLocation}
            />
          );
        }}
      />
    </Fragment>
  );
};
