import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import classnames from "classnames";
import ImageSelectionModal from "../modal/imageSelectionModal";
import Button from "../../component/button";
import { toastMsg } from "../../actions/common.action";
import { MEGA_MENU_ATTRIB_LANG_MAP, LANGUAGE, ERROR_MESSAGES } from "../../constants";
import {
  clearAttributeState,
  handleAttributesChange,
  saveAttribute,
  updateAttribute
} from "../../actions/megaMenu.action";
import { _validateRedirectionUrl } from "../../util";
import AddMenuItemModalForm from "./addMenuItemModalForm";

const customStyles = {
  content: {
    background: "rgb(255, 255, 255)",
    borderRadius: "none",
    height: "520px",
    left: "50%",
    overflow: "auto",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "980px"
  },
  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

class AddUserModal extends React.Component {
  state = {
    disableSubmit: true,
    imageSelectionModal: false,
    language: null
  };

  UNSAFE_componentWillMount() {
    const { isEdit } = this.props;
    if (isEdit) {
      this.setState({ disableSubmit: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      disableSubmit: !this.isAttributeDataValid(nextProps)
    });
  }

  isAttributeDataValid = nextProps => {
    const { englishAttributeData, arabicAttributeData } = nextProps;
    return (
      englishAttributeData &&
      englishAttributeData.subTitle &&
      englishAttributeData.redirectUrl &&
      englishAttributeData.imageUrl &&
      arabicAttributeData &&
      arabicAttributeData.subTitle &&
      arabicAttributeData.imageUrl
    );
  };

  handleAttributeChange = (name, value, language) => {
    const { handleAttributesChange } = this.props;
    if (name === "subTitle" && value.length > 50) {
      return null;
    }
    handleAttributesChange(MEGA_MENU_ATTRIB_LANG_MAP[language], name, value);
  };

  handleCommonAttributeChange = (name, value) => {
    let validatedValue = value;
    if (name === "redirectUrl") {
      validatedValue = _validateRedirectionUrl(value) ? value : `/${value}`;
    }
    this.handleAttributeChange(name, validatedValue, LANGUAGE.ENGLISH);
    this.handleAttributeChange(name, validatedValue, LANGUAGE.ARABIC);
  };

  validateFormData = (formData, language) => {
    const { toastMsg } = this.props;
    const { groupBy = "", subTitle = "" } = formData || {};
    if (subTitle && (subTitle !== subTitle.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Sub Title`);
      return false;
    }
    if (groupBy && (groupBy !== groupBy.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Group By`);
      return false;
    }

    return true;
  }


  handleSaveAttribute = e => {
    e.preventDefault();
    const { onHide, columnName, saveAttribute, arabicAttributeData, englishAttributeData } = this.props;
    const isValidEnglishForm = this.validateFormData(englishAttributeData, "English");
    if (!isValidEnglishForm) return;

    const isValidArabicForm = this.validateFormData(arabicAttributeData, "Arabic");
    if (!isValidArabicForm) return;

    saveAttribute(columnName);
    onHide(false);
  };

  handleUpdateAttribute = e => {
    e.preventDefault();
    const { columnName, updateAttribute, arabicAttributeData, englishAttributeData } = this.props;
    const isValidEnglishForm = this.validateFormData(englishAttributeData, "English");
    if (!isValidEnglishForm) return;

    const isValidArabicForm = this.validateFormData(arabicAttributeData, "Arabic");
    if (!isValidArabicForm) return;

    updateAttribute(columnName);
    this.closeModal();
  };

  closeModal = () => {
    const { onHide, clearAttributeState } = this.props;
    onHide(false);
    clearAttributeState();
  };

  handleModal = (modalName, language) => {
    this.setState(prevState => ({
      [modalName]: !prevState[modalName],
      language
    }));
  };

  updateURL = (key, value, brandId, language, modalName) => {
    this.handleAttributeChange(key, value, language);
    if (modalName) {
      this.setState(prevState => ({
        [modalName]: !prevState[modalName],
        language
      }));
    } else {
      this.setState({ language });
    }
  };

  render() {
    const { disableSubmit, imageSelectionModal, language } = this.state;
    const {
      arabicAttributeData,
      btnName,
      countryId,
      englishAttributeData,
      isEdit,
      isL3,
      modalName,
      storeId
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={true}
          ariaHideApp={false}
          onRequestClose={this.closeModal}
          style={customStyles}
          closeTimeoutMS={2000}
        >
          {imageSelectionModal && (
            <ImageSelectionModal
              title="Upload Category Image"
              modalName={"imageSelectionModal"}
              keyName={"imageUrl"}
              brandId={storeId}
              storeId={storeId}
              countryId={countryId}
              language={language}
              containerName={"imageList"}
              onHide={this.handleModal}
              onSuccess={this.updateURL}
            />
          )}
          <form onSubmit={this.handleSaveAttribute}>
            <span className="modal-heading">{modalName}</span>
            <div className="close-btn">
              <span onClick={this.closeModal} className="close-icon" />
            </div>
            <div className="wrapper-box">
              <div className="data-wrapper">
                <AddMenuItemModalForm
                  attributeData={englishAttributeData}
                  handleAttributeChange={this.handleAttributeChange}
                  handleCommonAttributeChange={this.handleCommonAttributeChange}
                  handleModal={this.handleModal}
                  isL3={isL3}
                  language={LANGUAGE.ENGLISH}
                />
                <AddMenuItemModalForm
                  attributeData={arabicAttributeData}
                  handleAttributeChange={this.handleAttributeChange}
                  handleCommonAttributeChange={this.handleCommonAttributeChange}
                  handleModal={this.handleModal}
                  isL3={isL3}
                  language={LANGUAGE.ARABIC}
                />
              </div>
            </div>
            <Button
              customClass={classnames({ "btn-disabled": disableSubmit })}
              disabled={disableSubmit || false}
              onClick={
                isEdit ? this.handleUpdateAttribute : this.handleSaveAttribute
              }
              name={btnName}
            />
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  arabicAttributeData: state.megaMenu.arabicAttributeData,
  countryId: state.store.countryId,
  englishAttributeData: state.megaMenu.englishAttributeData,
  storeId: state.store.storeId
});

const mapDispatchToProps = {
  clearAttributeState,
  handleAttributesChange,
  saveAttribute,
  toastMsg,
  updateAttribute
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
