import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo
} from "react";
import { connect } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import Popup from "reactjs-popup";
import cn from "classnames";
import ExpandableList from "../../component/ExpendableList";
import Page from "./page";
import EditableSpan from "../../component/EditableSpan";
import PopupContent, { UnemptyFolderPopupContent } from "./popupContent";
import {
  selectHomePage,
  selectNewAddedFolder,
  selectOpenedFolderIds
} from "../../selectors";
import {
  updateFolder,
  createNewPage,
  deletePage,
  updateOpenedFolders,
  sortPages
} from "../../actions/pagesPage.action";
import { useIsInViewport } from "../../hooks";
import { generateUUID, scrollIntoViewWithOffset } from "../../util";
import { SORT_DIRECTION, MESSAGES } from "../../constants";

import ArrowDown from "../../images/arrow-dd.svg";
import { DeleteIcon, FolderIcon, SortByAlphaIcon, AddIcon } from "../../materialUi/icons";
import { Tooltip, IconButton } from "../../materialUi/components";

const getNewPage = folder => {
  const folderTitle = folder.title.toLowerCase().split(" ").join("-");
  const pageName = `New ${folderTitle} page ${folder.pageIds.length + 1}`;
  return {
    meta: {
      english: {
        title: pageName,
        metaKeyword: pageName,
        metaDescription: pageName
      },
      arabic: {
        title: pageName,
        metaKeyword: pageName,
        metaDescription: pageName
      }
    },
    content: [],
    schedulers: [],
    variants: [],
    title: pageName,
    url: folder.scheduled
      ? `/${generateUUID()}/`
      : `/new-${folderTitle}-page-${folder.pageIds.length + 1}/`
  };
};

const PageFolder = ({
  folder,
  pages,
  // index,
  updateFolder,
  createNewPage,
  deleteFolderHandler,
  deletePage,
  container,
  openedFolderIds,
  updateOpenedFolders,
  setCurrentTab,
  newAddedFolderId,
  sortPages,
  homePage,
  accessRights
}) => {
  const [sortMode, setSortMode] = useState(SORT_DIRECTION.ASC);
  const popupRef = useRef();
  const folderRef = useRef(null);
  const isIntersecting = useIsInViewport(folderRef);

  const addPageHandler = e => {
    e.stopPropagation();
    const newPageData = getNewPage(folder);
    createNewPage(folder, newPageData);
  };
  const deletePageHandler = (e, page) => {
    e.stopPropagation();
    return deletePage(folder, page);
  };

  const isHomepageFolder = useMemo(() => folder.id === homePage.folderId, [
    homePage,
    folder
  ]);

  const folderOpened = openedFolderIds.includes(folder.id) || isHomepageFolder;

  useEffect(() => {
    if (newAddedFolderId && newAddedFolderId === folder.id && !isIntersecting) {
      scrollIntoViewWithOffset(folderRef.current);
    }
    // eslint-disable-next-line
  }, [newAddedFolderId]);

  const updateFolderName = useCallback(
    newName => {
      if (folder.title.trim() === newName.trim()) return;
      updateFolder(container, folder, newName);
    },
    // eslint-disable-next-line
    [container, folder]
  );
  return (
    <li
      className="folder"
    >
      <ExpandableList
        opened={folderOpened}
        onClick={() => {
          updateOpenedFolders({ folder });
        }}
        header={() => (
          <div
            className={cn("folder-header", {
              added: folder.id === newAddedFolderId,
              "homepage-folder": isHomepageFolder
            })}
            ref={folderRef}
          >
            <div className="folder-title">
              <FolderIcon sx={{ color: "white" }} />
              <EditableSpan
                className="folder-name"
                onEditingEnd={updateFolderName}
                inputProps={{ maxLength: 30, disabled: !accessRights.UPDATE }}
              >
                {folder.title}
              </EditableSpan>
            </div>

            <div className="folder-icons-container">
              <img
                src={ArrowDown}
                className={cn("folder-arrow-down", {
                  opened: folderOpened
                })}
                alt="arrow down"
              />
              <Popup
                ref={popupRef}
                trigger={
                  <Tooltip title={accessRights.DELETE ? "Delete Folder" : MESSAGES.TOOLTIP_DELETE} placement="top" arrow>
                    <span>
                      <IconButton
                        disabled={!accessRights.DELETE}
                      >
                        <DeleteIcon sx={{ color: "white", opacity: accessRights.DELETE ? 1 : 0.3 }} fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                }
                position="right top"
                disabled={!accessRights.DELETE}
              >
                {
                  folder.pageIds.length ? (
                    <UnemptyFolderPopupContent
                      onConfirm={() => popupRef.current.close()}
                    />
                  ) : (
                    <PopupContent
                      onConfirm={e => deleteFolderHandler(e, folder)}
                      onCancel={() => popupRef.current.close()}
                    />
                  )
                }
              </Popup>
              <Tooltip title="Sort Pages" placement="top" arrow>
                <span>
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      const sortDirection =
                        sortMode === SORT_DIRECTION.ASC
                          ? SORT_DIRECTION.DESC
                          : SORT_DIRECTION.ASC;
                      sortPages(folder, sortDirection);
                      setSortMode(sortDirection);
                    }}
                  >
                    <SortByAlphaIcon sx={{ color: "white" }} fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={accessRights.WRITE ? "Create Page" : MESSAGES.TOOLTIP_WRITE} placement="top" arrow>
                <span>
                  <IconButton
                    disabled={!accessRights.WRITE}
                    onClick={addPageHandler}
                  >
                    <AddIcon sx={{ color: "white", opacity: accessRights.WRITE ? 1 : 0.3 }} fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
        )}
        list={() => (
          <Droppable droppableId={`droppable-${folder.id}`}>
            {providedDroppable => (
              <ul
                className="pages-list"
                {...providedDroppable.droppableProps}
                ref={providedDroppable.innerRef}
              >
                {folder.pageIds.map((pageId, index) => {
                  const page = pages[pageId];
                  return (
                    <Page
                      key={page.id}
                      page={page}
                      index={index}
                      deletePageHandler={deletePageHandler}
                      {...(page.schedulers && {
                        schedulerCount: page.schedulers.length
                      })}
                      setCurrentTab={setCurrentTab}
                      accessRights={accessRights}
                    />
                  );
                })}
                {providedDroppable.placeholder}
              </ul>
            )}
          </Droppable>
        )}
      />
    </li>
  );
};

const mapStateToProps = state => ({
  openedFolderIds: selectOpenedFolderIds(state),
  newAddedFolderId: selectNewAddedFolder(state),
  homePage: selectHomePage(state)
});

const mapDispatchToProps = {
  updateFolder,
  createNewPage,
  deletePage,
  updateOpenedFolders,
  sortPages
};

export default connect(mapStateToProps, mapDispatchToProps)(PageFolder);
