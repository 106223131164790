import {
  UPDATE_DND_TREE_DATA,
  CLEAR_SORTABLE_TREE_DATA,
  UPDATE_CONTENT_ON_TREE_DATA_CHANGE,
  DELETE_DATA_DND_TREE
} from "../constants/actions";

export const updateTreeDataValue = treeData => ({
  type: UPDATE_DND_TREE_DATA,
  treeData
});

export const deleteAttribute = (selectedAttribData, columnName) => ({
  type: DELETE_DATA_DND_TREE,
  selectedAttribData,
  columnName
});

export const updateContentOnTreeChange = (treeData, columnName) => ({
  type: UPDATE_CONTENT_ON_TREE_DATA_CHANGE,
  treeData,
  columnName
});

export const clearTreeDataValue = () => ({ type: CLEAR_SORTABLE_TREE_DATA });
