import React, { Component, Fragment } from "react";
import SearchBrands from "./searchDropDown";
import Button from "../../component/button";

class SearchContainer extends Component {
  UNSAFE_componentWillMount() {
    this.props.changeSelectedBrand({});
  }

  handleSave = () => {
    const { changePageType } = this.props;
    changePageType(3);
  };

  render() {
    const {
      changeSelectedBrand,
      selectedBrand,
      brandsList,
      fetchBrandsData
    } = this.props;
    return (
      <Fragment>
        <div className="wrapper-box">
          <div className="data-wrapper">
            <div className="english-wrapper">
              <div className="english-content">
                <SearchBrands
                  isMulti={false}
                  title={"Select Brand"}
                  placeHolder={"Please Select Brand"}
                  searchList={brandsList}
                  changeSelectedData={changeSelectedBrand}
                  selectedData={
                    selectedBrand &&
                    (selectedBrand.brandname || selectedBrand.brandName)
                      ? {
                          label:
                            selectedBrand.brandname || selectedBrand.brandName,
                          value: selectedBrand.id
                        }
                      : ""
                  }
                  onTextChange={fetchBrandsData}
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          name={"Continue"}
          onClick={
            selectedBrand && !!Object.keys(selectedBrand).length
              ? this.handleSave
              : null
          }
          disabled={false}
          customClass={
            selectedBrand && Object.keys(selectedBrand).length
              ? ""
              : "opacity disable-btn"
          }
        />
      </Fragment>
    );
  }
}

export default SearchContainer;
