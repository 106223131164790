import { SELECT_COUNTRY_ID, RESET_COUNTRY_ID } from "../constants/actions";

export const selectCountryId = countryId => ({
  type: SELECT_COUNTRY_ID,
  countryId
});

export const resetCountryId = () => (dispatch, getState) => {
  const {
    store: { countryId }
  } = getState();
  dispatch({
    type: RESET_COUNTRY_ID,
    countryId
  });
};
