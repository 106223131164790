import {
  fetchWebApi,
  putDashboardWebApi,
  deleteWebApi
} from "../webapis/apiResource";
import { ENV_CONFIG } from "../config/env";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  toastMsg
} from "../actions/common.action";
import {
  UPDATE_SIZE_CHART_DATA,
  ENABLE_SIZE_CHART_SUBMIT_BUTTON,
  DISABLE_SIZE_CHART_SUBMIT_BUTTON,
  TOGGLE_SIZE_CHART_RESET_BUTTON,
  CHANGE_SIZE_CHART_CATEGORY,
  CHANGE_SIZE_CHART_NAV,
  CHANGE_SELECTED_BRAND,
  CHANGE_SELECTED_GENERIC_CATEGORY,
  FETCH_BRANDS_LIST,
  FETCH_SUB_CATEGORY_LIST,
  HANDLE_SIZE_CHART_INPUT_TEXT_CHANGE,
  CHANGE_SELECTED_SUB_CATEGORY,
  CHANGE_CK_EDITOR_TEXT,
  UPDATE_SUB_CATEGORY_DATA,
  CLEAR_SUB_CATEGORY_DATA,
  HANDLE_GENERAL_CHANGE,
  HANDLE_GENERIC_SIZE_CHART_INPUT_TEXT_CHANGE,
  HANDLE_GENERIC_SIZE_CHART_CHANGE,
  HANDLE_SELECTED_GENERIC_SUB_CATEGORY_CATEGORIES_CHANGE,
  RESET_SIZE_CHART_GENERIC_CONTENT,
  CHANGE_SELECTED_GENERIC_SUB_CATEGORY,
  HANDLE_RESET,
  CLEAR_SELECTED_GENERIC_SUB_CATEGORY,
  HANDLE_SELECTED_GENERIC_SUB_CATEGORY_INPUT_TEXT_CHANGE
} from "../constants/actions";

export const enableSubmit = () => ({ type: ENABLE_SIZE_CHART_SUBMIT_BUTTON });

export const disableSubmit = () => ({ type: DISABLE_SIZE_CHART_SUBMIT_BUTTON });

export const toggleReset = value => ({
  type: TOGGLE_SIZE_CHART_RESET_BUTTON,
  value
});

export const updateSizeChartData = ({ result }) => ({
  type: UPDATE_SIZE_CHART_DATA,
  result
});

export const handleReset = () => ({ type: HANDLE_RESET });

export const fetchBrandsList = list => ({ type: FETCH_BRANDS_LIST, list });

export const fetchSubCategoryList = list => ({
  type: FETCH_SUB_CATEGORY_LIST,
  list
});

export const changeSizeChartCategory = (key, category) => ({
  type: CHANGE_SIZE_CHART_CATEGORY,
  key,
  category
});

export const changeSizeChartNav = category => ({
  type: CHANGE_SIZE_CHART_NAV,
  category
});

export const changeSelectedBrand = data => ({
  type: CHANGE_SELECTED_BRAND,
  data
});

export const changeSelectedSubCategory = data => ({
  type: CHANGE_SELECTED_SUB_CATEGORY,
  data
});

export const changeHandler = (key, value) => ({
  type: CHANGE_CK_EDITOR_TEXT,
  key,
  value
});

export const updateSubCategoryData = data => ({
  type: UPDATE_SUB_CATEGORY_DATA,
  data
});

export const clearSubCategoryData = () => ({ type: CLEAR_SUB_CATEGORY_DATA });

export const handleGeneralChange = (name, value, language, keyType) => ({
  type: HANDLE_GENERAL_CHANGE,
  name,
  value,
  language,
  keyType
});

export const handleInputTextChange = (
  containerName,
  category,
  language,
  key,
  value
) => ({
  type: HANDLE_SIZE_CHART_INPUT_TEXT_CHANGE,
  containerName,
  category,
  language,
  key,
  value
});

export const fetchSizeChartData = countryId => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content?countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(changeSelectedGenericCategory(-1));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateToggleGenericSizeChartData = (id, payload) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/generic/toggle/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(changeSelectedGenericCategory(-1));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchBrandsData = (
  searchText,
  limit = 300,
  skip = 0,
  sort = 1
) => {
  let apiURL = `${ENV_CONFIG.commonBaseURL}${
    ENV_CONFIG.versionInfo
  }/common/brands?search=${
    searchText || ""
  }&limit=${limit}&sort=${sort}&skip=${skip}`;
  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data) {
          dispatch(fetchBrandsList(response.data));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchSubCategoryData = (
  searchText,
  limit = 300,
  skip = 0,
  sort = 1
) => {
  let apiURL = `${ENV_CONFIG.commonBaseURL}${
    ENV_CONFIG.versionInfo
  }/common/subCategories?search=${
    searchText || ""
  }&limit=${limit}&sort=${sort}&skip=${skip}`;
  return (dispatch, getState) => {
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data) {
          dispatch(fetchSubCategoryList(response.data));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateSizeChartBrandContentApi = bodyData => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/brand`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(
            toastMsg(
              "success",
              "Size chart brand content updated successfully."
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const submitSubCategoryList = (id, bodyData) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/brand/sub-category/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(
            toastMsg("success", "Sub category has been updated successfully.")
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const submitGenericSubCategoryList = (id, bodyData) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/generic/sub-category/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(
            toastMsg("success", "Sub category has been updated successfully.")
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateSizeChartGenericContentApi = bodyData => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/generic`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const handleBrandToggle = (id, bodyData) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/brand/toggle/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(toastMsg("success", "Record updated successfully."));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const handleBrandDelete = bodyData => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/brand/${bodyData.id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(
            toastMsg("success", "Size chart brand deleted successfully.")
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const handleBrandSubCategoryDelete = bodyData => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/brand/sub-category/${bodyData.id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(
            toastMsg("success", "Size chart sub category deleted successfully.")
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(
          toastMsg(
            "error",
            "Something went wrong while deleting the record. Please try again later."
          )
        );
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const handleGenericSubCategoryDelete = (id, bodyData) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/generic/sub-category/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(
            toastMsg("success", "Size chart sub category deleted successfully.")
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(
          toastMsg(
            "error",
            "Something went wrong while deleting the record. Please try again later."
          )
        );
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const handleGeneralSave = (id, countryId, bodyData) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/general?id=${id}&countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(
            toastMsg(
              "success",
              "Size chart general details saved successfully."
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(
          toastMsg(
            "error",
            "Something went wrong while saving general details. Please try again later."
          )
        );
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const handleMetaDataSave = (id, countryId, bodyData) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/meta?id=${id}&countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(
            toastMsg("success", "Size chart seo details saved successfully.")
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(
          toastMsg(
            "error",
            "Something went wrong while saving seo details. Please try again later."
          )
        );
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const changeSelectedGenericCategory = categoryIndex => ({
  type: CHANGE_SELECTED_GENERIC_CATEGORY,
  categoryIndex
});

export const textChangeHandlerGenericSizeChart = (
  categoryKey,
  index,
  key,
  value,
  language
) => ({
  type: HANDLE_GENERIC_SIZE_CHART_INPUT_TEXT_CHANGE,
  categoryKey,
  index,
  key,
  value,
  language
});

export const changeHandlerGenericSizeChart = (
  categoryKey,
  index,
  key,
  value
) => ({
  type: HANDLE_GENERIC_SIZE_CHART_CHANGE,
  categoryKey,
  index,
  key,
  value
});

export const resetSizeChartGenericContent = (categoryKey, index) => ({
  type: RESET_SIZE_CHART_GENERIC_CONTENT,
  categoryKey,
  index
});

export const changeSelectedGenericSubCategory = subCategory => ({
  type: CHANGE_SELECTED_GENERIC_SUB_CATEGORY,
  subCategory
});

export const clearSelectedGenericSubCategory = () => ({
  type: CLEAR_SELECTED_GENERIC_SUB_CATEGORY
});

export const textChangeHandlerSelectedGenericSubCategory = (
  key,
  value,
  language
) => ({
  type: HANDLE_SELECTED_GENERIC_SUB_CATEGORY_INPUT_TEXT_CHANGE,
  key,
  value,
  language
});

export const categoriesChangeHandlerSelectedGenericSubCategory = value => ({
  type: HANDLE_SELECTED_GENERIC_SUB_CATEGORY_CATEGORIES_CHANGE,
  value
});

export const updateGenericSizeChartListOrder = genericSizeChartList => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/size-chart/content/generic/sub-category-list-order`;
  return (dispatch, getState) => {
    const {
      sizeChart: {
        selectedTab,
        selectedGenericCategoryIndex,
        apiContent: { _id }
      }
    } = getState();
    const bodyData = {
      genericSizeChartList,
      id: _id,
      index: selectedGenericCategoryIndex,
      type: selectedTab
    };
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(getAccessToken(getState), apiURL, bodyData)
      .then(response => {
        if (response.data) {
          dispatch(updateSizeChartData(response.data.data));
          dispatch(
            toastMsg(
              "success",
              "Sub category list order has been updated successfully."
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        dispatch(
          toastMsg(
            "error",
            "Something went wrong while updating size chart generic list order. Please try again later."
          )
        );
        throw new Error(error.response);
      });
  };
};
