import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { isEqual, get, cloneDeep } from "lodash";
import {
  Box, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Button,
  TextField, IconButton, MenuItem,
  BasicPopover, TableContainer, Paper, Table, TableHead, TableBody, TableCell, TableRow, Tooltip
} from '../../materialUi/components';
import { DeleteIcon } from "../../materialUi/icons"
import { externalLinkList, EXTERNAL_LINK_VALUE } from "../../constants";
import { snackbar } from "../../actions/common.action";


const AddExternalLink = ({ open, handleClose, handleSave, title, dialogContext, data }) => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState(data);
  const [selectedExternalLinkType, setSelectedExternalLinkType] = useState("");
  const [updatedExternalLinks, setUpdatedExternalLinks] = useState(cloneDeep(externalLinkList));
  const [currentRowIndex, setCurrentRowIndex] = useState(-1);
  const [anchorElement1, setAnchorElement1] = useState(null);

  const validateForm = () => {
    if (modalData?.externalLinks?.length) {
      for (const externalLink of modalData.externalLinks) {
        if (externalLink.type === EXTERNAL_LINK_VALUE.CSS &&
          !(externalLink.URL.endsWith('.css') || externalLink.URL.endsWith('.scss'))) {
          dispatch(snackbar({ open: true, severity: "error", text: "Invalid External link for CSS.Only .css and .scss extensions are accepted" }))
          return false
        }
        if (!externalLink.URL.startsWith("https://")) {
          dispatch(snackbar({ open: true, severity: "error", text: "URL should start with https://" }))
          return false
        }
      }
    }
    return true

  }

  const handleSaveClick = () => {
    const data = cloneDeep(modalData);
    if (data?.externalLinks?.length) {
      data.externalLinks.forEach(link => {
        if (link.URL) {
          link.URL = `${link.URL}?burst=${Date.now()}`;
        }
      });
    }
    handleSave(data)
  }

  const handleOnClickAddButton = () => {
    const externalLinks = [...(modalData.externalLinks || []), { type: selectedExternalLinkType, URL: "" }]
    setModalData({ ...modalData, externalLinks });
    setSelectedExternalLinkType("");
  }

  const handleUpdateValue = (index, key, value) => {
    const updatedExternalLinks = cloneDeep(modalData.externalLinks);
    updatedExternalLinks[index][key] = value;
    setModalData({ ...modalData, externalLinks: updatedExternalLinks })
  }

  const handleDeleteRow = (index) => {
    const updatedExternalLinks = cloneDeep(modalData.externalLinks);
    updatedExternalLinks.splice(index, 1);
    setModalData({ ...modalData, externalLinks: updatedExternalLinks })
  }

  const getUpdatedURL = (URL) => {
    const index = URL.indexOf('?burst');
    return index === -1 ? URL : URL.substring(0, index);
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box sx={{ "width": "100%" }}>
              {Boolean(anchorElement1) &&
                <BasicPopover open={Boolean(anchorElement1)}
                  id={currentRowIndex}
                  anchorEl={anchorElement1}
                  handleCancel={() => {
                    setAnchorElement1(null);
                    setCurrentRowIndex(-1);
                  }}
                  handleSave={() => {
                    handleDeleteRow(currentRowIndex);
                    setAnchorElement1(null);
                    setCurrentRowIndex(-1);
                  }}
                  text={"Are you sure you want to delete the row?"}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                />}
              <Box sx={{ "display": "flex" }}>
                <TextField
                  select
                  label="Select Extrenal Link Type"
                  onChange={(event) => {
                    setSelectedExternalLinkType(event.target.value)
                  }}
                  placeholder="Please Select External Link Type"
                  size="small"
                  sx={{ width: "20%" }}
                  value={selectedExternalLinkType || ""}
                  InputLabelProps={{ shrink: true }}
                >
                  {updatedExternalLinks.map((option) =>
                    <MenuItem key={option.value} value={option.value} disabled={option.disabled || false}>
                      {option.label}
                    </MenuItem>
                  )}
                </TextField>
                <Button size="small"
                  onClick={handleOnClickAddButton}
                  disabled={!selectedExternalLinkType}>
                  Add
                </Button>
              </Box>
              <Box sx={{ width: '100%', overflow: 'hidden', "marginTop": "42px" }}>
                <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
                  <Table aria-label="a dense table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          key={"key"}
                          align={"center"}
                          style={{ backgroundColor: "#f1f1f1" }}
                        >
                          {"Key"}
                        </TableCell>
                        <TableCell
                          key={"value"}
                          align={"center"}
                          style={{ backgroundColor: "#f1f1f1" }}
                        >
                          {"Value"}
                        </TableCell>
                        <TableCell
                          key={"action"}
                          align={"center"}
                          style={{ backgroundColor: "#f1f1f1" }}
                        >
                          {"Action"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!modalData.externalLinks && modalData.externalLinks.map(({ type, URL }, index) => (
                        <TableRow hover tabIndex={-1} key={index} style={{ "maxHeight": "15px" }}
                        >
                          <TableCell key={"key"} align={"left"} width={"30%"}>
                            <TextField
                              value={type}
                              style={{ width: "100%" }}
                              size="small"
                              disabled
                            />
                          </TableCell>
                          <TableCell key={"value"} align={"left"} width={"60%"}>
                            <TextField
                              onChange={event =>
                                handleUpdateValue(
                                  index,
                                  "URL",
                                  get(event, "target.value", "")
                                )
                              }
                              value={getUpdatedURL(URL)}
                              style={{ width: "100%" }}
                              size="small"
                            />
                          </TableCell>
                          <TableCell key={"action"} align={"center"} width={"10%"}>
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton aria-label="delete"
                                onClick={(event) => {
                                  setAnchorElement1(event.currentTarget)
                                  setCurrentRowIndex(index)
                                }}
                                size="small"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => validateForm() && handleSaveClick()} disabled={isEqual(data, modalData)}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddExternalLink