import React, { Component } from "react";
import Content from "./content";

class RightPanel extends Component {
  render() {
    const {
      currentTab,
      updatePageContent,
      updatePageIndex,
      changeTab,
      updateContent,
      resetData,
      accessRights
    } = this.props;
    return (
      <div className="right-wrapper">
        <Content
          languageType={"english"}
          title={"English"}
          currentTab={currentTab}
          updatePageContent={updatePageContent || ""}
          updatePageIndex={updatePageIndex}
          changeTab={changeTab}
          updateContent={updateContent}
          resetData={resetData}
          accessRights={accessRights}
        />
      </div>
    );
  }
}

export default RightPanel;
