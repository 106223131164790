import { generateUUID } from "../../util";

export const initialBannerSection = () => ({
  defaultTemplate: {
    english: "",
    arabic: ""
  },
  schedulers: [],
  enable: false,
  type: "banner",
  id: generateUUID(),
  openAccordion: true
});
