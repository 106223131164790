import React, { useEffect, useState, useCallback } from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import reduce from "lodash/reduce";
import includes from "lodash/includes";
import { useSelector, useDispatch } from "react-redux";
import { DropDownList } from "../applyDropDown/dropDownList";
import { selectStoreId } from "../../actions/store.action";
import { STORES } from "../../constants";

export const StoresDropdown = () => {
  const dispatch = useDispatch();
  const storeData = useSelector(state => state.store);
  const selectedStoreIds = useSelector(state => state.selectedStoreIds);
  const [stores, setStores] = useState([]);
  const [isAllStoresSelected, setIsAllStoresSelected] = useState(false);
  const { storeId, storeList } = storeData;
  const selectedStores = reduce(
    stores,
    (acc, store) => {
      if (store.isChecked) {
        acc += `${acc ? ", " : ""}${store.storeName}`;
      }
      return acc;
    },
    ""
  );

  const handleInitStoreList = useCallback(() => {
    const newStoreList = map(storeList, store => {
      const isStoreSelected = parseInt(storeId) === parseInt(store.storeId);
      if (
        store.storeName === STORES.MUMUSO ||
        store.storeName === STORES.MUY_MUCHO
      ) {
        return { ...store, isDisabled: true, isChecked: isStoreSelected };
      } else if (isStoreSelected) {
        return {
          ...store,
          isChecked: true,
          isDisabled: true
        };
      } else {
        return { ...store, isChecked: false };
      }
    });
    setStores(newStoreList);
    dispatch(selectStoreId([storeId]));
  }, [dispatch, storeId, storeList]);

  useEffect(() => {
    handleInitStoreList();
  }, [storeData, handleInitStoreList]);

  useEffect(() => {
    setStores(stores =>
      stores.map(store => ({
        ...store,
        isChecked: includes(selectedStoreIds, store.storeId)
      }))
    );
  }, [selectedStoreIds]);

  const handleSelectStore = (storeItem, isAllStores) => {
    let updatedStoreList = [];
    setIsAllStoresSelected(isAllStores);
    if (isAllStores) {
      updatedStoreList = stores.map(store => {
        if (!store.isDisabled) {
          return { ...store, isChecked: true };
        } else {
          return { ...store };
        }
      });
    } else {
      if (storeItem && storeItem.storeId) {
        updatedStoreList = stores.map(store => {
          if (store.storeId === storeItem.storeId && !storeItem.isDisabled) {
            return { ...store, isChecked: !store.isChecked };
          } else {
            return { ...store };
          }
        });
      } else {
        updatedStoreList = stores.map(store => {
          if (!store.isDisabled) {
            return { ...store, isChecked: !store.isChecked };
          } else {
            return { ...store };
          }
        });
      }
    }
    const updateStores = updatedStoreList
      .filter(store => store.isChecked)
      .map(store => store.storeId);

    setStores(updatedStoreList);
    dispatch(selectStoreId(updateStores));
  };

  return (
    !isEmpty(stores) && (
      <DropDownList
        listData={stores}
        handleSelect={handleSelectStore}
        isAll={isAllStoresSelected}
        selectedName={selectedStores}
        nameKey="storeName"
        idKey="storeId"
      />
    )
  );
};
