import React, { useState } from "react";
import map from "lodash/map";
import { MEGA_MENU_COLUMN_KEYS } from "../../constants";
import Button from "../../component/button";
import ConfirmModal from "../modal/ConfirmModal";

export const CopyColumnContent = ({
  listData,
  currentColumnName,
  handleSubmit
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedCopyColumn, setSelectedCopyColumn] = useState(null);

  const handleDropdownItemClick = selectedItem => {
    setConfirmModal(true);
    setSelectedCopyColumn(selectedItem);
  };

  const confirmSubmit = () => {
    handleSubmit(selectedCopyColumn);
    setConfirmModal(false);
    setShowDropdown(false);
  };

  return (
    <div className="copy-column-wrapper">
      {confirmModal && (
        <ConfirmModal
          className="confirm-copying"
          modalName={{}}
          onHide={() => setConfirmModal(false)}
          confirm={confirmSubmit}
          message={`Data from ${MEGA_MENU_COLUMN_KEYS[currentColumnName]} will be deleted and appear in ${MEGA_MENU_COLUMN_KEYS[selectedCopyColumn]}. Do you want to continue?`}
        />
      )}
      <Button name="Copy To" onClick={() => setShowDropdown(!showDropdown)} />
      {showDropdown && (
        <div className="dropdown-content">
          {map(
            listData,
            ({ name, key }) =>
              key !== currentColumnName && (
                <span
                  onClick={() => handleDropdownItemClick(key)}
                  className="dropdown-item"
                  key={key}
                >
                  <p>{name}</p>
                </span>
              )
          )}
        </div>
      )}
    </div>
  );
};
