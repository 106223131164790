import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Button, Typography,
  TextField, CustomizedMenus, IconButton, MenuItem, Divider,
  BasicPopover, FormControlLabel, Checkbox
} from '../../../materialUi/components';
import { imageMenuButtonOptions, fontStyleList, textAlignList, menuStyleList, ColumnMenuTypeList, ColumnMenuTypeValue, customColumnModuleNames, ColumnMenuTypeOptions } from "../../../constants";
import { VisibilityIcon, DeleteIcon } from "../../../materialUi/icons";
import { generateUUID } from "../../../util";
import SpacingControls from "../SpacingControlsMUI";
import SvgImageDialog from "./SvgImageDialog";
import { PreviewImageModal } from "../ItemModal/PreviewImageModal";
import { ColorPicker } from "../../ColorPicker";
import { snackbar } from "../../../actions/common.action";



const AddMenuDialog = ({ open, handleClose, handleSave, title, dialogContext, data, deviceType }) => {
  const IMAGEMODAL = "IMAGEMODAL";
  const PREVIEWIMAGEMODAL = "PREVIEWIMAGEMODAL";
  const EN = "english";
  const AR = "arabic";
  const dispatch = useDispatch();

  const [menuData, setMenuData] = useState(data);
  const [previewImageURL, setPreviewImageURL] = useState(null);
  const [imageModalDetails, setImageModalDetails] = useState({});
  const [enDisplayColorPicker, setEnDisplayColorPicker] = useState(false);
  const [arDisplayColorPicker, setArDisplayColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [showModal, setShowModal] = useState({
    [IMAGEMODAL]: false,
    [PREVIEWIMAGEMODAL]: false,
  });

  const handleMenuDataChange = (key, value, language) => {
    setMenuData({
      ...menuData,
      ...(language ? { [language]: { ...menuData[language], [key]: value } } : { [key]: value })
    })
  }

  const getMenuRows = (imageURL) => {
    return imageMenuButtonOptions.map((menu) => {
      let disabled = false;
      if (imageURL && menu.value !== "edit") {
        disabled = true;
      } else if (!imageURL && menu.value === "edit") {
        disabled = true;
      }
      return { ...menu, disabled }
    })
  }

  const handleModal = (modalName, value) => {
    setShowModal({ [modalName]: value });
  }

  const onConfirmImageModal = ({ imageURL, ratio, width }, { key, lang }) => {
    handleMenuDataChange(key, { imageURL, ratio, width }, lang)
  }

  const handlePickerClick = (language) => {
    if (language === EN) {
      setEnDisplayColorPicker(prevState => !prevState);

    } else {
      setArDisplayColorPicker(prevState => !prevState);
    }
  }


  const validateForm = () => {

    if (!(menuData?.[EN]?.menuType)) {
      dispatch(snackbar({ open: true, severity: "error", text: "English Menu Type Required" }))
      return false
    }
    if (!(menuData?.[AR]?.menuType)) {
      dispatch(snackbar({ open: true, severity: "error", text: "Arabic Menu Type Required" }))
      return false
    }
    if (menuData[EN].menuType === ColumnMenuTypeValue.CUSTOM &&
      !(menuData[EN].moduleType)) {
      dispatch(snackbar({ open: true, severity: "error", text: "English Module Type Required" }))
      return false
    }
    if (menuData[AR].menuType === ColumnMenuTypeValue.CUSTOM &&
      !(menuData[AR].moduleType)) {
      dispatch(snackbar({ open: true, severity: "error", text: "Arabic Module Type Required" }))
      return false
    }

    if (menuData[EN].menuType === ColumnMenuTypeValue.TEXT) {
      if (!(menuData?.[EN]?.subTitle)) {
        dispatch(snackbar({ open: true, severity: "error", text: "English Subtitle Required" }))
        return false
      }

      if (menuData?.[EN]?.redirectionUrl &&
        !menuData[EN].redirectionUrl.startsWith('/')) {
        dispatch(snackbar({ open: true, severity: "error", text: "English RedirectionalURL should starts with /" }))
        return false
      }

      if (menuData?.[EN]?.redirectionUrl &&
        !menuData[EN].redirectionUrl.endsWith('/')) {
        dispatch(snackbar({ open: true, severity: "error", text: "English RedirectionalURL should ends with /" }))
        return false
      }

      if (!(menuData?.[EN]?.fontStyle)) {
        dispatch(snackbar({ open: true, severity: "error", text: "English Font Style Required" }))
        return false
      }

      if (!(menuData?.[EN]?.textAlign)) {
        dispatch(snackbar({ open: true, severity: "error", text: "English Text Alignment Required" }))
        return false
      }

      if (!(menuData?.[EN]?.menuStyle)) {
        dispatch(snackbar({ open: true, severity: "error", text: "English Menu Style Required" }))
        return false
      }


    }
    if (menuData[AR].menuType === ColumnMenuTypeValue.TEXT) {

      if (!(menuData?.[AR]?.subTitle)) {
        dispatch(snackbar({ open: true, severity: "error", text: "Arabic Subtitle Required" }))
        return false
      }

      if (menuData?.[AR]?.redirectionUrl &&
        !menuData[AR].redirectionUrl.startsWith('/')) {
        dispatch(snackbar({ open: true, severity: "error", text: "Arabic RedirectionalURL should starts with /" }))
        return false
      }

      if (menuData?.[AR]?.redirectionUrl &&
        !menuData[AR].redirectionUrl.endsWith('/')) {
        dispatch(snackbar({ open: true, severity: "error", text: "Arabic RedirectionalURL should ends with /" }))
        return false
      }

      if (!(menuData?.[AR]?.fontStyle)) {
        dispatch(snackbar({ open: true, severity: "error", text: "Arabic Font Style Required" }))
        return false
      }

      if (!(menuData?.[AR]?.textAlign)) {
        dispatch(snackbar({ open: true, severity: "error", text: "Arabic Text Alignment Required" }))
        return false
      }

      if (!(menuData?.[AR]?.menuStyle)) {
        dispatch(snackbar({ open: true, severity: "error", text: "Arabic Menu Style Required" }))
        return false
      }

    }


    return true

  }

  const handleSaveClick = () => {
    const data = { ...menuData };
    if (!data.id) {
      data.id = generateUUID();
    }
    data.expanded = true;
    const en_subTitle = data?.[EN]?.subTitle || "";
    const ar_subTitle = data?.[AR]?.subTitle || "";

    data.title = `${en_subTitle}/${ar_subTitle}`
    handleSave(data)
  }
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>
          <Box>
            {title}
            <FormControlLabel
              control={
                <Checkbox
                  key={"authorization"}
                  name={"authorization"}

                  checked={menuData?.authorization || false}
                  onChange={event =>
                    handleMenuDataChange(event.target.name, event.target.checked || false)
                  }
                />
              }
              sx={{ float: "right" }}
              label={"Authorization"} />
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {dialogContext}
          </DialogContentText>
          <Box sx={{ display: "flex", width: "100%" }}>
            {showModal[IMAGEMODAL] &&
              <SvgImageDialog
                handleSave={(data) => {
                  onConfirmImageModal(data, { ...imageModalDetails })
                  handleModal(IMAGEMODAL, false)
                  setImageModalDetails({})
                }}
                data={{
                  imageURL: menuData?.[imageModalDetails.lang]?.[imageModalDetails.key]?.imageURL || "",
                  ratio: menuData?.[imageModalDetails.lang]?.[imageModalDetails.key]?.ratio,
                  width: menuData?.[imageModalDetails.lang]?.[imageModalDetails.key]?.width
                }}
                handleClose={() => {
                  handleModal(IMAGEMODAL, false)
                  setImageModalDetails({})
                }}
                open={true}
                title={"Image Configuration"}
                dialogContext={"Please Configure Image Details."}
                language={imageModalDetails.lang === EN ? "english" : "arabic"}
                deviceTab={deviceType}
              />
            }
            {showModal[PREVIEWIMAGEMODAL] &&
              <PreviewImageModal
                imageURL={previewImageURL}
                handleClose={() => {
                  handleModal(PREVIEWIMAGEMODAL, false)
                  setPreviewImageURL(null)
                }}
                dialogContext={"Please Preview the below Image"}
                open={true}
                title={"Image Preview"}
              />
            }
            {Boolean(anchorEl) &&
              <BasicPopover open={Boolean(anchorEl)} id={"Add_menu_image"} anchorEl={anchorEl}
                handleCancel={() => {
                  setAnchorEl(null);
                  setImageModalDetails({})
                }}
                handleSave={() => {
                  handleMenuDataChange(imageModalDetails.key, {}, imageModalDetails.lang)
                  setAnchorEl(null);
                  setImageModalDetails({})
                }}
                text={"Are you sure you want to delete the Image?"}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              />}
            <Box sx={{ width: "48%", marginRight: "10px" }}>
              <Typography>
                English Version
              </Typography>
              <Box sx={{ display: "flex", marginTop: "20px" }}>
                <TextField
                  select
                  label="Select Menu Type"
                  onChange={(ev) => {
                    handleMenuDataChange("menuType", ev.target.value || "", EN)
                  }}
                  placeholder="Please Select Menu Type"
                  size="small"
                  sx={{ width: "30%" }}
                  value={menuData?.[EN]?.menuType || ""}
                  InputLabelProps={{ shrink: true }}
                >
                  {ColumnMenuTypeList.map((option) =>
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )}
                </TextField>
                {
                  menuData?.[EN]?.menuType === ColumnMenuTypeValue.CUSTOM && (
                    <TextField
                      select
                      label="Select Module Type"
                      onChange={(ev) => {
                        handleMenuDataChange("moduleType", ev.target.value || "", EN)
                      }}
                      placeholder="Please Select Module Type"
                      size="small"
                      sx={{ marginLeft: "10px", width: "30%" }}
                      value={menuData?.[EN]?.moduleType || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {customColumnModuleNames.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                  )
                }
              </Box>
              {(menuData?.[EN]?.menuType === ColumnMenuTypeOptions.TEXT) && (
                <>
                  <TextField
                    autoFocus
                    id="subTitle"
                    label="Sub Title"
                    type="text"
                    value={menuData?.[EN]?.subTitle || ""}
                    onChange={ev => {
                      handleMenuDataChange("subTitle", ev.target.value || "", EN)
                    }
                    }
                    style={{ width: "100%", "marginTop": "20px" }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please Enter Sub Title"
                  />
                  <TextField
                    autoFocus
                    id="redirectionUrl"
                    label="Redirection Url"
                    type="text"
                    value={menuData?.[EN]?.redirectionUrl || ""}
                    onChange={ev => {
                      handleMenuDataChange("redirectionUrl", ev.target.value || "", EN)
                    }
                    }
                    style={{ width: "100%", "marginTop": "20px" }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please Enter Redirection Url"
                  />

                  <Box sx={{ display: "flex", marginTop: "20px" }}>
                    <TextField
                      select
                      label="Select Font Style"
                      onChange={(ev) => {
                        handleMenuDataChange("fontStyle", ev.target.value || "", EN)
                      }}
                      placeholder="Please Select Font Style"
                      size="small"
                      sx={{ width: "30%" }}
                      value={menuData?.[EN]?.fontStyle || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {fontStyleList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                    <TextField
                      select
                      label="Select Text Alignment"
                      onChange={(ev) => {
                        handleMenuDataChange("textAlign", ev.target.value || "", EN)
                      }}
                      placeholder="Please Select Text Alignment"
                      size="small"
                      sx={{ width: "30%", "marginLeft": "10px" }}
                      value={menuData?.[EN]?.textAlign || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {textAlignList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                    <TextField
                      select
                      label="Select Menu Style"
                      onChange={(ev) => {
                        handleMenuDataChange("menuStyle", ev.target.value || "", EN)
                      }}
                      placeholder="Please Select Menu Style"
                      size="small"
                      sx={{ width: "30%", "marginLeft": "10px" }}
                      value={menuData?.[EN]?.menuStyle || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {menuStyleList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                  </Box>
                  <Box sx={{ marginTop: "20px" }}>
                    <SpacingControls
                      styles={
                        menuData?.[EN]?.styles || {}
                      }
                      handleSpacingChange={(val) => {
                        handleMenuDataChange("styles", val || {}, EN)
                      }}
                      CommonStyle={{ display: "flex", width: "100%" }}
                      paddingStyle={{ width: "70%" }}
                      marginStyle={{ width: "30%", marginLeft: "20px" }}
                    />
                  </Box>
                  <Box sx={{ "marginTop": "20px" }} className="mui-countdown">
                    <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Text color</span>
                    <span
                      onClick={() =>
                        handleMenuDataChange("textColor", "", EN)
                      }
                      className="countdown-delete-icon"
                    />
                    <ColorPicker
                      onClick={() => handlePickerClick(EN)}
                      background={menuData?.[EN]?.textColor || ""}
                      displayPicker={enDisplayColorPicker}
                      onChange={changedColor =>
                        handleMenuDataChange("textColor", changedColor.hex || "", EN)
                      }
                    />
                  </Box>
                </>
              )}
              <Box sx={{ "display": "flex", "marginTop": "20px", "alignItems": "center" }}>
                <Box sx={{ "display": "flex" }}>
                  <CustomizedMenus
                    menuLabel={menuData?.[EN]?.leftImage?.imageURL ? "Edit Left Image" : "Add Left Image"}
                    menuItems={getMenuRows(menuData?.[EN]?.leftImage?.imageURL || "")}
                    menuClickHandler={(menu, menuIndex) => {
                      setImageModalDetails({ key: "leftImage", lang: EN })
                      handleModal(IMAGEMODAL, true);
                    }}
                    id="config-en-media"
                    buttonStyle={{ fontSize: "0.7rem" }}
                  />
                  <IconButton
                    disabled={
                      menuData?.[EN]?.leftImage?.imageURL ? false : true
                    }
                    onClick={
                      () => {
                        setPreviewImageURL(menuData[EN].leftImage.imageURL);
                        handleModal(PREVIEWIMAGEMODAL, true);
                      }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    disabled={
                      menuData?.[EN]?.leftImage?.imageURL ? false : true
                    }
                    onClick={
                      (ev) => {
                        setImageModalDetails({ key: "leftImage", lang: EN })
                        setAnchorEl(ev.currentTarget)
                      }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Box sx={{ "display": "flex", "marginLeft": "10px" }}>
                  <CustomizedMenus
                    menuLabel={menuData?.[EN]?.rightImage?.imageURL ? "Edit Right Image" : "Add Right Image"}
                    menuItems={getMenuRows(menuData?.[EN]?.rightImage?.imageURL || "")}
                    menuClickHandler={(menu, menuIndex) => {
                      setImageModalDetails({ key: "rightImage", lang: EN })
                      handleModal(IMAGEMODAL, true);
                    }}
                    id="config-en-media"
                    buttonStyle={{ fontSize: "0.7rem" }}
                  />
                  <IconButton
                    disabled={
                      menuData?.[EN]?.rightImage?.imageURL ? false : true
                    }
                    onClick={
                      () => {
                        setPreviewImageURL(menuData[EN].rightImage.imageURL);
                        handleModal(PREVIEWIMAGEMODAL, true);
                      }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    disabled={
                      menuData?.[EN]?.rightImage?.imageURL ? false : true
                    }
                    onClick={
                      (ev) => {
                        setImageModalDetails({ key: "rightImage", lang: EN })
                        setAnchorEl(ev.currentTarget)
                      }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box sx={{ "marginLeft": "10px", "marginRight": "10px" }}>
              <Divider orientation="vertical" variant="fullWidth" />
            </Box>
            <Box sx={{ width: "48%", marginLeft: "10px" }}>
              <Typography>
                Arabic Version
              </Typography>
              <Box sx={{ display: "flex", marginTop: "20px" }}>
                <TextField
                  select
                  label="Select Menu Type"
                  onChange={(ev) => {
                    handleMenuDataChange("menuType", ev.target.value || "", AR)
                  }}
                  placeholder="Please Select Menu Type"
                  size="small"
                  sx={{ width: "30%" }}
                  value={menuData?.[AR]?.menuType || ""}
                  InputLabelProps={{ shrink: true }}
                >
                  {ColumnMenuTypeList.map((option) =>
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )}
                </TextField>
                {
                  menuData?.[AR]?.menuType === ColumnMenuTypeValue.CUSTOM && (
                    <TextField
                      select
                      label="Select Module Type"
                      onChange={(ev) => {
                        handleMenuDataChange("moduleType", ev.target.value || "", AR)
                      }}
                      placeholder="Please Select Module Type"
                      size="small"
                      sx={{ marginLeft: "10px", width: "30%" }}
                      value={menuData?.[AR]?.moduleType || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {customColumnModuleNames.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                  )
                }
              </Box>
              {(menuData?.[AR]?.menuType === ColumnMenuTypeOptions.TEXT) && (
                <>
                  <TextField
                    autoFocus
                    id="subTitle"
                    label="Sub Title"
                    type="text"
                    lang="arabic"
                    value={menuData?.[AR]?.subTitle || ""}
                    onChange={ev => {
                      handleMenuDataChange("subTitle", ev.target.value || "", AR)
                    }
                    }
                    style={{ width: "100%", "marginTop": "20px" }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please Enter Sub Title"
                    dir="rtl"
                  />
                  <TextField
                    autoFocus
                    id="redirectionUrl"
                    label="Redirection Url"
                    type="text"
                    value={menuData?.[AR]?.redirectionUrl || ""}
                    onChange={ev => {
                      handleMenuDataChange("redirectionUrl", ev.target.value || "", AR)
                    }
                    }
                    style={{ width: "100%", "marginTop": "20px" }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please Enter Redirection Url"
                  />
                  <Box sx={{ display: "flex", marginTop: "20px" }}>
                    <TextField
                      select
                      label="Select Font Style"
                      onChange={(ev) => {
                        handleMenuDataChange("fontStyle", ev.target.value || "", AR)
                      }}
                      placeholder="Please Select Font Style"
                      size="small"
                      sx={{ width: "30%" }}
                      value={menuData?.[AR]?.fontStyle || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {fontStyleList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                    <TextField
                      select
                      label="Select Text Alignment"
                      onChange={(ev) => {
                        handleMenuDataChange("textAlign", ev.target.value || "", AR)
                      }}
                      placeholder="Please Select Text Alignment"
                      size="small"
                      sx={{ width: "30%", "marginLeft": "10px" }}
                      value={menuData?.[AR]?.textAlign || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {textAlignList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                    <TextField
                      select
                      label="Select Menu Style"
                      onChange={(ev) => {
                        handleMenuDataChange("menuStyle", ev.target.value || "", AR)
                      }}
                      placeholder="Please Select Menu Style"
                      size="small"
                      sx={{ width: "30%", "marginLeft": "10px" }}
                      value={menuData?.[AR]?.menuStyle || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {menuStyleList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                  </Box>
                  <Box sx={{ marginTop: "20px" }}>
                    <SpacingControls
                      styles={
                        menuData?.[AR]?.styles || {}
                      }
                      handleSpacingChange={(val) => {
                        handleMenuDataChange("styles", val || {}, AR)
                      }}
                      CommonStyle={{ display: "flex", width: "100%" }}
                      paddingStyle={{ width: "70%" }}
                      marginStyle={{ width: "30%", marginLeft: "20px" }}
                    />
                  </Box>
                  <Box sx={{ "marginTop": "20px" }} className="mui-countdown">
                    <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Text color</span>
                    <span
                      onClick={() =>
                        handleMenuDataChange("textColor", "", AR)
                      }
                      className="countdown-delete-icon"
                    />
                    <ColorPicker
                      onClick={() => handlePickerClick(AR)}
                      background={menuData?.[AR]?.textColor || ""}
                      displayPicker={arDisplayColorPicker}
                      onChange={changedColor =>
                        handleMenuDataChange("textColor", changedColor.hex || "", AR)

                      }
                    />
                  </Box>
                </>
              )}
              <Box sx={{ "display": "flex", "marginTop": "20px", "alignItems": "center" }}>
                <Box sx={{ "display": "flex" }}>
                  <CustomizedMenus
                    menuLabel={menuData?.[AR]?.leftImage?.imageURL ? "Edit Left Image" : "Add Left Image"}
                    menuItems={getMenuRows(menuData?.[AR]?.leftImage?.imageURL || "")}
                    menuClickHandler={(menu, menuIndex) => {
                      setImageModalDetails({ key: "leftImage", lang: AR })
                      handleModal(IMAGEMODAL, true);
                    }}
                    id="config-ar-media"
                    buttonStyle={{ fontSize: "0.7rem" }}
                  />
                  <IconButton
                    disabled={
                      menuData?.[AR]?.leftImage?.imageURL ? false : true
                    }
                    onClick={
                      () => {
                        setPreviewImageURL(menuData[AR].leftImage.imageURL);
                        handleModal(PREVIEWIMAGEMODAL, true);
                      }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    disabled={
                      menuData?.[AR]?.leftImage?.imageURL ? false : true
                    }
                    onClick={
                      (ev) => {
                        setImageModalDetails({ key: "leftImage", lang: AR })
                        setAnchorEl(ev.currentTarget)
                      }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Box sx={{ "display": "flex", "marginLeft": "10px" }}>
                  <CustomizedMenus
                    menuLabel={menuData?.[AR]?.rightImage?.imageURL ? "Edit Right Image" : "Add Right Image"}
                    menuItems={getMenuRows(menuData?.[AR]?.rightImage?.imageURL || "")}
                    menuClickHandler={(menu, menuIndex) => {
                      setImageModalDetails({ key: "rightImage", lang: AR })
                      handleModal(IMAGEMODAL, true);
                    }}
                    id="config-ar-media"
                    buttonStyle={{ fontSize: "0.7rem" }}
                  />
                  <IconButton
                    disabled={
                      menuData?.[AR]?.rightImage?.imageURL ? false : true
                    }
                    onClick={
                      () => {
                        setPreviewImageURL(menuData[AR].rightImage.imageURL);
                        handleModal(PREVIEWIMAGEMODAL, true);
                      }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    disabled={
                      menuData?.[AR]?.rightImage?.imageURL ? false : true
                    }
                    onClick={
                      (ev) => {
                        setImageModalDetails({ key: "rightImage", lang: AR })
                        setAnchorEl(ev.currentTarget)
                      }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => validateForm() && handleSaveClick()}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddMenuDialog