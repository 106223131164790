import {
  FLASH_SALE_LIST,
  UPDATE_TOGGLE_FLASH_SALE_DATA,
  SET_FLASH_SALE_PAGE_CONTENT,
  SET_ACTIVE_FLASH_SALE_PAGE,
  SET_FLASH_PAGE_SELECTED_SALE_INDEX,
  UPDATE_FLASH_SALE_PAGE_CONTENT,
  UPDATE_FLASH_SALE_PAGE_NAME,
  UPDATE_FLASH_SALE_PRODUCT_LIST,
  DELETE_FLASH_SALE_PRODUCT,
} from "../constants/actions";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../constants";
import { ENV_CONFIG } from "../config/env";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
  hideCustomLoadingIndicator,
  showCustomLoadingIndicator,
  snackbar,
} from "./common.action";
import {
  deleteWebApi,
  fetchWebApi,
  postDashboardWebApi,
  putDashboardWebApi,
  patchDashboardWebApi
} from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import { delay } from "../util";

const listFlashSaleData = (data, count) => ({
  type: FLASH_SALE_LIST,
  data,
  count
});

const setInitialPageContent = data => ({
  type: SET_FLASH_SALE_PAGE_CONTENT,
  data
});

export const setSelectedSaleContentIndex = index => ({
  type: SET_FLASH_PAGE_SELECTED_SALE_INDEX,
  index
});

export const fetchFlashSaleData = (
  limit = 50,
  skip = 0,
  searchText = "",
  countryId
) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/list?limit=${limit}&skip=${skip}&search=${searchText}&countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(listFlashSaleData(response.data.data, response.data.count));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const fetchFlashSalePageContent = (id, countryId) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(setInitialPageContent(response.data.data));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const addFlashSalePage = payload => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/create`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(snackbar({ open: true, severity: "success", text: SUCCESS_MESSAGES.pageCreated }));
        } else {
          dispatch(snackbar({
            open: true,
            severity: "error",
            text: (response.data.error?.message) ||
              (response.data.error?.detail) ||
              ERROR_MESSAGES.pageCreated
          }));
        }
        return response;
      })
      .catch(error => {
        dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.pageCreated }));
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const deleteFlashSaleUrl = id => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(snackbar({ open: true, severity: "success", text: SUCCESS_MESSAGES.pageDeleted }));
        } else {
          dispatch(
            snackbar({
              open: true,
              severity: "error",
              text: (response.data.error?.message) ||
                (response.data.error?.detail) ||
                ERROR_MESSAGES.pageDeleted
            })
          );
        }
        return response;
      })
      .catch(error => {
        dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.pageDeleted }));
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

const modifyToggleData = data => ({
  type: UPDATE_TOGGLE_FLASH_SALE_DATA,
  ...data
});

export const updateToggleData = (id, payload, index) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return patchDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(modifyToggleData({ id, data: response.data.data, index }));

          dispatch(snackbar({
            open: true, severity: "success",
            text: payload.enabled === true
              ? SUCCESS_MESSAGES.urlToggleUpdated
              : SUCCESS_MESSAGES.urlToggleDisabled
          }));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error?.message) ||
              (response.data.error?.detail) ||
              ERROR_MESSAGES.pageUpdated
          }));
        }
        return response;
      })
      .catch(error => {
        dispatch(snackbar({
          open: true, severity: "error",
          text: ERROR_MESSAGES.pageUpdated
        }));
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const updatePageContent = data => ({
  type: UPDATE_FLASH_SALE_PAGE_CONTENT,
  data
});

export const fetchProductDetailsByCountryId = (countryId, productIds = []) => {
  let apiURL = `${ENV_CONFIG.productBaseURL}${ENV_CONFIG.versionInfo}/products/basic-info-list`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, productIds, {
      "x-countryid": countryId
    })
      .then(response => {
        if (
          response.status === 200 &&
          response.data?.products
        ) {
          if (response.data.products.length) {
            dispatch(snackbar({
              open: true, severity: "success",
              text: "Products Fetched"
            }));
          } else {
            dispatch(snackbar({
              open: true, severity: "error",
              text: "No Products Found"
            }));
          }
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data?.error?.detail) ||
              (response.data?.error?.message) ||
              "Error While fetching Products"
          }));
        }
        return response;
      })
      .catch(error => {
        dispatch(snackbar({
          open: true, severity: "error",
          text: "Error in fetching Products"
        }));
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const updateFlashSaleProductList = (
  data,
  pageId,
  pageSlug,
  saleSlug
) => ({
  type: UPDATE_FLASH_SALE_PRODUCT_LIST,
  data,
  payload: { pageId, pageSlug, saleSlug }
});

export const fetchProductListOfSales = (id, pageSlug, saleSlug, countryId) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/products/${pageSlug}/${saleSlug}?countryId=${countryId}`;
  return async (dispatch, getState) => {
    dispatch(showCustomLoadingIndicator());
    await delay(6000);
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(
            updateFlashSaleProductList(
              response.data.data,
              id,
              pageSlug,
              saleSlug
            )
          );
        }
        dispatch(hideCustomLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideCustomLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updatePageData = (id, payload, type) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return patchDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(setInitialPageContent(response.data.data));
          if (type !== "batch")
            dispatch(snackbar({
              open: true, severity: "success",
              text: SUCCESS_MESSAGES.pageUpdated
            }));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error?.message) ||
              (response.data.error?.detail) ||
              ERROR_MESSAGES.pageUpdated
          }));
        }
        return response;
      })
      .catch(error => {
        dispatch(snackbar({
          open: true, severity: "error",
          text: error?.response?.data?.message || ERROR_MESSAGES.pageUpdated
        }));
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const setActiveFlashPage = data => ({
  type: SET_ACTIVE_FLASH_SALE_PAGE,
  data
});

export const modifyFlashSalePageName = (data, index) => ({
  type: UPDATE_FLASH_SALE_PAGE_NAME,
  data,
  index
});

export const updateFlashSalePageName = (id, index, payload) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return patchDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(modifyFlashSalePageName(response.data.data, index));

          dispatch(snackbar({
            open: true, severity: "success",
            text: SUCCESS_MESSAGES.pageRenamed
          }));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error?.message) ||
              (response.data.error?.detail) ||
              ERROR_MESSAGES.pageRenamed
          }));
        }
        return response;
      })
      .catch(error => {
        dispatch(snackbar({
          open: true, severity: "error",
          text: ERROR_MESSAGES.pageRenamed
        }));
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const fetchFlashSalePagesList = (_countryId) => {
  return (dispatch, getState) => {
    const state = getState();
    const countryId = _countryId || state.store.countryId;
    let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/list?countryId=${countryId}`;
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const deleteProducts = (id, payload) => ({
  type: DELETE_FLASH_SALE_PRODUCT,
  id,
  payload
});

export const deleteProductFromSale = (id, objectID, payload) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/sale-product?objectID=${objectID}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(deleteProducts([id], payload));
          dispatch(snackbar({
            open: true, severity: "success",
            text: SUCCESS_MESSAGES.productDeleted
          }));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error?.message) ||
              (response.data.error?.detail) ||
              ERROR_MESSAGES.productDeleted
          }));
        }
        return response;
      })
      .catch(error => {
        dispatch(snackbar({
          open: true, severity: "error",
          text: ERROR_MESSAGES.productDeleted
        }));
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });;
  };

}

export const deleteAllProductsFromSale = (pageId, pageSlug, saleSlug, pIds = [], countryId) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/delete-products`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, { pageId, pageSlug, saleSlug, pIds }, {
      "x-countryid": countryId
    })
      .then(response => {
        if (response.data.code === 200) {
          dispatch(setInitialPageContent(response.data.data));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error?.message) ||
              (response.data.error?.detail) ||
              ERROR_MESSAGES.productDeleted
          }));
        }
        return response;
      })
      .catch(error => {
        dispatch(snackbar({
          open: true, severity: "error",
          text: ERROR_MESSAGES.productDeleted
        }));
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };

}

export const multiDeleteProductsFromSale = (objectIds = [], pIds = [], payload) => {
  const { pageId, pageSlug, saleSlug, countryId } = payload;
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/flash-sale/delete-products`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, { pageId, pageSlug, saleSlug, pIds: pIds }, {
      "x-countryid": countryId
    })
      .then(response => {
        if (response.data.code === 200) {
          dispatch(deleteProducts(pIds, payload));
          dispatch(snackbar({
            open: true, severity: "success",
            text: SUCCESS_MESSAGES.productDeleted
          }));
        } else {
          dispatch(snackbar({
            open: true, severity: "error",
            text: (response.data.error?.message) ||
              (response.data.error?.detail) ||
              ERROR_MESSAGES.productDeleted
          }));
        }
        return response;
      })
      .catch(error => {
        dispatch(snackbar({
          open: true, severity: "error",
          text: ERROR_MESSAGES.productDeleted
        }));
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };

}
