import React from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { SECTIONS_COMPONENT_TYPES, SECTIONS_PAGE } from "../../constants";
import { DynamicBannerContent } from "../../component";
import {
  openComponent,
  toggleSection,
  deleteComponent
} from "../../actions/sectionsPage.action";
function UpdatePageContent(props) {
  const dispatch = useDispatch();
  const updatePageContent = props.updatePageContent.content;

  const handleComponentOpen = (id, index) => {
    dispatch(openComponent({ id, index }));
  };

  const handleToggleSection = index => {
    dispatch(toggleSection({ index }));
  };

  const handleDeleteItem = index => {
    deleteComponent({ index });
  };
  const getComponent = (type, { index, pageContent }) => {
    // TODO This is for the old banner's structure, should be removed in the future, when we'll have no old structure banners in the database
    if (pageContent.data) {
      pageContent = { ...pageContent.data, ...pageContent };
    }

    const isOpen = _.get(pageContent, "openAccordion", false);

    if (pageContent) {
      switch (type) {
        case SECTIONS_COMPONENT_TYPES.DYNAMIC_BANNER: {
          return (
            <DynamicBannerContent
              sectionTitle={
                props.updatePageContent.sectionName
                  ? props.updatePageContent.sectionName
                  : ""
              }
              content={pageContent}
              // handleDeleteSection={() => handleDeleteItem(index)}
              handleExpandSection={() =>
                handleComponentOpen(pageContent.id, index)
              }
              handleToggleSection={() => handleToggleSection(index)}
              keyIndex={index}
              listName="imageList"
              sliderId="dynamic_banner"
              accordionTitle={pageContent.title}
              pageType={SECTIONS_PAGE}
              accessRights={props.accessRights}
            />
          );
        }
      }
    }
  };

  return (
    <div>
      <DragDropContext>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {_.map(updatePageContent, (pageContent, index) => (
                <div className={pageContent.type} key={index}>
                  {getComponent(pageContent.type, {
                    pageContent,
                    index
                  })}
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default UpdatePageContent;
