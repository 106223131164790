import React, { useState } from "react";
import { Box, Typography, TextField, IconButton, Tooltip } from "../../../materialUi/components";
import { EditIcon } from "../../../materialUi/icons";

import HTMLViewDialog from "./HTMLViewDialog";

const HTMLRowType = ({
    englishHTML,
    arabicHTML,
    onChangeEnglishHTML,
    onChangeArabicHTML,
}) => {
    const HTML_EDITOR_EN = "HTML_EDITOR_EN";
    const HTML_EDITOR_AR = "HTML_EDITOR_AR";

    const [showModal, setShowModal] = useState({
        [HTML_EDITOR_EN]: false,
        [HTML_EDITOR_AR]: false,
    });

    const handleModal = (modalName, value) => {
        setShowModal({ [modalName]: value });
    }
    return (
        <>
            {showModal[HTML_EDITOR_EN] &&
                <HTMLViewDialog
                    handleSave={(data) => {
                        onChangeEnglishHTML(data)
                        handleModal(HTML_EDITOR_EN, false)
                    }}
                    html={englishHTML || ""}
                    handleClose={() => {
                        handleModal(HTML_EDITOR_EN, false)
                    }}
                    open={true}
                    title={"HTML EDITOR"}
                    dialogContext={"Please Configure HTML Details."}
                    language={"en"}
                />
            }
            {showModal[HTML_EDITOR_AR] &&
                <HTMLViewDialog
                    handleSave={(data) => {
                        onChangeArabicHTML(data)
                        handleModal(HTML_EDITOR_AR, false)
                    }}
                    html={arabicHTML || ""}
                    handleClose={() => {
                        handleModal(HTML_EDITOR_AR, false)
                    }}
                    open={true}
                    title={"HTML EDITOR"}
                    dialogContext={"Please Configure HTML Details."}
                    language={"ar"}
                />
            }
            <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "50%", marginRight: "10px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ marginLeft: "auto", marginRight: "auto" }}>English Version</Typography>
                            <Tooltip title={englishHTML ? "Edit HTML" : "Add HTML"} placement="top" arrow>
                                <IconButton size="small"
                                    onClick={() => handleModal(HTML_EDITOR_EN, true)} variant="contained">
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <TextField
                            type="text"
                            key="html_en"
                            label="Default HTML"
                            name="html"
                            placeholder="Default HTML"
                            value={englishHTML}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            disabled={true}
                            minRows={5}
                            sx={{ width: "100%" }}
                            multiline
                        />
                    </Box>

                </Box>
                <Box sx={{ width: "50%", marginLeft: "10px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ marginLeft: "auto", marginRight: "auto" }}>Arabic Version</Typography>
                            <Tooltip title={arabicHTML ? "Edit HTML" : "Add HTML"} placement="top" arrow>
                                <IconButton size="small"
                                    onClick={() => handleModal(HTML_EDITOR_AR, true)} variant="contained">
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <TextField
                            type="text"
                            key="html_ar"
                            label="Default HTML"
                            name="html"
                            placeholder="Default HTML"
                            value={arabicHTML}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            disabled={true}
                            minRows={5}
                            sx={{ width: "100%" }}
                            multiline
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default HTMLRowType;