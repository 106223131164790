import React from "react";
import { connect } from "react-redux";
import {
  addNewScheduler,
  deleteScheduler,
  deleteComponent,
  updateType2Text,
  handleToggle,
  updateTypeBHandleDateChange,
  updateTypeBSchedulerTextChange
} from "../../actions/pagesPage.action";
import { formatUrlString } from "../../util";
import { SCHEDULER_TYPE, LANGUAGE } from "../../constants";
import ProductCarouselAccordion from "../../containers/productCarouselAccordion";

const UpdateCarouselTypeB = ({
  addNewScheduler,
  deleteScheduler,
  position,
  deleteComponent,
  handleToggle,
  updateType2Text,
  updateTypeBHandleDateChange,
  updateTypeBSchedulerTextChange,
  content,
  isOpen,
  handleOpen
}) => {
  const handleAddNewScheduler = () => {
    addNewScheduler(position, SCHEDULER_TYPE.CAROUSEL_TYPE_B);
  };

  const handleDeleteScheduler = (_type, index) => {
    deleteScheduler(position, index);
  };

  const deleteProductCarousel = () => {
    deleteComponent({ index: position });
  };

  const handleSchedulerDateChange = (schedulerIndex, id, timeStamp) => {
    updateTypeBHandleDateChange(id, schedulerIndex, timeStamp, position);
  };

  const handleSchedulerTextChange = (e, language, schedulerIndex) => {
    updateTypeBSchedulerTextChange(
      e.target.name,
      e.target.value,
      position,
      language,
      schedulerIndex
    );
  };

  const handleSchedulerToggleChange = (_type, keyIndex, value) => {
    handleToggle(position, keyIndex, value);
  };

  const handleTextChange = (name, value, language) => {
    updateType2Text(name, value, position, language);
  };

  const handleUrlChange = (name, value) => {
    updateType2Text(name, formatUrlString(value), position, LANGUAGE.ENGLISH);
    updateType2Text(name, formatUrlString(value), position, LANGUAGE.ARABIC);
  };

  const productCarouselId = `product_carousel_${position}`;

  return (
    <ProductCarouselAccordion
      addNewScheduler={handleAddNewScheduler}
      content={content}
      deleteProductCarousel={deleteProductCarousel}
      deleteScheduler={handleDeleteScheduler}
      handleSchedulerDateChange={handleSchedulerDateChange}
      handleSchedulerTextChange={handleSchedulerTextChange}
      handleSchedulerToggleChange={handleSchedulerToggleChange}
      handleTextChange={handleTextChange}
      handleUrlChange={handleUrlChange}
      productCarouselId={productCarouselId}
      isOpen={isOpen}
      handleOpen={handleOpen}
      keyIndex={position}
    />
  );
};

const mapDispatchToProps = {
  addNewScheduler,
  deleteScheduler,
  deleteComponent,
  handleToggle,
  updateType2Text,
  updateTypeBHandleDateChange,
  updateTypeBSchedulerTextChange
};

export default connect(null, mapDispatchToProps)(UpdateCarouselTypeB);
