import React, { Fragment } from "react";
import { connect } from "react-redux";
import CKEditor from "ckeditor4-react";
import { addNewPage } from "./validation";
import Accordion from "./accordion";
import isEqual from "lodash/isEqual";
import CKEditorNotes from "./../../component/ckEditorNotes";
import { setSubmitState } from "../../actions/common.action";
import {
  addPageType,
  addStaticSeo,
  addCkStaticContent,
  addCkStaticAccordContent,
  addCkStaticAccordTile,
  removeCkStaticAccordTile,
  fetchStaticPages,
  resetAddPageContent
} from "../../actions/staticPage.action";
import {
  STATIC_PAGE_TYPE,
  STATIC_PAGE_TYPE_MAP,
  CK_CONFIG_ENGLISH,
  CK_CONFIG_ARABIC,
  CK_EDITOR_URL,
  STANDARD_CK_CONFIG
} from "../../constants";
import { ENV_CONFIG } from "../../config/env";
import { _validateRedirectionUrl } from "../../util";
CKEditor.editorUrl = CK_EDITOR_URL.url;

class AddPageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      componentPosition: 1,
      active: false,
      seo: false,
      englishTextEditor: false,
      arabicTextEditor: false,
      invalidUrlMessage: false
    };
  }

  toggleContent = contentName => {
    // CONTENT TOGGLE
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));
  };

  handleCkTextChange = (evt, lang) => {
    let value = evt.editor.getData();
    this.props.addCkStaticContent("html", value, lang);
  };

  handleAccordTextChange = (key, value, lang, pos) => {
    this.props.addCkStaticAccordContent(key, value, lang, pos);
  };

  UNSAFE_componentWillMount() {
    this.props.resetAddPageContent();
    // this.setState({seo: false, englishTextEditor: false, arabicTextEditor: false});
  }

  handleTextChange = e => {
    const {
      staticAddPageContent,
      addPageType,
      storeId,
      countryId
    } = this.props;
    let url = "";
    if (e.target.name === "url") {
      if (_validateRedirectionUrl(e.target.value)) {
        url = e.target.value.toLowerCase();
      } else {
        url = `/${e.target.value}`;
      }
    }
    this.setState({ invalidUrlMessage: false });
    this.props.addPageType(e.target.name, url || e.target.value);
    if (
      staticAddPageContent.storeId === "" ||
      staticAddPageContent.countryId === ""
    ) {
      addPageType("storeId", storeId);
      addPageType("countryId", countryId);
    }
  };

  componentDidUpdate() {
    const { staticPageR, setSubmitState } = this.props;
    const isValidated = addNewPage(this.props.staticAddPageContent);
    setSubmitState(
      isValidated &&
      !isEqual(
        staticPageR.staticAddPageContent,
        staticPageR.initialAddPageContent
      )
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.closeDropDowns !== this.props.closeDropDowns &&
      nextProps.closeDropDowns
    ) {
      this.setState({
        seo: false,
        englishTextEditor: false,
        arabicTextEditor: false
      });
    }
  }

  render() {
    const {
      seo,
      englishTextEditor,
      arabicTextEditor,
      invalidUrlMessage
    } = this.state;
    const {
      storeId,
      countryId,
      addPageType,
      staticAddPageContent,
      addStaticSeo,
      seoContent,
      ckContent,
      addCkStaticAccordTile,
      removeCkStaticAccordTile,
      staticPages
    } = this.props;

    let dropdown = [];

    Object.keys(STATIC_PAGE_TYPE).map((type, index) => {
      type === "comb_accordion" &&
        staticPages &&
        staticPages[STATIC_PAGE_TYPE_MAP[type]] &&
        staticPages[STATIC_PAGE_TYPE_MAP[type]].length &&
        staticPages[STATIC_PAGE_TYPE_MAP[type]].length >= 2
        ? null
        : dropdown.push(
          <span
            key={index}
            onClick={() => {
              addPageType("pageType", type);
            }}
          >
            {STATIC_PAGE_TYPE[type]}
          </span>
        );
    });

    return (
      <React.Fragment>
        {/* STATIC_PAGE_TYPE */}
        <div>
          <div className="form-group page_information">
            <input
              name="title"
              className="form-control"
              type="text"
              placeholder="Enter Page Name"
              value={staticAddPageContent.title || ""}
              onChange={this.handleTextChange}
            />

            <div className="catalog-url">
              <input
                name="url"
                className="form-control"
                type="text"
                placeholder="Enter Page URL (must start with '/')"
                value={staticAddPageContent.url || ""}
                onChange={e => this.handleTextChange(e)}
              />
              <span className="invalid-url-message">
                {invalidUrlMessage ? `Kindly enter valid URL` : null}
              </span>
            </div>

            {/*<input*/}
            {/*name="url"*/}
            {/*className="form-control"*/}
            {/*type="text"*/}
            {/*placeholder="Enter Page URL"*/}
            {/*value={staticAddPageContent.url || ""}*/}
            {/*onChange={this.handleUpdateSEOData}*/}
            {/*/>*/}

            <div className="dropdown top_custom_dropdown">
              <span className="dropbtn">
                {STATIC_PAGE_TYPE[staticAddPageContent.pageType] ||
                  "Select Static page type"}
              </span>
              <div className="dropdown-content">{dropdown}</div>
            </div>
          </div>
        </div>
        {/* SEO */}
        <div className="cms-wrapper">
          <div className="wrapper-box">
            <div className="heading-wrapper flex catalog-banner-heading">
              <h3>
                <span className="required" />
                {`General SEO`}{" "}
              </h3>
              <span
                onClick={() => this.toggleContent("seo")}
                className={`toggle-banner ${seo ? "minus-banner" : "plus-banner"
                  }`}
              />
            </div>
            {seo && (
              <div className="cms-wrapper">
                <div className="wrapper-box">
                  <div className="data-wrapper">
                    <div className="english-wrapper">
                      <h4>English Version</h4>
                      <div className="english-content">
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Page Title
                          </label>
                          <input
                            name="title"
                            className="form-control date-calender"
                            type="text"
                            placeholder="Enter Page Title"
                            value={seoContent.english.title}
                            onChange={e =>
                              addStaticSeo(
                                e.target.name,
                                e.target.value,
                                "english"
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Meta Keywords
                          </label>
                          <input
                            name="metaKeyword"
                            className="form-control date-calender"
                            type="text"
                            placeholder="Enter Meta Keywords"
                            value={seoContent.english.metaKeyword}
                            onChange={e =>
                              addStaticSeo(
                                e.target.name,
                                e.target.value,
                                "english"
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Meta Description
                          </label>
                        </div>
                        <textarea
                          name="metaDescription"
                          rows="12"
                          cols="50"
                          placeholder="Enter Meta Description"
                          value={seoContent.english.metaDescription}
                          onChange={e =>
                            addStaticSeo(
                              e.target.name,
                              e.target.value,
                              "english"
                            )
                          }
                        />
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Heading H1
                          </label>
                          <input
                            name="headingH1"
                            className="form-control date-calender"
                            type="text"
                            placeholder="Enter Heading H1"
                            value={seoContent.english.headingH1}
                            onChange={e =>
                              addStaticSeo(
                                e.target.name,
                                e.target.value,
                                "english"
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Heading H2
                          </label>
                          <input
                            name="headingH2"
                            className="form-control date-calender"
                            type="text"
                            placeholder="Enter Heading H2"
                            value={seoContent.english.headingH2}
                            onChange={e =>
                              addStaticSeo(
                                e.target.name,
                                e.target.value,
                                "english"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="arabic-wrapper">
                      <h4>Arabic Version</h4>
                      <div className="arabic-content">
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Page Title
                          </label>
                          <input
                            name="title"
                            className="form-control date-calender"
                            type="text"
                            dir="rtl"
                            placeholder="أدخل عنوان الصفحة"
                            value={seoContent.arabic.title}
                            onChange={e =>
                              addStaticSeo(
                                e.target.name,
                                e.target.value,
                                "arabic"
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Meta Keywords
                          </label>
                          <input
                            name="metaKeyword"
                            className="form-control date-calender"
                            type="text"
                            dir="rtl"
                            placeholder="أدخل الكلمات الرئيسية ميتا"
                            vlaue={seoContent.arabic.metaKeyword}
                            onChange={e =>
                              addStaticSeo(
                                e.target.name,
                                e.target.value,
                                "arabic"
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Meta Description
                          </label>
                        </div>
                        <textarea
                          name="metaDescription"
                          rows="12"
                          cols="50"
                          value={seoContent.arabic.metaDescription}
                          placeholder="أدخل الوصف الوصفى"
                          onChange={e =>
                            addStaticSeo(
                              e.target.name,
                              e.target.value,
                              "arabic"
                            )
                          }
                        />
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Heading H1
                          </label>
                          <input
                            name="headingH1"
                            className="form-control date-calender"
                            type="text"
                            dir="rtl"
                            placeholder="أدخل العنوان H1"
                            value={seoContent.arabic.headingH1}
                            onChange={e =>
                              addStaticSeo(
                                e.target.name,
                                e.target.value,
                                "arabic"
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            <span className="required" />
                            Heading H2
                          </label>
                          <input
                            name="headingH2"
                            className="form-control date-calender"
                            type="text"
                            dir="rtl"
                            placeholder="أدخل العنوان H2"
                            value={seoContent.arabic.headingH2}
                            onChange={e =>
                              addStaticSeo(
                                e.target.name,
                                e.target.value,
                                "arabic"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* ENGLISH TEXT_EDITOR */}
        <div className="cms-wrapper">
          <div className="wrapper-box">
            <div className="heading-wrapper flex catalog-banner-heading">
              <h3>
                <span className="required" />
                {`English Text Editor`}
              </h3>
              <span
                onClick={() => this.toggleContent("englishTextEditor")}
                className={`toggle-banner ${englishTextEditor ? "minus-banner" : "plus-banner"
                  }`}
              />
            </div>
            {englishTextEditor && (
              <Fragment>
                <CKEditor
                  activeClass="p10"
                  config={{
                    ...CK_CONFIG_ENGLISH,
                    ...STANDARD_CK_CONFIG,
                    ...{
                      filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                        }/homepage/content/uploadCK?id=${storeId}&key=${"customImage"}&language=${"english"}&storeId=${storeId}&countryId=${countryId}&containerName=${"staticPage"}`
                    }
                  }}
                  data={ckContent.english.html}
                  onChange={e => {
                    this.handleCkTextChange(e, "english");
                  }}
                  onKey={e => {
                    this.handleCkTextChange(e, "english");
                  }}
                />
                <CKEditorNotes noMargin={true} display={[1, 2, 3, 4]} />
              </Fragment>
            )}
          </div>
        </div>
        {/* ARABIC TEXT_EDITOR */}
        <div className="cms-wrapper">
          <div className="wrapper-box">
            <div className="heading-wrapper flex catalog-banner-heading">
              <h3>
                <span className="required" />
                {`Arabic Text Editor`}
              </h3>
              <span
                onClick={() => this.toggleContent("arabicTextEditor")}
                className={`toggle-banner ${arabicTextEditor ? "minus-banner" : "plus-banner"
                  }`}
              />
            </div>
            {arabicTextEditor && (
              <Fragment>
                <CKEditor
                  name="html"
                  activeClass="p10"
                  config={{
                    ...CK_CONFIG_ARABIC,
                    ...STANDARD_CK_CONFIG,
                    ...{
                      filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                        }/homepage/content/uploadCK?id=${storeId}&key=${"customImage"}&language=${"arabic"}&storeId=${storeId}&countryId=${countryId}&containerName=${"staticPage"}`
                    }
                  }}
                  data={ckContent.arabic.html}
                  onChange={e => {
                    this.handleCkTextChange(e, "arabic");
                  }}
                  onKey={e => {
                    this.handleCkTextChange(e, "arabic");
                  }}
                />
                <CKEditorNotes noMargin={true} display={[1, 2, 3, 4]} />
              </Fragment>
            )}
          </div>
        </div>
        {staticAddPageContent.pageType !== "html" && (
          <Accordion
            list={ckContent.accord}
            handleAccordTextChange={this.handleAccordTextChange}
            addCkStaticAccordTile={addCkStaticAccordTile}
            removeCkStaticAccordTile={removeCkStaticAccordTile}
            storeId={storeId}
            countryId={countryId}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  staticAddPageContent: state.staticReducer.staticAddPageContent,
  staticPages: state.staticReducer.staticPages,
  addComponent: state.staticReducer.addPageContent.addComponent,
  storeId: state.store.storeId,
  countryId: state.store.countryId,
  seoContent: state.staticReducer.staticAddPageContent.meta,
  ckContent: state.staticReducer.staticAddPageContent.content,
  staticPageR: state.staticReducer,
  closeDropDowns: state.staticReducer.closeDropDowns
});

const mapDispatchToProps = {
  addCkStaticAccordContent,
  addCkStaticAccordTile,
  addCkStaticContent,
  addPageType,
  addStaticSeo,
  fetchStaticPages,
  removeCkStaticAccordTile,
  resetAddPageContent,
  setSubmitState
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPageContent);
