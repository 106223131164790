export const HOMEPAGE_SECTION_TYPES = {
  BANNER: "banner",
  IMAGE_CAROUSEL: "catalog-carousel",
  META: "meta",
  NEWEST_ARRIVAL: "newestArrival",
  PRODUCT_CAROUSEL: "product-carousel",
  SHOP_BY_BRANDS: "shopByBrands",
  SHOP_BY_STORE: "shopByStore",
  TOP_BANNER_CONTENT: "topBannerContent",
  TOP_CATEGORIES: "topCategories",
  USP_CONTENT: "uspContent",
  SLIDER: "slider",
  DYNAMIC_SLIDER: "dynamicSliderModule",
  DYNAMIC_BANNER: "dynamicBannerModule"
};

export const HOMEPAGE_SECTION_TITLE = {
  DYNAMIC_BANNER: "Dynamic banner module",
  DYNAMIC_SLIDER: "Dynamic slider module"
};

export const TYPE_SELECT_OPTIONS = [
  { label: "Banner", value: HOMEPAGE_SECTION_TYPES.BANNER },
  { label: "Image Carousel", value: HOMEPAGE_SECTION_TYPES.IMAGE_CAROUSEL },
  {
    label: "Product Carousel",
    value: HOMEPAGE_SECTION_TYPES.PRODUCT_CAROUSEL
  },
  { label: "Slider", value: HOMEPAGE_SECTION_TYPES.SLIDER },
  {
    label: "Dynamic Slider Module",
    value: HOMEPAGE_SECTION_TYPES.DYNAMIC_SLIDER
  },
  {
    label: "Dynamic Banner Module",
    value: HOMEPAGE_SECTION_TYPES.DYNAMIC_BANNER
  }
];

export const SCREEN_TYPES = [
  { label: "Desktop", key: "desktop" },
  { label: "Mobile", key: "mobile" },
  { label: "Tablet", key: "tablet" }
];

export const PLATFORM_TYPES_CONFIG = [
  { label: "Resp", key: "resp" },
  { label: "App", key: "app" }
];

export const DEVICE_TYPES_CONFIG = [
  { label: "Desktop", key: "desktop" },
  { label: "Mobile", key: "mobile" },
  { label: "Tablet", key: "tablet" }
];

export const BANNER_STYLE_OPTIONS = [
  { label: "Single banner", value: "singleBanner" },
  { label: "Multiple banner", value: "multipleBanner" }
];
