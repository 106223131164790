import React, { Fragment } from "react";
import _ from "lodash";
import {
  Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button, TableContainer, Table,
  TableHead, TableRow, TableCell, TableBody, IconButton,
} from "../../materialUi/components";
import { LanguageIcon } from "../../materialUi/icons"

const ProductCountModal = ({ open, productCountData, handleIntBtnClick, handleClose, title, dialogContext }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {dialogContext}
        </DialogContentText>
        <TableContainer
        >
          <Table aria-label="sticky table" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  key={"index"}
                  align={"left"}
                >
                  {"Sl.No"}
                </TableCell>
                <TableCell
                  key={"countryName"}
                  align={"left"}
                >
                  {"Country Name"}
                </TableCell>
                <TableCell
                  key={"count"}
                  align={"left"}
                >
                  {"Product Count"}
                </TableCell>
                <TableCell
                  key={"action"}
                  align={"left"}
                >
                  {"Action"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productCountData && productCountData.map((data, index) => (
                <TableRow hover tabIndex={-1} key={index}
                >
                  <TableCell
                    key={"index"}
                    align={"left"}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    key={"countryName"}
                    align={"left"}
                  >
                    {data.countryName}
                  </TableCell>
                  <TableCell
                    key={"count"}
                    align={"left"}
                  >
                    {data.count}
                  </TableCell>
                  <TableCell
                    key={"action"}
                    align={"left"}
                  >
                    <IconButton
                      onClick={data.count !== 0 ? () => handleIntBtnClick(data.countrySHORT, true) : null}
                      disabled={data.count === 0}
                    >
                      <LanguageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductCountModal;
