import React from "react";
import get from "lodash/get";
import {
  FOOTER_STATIC_CONTENT_PLACEHOLDERS,
  IMAGE_SIZE_AND_TYPE_TOOLTIP
} from "../../../constants";
import Image from "../../../component/Image";
import InputText from "../../../component/InputText";
import infoIcon from "../../../images/icon-info.svg";

const SocialMediaLink = ({
  handleImageModal,
  handleUpdateSocialMediaLink,
  socialMediaLink,
  language,
  index
}) => (
  <div className={`${language}-wrapper`}>
    <div className={`${language}-content`}>
      <div className="img-preview marginTop-30">
        <span className="info img-info category-img-info">
          <Image src={infoIcon} alt="info icon" />
          <p className="tooltiptext">{IMAGE_SIZE_AND_TYPE_TOOLTIP}</p>
        </span>
        <div className="form-group preview hover-icon m-r-20">
          <span className="img-title">
            <span className="required" />
            Social Image
          </span>
          {socialMediaLink.img && (
            <span>
              <span className="img-overlay" />
              <span
                className="icon-close"
                onClick={() =>
                  handleUpdateSocialMediaLink("img", "", index, language)
                }
              />
            </span>
          )}
          <span
            className={
              socialMediaLink.img ? "hover-camera-icon" : "camera-icon"
            }
            onClick={() => handleImageModal(index, language)}
          />
          {socialMediaLink.img && (
            <span className="uploaded-image">
              <Image
                className="img-dimension"
                alt="No Image"
                src={socialMediaLink.img}
              />
            </span>
          )}
        </div>
      </div>
      <InputText
        label="Social Image Redirection Link"
        language={language}
        name="redirectLink"
        value={get(socialMediaLink, "redirectLink", "")}
        placeholder={FOOTER_STATIC_CONTENT_PLACEHOLDERS[language].redirectLink}
        onChange={e => {
          handleUpdateSocialMediaLink(
            e.target.name,
            e.target.value,
            index,
            language
          );
        }}
        disableWhitespaces
        required
      />
    </div>
  </div>
);

export default SocialMediaLink;
