import React, { Fragment, useEffect, useState } from "react";
import cn from "classnames";

const ExpandableList = ({ header, list, onClick, opened = false }) => {
  const [showContent, setShowContent] = useState(opened);

  const toggleContent = () => setShowContent(!showContent);
  const clickHandler = () => {
    if (onClick) {
      onClick();
      return null;
    }
    toggleContent();
  };
  useEffect(() => {
    setShowContent(opened);
  }, [opened]);
  return (
    <Fragment>
      <div
        className={cn("extendable-list-header", { expended: showContent })}
        onClick={clickHandler}
      >
        {header(toggleContent)}
      </div>
      {showContent && list(toggleContent)}
    </Fragment>
  );
};

export default ExpandableList;
