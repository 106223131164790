import { SELECT_STORE_ID, RESET_STORE_ID } from "../constants/actions";

const initialState = [];

const selectStoreId = (state, { storeId }) => {
  return (state = storeId);
};

const resetStoreId = (state, { storeId }) => {
  return (state = [storeId]);
};

const selectedStoreIds = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_STORE_ID:
      return selectStoreId(state, action);
    case RESET_STORE_ID:
      return resetStoreId(state, action);
    default:
      return state;
  }
};

export default selectedStoreIds;
