import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { SCREEN_TYPES } from "../../constants";
import { BannerTab } from "./Tabs";
import { changeDeviceTab } from "../../actions/dynamicBanner.action";

export const DynamicBannerTab = ({
  position,
  content,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  clone
}) => {
  const dispatch = useDispatch();

  const deviceTab = useSelector(({ common: { deviceTab } }) => deviceTab);

  const handleChangeTab = key => () => {
    dispatch(changeDeviceTab({ deviceType: key }));
  };

  return (
    <div className="st st-theme-default">
      <ul className="nav">
        {SCREEN_TYPES.map(({ key, label }) => (
          <li key={key}>
            <a
              onClick={handleChangeTab(key)}
              style={key === deviceTab ? { background: "#FFD100" } : {}}
              className="nav-link"
            >
              {[SCREEN_TYPES[0].label, SCREEN_TYPES[1].label].includes(
                label
              ) ? (
                <div>
                  {label}
                  {clone && (
                    <span
                      onClick={clone(label)}
                      data-tip={`Copy ${label} Tab to Tablet`}
                      className="clone-btn"
                    />
                  )}
                </div>
              ) : (
                label
              )}
            </a>
          </li>
        ))}
      </ul>

      <div className="tab-content">
        <div className="tab-pane active">
          <BannerTab
            listPosition={position}
            content={content}
            deviceType={deviceTab}
            pageType={pageType}
            updateCatalogPageType={updateCatalogPageType}
            updateCatalogPageLocation={updateCatalogPageLocation}
          />
        </div>
      </div>
    </div>
  );
};
