import { CONTAINER_TYPE, HOMEPAGE_URL } from "../../constants";

export const mapUpdatedSortableTreeNodeToContent = (nodeData, content) =>
  content.map(item => {
    if (item.uid === nodeData.uid) {
      return nodeData;
    } else {
      return {
        ...item,
        children: item.children.map(child => {
          if (child.uid === nodeData.uid) {
            return nodeData;
          }
          return child;
        })
      };
    }
  });

export const mapTreeData = (eData, aData, level = 1, maxLevel = 2) =>
  eData.map((item, index) => {
    const englishData = item;
    const arabicData = aData[index];
    return {
      title: `${englishData.subTitle || englishData.title}/ ${arabicData.subTitle || arabicData.title
        }`,
      expanded: true,
      arabicData,
      englishData,
      children:
        level < maxLevel
          ? mapTreeData(englishData.children, arabicData.children, level + 1)
          : []
    };
  });

export const mapPagesPageToFlatStructure = rawPages => {
  const { defaultPages = [], scheduledPages = [], variantPages = [] } = rawPages;
  const pageContainers = [
    {
      id: "container-1",
      title: "Default Pages",
      folderIds: defaultPages.map(folder => folder._id),
      type: CONTAINER_TYPE.DEFAULT
    },
    {
      id: "container-2",
      title: "Scheduled Pages",
      folderIds: scheduledPages.map(folder => folder._id),
      type: CONTAINER_TYPE.SCHEDULED,
      isScheduler: true
    },
    {
      id: "container-3",
      title: "Variant Pages",
      folderIds: variantPages.map(folder => folder._id),
      type: CONTAINER_TYPE.VARIANT,
      isVariant: true
    }
  ];
  const scheduledPagesBegin = defaultPages.length;
  const scheduledPagesEnd = scheduledPagesBegin + scheduledPages.length - 1;
  const variantPagesBegin = scheduledPagesEnd + 1;
  const variantPagesEnd = variantPagesBegin + variantPages.length - 1;
  const allPages = [...defaultPages, ...scheduledPages, ...variantPages];
  const folders = allPages.reduce((acc, folder, index) => {
    const scheduled =
      index >= scheduledPagesBegin && index <= scheduledPagesEnd;
    const variant =
      index >= variantPagesBegin && index <= variantPagesEnd;
    const { _id, ...otherProps } = folder;
    acc[_id] = {
      id: _id,
      pageIds: folder.pages.map(page => page._id),
      scheduled,
      variant,
      ...otherProps
    };
    return acc;
  }, {});

  let homePage = {};

  const pages = {};
  allPages.forEach(folder => {
    folder.pages.forEach(page => {
      const { _id, ...otherProps } = page;
      pages[_id] = {
        id: _id,
        scheduledPage: folders[folder._id].scheduled,
        variantPage: folders[folder._id].variant,
        folderId: folder._id,
        ...otherProps
      };
      if (page.url === HOMEPAGE_URL) {
        homePage = pages[_id];
      }
    });
  });

  return {
    pageContainers,
    folders,
    pages,
    homePage
  };
};
