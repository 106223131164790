import React, { Fragment } from "react";
import cn from "classnames";
import map from "lodash/map";
import CountryDropDown from "../containers/countryDropdown";
import Button from "./button";
import { FOOTER_PANEL, FOOTER_PANEL_LABELS } from "../constants";

const CustomSideBar = ({
  currentTab,
  changeTab,
  isSizeChart = false,
  isMegaMenu,
  clearMenuContent,
  englishList,
  arabicList,
  isFooter = false,
  showContent,
  changeNav,
  showCountryDropdown,
  isNewFooter = false
}) => {
  const iconNavCls = `open-nav ${showContent ? "minus-icon-nav" : "plus-icon-nav"
    }`;

  return (
    <div className="side-bar">
      {showCountryDropdown && (
        <div className="apply-for-dropdown">
          <p>Select Country</p>
          <CountryDropDown
            changeTab={changeTab}
            isFooterPage={isFooter}
            isMegaMenuPage={isMegaMenu}
          />
        </div>
      )}
      <div className="nav-child">
        {isFooter && (
          <Fragment>
            <span className={`${iconNavCls}`} onClick={changeNav}>
              Footer
            </span>
            {showContent && (
              <ul>
                <li
                  id={FOOTER_PANEL.MENU_ITEMS}
                  className={cn({
                    active: currentTab === FOOTER_PANEL.MENU_ITEMS
                  })}
                  onClick={changeTab}
                >
                  {FOOTER_PANEL_LABELS[FOOTER_PANEL.MENU_ITEMS]}
                </li>
                {!isNewFooter && (<li
                  id={FOOTER_PANEL.STATIC_CONTENT}
                  className={cn({
                    active: currentTab === FOOTER_PANEL.STATIC_CONTENT
                  })}
                  onClick={changeTab}
                >
                  {FOOTER_PANEL_LABELS[FOOTER_PANEL.STATIC_CONTENT]}
                </li>)}
              </ul>
            )}
          </Fragment>
        )}
        {isMegaMenu && (
          <Fragment>
            <span className={`${iconNavCls}`} onClick={changeNav}>
              Mega Menu
            </span>
            {showContent && (
              <ul
                className={cn({
                  "list-padding": !isFooter
                })}
              >
                <li className="add-menu-btn-wrapper">
                  <Button
                    name="Add New Menu"
                    customClass={"add-menu-btn"}
                    onClick={clearMenuContent}
                  />
                </li>
                {!isFooter &&
                  map(englishList, (item, i) => (
                    <li
                      key={item.uid}
                      className={cn({
                        active: currentTab === item.uid
                      })}
                      onClick={() => changeTab(item, arabicList[i])}
                    >
                      {`${item.headerTitle} / ${arabicList[i].headerTitle}`}
                    </li>
                  ))}
              </ul>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default CustomSideBar;
