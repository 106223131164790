export const FOOTER_STATIC_CONTENT_MESSAGES = {
  SEE_CHANGES: "Do you want to see the changes?",
  UNSAVED_CHANGES:
    "There are some unsaved changes, Kindly save it otherwise all the unsaved changes will be lost. Do you want to continue ?"
};

export const FOOTER_ADD_FOOTER_ITEM_MESSAGES = {
  DELETE_WITH_NODES:
    "Deleting will delete the child nodes also. Do you want to continue?",
  DELETE_RECORD: "Delete this record. Do you want to continue?"
};
