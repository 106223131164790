import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import ReactPaginate from "react-paginate";
import ReactTooltip from "react-tooltip";
import { isEqual } from "lodash";
import {
  fetchUserManagementData,
  deleteUserManagementData,
  addUserManagementData,
  updateUserPasswordManagementData,
  updateUserManagementData
} from "../../actions/userManagement.action";
import { fetchRoleManagementData } from "../../actions/permissions.action";
import AddUserModal from "../modal/addUser";
import ChangePasswordModal from "../modal/changePassword";
import ConfirmModal from "../modal/ConfirmModal";
import EditUserModal from "../modal/editUser";
import { changeTab } from "../../actions/common.action";
import { getDateTime } from "../../util";
import { NAVIGATION, NAVIGATION_MENUS_SLUGS, MESSAGES } from "../../constants";

class UserManagement extends Component {
  state = {
    selectedUser: null,
    searchText: "",
    limit: 10,
    skip: 0,
    pageCount: 0,
    addUserModal: false,
    editUserPasswordModal: false,
    removeUserConfirmModal: false,
    editUserModal: false,
    currentPage: 0,
    startPage: 1,
    endPage: 10, //endPage initial value should be equal to limit
    roleKeyNameMapping: {}
  };

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.USERS);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { roleList: prevRoleList } = prevProps;
    const { roleList = [] } = this.props;
    if (!isEqual(prevRoleList, roleList) || (roleList.length && isEmpty(this.state.roleKeyNameMapping))) {
      const roleKeyNameMapping = {};
      roleList.forEach((role) => {
        roleKeyNameMapping[role.slug] = role.name;
      });
      this.setState({ roleKeyNameMapping });
    }
  }

  loadData = (_limit, _skip, _currentPage) => {
    const { limit, skip, searchText = "", currentPage } = this.state;
    const { fetchUserManagementData, fetchRoleManagementData } = this.props;

    _limit = _limit || limit;
    _skip = _skip || skip;
    _currentPage = _currentPage || currentPage;

    fetchUserManagementData(_limit, _skip, searchText.trim()).then(res => {
      if (res.data && res.data.code && res.data.code === 200) {
        const { usersCount } = this.props;
        let end = _currentPage * limit + limit;
        let start = end - 9;
        this.setState({
          pageCount: Math.ceil(usersCount / _limit),
          currentPage: _currentPage,
          startPage: start,
          endPage: end
        });
      }
    });
    fetchRoleManagementData();
  };

  handlePageClick = data => {
    const { limit } = this.state;

    const selected = data.selected;
    const offset = Math.ceil(selected * limit);
    const end = selected * limit + limit;
    const start = end - 9;
    this.setState(
      { skip: offset, startPage: start, endPage: end, currentPage: selected },
      () => {
        this.loadData();
      }
    );
  };

  searchTextChangeHandler = e => {
    this.setState(
      {
        searchText: e.target.value || ""
      },
      () => {
        if (!this.state.searchText) {
          this.loadData(10, 0, 0);
        }
      }
    );
  };

  handleModal = (modalName, user = null) => {
    this.setState(prevState => ({
      [modalName]: !prevState[modalName],
      selectedUser: user
    }));
  };

  addUser = user => {
    const { addUserManagementData } = this.props;
    let payload = {
      name: user.name,
      email: user.email,
      password: user.password,
      role: user.role
    };

    addUserManagementData(payload).then(res => {
      if (res.data && res.data.code && res.data.code === 200) {
        this.loadData();
      }
      this.handleModal("addUserModal");
    });
  };

  editUserPassword = password => {
    const { selectedUser } = this.state;
    const { updateUserPasswordManagementData } = this.props;

    updateUserPasswordManagementData(selectedUser._id, {
      password,
      email: selectedUser.email
    }).then(res => {
      if (res.data && res.data.code && res.data.code === 200) {
        this.loadData();
      }
      this.handleModal("editUserPasswordModal");
    });
  };

  editUser = (name, role) => {
    const { selectedUser } = this.state;
    const { updateUserManagementData } = this.props;

    updateUserManagementData(selectedUser._id, {
      name,
      role,
      email: selectedUser.email
    }).then(res => {
      if (res.data && res.data.code && res.data.code === 200) {
        this.loadData();
      }
      this.handleModal("editUserModal");
    });
  };

  deleteUser = () => {
    const { selectedUser } = this.state;
    const { deleteUserManagementData } = this.props;

    deleteUserManagementData(selectedUser._id, selectedUser.email).then(res => {
      if (res.data && res.data.code && res.data.code === 200) {
        this.loadData();
      }
      this.handleModal("removeUserConfirmModal");
    });
  };

  pressEnter = e => {
    e.preventDefault();
    if (e.key === "Enter") {
      this.loadData(10, 0, 0);
    }
  };

  render() {
    const {
      searchText,
      addUserModal,
      editUserPasswordModal,
      removeUserConfirmModal,
      editUserModal,
      pageCount,
      endPage,
      currentPage,
      selectedUser,
      roleKeyNameMapping,
    } = this.state;
    const { userList, usersCount, user } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.USER_MANAGEMENT]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    let _userList = [];
    _userList = map(userList, (_user, _index) => (
      <Fragment key={`${_index}_${_user.email}`}>
        <tr key={`${_index}_${_user.email}`}>
          <th scope="row">
            <span className="user-icon" />
          </th>
          <td>{_user.name}</td>
          <td>{_user.email}</td>
          <td>
            {_user.updatedAt
              ? getDateTime(_user.updatedAt)
              : _user.createdAt
                ? getDateTime(_user.createdAt)
                : `--`}
          </td>
          <td>
            {_user.updatedBy
              ? _user.updatedBy.name || _user.updatedBy.email || `--`
              : _user.createdBy
                ? _user.createdBy.name || _user.createdBy.email || `--`
                : `--`}
          </td>
          <td>
            <span
              data-tip={_user.role}
              data-for="user-management-list">
              {roleKeyNameMapping[_user.role] || ""}
            </span>
          </td>
          <td>
            <span
              className={`text-border ${accessRights.UPDATE
                ? "cursor-pointer"
                : "cursor-pointer-disabled-permission"
                }`}
              onClick={
                accessRights.UPDATE
                  ? () => this.handleModal("editUserPasswordModal", _user)
                  : null
              }
              data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
              data-for="user-management-list"
            >
              Change Password
            </span>
            <span
              className={`edit-icon ${accessRights.UPDATE
                ? "cursor-pointer"
                : "cursor-pointer-disabled-permission"
                }`}
              onClick={
                accessRights.UPDATE
                  ? () => this.handleModal("editUserModal", _user)
                  : null
              }
              data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
              data-for="user-management-list"
            />
            <span
              className={`delete-icon ${accessRights.DELETE
                ? "cursor-pointer"
                : "cursor-pointer-disabled-permission"
                }`}
              onClick={
                accessRights.DELETE
                  ? () => this.handleModal("removeUserConfirmModal", _user)
                  : null
              }
              data-tip={accessRights.DELETE ? "" : MESSAGES.TOOLTIP_DELETE}
              data-for="user-management-list"
            />
          </td>
        </tr>
        <ReactTooltip
          place="top"
          effect="solid"
          id="user-management-list"
        />
      </Fragment>
    ));

    if (isEmpty(userList)) {
      _userList.push(
        <tr key="no_admin" className="text-align-middle">
          <td colSpan={5}>
            {searchText ? "No users found." : "No users available."}
          </td>
        </tr>
      );
    }

    return (
      <Fragment>
        {addUserModal && (
          <AddUserModal
            modalName={"addUserModal"}
            onHide={this.handleModal}
            addUser={this.addUser}
          />
        )}
        {editUserPasswordModal && (
          <ChangePasswordModal
            modalName={"editUserPasswordModal"}
            onHide={this.handleModal}
            changePassword={this.editUserPassword}
          />
        )}
        {removeUserConfirmModal && (
          <ConfirmModal
            modalName={"removeUserConfirmModal"}
            onHide={this.handleModal}
            confirm={this.deleteUser}
            message={"Are you sure you want to delete ?"}
          />
        )}
        {editUserModal && (
          <EditUserModal
            modalName={"editUserModal"}
            onHide={this.handleModal}
            editUser={this.editUser}
            user={selectedUser}
          />
        )}
        <div className="row user-management-container">
          <div className="col-lg-12 m-b-5">
            <span className="total-user-text col-sm-6">
              Total Users ({usersCount})
            </span>
            <div className="col-sm-4">
              <button
                onClick={() => this.loadData(10, 0, 0)}
                className="search-icon-btn"
              >
                <span className="search-icon" />
              </button>
              <input
                id="searchText"
                name="searchText"
                type="text"
                value={searchText}
                className="form-control marginField no-border-radius input-height"
                placeholder="Search Users"
                onChange={this.searchTextChangeHandler}
                onKeyUp={this.pressEnter}
              />
            </div>
            <span
              data-tip={accessRights.WRITE ? "" : MESSAGES.TOOLTIP_WRITE}
              data-for="user-management"
            >
              <button
                className="col-sm-2 round-btn pull-right"
                onClick={
                  accessRights.WRITE
                    ? () => this.handleModal("addUserModal")
                    : null
                }
                disabled={!accessRights.WRITE}
              >
                <span className="plus-icon" />
                <span className="btn-text">{"Add New User"}</span>
              </button>
            </span>
          </div>
          <div className="row">
            <div className="user-management-table">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" />
                    <th scope="col">Name</th>
                    <th scope="col">Email ID</th>
                    <th scope="col">Last Modification</th>
                    <th scope="col">Modified by</th>
                    <th scope="col">Role</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>{_userList}</tbody>
              </table>
              {!isEmpty(userList) && (
                <div>
                  <span className="view-number">
                    viewing {usersCount < endPage ? usersCount : endPage} out of{" "}
                    {usersCount} {usersCount > 1 ? "users" : "user"}
                  </span>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    forcePage={currentPage}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
          <ReactTooltip place="top" effect="solid" id="user-management" />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  userList: state.userManagement.userList,
  usersCount: state.userManagement.usersCount,
  roleList: state.permissions.roleList,

});

const mapDispatchToProps = {
  fetchUserManagementData,
  fetchRoleManagementData,
  deleteUserManagementData,
  addUserManagementData,
  updateUserManagementData,
  updateUserPasswordManagementData,
  changeTab
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
