import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import {
  SET_SECTIONS_PAGE_DATA,
  SET_SECTIONS_ACTIVE_PAGE,
  ADD_NEW_SECTIONS_COMPONENT,
  SECTIONS_PAGE_OPEN_COMPONENT,
  SECTIONS_PAGE_TOGGLE_COMPONENT,
  MODULE_DATA_CHANGE_SECTIONS_PAGE,
  GENERATE_IMAGES_SECTIONS_PAGE,
  UPDATE_BANNER_SECTION_DATA_STYLES_SECTIONS_PAGE,
  PUT_BANNER_DATA_STYLES_SECTIONS_PAGE,
  CLONE_MOBILE_SECTIONS_PAGE,
  UPDATE_LIST_DATA_SECTIONS_PAGE,
  CLONE_ROW_SECTIONS_PAGE,
  DELETE_LIST_ITEM_SECTIONS_PAGE,
  TOGGLE_LIST_ITEM_SECTIONS_PAGE,
  UPDATE_BANNER_SECTION_DATA_SECTIONS_PAGE,
  ADD_BANNER_ROW_ITEM_SECTIONS_PAGE,
  ADD_BANNER_SLIDER_ROW_IMAGES_SECTIONS_PAGE,
  ADD_BANNER_SCHEDULER_SECTIONS_PAGE,
  HANDLE_DATA_CHANGE_BANNER_SCHEDULER_SECTIONS_PAGE,
  HANDLE_ROW_SCHEDULER_TOGGLE_SECTIONS_PAGE,
  HANDLE_DELETE_BANNER_SCHEDULER_SECTIONS_PAGE,
  COPY_CONTENT_TO_SCHEDULER_SECTIONS_PAGE,
  CLONE_SCHEDULER_SECTIONS_PAGE,
  SET_SECTIONS_MODULES,
  SET_MODULE_SECTIONS_PAGE,
  TOGGLE_RESET_SECTIONS_PAGE,
  RESET_SECTIONS_CONTENT,
  CLEAR_BANNER_ROW_IMAGE_DATA_SECTIONS_PAGE,
  CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_SECTIONS_PAGE,
  DELETE_ROW_IMAGE_SECTIONS_PAGE,
  REORDER_BANNER_ROW_IMAGES_SECTIONS_PAGE,
  ITEMS_PER_GROUP_CHANGE_SECTIONS_PAGE,
  UPDATE_ROW_TYPE_CUSTOM_DATA_SECTIONS_PAGE,
  CHANGE_BANNER_IMAGE_HOVER_STYLE_SECTIONS_PAGE,
  UPDATE_BANNER_ROW_IMAGE_DATA_SECTIONS_PAGE,
  COUNTDOWN_DATA_CHANGE_SECTIONS_PAGE,
  UPDATE_BANNER_DEFAULT_TEXT_SECTIONS_PAGE,
  PRODUCT_CAROUSEL_DATA_CHANGE_SECTIONS_PAGE,
  ADD_ROW_TYPE_CUSTOM_FIELD_SECTIONS_PAGE,
  UPDATE_ROW_TYPE_CUSTOM_MODULE_SECTIONS_PAGE,
  REMOVE_ROW_TYPE_CUSTOM_FIELD_SECTIONS_PAGE,
  APPLY_GENERAL_SPACING_SECTIONS_PAGE,
  UPDATE_MODULE_SECTIONS_PAGE,
  UPDATE_MODULE_PAGE,
  ADD_ROW_TYPE_ACCORDION_FIELD_SECTIONS_PAGE,
  UPDATE_ROW_TYPE_ACCORDION_DATA_SECTIONS_PAGE,
  UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_SECTIONS_PAGE,
  REMOVE_ROW_TYPE_ACCORDION_FIELD_SECTIONS_PAGE,
  ADD_ROW_TYPE_BULLET_FIELD_SECTIONS_PAGE,
  UPDATE_ROW_TYPE_BULLET_DATA_SECTIONS_PAGE,
  UPDATE_ROW_TYPE_BULLET_HEADER_DATA_SECTIONS_PAGE,
  REMOVE_ROW_TYPE_BULLET_FIELD_SECTIONS_PAGE,
  ADD_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE,
  REMOVE_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE,
  DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE,
  ADD_ROW_TYPE_COLUMN_MENU_SECTIONS_PAGE,
  UPDATE_ROW_TYPE_COLUMN_MENU_SECTIONS_PAGE,
  SET_SECTIONS_ROLLBACK_HISTORY,
} from "../constants/actions";
import {
  bannerDesktopBackgroundOptions,
  bannerGradientTypes,
  bannerTypeValueConstants,
  dynamicBannerItems,
  HOMEPAGE_SECTION_TYPES,
  initialBannerSection,
  initialDynamicBannerRow,
  initialDynamicBannerSection,
  initialImage,
  initialImageCarouselSection,
  initialProductCarouselSection,
  customModuleNameConstants,
  rowWidthValues,
  SCREEN_TYPES
} from "../constants";
import { initialDynamicSlider } from "../constants/dynamicSlider";
import { generateUUID, arrayReorder } from "../util";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";

const initialState = {
  // addPageContent: {
  //   addComponent: [],
  // },
  activePage: {},
  initialActiveSection: {},
  initialPagesContent: {},
  initialData: [],
  resetDisabled: true,
  closeDropDowns: false,
  isPublished: true,
  rollbackHistory: [],
};

const setActiveSectionsPage = (state, action) => {
  return {
    ...state,
    activePage: action.payload,
    initialActiveSection: action.payload
  };
};

const addNewComponent = (state, { componentType, updatePageContent }) => {
  const updatePageContentData = cloneDeep(updatePageContent);
  let newComponent;

  switch (componentType) {
    case HOMEPAGE_SECTION_TYPES.BANNER:
      newComponent = initialBannerSection();
      break;
    case HOMEPAGE_SECTION_TYPES.IMAGE_CAROUSEL:
      newComponent = initialImageCarouselSection();
      break;
    case HOMEPAGE_SECTION_TYPES.PRODUCT_CAROUSEL:
      newComponent = initialProductCarouselSection();
      break;
    case HOMEPAGE_SECTION_TYPES.DYNAMIC_SLIDER:
      newComponent = initialDynamicSlider();
      break;
    case HOMEPAGE_SECTION_TYPES.DYNAMIC_BANNER:
      newComponent = initialDynamicBannerSection();
      break;
    default:
      newComponent = {
        id: generateUUID(),
        type: componentType,
        enable: false
      };
  }

  set(updatePageContentData, "content", [
    ...get(updatePageContentData, "content", []),
    newComponent
  ]);

  return {
    ...state,
    activePage: updatePageContentData
  };
};

const openComponent = (state, { id, index }) => {
  const pagesContent = cloneDeep(state.activePage);

  pagesContent.content.map((item, itemIndex) => {
    if (id ? item.id === id : itemIndex === index) {
      item.openAccordion = !item.openAccordion;
    } else {
      item.openAccordion = false;
    }
  });

  return {
    ...state,
    activePage: pagesContent
  };
};

const toggleSection = (state, { index }) => {
  const pagesContent = cloneDeep(state.activePage);

  const contentModule = pagesContent.content.find(
    (item, itemIndex) => itemIndex === index
  );
  contentModule.enable = !contentModule.enable;
  return {
    ...state,
    activePage: pagesContent
  };
};

const handleModuleDataChange = (state, { path, name, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, name], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const generateRows = (state, { path, numberOfRows }) => {
  const content = cloneDeep(state.activePage.content);

  const previousRows = get(content, path, []);

  const newArrayLength =
    !isEmpty(previousRows) && numberOfRows > previousRows.length
      ? numberOfRows - previousRows.length
      : numberOfRows;

  const emptyRows = Array.from(Array(newArrayLength), () => {
    return initialDynamicBannerRow();
  });

  const rowsWithData =
    numberOfRows > previousRows.length
      ? [...previousRows, ...emptyRows]
      : previousRows.slice(0, numberOfRows);

  set(content, path, rowsWithData);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateBannerSectionDataStyles = (state, { path, name, value }) => {
  const content = cloneDeep(state.activePage.content);

  const styles = get(content, [...path, "styles"], {});

  if (["backgroundImage", "backgroundColor"].includes(name)) {
    delete styles.backgroundImage;
    delete styles.backgroundColor;
  }

  set(styles, [name], value);

  if (
    [
      "primaryBackgroundColor",
      "secondaryBackgroundColor",
      "gradientType",
      "primaryLocation",
      "secondaryLocation",
      "gradientAngle"
    ].includes(name)
  ) {
    set(
      styles,
      "backgroundGradient",
      `${styles.gradientType}(${styles.gradientType === bannerGradientTypes[0].value
        ? `${styles.gradientAngle}deg`
        : "circle"
      }, ${styles.primaryBackgroundColor} ${styles.primaryLocation}%, ${styles.secondaryBackgroundColor
      } ${styles.secondaryLocation}%)`
    );
  }

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const putBannerDataStyles = (state, { value, path }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, "styles"], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const cloneDynamicTabRow = (state, { path, tab }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path);
  const tabletData = get(row, "tablet");
  const tabData = get(row, tab.toLowerCase());

  const isDesktop = tab === SCREEN_TYPES[0].label;

  tabletData.rows = [
    ...tabData.rows.map(row => {
      const _row = cloneDeep(row);

      if (isDesktop && _row.bannerType === bannerTypeValueConstants.SLIDER) {
        _row.imageList = _row.imageList.map(image => ({
          ...image,
          english: { ...image.english, imageURL: null },
          arabic: { ...image.arabic, imageURL: null }
        }));
      }

      return {
        ..._row,
        width:
          isDesktop && _row.width === rowWidthValues.CUSTOM_WIDTH
            ? null
            : _row.width,
        id: generateUUID()
      };
    })
  ];
  tabletData.styles = {
    ...tabData.styles,
    backgroundType:
      isDesktop &&
        tabData.styles.backgroundType === bannerDesktopBackgroundOptions[2].value
        ? null
        : tabData.styles
          ? tabData.styles.backgroundType
          : null
  };
  tabletData.title = tabData.title;
  tabletData.schedulers = [...(tabData.schedulers || [])];

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateListData = (state, { path, name, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, name], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const cloneDynamicRow = (
  state,
  { path: [contentIndex, deviceType, rows], rowIndex, moduleId }
) => {
  const content = cloneDeep(state.activePage.content);

  const targetModule = content.find(module => module.id === moduleId);

  const copiedRow = cloneDeep(
    get(content, [contentIndex, deviceType, rows, rowIndex])
  );

  copiedRow.id = generateUUID();

  set(
    targetModule,
    [deviceType, rows],
    [...get(targetModule, [deviceType, rows]), copiedRow]
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const deleteListItem = (state, { path, listIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const updatedHomepageListData = filter(
    get(content, path, []),
    (_listItem, index) => index !== listIndex
  );
  set(content, path, updatedHomepageListData);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const toggleListItem = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  const isEnabled = get(content, [...path, "enable"]);
  set(content, [...path, "enable"], !isEnabled);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateBannerSection = (state, { path, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, path, value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const addBannerRowItem = (state, { path, itemType }) => {
  const content = cloneDeep(state.activePage.content);

  const pathToRow = path.slice(0, path.length - 1);

  const row = get(content, pathToRow);

  const item = dynamicBannerItems[itemType]();

  item.styles = { ...item.styles, ...row.generalPadding };

  set(content, path, [...get(content, path, []), item]);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const addBannerCarouselRowImages = (state, { path, itemsPerGroup }) => {
  const content = cloneDeep(state.activePage.content);

  const imageList = get(content, path);

  let newImageListCount = imageList.length;

  const pathToRow = path.slice(0, path.length - 1);

  const row = get(content, pathToRow);

  const getImageObject = () => {
    const item = initialImage();

    item.styles = { ...item.styles, ...row.generalPadding };

    return item;
  };

  if (newImageListCount % itemsPerGroup !== 0) {
    while (newImageListCount % itemsPerGroup !== 0) {
      newImageListCount++;
    }

    set(content, path, [
      ...get(content, path, []),
      ...Array.from(Array(newImageListCount - imageList.length), getImageObject)
    ]);
  } else {
    set(content, path, [
      ...get(content, path, []),
      ...Array.from(Array(itemsPerGroup), getImageObject)
    ]);
  }

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const addSchedulerToBannerRow = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path, {});

  set(row, "schedulers", [
    ...get(row, "schedulers", []),
    initialDynamicBannerRow()
  ]);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleDataChangeBannerScheduler = (state, { path, key, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, key], value);

  const ONE_DAY_MS = 60000 * 1439;

  if (key === "from") {
    const toDate = get(content, [...path, "to"]);

    if (!toDate) set(content, [...path, "to"], value + ONE_DAY_MS);
  }

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleRowSchedulerToggle = (state, { path, key, value }) => {
  let newState = handleDataChangeBannerScheduler(state, { path, key, value });
  if (value) {
    const [listPosition, deviceType] = path;
    const schedulers = get(
      newState,
      ["activePage", "content", listPosition, deviceType, "schedulers"],
      []
    );
    schedulers.forEach(scheduler => {
      set(scheduler, "enabled", false);
    });
  }
  return newState;
};

const handleDeleteBannerScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path, []);

  set(
    row,
    "schedulers",
    get(row, "schedulers", []).filter(
      (_item, index) => index !== schedulerIndex
    )
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const copyContentToScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path);

  const schedulers = get(row, "schedulers");

  const scheduler = get(schedulers, [schedulerIndex]);

  set(schedulers, schedulerIndex, {
    ...scheduler,
    ...row,
    id: scheduler.id,
    imageList: [
      ...scheduler.imageList.map(image => ({ ...image, id: generateUUID() })),
      ...cloneDeep(row).imageList.map(image => ({
        ...image,
        id: generateUUID()
      }))
    ]
  });

  delete schedulers[schedulerIndex].schedulers;

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const cloneDynamicScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const copiedScheduler = cloneDeep(get(content, [...path, schedulerIndex]));

  copiedScheduler.id = generateUUID();
  copiedScheduler.enabled = false;
  set(content, path, [...get(content, path), copiedScheduler]);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const setPageData = (state, action) => {
  return {
    ...state,
    activePage: action.payload,
    initialPagesContent: cloneDeep(action.payload)
  };
};

const setModulePages = (state, action) => {
  return {
    ...state,
    initialPagesContent: action.payload
  };
};

const setSectionPages = (state, action) => {
  const content = cloneDeep(state.initialPagesContent);
  const currentItem = content.find(item => {
    return item.moduleType == action.payload.moduleType;
  });
  set(currentItem, "children", action.payload.children);
  const updatedData = [...new Set(content, currentItem)];
  return {
    ...state,
    initialPagesContent: updatedData
  };
};

const updateSectionPages = (state, action) => {
  const content = cloneDeep(state.initialPagesContent);
  const activePageContent = cloneDeep(state.activePage);
  const activeOrNot =
    activePageContent && activePageContent._id == action.data._id;

  const updatedDataActive = {
    ...activePageContent,
    sectionName: activeOrNot && action.data.sectionName,
    title: activeOrNot && action.data.sectionName,
    sectionSlug: activeOrNot && action.data.sectionSlug
  };
  return {
    ...state,
    initialPagesContent: action.payload,
    activePage: activeOrNot ? updatedDataActive : activePageContent
  };
};

const updateModulePages = (state, action) => {
  const content = cloneDeep(state.initialPagesContent);
  const activePageContent = cloneDeep(state.activePage);
  const activeOrNot =
    activePageContent && activePageContent._id == action.data._id;

  const updatedDataActive = {
    ...activePageContent,
    moduleName: activeOrNot && action.data.moduleName,
    title: activeOrNot && action.data.moduleName,
    moduleSlug: activeOrNot && action.data.moduleSlug
  };
  return {
    ...state,
    initialPagesContent: action.payload,
    activePage: activeOrNot ? updatedDataActive : activePageContent
  };
};

const clearBannerRowImageData = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, "imageList"], []);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const clearBannerSchedulerRowImageData = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, path, []);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const deleteRowImage = (state, { path, imageIndex }) => {
  const content = cloneDeep(state.activePage.content);

  const updatedImageList = filter(
    get(content, path, []),
    (_image, index) => index !== imageIndex
  );

  set(content, path, updatedImageList);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const reorderBannerRowImages = (state, { path, source, destination }) => {
  const content = cloneDeep(state.activePage.content);

  const imageList = get(content, path, []);

  [imageList[destination], imageList[source]] = [
    imageList[source],
    imageList[destination]
  ];

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleItemsPerGroupChange = (state, { path, itemsPerGroup }) => {
  const content = cloneDeep(state.activePage.content);
  const imageList = get(content, [...path, "imageList"]);
  let newImageListCount = imageList.length;

  while (newImageListCount % itemsPerGroup !== 0) {
    newImageListCount++;
  }

  set(content, [...path, "itemsPerGroup"], itemsPerGroup);
  set(
    content,
    [...path, "imageList"],
    [
      ...imageList,
      ...Array.from(Array(newImageListCount - imageList.length), () => {
        return initialImage();
      })
    ]
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateRowTypeCustomData = (state, { path, index, name, value }) => {
  const content = cloneDeep(state.activePage.content);

  const customField = get(content, [...path, "custom"], []).find(
    (_custom, _index) => _index === index
  );

  set(customField, name, value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const changeBannerImageHoverStyle = (state, { key, value, path }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, "styles", "hoverValues", key], value);

  let formattedValue;

  switch (key) {
    case "transform": {
      formattedValue = `scale(${value})`;
      break;
    }
    case "transition": {
      formattedValue = `transform ${value}s`;
      break;
    }

    default: {
      formattedValue = value;
    }
  }

  set(content, [...path, "styles", "hover", key], formattedValue);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateBannerRowImageData = (state, { path, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, path, value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleCountdownDataChange = (state, { path, name, value }) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, name], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateDefaultTextChange = (
  state,
  { name, value, position, language }
) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [position, name, language], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const handleProductCarouselDataChange = (
  state,
  { path, value, language, name }
) => {
  const content = cloneDeep(state.activePage.content);

  set(content, [...path, language, name], value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const addRowTypeCustomField = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);
  set(
    content,
    [...path, "custom"],
    [...get(content, [...path, "custom"], []), { key: "", value: "" }]
  );
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateRowTypeCustomModuleField = (state, { path, value }) => {
  const content = cloneDeep(state.activePage.content);
  set(
    content,
    [...path, "custom"],
    [...value]
  );
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const removeRowTypeCustomField = (state, { path, index }) => {
  const content = cloneDeep(state.activePage.content);

  set(
    content,
    [...path, "custom"],
    get(content, [...path, "custom"], []).filter(
      (_custom, _index) => _index !== index
    )
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const applyGeneralSpacing = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);

  const row = get(content, path);

  row.imageList.forEach(
    item => (item.styles = { ...item.styles, ...row.generalPadding })
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const toggleReset = (state, { value }) => ({
  ...state,
  resetToggled: value
});

const resetSectionsContent = state => ({
  ...state,
  activePage: cloneDeep(state.initialActiveSection)
});

const addRowTypeAccordionField = (state, { path, language }) => {
  const content = cloneDeep(state.activePage.content);
  set(
    content,
    [...path, "accordion", language],
    [...get(content, [...path, "accordion", language], []), { key: "", value: "" }]
  );
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const updateRowTypeAccordionData = (state, { path, index, name, value, language }) => {
  const content = cloneDeep(state.activePage.content);

  const accordionField = get(content, [...path, "accordion", language], []).find(
    (_accordion, _index) => _index === index
  );

  set(accordionField, name, value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const updateRowTypeAccordionHeaderData = (state, { path, name, value, language }) => {
  const content = cloneDeep(state.activePage.content);
  set(
    content,
    [...path, "accordion", "headers", language, name],
    value
  );
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const removeRowTypeAccordionField = (state, { path, index, language }) => {
  const content = cloneDeep(state.activePage.content);

  set(
    content,
    [...path, "accordion", language],
    get(content, [...path, "accordion", language], []).filter(
      (_accordion, _index) => _index !== index
    )
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const addRowTypeBulletField = (state, { path, language }) => {
  const content = cloneDeep(state.activePage.content);
  set(
    content,
    [...path, "bullet", language],
    [...get(content, [...path, "bullet", language], []), { key: "", value: "" }]
  );
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const updateRowTypeBulletData = (state, { path, index, name, value, language }) => {
  const content = cloneDeep(state.activePage.content);

  const bulletField = get(content, [...path, "bullet", language], []).find(
    (_bullet, _index) => _index === index
  );

  set(bulletField, name, value);

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const updateRowTypeBulletHeaderData = (state, { path, name, value, language }) => {
  const content = cloneDeep(state.activePage.content);
  set(
    content,
    [...path, "bullet", "headers", language, name],
    value
  );
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const removeRowTypeBulletField = (state, { path, index, language }) => {
  const content = cloneDeep(state.activePage.content);

  set(
    content,
    [...path, "bullet", language],
    get(content, [...path, "bullet", language], []).filter(
      (_bullet, _index) => _index !== index
    )
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const addRowTypeColumnField = (state, { path }) => {
  const content = cloneDeep(state.activePage.content);
  const columnLength = get(content, [...path, "columns"], []).length;
  let row = { name: `Column-${columnLength + 1}`, widthType: "percentage" }
  set(
    content,
    [...path, "columns"],
    [...get(content, [...path, "columns"], []),
      row
    ]
  );
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const removeRowTypeColumnField = (state, { path, index }) => {
  const content = cloneDeep(state.activePage.content);

  set(
    content,
    [...path, "columns"],
    get(content, [...path, "columns"], []).filter(
      (_column, _index) => _index !== index
    )
  );

  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const dragAndDropRowTypeColumnField = (state, { path, sourceIndex, destinationIndex }) => {
  const content = cloneDeep(state.activePage.content);
  const columns = arrayReorder(
    get(content, [...path, "columns"], []),
    sourceIndex,
    destinationIndex
  );
  set(content, [...path, "columns"], columns);
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
}

const addRowTypeColumnMenu = (state, { path, index, value }) => {
  const content = cloneDeep(state.activePage.content);
  const menus = get(content, [...path, "columns", index, "menu"], []);
  set(
    content,
    [...path, "columns", index, "menu"],
    [...menus, { ...value }]
  );
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const updateRowTypeColumnMenu = (state, { path, index, value, key }) => {
  const content = cloneDeep(state.activePage.content);
  set(
    content,
    [...path, "columns", index, key],
    value
  );
  if (key === "columnType" &&
    value === bannerTypeValueConstants.CUSTOM &&
    !get(content, [...path, "columns", index, "custom"], []).length) {
    set(
      content,
      [...path, "columns", index, "custom"],
      [{ key: "moduleName", value: customModuleNameConstants.STORYLY }]
    );
  }
  return {
    ...state,
    activePage: {
      ...state.activePage,
      content
    }
  };
};

const setSectionAllRollbackHistory = (state, action) => {
  return {
    ...state,
    rollbackHistory: action?.payload?.data || []
  };
};

const sectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SECTIONS_ACTIVE_PAGE:
      return setActiveSectionsPage(state, action);
    case ADD_NEW_SECTIONS_COMPONENT:
      return addNewComponent(state, action);
    case SECTIONS_PAGE_OPEN_COMPONENT:
      return openComponent(state, action);
    case SECTIONS_PAGE_TOGGLE_COMPONENT:
      return toggleSection(state, action);
    case MODULE_DATA_CHANGE_SECTIONS_PAGE:
      return handleModuleDataChange(state, action);
    case GENERATE_IMAGES_SECTIONS_PAGE:
      return generateRows(state, action);
    case UPDATE_BANNER_SECTION_DATA_STYLES_SECTIONS_PAGE:
      return updateBannerSectionDataStyles(state, action);
    case PUT_BANNER_DATA_STYLES_SECTIONS_PAGE:
      return putBannerDataStyles(state, action);
    case CLONE_MOBILE_SECTIONS_PAGE:
      return cloneDynamicTabRow(state, action);
    case UPDATE_LIST_DATA_SECTIONS_PAGE:
      return updateListData(state, action);
    case CLONE_ROW_SECTIONS_PAGE:
      return cloneDynamicRow(state, action);
    case DELETE_LIST_ITEM_SECTIONS_PAGE:
      return deleteListItem(state, action);
    case TOGGLE_LIST_ITEM_SECTIONS_PAGE:
      return toggleListItem(state, action);
    case UPDATE_BANNER_SECTION_DATA_SECTIONS_PAGE:
      return updateBannerSection(state, action);
    case ADD_BANNER_ROW_ITEM_SECTIONS_PAGE:
      return addBannerRowItem(state, action);
    case ADD_BANNER_SLIDER_ROW_IMAGES_SECTIONS_PAGE:
      return addBannerCarouselRowImages(state, action);
    case ADD_BANNER_SCHEDULER_SECTIONS_PAGE:
      return addSchedulerToBannerRow(state, action);
    case HANDLE_DATA_CHANGE_BANNER_SCHEDULER_SECTIONS_PAGE:
      return handleDataChangeBannerScheduler(state, action);
    case HANDLE_ROW_SCHEDULER_TOGGLE_SECTIONS_PAGE:
      return handleRowSchedulerToggle(state, action);
    case HANDLE_DELETE_BANNER_SCHEDULER_SECTIONS_PAGE:
      return handleDeleteBannerScheduler(state, action);
    case COPY_CONTENT_TO_SCHEDULER_SECTIONS_PAGE:
      return copyContentToScheduler(state, action);
    case CLONE_SCHEDULER_SECTIONS_PAGE:
      return cloneDynamicScheduler(state, action);
    case SET_SECTIONS_PAGE_DATA:
      return setPageData(state, action);
    case SET_SECTIONS_MODULES:
      return setModulePages(state, action);
    case SET_MODULE_SECTIONS_PAGE:
      return setSectionPages(state, action);
    case UPDATE_MODULE_SECTIONS_PAGE:
      return updateSectionPages(state, action);
    case UPDATE_MODULE_PAGE:
      return updateModulePages(state, action);
    case CLEAR_BANNER_ROW_IMAGE_DATA_SECTIONS_PAGE:
      return clearBannerRowImageData(state, action);
    case CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_SECTIONS_PAGE:
      return clearBannerSchedulerRowImageData(state, action);
    case REORDER_BANNER_ROW_IMAGES_SECTIONS_PAGE:
      return reorderBannerRowImages(state, action);
    case ITEMS_PER_GROUP_CHANGE_SECTIONS_PAGE:
      return handleItemsPerGroupChange(state, action);
    case UPDATE_ROW_TYPE_CUSTOM_DATA_SECTIONS_PAGE:
      return updateRowTypeCustomData(state, action);
    case DELETE_ROW_IMAGE_SECTIONS_PAGE:
      return deleteRowImage(state, action);
    case CHANGE_BANNER_IMAGE_HOVER_STYLE_SECTIONS_PAGE:
      return changeBannerImageHoverStyle(state, action);
    case UPDATE_BANNER_ROW_IMAGE_DATA_SECTIONS_PAGE:
      return updateBannerRowImageData(state, action);
    case COUNTDOWN_DATA_CHANGE_SECTIONS_PAGE:
      return handleCountdownDataChange(state, action);
    case UPDATE_BANNER_DEFAULT_TEXT_SECTIONS_PAGE:
      return updateDefaultTextChange(state, action);
    case PRODUCT_CAROUSEL_DATA_CHANGE_SECTIONS_PAGE:
      return handleProductCarouselDataChange(state, action);
    case ADD_ROW_TYPE_CUSTOM_FIELD_SECTIONS_PAGE:
      return addRowTypeCustomField(state, action);
    case UPDATE_ROW_TYPE_CUSTOM_MODULE_SECTIONS_PAGE:
      return updateRowTypeCustomModuleField(state, action);
    case REMOVE_ROW_TYPE_CUSTOM_FIELD_SECTIONS_PAGE:
      return removeRowTypeCustomField(state, action);
    case APPLY_GENERAL_SPACING_SECTIONS_PAGE:
      return applyGeneralSpacing(state, action);
    case TOGGLE_RESET_SECTIONS_PAGE:
      return toggleReset(state, action);
    case RESET_SECTIONS_CONTENT:
      return resetSectionsContent(state, action);
    case ADD_ROW_TYPE_ACCORDION_FIELD_SECTIONS_PAGE:
      return addRowTypeAccordionField(state, action);
    case UPDATE_ROW_TYPE_ACCORDION_DATA_SECTIONS_PAGE:
      return updateRowTypeAccordionData(state, action);
    case UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_SECTIONS_PAGE:
      return updateRowTypeAccordionHeaderData(state, action);
    case REMOVE_ROW_TYPE_ACCORDION_FIELD_SECTIONS_PAGE:
      return removeRowTypeAccordionField(state, action);
    case ADD_ROW_TYPE_BULLET_FIELD_SECTIONS_PAGE:
      return addRowTypeBulletField(state, action);
    case UPDATE_ROW_TYPE_BULLET_DATA_SECTIONS_PAGE:
      return updateRowTypeBulletData(state, action);
    case UPDATE_ROW_TYPE_BULLET_HEADER_DATA_SECTIONS_PAGE:
      return updateRowTypeBulletHeaderData(state, action);
    case REMOVE_ROW_TYPE_BULLET_FIELD_SECTIONS_PAGE:
      return removeRowTypeBulletField(state, action);
    case ADD_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE:
      return addRowTypeColumnField(state, action);
    case REMOVE_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE:
      return removeRowTypeColumnField(state, action);
    case DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE:
      return dragAndDropRowTypeColumnField(state, action);
    case ADD_ROW_TYPE_COLUMN_MENU_SECTIONS_PAGE:
      return addRowTypeColumnMenu(state, action);
    case UPDATE_ROW_TYPE_COLUMN_MENU_SECTIONS_PAGE:
      return updateRowTypeColumnMenu(state, action);
    case SET_SECTIONS_ROLLBACK_HISTORY:
      return setSectionAllRollbackHistory(state, action);
    default:
      return state;
  }
};

export default sectionsReducer;
