import React, { Fragment } from "react";
import { connect } from "react-redux";
import { SliderForm } from "./SliderForm";
import { LANGUAGE } from "../../constants";
import Accordion from "../../component/accordion";

const SliderContentAccordion = ({
  handleDelete,
  handleToggleChange,
  slider,
  keyIndex,
  handleTextChange,
  handleRedirectionLinkChange
}) => {
  return (
    <Fragment>
      <Accordion
        className="slider-image-list"
        title={`Slider ${keyIndex + 1}`}
        isToggled={slider.enable}
        handleToggle={() => handleToggleChange(slider.enable)}
        handleDelete={handleDelete}
      >
        <SliderForm
          handleRedirectionLinkChange={handleRedirectionLinkChange}
          handleTextChange={handleTextChange}
          item={slider}
          language={LANGUAGE.ENGLISH}
        />
        <SliderForm
          handleRedirectionLinkChange={handleRedirectionLinkChange}
          handleTextChange={handleTextChange}
          item={slider}
          language={LANGUAGE.ARABIC}
        />
      </Accordion>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  countryId: state.store.countryId,
  storeId: state.store.brandId || state.store.storeId
});

export default connect(mapStateToProps)(SliderContentAccordion);
