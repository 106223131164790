import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import filter from "lodash/filter";
import { generateUUID, arrayReorder } from "../util";
import {
  initialDynamicBannerRow, bannerGradientTypes, initialImage,
  bannerDesktopBackgroundOptions,
  bannerTypeValueConstants,
  dynamicBannerItems,
  customModuleNameConstants,
  rowWidthValues,
  SCREEN_TYPES,
  tabBannerTypeNavigationType,
} from "../constants";
import {
  CLEAR_FOOTER_CONTENT,
  CREATE_FOOTER_TREE_RECORDS_INITIAL,
  DELETE_FOOTER_ATTRIBUTE,
  GET_FOOTER_DATA,
  HANDLE_ADD_FOOTER_SOCIAL_MEDIA_LINK,
  HANDLE_FOOTER_EDIT_ATTRIBUTE,
  HANDLE_FOOTER_STATIC_TEXT,
  HANDLE_FOOTER_TEXT_CHANGE,
  HANDLE_REMOVE_FOOTER_SOCIAL_MEDIA_LINK,
  HANDLE_UPDATE_FOOTER_SOCIAL_MEDIA_LINK,
  SAVE_FOOTER_CONTENT,
  UPDATE_FOOTER_ON_TREE_CHANGE,
  UPDATE_TREE_DATA,
  FOOTER_PAGE_OPEN_COMPONENT,
  FOOTER_PAGE_TOGGLE_COMPONENT,
  GENERATE_IMAGES_FOOTER_PAGE,
  PUT_BANNER_DATA_STYLES_FOOTER_PAGE,
  UPDATE_BANNER_SECTION_DATA_STYLES_FOOTER_PAGE,
  TOGGLE_LIST_ITEM_FOOTER_PAGE,
  CLONE_ROW_FOOTER_PAGE,
  UPDATE_BANNER_SECTION_DATA_FOOTER_PAGE,
  DELETE_LIST_ITEM_FOOTER_PAGE,
  CLEAR_BANNER_ROW_IMAGE_DATA_FOOTER_PAGE,
  UPDATE_LIST_DATA_FOOTER_PAGE,
  ITEMS_PER_GROUP_CHANGE_FOOTER_PAGE,
  APPLY_GENERAL_SPACING_FOOTER_PAGE,
  ADD_ROW_TYPE_CUSTOM_FIELD_FOOTER_PAGE,
  UPDATE_ROW_TYPE_CUSTOM_MODULE_FOOTER_PAGE,
  ADD_ROW_TYPE_ACCORDION_FIELD_FOOTER_PAGE,
  UPDATE_ROW_TYPE_ACCORDION_DATA_FOOTER_PAGE,
  UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_FOOTER_PAGE,
  REMOVE_ROW_TYPE_ACCORDION_FIELD_FOOTER_PAGE,
  ADD_ROW_TYPE_BULLET_FIELD_FOOTER_PAGE,
  UPDATE_ROW_TYPE_BULLET_DATA_FOOTER_PAGE,
  UPDATE_ROW_TYPE_BULLET_HEADER_DATA_FOOTER_PAGE,
  REMOVE_ROW_TYPE_BULLET_FIELD_FOOTER_PAGE,
  ADD_ROW_TYPE_TAB_FIELD_FOOTER_PAGE,
  UPDATE_ROW_TYPE_TAB_DATA_FOOTER_PAGE,
  UPDATE_ROW_TYPE_TABS_FOOTER_PAGE,
  REMOVE_ROW_TYPE_TAB_FOOTER_PAGE,
  ADD_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE,
  REMOVE_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE,
  DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE,
  ADD_ROW_TYPE_COLUMN_MENU_FOOTER_PAGE,
  UPDATE_ROW_TYPE_COLUMN_MENU_FOOTER_PAGE,
  PRODUCT_CAROUSEL_DATA_CHANGE_FOOTER_PAGE,
  UPDATE_ROW_TYPE_CUSTOM_DATA_FOOTER_PAGE,
  REMOVE_ROW_TYPE_CUSTOM_FIELD_FOOTER_PAGE,
  ADD_BANNER_SLIDER_ROW_IMAGES_FOOTER_PAGE,
  DELETE_ROW_IMAGE_FOOTER_PAGE,
  UPDATE_BANNER_ROW_IMAGE_DATA_FOOTER_PAGE,
  CHANGE_BANNER_IMAGE_HOVER_STYLE_FOOTER_PAGE,



  UPDATE_BANNER_DEFAULT_TEXT_FOOTER_PAGE,
  COUNTDOWN_DATA_CHANGE_FOOTER_PAGE,
  REORDER_BANNER_ROW_IMAGES_FOOTER_PAGE,
  CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_FOOTER_PAGE,
  CLONE_SCHEDULER_FOOTER_PAGE,
  COPY_CONTENT_TO_SCHEDULER_FOOTER_PAGE,
  HANDLE_DELETE_BANNER_SCHEDULER_FOOTER_PAGE,
  HANDLE_DATA_CHANGE_BANNER_SCHEDULER_FOOTER_PAGE,
  ADD_BANNER_SCHEDULER_FOOTER_PAGE,
  ADD_BANNER_ROW_ITEM_FOOTER_PAGE,
  CLONE_MOBILE_FOOTER_PAGE,
  MODULE_DATA_CHANGE_FOOTER_PAGE,
} from "../constants/actions";
import {
  mapTreeData,
  mapUpdatedSortableTreeNodeToContent
} from "../util/reducer";

const createSocialMediaLinkObject = () => ({
  id: generateUUID(),
  img: "",
  redirectLink: ""
});

const initialState = {
  english: [],
  arabic: [],
  updateEnglishList: [],
  updateArabicList: [],
  updateEnglishContent: {
    title: "",
    redirectionLink: "",
    bold: true,
    color: {},
    isPublish: false,
    children: []
  },
  updateArabicContent: {
    title: "",
    redirectionLink: "",
    bold: true,
    color: {},
    isPublish: false,
    children: []
  },
  treeData: [],
  tabChanged: true,
  isEdit: false,
  enStaticContent: {},
  arStaticContent: {},
  updateEnStaticContent: {
    socialMediaLinks: [createSocialMediaLinkObject()]
  },
  updateArStaticContent: {
    socialMediaLinks: [createSocialMediaLinkObject()]
  },
  menuItemContent: [],
  updateMenuItemContent: []
};

const getFooterData = (state, { result }) => {
  return {
    ...state,
    english: result.english || [],
    arabic: result.arabic || [],
    updateEnglishList: result.english || [],
    updateArabicList: result.arabic || [],
    enStaticContent: result.enStaticContent || {},
    arStaticContent: result.arStaticContent || {},
    updateEnStaticContent: {
      ...state.updateEnStaticContent,
      ...(result.enStaticContent || {})
    },
    updateArStaticContent: {
      ...state.updateArStaticContent,
      ...(result.arStaticContent || {})
    },
    isPublished: result.isPublished || false,
    menuItemContent: result.content,
    updateMenuItemContent: result.content,
  };
};

const clearFooterContent = state => ({
  ...state,
  updateEnglishContent: { ...initialState.updateEnglishContent },
  updateArabicContent: { ...initialState.updateArabicContent },
  isEdit: initialState.isEdit
});

const saveFooterContent = (
  state,
  { updateEnglishContent, updateArabicContent }
) => {
  if (state.isEdit) {
    const { updateEnglishList, updateArabicList } = state;
    const mappedUpdateEnglishList = mapUpdatedSortableTreeNodeToContent(
      updateEnglishContent,
      updateEnglishList
    );
    const mappedUpdateArabicList = mapUpdatedSortableTreeNodeToContent(
      updateArabicContent,
      updateArabicList
    );
    const treeData = mapTreeData(
      mappedUpdateEnglishList,
      mappedUpdateArabicList
    );
    return {
      ...state,
      updateEnglishList: mappedUpdateEnglishList,
      updateArabicList: mappedUpdateArabicList,
      treeData
    };
  } else {
    const uid = generateUUID();
    const data = {
      title: `${updateEnglishContent.title} / ${updateArabicContent.title}`,
      englishData: { ...updateEnglishContent, uid },
      arabicData: { ...updateArabicContent, uid },
      expanded: true,
      children: []
    };

    return { ...state, treeData: [...state.treeData, data] };
  }
};

const handleFooterTxtChange = (state, { language, key, value }) => {
  if (language === "english") {
    return {
      ...state,
      updateEnglishContent: { ...state.updateEnglishContent, [key]: value }
    };
  } else {
    return {
      ...state,
      updateArabicContent: { ...state.updateArabicContent, [key]: value }
    };
  }
};
const updateTreeData = (state, { data }) => ({
  ...state,
  treeData: cloneDeep(data)
});

const updateFooterOnTreeChange = (state, { treeData }) => {
  let updateEnglishList = [];
  let updateArabicList = [];
  if (treeData && treeData.length) {
    for (let i = 0; i < treeData.length; i++) {
      //check if children exists
      let childrenEnglish = [];
      let childrenArabic = [];
      for (let j = 0; j < treeData[i].children.length; j++) {
        childrenEnglish.push({
          ...treeData[i].children[j].englishData
        });
        childrenArabic.push({
          ...treeData[i].children[j].arabicData
        });
      }
      updateArabicList.push({
        ...treeData[i].arabicData,
        children: childrenArabic
      });

      updateEnglishList.push({
        ...treeData[i].englishData,
        children: childrenEnglish
      });
    }
  }

  return {
    ...state,
    updateEnglishList: cloneDeep(updateEnglishList),
    updateArabicList: cloneDeep(updateArabicList)
  };
};

const handleFooterEditAttribute = (state, { englishData, arabicData }) => {
  return {
    ...state,
    updateEnglishContent: cloneDeep(englishData),
    updateArabicContent: cloneDeep(arabicData),
    isEdit: true
  };
};

const createFooterTreeRecordInitial = state => {
  let _treeData = [];
  let _updateEnglishList = cloneDeep(state.updateEnglishList);
  let _updateArabicList = cloneDeep(state.updateArabicList);
  if (!isEmpty(_updateEnglishList) && !isEmpty(_updateArabicList)) {
    for (let i = 0; i < _updateEnglishList.length; i++) {
      //check if children exists
      let children = [];
      if (_updateEnglishList[i].children) {
        for (let j = 0; j < _updateEnglishList[i].children.length; j++) {
          children.push({
            children: [],
            expanded: true,
            title: `${_updateEnglishList[i].children[j].title}/ ${_updateArabicList[i].children[j].title}`,
            englishData: _updateEnglishList[i].children[j],
            arabicData: _updateArabicList[i].children[j]
          });
        }
      }

      _treeData.push({
        children: children,
        expanded: true,
        title: `${_updateEnglishList[i].title}/ ${_updateArabicList[i].title}`,
        englishData: _updateEnglishList[i],
        arabicData: _updateArabicList[i]
      });
    }
  }
  return {
    ...state,
    treeData: _treeData
  };
};

const deleteFooterAttribute = (state, { selectedAttributeData }) => {
  let ithPosition = 0;
  let jthPosition = 0;
  let isChildNode = false;
  let currentPos = 0;
  let _treeData = cloneDeep(state.treeData);

  for (let i = 0; i < _treeData.length; i++) {
    ithPosition = i;
    if (currentPos === selectedAttributeData.treeIndex) {
      break;
    }
    for (let j = 0; j < _treeData[i].children.length; j++) {
      jthPosition = j;
      currentPos += 1;
      if (currentPos === selectedAttributeData.treeIndex) {
        isChildNode = true;
        break;
      }
    }

    if (currentPos === selectedAttributeData.treeIndex) {
      break;
    }
    currentPos += 1;
  }

  if (isChildNode) {
    _treeData[ithPosition].children.splice(jthPosition, 1);
    _treeData[ithPosition].children = [..._treeData[ithPosition].children];
  } else {
    _treeData.splice(ithPosition, 1);
    _treeData = [..._treeData];
  }
  return { ...state, treeData: [..._treeData] };
};

const handleFooterTextChange = (state, { name, value, keyType }) => ({
  ...state,
  [keyType]: { ...state[keyType], [name]: value }
});

const handleAddFooterSocialMediaLink = (state, { keyType }) => ({
  ...state,
  [keyType]: {
    ...state[keyType],
    socialMediaLinks: [
      ...state[keyType].socialMediaLinks,
      createSocialMediaLinkObject()
    ]
  }
});

const handleRemoveFooterSocialMediaLink = (state, { index, keyType }) => ({
  ...state,
  [keyType]: {
    ...state[keyType],
    socialMediaLinks: state[keyType].socialMediaLinks.filter(
      (sml, smlIndex) => smlIndex !== index
    )
  }
});

const handleUpdateFooterSocialMediaLink = (state, action) => {
  const { name, value, index, keyType } = action;
  return {
    ...state,
    [keyType]: {
      ...state[keyType],
      socialMediaLinks: state[keyType].socialMediaLinks.map((sml, smlIndex) => {
        if (index === smlIndex) {
          return { ...sml, [name]: value };
        }
        return sml;
      })
    }
  };
};
const openComponent = (state, { id, index }) => {
  const menuItemContent = cloneDeep(state.updateMenuItemContent);

  menuItemContent.map((item, itemIndex) => {
    if (id ? item.id === id : itemIndex === index) {
      item.openAccordion = !item.openAccordion;
    } else {
      item.openAccordion = false;
    }
  });

  return {
    ...state,
    updateMenuItemContent: menuItemContent
  };
};

const toggleSection = (state, { index }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const contentModule = content.find(
    (item, itemIndex) => itemIndex === index
  );
  contentModule.enable = !contentModule.enable;
  return {
    ...state,
    updateMenuItemContent: content
  };
};

const generateRows = (state, { path, numberOfRows }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const previousRows = get(content, path, []);

  const newArrayLength =
    !isEmpty(previousRows) && numberOfRows > previousRows.length
      ? numberOfRows - previousRows.length
      : numberOfRows;

  const emptyRows = Array.from(Array(newArrayLength), () => {
    return initialDynamicBannerRow();
  });

  const rowsWithData =
    numberOfRows > previousRows.length
      ? [...previousRows, ...emptyRows]
      : previousRows.slice(0, numberOfRows);

  set(content, path, rowsWithData);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const putBannerDataStyles = (state, { value, path }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, [...path, "styles"], value);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const updateBannerSectionDataStyles = (state, { path, name, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const styles = get(content, [...path, "styles"], {});

  if (["backgroundImage", "backgroundColor"].includes(name)) {
    delete styles.backgroundImage;
    delete styles.backgroundColor;
  }

  set(styles, [name], value);

  if (
    [
      "primaryBackgroundColor",
      "secondaryBackgroundColor",
      "gradientType",
      "primaryLocation",
      "secondaryLocation",
      "gradientAngle"
    ].includes(name)
  ) {
    set(
      styles,
      "backgroundGradient",
      `${styles.gradientType}(${styles.gradientType === bannerGradientTypes[0].value
        ? `${styles.gradientAngle}deg`
        : "circle"
      }, ${styles.primaryBackgroundColor} ${styles.primaryLocation}%, ${styles.secondaryBackgroundColor
      } ${styles.secondaryLocation}%)`
    );
  }

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const toggleListItem = (state, { path }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const isEnabled = get(content, [...path, "enable"]);
  set(content, [...path, "enable"], !isEnabled);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const cloneDynamicRow = (
  state,
  { path: [contentIndex, deviceType, rows], rowIndex, moduleId }
) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const targetModule = content.find(module => module.id === moduleId);

  const copiedRow = cloneDeep(
    get(content, [contentIndex, deviceType, rows, rowIndex])
  );

  copiedRow.id = generateUUID();

  set(
    targetModule,
    [deviceType, rows],
    [...get(targetModule, [deviceType, rows]), copiedRow]
  );

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const updateBannerSection = (state, { path, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, path, value);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const deleteListItem = (state, { path, listIndex }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const updatedHomepageListData = filter(
    get(content, path, []),
    (_listItem, index) => index !== listIndex
  );
  set(content, path, updatedHomepageListData);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const clearBannerRowImageData = (state, { path }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, [...path, "imageList"], []);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const updateListData = (state, { path, name, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, [...path, name], value);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const handleItemsPerGroupChange = (state, { path, itemsPerGroup }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  const imageList = get(content, [...path, "imageList"]);
  let newImageListCount = imageList.length;

  while (newImageListCount % itemsPerGroup !== 0) {
    newImageListCount++;
  }

  set(content, [...path, "itemsPerGroup"], itemsPerGroup);
  set(
    content,
    [...path, "imageList"],
    [
      ...imageList,
      ...Array.from(Array(newImageListCount - imageList.length), () => {
        return initialImage();
      })
    ]
  );

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const applyGeneralSpacing = (state, { path }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const row = get(content, path);

  row.imageList.forEach(
    item => (item.styles = { ...item.styles, ...row.generalPadding })
  );

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const addRowTypeCustomField = (state, { path }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  set(
    content,
    [...path, "custom"],
    [...get(content, [...path, "custom"], []), { key: "", value: "" }]
  );
  return {
    ...state,
    updateMenuItemContent: content
  };
};

const updateRowTypeCustomModuleField = (state, { path, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  set(
    content,
    [...path, "custom"],
    [...value]
  );
  return {
    ...state,
    updateMenuItemContent: content
  };
};

const addRowTypeAccordionField = (state, { path, language }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  set(
    content,
    [...path, "accordion", language],
    [...get(content, [...path, "accordion", language], []), { key: "", value: "" }]
  );
  return {
    ...state,
    updateMenuItemContent: content
  };
}

const updateRowTypeAccordionData = (state, { path, index, name, value, language }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  const accordionField = get(content, [...path, "accordion", language], []).find(
    (_accordion, _index) => _index === index
  );

  set(accordionField, name, value);

  return {
    ...state,
    updateMenuItemContent: content
  };
}

const updateRowTypeAccordionHeaderData = (state, { path, name, value, language }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  set(
    content,
    [...path, "accordion", "headers", language, name],
    value
  );
  return {
    ...state,
    updateMenuItemContent: content
  };
}

const removeRowTypeAccordionField = (state, { path, index, language }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(
    content,
    [...path, "accordion", language],
    get(content, [...path, "accordion", language], []).filter(
      (_accordion, _index) => _index !== index
    )
  );

  return {
    ...state,
    updateMenuItemContent: content
  };
}

const addRowTypeBulletField = (state, { path, language }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  set(
    content,
    [...path, "bullet", language],
    [...get(content, [...path, "bullet", language], []), { key: "", value: "" }]
  );
  return {
    ...state,
    updateMenuItemContent: content
  };
}
const updateRowTypeBulletData = (state, { path, index, name, value, language }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  const bulletField = get(content, [...path, "bullet", language], []).find(
    (_bullet, _index) => _index === index
  );

  set(bulletField, name, value);
  return {
    ...state,
    updateMenuItemContent: content
  };
}

const updateRowTypeBulletHeaderData = (state, { path, name, value, language }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  set(
    content,
    [...path, "bullet", "headers", language, name],
    value
  );
  return {
    ...state,
    updateMenuItemContent: content
  };
}

const removeRowTypeBulletField = (state, { path, index, language }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(
    content,
    [...path, "bullet", language],
    get(content, [...path, "bullet", language], []).filter(
      (_bullet, _index) => _index !== index
    )
  );

  return {
    ...state,
    updateMenuItemContent: content
  };
}

const addRowTypeTabField = (state, { path }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  const tabLength = get(content, [...path, "tab"], []).length;
  let row = {
    isDefault: false,
    english: {
      title: "", imageURL: "", imagePosition: "left", redirectionUrl: "", contentSource: "", navigationType: "", sectionSlug: "", catalogUrl: ""
    },
    arabic: {
      title: "", imageURL: "", imagePosition: "left", redirectionUrl: "", contentSource: "", navigationType: "", sectionSlug: "", catalogUrl: ""
    }
  }

  if (!tabLength) {
    row.isDefault = true;
    row.english.navigationType = tabBannerTypeNavigationType.INLINE;
    row.arabic.navigationType = tabBannerTypeNavigationType.INLINE;
  }

  set(
    content,
    [...path, "tab"],
    [...get(content, [...path, "tab"], []),
      row
    ]
  );
  return {
    ...state,
    updateMenuItemContent: content
  };
};

const updateRowTypeTabData = (state, { path, index, name, value, language }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  const tab = get(content, [...path, "tab"], []).find(
    (_tab, _index) => _index === index
  );

  set(tab, language ? [language, name] : [name], value);
  return {
    ...state,
    updateMenuItemContent: content
  };
}

const updateRowTypeTabs = (state, { path, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  set(content, [...path, "tab"], value);
  return {
    ...state,
    updateMenuItemContent: content
  };
}


const removeRowTypeTab = (state, { path, index }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(
    content,
    [...path, "tab"],
    get(content, [...path, "tab"], []).filter(
      (_tab, _index) => _index !== index
    )
  );

  return {
    ...state,
    updateMenuItemContent: content
  };
}

const addRowTypeColumnField = (state, { path }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  const columnLength = get(content, [...path, "columns"], []).length;
  let row = { name: `Column-${columnLength + 1}`, widthType: "percentage" }
  set(
    content,
    [...path, "columns"],
    [...get(content, [...path, "columns"], []),
      row
    ]
  );
  return {
    ...state,
    updateMenuItemContent: content
  };
};

const removeRowTypeColumnField = (state, { path, index }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(
    content,
    [...path, "columns"],
    get(content, [...path, "columns"], []).filter(
      (_column, _index) => _index !== index
    )
  );

  return {
    ...state,
    updateMenuItemContent: content
  };
}

const dragAndDropRowTypeColumnField = (state, { path, sourceIndex, destinationIndex }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  const columns = arrayReorder(
    get(content, [...path, "columns"], []),
    sourceIndex,
    destinationIndex
  );
  set(content, [...path, "columns"], columns);

  return {
    ...state,
    updateMenuItemContent: content
  };
}

const addRowTypeColumnMenu = (state, { path, index, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  const menus = get(content, [...path, "columns", index, "menu"], []);
  set(
    content,
    [...path, "columns", index, "menu"],
    [...menus, { ...value }]
  );
  return {
    ...state,
    updateMenuItemContent: content
  };
};

const updateRowTypeColumnMenu = (state, { path, index, value, key }) => {
  const content = cloneDeep(state.updateMenuItemContent);
  set(
    content,
    [...path, "columns", index, key],
    value
  );
  if (key === "columnType" &&
    value === bannerTypeValueConstants.CUSTOM &&
    !get(content, [...path, "columns", index, "custom"], []).length) {
    set(
      content,
      [...path, "columns", index, "custom"],
      [{ key: "moduleName", value: customModuleNameConstants.STORYLY }]
    );
  }
  return {
    ...state,
    updateMenuItemContent: content
  };
};

const handleProductCarouselDataChange = (
  state,
  { path, value, language, name }
) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, [...path, language, name], value);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const updateRowTypeCustomData = (state, { path, index, name, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const customField = get(content, [...path, "custom"], []).find(
    (_custom, _index) => _index === index
  );

  set(customField, name, value);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const removeRowTypeCustomField = (state, { path, index }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(
    content,
    [...path, "custom"],
    get(content, [...path, "custom"], []).filter(
      (_custom, _index) => _index !== index
    )
  );

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const addBannerCarouselRowImages = (state, { path, itemsPerGroup }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const imageList = get(content, path);

  let newImageListCount = imageList.length;

  const pathToRow = path.slice(0, path.length - 1);

  const row = get(content, pathToRow);

  const getImageObject = () => {
    const item = initialImage();

    item.styles = { ...item.styles, ...row.generalPadding };

    return item;
  };

  if (newImageListCount % itemsPerGroup !== 0) {
    while (newImageListCount % itemsPerGroup !== 0) {
      newImageListCount++;
    }

    set(content, path, [
      ...get(content, path, []),
      ...Array.from(Array(newImageListCount - imageList.length), getImageObject)
    ]);
  } else {
    set(content, path, [
      ...get(content, path, []),
      ...Array.from(Array(itemsPerGroup), getImageObject)
    ]);
  }

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const deleteRowImage = (state, { path, imageIndex }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const updatedImageList = filter(
    get(content, path, []),
    (_image, index) => index !== imageIndex
  );

  set(content, path, updatedImageList);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const updateBannerRowImageData = (state, { path, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, path, value);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const changeBannerImageHoverStyle = (state, { key, value, path }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, [...path, "styles", "hoverValues", key], value);

  let formattedValue;

  switch (key) {
    case "transform": {
      formattedValue = `scale(${value})`;
      break;
    }
    case "transition": {
      formattedValue = `transform ${value}s`;
      break;
    }

    default: {
      formattedValue = value;
    }
  }

  set(content, [...path, "styles", "hover", key], formattedValue);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const updateDefaultTextChange = (
  state,
  { name, value, position, language }
) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, [position, name, language], value);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const handleCountdownDataChange = (state, { path, name, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, [...path, name], value);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const reorderBannerRowImages = (state, { path, source, destination }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const imageList = get(content, path, []);

  [imageList[destination], imageList[source]] = [
    imageList[source],
    imageList[destination]
  ];

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const clearBannerSchedulerRowImageData = (state, { path }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, path, []);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const cloneDynamicScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const copiedScheduler = cloneDeep(get(content, [...path, schedulerIndex]));

  copiedScheduler.id = generateUUID();
  copiedScheduler.enabled = false;
  set(content, path, [...get(content, path), copiedScheduler]);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const copyContentToScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const row = get(content, path);

  const schedulers = get(row, "schedulers");

  const scheduler = get(schedulers, [schedulerIndex]);

  set(schedulers, schedulerIndex, {
    ...scheduler,
    ...row,
    id: scheduler.id,
    imageList: [
      ...scheduler.imageList.map(image => ({ ...image, id: generateUUID() })),
      ...cloneDeep(row).imageList.map(image => ({
        ...image,
        id: generateUUID()
      }))
    ]
  });

  delete schedulers[schedulerIndex].schedulers;

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const handleDeleteBannerScheduler = (state, { path, schedulerIndex }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const row = get(content, path, []);

  set(
    row,
    "schedulers",
    get(row, "schedulers", []).filter(
      (_item, index) => index !== schedulerIndex
    )
  );

  return {
    ...state,
    updateMenuItemContent: content
  };
};


const handleDataChangeBannerScheduler = (state, { path, key, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, [...path, key], value);

  const ONE_DAY_MS = 60000 * 1439;

  if (key === "from") {
    const toDate = get(content, [...path, "to"]);

    if (!toDate) set(content, [...path, "to"], value + ONE_DAY_MS);
  }

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const addSchedulerToBannerRow = (state, { path }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const row = get(content, path, {});

  set(row, "schedulers", [
    ...get(row, "schedulers", []),
    initialDynamicBannerRow()
  ]);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const addBannerRowItem = (state, { path, itemType }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const pathToRow = path.slice(0, path.length - 1);

  const row = get(content, pathToRow);

  const item = dynamicBannerItems[itemType]();

  item.styles = { ...item.styles, ...row.generalPadding };

  set(content, path, [...get(content, path, []), item]);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const cloneDynamicTabRow = (state, { path, tab }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  const row = get(content, path);
  const tabletData = get(row, "tablet");
  const tabData = get(row, tab.toLowerCase());

  const isDesktop = tab === SCREEN_TYPES[0].label;

  tabletData.rows = [
    ...tabData.rows.map(row => {
      const _row = cloneDeep(row);

      if (isDesktop && _row.bannerType === bannerTypeValueConstants.SLIDER) {
        _row.imageList = _row.imageList.map(image => ({
          ...image,
          english: { ...image.english, imageURL: null },
          arabic: { ...image.arabic, imageURL: null }
        }));
      }

      return {
        ..._row,
        width:
          isDesktop && _row.width === rowWidthValues.CUSTOM_WIDTH
            ? null
            : _row.width,
        id: generateUUID()
      };
    })
  ];
  tabletData.styles = {
    ...tabData.styles,
    backgroundType:
      isDesktop &&
        tabData.styles.backgroundType === bannerDesktopBackgroundOptions[2].value
        ? null
        : tabData.styles
          ? tabData.styles.backgroundType
          : null
  };
  tabletData.title = tabData.title;
  tabletData.schedulers = [...(tabData.schedulers || [])];

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const handleModuleDataChange = (state, { path, name, value }) => {
  const content = cloneDeep(state.updateMenuItemContent);

  set(content, [...path, name], value);

  return {
    ...state,
    updateMenuItemContent: content
  };
};

const footerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FOOTER_CONTENT:
      return clearFooterContent(state);
    case CREATE_FOOTER_TREE_RECORDS_INITIAL:
      return createFooterTreeRecordInitial(state, action);
    case DELETE_FOOTER_ATTRIBUTE:
      return deleteFooterAttribute(state, action);
    case GET_FOOTER_DATA:
      return getFooterData(state, action);
    case HANDLE_ADD_FOOTER_SOCIAL_MEDIA_LINK:
      return handleAddFooterSocialMediaLink(state, action);
    case HANDLE_FOOTER_EDIT_ATTRIBUTE:
      return handleFooterEditAttribute(state, action);
    case HANDLE_FOOTER_STATIC_TEXT:
      return handleFooterTextChange(state, action);
    case HANDLE_FOOTER_TEXT_CHANGE:
      return handleFooterTxtChange(state, action);
    case HANDLE_REMOVE_FOOTER_SOCIAL_MEDIA_LINK:
      return handleRemoveFooterSocialMediaLink(state, action);
    case HANDLE_UPDATE_FOOTER_SOCIAL_MEDIA_LINK:
      return handleUpdateFooterSocialMediaLink(state, action);
    case SAVE_FOOTER_CONTENT:
      return saveFooterContent(state, action);
    case UPDATE_FOOTER_ON_TREE_CHANGE:
      return updateFooterOnTreeChange(state, action);
    case UPDATE_TREE_DATA:
      return updateTreeData(state, action);
    case FOOTER_PAGE_OPEN_COMPONENT:
      return openComponent(state, action);
    case FOOTER_PAGE_TOGGLE_COMPONENT:
      return toggleSection(state, action);
    case GENERATE_IMAGES_FOOTER_PAGE:
      return generateRows(state, action);
    case PUT_BANNER_DATA_STYLES_FOOTER_PAGE:
      return putBannerDataStyles(state, action);
    case UPDATE_BANNER_SECTION_DATA_STYLES_FOOTER_PAGE:
      return updateBannerSectionDataStyles(state, action);
    case TOGGLE_LIST_ITEM_FOOTER_PAGE:
      return toggleListItem(state, action);
    case CLONE_ROW_FOOTER_PAGE:
      return cloneDynamicRow(state, action);
    case UPDATE_BANNER_SECTION_DATA_FOOTER_PAGE:
      return updateBannerSection(state, action);
    case DELETE_LIST_ITEM_FOOTER_PAGE:
      return deleteListItem(state, action);
    case CLEAR_BANNER_ROW_IMAGE_DATA_FOOTER_PAGE:
      return clearBannerRowImageData(state, action);
    case UPDATE_LIST_DATA_FOOTER_PAGE:
      return updateListData(state, action);
    case ITEMS_PER_GROUP_CHANGE_FOOTER_PAGE:
      return handleItemsPerGroupChange(state, action);
    case APPLY_GENERAL_SPACING_FOOTER_PAGE:
      return applyGeneralSpacing(state, action);
    case ADD_ROW_TYPE_CUSTOM_FIELD_FOOTER_PAGE:
      return addRowTypeCustomField(state, action);
    case UPDATE_ROW_TYPE_CUSTOM_MODULE_FOOTER_PAGE:
      return updateRowTypeCustomModuleField(state, action);
    case ADD_ROW_TYPE_ACCORDION_FIELD_FOOTER_PAGE:
      return addRowTypeAccordionField(state, action);
    case UPDATE_ROW_TYPE_ACCORDION_DATA_FOOTER_PAGE:
      return updateRowTypeAccordionData(state, action);
    case UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_FOOTER_PAGE:
      return updateRowTypeAccordionHeaderData(state, action);
    case REMOVE_ROW_TYPE_ACCORDION_FIELD_FOOTER_PAGE:
      return removeRowTypeAccordionField(state, action);
    case ADD_ROW_TYPE_BULLET_FIELD_FOOTER_PAGE:
      return addRowTypeBulletField(state, action);
    case UPDATE_ROW_TYPE_BULLET_DATA_FOOTER_PAGE:
      return updateRowTypeBulletData(state, action);
    case UPDATE_ROW_TYPE_BULLET_HEADER_DATA_FOOTER_PAGE:
      return updateRowTypeBulletHeaderData(state, action);
    case REMOVE_ROW_TYPE_BULLET_FIELD_FOOTER_PAGE:
      return removeRowTypeBulletField(state, action);
    case ADD_ROW_TYPE_TAB_FIELD_FOOTER_PAGE:
      return addRowTypeTabField(state, action);
    case UPDATE_ROW_TYPE_TAB_DATA_FOOTER_PAGE:
      return updateRowTypeTabData(state, action);
    case UPDATE_ROW_TYPE_TABS_FOOTER_PAGE:
      return updateRowTypeTabs(state, action);
    case REMOVE_ROW_TYPE_TAB_FOOTER_PAGE:
      return removeRowTypeTab(state, action);
    case ADD_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE:
      return addRowTypeColumnField(state, action);
    case REMOVE_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE:
      return removeRowTypeColumnField(state, action);
    case DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE:
      return dragAndDropRowTypeColumnField(state, action);
    case ADD_ROW_TYPE_COLUMN_MENU_FOOTER_PAGE:
      return addRowTypeColumnMenu(state, action);
    case UPDATE_ROW_TYPE_COLUMN_MENU_FOOTER_PAGE:
      return updateRowTypeColumnMenu(state, action);
    case PRODUCT_CAROUSEL_DATA_CHANGE_FOOTER_PAGE:
      return handleProductCarouselDataChange(state, action);
    case UPDATE_ROW_TYPE_CUSTOM_DATA_FOOTER_PAGE:
      return updateRowTypeCustomData(state, action);
    case REMOVE_ROW_TYPE_CUSTOM_FIELD_FOOTER_PAGE:
      return removeRowTypeCustomField(state, action);
    case ADD_BANNER_SLIDER_ROW_IMAGES_FOOTER_PAGE:
      return addBannerCarouselRowImages(state, action);
    case DELETE_ROW_IMAGE_FOOTER_PAGE:
      return deleteRowImage(state, action);
    case UPDATE_BANNER_ROW_IMAGE_DATA_FOOTER_PAGE:
      return updateBannerRowImageData(state, action);
    case CHANGE_BANNER_IMAGE_HOVER_STYLE_FOOTER_PAGE:
      return changeBannerImageHoverStyle(state, action);
    case UPDATE_BANNER_DEFAULT_TEXT_FOOTER_PAGE:
      return updateDefaultTextChange(state, action);
    case COUNTDOWN_DATA_CHANGE_FOOTER_PAGE:
      return handleCountdownDataChange(state, action);
    case REORDER_BANNER_ROW_IMAGES_FOOTER_PAGE:
      return reorderBannerRowImages(state, action);
    case CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_FOOTER_PAGE:
      return clearBannerSchedulerRowImageData(state, action);
    case CLONE_SCHEDULER_FOOTER_PAGE:
      return cloneDynamicScheduler(state, action);
    case COPY_CONTENT_TO_SCHEDULER_FOOTER_PAGE:
      return copyContentToScheduler(state, action);
    case HANDLE_DELETE_BANNER_SCHEDULER_FOOTER_PAGE:
      return handleDeleteBannerScheduler(state, action);
    case HANDLE_DATA_CHANGE_BANNER_SCHEDULER_FOOTER_PAGE:
      return handleDataChangeBannerScheduler(state, action);
    case ADD_BANNER_SCHEDULER_FOOTER_PAGE:
      return addSchedulerToBannerRow(state, action);
    case ADD_BANNER_ROW_ITEM_FOOTER_PAGE:
      return addBannerRowItem(state, action);
    case CLONE_MOBILE_FOOTER_PAGE:
      return cloneDynamicTabRow(state, action);
    case MODULE_DATA_CHANGE_FOOTER_PAGE:
      return handleModuleDataChange(state, action);
    default:
      return state;
  }
};

export default footerReducer;
