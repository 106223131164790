import _ from "lodash";
import { urlKeyFormatMap, categoryUrlKeys } from "../../constants"

export const convertOldFilterCondition = (filterCondition) => {
  let finalValue = _.chain(filterCondition)
    .split("&")
    .reduce((prev, currEl) => {
      const [property, valueRaw] = currEl.split("=");
      const value = decodeURIComponent(valueRaw);
      const valueArray = value.split(",");
      const urlKeyArray = valueArray.map((value) => {
        const valueArr = value.split(" > ");
        if (valueArr.length > 3) {
          for (let i = 0; i < 3; i++) {
            valueArr.pop();
          }
        }
        return valueArr.map((el) => generateUrlKey(el))
          .join("+");
      });
      const formattedProperty = urlKeyFormatMap[property] || property;
      urlKeyArray.forEach((urlKey) => {
        prev.push({
          key: formattedProperty,
          value: urlKey
        });
      });
      return prev;
    }, [])
    .groupBy(({ key }) => categoryUrlKeys.includes(key) ? "category" : "common")
    .thru(({ category: categoryUrlKeys, common: commonUrlKeys = [] }) => {
      const lastCategory = _.last(categoryUrlKeys);

      return [lastCategory, ...(commonUrlKeys)];
    })
    .compact()
    .map(({ key, value }) => `${key}=${value}`)
    .join("&")
    .value();
  return finalValue;
};

const generateUrlKey = (str) => {
  if (!str || !str.trim()) return null;
  return String(str)
    .normalize("NFKD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .toLowerCase()
    .replace(/&+/g, "-and-")
    .replace(/\//g, "-or-")
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
};