import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Routes from "./superAdminRoutes";
import { LinearProgress } from "../../materialUi/components"
import {
  setSelectedStoreState,
  fetchStoreList,
  changeBreadCrumb
} from "../../actions/store.action";
import { fetchUserAndLoadData } from "../../actions/user.action";

class AuthContentContainer extends React.Component {
  UNSAFE_componentWillMount() {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("storeId")) {
      this.props.setSelectedStoreState("storeId", urlParams.get("storeId"));
    }
    if (urlParams.get("countryId")) {
      this.props.setSelectedStoreState("countryId", urlParams.get("countryId"));
    }
  }

  componentDidMount() {
    this.props.fetchUserAndLoadData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { storeId, countryId, storeList, changeBreadCrumb } = nextProps;
    const selectedStore =
      storeList && storeList.find(store => store.storeId === storeId);
    const selectedCountry =
      selectedStore &&
      selectedStore.countries.find(country => country.countryId === countryId);
    if (selectedCountry && selectedCountry.name) {
      changeBreadCrumb(selectedStore.storeName, selectedCountry.name);
    }
  }

  render() {
    const { user: { permissions = {} } = {} } = this.props;
    return !isEmpty(permissions) ? <Routes /> : null;
  }
}

const mapStateToProps = state => ({
  countryId: state.store.countryId,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  user: state.authentication.user
});

const mapDispatchToProps = {
  changeBreadCrumb,
  fetchStoreList,
  fetchUserAndLoadData,
  setSelectedStoreState
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthContentContainer);
