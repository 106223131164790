import * as React from "react";

export const Down = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 48 48"
      {...props}
    >
      <path d="M18.8 5C8.3 8.1 2.2 18.7 5 29.1c1.6 5.8 8.1 12.3 13.9 13.9 14.4 3.9 28-9.6 24.1-24.1-1.5-5.6-8-12.3-13.5-13.8-4.6-1.2-6.9-1.3-10.7-.1zm1.4 17.7l3.8 3.7 4-3.9c3.8-3.7 4.1-3.8 5.7-2.2 1.7 1.6 1.4 2-4 7.5L24 33.5l-5.7-5.7c-4.8-4.9-5.5-5.9-4.4-7.2 1.8-2.2 2-2.1 6.3 2.1z" />
    </svg>
  );
};
