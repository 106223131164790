import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Paper, Typography, Stack, Box } from "../materialUi/components";
import { HomeIcon, NavigateNextIcon } from "../materialUi/icons";
import { routeNameMap } from "../constants"


const Subheader = ({ showCountry, breadcrumArr, ...props }) => {
    const selectedRouteName = location.pathname.split("/")[1] || "home-page";
    const selectedCountryName = useSelector(store => store.store.selectedCountryName);
    return (
        <Paper
            elevation={0}
            sx={{
                padding: "20px 15px 15px 15px",
                marginBottom: "10px",
            }}
            variant="outlined"
            {...props}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize={"14px"}>
                    {routeNameMap[selectedRouteName].toUpperCase()}
                </Typography>
                <div style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
                    <HomeIcon fontSize="small" />
                    <NavigateNextIcon sx={{ "marginLeft": "10px", "marginRight": "10px" }} fontSize="small" />
                    <Typography fontSize={"14px"}
                    >
                        {routeNameMap[selectedRouteName]}
                    </Typography>
                    {showCountry &&
                        <>
                            <NavigateNextIcon sx={{ "marginLeft": "10px", "marginRight": "10px" }} fontSize="small" />
                            <Typography fontSize={"14px"}
                            >
                                {selectedCountryName}
                            </Typography>
                        </>
                    }
                    {
                        !!(breadcrumArr?.length) && breadcrumArr.map((breadcrum) =>
                            <Fragment key={breadcrum}>
                                <NavigateNextIcon sx={{ "marginLeft": "10px", "marginRight": "10px" }} fontSize="small" />
                                <Typography fontSize={"14px"}
                                >
                                    {breadcrum}
                                </Typography>
                            </Fragment>
                        )
                    }
                </div>
            </div>
        </Paper>
    );
};

export default Subheader;
