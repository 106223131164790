import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import cn from "classnames";
import isEqual from "lodash/isEqual";
import Button from "../../component/button";
import {
  clearFooterContent,
  saveFooterContent,
  updateTreeData,
  handleFooterEditAttribute,
  createFooterTreeRecordInitial,
  updateFooterOnTreeChange,
  deleteFooterAttribute
} from "../../actions/footer.action";
import { toastMsg } from "../../actions/common.action";
import { FOOTER_ADD_FOOTER_ITEM_MESSAGES, MESSAGES, ERROR_MESSAGES } from "../../constants";
import ConfirmModal from "../modal/ConfirmModal";
import ColumnMenu from "./coloumnMenu";
import AddAttributeModal from "./addAttribModal";

class AddFooterItem extends Component {
  state = {
    confirmModal: false,
    confirmModalMessage: "",
    disableSubmit: true,
    imageSelectionModal: false,
    language: null,
    openModal: false,
    selectedAttributeData: {},
    showContent: { index: 1, show: false }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      arabicList,
      createFooterTreeRecordInitial,
      englishList,
      treeData,
      updateFooterOnTreeChange
    } = this.props;
    if (englishList !== nextProps.englishList) {
      createFooterTreeRecordInitial();
    }
    if (!isEqual(treeData, nextProps.treeData)) {
      updateFooterOnTreeChange(nextProps.treeData);
    }

    if (
      isEqual(nextProps.updateArabicList, arabicList) &&
      isEqual(nextProps.updateEnglishList, englishList)
    ) {
      this.setState({ disableSubmit: true });
    } else {
      this.setState({ disableSubmit: false });
    }
  }

  openEditModal = selectedAttributeData => {
    const { node } = selectedAttributeData;
    this.props.handleFooterEditAttribute(node);
    this.setState({ openModal: true });
  };

  openConfirmModal = selectedAttributeData => {
    const confirmModalMessage = selectedAttributeData.node.children.length
      ? FOOTER_ADD_FOOTER_ITEM_MESSAGES.DELETE_WITH_NODES
      : FOOTER_ADD_FOOTER_ITEM_MESSAGES.DELETE_RECORD;
    this.setState({
      selectedAttributeData,
      confirmModal: true,
      confirmModalMessage
    });
  };

  changeKeyIndex = index => {
    const { showContent } = this.state;
    if (index === showContent.index) {
      this.setState({ showContent: { show: !showContent.show, index } });
    } else {
      this.props.clearFooterContent();
      this.setState({ showContent: { index, show: true } });
    }
  };

  handleSubmit = (tempsave = false) => {
    const {
      updateArabicList,
      updateEnglishList,
      updateFooterDetails,
      toastMsg
    } = this.props;
    if (
      (updateEnglishList && updateEnglishList.length > 5) ||
      (updateArabicList && updateArabicList.length > 5)
    ) {
      return toastMsg(
        "warning",
        FOOTER_ADD_FOOTER_ITEM_MESSAGES.PARENT_NUMBER_RESTRICTION
      );
    }
    updateFooterDetails(tempsave);
  };

  validateFormData = (formData, language) => {
    const { toastMsg } = this.props;
    const { title = "" } = formData || {};
    if (title && (title !== title.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Title`);
      return false;
    }

    return true;
  }

  handleSaveContent = e => {
    e.preventDefault();
    const {
      saveFooterContent,
      updateArabicContent,
      updateEnglishContent
    } = this.props;

    const isValidEnglishForm = this.validateFormData(updateEnglishContent, "English");
    if (!isValidEnglishForm) return;

    const isValidArabicForm = this.validateFormData(updateArabicContent, "Arabic");
    if (!isValidArabicForm) return;

    saveFooterContent(updateEnglishContent, updateArabicContent);
    this.closeModal();
  };

  handleUpdateContent = e => {
    e.preventDefault();
    const {
      saveFooterContent,
      updateArabicContent,
      updateEnglishContent
    } = this.props;
    saveFooterContent(updateEnglishContent, updateArabicContent);
    this.closeModal();
  };

  closeModal = () => {
    const { clearFooterContent } = this.props;
    this.handleModal(false);
    clearFooterContent();
  };

  handleModal = value => this.setState({ openModal: value });

  updateTreeData = data => this.props.updateTreeData(data);

  closeConfirmModal = () =>
    this.setState({ confirmModal: false, selectedAttributeData: {} });

  handleConfirmModal = () => {
    this.props.deleteFooterAttribute(this.state.selectedAttributeData);
    this.setState({ confirmModal: false });
  };

  render() {
    const {
      updateEnglishList,
      updateArabicList,
      isEdit,
      treeData,
      updateArabicContent,
      updateEnglishContent,
      isPublished,
      accessRights
    } = this.props;
    const {
      confirmModal,
      openModal,
      confirmModalMessage,
      disableSubmit
    } = this.state;
    return (
      <Fragment>
        {openModal && (
          <AddAttributeModal
            clearFooterContent={this.closeModal}
            handleSaveContent={this.handleSaveContent}
            isEdit={isEdit}
            onHide={this.handleModal}
            updateArabicContent={updateArabicContent}
            updateEnglishContent={updateEnglishContent}
          />
        )}
        {confirmModal && (
          <ConfirmModal
            onHide={this.closeConfirmModal}
            confirm={this.handleConfirmModal}
            message={confirmModalMessage}
          />
        )}
        <div className="wrapper-box footer-padding">
          <ColumnMenu
            updateEnglishList={updateEnglishList || []}
            updateArabicList={updateArabicList || []}
            treeData={treeData}
            updateTreeData={this.updateTreeData}
            handleModal={this.handleModal}
            openEditModal={this.openEditModal}
            openConfirmModal={this.openConfirmModal}
          />
        </div>
        <div className="marginBottom">
          <span
            data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
            data-for="footer-add"
          >
            <Button
              customClass={cn("marginBottom", {
                "btn-disabled": disableSubmit
              })}
              disabled={disableSubmit || !accessRights.UPDATE}
              name="Save & Preview"
              onClick={
                accessRights.UPDATE
                  ? () => {
                    if (!disableSubmit) {
                      this.handleSubmit(true);
                    }
                  }
                  : null
              }
            />
          </span>
          <span
            data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
            data-for="footer-add"
          >
            <Button
              customClass={cn("marginBottom", {
                "btn-disabled": isPublished
              })}
              name="Publish"
              disabled={isPublished || !accessRights.UPDATE}
              onClick={
                accessRights.UPDATE ? () => this.handleSubmit(false) : null
              }
            />
          </span>
        </div>
        <ReactTooltip place="top" effect="solid" id="footer-add" />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isEdit: state.footer.isEdit,
  isPublished: state.footer.isPublished,
  updateArabicContent: state.footer.updateArabicContent,
  updateArabicList: state.footer.updateArabicList,
  updateEnglishContent: state.footer.updateEnglishContent,
  updateEnglishList: state.footer.updateEnglishList
});

const mapDispatchToProps = {
  clearFooterContent,
  createFooterTreeRecordInitial,
  deleteFooterAttribute,
  handleFooterEditAttribute,
  saveFooterContent,
  toastMsg,
  updateFooterOnTreeChange,
  updateTreeData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFooterItem);
