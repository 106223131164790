import React from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import InputText from "../../component/InputText";
import { CATALOG_PAGE_GENERAL_SEO_CONTENT_PLACEHOLDERS } from "../../constants";

const GeneralSeoContentForm = ({ handleUpdate, seoContent, language }) => {
  const placeholders = CATALOG_PAGE_GENERAL_SEO_CONTENT_PLACEHOLDERS[language];
  return (
    <div className={`${language}-wrapper`}>
      <h4>{upperFirst(language)} Version</h4>
      <div className={`${language}-content`}>
        <InputText
          label="Page Title"
          name="title"
          language={language}
          value={get(seoContent, `${language}.title`, "")}
          onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          placeholder={placeholders.title}
          required
        />
        <InputText
          label="Meta Keywords"
          name="metaKeyword"
          language={language}
          value={get(seoContent, `${language}.metaKeyword`, "")}
          onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          placeholder={placeholders.metaKeyword}
          required
        />
        <InputText
          label="Meta Description"
          name="metaDescription"
          language={language}
          value={get(seoContent, `${language}.metaDescription`, "")}
          onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          placeholder={placeholders.metaDescription}
          textarea
          required
        />
        <InputText
          label="Heading H1"
          name="headingH1"
          language={language}
          value={get(seoContent, `${language}.headingH1`, "")}
          onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          placeholder={placeholders.headingH1}
          required
        />
        <InputText
          label="Heading H2"
          name="headingH2"
          language={language}
          value={get(seoContent, `${language}.headingH2`, "")}
          onChange={e => handleUpdate(e.target.name, e.target.value, language)}
          placeholder={placeholders.headingH2}
          required
        />
      </div>
    </div>
  );
};

export default GeneralSeoContentForm;
