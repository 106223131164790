import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastMsg, snackbar, uploadImage } from "../../actions/common.action";
import {
  Dialog, DialogActions, DialogContent,
  DialogTitle, Divider, Box, Button,
  TextField, InputAdornment
} from "../../materialUi/components"
import { CloseIcon, FileUploadIcon } from "../../materialUi/icons"

export const VideoSelectionModal = ({
  title,
  language,
  keyName,
  onSuccess,
  containerName,
  isOpen,
  handleClose
}) => {
  const dispatch = useDispatch();
  const inputFileRef = useRef();

  const [uploadedFile, setUploadedFile] = useState("");
  const [cdnURL, setCdnURL] = useState("");
  const [validCdnUrl, setValidCdnUrl] = useState(false);
  const regex = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;
  const brandId = useSelector(
    state => state.store.brandId || state.store.storeId
  );
  const countryId = useSelector(state => state.store.countryId);
  const storeId = useSelector(state => state.store.storeId);

  const onSubmit = e => {
    e.preventDefault();

    if (!cdnURL && !(uploadedFile && uploadedFile[0].name)) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.noVideoFileError }));
      return;
    } else if (cdnURL && !validCdnUrl) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.invalidVideoLink }));
    }

    if (cdnURL) {
      onSuccess(`${cdnURL}?burst=${Date.now()}`, language, "");
    } else if (uploadedFile) {
      let _formData = new FormData();
      _formData.append("file", uploadedFile[0]);

      let _brandId = brandId;
      let fileName = uploadedFile?.[0]?.name || "";
      const index = fileName.lastIndexOf('.');
      fileName = fileName.slice(0, index);
      if (containerName != "storeList") {
        _brandId = `${brandId}${+new Date()}`;
      }

      const videoUpload = dispatch(
        uploadImage({
          brandId: _brandId,
          keyName,
          language,
          storeId,
          countryId,
          containerName,
          _formData
        })
      );

      videoUpload.then(res => {
        if (
          res?.data?.code == 200 &&
          res.data?.data?.result?.url
        ) {
          onSuccess(`${res.data.data.result.url}?burst=${Date.now()}`, language, fileName);
        } else {
          dispatch(toastMsg("error", "Unable to upload image."));
        }
      });
    }
  };

  const changeFileHandler = e => {
    if (e.target.files[0].size / 1024 ** 2 > 10) {
      dispatch(toastMsg("warning", "Maximum file size allowed 10Mb"));
    } else if (e.target.files[0].type.split("/")[1].indexOf("tif") !== -1) {
      dispatch(
        toastMsg("warning", ".tiff/.tif file extensions are not allowed.")
      );
    } else {
      setUploadedFile(e.target.files);
    }
  };

  const clearSelectedImage = () => {
    setUploadedFile("");
  };

  const handleChange = (event) => {
    setCdnURL(event.target.value);
    setValidCdnUrl(event.target.value.match(regex))
  }
  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>
          {title || "Upload Video"}
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <Box style={{ "display": "flex" }}>
              <TextField
                autoFocus
                margin="dense"
                id="fileName"
                label="File Name"
                type="text"
                fullWidth
                value={uploadedFile?.[0]?.name || ""}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <Button onClick={clearSelectedImage}>
                        <CloseIcon />
                      </Button>
                    </InputAdornment>
                }}
                aria-readonly={true}
                disabled={true}
                placeholder="Choose file"
              />
              <TextField
                id="uploadFile"
                type="file"
                fullWidth
                inputRef={inputFileRef}
                onChange={e => {
                  changeFileHandler(e);
                }}
                style={{ "display": "none" }}
              />
              <Button
                onClick={() => {
                  inputFileRef.current.click()
                }}
                disabled={true}
              >
                <FileUploadIcon fontSize="small" />
              </Button>
            </Box>
            <Divider variant="fullWidth" style={{ "marginBottom": "10px", "marginTop": "10px" }}>OR</Divider>
            <TextField
              autoFocus
              name="videoUrl"
              margin="dense"
              id="videoUrl"
              label="Video Link"
              type="text"
              fullWidth
              value={cdnURL || ""}
              onChange={handleChange}
              disabled={!!uploadedFile}
              placeholder="Video Link"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" sx={{ "marginRight": "auto" }}>
            Close
          </Button>
          <Button
            onClick={e => {
              onSubmit(e);
            }}
            disabled={!!(!(cdnURL || uploadedFile) || (cdnURL && !validCdnUrl))}
            variant="outlined">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
