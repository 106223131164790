import {
  ADD_NEED_HELP_SEO,
  CHANGE_CATEGORY,
  HANDLE_ACCORD_TEXT_CHANGE,
  HANDLE_ACCORD_CHECK_BOX_CHANGE,
  HANDLE_NEED_HELP_ACCORD_TILE,
  REMOVE_NEED_HELP_ACCORD_TILE,
  UPDATE_NEED_HELP_ACCORD_TILE_POSITION,
  RESET_NEED_HELP_CONTENT,
  UPDATE_NEED_HELP_CONTENT,
  DISABLE_SUBMIT_BUTTON,
  ENABLE_SUBMIT_BUTTON,
  TOGGLE_RESET_BUTTON
} from "../constants/actions";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
  toastMsg
} from "./common.action";
import { fetchWebApi, putDashboardWebApi } from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import { ENV_CONFIG } from "../config/env";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  NEED_HELP_PANEL_API_MAP
} from "../constants";
import { toggleReset } from "./staticPage.action";
import { resetCountryId } from "./selectedCountyId.action";
import { resetStoreId } from "./store.action";

export const addNeedHelpSeo = (key, name, value, lang) => {
  return { type: ADD_NEED_HELP_SEO, key, name, value, lang };
};

export const changeCategory = key => {
  return { type: CHANGE_CATEGORY, key };
};

export const handleAccordTextChange = (
  index,
  needHelpKey,
  language,
  key,
  value
) => {
  return {
    type: HANDLE_ACCORD_TEXT_CHANGE,
    index,
    needHelpKey,
    language,
    key,
    value
  };
};

export const handleAccordCheckBoxChange = (index, needHelpKey, key, value) => {
  return {
    type: HANDLE_ACCORD_CHECK_BOX_CHANGE,
    index,
    needHelpKey,
    key,
    value
  };
};

export const handleNeedHelpAccordTile = (pos, needHelpKey) => {
  return { type: HANDLE_NEED_HELP_ACCORD_TILE, pos, needHelpKey };
};

export const handelRemoveNeedHelpAccordTile = (pos, needHelpKey) => {
  return { type: REMOVE_NEED_HELP_ACCORD_TILE, pos, needHelpKey };
};

export const handelUpdateNeedHelpAccordTilePosition = (
  pos,
  needHelpKey,
  index
) => {
  return {
    type: UPDATE_NEED_HELP_ACCORD_TILE_POSITION,
    pos,
    needHelpKey,
    index
  };
};

export const resetNeedHelpContent = needHelpKey => {
  return { type: RESET_NEED_HELP_CONTENT, needHelpKey };
};

export const getNeedHelpData = ({ result }, currentTab) => {
  return { type: UPDATE_NEED_HELP_CONTENT, result, currentTab };
};

export const enableSubmitBtn = () => {
  return { type: ENABLE_SUBMIT_BUTTON };
};

export const disableSubmitBtn = () => {
  return { type: DISABLE_SUBMIT_BUTTON };
};

export const toggleResetBtn = value => {
  return { type: TOGGLE_RESET_BUTTON, value };
};

export const fetchNeedHelpData = (countryId, currentTab) => {
  const urlType = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/need-help?countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), urlType)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(getNeedHelpData(response.data.data, currentTab));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const submitNeedHelpPage = (
  countryId,
  currentTab,
  bodyData,
  tempsave,
  selectedCountryId
) => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/need-help/content/${NEED_HELP_PANEL_API_MAP[currentTab]}?countryId=${countryId}`;
  bodyData = {
    body: bodyData,
    selectedCountryId
  };
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return putDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      bodyData,
      tempsave
    )
      .then(response => {
        if (response.data.code === 200) {
          dispatch(fetchNeedHelpData(countryId, currentTab));
          dispatch(toastMsg("success", SUCCESS_MESSAGES[currentTab]));
          dispatch(resetNeedHelpContent());
          dispatch(toggleReset(true));
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message ||
                response.data.error.detail ||
                ERROR_MESSAGES[currentTab]
            )
          );
        }
        dispatch(hideLoadingIndicator());
        if (!tempsave) {
          dispatch(resetCountryId());
          dispatch(resetStoreId());
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
