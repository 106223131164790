import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";

const SchedulerDatePicker = ({
  keyIndex,
  handleDatePicker,
  type,
  label,
  id,
  value,
  showTimeSelect = true,
  ...restPickerProps
}) => {
  const [focus, setFocus] = useState(true);
  const datePickerRef = useRef(null);

  const toggle = () => {
    datePickerRef.current.setOpen(focus);
    setFocus(fcs => !fcs);
  };

  return (
    <div className="form-group">
      <div className="custom-calender">
        <label>{label}</label>
        <div className="customDatePickerWidth">
          <DatePicker
            ref={datePickerRef}
            id={id}
            name={`scheduler${keyIndex + 1}`}
            className="form-control"
            timeCaption="Time"
            minDate={new Date()}
            // showTimeSelect={showTimeSelect}
            showTimeInput={showTimeSelect}
            timeFormat="HH:mm"
            timeIntervals={1}
            onChange={e => handleDatePicker(e, type, keyIndex, id)}
            onKeyDown={event => event.preventDefault()}
            selected={value ? new Date(value) : null}
            dateFormat={"yyyy-MM-dd HH:mm"}
            isClearable
            {...restPickerProps}
          />
          <span className="date-calender" onClick={toggle} />
        </div>
      </div>
    </div>
  );
};

export default SchedulerDatePicker;
