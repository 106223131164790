import React from "react";
import map from "lodash/map";
import cn from "classnames";

export const DropDownList = ({
  listData,
  handleSelect,
  isAll,
  selectedName,
  nameKey,
  idKey,
  type
}) => (
  <div className="drpdwn_wrapper">
    <div className="apply-for-dropdown withCheckbox">
      <div className="dropdown">
        <span className="dropbtn">{selectedName}</span>
        <div className="dropdown-content" id="dropbtnApplyFor">
          {type !== "simple" && (
            <span onClick={() => handleSelect(null, !isAll)}>
              <label
                className={cn({
                  checkedBox: isAll,
                  uncheckedBox: !isAll
                })}
              >
                <input type="checkbox" id="isAll" />
              </label>
              <p className="lblTxt">All</p>
            </span>
          )}
          {map(listData, item => (
            <span
              key={item[idKey]}
              onClick={() => {
                if (!item.isDisabled) {
                  handleSelect(item, false);
                }
              }}
              className={cn({ disabled: item.isDisabled })}
            >
              {type !== "simple" && (
                <label
                  className={cn({
                    checkedBox: item.isChecked,
                    uncheckedBox: !item.isChecked
                  })}
                >
                  <input type="checkbox" id={item[idKey]} />
                </label>
              )}
              <p className="lblTxt">{item[nameKey]}</p>
            </span>
          ))}
        </div>
      </div>
    </div>
  </div>
);
