import React, { useState, Fragment } from "react";
import _, { cloneDeep } from "lodash";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import {
  Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button, TableContainer, Table,
  TableHead, TableRow, TableCell, TableBody, IconButton, TextField, AntSwitch, BasicPopover
} from "../../materialUi/components";
import { DeleteIcon } from "../../materialUi/icons";
import { validateEmail } from "../../util/storeHelper";
import { snackbar } from "../../actions/common.action";


const AllowedUsersModal = ({ open, data, handleClose, handleSave, title, dialogContext }) => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState(cloneDeep(data) || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowIndex, setRowIndex] = useState(-1);

  const validateForm = () => {
    let index = 0;
    for (const data of modalData) {
      if (!data.userId) {
        dispatch(snackbar({ open: true, severity: "error", text: `UserId cannot be blank at Index ${index}` }));
        return false;
      }
      if (data.userId !== data.userId.trim()) {
        dispatch(snackbar({ open: true, severity: "error", text: `Please remove leading and trailing spaces from UserId at Index ${index}` }));
        return false;
      }
      if (!data.name) {
        dispatch(snackbar({ open: true, severity: "error", text: `name cannot be blank at Index ${index}` }));
        return false;
      }
      if (data.name !== data.name.trim()) {
        dispatch(snackbar({ open: true, severity: "error", text: `Please remove leading and trailing spaces from name at Index ${index}` }));
        return false;
      }
      if (!data.email) {
        dispatch(snackbar({ open: true, severity: "error", text: `email cannot be blank at Index ${index}` }));
        return false;
      }
      if (data.email !== data.email.trim()) {
        dispatch(snackbar({ open: true, severity: "error", text: `Please remove leading and trailing spaces from email at Index ${index}` }));
        return false;
      }
      if (!validateEmail(data.email)) {
        dispatch(snackbar({ open: true, severity: "error", text: `Please enter valid email at Index ${index}` }));
        return false;
      }
      index++;
    }
    return true;
  }

  const handleUpdateModalData = (index, key, value) => {
    const newData = cloneDeep(modalData);
    newData[index][key] = value;
    setModalData(newData);
  }

  const handleRemoveModalRow = (index) => {
    const newData = cloneDeep(modalData);
    newData.splice(index, 1);
    setModalData(newData);
  }

  const handleAddModalRow = () => {
    const newData = cloneDeep(modalData);
    newData.push({ userId: "", name: "", email: "", status: false });
    setModalData(newData);
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
      <DialogTitle>
        {title}
        <Button
          onClick={handleAddModalRow}
          variant="contained"
          sx={{ float: "right" }}
        >
          {"Add Row"}
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {dialogContext}
        </DialogContentText>
        {Boolean(anchorEl) &&
          <BasicPopover open={Boolean(anchorEl)} id={"allowedUsers"} anchorEl={anchorEl}
            handleCancel={() => {
              setAnchorEl(null);
              setRowIndex(-1);
            }}
            handleSave={() => {
              handleRemoveModalRow(rowIndex)
              setAnchorEl(null);
              setRowIndex(-1);
            }}
            text={"Are you sure you want to delete this Row?"}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          />}
        <TableContainer
        >
          <Table aria-label="sticky table" size="medium" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  key={"index"}
                  align={"left"}
                >
                  Sl.No
                </TableCell>
                <TableCell
                  key={"userId"}
                  align={"left"}
                >
                  User Id
                </TableCell>
                <TableCell
                  key={"name"}
                  align={"left"}
                >
                  Name
                </TableCell>
                <TableCell
                  key={"email"}
                  align={"left"}
                >
                  Email
                </TableCell>
                <TableCell
                  key={"status"}
                  align={"left"}
                >
                  Status
                </TableCell>
                <TableCell
                  key={"action"}
                  align={"left"}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modalData && modalData.map((data, index) => (
                <TableRow hover tabIndex={-1} key={index}
                >
                  <TableCell
                    key={"index"}
                    align={"left"}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    key={"userId"}
                    align={"left"}
                  >
                    {<TextField
                      autoFocus
                      name="userId"
                      label=""
                      type="text"
                      value={data.userId || ""}
                      onChange={event => {
                        handleUpdateModalData(
                          index,
                          "userId",
                          get(event, "target.value", "")
                        )
                      }
                      }
                      style={{ width: "100%" }}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Please Enter UserId"
                    />
                    }
                  </TableCell>
                  <TableCell
                    key={"name"}
                    align={"left"}
                  >
                    {<TextField
                      autoFocus
                      name="name"
                      label=""
                      type="text"
                      value={data.name || ""}
                      onChange={event => {
                        handleUpdateModalData(
                          index,
                          "name",
                          get(event, "target.value", "")
                        )
                      }
                      }
                      style={{ width: "100%" }}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Please Enter Name"
                    />
                    }
                  </TableCell>
                  <TableCell
                    key={"email"}
                    align={"left"}
                  >
                    {<TextField
                      autoFocus
                      name="email"
                      label=""
                      type="text"
                      value={data.email || ""}
                      onChange={event => {
                        handleUpdateModalData(
                          index,
                          "email",
                          get(event, "target.value", "")
                        )
                      }
                      }
                      style={{ width: "100%" }}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Please Enter email"
                    />
                    }
                  </TableCell>
                  <TableCell
                    key={"status"}
                    align={"left"}
                  >
                    {
                      <AntSwitch
                        checked={data.status || false}
                        onChange={(event) =>
                          handleUpdateModalData(index, "status", event.target.checked || false)
                        }

                      />
                    }
                  </TableCell>
                  <TableCell
                    key={"action"}
                    align={"left"}
                  >
                    <IconButton
                      onClick={(event) => {
                        setRowIndex(index);
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => {
          validateForm() && handleSave(cloneDeep(modalData))
        }}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AllowedUsersModal;
