import React from "react";
import { StoresDropdown } from "../storesDropdown";
import MultiCountryDropDown from "../countryDropDown";

export const ApplyDropDown = ({ onlyCountries, style }) => {
  return (
    <div className="apply-for-wrapper" style={style}>
      {/*<div className="labelTxt">*/}
      {/*  <p>Apply For:</p>*/}
      {/*</div>*/}
      {!onlyCountries && <StoresDropdown />}
      <MultiCountryDropDown />
    </div>
  );
};
