import React from "react";
import Select from "react-select";

export const CustomSelect = ({
  options,
  className,
  defaultSelectValue,
  isDisabled = false,
  isSearchable = true,
  name,
  onChange,
  value,
  placeholder,
  clearable = false
}) => {
  return (
    <Select
      defaultInputValue={defaultSelectValue}
      // value={value}
      value={options.filter((option) => option.value === value)}
      classNamePrefix="select"
      className={className}
      options={options}
      disabled={isDisabled}
      isSearchable={isSearchable}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      clearable={clearable}
    />
  );
};
