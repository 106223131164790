import React from "react";
import get from "lodash/get";
import { FOOTER_STATIC_CONTENT_PLACEHOLDERS } from "../../../constants";
import InputText from "../../../component/InputText";

const CustomerSupport = ({ handleFooterText, staticContent, language }) => {
  const placeholders = FOOTER_STATIC_CONTENT_PLACEHOLDERS[language];
  return (
    <div className="wrapper-box">
      <div className="data-wrapper">
        <InputText
          name="csHeading"
          label="Heading"
          language={language}
          placeholder={placeholders.csHeading}
          value={get(staticContent, "csHeading", "")}
          onChange={e =>
            handleFooterText(e.target.name, e.target.value, language)
          }
          required
        />
        <InputText
          alignRight
          name="csNumber"
          label="Support Number"
          language={language}
          placeholder={placeholders.csNumber}
          value={get(staticContent, "csNumber", "")}
          onChange={e =>
            handleFooterText(e.target.name, e.target.value, language)
          }
          required
        />
        <InputText
          name="csTime"
          label="Support Time"
          language={language}
          placeholder={placeholders.csTime}
          value={get(staticContent, "csTime", "")}
          onChange={e =>
            handleFooterText(e.target.name, e.target.value, language)
          }
          required
        />
        <InputText
          name="csEmail"
          label="Support Email"
          language={language}
          placeholder={placeholders.csEmail}
          value={get(staticContent, "csEmail", "")}
          onChange={e =>
            handleFooterText(e.target.name, e.target.value, language)
          }
          required
        />
      </div>
    </div>
  );
};

export default CustomerSupport;
