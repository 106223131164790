export function getResult(arg) {
  return typeof arg === "function" ? arg() : arg;
}

export function getAccessToken(getState) {
  const state = getResult(getState);
  return state.authentication.accessToken;
}

export const parseQueryParams = query => {
  const queryArray = query.split("?")[1].split("&");
  let queryParams = {};
  for (let i = 0; i < queryArray.length; i++) {
    const [key, val] = queryArray[i].split("=");
    queryParams[key] = val ? decodeURIComponent(val) : true;
  }
  return queryParams;
};

export const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
  return re.test(String(email).toLowerCase());
};
