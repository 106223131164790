import { cloneDeep } from "lodash";
import { SIZE_CHART_PANEL } from "../constants";
import {
  CHANGE_SIZE_CHART_CATEGORY,
  CHANGE_SIZE_CHART_NAV,
  CHANGE_SELECTED_BRAND,
  FETCH_BRANDS_LIST,
  CHANGE_SELECTED_GENERIC_CATEGORY,
  UPDATE_SIZE_CHART_DATA,
  RESET_SIZE_CHART_CONTENT,
  FETCH_SUB_CATEGORY_LIST,
  HANDLE_SIZE_CHART_INPUT_TEXT_CHANGE,
  CHANGE_SELECTED_SUB_CATEGORY,
  CHANGE_CK_EDITOR_TEXT,
  UPDATE_SUB_CATEGORY_DATA,
  CLEAR_SUB_CATEGORY_DATA,
  HANDLE_GENERAL_CHANGE,
  HANDLE_GENERIC_SIZE_CHART_INPUT_TEXT_CHANGE,
  HANDLE_GENERIC_SIZE_CHART_CHANGE,
  RESET_SIZE_CHART_GENERIC_CONTENT,
  CHANGE_SELECTED_GENERIC_SUB_CATEGORY,
  HANDLE_RESET,
  CLEAR_SELECTED_GENERIC_SUB_CATEGORY,
  HANDLE_SELECTED_GENERIC_SUB_CATEGORY_INPUT_TEXT_CHANGE,
  HANDLE_SELECTED_GENERIC_SUB_CATEGORY_CATEGORIES_CHANGE
} from "../constants/actions";

const initialState = {
  apiContent: {
    meta: {
      english: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      },
      arabic: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    },
    general: {
      english: {
        heading: "",
        subHeading: ""
      },
      arabic: {
        heading: "",
        subHeading: ""
      }
    },
    brandSizeChart: {
      men: {
        english: {
          heading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          imageUrl: ""
        },
        enabled: [],
        brandList: [
          /*{
           // _id: "",
           brandId: "",
           brandName: "",
           subCategoryId: "",
           subCategoryName: "",
           pos: "",
           englishText: "",
           arabicText: ""
           }*/
        ]
      },
      women: {
        english: {
          heading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          imageUrl: ""
        },
        enabled: [],
        brandList: [
          /*{
           // _id: "",
           brandId: "",
           brandName: "",
           subCategoryId: "",
           subCategoryName: "",
           pos: "",
           englishText: "",
           arabicText: ""
           }*/
        ]
      },
      kids: {
        english: {
          heading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          imageUrl: ""
        },
        enabled: [],
        brandList: [
          /*{
           // _id: "",
           brandId: "",
           brandName: "",
           subCategoryId: "",
           subCategoryName: "",
           pos: "",
           englishText: "",
           arabicText: ""
           }*/
        ]
      }
    },
    genericSizeChart: {
      men: [
        {
          // _id: "",
          id: "clothing",
          name: "Clothing",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        },
        {
          // _id: "",
          id: "imageBlock1",
          name: "Image Block 1",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        },
        {
          // _id: "",
          id: "imageBlock2",
          name: "Image Block 2",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        },
        {
          // _id: "",
          id: "imageBlock3",
          name: "Image Block 3",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        }
      ],
      women: [
        {
          // _id: "",
          id: "clothing",
          name: "Clothing",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        },
        {
          // _id: "",
          id: "imageBlock1",
          name: "Image Block 1",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        },
        {
          // _id: "",
          id: "imageBlock2",
          name: "Image Block 2",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        },
        {
          // _id: "",
          id: "imageBlock3",
          name: "Image Block 3",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        }
      ],
      kids: [
        {
          // _id: "",
          id: "clothing",
          name: "Clothing",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        },
        {
          // _id: "",
          id: "imageBlock1",
          name: "Image Block 1",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        },
        {
          // _id: "",
          id: "imageBlock2",
          name: "Image Block 2",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        },
        {
          // _id: "",
          id: "imageBlock3",
          name: "Image Block 3",
          subCategoryId: "",
          subCategoryName: "",
          enabled: false,
          english: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          arabic: {
            heading: "",
            subHeading: "",
            imageUrl: ""
          },
          list: [
            /*{
             // _id: "",
             english: {
             heading: "",
             html: ""
             },
             arabic: {
             heading: "",
             html: ""
             },
             pos: ""
             }*/
          ]
        }
      ]
    }
  },
  updateMeta: {},
  updateGeneral: {},
  updateBrandSizeChart: {},
  updateGenericSizeChart: {},
  meta: {
    english: {
      title: "",
      metaKeyword: "",
      metaDescription: "",
      headingH1: "",
      headingH2: ""
    },
    arabic: {
      title: "",
      metaKeyword: "",
      metaDescription: "",
      headingH1: "",
      headingH2: ""
    }
  },
  general: {
    english: {
      heading: "",
      subHeading: ""
    },
    arabic: {
      heading: "",
      subHeading: ""
    }
  },
  brandSizeChart: {
    men: {
      english: {
        heading: "",
        imageUrl: ""
      },
      arabic: {
        heading: "",
        imageUrl: ""
      },
      enabled: [],
      brandList: [
        /*{
          // _id: "",
          brandId: "",
          brandName: "",
          subCategoryId: "",
          subCategoryName: "",
          pos: "",
          englishText: "",
          arabicText: ""
        }*/
      ]
    },
    women: {
      english: {
        heading: "",
        imageUrl: ""
      },
      arabic: {
        heading: "",
        imageUrl: ""
      },
      enabled: [],
      brandList: [
        /*{
          // _id: "",
          brandId: "",
          brandName: "",
          subCategoryId: "",
          subCategoryName: "",
          pos: "",
          englishText: "",
          arabicText: ""
        }*/
      ]
    },
    kids: {
      english: {
        heading: "",
        imageUrl: ""
      },
      arabic: {
        heading: "",
        imageUrl: ""
      },
      enabled: [],
      brandList: [
        /*{
          // _id: "",
          brandId: "",
          brandName: "",
          subCategoryId: "",
          subCategoryName: "",
          pos: "",
          englishText: "",
          arabicText: ""
        }*/
      ]
    }
  },
  genericSizeChart: {
    men: [
      {
        // _id: "",
        id: "clothing",
        name: "Clothing",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
            // _id: "",
            english: {
              heading: "",
              html: ""
            },
            arabic: {
              heading: "",
              html: ""
            },
            pos: ""
          }*/
        ]
      },
      {
        // _id: "",
        id: "imageBlock1",
        name: "Image Block 1",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
            // _id: "",
            english: {
              heading: "",
              html: ""
            },
            arabic: {
              heading: "",
              html: ""
            },
            pos: ""
          }*/
        ]
      },
      {
        // _id: "",
        id: "imageBlock2",
        name: "Image Block 2",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
            // _id: "",
            english: {
              heading: "",
              html: ""
            },
            arabic: {
              heading: "",
              html: ""
            },
            pos: ""
          }*/
        ]
      },
      {
        // _id: "",
        id: "imageBlock3",
        name: "Image Block 3",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
            // _id: "",
            english: {
              heading: "",
              html: ""
            },
            arabic: {
              heading: "",
              html: ""
            },
            pos: ""
          }*/
        ]
      }
    ],
    women: [
      {
        // _id: "",
        id: "clothing",
        name: "Clothing",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
           // _id: "",
           english: {
           heading: "",
           html: ""
           },
           arabic: {
           heading: "",
           html: ""
           },
           pos: ""
           }*/
        ]
      },
      {
        // _id: "",
        id: "imageBlock1",
        name: "Image Block 1",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
           // _id: "",
           english: {
           heading: "",
           html: ""
           },
           arabic: {
           heading: "",
           html: ""
           },
           pos: ""
           }*/
        ]
      },
      {
        // _id: "",
        id: "imageBlock2",
        name: "Image Block 2",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
           // _id: "",
           english: {
           heading: "",
           html: ""
           },
           arabic: {
           heading: "",
           html: ""
           },
           pos: ""
           }*/
        ]
      },
      {
        // _id: "",
        id: "imageBlock3",
        name: "Image Block 3",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
           // _id: "",
           english: {
           heading: "",
           html: ""
           },
           arabic: {
           heading: "",
           html: ""
           },
           pos: ""
           }*/
        ]
      }
    ],
    kids: [
      {
        // _id: "",
        id: "clothing",
        name: "Clothing",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
           // _id: "",
           english: {
           heading: "",
           html: ""
           },
           arabic: {
           heading: "",
           html: ""
           },
           pos: ""
           }*/
        ]
      },
      {
        // _id: "",
        id: "imageBlock1",
        name: "Image Block 1",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
           // _id: "",
           english: {
           heading: "",
           html: ""
           },
           arabic: {
           heading: "",
           html: ""
           },
           pos: ""
           }*/
        ]
      },
      {
        // _id: "",
        id: "imageBlock2",
        name: "Image Block 2",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
           // _id: "",
           english: {
           heading: "",
           html: ""
           },
           arabic: {
           heading: "",
           html: ""
           },
           pos: ""
           }*/
        ]
      },
      {
        // _id: "",
        id: "imageBlock3",
        name: "Image Block 3",
        subCategoryId: "",
        subCategoryName: "",
        enabled: false,
        english: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        arabic: {
          heading: "",
          subHeading: "",
          imageUrl: ""
        },
        list: [
          /*{
           // _id: "",
           english: {
           heading: "",
           html: ""
           },
           arabic: {
           heading: "",
           html: ""
           },
           pos: ""
           }*/
        ]
      }
    ]
  },
  isSubmit: true,
  resetDisabled: true,
  selectedTab: SIZE_CHART_PANEL.SEO,
  selectedCategory: "",
  selectedBrand: {},
  selectedSubCategory: [],
  updateSubCategory: {},
  showNav: false,
  selectedGenericCategoryIndex: -1,
  selectedGenericSubCategory: {},
  updatedSelectedGenericSubCategory: {},
  brandsList: [],
  subCategoryList: []
};

const handleReset = state => {
  const _id = state.updateSubCategory._id;
  if (_id) {
    const resetData =
      (state.updateBrandSizeChart[state.selectedTab] &&
        state.updateBrandSizeChart[state.selectedTab].brandList &&
        state.updateBrandSizeChart[state.selectedTab].brandList.find(
          item => item._id === _id
        )) ||
      {};
    return { ...state, updateSubCategory: { ...resetData }, selectedSubCategory: [] };
  } else {
    return { ...state, updateSubCategory: {}, selectedSubCategory: [] };
  }
};

const handleInputTextChange = (
  state,
  { containerName, category, language, key, value }
) => {
  return {
    ...state,
    [containerName]: {
      ...state[containerName],
      [category]: {
        ...state[containerName][category],
        [language]: {
          ...state[containerName][category][language],
          [key]: value
        }
      }
    }
  };
};

const changeCkEditorText = (state, { key, value }) => ({
  ...state,
  updateSubCategory: { ...state.updateSubCategory, [key]: value }
});

const textChangeHandlerGenericSizeChart = (
  state,
  { categoryKey, index, key, value, language }
) => {
  return {
    ...state,
    updateGenericSizeChart: {
      ...state.updateGenericSizeChart,
      [categoryKey]: [
        ...state["updateGenericSizeChart"][categoryKey].slice(0, index),
        {
          ...state["updateGenericSizeChart"][categoryKey][index],
          [language]: {
            ...state["updateGenericSizeChart"][categoryKey][index][language],
            [key]: value
          }
        },
        ...state["updateGenericSizeChart"][categoryKey].slice(index + 1)
      ]
    }
  };
};

const changeHandlerGenericSizeChart = (
  state,
  { categoryKey, index, key, value }
) => {
  return {
    ...state,
    updateGenericSizeChart: {
      ...state.updateGenericSizeChart,
      [categoryKey]: [
        ...state["updateGenericSizeChart"][categoryKey].slice(0, index),
        {
          ...state["updateGenericSizeChart"][categoryKey][index],
          [key]: value
        },
        ...state["updateGenericSizeChart"][categoryKey].slice(index + 1)
      ]
    }
  };
};

const changeSizeChartCategory = (state, { key, category }) => ({
  ...state,
  selectedTab: key,
  selectedCategory: category
});

const changeSizeChartNav = (state, { category }) => {
  if (category === state.selectedCategory) {
    return {
      ...state,
      selectedCategory: category,
      showNav: !state.showNav,
      selectedTab: SIZE_CHART_PANEL.MEN
    };
  } else {
    return {
      ...state,
      selectedCategory: category,
      showNav: true,
      selectedTab: SIZE_CHART_PANEL.MEN
    };
  }
};

const changeSelectedBrand = (state, { data }) => ({
  ...state,
  selectedBrand: data
});

const changeSelectedSubCategory = (state, { data }) => {
  let updatedData = data ? cloneDeep(data) : [];
  return {
    ...state,
    selectedSubCategory: updatedData
  }
};

const fetchBrandsList = (state, { list }) => ({
  ...state,
  brandsList: list
});

const fetchSubCategoryList = (state, { list }) => ({
  ...state,
  subCategoryList: list
});

const changeSelectedGenericCategory = (state, { categoryIndex }) => ({
  ...state,
  updateMeta: {
    ...initialState.meta,
    ...((state.apiContent && state.apiContent.meta) || {})
  },
  updateGeneral: {
    ...initialState.general,
    ...((state.apiContent && state.apiContent.general) || {})
  },
  updateBrandSizeChart: {
    men: {
      ...initialState.brandSizeChart.men,
      ...((state.apiContent &&
        state.apiContent.brandSizeChart &&
        state.apiContent.brandSizeChart.men) ||
        {})
    },
    women: {
      ...initialState.brandSizeChart.women,
      ...((state.apiContent &&
        state.apiContent.brandSizeChart &&
        state.apiContent.brandSizeChart.women) ||
        {})
    },
    kids: {
      ...initialState.brandSizeChart.kids,
      ...((state.apiContent &&
        state.apiContent.brandSizeChart &&
        state.apiContent.brandSizeChart.kids) ||
        {})
    }
  },
  updateGenericSizeChart: {
    men: [
      ...(state.apiContent &&
        state.apiContent.genericSizeChart &&
        state.apiContent.genericSizeChart.men
        ? state.apiContent.genericSizeChart.men
        : initialState.genericSizeChart.men)
    ],
    women: [
      ...(state.apiContent &&
        state.apiContent.genericSizeChart &&
        state.apiContent.genericSizeChart.women
        ? state.apiContent.genericSizeChart.women
        : initialState.genericSizeChart.women)
    ],
    kids: [
      ...(state.apiContent &&
        state.apiContent.genericSizeChart &&
        state.apiContent.genericSizeChart.kids
        ? state.apiContent.genericSizeChart.kids
        : initialState.genericSizeChart.kids)
    ]
  },
  selectedGenericCategoryIndex: categoryIndex
});

const changeSelectedGenericSubCategory = (state, { subCategory }) => ({
  ...state,
  selectedGenericSubCategory: subCategory,
  updatedSelectedGenericSubCategory: subCategory
});

const clearSelectedGenericSubCategory = state => ({
  ...state,
  selectedGenericSubCategory: {
    english: { html: "", heading: "" },
    arabic: { html: "", heading: "" }
  },
  updatedSelectedGenericSubCategory: {
    english: { html: "", heading: "" },
    arabic: { html: "", heading: "" }
  }
});

const updateSizeChartData = (state, { result }) => ({
  ...state,
  apiContent: {
    ...result,
    meta: { ...initialState.meta, ...((result && result.meta) || {}) },
    general: { ...initialState.general, ...((result && result.general) || {}) },
    brandSizeChart: {
      men: {
        ...initialState.brandSizeChart.men,
        ...((result && result.brandSizeChart && result.brandSizeChart.men) ||
          {})
      },
      women: {
        ...initialState.brandSizeChart.women,
        ...((result && result.brandSizeChart && result.brandSizeChart.women) ||
          {})
      },
      kids: {
        ...initialState.brandSizeChart.kids,
        ...((result && result.brandSizeChart && result.brandSizeChart.kids) ||
          {})
      }
    },
    genericSizeChart: {
      men: [
        ...(result && result.genericSizeChart && result.genericSizeChart.men
          ? result.genericSizeChart.men
          : initialState.genericSizeChart.men)
      ],
      women: [
        ...(result && result.genericSizeChart && result.genericSizeChart.women
          ? result.genericSizeChart.women
          : initialState.genericSizeChart.women)
      ],
      kids: [
        ...(result && result.genericSizeChart && result.genericSizeChart.kids
          ? result.genericSizeChart.kids
          : initialState.genericSizeChart.kids)
      ]
    }
  },
  updateMeta: { ...initialState.meta, ...((result && result.meta) || {}) },
  updateGeneral: {
    ...initialState.general,
    ...((result && result.general) || {})
  },
  updateBrandSizeChart: {
    men: {
      ...initialState.brandSizeChart.men,
      ...((result && result.brandSizeChart && result.brandSizeChart.men) || {})
    },
    women: {
      ...initialState.brandSizeChart.women,
      ...((result && result.brandSizeChart && result.brandSizeChart.women) ||
        {})
    },
    kids: {
      ...initialState.brandSizeChart.kids,
      ...((result && result.brandSizeChart && result.brandSizeChart.kids) || {})
    }
  },
  updateGenericSizeChart: {
    men: [
      ...(result && result.genericSizeChart && result.genericSizeChart.men
        ? result.genericSizeChart.men
        : initialState.genericSizeChart.men)
    ],
    women: [
      ...(result && result.genericSizeChart && result.genericSizeChart.women
        ? result.genericSizeChart.women
        : initialState.genericSizeChart.women)
    ],
    kids: [
      ...(result && result.genericSizeChart && result.genericSizeChart.kids
        ? result.genericSizeChart.kids
        : initialState.genericSizeChart.kids)
    ]
  }
});

const resetSizeChartContent = state => ({
  ...state,
  updateMeta: {
    ...initialState.meta,
    ...((state.apiContent && state.apiContent.meta) || {})
  },
  updateGeneral: {
    ...initialState.general,
    ...((state.apiContent && state.apiContent.general) || {})
  },
  updateBrandSizeChart: {
    men: {
      ...initialState.brandSizeChart.men,
      ...((state.apiContent &&
        state.apiContent.brandSizeChart &&
        state.apiContent.brandSizeChart.men) ||
        {})
    },
    women: {
      ...initialState.brandSizeChart.women,
      ...((state.apiContent &&
        state.apiContent.brandSizeChart &&
        state.apiContent.brandSizeChart.women) ||
        {})
    },
    kids: {
      ...initialState.brandSizeChart.kids,
      ...((state.apiContent &&
        state.apiContent.brandSizeChart &&
        state.apiContent.brandSizeChart.kids) ||
        {})
    }
  },
  updateGenericSizeChart: {
    men: [
      ...(state.apiContent &&
        state.apiContent.genericSizeChart &&
        state.apiContent.genericSizeChart.men
        ? state.apiContent.genericSizeChart.men
        : initialState.genericSizeChart.men)
    ],
    women: [
      ...(state.apiContent &&
        state.apiContent.genericSizeChart &&
        state.apiContent.genericSizeChart.women
        ? state.apiContent.genericSizeChart.women
        : initialState.genericSizeChart.women)
    ],
    kids: [
      ...(state.apiContent &&
        state.apiContent.genericSizeChart &&
        state.apiContent.genericSizeChart.kids
        ? state.apiContent.genericSizeChart.kids
        : initialState.genericSizeChart.kids)
    ]
  }
});

const resetSizeChartGenericContent = (state, { categoryKey, index }) => ({
  ...state,
  updateGenericSizeChart: {
    ...state.updateGenericSizeChart,
    [categoryKey]: [
      ...state["updateGenericSizeChart"][categoryKey].slice(0, index),
      {
        ...state["updateGenericSizeChart"][categoryKey][index],
        english:
          state.apiContent &&
          state.apiContent.genericSizeChart &&
          state.apiContent.genericSizeChart[categoryKey] &&
          state.apiContent.genericSizeChart[categoryKey][index] &&
          state.apiContent.genericSizeChart[categoryKey][index].english,
        arabic:
          state.apiContent &&
          state.apiContent.genericSizeChart &&
          state.apiContent.genericSizeChart[categoryKey] &&
          state.apiContent.genericSizeChart[categoryKey][index] &&
          state.apiContent.genericSizeChart[categoryKey][index].arabic,
        subCategoryName:
          state.apiContent &&
          state.apiContent.genericSizeChart &&
          state.apiContent.genericSizeChart[categoryKey] &&
          state.apiContent.genericSizeChart[categoryKey][index] &&
          state.apiContent.genericSizeChart[categoryKey][index].subCategoryName,
        subCategoryId:
          state.apiContent &&
          state.apiContent.genericSizeChart &&
          state.apiContent.genericSizeChart[categoryKey] &&
          state.apiContent.genericSizeChart[categoryKey][index] &&
          state.apiContent.genericSizeChart[categoryKey][index].subCategoryId
      },
      ...state["updateGenericSizeChart"][categoryKey].slice(index + 1)
    ]
  }
});

const updateSubCategoryData = (state, { data }) => ({
  ...state,
  updateSubCategory: { ...state.updateSubCategory, ...data }
});

const clearSubCategoryData = state => ({
  ...state,
  updateSubCategory: {},
  selectedSubCategory: []
});

const handleGeneralChange = (state, { name, value, language, keyType }) => ({
  ...state,
  [keyType]: {
    ...state[keyType],
    [language]: { ...state[keyType][language], [name]: value }
  }
});

const textChangeHandlerSelectedGenericSubCategory = (
  state,
  { key, value, language }
) => ({
  ...state,
  updatedSelectedGenericSubCategory: {
    ...state.updatedSelectedGenericSubCategory,
    [language]: {
      ...state.updatedSelectedGenericSubCategory[language],
      [key]: value
    }
  }
});

const categoriesChangeHandlerSelectedGenericSubCategory = (
  state,
  { value }
) => ({
  ...state,
  updatedSelectedGenericSubCategory: {
    ...state.updatedSelectedGenericSubCategory,
    subCategory: value
  }
});

const sizeChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SIZE_CHART_CATEGORY:
      return changeSizeChartCategory(state, action);
    case CHANGE_SIZE_CHART_NAV:
      return changeSizeChartNav(state, action);
    case CHANGE_SELECTED_BRAND:
      return changeSelectedBrand(state, action);
    case FETCH_BRANDS_LIST:
      return fetchBrandsList(state, action);
    case FETCH_SUB_CATEGORY_LIST:
      return fetchSubCategoryList(state, action);
    case CHANGE_SELECTED_GENERIC_CATEGORY:
      return changeSelectedGenericCategory(state, action);
    case UPDATE_SIZE_CHART_DATA:
      return updateSizeChartData(state, action);
    case RESET_SIZE_CHART_CONTENT:
      return resetSizeChartContent(state);
    case HANDLE_SIZE_CHART_INPUT_TEXT_CHANGE:
      return handleInputTextChange(state, action);
    case CHANGE_SELECTED_SUB_CATEGORY:
      return changeSelectedSubCategory(state, action);
    case CHANGE_CK_EDITOR_TEXT:
      return changeCkEditorText(state, action);
    case UPDATE_SUB_CATEGORY_DATA:
      return updateSubCategoryData(state, action);
    case CLEAR_SUB_CATEGORY_DATA:
      return clearSubCategoryData(state);
    case HANDLE_GENERAL_CHANGE:
      return handleGeneralChange(state, action);
    case HANDLE_GENERIC_SIZE_CHART_INPUT_TEXT_CHANGE:
      return textChangeHandlerGenericSizeChart(state, action);
    case HANDLE_GENERIC_SIZE_CHART_CHANGE:
      return changeHandlerGenericSizeChart(state, action);
    case RESET_SIZE_CHART_GENERIC_CONTENT:
      return resetSizeChartGenericContent(state, action);
    case CHANGE_SELECTED_GENERIC_SUB_CATEGORY:
      return changeSelectedGenericSubCategory(state, action);
    case CLEAR_SELECTED_GENERIC_SUB_CATEGORY:
      return clearSelectedGenericSubCategory(state, action);
    case HANDLE_SELECTED_GENERIC_SUB_CATEGORY_INPUT_TEXT_CHANGE:
      return textChangeHandlerSelectedGenericSubCategory(state, action);
    case HANDLE_RESET:
      return handleReset(state);
    case HANDLE_SELECTED_GENERIC_SUB_CATEGORY_CATEGORIES_CHANGE:
      return categoriesChangeHandlerSelectedGenericSubCategory(state, action);
    default:
      return state;
  }
};

export default sizeChartReducer;
