import React, { Component, Fragment } from "react";
import CKEditor from "ckeditor4-react";
import isEqual from "lodash/isEqual";
import Button from "../../component/button";
import {
  CK_CONFIG_ENGLISH,
  CK_CONFIG_ARABIC,
  STANDARD_CK_CONFIG
} from "../../constants";
import { ENV_CONFIG } from "../../config/env";
import CKEditorNotes from "./../../component/ckEditorNotes";
import SearchSubCategories from "./searchDropDown";

class AddUpdateSubCategory extends Component {
  state = {
    enable: false,
    selectedSubCategory: []
  };

  UNSAFE_componentWillMount() {
    const { updateContent } = this.props;
    this.setState({ selectedSubCategory: updateContent.subCategory || [] });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updateSubCategory, updateContent } = nextProps;
    const subCategory = ((nextProps.selectedSubCategory &&
      nextProps.selectedSubCategory.length) ? nextProps.selectedSubCategory : updateContent.subCategory) || [];

    if (
      ((nextProps.selectedSubCategory &&
        nextProps.selectedSubCategory.length) ||
        (updateContent.subCategory && updateContent.subCategory.length)) &&
      updateSubCategory.englishText &&
      updateSubCategory.arabicText
    ) {
      this.setState({
        selectedSubCategory: subCategory,
        enable:
          !isEqual(updateSubCategory, updateContent) ||
            !isEqual(
              updateSubCategory.arabicText,
              this.props.updateSubCategory.arabicText
            ) ||
            !isEqual(
              updateSubCategory.englishText,
              this.props.updateSubCategory.englishText
            ) ||
            (!isEqual(
              nextProps.selectedSubCategory,
              this.props.selectedSubCategory
            ) && !isEqual(
              nextProps.selectedSubCategory,
              []
            ))
            ? true
            : false
      });
    } else {
      this.setState({
        selectedSubCategory: subCategory,
        enable: false
      });
    }
  }

  onChange = (e, key) => {
    const { changeHandler } = this.props;
    const value = e.editor.getData();
    changeHandler(key, value);
  };

  handleSave = () => {
    const {
      updateSubCategory,
      countryId,
      selectedTab,
      apiContent,
      selectedBrand,
      submitSubCategoryList,
      clearSubCategoryData,
      closeBothContent,
      selectedSubCategory: selectedSub,
      updateContent
    } = this.props;
    submitSubCategoryList(apiContent._id, {
      type: selectedTab,
      countryId,
      subCategoryObj: {
        _id: updateSubCategory._id || null,
        brandId: updateSubCategory.brandId || selectedBrand.id,
        brandName: updateSubCategory.brandName || selectedBrand.brandname,
        subCategory:
          (selectedSub && selectedSub.length && selectedSub) ||
          updateContent.subCategory,
        englishText: updateSubCategory.englishText,
        arabicText: updateSubCategory.arabicText
      }
    });
    clearSubCategoryData();
    closeBothContent();
  };

  render() {
    const { enable, selectedSubCategory } = this.state;
    const {
      subCategoryList,
      changeSelectedSubCategory,
      fetchSubCategoryData,
      updateSubCategory,
      storeId,
      countryId,
      isUpdate,
      handleReset
    } = this.props;
    return (
      <Fragment>
        <div className="data-wrapper">
          <div className="english-wrapper">
            <SearchSubCategories
              isDisabled={!!isUpdate}
              title={"Sub Category"}
              placeHolder={"Please Select Sub Category"}
              searchList={subCategoryList}
              selectedData={selectedSubCategory}
              changeSelectedData={changeSelectedSubCategory}
              onTextChange={fetchSubCategoryData}
            />
          </div>
          <div className="marginTop-20">
            <label>
              <span className="required" />
              Text Editor(English)
            </label>
            <CKEditor
              activeClass="p10"
              config={{
                ...CK_CONFIG_ENGLISH,
                ...STANDARD_CK_CONFIG,
                ...{
                  filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                    }/homepage/content/uploadCK?id=${storeId}&key=englishText&language=${"english"}&storeId=${storeId}&countryId=${countryId}&containerName=${"brandSizeChart"}`
                },
                removeButtons: STANDARD_CK_CONFIG.removeButtons
              }}
              data={updateSubCategory && updateSubCategory.englishText}
              onChange={e => {
                this.onChange(e, "englishText");
              }}
              onKey={e => {
                this.onChange(e, "englishText");
              }}
            />
            <CKEditorNotes display={[2]} />
            <label>
              <span className="required" />
              Text Editor(Arabic)
            </label>
            <CKEditor
              activeClass="p10"
              config={{
                ...CK_CONFIG_ARABIC,
                ...STANDARD_CK_CONFIG,
                ...{
                  filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                    }/homepage/content/uploadCK?id=${storeId}&key=arabicText&language=${"arabic"}&storeId=${storeId}&countryId=${countryId}&containerName=${"brandSizeChart"}`
                },
                removeButtons: STANDARD_CK_CONFIG.removeButtons
              }}
              data={updateSubCategory && updateSubCategory.arabicText}
              onChange={e => {
                this.onChange(e, "arabicText");
              }}
              onKey={e => {
                this.onChange(e, "arabicText");
              }}
            />
            <CKEditorNotes noMargin={true} display={[2]} />
          </div>
        </div>
        <div className="m-l-20 marginField">
          <Button
            name={"Save"}
            disabled={false}
            onClick={enable ? this.handleSave : null}
            customClass={enable ? "" : "opacity disable-btn"}
          />
          <Button
            name={"Cancel"}
            disabled={false}
            onClick={enable ? handleReset : null}
            customClass={enable ? "" : "opacity disable-btn"}
          />
        </div>
      </Fragment>
    );
  }
}

export default AddUpdateSubCategory;
