import React, { Fragment } from "react";
import upperFirst from "lodash/upperFirst";
import get from "lodash/get";
import Image from "../../component/Image";
import InputText from "../../component/InputText";
import infoIcon from "../../images/icon-info.svg";
import {
  CAROUSEL_LIST_PLACEHOLDERS,
  IMAGE_SIZE_AND_TYPE_TOOLTIP,
  LANGUAGE
} from "../../constants";

const CarousalListForm = ({
  handleCarouselListChange,
  handleCarouselListUrlChange,
  handleModal,
  listItem,
  keyIndex,
  language
}) => (
  <div className={`${language}-wrapper`}>
    <h4>{upperFirst(language)} Version</h4>
    <div className={`${language}-content`}>
      <InputText
        wrapperClassName="marginBottom"
        label="Redirection Link (must start with '/')"
        name="redirectUrl"
        value={get(listItem, "redirectUrl", "")}
        language={language}
        disabled={language === LANGUAGE.ARABIC}
        onChange={e =>
          handleCarouselListUrlChange(e.target.name, e.target.value, keyIndex)
        }
        placeholder={CAROUSEL_LIST_PLACEHOLDERS[language].redirectUrl}
        disableWhitespaces
        required
      />
      <div className="img-preview">
        <span className="info img-info category-img-info">
          <Image src={infoIcon} alt="info icon" />
          <p className="tooltiptext">{IMAGE_SIZE_AND_TYPE_TOOLTIP}</p>
        </span>
        <div className="form-group preview hover-icon m-r-20">
          <span className="img-title">
            <span className="required" />
            Category Image
          </span>
          {get(listItem, "imageUrl") ? (
            <Fragment>
              <span>
                <span className="img-overlay" />
                <span
                  className="icon-close"
                  onClick={() =>
                    handleCarouselListChange("imageUrl", "", keyIndex, language)
                  }
                />
              </span>
              <span
                className="hover-camera-icon"
                onClick={() => handleModal("imageSelectionModal", language)}
              />
              <span className="uploaded-image">
                <Image
                  className="img-dimension"
                  alt="No Image"
                  src={get(listItem, "imageUrl", "")}
                />
              </span>
            </Fragment>
          ) : (
            <span
              className="camera-icon"
              onClick={() => handleModal("imageSelectionModal", language)}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

export default CarousalListForm;
