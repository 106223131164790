import { generateUUID } from "../../util";

export const initialProductCarouselSection = () => ({
  english: {
    title: "",
    searchUrl: ""
  },
  arabic: {
    title: "",
    searchUrl: ""
  },
  schedulers: [],
  enable: false,
  type: "product-carousel",
  id: generateUUID(),
  openAccordion: true
});
