export const SET_SECTIONS_ACTIVE_PAGE = "SET_SECTIONS_ACTIVE_PAGE";
export const ADD_SECTIONS_PAGE_TYPE = "ADD_SECTIONS_PAGE_TYPE";
export const ADD_NEW_SECTIONS_COMPONENT = "ADD_NEW_SECTIONS_COMPONENT";
export const SECTIONS_PAGE_OPEN_COMPONENT = "SECTIONS_PAGE_OPEN_COMPONENT";
export const SECTIONS_PAGE_TOGGLE_COMPONENT = "SECTIONS_PAGE_TOGGLE_COMPONENT";
export const MODULE_DATA_CHANGE_SECTIONS_PAGE =
  "MODULE_DATA_CHANGE_SECTIONS_PAGE";
export const GENERATE_IMAGES_SECTIONS_PAGE = "GENERATE_IMAGES_SECTIONS_PAGE";
export const UPDATE_BANNER_SECTION_DATA_STYLES_SECTIONS_PAGE =
  "UPDATE_BANNER_SECTION_DATA_STYLES_SECTIONS_PAGE";
export const PUT_BANNER_DATA_STYLES_SECTIONS_PAGE =
  "PUT_BANNER_DATA_STYLES_SECTIONS_PAGE";
export const CLONE_MOBILE_SECTIONS_PAGE = "CLONE_MOBILE_SECTIONS_PAGE";
export const UPDATE_LIST_DATA_SECTIONS_PAGE = "UPDATE_LIST_DATA_SECTIONS_PAGE";
export const CLONE_ROW_SECTIONS_PAGE = "CLONE_ROW_SECTIONS_PAGE";
export const DELETE_LIST_ITEM_SECTIONS_PAGE = "DELETE_LIST_ITEM_SECTIONS_PAGE";
export const TOGGLE_LIST_ITEM_SECTIONS_PAGE = "TOGGLE_LIST_ITEM_SECTIONS_PAGE";
export const UPDATE_BANNER_SECTION_DATA_SECTIONS_PAGE =
  "UPDATE_BANNER_SECTION_DATA_SECTIONS_PAGE";
export const ADD_BANNER_ROW_ITEM_SECTIONS_PAGE =
  "ADD_BANNER_ROW_ITEM_SECTIONS_PAGE";
export const ADD_BANNER_SLIDER_ROW_IMAGES_SECTIONS_PAGE =
  "ADD_BANNER_SLIDER_ROW_IMAGES_SECTIONS_PAGE"
export const ADD_BANNER_SCHEDULER_SECTIONS_PAGE =
  "ADD_BANNER_SCHEDULER_SECTIONS_PAGE";
export const HANDLE_DATA_CHANGE_BANNER_SCHEDULER_SECTIONS_PAGE =
  "HANDLE_DATA_CHANGE_BANNER_SCHEDULER_SECTIONS_PAGE";
export const HANDLE_ROW_SCHEDULER_TOGGLE_SECTIONS_PAGE =
  "HANDLE_ROW_SCHEDULER_TOGGLE_SECTIONS_PAGE";
export const HANDLE_DELETE_BANNER_SCHEDULER_SECTIONS_PAGE =
  "HANDLE_DELETE_BANNER_SCHEDULER_SECTIONS_PAGE";
export const COPY_CONTENT_TO_SCHEDULER_SECTIONS_PAGE =
  "COPY_CONTENT_TO_SCHEDULER_SECTIONS_PAGE";
export const CLONE_SCHEDULER_SECTIONS_PAGE = "CLONE_SCHEDULER_SECTIONS_PAGE";
export const SECTIONS_PAGE_DELETE_COMPONENT = "SECTIONS_PAGE_DELETE_COMPONENT";
export const SET_SECTIONS_PAGE_DATA = "SET_SECTIONS_PAGE_DATA";
export const SET_SECTIONS_MODULES = "SET_SECTIONS_MODULES";
export const SET_MODULE_SECTIONS_PAGE = "SET_MODULE_SECTIONS_PAGE";
export const TOGGLE_RESET_SECTIONS_PAGE = "TOGGLE_RESET_SECTIONS_PAGE";
export const RESET_SECTIONS_CONTENT = "RESET_SECTIONS_CONTENT";
export const CLEAR_BANNER_ROW_IMAGE_DATA_SECTIONS_PAGE =
  "CLEAR_BANNER_ROW_IMAGE_DATA_SECTIONS_PAGE";
export const CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_SECTIONS_PAGE =
  "CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_SECTIONS_PAGE";
export const DELETE_ROW_IMAGE_SECTIONS_PAGE = "DELETE_ROW_IMAGE_SECTIONS_PAGE";
export const REORDER_BANNER_ROW_IMAGES_SECTIONS_PAGE =
  "REORDER_BANNER_ROW_IMAGES_SECTIONS_PAGE";
export const ITEMS_PER_GROUP_CHANGE_SECTIONS_PAGE =
  "ITEMS_PER_GROUP_CHANGE_SECTIONS_PAGE";
export const UPDATE_ROW_TYPE_CUSTOM_DATA_SECTIONS_PAGE =
  "UPDATE_ROW_TYPE_CUSTOM_DATA_SECTIONS_PAGE";
export const CHANGE_BANNER_IMAGE_HOVER_STYLE_SECTIONS_PAGE =
  "CHANGE_BANNER_IMAGE_HOVER_STYLE_SECTIONS_PAGE";
export const UPDATE_BANNER_ROW_IMAGE_DATA_SECTIONS_PAGE =
  "UPDATE_BANNER_ROW_IMAGE_DATA_SECTIONS_PAGE";
export const COUNTDOWN_DATA_CHANGE_SECTIONS_PAGE =
  "COUNTDOWN_DATA_CHANGE_SECTIONS_PAGE";
export const UPDATE_BANNER_DEFAULT_TEXT_SECTIONS_PAGE =
  "UPDATE_BANNER_DEFAULT_TEXT_SECTIONS_PAGE";
export const PRODUCT_CAROUSEL_DATA_CHANGE_SECTIONS_PAGE =
  "PRODUCT_CAROUSEL_DATA_CHANGE_SECTIONS_PAGE";
export const ADD_ROW_TYPE_CUSTOM_FIELD_SECTIONS_PAGE =
  "ADD_ROW_TYPE_CUSTOM_FIELD_SECTIONS_PAGE";
export const UPDATE_ROW_TYPE_CUSTOM_MODULE_SECTIONS_PAGE =
  "UPDATE_ROW_TYPE_CUSTOM_MODULE_SECTIONS_PAGE";
export const REMOVE_ROW_TYPE_CUSTOM_FIELD_SECTIONS_PAGE =
  "REMOVE_ROW_TYPE_CUSTOM_FIELD_SECTIONS_PAGE";
export const APPLY_GENERAL_SPACING_SECTIONS_PAGE =
  "APPLY_GENERAL_SPACING_SECTIONS_PAGE";
export const UPDATE_MODULE_SECTIONS_PAGE = "UPDATE_MODULE_SECTIONS_PAGE";
export const UPDATE_MODULE_PAGE = "UPDATE_MODULE_PAGE";
export const ADD_ROW_TYPE_ACCORDION_FIELD_SECTIONS_PAGE = "ADD_ROW_TYPE_ACCORDION_FIELD_SECTIONS_PAGE";
export const UPDATE_ROW_TYPE_ACCORDION_DATA_SECTIONS_PAGE = "UPDATE_ROW_TYPE_ACCORDION_DATA_SECTIONS_PAGE";
export const UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_SECTIONS_PAGE = "UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_SECTIONS_PAGE";
export const REMOVE_ROW_TYPE_ACCORDION_FIELD_SECTIONS_PAGE = "REMOVE_ROW_TYPE_ACCORDION_FIELD_SECTIONS_PAGE";
export const ADD_ROW_TYPE_BULLET_FIELD_SECTIONS_PAGE = "ADD_ROW_TYPE_BULLET_FIELD_SECTIONS_PAGE";
export const UPDATE_ROW_TYPE_BULLET_DATA_SECTIONS_PAGE = "UPDATE_ROW_TYPE_BULLET_DATA_SECTIONS_PAGE";
export const UPDATE_ROW_TYPE_BULLET_HEADER_DATA_SECTIONS_PAGE = "UPDATE_ROW_TYPE_BULLET_HEADER_DATA_SECTIONS_PAGE";
export const REMOVE_ROW_TYPE_BULLET_FIELD_SECTIONS_PAGE = "REMOVE_ROW_TYPE_BULLET_FIELD_SECTIONS_PAGE";
export const SET_SECTIONS_ROLLBACK_HISTORY = "SET_SECTIONS_ROLLBACK_HISTORY";
export const ADD_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE = "ADD_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE";
export const REMOVE_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE = "REMOVE_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE";
export const ADD_ROW_TYPE_COLUMN_MENU_SECTIONS_PAGE = "ADD_ROW_TYPE_COLUMN_MENU_SECTIONS_PAGE";
export const UPDATE_ROW_TYPE_COLUMN_MENU_SECTIONS_PAGE = "UPDATE_ROW_TYPE_COLUMN_MENU_SECTIONS_PAGE";
export const DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE = "DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_SECTIONS_PAGE";