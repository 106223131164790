import React from "react";
import map from "lodash/map";
import Accordion from "../../component/accordion";
import { BANNER_NOTES_TOPICS } from "../../constants";

const BannerNotes = () => {
  return (
    <Accordion
      className="banner-helping-html"
      title="Banner Helping HTML Classes"
    >
      <div>
        {map(BANNER_NOTES_TOPICS, (topics, key) => (
          <div key={key}>
            <h4 className="sub-heading">
              <span className="required"></span>
              <span className="required"></span>
              {key}:
            </h4>
            <ul>
              {topics.map(topic => (
                <li key={`key_${topic.code}`}>
                  <code>{topic.code}</code> {topic.text}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Accordion>
  );
};

export default BannerNotes;
