import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import ReactTooltip from "react-tooltip";
import Button from "../../component/button";
import { MESSAGES, ERROR_MESSAGES } from "../../constants";
import { toastMsg } from "../../actions/common.action";

class GeneralTab extends Component {
  state = { enable: false };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updateGeneral, apiContent } = nextProps;
    if (
      updateGeneral.english &&
      updateGeneral.english.heading &&
      updateGeneral.english &&
      updateGeneral.english.subHeading &&
      updateGeneral.arabic &&
      updateGeneral.arabic.heading &&
      updateGeneral.arabic &&
      updateGeneral.arabic.subHeading
    ) {
      this.setState({
        enable: !isEqual(apiContent.general, updateGeneral)
      });
    } else {
      this.setState({ enable: false });
    }
  }

  validateFormData = (formData, language) => {
    const { toastMsg } = this.props;
    const { heading = "", subHeading = "" } = formData || {};
    if (heading && (heading !== heading.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Heading`);
      return false;
    }
    if (subHeading && (subHeading !== subHeading.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Sub Heading`);
      return false;
    }

    return true;
  }


  handleSave = () => {
    const {
      handleGeneralSave,
      updateGeneral,
      countryId,
      apiContent
    } = this.props;

    const { english = {}, arabic = {} } = updateGeneral || {};

    const isValidEnglishForm = this.validateFormData(english, "English");
    if (!isValidEnglishForm) return;

    const isValidArabicForm = this.validateFormData(arabic, "Arabic");
    if (!isValidArabicForm) return;

    handleGeneralSave(apiContent._id, countryId, updateGeneral);
  };

  render() {
    const { updateGeneral, handleGeneralChange, accessRights } = this.props;
    const { enable } = this.state;
    return (
      <div>
        <h2 className="page-main-heading">General</h2>
        <div className="wrapper-box">
          <div className="data-wrapper">
            <div className="english-wrapper">
              <h4>English Version</h4>
              <div className="english-content">
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading
                  </label>
                  <input
                    type="text"
                    name="heading"
                    className="form-control"
                    placeholder="Heading Text"
                    value={
                      (updateGeneral &&
                        updateGeneral.english &&
                        updateGeneral.english.heading) ||
                      ""
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "english",
                        "updateGeneral"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Sub Heading
                  </label>
                  <input
                    type="text"
                    name="subHeading"
                    className="form-control"
                    placeholder="Heading Text"
                    value={
                      (updateGeneral &&
                        updateGeneral.english &&
                        updateGeneral.english.subHeading) ||
                      ""
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "english",
                        "updateGeneral"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="arabic-wrapper">
              <h4>Arabic Version</h4>
              <div className="arabic-content">
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Heading
                  </label>
                  <input
                    dir="rtl"
                    type="text"
                    name="heading"
                    className="form-control"
                    placeholder="Heading Text"
                    value={
                      (updateGeneral &&
                        updateGeneral.arabic &&
                        updateGeneral.arabic.heading) ||
                      ""
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        "updateGeneral"
                      )
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <span className="required" />
                    Sub Heading
                  </label>
                  <input
                    dir="rtl"
                    type="text"
                    name="subHeading"
                    className="form-control"
                    placeholder="Heading Text"
                    value={
                      (updateGeneral &&
                        updateGeneral.arabic &&
                        updateGeneral.arabic.subHeading) ||
                      ""
                    }
                    onChange={e =>
                      handleGeneralChange(
                        e.target.name,
                        e.target.value,
                        "arabic",
                        "updateGeneral"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
          data-for="size-chart-general"
        >
          <Button
            name={"Save"}
            onClick={enable && accessRights.UPDATE ? this.handleSave : null}
            disabled={!accessRights.UPDATE}
            customClass={enable ? "" : "opacity disable-btn"}
          />
        </span>
        <ReactTooltip place="top" effect="solid" id="size-chart-general" />
      </div>
    );
  }
}

const mapStateToProps = reduxState => ({
});

const mapDispatchToProps = {
  toastMsg,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralTab);
