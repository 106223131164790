import React, { Component } from "react";
import AccordionTile from "./accordionTile";

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultContent: props.isOpen || false
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ defaultContent: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.pageIndex !== this.props.pageIndex ||
      nextProps.pageType !== this.props.pageType
    ) {
      this.setState({ defaultContent: nextProps.isOpen });
    }
  }
  toggleContent = contentName =>
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));

  addTile = () => {
    let index =
      (this.props.list &&
        this.props.list.length &&
        this.props.list[this.props.list.length - 1].pos) ||
      0;
    this.props.addCkStaticAccordTile(index + 1);
  };

  removeTile = index => {
    this.props.removeCkStaticAccordTile(index);
  };

  changeHandler = (index, language, key, value) => {
    this.props.handleAccordTextChange(key, value, language, index);
  };

  render() {
    const { defaultContent } = this.state;
    const { list, storeId, countryId } = this.props;
    let accordionList =
      list &&
      list.map((_item, _index) => {
        return (
          <AccordionTile
            key={`accordion_${_index}`}
            index={_index}
            item={_item}
            remove={this.removeTile}
            changeHandler={this.changeHandler}
            storeId={storeId}
            countryId={countryId}
          />
        );
      });

    return (
      <div className="wrapper-box">
        <div className="heading-wrapper flex catalog-banner-heading">
          <h3>Accordion</h3>
          <span
            onClick={() => this.toggleContent("defaultContent")}
            className={`toggle-banner ${
              defaultContent ? "minus-banner" : "plus-banner"
            }`}
          />
        </div>
        {defaultContent && accordionList}
        {defaultContent && (
          <div className="heading-wrapper flex catalog-banner-heading">
            <h3>Add Tile</h3>
            <span
              onClick={this.addTile}
              className={`toggle-banner plus-banner`}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Accordion;
