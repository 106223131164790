import { createTheme, ThemeProvider } from '@mui/material';
const theme = createTheme({
    typography: {
        fontFamily: [
            'Futura PT Book',
            'Arial',
            'SansSerif'
        ].join(','),
        fontSize: 14,
    },
});

export { theme, ThemeProvider }