import { LIST_USERS, SESSION_TERMINATED } from "../constants/actions";

const initialState = {
  userList: [],
  usersCount: 0
};

const userList = (state, { data, count }) => ({
  ...state,
  userList: data,
  usersCount: count
});

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_TERMINATED:
      return state;
    case LIST_USERS:
      return userList(state, action);
    default:
      return state;
  }
};

export default userManagementReducer;
