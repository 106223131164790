import React from "react";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import get from "lodash/get";
import map from "lodash/map";
import ReactTooltip from "react-tooltip";
import { SIZE_CHART_CATEGORY_MAP, MESSAGES } from "../../constants";
import {
  changeSelectedGenericCategory,
  updateToggleGenericSizeChartData
} from "../../actions/sizechart.action";

const GenericCategoryList = ({
  apiContent,
  changeSelectedGenericCategory,
  countryId,
  selectedTab,
  updateGenericSizeChart,
  updateToggleGenericSizeChartData,
  accessRights
}) => {
  const setSelectedCategory = index => {
    changeSelectedGenericCategory(index);
  };

  const updateToggle = (e, category) => {
    updateToggleGenericSizeChartData(apiContent._id, {
      countryId: countryId,
      enabled: e.target.checked,
      name: category.name,
      recordId: category._id,
      type: selectedTab
    });
  };

  return (
    <div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Sr.</th>
            <th scope="col">Category</th>
            <th scope="col">Size Chart Type</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody className="center-align">
          {map(get(updateGenericSizeChart, selectedTab), (category, index) => (
            <tr key={category._id || `key_${index}`}>
              <th scope="row">{index + 1}</th>
              <th scope="row">{SIZE_CHART_CATEGORY_MAP[selectedTab]}</th>
              <td>{get(category, "english.heading", category.name)}</td>
              <td>
                <span
                  className="action-toggle"
                  data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
                  data-for="generic-category-list"
                >
                  <Toggle
                    checked={category.enabled}
                    icons={false}
                    onChange={
                      accessRights.UPDATE
                        ? e => updateToggle(e, category)
                        : null
                    }
                    disabled={!accessRights.UPDATE}
                  />
                </span>
                <span
                  className={`edit-icon ${
                    accessRights.UPDATE
                      ? "cursor-pointer"
                      : "cursor-pointer-disabled-permission"
                  }`}
                  onClick={
                    accessRights.UPDATE
                      ? () => setSelectedCategory(index)
                      : null
                  }
                  data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
                  data-for="generic-category-list"
                />
              </td>
              <ReactTooltip
                place="top"
                effect="solid"
                id="generic-category-list"
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = state => ({
  apiContent: state.sizeChart.apiContent,
  countryId: state.store.countryId,
  selectedCategory: state.sizeChart.selectedCategory,
  selectedTab: state.sizeChart.selectedTab,
  updateGenericSizeChart: state.sizeChart.updateGenericSizeChart
});

const mapDispatchToProps = {
  changeSelectedGenericCategory,
  updateToggleGenericSizeChartData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericCategoryList);
