import { generateUUID } from "../../util";
import { HOMEPAGE_SECTION_TITLE } from "../homePage";
import { customModuleNameConstants, rowWidthOptions, rowWidthValues } from "../bannerModule";

export const spacingStyles = {
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  marginTop: 0,
  marginBottom: 0
};

export const generalPadding = {
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0
};

const initialDynamicBannerIndicatorStyles = {
  indicatorColor: "#000000",
  backgroundIndicatorColor: "#ffffff"
};

const initialDynamicBannerStyles = () => ({
  backgroundColor: "#FFFFFF",
  backgroundType: "backgroundColor",
  ...spacingStyles
});

const autoScrollDefaultOptions = () => ({
  type: "none",
  speed: 5
});

export const initialDynamicBannerImageStyles = {
  ...spacingStyles,
  hover: {
    opacity: 1,
    transform: "scale(1.025)",
    transition: "transform 0.6s"
  },
  hoverValues: { opacity: 1, transform: 1.025, transition: 0.6 }
};

export const initialDynamicBannerVideoStyles = {
  ...spacingStyles,
  hover: {
    opacity: 1,
    transform: "scale(1.025)",
    transition: "transform 0.6s"
  },
  hoverValues: { opacity: 1, transform: 1.025, transition: 0.6 }
};

export const dynamicBannerItemsTypes = {
  image: "image",
  countdown: "countdown",
  slide: "slide",
  video: "video"
};

export const initialDynamicBannerRow = () => ({
  id: generateUUID(),
  bannerType: "",
  enabledOn: ["resp", "app"],
  imageList: [],
  enable: false,
  styles: spacingStyles,
  indicatorStyles: initialDynamicBannerIndicatorStyles,
  autoScroll: autoScrollDefaultOptions(),
  indicatorType: "none",
  width: rowWidthValues.CONTAINER_WIDTH,
  customWidth: 0,
  english: { title: "", searchUrl: "" },
  arabic: { title: "", searchUrl: "" },
  custom: [{ key: "moduleName", value: customModuleNameConstants.STORYLY }],
  autoPlay: "none",
  bulletType: "none",
  title: "",
  generalPadding: generalPadding,
  visibleImages: 1
});

export const initialCountdown = () => ({
  id: generateUUID(),
  date: null,
  styleType: 0,
  styles: spacingStyles,
  type: dynamicBannerItemsTypes.countdown,
  countdownOption: "center",
  beforeCountDown: initialImage(),
  afterCountDown: initialImage()
});

export const initialImage = () => ({
  id: generateUUID(),
  english: { enabledOn: ["resp", "app"] },
  arabic: { enabledOn: ["resp", "app"] },
  styles: initialDynamicBannerImageStyles,
  type: dynamicBannerItemsTypes.image
});

export const initialSlide = () => ({
  id: generateUUID(),
  english: { enabledOn: ["resp", "app"] },
  arabic: { enabledOn: ["resp", "app"] },
  styles: spacingStyles,
  type: dynamicBannerItemsTypes.slide
});

export const initialVideo = () => ({
  id: generateUUID(),
  english: { videoUrl: "", enabledOn: ["resp", "app"] },
  arabic: { videoUrl: "", enabledOn: ["resp", "app"] },
  styles: initialDynamicBannerVideoStyles,
  type: dynamicBannerItemsTypes.video
});

export const initialDeviceSettings = () => ({
  rows: [],
  styles: initialDynamicBannerStyles(),
  title: ""
});

export const initialDynamicBannerSection = () => ({
  enable: false,
  type: "dynamicBannerModule",
  id: generateUUID(),
  title: HOMEPAGE_SECTION_TITLE.DYNAMIC_BANNER,
  desktop: initialDeviceSettings(),
  mobile: initialDeviceSettings(),
  tablet: initialDeviceSettings(),
  backgroundColor: "#3f3f3f"
});

export const dynamicBannerItems = {
  [dynamicBannerItemsTypes.image]: initialImage,
  [dynamicBannerItemsTypes.countdown]: initialCountdown,
  [dynamicBannerItemsTypes.slide]: initialSlide,
  [dynamicBannerItemsTypes.video]: initialVideo
};
