import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import cn from "classnames";
import isEqual from "lodash/isEqual";
import {
  CATALOG_PANEL,
  STATIC_PAGE_PANEL,
  SECTIONS_PAGE_PANEL,
  CONFIGURATIONS_PAGE_PANEL,
  URL_PAGE_PANEL,
  FLASH_SALE_PAGE_PANEL,
  MESSAGES,
  NAVIGATION,
  URL_TYPES,
} from "../../constants";
import CountryDropDown from "../countryDropdown";
import SubListing from "./pages-options";
import ConfirmModal from "../modal/ConfirmModal";
import Popup from "reactjs-popup";
import ArrowDown from "../../images/arrow-dd.svg";
import PopupContent, {
  AddNewModulePopupContent,
  AddNewSectionPopupContent
} from "../sections/popupContent";
import UrlPopupContent from "../urlCreation/popupContent";
import {
  AddNewConfigPopupContent,
  ConfigPopupContentDelete,
  UpdateConfigPopupContent
} from "../configurations/ConfigPopupContent";
import FolderMinusIcon from "../../images/folder-minus.svg";
import UpdateModulePopupContent from "../sections/UpdateModulePopupContent";
import FlashSalePagePopupContent, {
  AddNewFlashPagePopupContent, UpdateFlashPagePopupContent
} from "../flashSale/popupContent";
import RollbackModalMUI from "../modal/RollbackModalMUI";
import UpdateSectionPopupContent from "../sections/UpdateSectionPopupContent";
import { CreateNewFolderIcon, EditIcon, HistoryIcon, AddIcon, DeleteIcon } from "../../materialUi/icons";
import { Tooltip, AntSwitch, IconButton, Chip } from "../../materialUi/components";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.createNewConfigurationRef = React.createRef();
    this.updateConfigNameRef = React.createRef();
    this.deleteConfigurationRef = React.createRef();
    this.deleteUrlRef = React.createRef();
    this.toggleUrlRef = React.createRef();
    this.deleteFlashSaleRef = React.createRef();
    this.toggleFlashSaleRef = React.createRef();
    this.addFlashSaleRef = React.createRef();
    this.updateFlashSaleRef = React.createRef();

  }

  state = {
    showContent: { navName: "home-page", show: true },
    showSubContent: false,
    Promotion: false,
    Catalog: false,
    Brands: false,
    Html: false,
    Accordion: false,
    Comb_accordion: false,
    header: false,
    Checkout: false,
    confirmModal: false,
    contentToBeSelected: "",
    showSubContentPromotion: false,
    showSubContentCatalog: false,
    showSubContentBrands: false,
    showSubContentHtml: false,
    showSubContentAccordion: false,
    showSubContentComb_accordion: false,
    showSubContentCheckout: false,
    showRollback: false,
    currentActiveConfig: false,
    dynamicValues: {}
  };

  handleNav = pageType => {
    this.setState({
      Promotion: false,
      Catalog: false,
      Brands: false,
      Html: false,
      Accordion: false,
      Comb_accordion: false,
      Checkout: false,
      showSubContentPromotion: false,
      showSubContentCatalog: false,
      showSubContentBrands: false,
      showSubContentHtml: false,
      showSubContentAccordion: false,
      showSubContentComb_accordion: false,
      showSubContentCheckout: false
    });
    this.setState(prevState => {
      return {
        dynamicValues: {
          [pageType]: true,
          [`showSubContent${pageType}`]: !prevState.dynamicValues[
            `showSubContent${pageType}`
          ]
        }
      };
    });
  };

  changeNav = e => {
    const { showContent, confirmModal } = this.state;
    const {
      catalogR,
      staticPageR,
      sectionsPageR,
      configurationsR
    } = this.props;

    if (showContent.navName === "catalog-page") {
      if (
        isEqual(
          catalogR.initialAddPageContent,
          catalogR.catalogAddPageContent
        ) &&
        isEqual(catalogR.initialCatalogPages, catalogR.catalogPages)
      ) {
        e.target.id === showContent.navName
          ? this.setState({
            showContent: {
              navName: e.target.id,
              show: !showContent.show
            }
          })
          : this.setState({
            showContent: { navName: e.target.id, show: true }
          });
        this.props.history.push(e.target.id);
      } else {
        e.target.id === showContent.navName
          ? this.setState({
            showContent: {
              navName: e.target.id,
              show: !showContent.show
            }
          })
          : this.setState({
            confirmModal: !confirmModal,
            contentToBeSelected: e.target.id
          });
      }
    } else if (showContent.navName === "static-page") {
      if (
        isEqual(
          staticPageR.initialAddPageContent,
          staticPageR.staticAddPageContent
        ) &&
        isEqual(staticPageR.initialStaticPages, staticPageR.staticPages)
      ) {
        e.target.id === showContent.navName
          ? this.setState({
            showContent: {
              navName: e.target.id,
              show: !showContent.show
            }
          })
          : this.setState({
            showContent: { navName: e.target.id, show: true }
          });
        this.props.history.push(e.target.id);
      } else {
        e.target.id === showContent.navName
          ? this.setState({
            showContent: {
              navName: e.target.id,
              show: !showContent.show
            }
          })
          : this.setState({
            confirmModal: !confirmModal,
            contentToBeSelected: e.target.id
          });
      }
    } else if (showContent.navName === "sections") {
      if (
        isEqual(
          sectionsPageR.initialAddPageContent,
          sectionsPageR.sectionsAddPageContent
        ) &&
        isEqual(sectionsPageR.initialData, sectionsPageR.sectionsPages)
      ) {
        e.target.id === showContent.navName
          ? this.setState({
            showContent: {
              navName: e.target.id,
              show: !showContent.show
            }
          })
          : this.setState({
            showContent: { navName: e.target.id, show: true }
          });
        this.props.history.push(e.target.id);
      } else {
        e.target.id === showContent.navName
          ? this.setState({
            showContent: {
              navName: e.target.id,
              show: !showContent.show
            }
          })
          : this.setState({
            confirmModal: !confirmModal,
            contentToBeSelected: e.target.id
          });
      }
    } else if (showContent.navName === "configurations") {
      if (
        isEqual(
          configurationsR.initialAddPageContent,
          configurationsR.configurationsAddPageContent
        ) &&
        isEqual(
          configurationsR.initialData,
          configurationsR.configurationsPages
        )
      ) {
        e.target.id === showContent.navName
          ? this.setState({
            showContent: {
              navName: e.target.id,
              show: !showContent.show
            }
          })
          : this.setState({
            showContent: { navName: e.target.id, show: true }
          });
        this.props.history.push(e.target.id);
      } else {
        e.target.id === showContent.navName
          ? this.setState({
            showContent: {
              navName: e.target.id,
              show: !showContent.show
            }
          })
          : this.setState({
            confirmModal: !confirmModal,
            contentToBeSelected: e.target.id
          });
      }
    } else if (showContent.navName === "url-page") {
      this.setState({
        showContent: {
          navName: e.target.id,
          show: !showContent.show
        }
      });
    } else if (showContent.navName === "flash-sale-page") {
      this.setState({
        showContent: {
          navName: e.target.id,
          show: !showContent.show
        }
      });
    }
    else {
      e.target.id === showContent.navName
        ? this.setState({
          showContent: {
            navName: e.target.id,
            show: !showContent.show
          }
        })
        : this.setState({ showContent: { navName: e.target.id, show: true } });
      this.props.history.push(e.target.id);
    }
  };
  hideModal = () =>
    this.setState(prevProps => ({
      confirmModal: !prevProps.confirmModal,
      contentToBeSelected: ""
    }));
  confirmModal = () => {
    const { confirmModal, contentToBeSelected } = this.state;
    this.setState({
      confirmModal: !confirmModal,
      showContent: { navName: contentToBeSelected, show: true },
      contentToBeSelected: ""
    });
    this.props.history.push(contentToBeSelected);
  };

  UNSAFE_componentWillMount() {
    if (Object.values(CATALOG_PANEL).indexOf(this.props.currentTab) > -1) {
      this.setState({ showContent: { navName: "catalog-page", show: true } });
    } else if (
      Object.values(STATIC_PAGE_PANEL).indexOf(this.props.currentTab) > -1
    ) {
      this.setState({ showContent: { navName: "static-page", show: true } });
    } else if (
      Object.values(SECTIONS_PAGE_PANEL).indexOf(this.props.currentTab) > -1
    ) {
      this.setState({ showContent: { navName: "sections", show: true } });
    } else if (
      Object.values(CONFIGURATIONS_PAGE_PANEL).indexOf(this.props.currentTab) >
      -1
    ) {
      this.setState({ showContent: { navName: "configurations", show: true } });
    } else if (
      Object.values(URL_PAGE_PANEL).indexOf(this.props.currentTab) > -1
    ) {
      this.setState({ showContent: { navName: "url-page", show: true } });
    } else if (
      Object.values(FLASH_SALE_PAGE_PANEL).indexOf(this.props.currentTab) > -1
    ) {
      this.setState({ showContent: { navName: "flash-sale-page", show: true } });
    }
  }

  handleOnClickConfig = (e, page, index, section) => {
    const { handlePageClick, changeTab } = this.props;
    handlePageClick(page, index, section);
    changeTab(e);
    this.setState({ currentActiveConfig: section });
  };

  handleOnClickUrlPage = page => {
    const { handlePageClick, changeTab, currentActiveUrlPageId } = this.props;
    if (page._id !== currentActiveUrlPageId) handlePageClick(page);
  };

  handleOnClickFlashPage = page => {
    const { handlePageClick, changeTab, currentActiveFlashPageId } = this.props;
    if (page._id !== currentActiveFlashPageId) handlePageClick(page);
  };

  handleCloseRollbackModal = () => {
    const { closeRollbackModal } = this.props;
    this.setState({ showRollback: false });
    closeRollbackModal();
  };

  handleOnConfirmRollback = (id, index) => {
    const { onConfirmRollback } = this.props;
    onConfirmRollback(id, index);
  }

  handleGetRollbackHistory = (pageId, fromDate, toDate) => {
    const { getRollbackHistory } = this.props;
    getRollbackHistory(pageId, fromDate, toDate);
  }

  componentDidUpdate(prevProps) {
    if (this.props.countryId !== prevProps.countryId) {
      this.setState({ dynamicValues: {} });
    }
  }

  render() {
    const {
      changeTab,
      currentTab,
      catalogData,
      handlePageClick,
      currentTabName,
      staticData,
      sectionsData,
      configurationsData,
      urlData,
      flashSaleData,
      createModuleHandler,
      updateModulePopupContent,
      sectionPopupContentData,
      sectionPopupRef,
      deleteModuleHandler,
      createSectionHandler,
      deleteSectionHandler,
      createConfiguration,
      deleteConfiguration,
      updateConfigName,
      updateSectionHandler,
      updateSecPopupContent,
      rollbackHistory,
      userRole,
      currentActiveUrlPageId,
      currentActiveFlashPageId,
      currentActiveSectionPageId,
      accessRights,
      deleteUrlHandler,
      updateToggleUrlHandler,
      urlChangeTabHandler,
      deleteFlashSalePageHandler,
      updateToggleFlashSaleHandler,
      createNewFlashSalePageData,
      addFlashPagePopupContentHandler,
      createFlashSalePageHandler,
      createFlashPageValidationHandler,
      updateFlashSalePageUrlHandler,
      updateFlashPageValidationHandler,
    } = this.props;

    const {
      showContent,
      confirmModal
    } = this.state;
    const {
      STATIC_ADD_PAGE_DISPLAY,
      STATIC_UPDATE_PAGE_DISPLAY,
      STATIC_DELETE_PAGE_DISPLAY
    } = STATIC_PAGE_PANEL;
    const { SECTIONS_UPDATE_PAGE_DISPLAY } = SECTIONS_PAGE_PANEL;
    return (
      <div className="side-bar">
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={
              "All the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        {
          !["url-page", "flash-sale-page", "sections",
            "static-page", "configurations"]
            .includes(showContent.navName) &&
          (
            <div className="apply-for-dropdown">
              <p>Select Country</p>
              <CountryDropDown changeTab={changeTab} />
            </div>
          )}
        <div className="nav-child">
          <span
            className={cn("open-nav", "cursor-default", {
              "display-none": showContent.navName !== "home-page"
            })}
            id="home-page"
          >
            Home Page
          </span>


          {/*static pages*/}
          <span
            className={`open-nav
              ${showContent.navName === "static-page" && showContent.show
                ? "minus-icon-nav"
                : "plus-icon-nav"
              }
              ${showContent.navName !== "static-page" ? "display-none" : ""}`}
            id="static-page"
            onClick={this.changeNav}
          >
            Static Pages
          </span>
          {showContent.navName === "static-page" && showContent.show && (
            <ul className="static-nav">
              <li
                id={STATIC_ADD_PAGE_DISPLAY}
                name={STATIC_ADD_PAGE_DISPLAY}
                className={
                  !accessRights.WRITE
                    ? "disable-permission"
                    : `
                ${[STATIC_ADD_PAGE_DISPLAY, STATIC_DELETE_PAGE_DISPLAY].indexOf(
                      currentTab
                    ) > -1 && "active"
                    }
                  `
                }
                onClick={accessRights.WRITE ? changeTab : null}
                data-tip={accessRights.WRITE ? "" : MESSAGES.TOOLTIP_WRITE}
                data-for="static-page"
              >
                Add Pages
              </li>
              {Object.keys(staticData).map(
                (pageNames, index) =>
                  staticData[pageNames].length !== 0 &&
                  staticData[pageNames][0] &&
                  staticData[pageNames][0].title && (
                    <li key={index}>
                      <ul>
                        <li
                          className={`static-sub-nav ${this.state.dynamicValues[
                            `showSubContent${pageNames}`
                          ]
                            ? "minus-icon-nav"
                            : "plus-icon-nav"
                            }`}
                          onClick={() => this.handleNav(pageNames)}
                        >
                          {STATIC_PAGE_PANEL[pageNames]}
                        </li>
                        {pageNames === "Html" &&
                          this.state.dynamicValues[
                          `showSubContent${pageNames}`
                          ] && (
                            <SubListing
                              pageContent={staticData[pageNames]}
                              handlePageClick={handlePageClick}
                              id={STATIC_UPDATE_PAGE_DISPLAY}
                              changeTab={changeTab}
                              section={"Html"}
                            />
                          )}
                        {pageNames === "Accordion" &&
                          this.state.dynamicValues[
                          `showSubContent${pageNames}`
                          ] && (
                            <SubListing
                              pageContent={staticData[pageNames]}
                              handlePageClick={handlePageClick}
                              id={STATIC_UPDATE_PAGE_DISPLAY}
                              changeTab={changeTab}
                              section={"Accordion"}
                            />
                          )}
                        {pageNames === "Comb_accordion" &&
                          this.state.dynamicValues[
                          `showSubContent${pageNames}`
                          ] && (
                            <SubListing
                              pageContent={staticData[pageNames]}
                              handlePageClick={handlePageClick}
                              id={STATIC_UPDATE_PAGE_DISPLAY}
                              changeTab={changeTab}
                              section={"Comb_accordion"}
                            />
                          )}
                      </ul>
                    </li>
                  )
              )}
            </ul>
          )}
          {/*sections pages*/}
          <span
            className={`open-nav
              ${showContent.navName === "sections" && showContent.show
                ? "minus-icon-nav folder-arrow-down-opened"
                : "plus-icon-nav"
              }
              ${showContent.navName !== "sections" ? "display-none" : ""}`}
            id="sections"
            onClick={this.changeNav}
          >
            Modules
            <div
              className={cn("page-icons-container", {
                "d-flex justify-content-end": "sectionsAddPageDisplay"
              })}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Popup
                ref={sectionPopupRef}
                trigger={
                  <Tooltip title={accessRights.WRITE ? "Create Module" : MESSAGES.TOOLTIP_WRITE} placement="top" arrow>
                    <span>
                      <IconButton
                        disabled={!accessRights.WRITE}
                      >
                        <CreateNewFolderIcon sx={{ color: "white", opacity: accessRights.WRITE ? 1 : 0.3 }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                }
                position="right top"
                disabled={!accessRights.WRITE}
              >
                <AddNewModulePopupContent
                  onConfirm={createModuleHandler}
                  onCancel={() => sectionPopupRef.current.close()}
                />
              </Popup>
            </div>
          </span>

          {showContent.navName === "sections" && showContent.show && (
            <ul className="sections-nav">
              {sectionsData &&
                Object.keys(sectionsData).map(
                  (pageNames, index) =>
                    sectionsData[pageNames].moduleName && (
                      <li
                        key={index}
                        style={{
                          backgroundColor: "#3F3F3F",
                          transition: "all 1s ease-in-out",
                          padding: 0
                        }}
                      >
                        <ul
                          className=""
                          style={{ borderBottom: "#636363 1px solid" }}
                        >
                          <li
                            className={`sections-sub-nav ${this.state.dynamicValues[
                              `showSubContent${pageNames}`
                            ]
                              ? "minus-icon-nav folder-arrow-down-opened"
                              : "plus-icon-nav"
                              } `}
                            onClick={() => this.handleNav(pageNames)}
                          >
                            <div className="module-title">
                              <img src={FolderMinusIcon} alt="Folder" />

                              {sectionsData[pageNames].moduleName}
                            </div>

                            <div
                              className={cn("page-icons-container", {
                                "d-flex justify-content-end":
                                  "sectionsAddPageDisplay"
                              })}
                              onClick={e => {
                                e.stopPropagation();
                              }}
                            >
                              <img
                                src={ArrowDown}
                                className={cn("folder-arrow-down", {
                                  //opened: 'minus-icon-nav',
                                })}
                                alt="arrow down"
                                onClick={() => this.handleNav(pageNames)}
                              />
                              <Popup
                                ref={sectionPopupRef}
                                trigger={
                                  <Tooltip title={accessRights.UPDATE ? "Update Module" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                                    <span>
                                      <IconButton
                                        disabled={!accessRights.UPDATE}
                                      >
                                        <EditIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                }
                                position="right top"
                                disabled={!accessRights.UPDATE}
                              >
                                <UpdateModulePopupContent
                                  onConfirm={updateModulePopupContent}
                                  selectedModule={sectionsData[pageNames]}
                                  onCancel={() =>
                                    sectionPopupRef.current.close()
                                  }
                                />
                              </Popup>
                              <Popup
                                ref={sectionPopupRef}
                                trigger={
                                  <Tooltip title={accessRights.DELETE ? "Delete Module" : MESSAGES.TOOLTIP_DELETE} placement="top" arrow>
                                    <span>
                                      <IconButton
                                        disabled={!accessRights.DELETE}
                                      >
                                        <DeleteIcon sx={{ color: "white", opacity: accessRights.DELETE ? 1 : 0.3 }} fontSize="small" />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                }
                                position="right top"
                                disabled={!accessRights.DELETE}
                              >

                                <PopupContent
                                  onConfirm={_id =>
                                    deleteModuleHandler(
                                      sectionsData[pageNames]._id
                                    )
                                  }
                                  onCancel={() =>
                                    sectionPopupRef.current.close()
                                  }
                                />

                              </Popup>
                              <Popup
                                ref={sectionPopupRef}
                                trigger={
                                  <Tooltip title={accessRights.WRITE ? "Add section" : MESSAGES.TOOLTIP_WRITE} placement="top" arrow>
                                    <span>
                                      <IconButton
                                        disabled={!accessRights.WRITE}
                                      >
                                        <AddIcon sx={{ color: "white", opacity: accessRights.WRITE ? 1 : 0.3 }} fontSize="small" />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                }
                                position="right top"
                                disabled={!accessRights.WRITE}
                              >

                                <AddNewSectionPopupContent
                                  onConfirm={createSectionHandler}
                                  moduleSlug={sectionsData[pageNames].moduleType}
                                  onCancel={() =>
                                    sectionPopupRef.current.close()
                                  }
                                />

                              </Popup>
                            </div>
                          </li>

                          {sectionsData[pageNames].moduleName &&
                            this.state.dynamicValues[
                            `showSubContent${pageNames}`
                            ] && (
                              <ul>
                                <div>
                                  <ul className="sub_listing">
                                    {
                                      sectionsData[pageNames].children &&
                                      sectionsData[pageNames].children.map((page, index) =>
                                        page.title ? (
                                          <li
                                            className={`${page._id === currentActiveSectionPageId && "active"}`}
                                            onClick={() => handlePageClick(page)}
                                            id={SECTIONS_UPDATE_PAGE_DISPLAY}
                                            name={SECTIONS_UPDATE_PAGE_DISPLAY + index + "Checkout"}
                                            key={index}
                                          >
                                            {page.title}

                                            <div
                                              className={cn("page-icons-container", {
                                                "d-flex justify-content-end": true
                                              })}
                                              onClick={e => {
                                                e.stopPropagation();
                                              }}
                                            >
                                              {page._id === currentActiveSectionPageId &&
                                                userRole === "SUPERADMIN" && (
                                                  <div>
                                                    <Tooltip title={accessRights.UPDATE ? "Rollback" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                                                      <span>
                                                        <IconButton
                                                          disabled={!accessRights.UPDATE}
                                                          onClick={
                                                            accessRights.UPDATE
                                                              ? () => this.setState({ showRollback: true })
                                                              : null
                                                          }
                                                        >
                                                          <HistoryIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                                                        </IconButton>
                                                      </span>
                                                    </Tooltip>
                                                    {this.state.showRollback && (
                                                      <RollbackModalMUI
                                                        pageId={page._id}
                                                        history={rollbackHistory}
                                                        open={this.state.showRollback}
                                                        dialogContentText={""}
                                                        handleClose={this.handleCloseRollbackModal}
                                                        onConfirmRollback={this.handleOnConfirmRollback}
                                                        getRollbackHistory={this.handleGetRollbackHistory}
                                                        route={[sectionsData[pageNames].moduleName, page.title]}
                                                        pageType={NAVIGATION.SECTIONS}
                                                      />
                                                    )}
                                                  </div>
                                                )}
                                              <Popup
                                                ref={sectionPopupRef}
                                                trigger={
                                                  <Tooltip title={accessRights.UPDATE ? "Edit Section" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                                                    <span>
                                                      <IconButton
                                                        disabled={!accessRights.UPDATE}
                                                      >
                                                        <EditIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                                                      </IconButton>
                                                    </span>
                                                  </Tooltip>
                                                }
                                                position="right top"
                                                disabled={!accessRights.UPDATE}
                                              >

                                                <UpdateSectionPopupContent
                                                  onConfirm={updateSecPopupContent}
                                                  sectionPopupContentData={page}
                                                  onCancel={() => sectionPopupRef.current.close()}
                                                />

                                              </Popup>
                                              <Popup
                                                ref={sectionPopupRef}
                                                trigger={
                                                  <Tooltip title={accessRights.DELETE ? "Delete Section" : MESSAGES.TOOLTIP_DELETE} placement="top" arrow>
                                                    <span>
                                                      <IconButton
                                                        disabled={!accessRights.DELETE}
                                                      >
                                                        <DeleteIcon sx={{ color: "white", opacity: accessRights.DELETE ? 1 : 0.3 }} fontSize="small" />
                                                      </IconButton>
                                                    </span>
                                                  </Tooltip>
                                                }
                                                position="right top"
                                                disabled={!accessRights.DELETE}
                                              >

                                                <PopupContent
                                                  onConfirm={() =>
                                                    deleteSectionHandler(page._id, sectionsData[pageNames].moduleSlug)
                                                  }
                                                  onCancel={() => sectionPopupRef.current.close()}
                                                />

                                              </Popup>

                                            </div>
                                          </li>
                                        ) : null
                                      )
                                    }
                                  </ul>
                                </div>
                              </ul>
                            )}
                        </ul>
                      </li>
                    )
                )}
            </ul>
          )}

          {/*configurations pages*/}
          <span
            className={`open-nav
              ${showContent.navName === "configurations" && showContent.show
                ? "minus-icon-nav folder-arrow-down-opened"
                : "plus-icon-nav"
              }
              ${showContent.navName !== "configurations" ? "display-none" : ""
              }`}
            id="configurations"
            onClick={this.changeNav}
          >
            Configurations
            <div
              className={cn("page-icons-container", {
                "d-flex justify-content-end": "configurationsAddPageDisplay"
              })}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Popup
                ref={this.createNewConfigurationRef}
                trigger={
                  <Tooltip title={accessRights.WRITE ? "Create New Config" : MESSAGES.TOOLTIP_WRITE} placement="top" arrow>
                    <span>
                      <IconButton
                        disabled={!accessRights.WRITE}
                      >
                        <CreateNewFolderIcon sx={{ color: "white", opacity: accessRights.WRITE ? 1 : 0.3 }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                }
                position="right top"
                disabled={!accessRights.WRITE}
              >

                <AddNewConfigPopupContent
                  onConfirm={(name, slug) => {
                    createConfiguration(name, slug);
                    this.createNewConfigurationRef.current.close();
                  }}
                  onCancel={() =>
                    this.createNewConfigurationRef.current.close()
                  }
                />

              </Popup>
            </div>
          </span>

          {showContent.navName === "configurations" && showContent.show && (
            <ul className="sections-nav">
              {configurationsData &&
                Object.keys(configurationsData).map((pageNames, index) => (
                  <li
                    className={`catalog-sub-nav config-nav ${currentTabName ===
                      index + configurationsData[pageNames].name && "active"
                      }
                    ${configurationsData[pageNames].name ==
                        this.state.currentActiveConfig
                        ? `active`
                        : `inactive`
                      }`}
                    onClick={e =>
                      this.handleOnClickConfig(
                        e,
                        configurationsData[pageNames],
                        index,
                        configurationsData[pageNames].name
                      )
                    }
                    style={{
                      borderBottom: "1px solid #636363b8",
                      padding: "8px 20px"
                    }}
                    key={configurationsData[pageNames]._id}
                  >
                    {configurationsData[pageNames].name}

                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      className="d-flex"
                    >
                      {configurationsData[pageNames].name ==
                        this.state.currentActiveConfig &&
                        userRole === "SUPERADMIN" && (
                          <div>
                            <Tooltip title={accessRights.UPDATE ? "Rollback" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                              <span>
                                <IconButton
                                  disabled={!accessRights.UPDATE}
                                  onClick={
                                    accessRights.UPDATE
                                      ? () => this.setState({ showRollback: true })
                                      : null
                                  }
                                >
                                  <HistoryIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                            {this.state.showRollback && (
                              <RollbackModalMUI
                                pageId={configurationsData[pageNames]._id}
                                history={rollbackHistory}
                                open={this.state.showRollback}
                                dialogContentText={""}
                                handleClose={this.handleCloseRollbackModal}
                                onConfirmRollback={this.handleOnConfirmRollback}
                                getRollbackHistory={this.handleGetRollbackHistory}
                                route={[configurationsData[pageNames].name]}
                                pageType={NAVIGATION.CONFIGURATIONS}
                              />
                            )}
                          </div>
                        )}

                      <Popup
                        ref={this.updateConfigNameRef}
                        trigger={
                          <Tooltip title={accessRights.UPDATE ? "Update Config" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                            <span>
                              <IconButton
                                disabled={!accessRights.UPDATE}
                              >
                                <EditIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        }
                        position="right top"
                        disabled={!accessRights.UPDATE}
                      >

                        <UpdateConfigPopupContent
                          configDatas={configurationsData[pageNames]}
                          onConfirm={updateConfigName}
                          onCancel={() =>
                            this.updateConfigNameRef.current.close()
                          }
                        />

                      </Popup>
                      <Popup
                        ref={this.deleteConfigurationRef}
                        trigger={
                          <Tooltip title={accessRights.DELETE ? "Delete Config" : MESSAGES.TOOLTIP_DELETE} placement="top" arrow>
                            <span>
                              <IconButton
                                disabled={!accessRights.DELETE}
                              >
                                <DeleteIcon sx={{ color: "white", opacity: accessRights.DELETE ? 1 : 0.3 }} fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        }
                        position="right top"
                        disabled={!accessRights.DELETE}
                      >

                        <ConfigPopupContentDelete
                          onConfirm={() =>
                            deleteConfiguration(
                              configurationsData[pageNames]._id
                            )
                          }
                          onCancel={() =>
                            this.deleteConfigurationRef.current.close()
                          }
                        />

                      </Popup>
                    </div>
                  </li>
                ))}
            </ul>
          )}

          {/*url pages*/}
          <span
            className={`open-nav
              ${showContent.navName === "url-page" && showContent.show
                ? "folder-arrow-down-opened"
                : ""
              }
              ${showContent.navName !== "url-page" ? "display-none" : ""}`}
            id="url-page"
            onClick={this.changeNav}
          >
            URL PAGES
            <div
              className={cn("page-icons-container", {
                "d-flex justify-content-end": "configurationsAddPageDisplay"
              })}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Tooltip title={accessRights.WRITE ? "Create New Url" : MESSAGES.TOOLTIP_WRITE} placement="top" arrow>
                <span>
                  <IconButton
                    disabled={!accessRights.WRITE}
                    onClick={urlChangeTabHandler}
                  >
                    <AddIcon sx={{ color: "white", opacity: accessRights.WRITE ? 1 : 0.3 }} />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </span>

          {showContent.navName === "url-page" && showContent.show && (
            <Fragment>
              <ul className="sections-nav">
                {urlData.map((page, index) => (
                  <li
                    className={`catalog-sub-nav config-nav
                    ${page._id == currentActiveUrlPageId ? `active` : `inactive`
                      }
                    `}
                    onClick={e => this.handleOnClickUrlPage(page)}
                    style={{
                      borderBottom: "1px solid #636363b8",
                      padding: "8px 20px",
                      backgroundColor: "#3f3f3f",
                      alignItems: "center"
                    }}
                    key={page._id}
                  >
                    <div style={{ "overflowWrap": "anywhere" }}>
                      {page.url}
                    </div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      className="d-flex"
                    >
                      {page._id === currentActiveUrlPageId &&
                        userRole === "SUPERADMIN" && (
                          <div>
                            <Tooltip title={accessRights.UPDATE ? "Rollback" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                              <span>
                                <IconButton
                                  disabled={!accessRights.UPDATE}
                                  onClick={
                                    accessRights.UPDATE
                                      ? () => this.setState({ showRollback: true })
                                      : null
                                  }
                                >
                                  <HistoryIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                            {this.state.showRollback && (
                              <RollbackModalMUI
                                pageId={page._id}
                                history={rollbackHistory}
                                open={this.state.showRollback}
                                dialogContentText={""}
                                handleClose={this.handleCloseRollbackModal}
                                onConfirmRollback={(id) => this.handleOnConfirmRollback(id, index)}
                                getRollbackHistory={this.handleGetRollbackHistory}
                                route={[page.url]}
                                pageType={NAVIGATION.CREATE_URL}
                              />
                            )}
                          </div>
                        )}
                      {page.urlType &&
                        <Tooltip title={page.urlType} placement="top" arrow>
                          <Chip sx={{
                            marginTop: "5px",
                            "backgroundColor": page.urlType === URL_TYPES.SYSGENERATED ? "#1976d2" : (page.urlType === URL_TYPES.CUSTOM ? "rgb(255, 209, 0)" : "green")
                          }}
                            label={page.urlType[0].toUpperCase()} size="small" variant={"filled"} />
                        </Tooltip>}
                      <Popup
                        ref={this.deleteUrlRef}
                        trigger={
                          <Tooltip title={
                            accessRights.DELETE ? (page.urlType === URL_TYPES.SYSGENERATED ? "sysGenerated Url's cannot be deleted from here" : "Delete Url") : MESSAGES.TOOLTIP_DELETE} placement="top" arrow>
                            <span>
                              <IconButton
                                disabled={!accessRights.DELETE || page.urlType === URL_TYPES.SYSGENERATED}
                              >
                                <DeleteIcon sx={{ color: "white", opacity: (accessRights.DELETE && page.urlType !== URL_TYPES.SYSGENERATED) ? 1 : 0.3 }} fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        }
                        position="right top"
                        disabled={!accessRights.DELETE || page.urlType === URL_TYPES.SYSGENERATED}
                      >

                        <UrlPopupContent
                          onConfirm={() =>
                            deleteUrlHandler(page._id, page.url, index)
                          }
                          onCancel={() => this.deleteUrlRef.current.close()}
                        />

                      </Popup>
                      <Popup
                        ref={this.toggleUrlRef}
                        trigger={
                          <Tooltip title={accessRights.UPDATE ? "Action" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                            <span>
                              <AntSwitch checked={page.enabled} disabled={!accessRights.UPDATE} sx={{ "marginTop": "10px" }} onChange={() => { }} />
                            </span>
                          </Tooltip>
                        }
                        position="right top"
                        disabled={!accessRights.UPDATE}
                      >
                        {accessRights.UPDATE ? (
                          <UrlPopupContent
                            onConfirm={() => {
                              updateToggleUrlHandler(!page.enabled, page, index)
                              this.toggleUrlRef.current.close()
                            }
                            }
                            message={"Are you sure to perform this Action?"}
                            onCancel={() => this.toggleUrlRef.current.close()}
                          />
                        ) : null}
                      </Popup>
                    </div>
                  </li>
                ))}
              </ul>
            </Fragment>
          )}

          {/*flash sale pages*/}
          <span
            className={`open-nav
              ${showContent.navName === "flash-sale-page" && showContent.show
                ? "folder-arrow-down-opened"
                : ""
              }
              ${showContent.navName !== "flash-sale-page" ? "display-none" : ""}`}
            id="flash-sale-page"
            onClick={this.changeNav}
          >
            Flash Sale Pages
            <div
              className={cn("page-icons-container", {
                "d-flex justify-content-end": "configurationsAddPageDisplay"
              })}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Popup
                ref={this.addFlashSaleRef}
                trigger={
                  <Tooltip title={accessRights.WRITE ? "Create Flash Sale Page" : MESSAGES.TOOLTIP_WRITE} placement="top" arrow>
                    <span>
                      <IconButton
                        disabled={!accessRights.WRITE}
                      >
                        <AddIcon sx={{ color: "white", opacity: accessRights.WRITE ? 1 : 0.3 }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                }
                position="right top"
                disabled={!accessRights.WRITE}
              >
                <AddNewFlashPagePopupContent
                  onConfirm={() => {
                    if (createFlashPageValidationHandler(createNewFlashSalePageData)) {
                      this.addFlashSaleRef.current.close()
                      createFlashSalePageHandler(createNewFlashSalePageData)
                    }
                  }}
                  onChangeHandler={addFlashPagePopupContentHandler}
                  data={createNewFlashSalePageData}
                  onCancel={() => this.addFlashSaleRef.current.close()}
                  title={"Add new Flash Sale Page"}
                />
              </Popup>
            </div>
          </span>

          {showContent.navName === "flash-sale-page" && showContent.show && (
            <Fragment>
              <ul className="sections-nav">
                {flashSaleData.map((page, index) => (
                  <li
                    className={`catalog-sub-nav config-nav
                    ${page._id == currentActiveFlashPageId ? `active` : `inactive`
                      }
                    `}
                    onClick={e => this.handleOnClickFlashPage(page)}
                    style={{
                      borderBottom: "1px solid #636363b8",
                      padding: "8px 20px",
                      backgroundColor: "#3f3f3f"
                    }}
                    key={page._id}
                  >
                    <div style={{ "overflowWrap": "anywhere" }}>
                      {page.name}
                    </div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      className="d-flex"
                    >
                      <Popup
                        ref={this.updateFlashSaleRef}
                        trigger={
                          <Tooltip title={accessRights.UPDATE ? "Update" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                            <span>
                              <IconButton
                                disabled={!accessRights.UPDATE}
                              >
                                <EditIcon sx={{ color: "white", opacity: accessRights.UPDATE ? 1 : 0.3 }} fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        }
                        position="right top"
                        disabled={!accessRights.UPDATE}
                      >
                        <UpdateFlashPagePopupContent
                          onConfirm={(id, index, name) => {
                            if (updateFlashPageValidationHandler({ name })) {
                              this.updateFlashSaleRef.current.close()
                              updateFlashSalePageUrlHandler(id, index, { name })
                            }
                          }}
                          data={{ _id: page._id, name: page.name, slug: page.slug, index }}
                          onCancel={() => this.updateFlashSaleRef.current.close()}
                          title={"Update Page Name"}
                        />
                      </Popup>
                      <Popup
                        ref={this.deleteFlashSaleRef}
                        trigger={
                          <Tooltip title={accessRights.DELETE ? "Delete" : MESSAGES.TOOLTIP_DELETE} placement="top" arrow>
                            <span>
                              <IconButton
                                disabled={!accessRights.DELETE}
                              >
                                <DeleteIcon sx={{ color: "white", opacity: accessRights.DELETE ? 1 : 0.3 }} fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        }
                        position="right top"
                        disabled={!accessRights.DELETE}
                      >
                        <FlashSalePagePopupContent
                          onConfirm={() => {
                            this.deleteFlashSaleRef.current.close();
                            deleteFlashSalePageHandler(page._id)
                          }
                          }
                          onCancel={() => this.deleteFlashSaleRef.current.close()}
                        />
                      </Popup>
                      <Popup
                        ref={this.toggleFlashSaleRef}
                        trigger={
                          <Tooltip title={accessRights.UPDATE ? "Action" : MESSAGES.TOOLTIP_UPDATE} placement="top" arrow>
                            <span>
                              {/* Note:onChange should always be used with checked prop */}
                              <AntSwitch checked={page.enabled} disabled={!accessRights.UPDATE} sx={{ "marginTop": "10px" }} onChange={() => { }} />
                            </span>
                          </Tooltip>
                        }
                        position="right top"
                        disabled={!accessRights.UPDATE}
                      >
                        <FlashSalePagePopupContent
                          onConfirm={() => {
                            updateToggleFlashSaleHandler(page._id, !page.enabled, index)
                            this.toggleFlashSaleRef.current.close()
                          }
                          }
                          message={"Are you sure to perform this Action?"}
                          onCancel={() => this.toggleFlashSaleRef.current.close()}
                        />
                      </Popup>
                    </div>
                  </li>
                ))}
              </ul>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  catalogR: state.catalogReducer,
  staticPageR: state.staticReducer,
  sectionsPageR: state.sectionsReducer,
  configurationsR: state.configurationsReducer
});

export default connect(mapStateToProps, null)(withRouter(SideBar));
