function baseUrl(defaultUrl) {
  let url;

  if (process.env.DOMAIN_NAME !== undefined) {
    url = `https://${process.env.DOMAIN_NAME}`;
  } else {
    url = defaultUrl;
  }

  return url;
}

export const config = {
  development: {
    env: process.env.REACT_APP_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl("http://localhost:3005/api/v6"),
    productBaseURL: "https://qa-api.bfl.dev/products-api/api/v4",
    productBaseURLV5: "https://qa-api.bfl.dev/products-api/api/v5",
    versionInfo: "",
    configBaseURL: "http://localhost:3005/api/v6",
    commonBaseURL: "https://qa-api.bfl.dev/common-api/api/v2",
    redirectionUrl: baseUrl("https://qa-com.bfl.dev/en"),
    productImageBaseUrl: "https://b.bflcdn.com/t_140/f_auto,q_auto/products/"
  },
  qa: {
    env: process.env.REACT_APP_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl("https://qa-api.bfl.dev/config-api/api/v6"),
    productBaseURL: "https://qa-api.bfl.dev/products-api/api/v4",
    productBaseURLV5: "https://qa-api.bfl.dev/products-api/api/v5",
    versionInfo: "",
    configBaseURL: "https://qa-api.bfl.dev/config-api/api/v6",
    commonBaseURL: "https://qa-api.bfl.dev/common-api/api/v2",
    redirectionUrl: baseUrl("https://qa-com.bfl.dev/en"),
    productImageBaseUrl: "https://b.bflcdn.com/t_140/f_auto,q_auto/products/"

  },
  // staging: {
  //   env: process.env.REACT_APP_ENV || 'development',
  //   port: process.env.PORT || 3000,
  //   baseURL: baseUrl('https://bfl-config-api.qa3.tothenew.net/api/v2'),
  //   productBaseURL: 'https://bfl-products-api.qa3.tothenew.net/api/v1',
  //   versionInfo: '',
  //   configBaseURL: 'https://bfl-config-api.qa3.tothenew.net/api/v2',
  //   commonBaseURL: 'https://bfl-common-api.qa3.tothenew.net/api/v1',
  //   redirectionUrl: baseUrl('https://qa-ae.bfl.dev/en'),
  // },
  uat: {
    env: process.env.REACT_APP_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl("https://uat-api.bfl.dev/config-api/api/v6"),
    productBaseURL: "https://uat-api.bfl.dev/products-api/api/v4",
    productBaseURLV5: "https://uat-api.bfl.dev/products-api/api/v5",
    versionInfo: "",
    configBaseURL: "https://uat-api.bfl.dev/config-api/api/v6",
    commonBaseURL: "https://uat-api.bfl.dev/common-api/api/v2",
    redirectionUrl: baseUrl("https://uat-com.bfl.dev/en"),
    productImageBaseUrl: "https://b.bflcdn.com/t_140/f_auto,q_auto/products/"

  },
  prod: {
    env: process.env.REACT_APP_ENV || "development",
    port: process.env.PORT || 3000,
    baseURL: baseUrl("http://52.142.83.40:3009/api/v6"), //https://api.brandsforless.com/config-api
    productBaseURL: "https://api.brandsforless.com/products-api/api/v4",
    productBaseURLV5: "https://api.brandsforless.com/products-api/api/v5",
    versionInfo: "",
    configBaseURL: "http://52.142.83.40:3009/api/v6", //https://api.brandsforless.com/config-api
    commonBaseURL: "https://api.brandsforless.com/common-api/api/v2",
    redirectionUrl: baseUrl("https://www.brandsforless.com/en"),
    productImageBaseUrl: "https://b.bflcdn.com/t_140/f_auto,q_auto/products/"

  }
  // production: {
  //   env: process.env.REACT_APP_ENV || 'development',
  //   port: process.env.PORT || 3000,
  //   baseURL: baseUrl('http://52.142.83.40:3009/api/v2'), //https://api.brandsforless.com/config-api
  //   productBaseURL: 'https://api.brandsforless.com/products-api/api/v1',
  //   versionInfo: '',
  //   configBaseURL: 'http://52.142.83.40:3009/api/v2', //https://api.brandsforless.com/config-api
  //   commonBaseURL: 'https://api.brandsforless.com/common-api/api/v1',
  //   redirectionUrl: baseUrl('https://www.brandsforless.ae/en'),
  // },
  // test: {
  //   env: process.env.REACT_APP_ENV || 'development',
  //   port: process.env.PORT || 4000,
  //   mongoURL: 'mongodb://localhost/Ecomm_Order_Test',
  //   baseURL: 'http://localhost:4000/api/v2',
  //   productBaseURL: 'https://bfltest-products-api.qa3.tothenew.net/api/v1',
  //   versionInfo: '',
  //   configBaseURL: 'https://bfltest-config-api.qa3.tothenew.net/api/v2',
  //   commonBaseURL: 'https://bfltest-common-api.qa3.tothenew.net/api/v1',
  //   redirectionUrl: baseUrl('https://qa-ae.bfl.dev/en'),
  // },
};

export const ENV_CONFIG = config[process.env.REACT_APP_ENV || "development"];
