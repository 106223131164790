import {
  SESSION_TERMINATED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  USER_INFO_RECEIVED,
  UPDATE_USER_PERMISSIONS
} from "../constants/actions";

const initialState = {
  userLoggedIn: false,
  accessToken: "temp_access_token",
  user: {},
  loading: true
};

const setToken = (state, { token }) => ({
  ...state,
  userLoggedIn: true,
  accessToken: token,
  loading: false
});
const setUserInfo = (state, { user }) => ({ ...state, user });

const updateUserPermissions = (state, { permissions }) => {
  if (state.user) {
    return {
      ...state,
      user: { ...state.user, permissions: { ...permissions } }
    };
  } else {
    return { ...state };
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_TERMINATED:
    case LOGOUT_SUCCESS:
      return initialState;
    case LOGIN_SUCCESS:
      return setToken(state, action);
    case USER_INFO_RECEIVED:
      return setUserInfo(state, action);
    case UPDATE_USER_PERMISSIONS:
      return updateUserPermissions(state, action);
    default:
      return state;
  }
};

export default userReducer;
