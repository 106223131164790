export const ADD_STATIC_PAGE_TYPE = "ADD_STATIC_PAGE_TYPE";
export const ADD_STATIC_SEO = "ADD_STATIC_SEO";
export const ADD_CK_STATIC_CONTENT = "ADD_CK_STATIC_CONTENT";
export const ADD_CK_STATIC_ACCORD_CONTENT = "ADD_CK_STATIC_ACCORD_CONTENT";
export const STATIC_FETCH_PAGES = "STATIC_FETCH_PAGES";
export const RESET_STATIC_PAGES = "RESET_STATIC_PAGES";
export const SEO_UPDATE_STATIC = "SEO_UPDATE_STATIC";
export const ADD_CK_STATIC_ACCORD_TILE = "ADD_CK_STATIC_ACCORD_TILE";
export const REMOVE_CK_STATIC_ACCORD_TILE = "REMOVE_CK_STATIC_ACCORD_TILE";
export const STATIC_TOGGLE_RESET = "STATIC_TOGGLE_RESET";
export const STATIC_CANCEL_ACTION = "STATIC_CANCEL_ACTION";
export const UPDATE_STATIC_SEO = "UPDATE_STATIC_SEO";
export const UPDATE_CK_STATIC_CONTENT = "UPDATE_CK_STATIC_CONTENT";
export const UPDATE_CK_STATIC_ACCORD_CONTENT =
  "UPDATE_CK_STATIC_ACCORD_CONTENT";
export const UPDATE_CK_STATIC_ACCORD_TILE = "UPDATE_CK_STATIC_ACCORD_TILE";
export const UPDATE_REMOVE_CK_STATIC_ACCORD_TILE =
  "UPDATE_REMOVE_CK_STATIC_ACCORD_TILE";
export const UPDATE_STATIC_PAGE_TYPE = "UPDATE_STATIC_PAGE_TYPE";
export const RESET_UPDATE_STATIC_PAGE = "RESET_UPDATE_STATIC_PAGE";
export const DELETE_URL = "DELETE_URL";
