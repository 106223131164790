import { EditIcon, ImageIcon, VideoFileIcon } from "../../materialUi/icons";

export const CONFIGURATIONS_PAGE_PANEL = {
  CONFIGURATIONS_ADD_PAGE_DISPLAY: "configurationsAddPageDisplay",
  CONFIGURATIONS_UPDATE_PAGE_DISPLAY: "configurationsUpdatePageDisplay",
  CONFIGURATIONS_DELETE_PAGE_DISPLAY: "configurationsDeletePageDisplay",
  product_description_page: "PDP",
  Checkout: "Checkout",
  Header: "Header"
};

export const CONFIGURATIONS_PAGE = "CONFIGURATIONS_PAGE";

export const CONFIGURATIONS_PAGE_PAGETYPE = {
  header: "Header",
  product_description_page: "PDP",
  Checkout: "Checkout"
};

export const CONFIGURATIONS_PAGE_TYPE_MAP = {
  header: "Header",
  product_description_page: "PDP",
  Checkout: "Checkout"
};

export const CONFIGURATIONS_PAGE_TYPE = {
  header: "Header",
  product_description_page: "PDP",
  Checkout: "Checkout"
};

//   export const SECTIONS_COMPONENT_TYPES = {
//     BANNER: 'banner',
//     PRODUCT_CAROUSEL: 'product-carousel',
//     IMAGE_CAROUSEL: 'catalog-carousel',
//     DYNAMIC_SLIDER: 'dynamicSliderModule',
//     DYNAMIC_BANNER: 'dynamicBannerModule',
//   };

//   export const SECTIONS_SECTION_TITLES = {
//     DYNAMIC_BANNER: 'Dynamic banner module',
//     DYNAMIC_SLIDER: 'Dynamic slider module',
//   };

//   export const SECTIONS_TYPE_SELECT_OPTIONS = [
//     { label: 'Banner', value: SECTIONS_COMPONENT_TYPES.BANNER },
//     { label: 'Image Carousel', value: SECTIONS_COMPONENT_TYPES.IMAGE_CAROUSEL },
//     {
//       label: 'Product Carousel',
//       value: SECTIONS_COMPONENT_TYPES.PRODUCT_CAROUSEL,
//     },
//     {
//       label: 'Dynamic Banner Module',
//       value: SECTIONS_COMPONENT_TYPES.DYNAMIC_BANNER,
//     },
//     {
//       label: 'Dynamic Slider Module',
//       value: SECTIONS_COMPONENT_TYPES.DYNAMIC_SLIDER,
//     },
//   ];

export const CONFIGURATIONS_PINS_CONFIG_MATRIX = [1, 1, 1, 0, 1, 1, 1, 0];
export const CONFIGURATIONS_PINS_COORDS = [
  {
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "grey",
    fillOpacity: "1"
  },
  {
    x: "5.23828",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "grey",
    fillOpacity: "1"
  },
  {
    x: "10.4766",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "grey",
    fillOpacity: "1"
  },
  {
    x: "16.0469",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "grey",
    fillOpacity: "1"
  },
  {
    y: "4.19043",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "grey",
    fillOpacity: "1"
  },
  {
    x: "5.23828",
    y: "4.19043",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "grey",
    fillOpacity: "1"
  },
  {
    x: "10.4766",
    y: "4.19043",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "grey",
    fillOpacity: "1"
  },
  {
    x: "16.0469",
    y: "4.19043",
    width: "2.61905",
    height: "2.61905",
    rx: "1.30952",
    fill: "grey",
    fillOpacity: "1"
  }
];
export const CONFIGURATIONS_MEDIA_OPTIONS = [
  { value: "edit", label: "Edit", showIcon: true, icon: <EditIcon /> },
  { value: "image", label: "Image", showIcon: true, icon: <ImageIcon /> },
  { value: "video", label: "Video", showIcon: true, icon: <VideoFileIcon /> },
]

export const CONFIGURATIONS_JSON_OPTIONS = [
  { value: "edit", label: "Edit", showIcon: true, icon: <EditIcon /> },
  { value: "add", label: "Add", showIcon: true, icon: <ImageIcon /> },
]

export const CONFIGURATIONS_MEDIA_TYPES = {
  IMAGE: "image",
  VIDEO: "video"
}