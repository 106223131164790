import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Login from "../login";

const UnAuthContent = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default UnAuthContent;
