import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import ExpandableList from "../../component/ExpendableList";
import PageFolder from "./pageFolder";
import {
  createFolder,
  deleteFolder,
  setNewAddedFolder,
  updateOpenedContainers
} from "../../actions/pagesPage.action";
import {
  selectNewAddedFolder,
  selectOpenedContainerIds,
  selectPagesFoldersShape
} from "../../selectors";
import { usePrev } from "../../hooks";
import { getContainerByFolderId, getNewAdded } from "../../util";

import ArrowDown from "../../images/arrow-dd.svg";
import { MESSAGES, CONTAINER_TYPE } from "../../constants";
import { CreateNewFolderIcon } from "../../materialUi/icons";
import { Tooltip, IconButton } from "../../materialUi/components";

const FoldersContainer = ({
  container,
  folders,
  pages,
  createFolder,
  deleteFolder,
  openedContainerIds,
  updateOpenedContainers,
  setCurrentTab,
  pagesFoldersShape,
  setNewAddedFolder,
  folderCreationDisabled,
  accessRights
}) => {
  const prevFolders = usePrev(pagesFoldersShape.folders);
  const createFolderHandler = e => {
    e.stopPropagation();
    const defaultFolderName = "New folder";
    const defaultFolderIndex = Math.max(
      ...folders
        .filter(folder => folder.title.includes(defaultFolderName))
        .map(folder => folder.title.replace(/New folder?\s/gi, "")),
      0
    );
    createFolder(container, `${defaultFolderName} ${defaultFolderIndex + 1}`);
  };
  const deleteFolderHandler = useCallback(
    (e, folder) => {
      e.stopPropagation();
      deleteFolder(container, folder);
    },
    [container, deleteFolder]
  );

  useEffect(() => {
    if (!prevFolders) return;
    const foldersIds = Object.keys(pagesFoldersShape.folders);
    const oldFoldersIds = Object.keys(prevFolders);
    const newFolderAdded =
      oldFoldersIds.length && foldersIds.length > oldFoldersIds.length;
    if (newFolderAdded) {
      const newFolderId = getNewAdded(foldersIds, oldFoldersIds);
      if (newFolderId) {
        updateOpenedContainers({
          container: getContainerByFolderId(pagesFoldersShape, newFolderId),
          closeAllExceptPassed: true
        });
        setNewAddedFolder(newFolderId);
        setTimeout(() => {
          setNewAddedFolder(null);
        }, 2000);
      }
    }
    // eslint-disable-next-line
  }, [pagesFoldersShape.folders]);

  const isOpened = openedContainerIds.includes(container.id);

  return (
    <div className={container.type === CONTAINER_TYPE.VARIANT ? "folders-container variant-container" : "folders-container"}>
      <ExpandableList
        opened={isOpened}
        onClick={() => {
          updateOpenedContainers({ container });
        }}
        header={() => (
          <div className="folders-container-header">
            <span className="container-name">{container.title}</span>
            <div className="button-container">
              <img
                src={ArrowDown}
                className={cn("container-arrow-down", {
                  opened: isOpened
                })}
                alt="expand"
              />
              <Tooltip title={accessRights.WRITE ? "Create Folder" : MESSAGES.TOOLTIP_WRITE} placement="top" arrow>
                <span>
                  <IconButton
                    disabled={folderCreationDisabled || !accessRights.WRITE}
                    onClick={createFolderHandler}
                  >
                    <CreateNewFolderIcon sx={{ color: "white", opacity: (accessRights.WRITE && !folderCreationDisabled) ? 1 : 0.3 }} />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
        )}
        list={
          () => (
            <ul
              className="folders-list"
            >
              {folders.map(
                (folder, index) =>
                  folder && (
                    <PageFolder
                      key={folder.id}
                      folder={folder}
                      pages={pages}
                      index={index}
                      deleteFolderHandler={deleteFolderHandler}
                      container={container}
                      setCurrentTab={setCurrentTab}
                      accessRights={accessRights}
                    />
                  )
              )}
            </ul>
          )
        }
      />
    </div>
  );
};

const mapStateToProps = state => ({
  openedContainerIds: selectOpenedContainerIds(state),
  pagesFoldersShape: selectPagesFoldersShape(state),
  newAddedFolder: selectNewAddedFolder(state)
});

const mapDispatchToProps = {
  createFolder,
  deleteFolder,
  updateOpenedContainers,
  setNewAddedFolder
};

export default connect(mapStateToProps, mapDispatchToProps)(FoldersContainer);
