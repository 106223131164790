import React, { Fragment, useState, useEffect } from "react";
import InputText from "../../component/InputText";

function UpdateSectionPopupContent({
  sectionPopupContentData,
  onConfirm,
  onCancel
}) {
  const [data, setData] = useState({
    moduleType: sectionPopupContentData.moduleType,
    sectionName: sectionPopupContentData.sectionName,
    sectionSlug: sectionPopupContentData.sectionSlug,
    title: sectionPopupContentData.title,
    _id: sectionPopupContentData._id
  });

  const onDataChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Fragment>
        <div>
          <p>Update Section</p>
          <InputText
            placeholder="Section Name"
            name="sectionName"
            value={data.sectionName}
            onChange={onDataChange}
          />
          <InputText
            placeholder="Slug"
            name="sectionSlug"
            value={data.sectionSlug}
            onChange={onDataChange}
          />
        </div>
        <div className="popup-buttons">
          <button className="popup-confirm" onClick={() => onConfirm(data)}>
            <span className="btn-text">Yes</span>
          </button>
          <button className="popup-cancel" onClick={onCancel}>
            <span className="n-btn-text">No</span>
          </button>
        </div>
      </Fragment>
    </div>
  );
}

export default UpdateSectionPopupContent;
