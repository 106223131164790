import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import cn from "classnames";
import ReactTooltip from "react-tooltip";
import Button from "../../../component/button";
import ImageSelectionModal from "../../modal/imageSelectionModal";
import {
  isUpdateStaticContentDataChanged,
  validateUpdateStaticContentData
} from "../../../util/footer";
import {
  handleAddFooterSocialMediaLink,
  handleFooterTextChange,
  handleRemoveFooterSocialMediaLink,
  handleUpdateFooterSocialMediaLink,
  updateFooterStaticDetails
} from "../../../actions/footer.action";
import { toastMsg } from "../../../actions/common.action";
import {
  FOOTER_STATIC_LANG_MAP,
  LANGUAGE,
  SEE_CHANGES_MESSAGE,
  UNSAVED_CHANGES_WARNING_MESSAGE,
  MESSAGES,
  ERROR_MESSAGES,
} from "../../../constants";
import { ENV_CONFIG } from "../../../config/env";
import ConfirmModal from "../../modal/ConfirmModal";
import Accordion from "../../../component/accordion";
import SocialMediaLink from "./socialMediaLink";
import CustomerSupport from "./customerSupport";
import Connect from "./connect";

class FooterStaticContent extends Component {
  state = {
    confirmPreviewModal: false,
    confirmPreviewSubmitModal: false,
    disableSubmit: true,
    showImageModal: false,
    imageModalIndex: null,
    imageModalLanguage: null
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      disableSubmit: this.determineIsDisableSubmit(nextProps)
    });
  }

  determineIsDisableSubmit = nextProps => {
    const {
      arStaticContent,
      enStaticContent,
      updateArStaticContent,
      updateEnStaticContent
    } = nextProps;
    return !(
      validateUpdateStaticContentData(updateArStaticContent, arStaticContent) &&
      validateUpdateStaticContentData(updateEnStaticContent, enStaticContent) &&
      (isUpdateStaticContentDataChanged(
        updateArStaticContent,
        arStaticContent
      ) ||
        isUpdateStaticContentDataChanged(
          updateEnStaticContent,
          enStaticContent
        ))
    );
  };

  handleFooterText = (name, value, language) => {
    const { handleFooterTextChange } = this.props;
    handleFooterTextChange(name, value, FOOTER_STATIC_LANG_MAP[language]);
  };

  handleImageModal = (index, language) => {
    this.setState(state => ({
      showImageModal: !state.showImageModal,
      imageModalIndex: index,
      imageModalLanguage: language
    }));
  };

  handleAddSocialMediaLink = () => {
    const { handleAddFooterSocialMediaLink } = this.props;
    handleAddFooterSocialMediaLink(FOOTER_STATIC_LANG_MAP[LANGUAGE.ENGLISH]);
    handleAddFooterSocialMediaLink(FOOTER_STATIC_LANG_MAP[LANGUAGE.ARABIC]);
  };

  handleRemoveSocialMediaLink = index => {
    const { handleRemoveFooterSocialMediaLink } = this.props;
    handleRemoveFooterSocialMediaLink(
      index,
      FOOTER_STATIC_LANG_MAP[LANGUAGE.ENGLISH]
    );
    handleRemoveFooterSocialMediaLink(
      index,
      FOOTER_STATIC_LANG_MAP[LANGUAGE.ARABIC]
    );
  };

  handleUpdateSocialMediaLink = (name, value, index, language) => {
    const { handleUpdateFooterSocialMediaLink } = this.props;
    handleUpdateFooterSocialMediaLink(
      name,
      value,
      index,
      FOOTER_STATIC_LANG_MAP[language]
    );
  };

  validateFormData = (formData, language) => {
    const { toastMsg } = this.props;
    const { connect = "", copyRight = "", csEmail = "", csHeading = "", csNumber = "", csTime = "" } = formData || {};
    if (connect && (connect !== connect.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Connect`);
      return false;
    }
    if (copyRight && (copyRight !== copyRight.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Copy Right Label`);
      return false;
    }
    if (csHeading && (csHeading !== csHeading.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Heading`);
      return false;
    }
    if (csNumber && (csNumber !== csNumber.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Support Number`);
      return false;
    }
    if (csTime && (csTime !== csTime.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Support Time`);
      return false;
    }
    if (csEmail && (csEmail !== csEmail.trim())) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} ${language} Support Email`);
      return false;
    }

    return true;
  }

  handleSubmit = (tempsave = false) => {
    const {
      arStaticContent,
      countryId,
      enStaticContent,
      selectedCountryId,
      updateArStaticContent,
      updateEnStaticContent,
      updateFooterStaticDetails
    } = this.props;

    const isValidEnglishForm = this.validateFormData(updateEnStaticContent, "English");
    if (!isValidEnglishForm) return;

    const isValidArabicForm = this.validateFormData(updateArStaticContent, "Arabic");
    if (!isValidArabicForm) return;

    if (
      !tempsave &&
      (!isEqual(updateArStaticContent, arStaticContent) ||
        !isEqual(updateEnStaticContent, enStaticContent))
    ) {
      this.setState({ confirmPreviewSubmitModal: true });
    } else {
      updateFooterStaticDetails(
        countryId,
        updateEnStaticContent,
        updateArStaticContent,
        tempsave,
        selectedCountryId
      ).then(resp => {
        if (resp.data.code === 200 && tempsave) {
          this.setState({ confirmPreviewModal: true });
        }
      });
    }
  };

  hidePreviewModal = () =>
    this.setState(state => ({
      confirmPreviewModal: !state.confirmPreviewModal
    }));

  hidePreviewSubmitModal = () =>
    this.setState(state => ({
      confirmPreviewSubmitModal: !state.confirmPreviewSubmitModal
    }));

  confirmPreviewModal = () => {
    const { confirmPreviewModal } = this.state;
    const { storeList, storeId, countryId } = this.props;
    let selectedCountry = null,
      defaultCountry = null;
    storeList.forEach(store => {
      if (store.storeId === storeId) {
        store.countries.forEach(country => {
          if (country.countryId === countryId) {
            selectedCountry = country;
          }
          if (country.countrySHORT.toLowerCase() === "ae") {
            defaultCountry = country;
          }
        });
      }
    });
    this.setState({ confirmPreviewModal: !confirmPreviewModal }, () => {
      window.open(
        `${ENV_CONFIG.redirectionUrl}-${selectedCountry
          ? selectedCountry.countrySHORT.toLowerCase()
          : defaultCountry.countrySHORT.toLowerCase()
        }/?prevMode=true`,
        "_blank"
      );
    });
  };

  confirmPreviewSubmitModal = (tempsave = false) => {
    const {
      countryId,
      updateEnStaticContent,
      updateArStaticContent,
      updateFooterStaticDetails,
      selectedCountryId
    } = this.props;
    updateFooterStaticDetails(
      countryId,
      updateEnStaticContent,
      updateArStaticContent,
      tempsave,
      selectedCountryId
    ).then(() => {
      this.setState({ confirmPreviewSubmitModal: false });
    });
  };

  handleFooterSocialMediaLinkImageUpdate = (keyName, img) => {
    const { handleUpdateFooterSocialMediaLink } = this.props;
    const { imageModalIndex, imageModalLanguage } = this.state;
    handleUpdateFooterSocialMediaLink(
      "img",
      img,
      imageModalIndex,
      FOOTER_STATIC_LANG_MAP[imageModalLanguage]
    );
    this.handleImageModal(null, null);
  };

  render() {
    const {
      countryId,
      storeId,
      updateArStaticContent,
      updateEnStaticContent,
      accessRights
    } = this.props;
    const {
      confirmPreviewModal,
      confirmPreviewSubmitModal,
      disableSubmit,
      showImageModal,
      imageModalLanguage
    } = this.state;

    return (
      <Fragment>
        <div className="wrapper-box">
          {showImageModal && (
            <ImageSelectionModal
              title="Upload Image"
              modalName={"imageModal"}
              keyName={"img"}
              brandId={storeId}
              storeId={storeId}
              countryId={countryId}
              language={imageModalLanguage}
              containerName={"footerStaticContent"}
              onHide={() => this.handleImageModal(null, null)}
              onSuccess={this.handleFooterSocialMediaLinkImageUpdate}
            />
          )}
          {confirmPreviewModal && (
            <ConfirmModal
              modalName={{}}
              onHide={this.hidePreviewModal}
              confirm={this.confirmPreviewModal}
              message={SEE_CHANGES_MESSAGE}
            />
          )}
          {confirmPreviewSubmitModal && (
            <ConfirmModal
              modalName={{}}
              onHide={this.hidePreviewSubmitModal}
              confirm={() => this.confirmPreviewSubmitModal(false)}
              message={UNSAVED_CHANGES_WARNING_MESSAGE}
            />
          )}
          <div className="data-wrapper">
            <div className="english-wrapper">
              <div className="english-content">
                <h4>English Version</h4>
                <Connect
                  handleFooterText={this.handleFooterText}
                  staticContent={updateEnStaticContent}
                  language={LANGUAGE.ENGLISH}
                />
              </div>
            </div>
            <div className="arabic-wrapper">
              <div className="arabic-content">
                <h4>Arabic Version</h4>
                <Connect
                  handleFooterText={this.handleFooterText}
                  staticContent={updateArStaticContent}
                  language={LANGUAGE.ARABIC}
                />
              </div>
            </div>
            <div className="english-wrapper">
              <div className="english-content">
                <h4>Social Media Links</h4>
              </div>
            </div>
            <div className="arabic-wrapper">
              <div className="arabic-content">
                <h4>Social Media Links</h4>
              </div>
            </div>
            {map(
              updateEnStaticContent.socialMediaLinks,
              (socialMediaLink, index) => (
                <Accordion
                  title={
                    socialMediaLink.redirectLink
                      ? socialMediaLink.redirectLink
                      : `Social Media Link ${index + 1}`
                  }
                  handleDelete={() => this.handleRemoveSocialMediaLink(index)}
                >
                  <SocialMediaLink
                    handleImageModal={this.handleImageModal}
                    handleUpdateSocialMediaLink={
                      this.handleUpdateSocialMediaLink
                    }
                    index={index}
                    key={socialMediaLink.id}
                    language={LANGUAGE.ENGLISH}
                    socialMediaLink={socialMediaLink}
                    updateURL={this.updateURL}
                  />
                  <SocialMediaLink
                    handleImageModal={this.handleImageModal}
                    handleUpdateSocialMediaLink={
                      this.handleUpdateSocialMediaLink
                    }
                    index={index}
                    key={updateArStaticContent.socialMediaLinks[index].id}
                    language={LANGUAGE.ARABIC}
                    socialMediaLink={
                      updateArStaticContent.socialMediaLinks[index]
                    }
                    updateURL={this.updateURL}
                  />
                </Accordion>
              )
            )}
            <div className="d-flex justify-content-end">
              <Button
                id="addSocialMediaLink"
                customClass="m-0 mt-2 mb-1"
                onClick={this.handleAddSocialMediaLink}
                name="Add New Link"
              />
            </div>
            <div className="english-wrapper">
              <div className="english-content">
                <h4>Customer Support</h4>
                <CustomerSupport
                  handleFooterText={this.handleFooterText}
                  staticContent={updateEnStaticContent}
                  language={LANGUAGE.ENGLISH}
                />
              </div>
            </div>
            <div className="arabic-wrapper">
              <div className="arabic-content">
                <h4>Customer Support</h4>
                <CustomerSupport
                  handleFooterText={this.handleFooterText}
                  staticContent={updateArStaticContent}
                  language={LANGUAGE.ARABIC}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="marginBottom">
          <span
            data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
            data-for="footer-static"
          >
            <Button
              customClass={cn({ "btn-disabled": disableSubmit })}
              disabled={disableSubmit || !accessRights.UPDATE}
              name="Save & Preview"
              onClick={
                accessRights.UPDATE
                  ? () => {
                    if (!disableSubmit) {
                      this.handleSubmit(true);
                    }
                  }
                  : null
              }
            />
          </span>
          <span
            data-tip={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
            data-for="footer-static"
          >
            <Button
              customClass={cn({
                "btn-disabled": updateEnStaticContent.isPublished
              })}
              disabled={
                updateEnStaticContent.isPublished || !accessRights.UPDATE
              }
              name="Publish"
              onClick={
                accessRights.UPDATE
                  ? () => {
                    if (!updateEnStaticContent.isPublished) {
                      this.handleSubmit();
                    }
                  }
                  : null
              }
            />
          </span>
        </div>
        <ReactTooltip place="top" effect="solid" id="footer-static" />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  arStaticContent: state.footer.arStaticContent,
  countryId: state.store.countryId,
  enStaticContent: state.footer.enStaticContent,
  selectedCountryId: state.selectedCountryId,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  updateArStaticContent: state.footer.updateArStaticContent,
  updateEnStaticContent: state.footer.updateEnStaticContent
});

const mapDispatchToProps = {
  handleAddFooterSocialMediaLink,
  handleFooterTextChange,
  handleRemoveFooterSocialMediaLink,
  handleUpdateFooterSocialMediaLink,
  updateFooterStaticDetails,
  toastMsg,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterStaticContent);
