export const CATALOG_PANEL = {
  CATALOG_ADD_PAGE_DISPLAY: "catalogAddPageDisplay",
  CATALOG_UPDATE_PAGE_DISPLAY: "catalogUpdatePageDisplay",
  CATALOG_DELETE_PAGE_DISPLAY: "catalogDeletePageDisplay"
};

export const CATALOG_COMPONENT_TYPES = {
  BANNER: "banner",
  PRODUCT_CAROUSEL: "product-carousel",
  IMAGE_CAROUSEL: "catalog-carousel",
  DYNAMIC_SLIDER: "dynamicSliderModule",
  DYNAMIC_BANNER: "dynamicBannerModule"
};

export const CATALOG_SECTION_TITLES = {
  DYNAMIC_BANNER: "Dynamic banner module",
  DYNAMIC_SLIDER: "Dynamic slider module"
};

export const CATALOG_TYPE_SELECT_OPTIONS = [
  { label: "Banner", value: CATALOG_COMPONENT_TYPES.BANNER },
  { label: "Image Carousel", value: CATALOG_COMPONENT_TYPES.IMAGE_CAROUSEL },
  {
    label: "Product Carousel",
    value: CATALOG_COMPONENT_TYPES.PRODUCT_CAROUSEL
  },
  {
    label: "Dynamic Banner Module",
    value: CATALOG_COMPONENT_TYPES.DYNAMIC_BANNER
  },
  {
    label: "Dynamic Slider Module",
    value: CATALOG_COMPONENT_TYPES.DYNAMIC_SLIDER
  }
];

export const SCHEDULER_TYPE = {
  BANNER: "BANNER",
  CAROUSEL_TYPE_A: "CAROUSEL_TYPE_A",
  CAROUSEL_TYPE_B: "CAROUSEL_TYPE_B",
  SLIDER: "SLIDER"
};

export const SCHEDULER_DATA_BY_TYPE = {
  BANNER: {
    from: "",
    to: "",
    enabled: false,
    template: {
      english: "",
      arabic: ""
    }
  },
  CAROUSEL_TYPE_A: {
    imageList: [],
    enabled: false,
    to: "",
    from: "",
    english: {
      title: "",
      subTitle: ""
    },
    arabic: {
      title: "",
      subTitle: ""
    }
  },
  CAROUSEL_TYPE_B: {
    enabled: false,
    to: "",
    from: "",
    english: {
      title: "",
      searchUrl: ""
    },
    arabic: {
      title: "",
      searchUrl: ""
    }
  }
};
