import { createSelector } from "reselect";

export const selectStore = state => state.store;

export const selectStoreId = createSelector(
  [selectStore],
  store => store.storeId
);

export const selectCountryId = createSelector(
  [selectStore],
  store => store.countryId
);

export const selectStoreList = createSelector(
  [selectStore],
  store => store.storeList
);

export const selectSelectedStoreName = createSelector(
  [selectStore],
  store => store.selectedStoreName
);

export const selectSelectedCountryName = createSelector(
  [selectStore],
  store => store.selectedCountryName
);
