import React, { useState, Fragment, forwardRef, useMemo, useRef } from "react";
import Popup from "reactjs-popup";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  DELETE_ITEM_CONFIRMATION_MESSAGE,
  PAGES_PAGE,
  UNSAVED_CHANGES_WARNING_MESSAGE,
  URL_PAGE,
  SECTIONS_PAGE
} from "../../../constants";
import { arrayReorder } from "../../../util";
import {
  getBannerSchedulersAllLevels,
  isSchedulerPopUpEnabled
} from "../../../util/pagesHandlers";
import { BannerRowContent } from "./BannerRowContent";
import Accordion from "../../accordion";
import Scheduler from "../../scheduler";
import CustomModal from "../../../containers/modal/ConfirmModal";
import { toastMsg } from "../../../actions/common.action";
import { CloneRowPopupContent } from "../../../containers/pagesPage/popupContent";

import { selectActivePageContent, selectActiveUrlPageContent, selectMenuItemContent } from "../../../selectors";
import {
  clearBannerRowImageData,
  updateBannerSectionData,
  addNewBannerScheduler,
  handleDeleteBannerScheduler,
  handleRowSchedulerToggle,
  clearBannerSchedulerRowImageData,
  copyRowContentToScheduler,
  cloneRow,
  cloneScheduler,
  handleDataChangeBannerScheduler
} from "../../../actions/dynamicBanner.action";

export const BannerRow = forwardRef(
  (
    {
      rowIndex,
      toogleRow,
      rowData,
      deleteRow,
      updateRowData,
      listPosition,
      deviceType,
      rows,
      pageType,
      updateCatalogPageType,
      updateCatalogPageLocation,
      path,
      isScheduler
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const content = useSelector(
      pageType === PAGES_PAGE
        ? ({
          pagesPageReducer: {
            pagesContent: { content }
          }
        }) => content
        : (pageType === URL_PAGE ? selectActiveUrlPageContent :
          pageType === SECTIONS_PAGE ? selectActivePageContent : selectMenuItemContent)
    );

    const popupRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [
      unsavedChangesModalVisible,
      setUnsavedChangesModalVisible
    ] = useState(false);
    const [rowBannerType, setRowModalType] = useState(null);
    const [schedulerIdx, setSchedulerIdx] = useState(null);
    const [deleteRowModalVisible, setDeleteRowModalVisible] = useState(false);
    const [
      deleteSchedulerModalVisible,
      setDeleteSchedulerModalVisible
    ] = useState(false);
    const [
      schedulerBannerModalVisible,
      setSchedulerBannerModalVisible
    ] = useState(false);
    const [
      sectionsRowCloneModalVisible,
      setSectionsRowCloneModalVisible
    ] = useState(false);

    const itemList = get(rowData, "imageList", []);

    const rowTitleData = get(rowData, "title", "");
    const rowTitle =
      rowTitleData ||
      `Row ${rowIndex + 1} - ${deviceType.charAt(0).toUpperCase() + deviceType.slice(1)
      }`;

    const defaultPath = useMemo(() => [...path, "rows"], [path]);

    const clearRowImageData = () => {
      dispatch(
        clearBannerRowImageData({
          path: [...defaultPath, rowIndex],
          pageType,
          updateCatalogPageType,
          updateCatalogPageLocation
        })
      );
    };

    const reorderRows = direction => {
      const toIndex = direction === "up" ? rowIndex - 1 : rowIndex + 1;

      if (toIndex < 0 || toIndex >= rows.length) return;
      const newArray = arrayReorder(rows, rowIndex, toIndex);

      dispatch(
        updateBannerSectionData({
          path: defaultPath,
          value: newArray,
          pageType,
          updateCatalogPageType,
          updateCatalogPageLocation
        })
      );
    };

    const handleChangeBannerType = value => {
      if (!isEmpty(itemList)) {
        setUnsavedChangesModalVisible(true);
        setRowModalType(value);
      } else {
        clearRowImageData();
        updateRowData("bannerType", value);
      }
    };

    const handleModalConfirm = () => {
      if (schedulerIdx < 0 || schedulerIdx === null) {
        clearRowImageData();
        updateRowData("bannerType", rowBannerType);
      } else {
        dispatch(
          clearBannerSchedulerRowImageData({
            path: [
              ...defaultPath,
              rowIndex,
              "schedulers",
              schedulerIdx,
              "imageList"
            ],
            pageType,
            updateCatalogPageType,
            updateCatalogPageLocation
          })
        );
        handleSchedulerDataChange(schedulerIdx, "bannerType", rowBannerType);
      }

      setUnsavedChangesModalVisible(false);
      setSchedulerIdx(null);
    };

    const addScheduler = () => {
      dispatch(
        addNewBannerScheduler({
          path: [...defaultPath, rowIndex],
          pageType,
          updateCatalogPageType,
          updateCatalogPageLocation
        })
      );
    };

    const deleteScheduler = schedulerIndex => {
      dispatch(
        handleDeleteBannerScheduler({
          path: [...defaultPath, rowIndex],
          schedulerIndex,
          pageType,
          updateCatalogPageType,
          updateCatalogPageLocation
        })
      );
    };

    const handleSchedulerDataChange = (schedulerIndex, key, value) =>
      dispatch(
        handleDataChangeBannerScheduler({
          path: [...defaultPath, rowIndex, "schedulers", schedulerIndex],
          key,
          value,
          pageType,
          updateCatalogPageType,
          updateCatalogPageLocation
        })
      );

    const handleSchedulerToggleChange = (_type, schedulerIndex, value) => {
      const [contentPosition] = defaultPath;
      if (
        !value ||
        (content[contentPosition][deviceType]["rows"][rowIndex]["schedulers"][
          schedulerIndex
        ].from &&
          content[contentPosition][deviceType]["rows"][rowIndex]["schedulers"][
            schedulerIndex
          ].to)
      ) {
        dispatch(
          handleRowSchedulerToggle({
            path: [...defaultPath, rowIndex, "schedulers", schedulerIndex],
            key: "enabled",
            value,
            pageType
          })
        );
      } else {
        dispatch(toastMsg("error", "Missing from and to dates"));
      }
    };

    const handleChangeSchedulerBannerType = ({
      value,
      schedulerIndex,
      schedulerContent
    }) => {
      const schedulerImageList = get(schedulerContent, "imageList", []);
      if (!isEmpty(schedulerImageList)) {
        setUnsavedChangesModalVisible(true);
        setSchedulerIdx(schedulerIndex);
        setRowModalType(value);
      } else {
        dispatch(
          clearBannerSchedulerRowImageData({
            path: [
              ...defaultPath,
              rowIndex,
              "schedulers",
              schedulerIdx,
              "imageList"
            ],
            pageType,
            updateCatalogPageType,
            updateCatalogPageLocation
          })
        );
        handleSchedulerDataChange(schedulerIndex, "bannerType", value);
      }
    };

    const handleDeleteRow = () => setDeleteRowModalVisible(true);
    const handleShowSchedulerRow = () => setSchedulerBannerModalVisible(true);

    const handleDeleteScheduler = schedulerIndex => {
      setDeleteSchedulerModalVisible(true);
      setSchedulerIdx(schedulerIndex);
    };

    const copyContentToScheduler = schedulerIndex => {
      dispatch(
        copyRowContentToScheduler({
          path: [...defaultPath, rowIndex],
          schedulerIndex,
          pageType,
          updateCatalogPageLocation,
          updateCatalogPageType
        })
      );
      dispatch(toastMsg("success", "Copied content to scheduler"));
    };

    const handleCloneRow = moduleId =>
      dispatch(
        cloneRow({
          path: defaultPath,
          rowIndex,
          moduleId,
          pageType,
          updateCatalogPageLocation,
          updateCatalogPageType
        })
      );

    const handleCloneScheduler = schedulerIndex =>
      dispatch(
        cloneScheduler({
          path: [...defaultPath, rowIndex, "schedulers"],
          schedulerIndex,
          pageType,
          updateCatalogPageLocation,
          updateCatalogPageType
        })
      );

    const renderCloneButton = () => (
      <Popup
        ref={popupRef}
        trigger={
          <span
            data-tip={rowTitle ? `Clone ${rowTitle}` : "Clone"}
            className="clone-btn"
          />
        }
        position="left center"
        closeOnDocumentClick={false}
      >
        <CloneRowPopupContent
          onConfirm={moduleId => {
            handleCloneRow(moduleId);
            dispatch(toastMsg("success", "Cloned successfully"));
            popupRef.current.close();
          }}
          onCancel={() => popupRef.current.close()}
          modules={content ? content : []}
          rowTitle={rowTitle}
        />
      </Popup>
    );

    const cloneRowSectionsModule = () => setSectionsRowCloneModalVisible(true);

    const bannerSchedulers = getBannerSchedulersAllLevels(content, path);
    const enableTogglePopup = isSchedulerPopUpEnabled(
      bannerSchedulers.bannerSchedulers
    );

    return (
      <div ref={ref}>
        {unsavedChangesModalVisible && (
          <CustomModal
            modalName="row-confirmation-modal"
            onHide={() => setUnsavedChangesModalVisible(false)}
            confirm={handleModalConfirm}
            message={UNSAVED_CHANGES_WARNING_MESSAGE}
          />
        )}
        {deleteRowModalVisible && (
          <CustomModal
            modalName="row-confirmation-modal"
            onHide={() => setDeleteRowModalVisible(false)}
            confirm={() => {
              deleteRow();
              setDeleteRowModalVisible(false);
            }}
            message={`Are you sure you want to delete ${rowTitle}? You can always undo your changes before saving`}
          />
        )}
        {deleteSchedulerModalVisible && (
          <CustomModal
            modalName="row-confirmation-modal"
            onHide={() => setDeleteSchedulerModalVisible(false)}
            confirm={() => {
              deleteScheduler(schedulerIdx);
              setSchedulerIdx(null);
              setDeleteSchedulerModalVisible(false);
            }}
            message={DELETE_ITEM_CONFIRMATION_MESSAGE}
          />
        )}
        {schedulerBannerModalVisible && (
          <CustomModal
            modalName="row-confirmation-modal"
            onHide={() => setSchedulerBannerModalVisible(false)}
            confirm={() => {
              addScheduler();
              setSchedulerBannerModalVisible(false);
            }}
            message={`Are you sure you want to add a new scheduler inside ${rowTitle}? You can always undo your changes before saving`}
          />
        )}
        {sectionsRowCloneModalVisible && (
          <CustomModal
            modalName="row-confirmation-modal"
            onHide={() => setSectionsRowCloneModalVisible(false)}
            confirm={() => {
              // handleCloneRow(moduleId);
              dispatch(toastMsg("success", "Cloned successfully"));

              setSectionsRowCloneModalVisible(false);
            }}
            message={`Are you sure you want to clone the ${rowTitle}? You can always undo your changes before saving`}
          />
        )}
        <Accordion
          accordionId="banner-accordion"
          title={rowTitle}
          headingWrapperClassName="banner-heading"
          handleDelete={handleDeleteRow}
          handleToggle={toogleRow}
          handleOpen={() => setIsOpen(!isOpen)}
          isToggled={get(rowData, "enable", false)}
          isOpen={isOpen}
          isControlled
          withControlButtons
          keyIndex={rowIndex}
          reorderRows={direction => reorderRows(direction)}
          rowData={rowData}
          //addScheduler={addScheduler}
          addScheduler={handleShowSchedulerRow}
          disableAddScheduler={isScheduler}
          renderCloneRow={renderCloneButton}
          renderCloneSectionsRow={cloneRowSectionsModule}
          // renderCloneRow={
          //   !(pageType === 'SECTIONS_PAGE')
          //     ? renderCloneButton
          //     : cloneRowSectionsModule
          // }
          handleModuleTitleChange={value => updateRowData("title", value)}
          pageType={pageType}
        >
          <BannerRowContent
            rowData={rowData}
            handleChangeBannerType={handleChangeBannerType}
            updateRowData={updateRowData}
            deviceType={deviceType}
            rowIndex={rowIndex}
            listPosition={listPosition}
            itemList={itemList}
            pageType={pageType}
            updateCatalogPageType={updateCatalogPageType}
            updateCatalogPageLocation={updateCatalogPageLocation}
            path={defaultPath}
          />

          <Scheduler
            handleModuleTitleChange={(schedulerIndex, value) =>
              handleSchedulerDataChange(schedulerIndex, "title", value)
            }
            addNewScheduler={addScheduler}
            childContent={[]}
            content={rowData}
            deleteScheduler={(_type, schedulerIndex) =>
              handleDeleteScheduler(schedulerIndex)
            }
            handleDateChange={handleSchedulerDataChange}
            handleToggleChange={handleSchedulerToggleChange}
            copyContentToScheduler={copyContentToScheduler}
            clone={handleCloneScheduler}
            disableButton
            enableTogglePopup={enableTogglePopup}
            togglePopupContent={
              "Enabling row scheduler will disable all the banner level schedulers. Continue?"
            }
            schedulerItemRenderer={schedulerIndex => {
              const schedulerContent = get(
                rowData,
                `schedulers[${schedulerIndex}]`
              );

              return (
                <Fragment>
                  <BannerRowContent
                    isScheduler
                    schedulerIndex={schedulerIndex}
                    rowData={schedulerContent}
                    handleChangeBannerType={value =>
                      handleChangeSchedulerBannerType({
                        value,
                        schedulerIndex,
                        schedulerContent
                      })
                    }
                    updateRowData={(type, value) =>
                      handleSchedulerDataChange(schedulerIndex, type, value)
                    }
                    deviceType={deviceType}
                    rowIndex={rowIndex}
                    listPosition={listPosition}
                    itemList={get(
                      rowData,
                      `schedulers[${schedulerIndex}].imageList`,
                      []
                    )}
                    pageType={pageType}
                    updateCatalogPageType={updateCatalogPageType}
                    updateCatalogPageLocation={updateCatalogPageLocation}
                    path={defaultPath}
                  />
                </Fragment>
              );
            }}
          />
        </Accordion>
      </div>
    );
  }
);

BannerRow.displayName = "BannerRow";
