import { CONTAINER_TYPE } from "../../constants";

export const pagesFoldersShape = {
  pageContainers: [
    {
      id: "container-1",
      title: "Default Pages",
      folderIds: ["folder-1", "folder-2"],
      type: CONTAINER_TYPE.DEFAULT
    },
    {
      id: "container-2",
      title: "Scheduled Pages",
      folderIds: ["folder-3"],
      type: CONTAINER_TYPE.SCHEDULED
    }
  ],
  folders: {
    // 'folder-1': {
    //   id: 'folder-1',
    //   title: 'Store Pages',
    //   pageIds: ['page-7', 'page-1', 'page-2'],
    // },
  },
  pages: {
    // 'page-1': { id: 'page-1', title: 'BFL GROUP' },
  },
  homePage: {}
};
