export const BANNER_NOTES_TOPICS = {
  "Countdown Component": [
    {
      code:
        '<div class="bfl-countdown" data-date="2020/20/20 20:20" data-type="0"></div>',
      text: "countdown component example."
    },
    {
      code: "bfl-countdown",
      text:
        "add this class to an empty div tag to create a countdown component for web and mobile banners."
    },
    {
      code: 'data-date="2020/20/20 20:20"',
      text:
        "add data-date attribute to set the end date for the countdown component."
    },
    {
      code: 'data-type="0"',
      text:
        "add data-type attribute to set the style type of the countdown component. Currently we accept values ranging from 0-3, defaults to type 0 if data-type is ommited."
    },
    {
      code:
        'bfl-countdown > bfl-countdown_container > bfl-countdown_section > bfl-countdown_box bfl-countdown_box bfl-countdown_text"',
      text:
        "apply additional class to the main div and use the following class nesting structure to restyle the countdown component."
    }
  ],
  "Country Prefix": [
    {
      code: '<a href="/${countryPrefix}/url"></a>',
      text: "country prefix example."
    },
    {
      code: "${countryPrefix}",
      text:
        "use this variable for url links and it will be replaced with country-language prefix for each individual country"
    }
  ]
};
