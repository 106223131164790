import {
  ADD_NEW_SECTIONS_COMPONENT,
  SET_SECTIONS_ACTIVE_PAGE,
  SECTIONS_PAGE_OPEN_COMPONENT,
  SECTIONS_PAGE_TOGGLE_COMPONENT,
  SECTIONS_PAGE_DELETE_COMPONENT,
  SET_SECTIONS_PAGE_DATA,
  SET_SECTIONS_MODULES,
  SET_MODULE_SECTIONS_PAGE,
  TOGGLE_RESET_SECTIONS_PAGE,
  RESET_PAGES_CONTENT,
  RESET_SECTIONS_CONTENT,
  UPDATE_MODULE_SECTIONS_PAGE,
  UPDATE_MODULE_PAGE,
  SET_SECTIONS_ROLLBACK_HISTORY,
} from "../constants/actions";
import qs from "query-string";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
  snackbar,
} from "./common.action";
import { SECTIONS_PAGE_ACTIVITIES } from "../constants/common";
import {
  deleteWebApi,
  fetchWebApi,
  postDashboardWebApi,
  putDashboardWebApi,
  patchDashboardWebApi
} from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import { ENV_CONFIG } from "../config/env";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../constants";
import { setSubmitState } from "./common.action";
import { mapPagesPageToFlatStructure } from "../util/reducer";
import { generateUUID, getPageRoute } from "../util";
import { getPageData, setPagesRoute } from "./pagesPage.action";

export const addNewComponent = (componentType, updatePageContent) => ({
  type: ADD_NEW_SECTIONS_COMPONENT,
  componentType,
  updatePageContent
});

export const setActiveSectionsPage = page => ({
  type: SET_SECTIONS_ACTIVE_PAGE,
  payload: page
});

export const openComponent = ({ id, index }) => ({
  type: SECTIONS_PAGE_OPEN_COMPONENT,
  id,
  index
});

export const toggleSection = ({ index }) => ({
  type: SECTIONS_PAGE_TOGGLE_COMPONENT,
  index
});
export const deleteComponent = ({ index }) => ({
  type: SECTIONS_PAGE_DELETE_COMPONENT,
  index
});
const setPages = pages => ({
  type: SET_SECTIONS_MODULES,
  payload: pages
});
const setModuleSectionPages = pages => ({
  type: SET_MODULE_SECTIONS_PAGE,
  payload: pages
});
const updateModuleSectionPages = (pages, data) => ({
  type: UPDATE_MODULE_SECTIONS_PAGE,
  payload: pages,
  data
});

const updateModulePages = (pages, data) => ({
  type: UPDATE_MODULE_PAGE,
  payload: pages,
  data
});

export const toggleReset = value => ({
  type: TOGGLE_RESET_SECTIONS_PAGE,
  value
});

export const resetSectionsContent = () => ({ type: RESET_SECTIONS_CONTENT });

export const fetchSectionsPages = countryId => {
  let apiURL = `${ENV_CONFIG.configBaseURL}${ENV_CONFIG.versionInfo}/sections?&countryId=${countryId}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    // dispatch(resetAddPageContent());
    // const state = getState();

    //return state.sectionsReducer.initialData;
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.status === 200) {
          //filterAllPages(response.data.data, dispatch);
          dispatch({
            type: SET_SECTIONS_PAGE_DATA,
            payload: response.data.data
          });
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchSectionsPagesList = (moduleType = "") => {
  return (dispatch, getState) => {
    const state = getState();
    const countryId = state.store.countryId;
    let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/sections?countryId=${countryId}&moduleType=${moduleType}`;
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      });
  };
};

export const getSection = sectionId => (dispatch, getState) => {
  const state = getState();

  const { store } = state;
  const { countryId } = store;

  dispatch(showLoadingIndicator());

  return fetchWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/sections/${sectionId}`,
      query: { countryId }
    })
  )
    .then(response => {
      if (response.status === 200) {
        dispatch(setActiveSectionsPage(response.data.data));
      }
      dispatch(hideLoadingIndicator());
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);
    });
};

export const updateSection = (section, tempsave) => (dispatch, getState) => {
  const state = getState();
  const { store, selectedCountryId } = state;
  const { countryId } = store;

  dispatch(showLoadingIndicator());

  const bodyData = { countryIds: selectedCountryId, data: section };

  return putDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/sections/${section._id}`,
      query: { countryId }
    }),
    bodyData,
    tempsave
  )
    .then(response => {
      const { data } = response;
      if (response.status === 200 && data.code === 200) {
        dispatch(getSection(section._id));
        dispatch(snackbar({
          open: true, severity: "success",
          text: "Updated Successfully"
        }));
        dispatch(setActiveSectionsPage(response.data.data));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message || "Something went wrong"
        }));

      }

      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

const manageRequestPromise = (requestPromise, dispatch, activityType) =>
  requestPromise
    .then(response => {
      const { data } = response;
      if (data.code === 200) {
        dispatch(setPages(data.data));
        activityType &&
          dispatch(snackbar({
            open: true, severity: "success",
            text: SUCCESS_MESSAGES[activityType]
          }));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message ||
            response.data.error.detail ||
            ERROR_MESSAGES[activityType]
        }));
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });

export const createModule = (moduleName, moduleSlug, moduleType) => (
  dispatch,
  getState
) => {
  const state = getState();

  const { store } = state;
  const { countryId, storeId } = store;

  dispatch(showLoadingIndicator());

  const bodyData = { moduleName, moduleSlug, moduleType };

  return manageRequestPromise(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/sections/module`,
        query: { storeId, countryId, moduleName }
      }),
      bodyData
    ),
    dispatch,
    SECTIONS_PAGE_ACTIVITIES.MODULE_CREATED
  );
};

export const deleteModule = id => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId, storeId } = store;
  dispatch(showLoadingIndicator());
  return manageRequestPromise(
    deleteWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/sections/module/${id}`,
        query: { storeId, countryId }
      })
    ),
    dispatch,
    SECTIONS_PAGE_ACTIVITIES.MODULE_DELETED
  );
};

const manageRequestPromiseSections = (requestPromise, dispatch, activityType) =>
  requestPromise
    .then(response => {
      const { data } = response;
      if (data.code === 200) {
        dispatch(setModuleSectionPages(data.data));
        dispatch(snackbar({
          open: true, severity: "success",
          text: "Created sections successfully"
        }));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message ||
            response.data.error.detail ||
            "Something went wrong"
        }));
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });

export const createSection = (sectionName, sectionSlug, moduleSlug) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { store, selectedCountryId } = state;
  const { countryId, storeId } = store;
  const bodyData = {
    countryIds: selectedCountryId,
    moduleSlug: moduleSlug,
    data: {
      sectionName,
      sectionSlug,
      title: sectionName,
      moduleType: moduleSlug,
      content: [
        {
          id: generateUUID(),
          title: "Dynamic banner",
          type: "dynamicBannerModule",
          enable: true,
          backgroundColor: "#3f3f3f",
          desktop: {
            rows: [],
            styles: {
              backgroundColor: "#FFFFFF",
              backgroundType: "backgroundColor",
              marginBottom: 0,
              marginTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0
            }
          },
          mobile: {
            rows: [],
            styles: {
              backgroundColor: "#FFFFFF",
              backgroundType: "backgroundColor",
              marginBottom: 0,
              marginTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0
            }
          },
          tablet: {
            rows: [],
            styles: {
              backgroundColor: "#FFFFFF",
              backgroundType: "backgroundColor",
              marginBottom: 0,
              marginTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0
            }
          }
        }
      ]
    }
  };
  dispatch(showLoadingIndicator());

  return manageRequestPromiseSections(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/sections`,
        query: { storeId, countryId }
      }),
      bodyData
    ),
    dispatch,
    SECTIONS_PAGE_ACTIVITIES.SECTIONS_CREATED
  );
};

export const deleteSection = (id, moduleSlug) => (dispatch, getState) => {
  const state = getState();

  const { store } = state;
  const { countryId, storeId } = store;

  dispatch(showLoadingIndicator());

  return manageRequestPromise(
    deleteWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/sections/${id}`,
        query: { storeId, countryId, moduleSlug }
      })
    ),
    dispatch,
    SECTIONS_PAGE_ACTIVITIES.MODULE_DELETED
  ).then(() => {
    dispatch(setActiveSectionsPage(null));
  });
};

export const updateSectionModule = data => (dispatch, getState) => {
  const state = getState();
  const { store, selectedCountryId } = state;
  const { countryId } = store;

  dispatch(showLoadingIndicator());

  const bodyData = {
    countryIds: selectedCountryId,
    moduleSlug: data.moduleType,
    data: {
      sectionName: data.sectionName,
      sectionSlug: data.sectionSlug,
      title: data.sectionName,
      moduleType: data.moduleType
    }
  };

  return putDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/sections/${data._id}`,
      query: { countryId }
    }),
    bodyData
  )
    .then(response => {
      const { data } = response;
      if (response.status === 200 && data.code === 200) {
        dispatch(snackbar({
          open: true, severity: "success",
          text: "Updated successfully"
        }));
        dispatch(updateModuleSectionPages(response.data.data, data));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message || "Something went wrong"
        }));
      }
    })
    .catch(error => {
      handleFetchError(error, dispatch);
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

export const updateModule = data => (dispatch, getState) => {
  const state = getState();

  const { store, selectedCountryId } = state;
  const { countryId } = store;

  dispatch(showLoadingIndicator());
  return patchDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/sections/module/${data._id}?countryId=${countryId}`
    }),
    { moduleName: data.moduleName, moduleSlug: data.moduleSlug }
  )
    .then(response => {
      const { data } = response;
      if (response.status === 200 && data.code === 200) {
        dispatch(snackbar({
          open: true, severity: "success",
          text: "Updated successfully"
        }));
        dispatch(updateModulePages(response.data.data, data));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message || "Something went wrong"
        }));
      }
    })
    .catch(error => {
      handleFetchError(error, dispatch);
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

//Rollback
export const setPagesRollbackHistory = history => ({
  type: SET_SECTIONS_ROLLBACK_HISTORY,
  payload: history
});

export const getAllRollbackHistory = (id, fromDate, toDate) => (dispatch, getState) => {
  const state = getState();
  const { store } = state;
  const { countryId } = store;

  dispatch(showLoadingIndicator());

  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/history/sections/${id}?countryId=${countryId}`
    }),
    { fromDate, toDate }
  )
    .then(response => {
      dispatch(setPagesRollbackHistory(response.data));
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};

const manageRequestPromiseRollback = (requestPromise, dispatch, activityType) =>
  requestPromise
    .then(response => {
      const { data } = response;
      if (data.code === 200) {
        dispatch(setActiveSectionsPage(data.data));
        dispatch(snackbar({
          open: true, severity: "success",
          text: "Rollback successful"
        }));
      } else {
        dispatch(snackbar({
          open: true, severity: "error",
          text: response.data.error.message ||
            response.data.error.detail ||
            ERROR_MESSAGES[activityType]
        }));
      }
      return response;
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });

export const setPageRollback = id => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  return manageRequestPromiseRollback(
    postDashboardWebApi(
      getAccessToken(getState),
      qs.stringifyUrl({
        url: `${ENV_CONFIG.configBaseURL}/history/sections/rollback`
      }),
      { versionId: id }
    ),
    dispatch
  );
};

export const getRollbackCompareVersionData = (id, versions) => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/history/sections/content?referenceId=${id}`
    }),
    { versions }
  )
    .then(response => {
      if (!response || response.status !== 200 ||
        !response.data || !response.data.data ||
        response.data.data.length < 2) {
        dispatch(snackbar({ open: true, severity: "error", text: "Error while fetching versions Info " }));
        return []
      } else {
        return response.data.data
      }
    })
    .catch(error => {
      handleFetchError(error, dispatch);
      return error.response;
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    });
};
