export const URL_LIST = "URL_LIST";
export const UPDATE_TOGGLE_URL_DATA = "UPDATE_TOGGLE_URL_DATA";
export const UPDATE_URL = "UPDATE_URL";
export const VALIDATE_URL = "VALIDATE_URL";
export const FILTER_CONDITION_COUNT = "FILTER_CONDITION_COUNT";
export const SET_URL_PAGE_DATA = "SET_URL_PAGE_DATA";
export const RESET_URL_PAGE_DATA = "RESET_URL_PAGE_DATA";
export const UPDATE_ACTIVE_URL_PAGE_DATA = "UPDATE_ACTIVE_URL_PAGE_DATA";
export const URL_PAGE_OPEN_COMPONENT = "URL_PAGE_OPEN_COMPONENT";
export const URL_PAGE_TOGGLE_COMPONENT = "URL_PAGE_TOGGLE_COMPONENT";
export const MODULE_DATA_CHANGE_URL_PAGE = "MODULE_DATA_CHANGE_URL_PAGE";
export const CLONE_MOBILE_URL_PAGE = "CLONE_MOBILE_URL_PAGE";
export const TOGGLE_LIST_ITEM_URL_PAGE = "TOGGLE_LIST_ITEM_URL_PAGE";
export const DELETE_LIST_ITEM_URL_PAGE = "DELETE_LIST_ITEM_URL_PAGE";
export const UPDATE_LIST_DATA_URL_PAGE = "UPDATE_LIST_DATA_URL_PAGE";
export const GENERATE_IMAGES_URL_PAGE = "GENERATE_IMAGES_URL_PAGE";
export const UPDATE_BANNER_SECTION_DATA_STYLES_URL_PAGE = "UPDATE_BANNER_SECTION_DATA_STYLES_URL_PAGE";
export const PUT_BANNER_DATA_STYLES_URL_PAGE = "PUT_BANNER_DATA_STYLES_URL_PAGE";
export const CLEAR_BANNER_ROW_IMAGE_DATA_URL_PAGE = "CLEAR_BANNER_ROW_IMAGE_DATA_URL_PAGE";
export const UPDATE_BANNER_SECTION_DATA_URL_PAGE = "UPDATE_BANNER_SECTION_DATA_URL_PAGE";
export const CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_URL_PAGE = "CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_URL_PAGE";
export const HANDLE_DATA_CHANGE_BANNER_SCHEDULER_URL_PAGE = "HANDLE_DATA_CHANGE_BANNER_SCHEDULER_URL_PAGE";
export const HANDLE_ROW_SCHEDULER_TOGGLE_URL_PAGE = "HANDLE_ROW_SCHEDULER_TOGGLE_URL_PAGE";
export const COPY_CONTENT_TO_SCHEDULER_URL_PAGE = "COPY_CONTENT_TO_SCHEDULER_URL_PAGE";
export const CLONE_ROW_URL_PAGE = "CLONE_ROW_URL_PAGE";
export const CLONE_SCHEDULER_URL_PAGE = "CLONE_SCHEDULER_URL_PAGE";
export const HANDLE_DELETE_BANNER_SCHEDULER_URL_PAGE = "HANDLE_DELETE_BANNER_SCHEDULER_URL_PAGE";
export const ADD_BANNER_SCHEDULER_URL_PAGE = "ADD_BANNER_SCHEDULER_URL_PAGE";
export const ADD_BANNER_ROW_ITEM_URL_PAGE = "ADD_BANNER_ROW_ITEM_URL_PAGE";
export const DELETE_ROW_IMAGE_URL_PAGE = "DELETE_ROW_IMAGE_URL_PAGE";
export const PRODUCT_CAROUSEL_DATA_CHANGE_URL_PAGE = "PRODUCT_CAROUSEL_DATA_CHANGE_URL_PAGE";
export const REORDER_BANNER_ROW_IMAGES_URL_PAGE = "REORDER_BANNER_ROW_IMAGES_URL_PAGE";
export const APPLY_GENERAL_SPACING_URL_PAGE = "APPLY_GENERAL_SPACING_URL_PAGE";
export const UPDATE_BANNER_ROW_IMAGE_DATA_URL_PAGE = "UPDATE_BANNER_ROW_IMAGE_DATA_URL_PAGE";
export const CHANGE_BANNER_IMAGE_HOVER_STYLE_URL_PAGE = "CHANGE_BANNER_IMAGE_HOVER_STYLE_URL_PAGE";
export const COUNTDOWN_DATA_CHANGE_URL_PAGE = "COUNTDOWN_DATA_CHANGE_URL_PAGE";
export const SET_URL_ROLLBACK_HISTORY = "SET_URL_ROLLBACK_HISTORY";