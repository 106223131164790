export const UPDATE_FOOTER_TREE_RECORDS = "UPDATE_FOOTER_TREE_RECORDS";
export const GET_FOOTER_DATA = "GET_FOOTER_DATA";
export const HANDLE_FOOTER_STATIC_TEXT = "HANDLE_FOOTER_STATIC_TEXT";
export const CLEAR_FOOTER_CONTENT = "CLEAR_FOOTER_CONTENT";
export const PUT_FOOTER_DATA = "PUT_FOOTER_DATA";
export const PUT_FOOTER_STATIC_DATA = "PUT_FOOTER_STATIC_DATA";
export const UPDATE_FOOTER_CONTENT = "UPDATE_FOOTER_CONTENT";
export const SAVE_FOOTER_CONTENT = "SAVE_FOOTER_CONTENT";
export const UPDATE_TREE_DATA = "UPDATE_TREE_DATA";
export const HANDLE_FOOTER_TEXT_CHANGE = "HANDLE_FOOTER_TEXT_CHANGE";
export const HANDLE_FOOTER_EDIT_ATTRIBUTE = "HANDLE_FOOTER_EDIT_ATTRIBUTE";
export const CREATE_FOOTER_TREE_RECORDS_INITIAL =
  "CREATE_FOOTER_TREE_RECORDS_INITIAL";
export const UPDATE_FOOTER_ON_TREE_CHANGE = "UPDATE_FOOTER_ON_TREE_CHANGE";
export const DELETE_FOOTER_ATTRIBUTE = "DELETE_FOOTER_ATTRIBUTE";
export const HANDLE_ADD_FOOTER_SOCIAL_MEDIA_LINK =
  "HANDLE_ADD_FOOTER_SOCIAL_MEDIA_LINK";
export const HANDLE_REMOVE_FOOTER_SOCIAL_MEDIA_LINK =
  "HANDLE_REMOVE_FOOTER_SOCIAL_MEDIA_LINK";
export const HANDLE_UPDATE_FOOTER_SOCIAL_MEDIA_LINK =
  "HANDLE_UPDATE_FOOTER_SOCIAL_MEDIA_LINK";

export const FOOTER_PAGE_OPEN_COMPONENT = "FOOTER_PAGE_OPEN_COMPONENT";

export const FOOTER_PAGE_TOGGLE_COMPONENT = "FOOTER_PAGE_TOGGLE_COMPONENT";

export const GENERATE_IMAGES_FOOTER_PAGE = "GENERATE_IMAGES_FOOTER_PAGE";

export const PUT_BANNER_DATA_STYLES_FOOTER_PAGE = "PUT_BANNER_DATA_STYLES_FOOTER_PAGE";

export const UPDATE_BANNER_SECTION_DATA_STYLES_FOOTER_PAGE = "UPDATE_BANNER_SECTION_DATA_STYLES_FOOTER_PAGE";

export const TOGGLE_LIST_ITEM_FOOTER_PAGE = "TOGGLE_LIST_ITEM_FOOTER_PAGE";

export const CLONE_ROW_FOOTER_PAGE = "CLONE_ROW_FOOTER_PAGE";

export const UPDATE_BANNER_SECTION_DATA_FOOTER_PAGE = "UPDATE_BANNER_SECTION_DATA_FOOTER_PAGE";

export const DELETE_LIST_ITEM_FOOTER_PAGE = "DELETE_LIST_ITEM_FOOTER_PAGE";

export const CLEAR_BANNER_ROW_IMAGE_DATA_FOOTER_PAGE = "CLEAR_BANNER_ROW_IMAGE_DATA_FOOTER_PAGE";

export const UPDATE_LIST_DATA_FOOTER_PAGE = "UPDATE_LIST_DATA_FOOTER_PAGE";

export const ITEMS_PER_GROUP_CHANGE_FOOTER_PAGE = "ITEMS_PER_GROUP_CHANGE_FOOTER_PAGE";

export const APPLY_GENERAL_SPACING_FOOTER_PAGE = "APPLY_GENERAL_SPACING_FOOTER_PAGE";

export const ADD_ROW_TYPE_CUSTOM_FIELD_FOOTER_PAGE = "ADD_ROW_TYPE_CUSTOM_FIELD_FOOTER_PAGE";

export const UPDATE_ROW_TYPE_CUSTOM_MODULE_FOOTER_PAGE = "UPDATE_ROW_TYPE_CUSTOM_MODULE_FOOTER_PAGE";

export const ADD_ROW_TYPE_ACCORDION_FIELD_FOOTER_PAGE = "ADD_ROW_TYPE_ACCORDION_FIELD_FOOTER_PAGE";

export const UPDATE_ROW_TYPE_ACCORDION_DATA_FOOTER_PAGE = "UPDATE_ROW_TYPE_ACCORDION_DATA_FOOTER_PAGE";

export const UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_FOOTER_PAGE = "UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA_FOOTER_PAGE";

export const REMOVE_ROW_TYPE_ACCORDION_FIELD_FOOTER_PAGE = "REMOVE_ROW_TYPE_ACCORDION_FIELD_FOOTER_PAGE";

export const ADD_ROW_TYPE_BULLET_FIELD_FOOTER_PAGE = "ADD_ROW_TYPE_BULLET_FIELD_FOOTER_PAGE";

export const UPDATE_ROW_TYPE_BULLET_DATA_FOOTER_PAGE = "UPDATE_ROW_TYPE_BULLET_DATA_FOOTER_PAGE";

export const UPDATE_ROW_TYPE_BULLET_HEADER_DATA_FOOTER_PAGE = "UPDATE_ROW_TYPE_BULLET_HEADER_DATA_FOOTER_PAGE";

export const REMOVE_ROW_TYPE_BULLET_FIELD_FOOTER_PAGE = "REMOVE_ROW_TYPE_BULLET_FIELD_FOOTER_PAGE";

export const ADD_ROW_TYPE_TAB_FIELD_FOOTER_PAGE = "ADD_ROW_TYPE_TAB_FIELD_FOOTER_PAGE";

export const UPDATE_ROW_TYPE_TAB_DATA_FOOTER_PAGE = "UPDATE_ROW_TYPE_TAB_DATA_FOOTER_PAGE";

export const UPDATE_ROW_TYPE_TABS_FOOTER_PAGE = "UPDATE_ROW_TYPE_TABS_FOOTER_PAGE";

export const REMOVE_ROW_TYPE_TAB_FOOTER_PAGE = "REMOVE_ROW_TYPE_TAB_FOOTER_PAGE";

export const PRODUCT_CAROUSEL_DATA_CHANGE_FOOTER_PAGE = "PRODUCT_CAROUSEL_DATA_CHANGE_FOOTER_PAGE";

export const UPDATE_ROW_TYPE_CUSTOM_DATA_FOOTER_PAGE = "UPDATE_ROW_TYPE_CUSTOM_DATA_FOOTER_PAGE";

export const REMOVE_ROW_TYPE_CUSTOM_FIELD_FOOTER_PAGE = "REMOVE_ROW_TYPE_CUSTOM_FIELD_FOOTER_PAGE";

export const ADD_BANNER_SLIDER_ROW_IMAGES_FOOTER_PAGE = "ADD_BANNER_SLIDER_ROW_IMAGES_FOOTER_PAGE";

export const DELETE_ROW_IMAGE_FOOTER_PAGE = "DELETE_ROW_IMAGE_FOOTER_PAGE";

export const UPDATE_BANNER_ROW_IMAGE_DATA_FOOTER_PAGE = "UPDATE_BANNER_ROW_IMAGE_DATA_FOOTER_PAGE";

export const CHANGE_BANNER_IMAGE_HOVER_STYLE_FOOTER_PAGE = "CHANGE_BANNER_IMAGE_HOVER_STYLE_FOOTER_PAGE";

export const UPDATE_BANNER_DEFAULT_TEXT_FOOTER_PAGE = "UPDATE_BANNER_DEFAULT_TEXT_FOOTER_PAGE"

export const COUNTDOWN_DATA_CHANGE_FOOTER_PAGE = "COUNTDOWN_DATA_CHANGE_FOOTER_PAGE"

export const REORDER_BANNER_ROW_IMAGES_FOOTER_PAGE = "REORDER_BANNER_ROW_IMAGES_FOOTER_PAGE"

export const CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_FOOTER_PAGE = "CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA_FOOTER_PAGE"

export const CLONE_SCHEDULER_FOOTER_PAGE = "CLONE_SCHEDULER_FOOTER_PAGE"

export const COPY_CONTENT_TO_SCHEDULER_FOOTER_PAGE = "COPY_CONTENT_TO_SCHEDULER_FOOTER_PAGE"

export const HANDLE_DELETE_BANNER_SCHEDULER_FOOTER_PAGE = "HANDLE_DELETE_BANNER_SCHEDULER_FOOTER_PAGE"

export const HANDLE_DATA_CHANGE_BANNER_SCHEDULER_FOOTER_PAGE = "HANDLE_DATA_CHANGE_BANNER_SCHEDULER_FOOTER_PAGE"

export const ADD_BANNER_SCHEDULER_FOOTER_PAGE = "ADD_BANNER_SCHEDULER_FOOTER_PAGE"


export const ADD_BANNER_ROW_ITEM_FOOTER_PAGE = "ADD_BANNER_ROW_ITEM_FOOTER_PAGE"

export const CLONE_MOBILE_FOOTER_PAGE = "CLONE_MOBILE_FOOTER_PAGE"

export const MODULE_DATA_CHANGE_FOOTER_PAGE = "MODULE_DATA_CHANGE_FOOTER_PAGE"

export const ADD_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE = "ADD_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE"

export const REMOVE_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE = "REMOVE_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE"

export const ADD_ROW_TYPE_COLUMN_MENU_FOOTER_PAGE = "ADD_ROW_TYPE_COLUMN_MENU_FOOTER_PAGE";

export const UPDATE_ROW_TYPE_COLUMN_MENU_FOOTER_PAGE = "UPDATE_ROW_TYPE_COLUMN_MENU_FOOTER_PAGE";

export const DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE = "DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD_FOOTER_PAGE";