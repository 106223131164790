import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import _ from "lodash";
import { customModuleNames } from "../../constants";
const customStyles = {
  content: {
    width: "45%",
    maxHeight: "600px",
    minHeight: "auto",
    borderRadius: "none",
    position: "absolute",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },

  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

function CustomFieldInfoPopup({ customField, onHide, deviceType }) {
  const [customFeildData, setCustomFeildData] = useState();
  useEffect(() => {
    const data =
      customField && customModuleNames.find(item => item.value == customField);
    setCustomFeildData(data);
  }, []);

  return (
    <div onClick={e => e.stopPropagation()}>
      <Modal
        isOpen={true}
        ariaHideApp={false}
        onRequestClose={onHide}
        style={customStyles}
        closeTimeoutMS={2000}
      >
        <div className="modal-scheduler-container">
          <div className="scheduler-body">
            <div className="info-content mb-5">
              <div>
                {!_.isEmpty(customFeildData) &&
                !_.isEmpty(customFeildData.info[deviceType]) ? (
                  <div>
                    <h4>{customFeildData.label}</h4>
                    <div className="d-flex align-content-center mb-2 info-block">
                      <h6>Heading</h6>
                      <h6>Key</h6>
                      <h6>Value</h6>
                    </div>
                  </div>
                ) : (
                  <h4>No data available!</h4>
                )}
                {!_.isEmpty(customFeildData) &&
                  !_.isEmpty(customFeildData.info[deviceType]) &&
                  customFeildData.info[deviceType].map((item, index) => (
                    <div
                      className="d-flex justify-content-between align-content-center mb-2 "
                      key={index}
                    >
                      <div className="d-flex info-details">
                        <p className="mb-0">{item.heading}</p>
                        <p className="mb-0">{item.key}</p>
                        <p className=" mb-0">{item.value}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="scheduler-footer justify-content-end">
            <button className="btn btn-save" onClick={onHide}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CustomFieldInfoPopup;
