import React, { useState } from "react";
import { connect } from "react-redux";
import { SCHEDULER_TYPE } from "../../constants/catalog";
import {
  addNewScheduler,
  deleteScheduler,
  updateBannerDefaultText,
  addImageList,
  deleteComponent,
  handleToggle,
  schedulerAddImageList,
  updateSchedulerDefaultText,
  updateTypeBHandleDateChange
} from "../../actions/pagesPage.action";
import ImageCarouselAccordion from "../../containers/imageCarouselAccordion";
import UpdateCatalogImageList from "./updateCatalogImageList";

const UpdateCarouselTypeA = ({
  addNewScheduler,
  deleteScheduler,
  position,
  updateBannerDefaultText,
  addImageList,
  deleteComponent,
  updateCatalogHandleDateChange,
  handleToggle,
  schedulerAddImageList,
  updateSchedulerDefaultText,
  content,
  isOpen,
  handleOpen
}) => {
  const [showImageAccordion, setShowImageAccordion] = useState({
    index: 0,
    show: false
  });

  const [
    showSchedulerImageAccordion,
    setShowSchedulerImageAccordion
  ] = useState({ index: 0, show: false });

  const handleAddNewScheduler = () => {
    addNewScheduler(position, SCHEDULER_TYPE.CAROUSEL_TYPE_A);
  };

  const addNewImage = () => {
    addImageList("imageList", position);
  };

  const changeImageKeyIndex = index => {
    setShowImageAccordion(prevState => ({
      index,
      show: index === prevState.index ? !prevState.show : true
    }));
  };

  const changeSchedulerImageKeyIndex = index => {
    setShowSchedulerImageAccordion(prevState => ({
      index,
      show: index === prevState.index ? !prevState.show : true
    }));
  };

  const handleDeleteScheduler = (_type, index) => {
    deleteScheduler(position, index);
  };

  const deleteImageCarousel = () => {
    deleteComponent({ index: position });
  };

  const handleSchedulerDateChange = (schedulerIndex, id, timeStamp) => {
    updateCatalogHandleDateChange(id, schedulerIndex, timeStamp, position);
  };

  const handleSchedulerToggleChange = (_type, keyIndex, value) => {
    handleToggle(position, keyIndex, value);
  };

  const handleSchedulerTextChange = (name, value, language, schedulerIndex) => {
    updateSchedulerDefaultText(name, value, position, language, schedulerIndex);
  };

  const handleTextChange = (name, value, language) => {
    updateBannerDefaultText(language, value, position, name);
  };

  const handleSchedulerAddImageList = index => {
    schedulerAddImageList(index, "imageList", position);
  };

  const imageCarouselId = `image_carousel_${position}`;

  return (
    <ImageCarouselAccordion
      addNewImage={addNewImage}
      addNewScheduler={handleAddNewScheduler}
      changeImageKeyIndex={changeImageKeyIndex}
      changeSchedulerImageKeyIndex={changeSchedulerImageKeyIndex}
      content={content}
      deleteImageCarousel={deleteImageCarousel}
      deleteScheduler={handleDeleteScheduler}
      handleSchedulerDateChange={handleSchedulerDateChange}
      handleSchedulerTextChange={handleSchedulerTextChange}
      handleSchedulerToggleChange={handleSchedulerToggleChange}
      handleTextChange={handleTextChange}
      imageCarouselId={imageCarouselId}
      schedulerAddImageList={handleSchedulerAddImageList}
      showImageAccordion={showImageAccordion}
      showSchedulerImageAccordion={showSchedulerImageAccordion}
      isOpen={isOpen}
      handleOpen={handleOpen}
      keyIndex={position}
    >
      <UpdateCatalogImageList position={position} />
    </ImageCarouselAccordion>
  );
};

const mapDispatchToProps = {
  addNewScheduler,
  deleteScheduler,
  updateBannerDefaultText,
  addImageList,
  deleteComponent,
  updateCatalogHandleDateChange: updateTypeBHandleDateChange,
  handleToggle,
  schedulerAddImageList,
  updateSchedulerDefaultText
};

export default connect(null, mapDispatchToProps)(UpdateCarouselTypeA);
