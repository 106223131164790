const language = ["arabic", "english"];

export const addNewPage = addPage => {
  if (addPage) {
    if (!addPage.url || !addPage.title || !addPage.pageType) {
      return false;
    } else return checkForPages(addPage);
  }
};

const checkForPages = page => {
  let allPagesValidation = true;
  allPagesValidation &= page && commonValidationFunc(checkForSEO, page.meta);
  if (page) {
    if (page.pageType === "html") {
      allPagesValidation &= commonValidationFunc(htmlValidation, page.content);
    } else if (
      page.pageType === "accordion" ||
      page.pageType === "comb_accordion"
    ) {
      allPagesValidation &= accordionValidation(page.content);
    }
  }
  return allPagesValidation;
};

const checkForSEO = meta => {
  return language.map(lang => {
    if (
      meta &&
      meta[lang].title &&
      meta[lang].metaKeyword &&
      meta[lang].metaDescription &&
      meta[lang].headingH1 &&
      meta[lang].headingH2
    ) {
      return true;
    } else {
      return false;
    }
  });
};

const htmlValidation = content => {
  return language.map(lang => {
    if (content[lang].html) {
      return true;
    } else {
      return false;
    }
  });
};

const accordionValidation = content => {
  let itemVal = true;
  itemVal &= commonValidationFunc(htmlValidation, content);
  if (itemVal && content.accord.length !== 0) {
    content.accord &&
      content.accord.map(accord => {
        language.map(lang => {
          if (accord[lang].title && accord[lang].html) {
            itemVal &= true;
          } else {
            itemVal &= false;
          }
        });
      });
  }
  return itemVal;
};

const commonValidationFunc = (validateFunction, param) => {
  const validated = validateFunction(param);
  return validated[0] && validated[1];
};
