import React, { Fragment } from "react";
import cn from "classnames";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import infoIcon from "../../images/icon-info.svg";
import {
  ADD_MENU_ITEM_MODAL_PLACEHOLDERS,
  LANGUAGE,
  IMAGE_SIZE_AND_TYPE_TOOLTIP,
  MEGA_MENU_FONT_COLOR_OPTIONS
} from "../../constants";
import Image from "../../component/Image";
import InputText from "../../component/InputText";
import InputCheckbox from "../../component/InputCheckbox";
import InputRadioGroup from "../../component/InputRadioGroup";

const AddMenuItemModalForm = ({
  attributeData,
  handleAttributeChange,
  handleCommonAttributeChange,
  handleModal,
  isL3,
  language
}) => (
  <div className={`${language}-wrapper`}>
    <h4>{upperFirst(language)} Version</h4>
    <div className={`${language}-content`}>
      <InputText
        name="subTitle"
        label="Sub Title"
        language={language}
        value={get(attributeData, "subTitle", "")}
        placeholder={ADD_MENU_ITEM_MODAL_PLACEHOLDERS[language].subTitle}
        onChange={e =>
          handleAttributeChange(e.target.name, e.target.value, language)
        }
        required
      />
      <InputText
        name="redirectUrl"
        label="Redirection Url (must start with '/')"
        language={language}
        disabled={language === LANGUAGE.ARABIC}
        value={get(attributeData, "redirectUrl", "")}
        placeholder={ADD_MENU_ITEM_MODAL_PLACEHOLDERS[language].redirectUrl}
        onChange={e =>
          handleCommonAttributeChange(e.target.name, e.target.value)
        }
        disableWhitespaces
        required
      />
      <InputRadioGroup
        label="Font Color"
        name="color"
        language={language}
        onChange={(option, e) =>
          handleAttributeChange(e.target.name, option, language)
        }
        options={MEGA_MENU_FONT_COLOR_OPTIONS}
        required
        value={get(attributeData, "color.value")}
      />
      <InputCheckbox
        wrapperClassName={cn({ marginBottom: isL3 })}
        label="Bold"
        name="bold"
        value={get(attributeData, "bold", false)}
        onChange={e =>
          handleAttributeChange(e.target.name, e.target.checked, language)
        }
      />
      {!isL3 && (
        <InputText
          label="Group By"
          language={language}
          name="groupBy"
          disabled={language === LANGUAGE.ARABIC}
          onChange={e =>
            handleCommonAttributeChange(e.target.name, e.target.value)
          }
          placeholder={ADD_MENU_ITEM_MODAL_PLACEHOLDERS[language].groupBy}
          value={get(attributeData, "groupBy", "")}
          wrapperClassName="marginBottom"
        />
      )}
      <div className="img-preview">
        <span className="info img-info category-img-info">
          <Image src={infoIcon} alt="info icon" />
          <p className="tooltiptext">{IMAGE_SIZE_AND_TYPE_TOOLTIP}</p>
        </span>
        <div className="form-group preview hover-icon m-r-20">
          <span className="img-title">
            <span className="required" />
            Category Image
          </span>
          {get(attributeData, "imageUrl") ? (
            <Fragment>
              <span>
                <span className="img-overlay" />
                <span
                  className="icon-close"
                  onClick={() =>
                    handleAttributeChange("imageUrl", "", language)
                  }
                />
              </span>
              <span
                className="hover-camera-icon"
                onClick={() => handleModal("imageSelectionModal", language)}
              />
              <span className="uploaded-image">
                <Image
                  className="img-dimension"
                  alt="No Image"
                  src={get(attributeData, "imageUrl", "")}
                />
              </span>
            </Fragment>
          ) : (
            <span
              className="camera-icon"
              onClick={() => handleModal("imageSelectionModal", language)}
            />
          )}
        </div>
      </div>
      {language === LANGUAGE.ENGLISH && (
        <InputCheckbox
          label="Disabled"
          name="isDisabled"
          value={get(attributeData, "isDisabled", false)}
          onChange={e =>
            handleCommonAttributeChange(e.target.name, e.target.checked)
          }
        />
      )}
    </div>
  </div>
);

export default AddMenuItemModalForm;
