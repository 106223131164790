import React, { Component } from "react";
import Content from "./content";

class RightPanel extends Component {
  render() {
    const {
      currentTab,
      menuItemContent,
      updateMenuItemContent,
      updateFooterDetails,
      accessRights
    } = this.props;

    return (
      <div className="right-wrapper">
        <Content
          currentTab={currentTab}
          accessRights={accessRights}
          updateFooterDetails={updateFooterDetails}
          menuItemContent={menuItemContent}
          updateMenuItemContent={updateMenuItemContent}
        />
      </div>
    );
  }
}

export default RightPanel;
