const toasterNotificationOpts = (title = "", message = "") => {
  return {
    // uid: 'once-please', // you can specify your own uid if required
    title: title ? title : null,
    message: message,
    position: "tr",
    autoDismiss: 4
    // action: {
    //     label: 'Click me!!',
    //     callback: () => alert('clicked!')
    // }
  };
};

export default toasterNotificationOpts;
