import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import isEqual from "lodash/isEqual";
import {
  NAVIGATION,
  NAVIGATION_MENUS_SLUGS,
  SECTIONS_PAGE_PANEL,
  ERROR_MESSAGES,
} from "../../constants";
import { Paper } from "../../materialUi/components"
import RightPanel from "./rightPanel";
import SideBar from "../sideBar";
import ConfirmModal from "../modal/ConfirmModal";
import CountryDropDown from "../countryDropdownMUI";
import Subheader from "../../component/subheader"
import { changeTab, toastMsg } from "../../actions/common.action";
import {
  fetchSectionsPages,
  setActiveSectionsPage,
  createModule,
  deleteModule,
  createSection,
  deleteSection,
  getSection,
  updateSectionModule,
  updateModule,
  getAllRollbackHistory,
  setPagesRollbackHistory,
  setPageRollback,
} from "../../actions/sectionsPage.action";

class Sections extends Component {
  constructor() {
    super();
    this.updatePageContent = {};
    this.tempPageContent = {};
    this.sectionPopupRef = React.createRef();

    this.state = {
      currentTab: SECTIONS_PAGE_PANEL.SECTIONS_ADD_PAGE_DISPLAY,
      confirmModal: false,
      storeTempSelectedTab: "",
      updatePageIndex: 0,
      tempPageIndex: 0,
      currentTabName: `${SECTIONS_PAGE_PANEL.SECTIONS_UPDATE_PAGE_DISPLAY}0`,
      initialModuleType: ""
    };
  }

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.SECTIONS);
  }

  componentDidMount() {
    const { countryId, fetchSectionsPages } = this.props;
    countryId &&
      fetchSectionsPages(countryId).then(res => {
        if (res.data && res.data.code && res.data.code === 200) {
          this.initialLoad();
        }
      });
  }

  initialLoad = () => {
    if (!_.isEmpty(this.props.sectionsData) && this.props.sectionsData) {
      const data = this.props.sectionsData.find(
        item => item.children.length > 0
      );
      const item = data && data.children.find((item, index) => index == 0);
      this.props.getSection(item && item._id);
      this.setState({ initialModuleType: item && item.moduleType });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { storeId, countryId, fetchSectionsPages } = this.props;
    const { SECTIONS_DELETE_PAGE_DISPLAY } = SECTIONS_PAGE_PANEL;
    let e = { target: {} };
    e["target"]["id"] = SECTIONS_DELETE_PAGE_DISPLAY;
    e["target"]["name"] = SECTIONS_DELETE_PAGE_DISPLAY;
    if (
      +storeId !== +nextProps.storeId ||
      +countryId !== +nextProps.countryId
    ) {
      nextProps.storeId &&
        nextProps.countryId &&
        this.setState({ updatePageIndex: 0 });
      nextProps.storeId && nextProps.countryId && this.changeTab(e);
      nextProps.countryId &&
        fetchSectionsPages(nextProps.countryId).then(res => {
          if (res.data && res.data.code && res.data.code === 200) {
            this.initialLoad();
          }
        });
    }
  }

  changeTab = e => {
    const { currentTabName, confirmModal } = this.state;
    const targetTabName =
      typeof e.target.getAttribute === "function"
        ? e.target.getAttribute("name")
        : e.target.name;
    const { sectionsPageR } = this.props;
    const {
      SECTIONS_ADD_PAGE_DISPLAY,
      SECTIONS_DELETE_PAGE_DISPLAY
    } = SECTIONS_PAGE_PANEL;

    if (currentTabName !== targetTabName) {
      if (
        [SECTIONS_ADD_PAGE_DISPLAY, SECTIONS_DELETE_PAGE_DISPLAY].indexOf(
          currentTabName
        ) > -1
      ) {
        if (
          isEqual(
            sectionsPageR.initialAddPageContent,
            sectionsPageR.sectionsAddPageContent
          )
        ) {
          this.setState({
            currentTab: e.target.id,
            currentTabName: targetTabName
          });
        } else {
          this.setState({
            confirmModal: !confirmModal,
            storeTempSelectedTab: e.target.id,
            storeTempSelectedTabName: targetTabName
          });
        }
      } else {
        if (
          isEqual(
            sectionsPageR.initialSectionsPages,
            sectionsPageR.sectionsPages
          )
        ) {
          this.setState({
            currentTab: e.target.id,
            currentTabName: targetTabName
          });
        } else {
          this.setState({
            confirmModal: !confirmModal,
            storeTempSelectedTab: e.target.id,
            storeTempSelectedTabName: targetTabName
          });
        }
      }
    } else {
      if (
        [SECTIONS_ADD_PAGE_DISPLAY, SECTIONS_DELETE_PAGE_DISPLAY].indexOf(
          currentTabName
        ) > -1
      ) {
        if (
          isEqual(
            sectionsPageR.initialAddPageContent,
            sectionsPageR.sectionsAddPageContent
          )
        ) {
          this.setState({
            currentTab: e.target.id,
            currentTabName: targetTabName
          });
        } else {
          this.setState({
            confirmModal: !confirmModal,
            storeTempSelectedTab: e.target.id,
            storeTempSelectedTabName: targetTabName
          });
        }
      }
    }
  };

  hideModal = () =>
    this.setState(prevProps => ({
      confirmModal: !prevProps.confirmModal,
      storeTempSelectedTab: ""
    }));

  confirmModal = () => {
    const {
      confirmModal,
      storeTempSelectedTab,
      currentTab,
      storeTempSelectedTabName,
      tempPageIndex
    } = this.state;
    const { resetAddPageContent, resetUpdateStaticPage } = this.props;
    resetAddPageContent();
    resetUpdateStaticPage();
    this.setState({
      confirmModal: !confirmModal,
      currentTab: storeTempSelectedTab,
      storeTempSelectedTab: "",
      currentTabName: storeTempSelectedTabName,
      storeTempSelectedTabName: "",
      updatePageIndex: tempPageIndex
    });
    this.props.cancelAction(currentTab);
    this.updatePageContent = this.tempPageContent;
  };

  handlePageClick = updatePageContent => {
    const { getSection, activePage } = this.props;
    const { SECTIONS_UPDATE_PAGE_DISPLAY } = SECTIONS_PAGE_PANEL;

    if (activePage && activePage._id === updatePageContent._id) return null;

    getSection(updatePageContent._id);

    this.setState({
      currentTab: SECTIONS_UPDATE_PAGE_DISPLAY
    });
  };

  updateContent = data => {
    this.updatePageContent = data;
  };

  updatePopupContent = e => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  createModuleHandler = (data) => {
    const { createModule, toastMsg } = this.props;
    const { moduleName = "", moduleSlug = "" } = data || {};
    if (!(moduleName && moduleSlug)) {
      return toastMsg("error", `Please fill all input fields.`);
    } else if (moduleName !== moduleName.trim()) {
      return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Section Name`);
    } else if (moduleSlug !== moduleSlug.trim()) {
      return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Slug`);
    }
    createModule(moduleName, moduleSlug, moduleSlug);
    this.sectionPopupRef.current.close();
  };

  deleteModuleHandler = id => {
    const { deleteModule } = this.props;
    deleteModule(id);
    this.sectionPopupRef.current.close();
  };

  createSectionHandler = (data) => {
    const { createSection, toastMsg } = this.props;
    const { moduleSlug = "", sectionName = "", sectionSlug = "" } = data || {};
    if (!(sectionName && sectionSlug)) {
      return toastMsg("error", `Please fill all input fields.`);
    } else if (sectionName && (sectionName !== sectionName.trim())) {
      return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Section Name`)
    } else if (sectionSlug && (sectionSlug !== sectionSlug.trim())) {
      return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Slug`);
    }
    createSection(sectionName, sectionSlug, moduleSlug);
    this.sectionPopupRef.current.close();
  };

  deleteSectionHandler = (id, moduleSlug) => {
    const { deleteSection } = this.props;
    deleteSection(id, moduleSlug);
    this.sectionPopupRef.current.close();
  };

  updateSecPopupContent = data => {
    const { updateSectionModule, toastMsg } = this.props;
    const { sectionName = "", sectionSlug = "" } = data || {};
    if (!(sectionName && sectionSlug)) {
      return toastMsg("error", `Please fill all input fields.`);
    } else if (sectionName && (sectionName !== sectionName.trim())) {
      return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Section Name`)
    } else if (sectionSlug && (sectionSlug !== sectionSlug.trim())) {
      return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Slug`);
    }
    updateSectionModule(data);
    this.sectionPopupRef.current.close();
  };

  updateModulePopupContent = data => {
    const { updateModule, toastMsg } = this.props;
    const { moduleName = "", moduleSlug = "" } = data || {};
    if (moduleName && (moduleName !== moduleName.trim())) {
      return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Module Name`)
    } else if (moduleSlug && (moduleSlug !== moduleSlug.trim())) {
      return toastMsg("error", `${ERROR_MESSAGES.extraSpace} Slug`);
    }
    updateModule(data);
    this.sectionPopupRef.current.close();
  };

  getRollbackHistory = (pageId, fromDate, toDate) => {
    const { getAllRollbackHistory } = this.props;
    getAllRollbackHistory(pageId, fromDate, toDate)
  }

  closeRollbackModal = () => {
    const { setPagesRollbackHistory } = this.props;
    setPagesRollbackHistory([]);
  };

  onConfirmRollback = (id) => {
    const { setPageRollback } = this.props;
    setPageRollback(id);
  }

  render() {
    const {
      currentTab,
      confirmModal,
      updatePageIndex,
      currentTabName
    } = this.state;
    const {
      selectedCountryName,
      sectionsData,
      activePage,
      countryId,
      user,
      rollbackHistory
    } = this.props;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.SECTIONS]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    const breadCrumbArr = [];

    if (activePage?.sectionName) {
      breadCrumbArr.push(activePage.sectionName);
    }

    return (
      <div className="row" style={{ "backgroundColor": "rgb(242, 245, 249)", "marginLeft": 0, "marginRight": 0, "paddingTop": "10px" }}>
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.hideModal}
            confirm={this.confirmModal}
            message={
              "All the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        <div className="component-header">
          <div className="col-sm-3" style={{ "paddingLeft": "10px", "paddingRight": "5px" }}>
            <Paper
              sx={{
                padding: "20px 15px 15px 15px",
              }}
              variant="outlined"
            >
              <CountryDropDown />
              <SideBar
                currentTab={currentTab}
                changeTab={this.changeTab}
                sectionsData={(sectionsData && sectionsData) || ""}
                handlePageClick={this.handlePageClick}
                createModuleHandler={this.createModuleHandler}
                updateModulePopupContent={this.updateModulePopupContent}
                sectionPopupRef={this.sectionPopupRef}
                deleteModuleHandler={this.deleteModuleHandler}
                createSectionHandler={this.createSectionHandler}
                deleteSectionHandler={this.deleteSectionHandler}
                updateSecPopupContent={this.updateSecPopupContent}
                countryId={countryId}
                accessRights={accessRights}
                currentActiveSectionPageId={activePage?._id || null}
                userRole={user.role}
                getRollbackHistory={this.getRollbackHistory}
                rollbackHistory={rollbackHistory}
                closeRollbackModal={this.closeRollbackModal}
                onConfirmRollback={this.onConfirmRollback}
              />
            </Paper>
          </div>
          <div className="col-sm-9" style={{ "paddingLeft": "5px", "paddingRight": "10px" }}>
            <Subheader showCountry={true} breadcrumArr={breadCrumbArr} />
            <RightPanel
              currentTab={currentTab}
              updatePageContent={activePage}
              updatePageIndex={updatePageIndex}
              changeTab={this.changeTab}
              updateContent={this.updateContent}
              accessRights={accessRights}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  storeId: state.store.storeId,
  countryId: state.store.countryId,
  storeList: state.store.storeList,
  selectedStoreName: state.store.selectedStoreName,
  selectedCountryName: state.store.selectedCountryName,
  sectionsData: state.sectionsReducer.initialPagesContent,
  sectionsPageR: state.sectionsReducer,
  activePage: state.sectionsReducer.activePage,
  initialPagesContent: state.sectionsReducer.initialPagesContent,
  rollbackHistory: state.sectionsReducer.rollbackHistory,
});

const mapDispatchToProps = dispatch => ({
  changeTab: bindActionCreators(changeTab, dispatch),
  toastMsg: bindActionCreators(toastMsg, dispatch),
  fetchSectionsPages: bindActionCreators(fetchSectionsPages, dispatch),
  setActiveSectionsPage: bindActionCreators(setActiveSectionsPage, dispatch),
  createModule: bindActionCreators(createModule, dispatch),
  deleteModule: bindActionCreators(deleteModule, dispatch),
  createSection: bindActionCreators(createSection, dispatch),
  deleteSection: bindActionCreators(deleteSection, dispatch),
  getSection: bindActionCreators(getSection, dispatch),
  updateSectionModule: bindActionCreators(updateSectionModule, dispatch),
  updateModule: bindActionCreators(updateModule, dispatch),
  getAllRollbackHistory: bindActionCreators(getAllRollbackHistory, dispatch),
  setPagesRollbackHistory: bindActionCreators(setPagesRollbackHistory, dispatch),
  setPageRollback: bindActionCreators(setPageRollback, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sections);
