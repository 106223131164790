import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ACCES_TYPES, SLUG_NAVIGATION_MAPPING } from "../../constants";
import BrandsForLessLogo from "../../images/brandsForLessLogo2.svg";
import { AppBar, Toolbar, Container, Box, Tooltip, IconButton, Menu, MenuItem, Typography, Avatar, Divider, Stack, Button } from "../../materialUi/components";
import { LogoutIcon } from "../../materialUi/icons"
import HeaderMenuItem from "./menuItems";
import ConfirmModal from "../modal/ConfirmModal";
import {
  fetchStoreList,
  changeSelectedStore,
  setSelectedStoreState
} from "../../actions/store.action";
import { fetchNavMenuManagementData } from "../../actions/navMenuManagement.action";
import {
  handleCurrentTabChange,
  clearMegaMenuContent
} from "../../actions/megaMenu.action";

class Header extends Component {
  state = { confirmModal: false, storeId: "", isMegaMenu: false, anchorElUser: null };

  componentDidMount() {
    this.props.fetchStoreList();
    this.props.fetchNavMenuManagementData();
  }

  toggleModal = (id = "", isMegaMenu = false) =>
    this.setState(prevState => ({
      confirmModal: !prevState.confirmModal,
      storeId: id,
      isMegaMenu: isMegaMenu
    }));

  confirmModal = () => {
    const {
      changeSelectedStore,
      history,
      countryId,
      clearMegaMenuContent,
      handleCurrentTabChange
    } = this.props;
    const { storeId, isMegaMenu } = this.state;
    if (history && countryId && !isMegaMenu) {
      history.replace(`/home-page?storeId=${storeId}&countryId=${countryId}`);
    } else if (history && countryId && isMegaMenu) {
      history.replace(`/mega-menu?storeId=${storeId}&countryId=${countryId}`);
      clearMegaMenuContent();
      handleCurrentTabChange("");
    }
    storeId && countryId && changeSelectedStore(storeId, countryId);
    this.setState({ confirmModal: false, storeId: "", isMegaMenu: false });
  };

  navigate = (route, slug) => {
    const { storeId, countryId, history, changeTab } = this.props;
    const queryParams =
      storeId && countryId ? `?storeId=${storeId}&countryId=${countryId}` : "";
    changeTab(SLUG_NAVIGATION_MAPPING[slug]);
    history.push(`${route}${queryParams}`);
  };

  createTabs = (list = []) => {
    const { user: { permissions = {} } = {}, currentTab } = this.props;
    let tabList = list.filter(({ slug }) => {
      const hasPermission =
        permissions[slug] &&
          permissions[slug]["accessRights"] &&
          permissions[slug]["accessRights"][ACCES_TYPES.READ]
          ? true
          : false;
      return hasPermission;
    });
    return tabList.map(tab => (
      <div style={{ "marginRight": "10px" }} key={tab._id}>
        <HeaderMenuItem
          menu={tab}
          key={tab._id}
          currentTab={currentTab}
          depthLevel={0}
          permissions={permissions}
          navigate={this.navigate}
          handleParentClose={() => { }}
        />
      </div>
    ));
  };

  handleOpenUserMenu = (event) => {
    this.setState({ anchorElUser: event.currentTarget });
  };

  handleCloseUserMenu = () => {
    this.setState({ anchorElUser: null });
  };

  render() {
    const { logOut, navMenuList, user, currentTab } = this.props;
    const { confirmModal, anchorElUser } = this.state;
    return (
      <Box>
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.toggleModal}
            confirm={this.confirmModal}
            message={
              "All the unsaved changes will be lost. Do you want to continue ?"
            }
          />
        )}
        <AppBar position="static"
          sx={{
            "backgroundColor": "white"
          }}
          elevation={1}>
          <Container style={{ "maxWidth": "100%" }}>
            <Toolbar disableGutters>
              <Box sx={{ "display": "flex", "flexWrap": "wrap", "width": "100%", "alignItems": "center" }}>
                <Box sx={{ "width": "24%" }}>
                  <img src={BrandsForLessLogo} style={{ "maxWidth": "150px" }} />
                </Box>
                <Box
                  sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, width: "65%", "alignItems": "center", justifyContent: "flex-start" }}
                >
                  {this.createTabs(navMenuList)}
                </Box>
                <Box sx={{ flexGrow: 0, width: "11%" }}>
                  <Tooltip title="Open settings" arrow placement="top">
                    <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0, float: "right" }}>
                      <Avatar alt={user.name} src="/static/images/avatar/2.jpg" sx={{ "maxWidth": "30px", "maxHeight": "30px" }} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    keepMounted
                    open={Boolean(anchorElUser)}
                    onClose={this.handleCloseUserMenu}
                  >
                    <MenuItem key={"user"} style={{ backgroundColor: "rgba(34, 51, 84, 0.02)" }}>
                      <Box>
                        <IconButton>
                          <Avatar alt={user.name} src="/static/images/avatar/2.jpg" sx={{ "maxWidth": "30px", "maxHeight": "30px" }} />
                        </IconButton>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box sx={{ "textAlign": "left", "paddingLeft": "9px" }}>
                        <Typography textAlign="center">{user.name}</Typography>
                        <Typography textAlign="center" color={"rgb(146, 151, 183)"}>{user.role}</Typography>
                      </Box>
                    </MenuItem>
                    <Divider />
                    <MenuItem key={"logout"} onClick={logOut} sx={{ "justifyContent": "center" }}>
                      <Button startIcon={<LogoutIcon />}>
                        Logout
                      </Button>
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  countryId: state.store.countryId,
  homepageData: state.homepage,
  megaMenu: state.megaMenu,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  navMenuList: state.navMenuManagement.navMenuList
});

const mapDispatchToProps = {
  changeSelectedStore,
  clearMegaMenuContent,
  fetchStoreList,
  fetchNavMenuManagementData,
  handleCurrentTabChange,
  setSelectedStoreState
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
