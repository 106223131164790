import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "../../materialUi/components"

export const PreviewVideoModal = ({ title, dialogContext, videoURL, open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {dialogContext}
        </DialogContentText>
        <video width="500" height="400" controls autoPlay>
          <source src={videoURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
