import React, { useCallback } from "react";
import { connect } from "react-redux";

import { LANGUAGE } from "../../constants";
import { updatePageData } from "../../actions/pagesPage.action";
import Accordion from "../../component/accordion";
import GeneralSeoContentForm from "./generalSeoContentForm";

const UpdateGeneralSeoContent = ({ seoContent, pageType, updatePageData }) => {
  const handleUpdate = useCallback(
    (name, value, language) => {
      updatePageData(["meta", language, name], value);
    },
    [updatePageData]
  );

  return (
    <Accordion
      accordionId={pageType}
      headingWrapperClassName="catalog-banner-heading"
      title="General SEO"
      required
    >
      <GeneralSeoContentForm
        seoContent={seoContent}
        handleUpdate={handleUpdate}
        language={LANGUAGE.ENGLISH}
      />
      <GeneralSeoContentForm
        seoContent={seoContent}
        handleUpdate={handleUpdate}
        language={LANGUAGE.ARABIC}
      />
    </Accordion>
  );
};

const mapDispatchToProps = {
  updatePageData
};

export default connect(null, mapDispatchToProps)(UpdateGeneralSeoContent);
