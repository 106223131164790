import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";

const InputRadioGroup = ({
  className,
  label,
  language,
  name,
  onChange,
  options = [],
  radioGroupClassName,
  radioGroupWrapperClassName,
  required,
  value,
  wrapperClassName
}) => (
  <div className={cn("form-group", wrapperClassName)}>
    {label && (
      <label>
        {required && <span className="required" />}
        {label}
      </label>
    )}
    <div
      className={cn(
        "radio-group-wrapper wrapper-box",
        radioGroupWrapperClassName
      )}
    >
      <div className={cn("radio-group d-flex", radioGroupClassName)}>
        {options.map(option => {
          const id = `${name}_${camelCase(option.label)}_${language}`;
          return (
            <span key={id}>
              <input
                id={id}
                type="radio"
                name={name}
                className={cn("custom-radio", className, {
                  "checked-radio": option.value === value
                })}
                onChange={e => onChange(option, e)}
              />
              <label htmlFor={id}>{startCase(option.label)}</label>
            </span>
          );
        })}
      </div>
    </div>
  </div>
);

InputRadioGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  language: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ).isRequired,
  radioGroupClassName: PropTypes.string,
  radioGroupWrapperClassName: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  wrapperClassName: PropTypes.string
};

InputRadioGroup.defaultProps = {
  className: undefined,
  label: null,
  language: "english",
  name: "default",
  radioGroupClassName: undefined,
  radioGroupWrapperClassName: undefined,
  required: false,
  wrapperClassName: undefined
};

export default InputRadioGroup;
