import React from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import { CustomSelect } from "../../component/customSelect";
import {
  CATALOG_FLASH_SALE_PAGE_CAROUSEL_TYPE_B_PLACEHOLDERS,
  LANGUAGE
} from "../../constants";

const FlashSaleProductCarouselForm = ({
  content,
  handleTextChange,
  handleUrlChange,
  language,
  options,
}) => {
  const placeholders = CATALOG_FLASH_SALE_PAGE_CAROUSEL_TYPE_B_PLACEHOLDERS[language];
  const editUrlDisabled = language === LANGUAGE.ARABIC;
  return (
    <div className={`${language}-wrapper`}>
      <h4>{upperFirst(language)} Version</h4>
      <div className={`${language}-content`}>
        <div className="form-group">
          <label>Title</label>
          <input
            className="form-control"
            name="title"
            onChange={e =>
              handleTextChange(e.target.name, e.target.value, language)
            }
            placeholder={placeholders.title}
            type="text"
            value={get(content, `${language}.title`, "")}
          />
        </div>
        <div className="form-group">
          <label>
            <span className="required" />
            Redirection Url (Flash Sale Page Url)
          </label>
          <input
            className="form-control"
            name="redirectionUrl"
            onChange={e => {
              if (!editUrlDisabled) {
                handleUrlChange(e.target.name, e.target.value);
              }
            }}
            placeholder={placeholders.redirectionUrl}
            type="text"
            value={get(content, `${language}.redirectionUrl`, "")}
            disabled={editUrlDisabled}
          />
        </div>
        <div className="form-group">
          <label>
            <span className="required" />
            Flash Sale Content
          </label>
          <CustomSelect
            options={options}
            onChange={(val) => {
              handleTextChange("flashSalePageSlug", val.value, language)
            }}
            value={
              get(content, `${language}.flashSalePageSlug`, "")
            }
            name="flashSalePageSlug"
            placeholder={placeholders.pageName}
            className="mt-1"
          />
        </div>
      </div>
    </div>
  );
};

export default FlashSaleProductCarouselForm;
