import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { isObject, generateUUID } from "../util";
import {
  mapTreeData,
  mapUpdatedSortableTreeNodeToContent
} from "../util/reducer";
import { LANGUAGE } from "../constants";
import {
  ADD_MEGA_MENU_DATA,
  UPDATE_MEGA_MENU_DATA,
  DELETE_MEGA_MENU_DATA,
  EDIT_MEGA_MENU_CONTENT,
  CLEAR_MEGA_MENU_CONTENT,
  MEGA_MENU_TEXT,
  HANDLE_ATTRIB_CHANGE,
  SAVE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  CLEAR_ATTRIBUTE_STATE,
  UPDATE_TREE_RECORDS,
  UPDATE_DND_TREE_DATA,
  UPDATE_CONTENT_ON_TREE_DATA_CHANGE,
  DELETE_DATA_DND_TREE,
  HANDLE_EDIT_ATTRIBUTE,
  CHANGE_IMAGE_COL_TYPE,
  HANDLE_IMAGE_TYPE,
  UPDATE_IMAGE_DATA,
  REMOVE_IMAGE,
  CLEAR_ALL_IMAGES,
  GET_MEGA_MENU_DATA,
  PUT_MEGA_MENU_DATA,
  PUT_MEGA_MENU_STATIC_DATA,
  HANDLE_MEGA_MENU_STATIC_TEXT,
  CLEAR_MEGA_MENU_STATIC_CONTENT,
  HANDLE_CURRENT_TAB_CHANGE,
  ADD_DATA_TO_CAROUSAL_LIST,
  DELETE_CAROUSAL_ITEM,
  MEGA_MENU_CAROUSAL_CHANGE,
  ADD_MEGA_MENU_SCHEDULERS,
  DELETE_MEGA_MENU_SCHEDULERS,
  HANDLE_SCHEDULER_IMAGE_TYPE,
  CLEAR_ALL_SCHEDULERS_IMAGES,
  HANDLE_SCHEDULER_IMAGE_DATA,
  UPDATE_SCHEDULER_IMAGE_DATA,
  REMOVE_SCHEDULER_IMAGE
} from "../constants/actions";

export const initialState = {
  english: [],
  arabic: [],
  updateEnglishContent: {
    headerTitle: "",
    imageUrl: "",
    pos: "",
    isSPS: false,
    isEnabledSPS: false,
    hoverImageUrl: "",
    redirectionLink: "",
    catCarousalList: [],
    isPublish: false,
    col1: null,
    col2: null,
    col3: null,
    col4: null,
    col5: null,
    col6: null
  },
  updateArabicContent: {
    headerTitle: "",
    imageUrl: "",
    pos: "",
    isSPS: false,
    isEnabledSPS: false,
    hoverImageUrl: "",
    redirectionLink: "",
    catCarousalList: [],
    isPublish: false,
    col1: null,
    col2: null,
    col3: null,
    col4: null,
    col5: null,
    col6: null
  },
  englishContent: {
    headerTitle: "",
    imageUrl: "",
    pos: "",
    isSPS: false,
    isEnabledSPS: false,
    hoverImageUrl: "",
    redirectionLink: "",
    catCarousalList: [],
    isPublish: false,
    col1: null,
    col2: null,
    col3: null,
    col4: null,
    col5: null,
    col6: null
  },
  arabicContent: {
    headerTitle: "",
    imageUrl: "",
    pos: "",
    isSPS: false,
    isEnabledSPS: false,
    hoverImageUrl: "",
    redirectionLink: "",
    catCarousalList: [],
    isPublish: false,
    col1: null,
    col2: null,
    col3: null,
    col4: null,
    col5: null,
    col6: null
  },
  englishAttributeData: {
    subTitle: "",
    redirectUrl: "",
    bold: true,
    isPublish: false,
    color: {
      id: 1,
      value: "#ff0000"
    },
    children: []
  },
  arabicAttributeData: {
    subTitle: "",
    redirectUrl: "",
    isPublish: false,
    bold: true,
    color: {
      id: 1,
      value: "#ff0000"
    },
    children: []
  },
  currentTab: "",
  imgClassList: [],
  isEdit: false,
  selectedImgCol: null,
  tabChanged: true,
  treeData: []
};

const getMegaMenuData = (state, { result }) => {
  const updatedEngTabData =
    result.english &&
    result.english
      .sort((a, b) => {
        return a.pos - b.pos;
      })
      .find(item => item.uid === state.currentTab);
  const updatedArbTabData =
    result.arabic &&
    result.arabic
      .sort((a, b) => {
        return a.pos - b.pos;
      })
      .find(item => item.uid === state.currentTab);
  return {
    ...state,
    english:
      (result.english &&
        result.english.sort((a, b) => {
          return a.pos - b.pos;
        })) ||
      [],
    arabic:
      (result.arabic &&
        result.arabic.sort((a, b) => {
          return a.pos - b.pos;
        })) ||
      [],
    enStaticContent: result.enStaticContent || {},
    arStaticContent: result.arStaticContent || {},
    updateEnStaticContent: result.enStaticContent || {},
    updateArStaticContent: result.arStaticContent || {},
    englishContent: updatedEngTabData || state.englishContent,
    arabicContent: updatedArbTabData || state.arabicContent,
    updateEnglishContent: updatedEngTabData || state.updateEnglishContent,
    updateArabicContent: updatedArbTabData || state.updateArabicContent
  };
};

const putMegaMenuStaticData = (state, { englishData, arabicData }) => ({
  ...state,
  updateEnStaticContent: englishData || {},
  updateArStaticContent: arabicData || {}
});

const handleMegaMenuTextChange = (state, { name, value, keyType }) => ({
  ...state,
  [keyType]: { ...state[keyType], [name]: value }
});

const clearMegaMenuStaticContent = state => {
  return {
    ...state,
    updateEnStaticContent: state.enStaticContent || {},
    updateArStaticContent: state.arStaticContent || {}
  };
};

const updateMegaMenuData = (state, { englishData, arabicData }) => {
  const _engIndex = state.english.findIndex(
    item => item.uid === englishData.uid
  );
  const _arbIndex = state.arabic.findIndex(item => item.uid === arabicData.uid);
  if (_engIndex === -1 && _arbIndex === -1) {
    return {
      ...state,
      english: [...state.english, englishData],
      arabic: [...state.arabic, arabicData],
      isEdit: true
    };
  } else {
    state.english[_engIndex] = englishData;
    state.arabic[_arbIndex] = arabicData;
    return {
      ...state,
      english: cloneDeep(state.english),
      arabic: cloneDeep(state.arabic),
      isEdit: true
    };
  }
};

const updateTreeRecords = (state, { colName }) => {
  let _treeData = [];
  if (
    state.updateEnglishContent[colName] &&
    state.updateEnglishContent[colName].length &&
    state.updateArabicContent[colName] &&
    state.updateArabicContent[colName].length
  ) {
    for (let i = 0; i < state.updateEnglishContent[colName].length; i++) {
      //check if children exists
      let children = [];
      for (
        let j = 0;
        j < state.updateEnglishContent[colName][i].children.length;
        j++
      ) {
        children.push({
          children: [],
          expanded: true,
          title: `${state.updateEnglishContent[colName][i].children[j].subTitle}/ ${state.updateArabicContent[colName][i].children[j].subTitle}`,
          englishData: state.updateEnglishContent[colName][i].children[j],
          arabicData: state.updateArabicContent[colName][i].children[j]
        });
      }

      _treeData.push({
        children: children,
        expanded: true,
        title: `${state.updateEnglishContent[colName][i].subTitle}/ ${state.updateArabicContent[colName][i].subTitle}`,
        englishData: state.updateEnglishContent[colName][i],
        arabicData: state.updateArabicContent[colName][i]
      });
    }
  }

  return {
    ...state,
    treeData: _treeData
  };
};

const saveAttribute = (state, { colName }) => {
  const uid = generateUUID();
  return {
    ...state,
    englishAttributeData: { ...initialState.englishAttributeData },
    arabicAttributeData: { ...initialState.arabicAttributeData },
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [colName]: Array.isArray(state.updateEnglishContent[colName])
        ? [
            ...state.updateEnglishContent[colName],
            { ...state.englishAttributeData, uid }
          ]
        : [state.englishAttributeData, uid]
    },
    updateArabicContent: {
      ...state.updateArabicContent,
      [colName]: Array.isArray(state.updateArabicContent[colName])
        ? [
            ...state.updateArabicContent[colName],
            { ...state.arabicAttributeData, uid }
          ]
        : [state.arabicAttributeData, uid]
    },
    treeData: [
      ...state.treeData,
      {
        title: `${state.englishAttributeData.subTitle}/ ${state.arabicAttributeData.subTitle}`,
        expanded: true,
        englishData: { ...state.englishAttributeData, uid },
        arabicData: { ...state.arabicAttributeData, uid },
        children: []
      }
    ]
  };
};

const updateAttribute = (state, { colName }) => {
  const {
    arabicAttributeData,
    englishAttributeData,
    updateArabicContent,
    updateEnglishContent
  } = state;

  const mappedEnglishColumn = mapUpdatedSortableTreeNodeToContent(
    englishAttributeData,
    updateEnglishContent[colName]
  );
  const mappedArabicColumn = mapUpdatedSortableTreeNodeToContent(
    arabicAttributeData,
    updateArabicContent[colName]
  );
  const mappedTreeData = mapTreeData(mappedEnglishColumn, mappedArabicColumn);

  return {
    ...state,
    arabicAttributeData: initialState.arabicAttributeData,
    englishAttributeData: initialState.englishAttributeData,
    updateArabicContent: {
      ...state.updateArabicContent,
      [colName]: mappedArabicColumn
    },
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [colName]: mappedEnglishColumn
    },
    treeData: mappedTreeData
  };
};

const clearAttributeData = state => ({
  ...state,
  englishAttributeData: { ...initialState.englishAttributeData },
  arabicAttributeData: { ...initialState.arabicAttributeData }
});

const addMegaMenuList = (state, { megaMenuList }) => ({
  ...state,
  megaMenuList
});

const updateMegaMenuList = (state, { megaMenuList }) => {
  return { ...state, megaMenuList };
};

const deleteMegaMenuList = (state, { megaMenuList }) => ({
  ...state,
  megaMenuList
});

const editMegaMenuContent = (
  state,
  { updateEnglishContent, updateArabicContent }
) => {
  let selectedImgCol = null;
  for (let i = 1; i <= 6; i++) {
    if (isObject(updateEnglishContent[`col${i}`])) {
      selectedImgCol = i;
      break;
    }
  }
  return {
    ...state,
    updateEnglishContent,
    updateArabicContent,
    englishContent: updateEnglishContent,
    arabicContent: updateArabicContent,
    tabChanged: true,
    selectedImgCol: selectedImgCol,
    isEdit: true
  };
};

const megaMenuText = (state, { name, value, obj }) => ({
  ...state,
  [obj]: { ...state[obj], [name]: value }
});

const clearMegaMenuContent = state => ({
  ...state,
  updateEnglishContent: initialState.updateEnglishContent,
  updateArabicContent: initialState.updateArabicContent,
  englishContent: initialState.englishContent,
  arabicContent: initialState.arabicContent,
  selectedImgCol: initialState.selectedImgCol,
  imgClassList: initialState.imgClassList,
  isEdit: initialState.isEdit
});

const handleAttribContentChange = (state, { contentType, name, value }) => {
  return {
    ...state,
    [contentType]: { ...state[contentType], [name]: value }
  };
};

const updateTreeDndValue = (state, { treeData }) => {
  return { ...state, treeData };
};

const deleteDataDNDTree = (state, { selectedAttribData }) => {
  let ithPosition = 0;
  let jthPosition = 0;
  let isChildNode = false;
  let currentPos = 0;
  let _treeData = cloneDeep(state.treeData);

  for (let i = 0; i < _treeData.length; i++) {
    ithPosition = i;
    if (currentPos === selectedAttribData.treeIndex) {
      break;
    }
    for (let j = 0; j < _treeData[i].children.length; j++) {
      jthPosition = j;
      currentPos += 1;
      if (currentPos === selectedAttribData.treeIndex) {
        isChildNode = true;
        break;
      }
    }

    if (currentPos === selectedAttribData.treeIndex) {
      break;
    }
    currentPos += 1;
  }

  if (isChildNode) {
    _treeData[ithPosition].children.splice(jthPosition, 1);
    _treeData[ithPosition].children = [..._treeData[ithPosition].children];
  } else {
    _treeData.splice(ithPosition, 1);
    _treeData = [..._treeData];
  }
  return { ...state, treeData: [..._treeData] };
};

const updateContentOnTreeChange = (state, { treeData, columnName }) => {
  let _updateEnglishContent = [];
  let _updateArabicContent = [];
  if (treeData && treeData.length) {
    for (let i = 0; i < treeData.length; i++) {
      //check if children exists
      let childrenEnglish = [];
      let childrenArabic = [];
      for (let j = 0; j < treeData[i].children.length; j++) {
        childrenEnglish.push({
          ...treeData[i].children[j].englishData
        });
        childrenArabic.push({
          ...treeData[i].children[j].arabicData
        });
      }
      _updateArabicContent.push({
        ...treeData[i].arabicData,
        children: childrenArabic
      });

      _updateEnglishContent.push({
        ...treeData[i].englishData,
        children: childrenEnglish
      });
    }
  }

  return {
    ...state,
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [columnName]: _updateEnglishContent
    },
    updateArabicContent: {
      ...state.updateArabicContent,
      [columnName]: _updateArabicContent
    },
    treeData: cloneDeep(treeData)
  };
};

const handleEditAttribute = (state, { englishData, arabicData }) => ({
  ...state,
  englishAttributeData: { ...englishData },
  arabicAttributeData: { ...arabicData }
});

const changeImageColType = (state, { value, columnName }) => {
  const arr = [4, 5, 6];
  arr.forEach(item => {
    if (item !== value && isObject(state.updateEnglishContent[`col${item}`])) {
      state.updateEnglishContent[`col${item}`] = null;
      state.updateArabicContent[`col${item}`] = null;
    }
  });

  return {
    ...state,
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [columnName]: null
    },
    updateArabicContent: {
      ...state.updateArabicContent,
      [columnName]: null
    },
    selectedImgCol: value,
    imgClassList: value === null ? [] : state.imgClassList
  };
};

const handleImageType = (state, { imgType, columnName, imgClassList }) => {
  let index = state.english.findIndex(
    item => item.uid === state.updateEnglishContent.uid
  );
  let englishImages = [];
  let arabicImages = [];
  if (
    state.updateEnglishContent[columnName] &&
    state.updateEnglishContent[columnName].imgType === imgType
  ) {
    englishImages =
      (state.updateEnglishContent[columnName] &&
        state.updateEnglishContent[columnName].images) ||
      [];
    arabicImages =
      (state.updateArabicContent[columnName] &&
        state.updateArabicContent[columnName].images) ||
      [];
  } else if (
    index >= 0 &&
    state.english[index][columnName] &&
    state.english[index][columnName].imgType === imgType
  ) {
    englishImages =
      (state.english[index][columnName] &&
        state.english[index][columnName].images) ||
      [];
    arabicImages =
      (state.arabic[index][columnName] &&
        state.arabic[index][columnName].images) ||
      [];
  }

  return {
    ...state,
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [columnName]: {
        ...state.updateEnglishContent[columnName],
        imgType,
        type: columnName,
        images: englishImages || []
      }
    },
    updateArabicContent: {
      ...state.updateArabicContent,
      [columnName]: {
        ...state.updateArabicContent[columnName],
        imgType,
        type: columnName,
        images: arabicImages || []
      }
    },
    imgClassList,
    tabChanged: false
  };
};

const clearAllImages = (state, { columnName }) => ({
  ...state,
  selectedImgCol: initialState.selectedImgCol,
  imgClassList: initialState.imgClassList,
  updateEnglishContent: {
    ...state.updateEnglishContent,
    [columnName]: {
      ...state.updateEnglishContent[columnName],
      imgType: "",
      type: "",
      images: []
    }
  },
  updateArabicContent: {
    ...state.updateArabicContent,
    [columnName]: {
      ...state.updateArabicContent[columnName],
      imgType: "",
      type: "",
      images: []
    }
  }
});

const removeImage = (state, { columnName, pos, imgLang }) => {
  let englishImages = [...state.updateEnglishContent[columnName].images];
  let arabicImages = [...state.updateArabicContent[columnName].images];

  if (imgLang === "english") {
    let posIndex = englishImages.findIndex(item => item.pos === pos);
    if (posIndex > -1) {
      englishImages.splice(posIndex, 1);
    }
  } else {
    let posIndex = arabicImages.findIndex(item => item.pos === pos);
    if (posIndex > -1) {
      arabicImages.splice(posIndex, 1);
    }
  }

  return {
    ...state,
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [columnName]: {
        ...state.updateEnglishContent[columnName],
        images: englishImages
      }
    },
    updateArabicContent: {
      ...state.updateArabicContent,
      [columnName]: {
        ...state.updateArabicContent[columnName],
        images: arabicImages
      }
    }
  };
};

const updateImageData = (state, { columnName, imgData, imgLang }) => {
  let englishImages = [...state.updateEnglishContent[columnName].images];
  let arabicImages = [...state.updateArabicContent[columnName].images];

  if (imgLang === "english") {
    let posIndex = englishImages.findIndex(item => item.pos === imgData.pos);
    if (posIndex > -1) {
      englishImages[posIndex] = imgData;
    } else {
      englishImages.push(imgData);
    }
  } else {
    let posIndex = arabicImages.findIndex(item => item.pos === imgData.pos);
    if (posIndex > -1) {
      arabicImages[posIndex] = imgData;
    } else {
      arabicImages.push(imgData);
    }
  }

  return {
    ...state,
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [columnName]: {
        ...state.updateEnglishContent[columnName],
        images: englishImages
      }
    },
    updateArabicContent: {
      ...state.updateArabicContent,
      [columnName]: {
        ...state.updateArabicContent[columnName],
        images: arabicImages
      }
    }
  };
};

const handleCurrentTabChange = (state, { value }) => ({
  ...state,
  currentTab: value
});

const addDataToCarousalList = state => ({
  ...state,
  updateEnglishContent: {
    ...state.updateEnglishContent,
    catCarousalList: state.updateEnglishContent.catCarousalList
      ? [
          ...state.updateEnglishContent.catCarousalList,
          { imgUrl: "", redirectUrl: "" }
        ]
      : [{ imageUrl: "", redirectUrl: "" }]
  },
  updateArabicContent: {
    ...state.updateArabicContent,
    catCarousalList: state.updateArabicContent.catCarousalList
      ? [
          ...state.updateArabicContent.catCarousalList,
          { imgUrl: "", redirectUrl: "" }
        ]
      : [{ imageUrl: "", redirectUrl: "" }]
  }
});

const deleteCarousalItem = (state, { keyIndex }) => ({
  ...state,
  updateEnglishContent: {
    ...state.updateEnglishContent,
    catCarousalList: state.updateEnglishContent.catCarousalList.filter(
      (item, idx) => idx !== keyIndex
    )
  },
  updateArabicContent: {
    ...state.updateArabicContent,
    catCarousalList: state.updateArabicContent.catCarousalList.filter(
      (item, idx) => idx !== keyIndex
    )
  }
});

const megaMenuCarousalChange = (state, { name, value, keyIndex, obj }) => {
  const updatedData = cloneDeep(state[obj]);
  updatedData["catCarousalList"][keyIndex][name] = value;
  return { ...state, [obj]: updatedData };
};

const addNewMegaMenuScheduler = (state, { columnName }) => ({
  ...state,
  updateEnglishContent: {
    ...state.updateEnglishContent,
    [columnName]: {
      ...state.updateEnglishContent[columnName],
      schedulers: [
        ...get(state, `updateEnglishContent[${columnName}].schedulers`, []),
        { images: [], from: "", to: "" }
      ]
    }
  },
  updateArabicContent: {
    ...state.updateArabicContent,
    [columnName]: {
      ...state.updateArabicContent[columnName],
      schedulers: [
        ...get(state, `updateArabicContent[${columnName}].schedulers`, []),
        { images: [], from: "", to: "" }
      ]
    }
  }
});

const deleteMegaMenuScheduler = (state, { columnName, schedulerIndex }) => ({
  ...state,
  updateEnglishContent: {
    ...state.updateEnglishContent,
    [columnName]: {
      ...state.updateEnglishContent[columnName],
      schedulers: get(
        state,
        `updateEnglishContent[${columnName}].schedulers`,
        []
      ).filter((_, index) => index !== schedulerIndex)
    }
  },
  updateArabicContent: {
    ...state.updateArabicContent,
    [columnName]: {
      ...state.updateArabicContent[columnName],
      schedulers: get(
        state,
        `updateEnglishContent[${columnName}].schedulers`,
        []
      ).filter((_, index) => index !== schedulerIndex)
    }
  }
});

const handleSchedulerImageType = (
  state,
  { columnName, imgClassList, imgType, schedulerIndex }
) => {
  let index = state.english.findIndex(
    item => item.uid === state.updateEnglishContent.uid
  );
  let englishImages = [];
  let arabicImages = [];
  if (
    get(
      state,
      `updateEnglishContent[${columnName}].schedulers[${schedulerIndex}].imgType`
    ) === imgType
  ) {
    englishImages = get(
      state,
      `updateEnglishContent[${columnName}].schedulers[${schedulerIndex}].images`,
      []
    );
    arabicImages = get(
      state,
      `updateArabicContent[${columnName}].schedulers[${schedulerIndex}].images`,
      []
    );
  } else if (
    index >= 0 &&
    get(
      state,
      `english[${index}][${columnName}].schedulers[${schedulerIndex}].imgType`
    ) === imgType
  ) {
    englishImages = get(
      state,
      `english[${index}][${columnName}].schedulers[${schedulerIndex}].images`,
      []
    );
    arabicImages = get(
      state,
      `arabic[${index}][${columnName}].schedulers[${schedulerIndex}].images`,
      []
    );
  }

  return {
    ...state,
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [columnName]: {
        ...state.updateEnglishContent[columnName],
        schedulers: get(
          state,
          `updateEnglishContent[${columnName}].schedulers`,
          []
        ).map((scheduler, index) =>
          index === schedulerIndex
            ? {
                ...scheduler,
                imgType,
                type: columnName,
                images: englishImages || [],
                imgClassList
              }
            : scheduler
        )
      }
    },
    updateArabicContent: {
      ...state.updateArabicContent,
      [columnName]: {
        ...state.updateArabicContent[columnName],
        schedulers: get(
          state,
          `updateArabicContent[${columnName}].schedulers`,
          []
        ).map((scheduler, index) =>
          index === schedulerIndex
            ? {
                ...scheduler,
                imgType,
                type: columnName,
                images: arabicImages || [],
                imgClassList
              }
            : scheduler
        )
      }
    },
    tabChanged: false
  };
};

export const clearAllSchedulerImages = (
  state,
  { columnName, schedulerIndex }
) => ({
  ...state,
  selectedImgCol: initialState.selectedImgCol,
  updateEnglishContent: {
    ...state.updateEnglishContent,
    [columnName]: {
      ...get(state, `updateEnglishContent[${columnName}]`),
      schedulers: get(
        state,
        `updateEnglishContent[${columnName}].schedulers`
      ).map((scheduler, index) =>
        index === schedulerIndex
          ? {
              ...scheduler,
              imgType: "",
              type: "",
              images: [],
              imgClassList: initialState.imgClassList
            }
          : scheduler
      )
    }
  },
  updateArabicContent: {
    ...state.updateArabicContent,
    [columnName]: {
      ...get(state, `updateArabicContent[${columnName}]`),
      schedulers: get(
        state,
        `updateArabicContent[${columnName}].schedulers`
      ).map((scheduler, index) =>
        index === schedulerIndex
          ? {
              ...scheduler,
              imgType: "",
              type: "",
              images: [],
              imgClassList: initialState.imgClassList
            }
          : scheduler
      )
    }
  }
});

const handleSchedulerImageData = (
  state,
  { columnName, schedulerIndex, dataKey, data }
) => ({
  ...state,
  updateEnglishContent: {
    ...state.updateEnglishContent,
    [columnName]: {
      ...get(state, `updateEnglishContent[${columnName}]`),
      schedulers: get(
        state,
        `updateEnglishContent[${columnName}].schedulers`
      ).map((scheduler, index) =>
        index === schedulerIndex
          ? {
              ...scheduler,
              [dataKey]: data
            }
          : scheduler
      )
    }
  },
  updateArabicContent: {
    ...state.updateArabicContent,
    [columnName]: {
      ...get(state, `updateArabicContent[${columnName}]`),
      schedulers: get(
        state,
        `updateArabicContent[${columnName}].schedulers`
      ).map((scheduler, index) =>
        index === schedulerIndex
          ? {
              ...scheduler,
              [dataKey]: data
            }
          : scheduler
      )
    }
  }
});

const updateImageSchedulerImage = (
  state,
  { columnName, imgData, imgLang, schedulerIndex }
) => {
  let englishImages = [
    ...get(
      state,
      `updateEnglishContent[${columnName}.schedulers[${schedulerIndex}].images`,
      []
    )
  ];
  let arabicImages = [
    ...get(
      state,
      `updateArabicContent[${columnName}.schedulers[${schedulerIndex}].images`,
      []
    )
  ];

  if (imgLang === LANGUAGE.ENGLISH) {
    let posIndex = englishImages.findIndex(item => item.pos === imgData.pos);
    if (posIndex > -1) {
      englishImages[posIndex] = imgData;
    } else {
      englishImages.push(imgData);
    }
  } else {
    let posIndex = arabicImages.findIndex(item => item.pos === imgData.pos);
    if (posIndex > -1) {
      arabicImages[posIndex] = imgData;
    } else {
      arabicImages.push(imgData);
    }
  }

  return {
    ...state,
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [columnName]: {
        ...state.updateEnglishContent[columnName],
        schedulers: get(
          state,
          `updateEnglishContent[${columnName}].schedulers`
        ).map((scheduler, index) =>
          index === schedulerIndex
            ? {
                ...scheduler,
                images: englishImages
              }
            : scheduler
        )
      }
    },
    updateArabicContent: {
      ...state.updateArabicContent,
      [columnName]: {
        ...state.updateArabicContent[columnName],
        schedulers: get(
          state,
          `updateArabicContent[${columnName}].schedulers`
        ).map((scheduler, index) =>
          index === schedulerIndex
            ? {
                ...scheduler,
                images: arabicImages
              }
            : scheduler
        )
      }
    }
  };
};

const removeSchedulerImage = (
  state,
  { columnName, pos, imgLang, schedulerIndex }
) => {
  let englishImages = [
    ...get(
      state,
      `updateEnglishContent[${columnName}].schedulers[${schedulerIndex}].images`,
      []
    )
  ];
  let arabicImages = [
    ...get(
      state,
      `updateArabicContent[${columnName}].schedulers[${schedulerIndex}].images`,
      []
    )
  ];

  if (imgLang === "english") {
    let posIndex = englishImages.findIndex(item => item.pos === pos);
    if (posIndex > -1) {
      englishImages.splice(posIndex, 1);
    }
  } else {
    let posIndex = arabicImages.findIndex(item => item.pos === pos);
    if (posIndex > -1) {
      arabicImages.splice(posIndex, 1);
    }
  }

  return {
    ...state,
    updateEnglishContent: {
      ...state.updateEnglishContent,
      [columnName]: {
        ...state.updateEnglishContent[columnName],
        schedulers: get(
          state,
          `updateEnglishContent[${columnName}].schedulers`
        ).map((scheduler, index) =>
          index === schedulerIndex
            ? {
                ...scheduler,
                images: englishImages
              }
            : scheduler
        )
      }
    },
    updateArabicContent: {
      ...state.updateArabicContent,
      [columnName]: {
        ...state.updateArabicContent[columnName],
        schedulers: get(
          state,
          `updateArabicContent[${columnName}].schedulers`
        ).map((scheduler, index) =>
          index === schedulerIndex
            ? {
                ...scheduler,
                images: arabicImages
              }
            : scheduler
        )
      }
    }
  };
};

const megaMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEGA_MENU_DATA:
      return addMegaMenuList(state, action);
    case UPDATE_MEGA_MENU_DATA:
      return updateMegaMenuList(state, action);
    case DELETE_MEGA_MENU_DATA:
      return deleteMegaMenuList(state, action);
    case EDIT_MEGA_MENU_CONTENT:
      return editMegaMenuContent(state, action);
    case CLEAR_MEGA_MENU_CONTENT:
      return clearMegaMenuContent(state);
    case MEGA_MENU_TEXT:
      return megaMenuText(state, action);
    case HANDLE_ATTRIB_CHANGE:
      return handleAttribContentChange(state, action);
    case SAVE_ATTRIBUTE:
      return saveAttribute(state, action);
    case UPDATE_ATTRIBUTE:
      return updateAttribute(state, action);
    case CLEAR_ATTRIBUTE_STATE:
      return clearAttributeData(state);
    case UPDATE_TREE_RECORDS:
      return updateTreeRecords(state, action);
    case UPDATE_DND_TREE_DATA:
      return updateTreeDndValue(state, action);
    case UPDATE_CONTENT_ON_TREE_DATA_CHANGE:
      return updateContentOnTreeChange(state, action);
    case DELETE_DATA_DND_TREE:
      return deleteDataDNDTree(state, action);
    case HANDLE_EDIT_ATTRIBUTE:
      return handleEditAttribute(state, action);
    case CHANGE_IMAGE_COL_TYPE:
      return changeImageColType(state, action);
    case HANDLE_IMAGE_TYPE:
      return handleImageType(state, action);
    case UPDATE_IMAGE_DATA:
      return updateImageData(state, action);
    case REMOVE_IMAGE:
      return removeImage(state, action);
    case CLEAR_ALL_IMAGES:
      return clearAllImages(state, action);
    case GET_MEGA_MENU_DATA:
      return getMegaMenuData(state, action);
    case PUT_MEGA_MENU_DATA:
      return updateMegaMenuData(state, action);
    case PUT_MEGA_MENU_STATIC_DATA:
      return putMegaMenuStaticData(state, action);
    case HANDLE_MEGA_MENU_STATIC_TEXT:
      return handleMegaMenuTextChange(state, action);
    case CLEAR_MEGA_MENU_STATIC_CONTENT:
      return clearMegaMenuStaticContent(state);
    case HANDLE_CURRENT_TAB_CHANGE:
      return handleCurrentTabChange(state, action);
    case ADD_DATA_TO_CAROUSAL_LIST:
      return addDataToCarousalList(state, action);
    case DELETE_CAROUSAL_ITEM:
      return deleteCarousalItem(state, action);
    case MEGA_MENU_CAROUSAL_CHANGE:
      return megaMenuCarousalChange(state, action);
    case ADD_MEGA_MENU_SCHEDULERS:
      return addNewMegaMenuScheduler(state, action);
    case DELETE_MEGA_MENU_SCHEDULERS:
      return deleteMegaMenuScheduler(state, action);
    case HANDLE_SCHEDULER_IMAGE_TYPE:
      return handleSchedulerImageType(state, action);
    case CLEAR_ALL_SCHEDULERS_IMAGES:
      return clearAllSchedulerImages(state, action);
    case HANDLE_SCHEDULER_IMAGE_DATA:
      return handleSchedulerImageData(state, action);
    case UPDATE_SCHEDULER_IMAGE_DATA:
      return updateImageSchedulerImage(state, action);
    case REMOVE_SCHEDULER_IMAGE:
      return removeSchedulerImage(state, action);
    default:
      return state;
  }
};

export default megaMenuReducer;
