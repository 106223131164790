export const ADD_MEGA_MENU_ITEM_MESSAGES = {
  SAVED_DATA_LOSS: "All the saved data will be lost. Do you want to continue ?",
  UNSAVED_CHANGES:
    "There are some unsaved changes, Kindly save it otherwise all the unsaved changes will be lost. Do you want to continue ?",
  VIEW_CHANGES: "Do you want to see the changes?",
  POSITION_ALREADY_ASSIGNED: "Selected position is already assigned"
};

export const MEGA_MENU_ITEM_MESSAGES = {
  DELETE_RECORD_WITH_NODES:
    "Deleting will delete the child nodes also. Do you want to continue?",
  DELETE_RECORD: "Delete this record. Do you want to continue?"
};

export const MEGA_MENU_MESSAGES = {
  UNSAVED_CHANGES:
    "All the unsaved changes will be lost. Do you want to continue ?"
};

export const CAROUSEL_LIST_MESSAGES = {
  DELETE_ITEM:
    "Are you sure you want to delete this item ? Please Save & Preview after deleting."
};

export const COLUMN_IMAGE_CONTENT = {
  SAVED_DATA_LOSS:
    "All the saved data and image selection will be lost. Do you want to continue ?"
};

export const OPTIONAL_SELECT_ATTRIBUTE_MESSAGES = {
  SAVED_DATA_LOSS: "All the saved data will be lost. Do you want to continue ?"
};
