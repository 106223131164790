import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import reactImageSize from "react-image-size";
import { cloneDeep } from "lodash";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '../../../materialUi/components';
import { imageWidthOptions, imageErrors, ERROR_MESSAGES, svgImageFormatArr, SVG_IMAGE_SIZE_AND_TYPE_TOOLTIP } from "../../../constants"
import { UploadImageModal } from "../../../component/dynamicBanner/ItemModal/UploadImageModal";
import ImageSelectionModal from "../../../containers/modal/imageSelectionModalMUI";
import { snackbar } from "../../../actions/common.action";

const SvgImageDialog = ({ open, handleClose, handleSave, data, title, dialogContext, language, deviceTab }) => {
  const [modalData, setModalData] = useState(cloneDeep(data || {}));
  const [imageSelectionModal, setImageSelectionModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setModalData(data);
  }, [data]);

  const handleDeleteImage = () => {
    setModalData({ ...modalData, "imageURL": "" });
  }

  const handleSaveImageSelectionModal = (
    keyName,
    cdnURL,
    brandId,
    imageLanguage,
    fileName) => {
    reactImageSize(cdnURL)
      .then(({ width, height }) => {
        if (width > imageWidthOptions[deviceTab].max) {
          throw new Error(imageErrors[deviceTab]);
        }
        const ratio = (height / width) * 100;
        setModalData({ ...modalData, "imageURL": cdnURL, ratio, width });
        setImageSelectionModal(false);
      })
      .catch(errorMessage =>
        dispatch(snackbar({ open: true, severity: "error", text: errorMessage.message })));
  }

  const validateForm = () => {

    const { imageURL } = modalData;

    if (!imageURL) {
      dispatch(snackbar({ open: true, severity: "error", text: ERROR_MESSAGES.imageRequired }));
      return false;
    }
    return true;
  }

  return (
    <>
      {imageSelectionModal &&
        <ImageSelectionModal
          title="Upload Image"
          modalName={"imageSelectionModal"}
          keyName="modal"
          language={language}
          containerName={"dynamicBanner"}
          onHide={() => setImageSelectionModal(false)}
          onSuccess={handleSaveImageSelectionModal}
          isOpen={imageSelectionModal}
          handleClose={() => setImageSelectionModal(false)}
          imageFormatArr={svgImageFormatArr}
          disableCDNuploadOption={true}
        />
      }
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {dialogContext}
          </DialogContentText>
          <UploadImageModal
            deleteImage={handleDeleteImage}
            handeImageSelectionModal={() => setImageSelectionModal(true)}
            imageURL={modalData.imageURL || ""}
            language={language}
            infoIconTitle={SVG_IMAGE_SIZE_AND_TYPE_TOOLTIP}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => validateForm() && handleSave(modalData)}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SvgImageDialog