import React, { useState } from "react";

const CKEditorNotes = ({ noMargin, display = [] }) => {
  const [showNotes, setShowNotes] = useState(false);

  const toggleContent = () => setShowNotes(sn => !sn);

  return (
    <div className={noMargin ? "ckeditor-notes mb-0" : "ckeditor-notes"}>
      <div className="heading-wrapper flex">
        <h3 className="heading">
          <span className="required"></span> Helping HTML Classes
        </h3>
        <span
          className={`toggle-banner ${
            showNotes ? "minus-banner" : "plus-banner"
          }`}
          onClick={toggleContent}
        ></span>
      </div>
      {showNotes && (
        <div>
          {display.includes(1) && (
            <div>
              <h4 className="sub-heading">
                <span className="required"></span>
                <span className="required"></span>Generic:
              </h4>
              <ul>
                <li>
                  <code>content_wrapper</code> Use this class to wrap the
                  general content for proper alignment.
                </li>
                <li>
                  <code>banner_container</code> Use this class to wrap the
                  banner image and banner text.
                </li>
                <li>
                  <code>
                    banner_container &gt; cms-static-container &gt; banner_text
                  </code>
                  Use this class for banner text.
                </li>
                <li>
                  <code>iframe_wrapper</code> Use this class to wrap iframe and
                  comment.
                </li>
                <li>
                  <code>
                    iframe_wrapper &gt; comment_outer_container &gt;
                    comment_container &gt; comment_text
                  </code>
                  Use this class to wrap iframe and comment.
                </li>
                <li>
                  <code>striked_heading</code> Use this class to make a striked
                  heading like on timeline or brands list.
                </li>
                <li>
                  <code>static_table</code> Use this class to make table.
                </li>
              </ul>
            </div>
          )}
          {display.includes(2) && (
            <div>
              <h4 className="sub-heading">
                <span className="required"></span>
                <span className="required"></span>Table:
              </h4>
              <ul>
                <li>
                  <code>static_table</code> Use this class to make table.
                </li>
                <li>
                  <code>table_heading_highlight</code> Use this class to create
                  highlighted heading.
                </li>
                <li>
                  <code>table-striped</code> Use this class to create striped
                  table rows.
                </li>
              </ul>
            </div>
          )}
          {display.includes(3) && (
            <div>
              <h4 className="sub-heading">
                <span className="required"></span>
                <span className="required"></span>Timeline:
              </h4>
              <ul>
                <li>
                  <code>content_wrapper timeline_container</code> Use these
                  classes to wrap the timeline container.
                </li>
                <li>
                  <code>
                    content_wrapper timeline_container &gt; timeline_content
                    &gt; left_border
                  </code>
                  Use this class to create the left border for timeline.
                </li>
                <li>
                  <code>
                    content_wrapper timeline_container &gt; timeline_content
                    &gt; card_container &gt; card_info
                  </code>
                  Use this class to wrap the card info on timeline.
                </li>
                <li>
                  <code>
                    content_wrapper timeline_container &gt; timeline_content
                    &gt; card_container &gt; card_info &gt;
                    concentric_circle_icon
                  </code>
                  Use this class to make the concentric circles on left border
                  of timeline.
                </li>
                <li>
                  <code>
                    content_wrapper timeline_container &gt; timeline_content
                    &gt; card_container &gt; card_info &gt; year
                  </code>
                  Use this class for year heading on timeline.
                </li>
                <li>
                  <code>
                    content_wrapper timeline_container &gt; timeline_content
                    &gt; card_container &gt; card_info &gt; basic_heading
                  </code>
                  Use this class for basic heading on timeline.
                </li>
              </ul>
            </div>
          )}
          {display.includes(4) && (
            <div>
              <h4 className="sub-heading">
                <span className="required"></span>
                <span className="required"></span>Brands List:
              </h4>
              <ul>
                <li>
                  <code>content_wrapper brands_container</code> Use these
                  classes to wrap the brands list container.
                </li>

                <li>
                  <code>
                    content_wrapper brands_container &gt; brands_content &gt;
                    tile
                  </code>
                  Use this class to wrap the individual tile content.
                </li>
                <li>
                  <code>
                    content_wrapper brands_container &gt; timeline_content &gt;
                    tile &gt; brand_logo_container
                  </code>
                  Use this class for brand logo.
                </li>
                <li>
                  <code>
                    content_wrapper brands_container &gt; timeline_content &gt;
                    tile &gt; info_container
                  </code>
                  Use this class for wrapping the brand info.
                </li>
                <li>
                  <code>
                    content_wrapper brands_container &gt; timeline_content
                    &gt;tile &gt; info_container &gt; basic_heading
                  </code>
                  Use this class for brand heading.
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CKEditorNotes;
