import qs from "query-string";

import {
  fetchWebApi,
  deleteWebApi,
  putDashboardWebApi,
  postDashboardWebApi
} from "../webapis/apiResource";
import { ENV_CONFIG } from "../config/env";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";
import {
  showLoadingIndicator,
  hideLoadingIndicator,
  toastMsg
} from "../actions/common.action";
import {
  LIST_ROLES,
  UPDATE_ROLE,
  DELETE_ROLE,
  ADD_ROLE
} from "../constants/actions";
import { SUCCESS_MESSAGES, ERROR_MESSAGES } from "../constants";

const listRoleManagementData = data => ({
  type: LIST_ROLES,
  data
});

export const fetchRoleManagementData = () => {
  let apiURL = `${ENV_CONFIG.configBaseURL}/roles`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(listRoleManagementData(response.data.data));
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

const newRoleManagementData = data => ({ type: ADD_ROLE, data });

export const addRoleManagementData2 = payload => {
  let apiURL = `${ENV_CONFIG.configBaseURL}/roles`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(getAccessToken(getState), apiURL, payload)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(newRoleManagementData(response.data.data));
          dispatch(toastMsg("success", SUCCESS_MESSAGES["roleAdded"]));
        } else {
          dispatch(
            toastMsg(
              "error",
              (response.data.error && response.data.error.detail) ||
                (response.data.error && response.data.error.message) ||
                ERROR_MESSAGES["roleAdd"]
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(toastMsg("error", ERROR_MESSAGES["roleAdd"]));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const addRoleManagementData = data => (dispatch, getState) => {
  dispatch(showLoadingIndicator());
  const state = getState();
  const { store } = state;
  const { countryId } = store;
  return postDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/roles`
    }),
    data
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(toastMsg("success", "Role created successfully"));
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message || "Something went wrong"
            )
          );
        }
      }

      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);

      return error.response;
    });
};

const modifyUserManagementData = data => ({ type: UPDATE_ROLE, data });

export const updateRoleManagementData = (id, payload) => (
  dispatch,
  getState
) => {
  dispatch(showLoadingIndicator());
  const state = getState();
  const { store } = state;
  const { countryId } = store;

  return putDashboardWebApi(
    getAccessToken(getState),
    qs.stringifyUrl({
      url: `${ENV_CONFIG.configBaseURL}/roles/${id}`
    }),
    payload
  )
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 200) {
          dispatch(modifyUserManagementData(response.data.data));
          dispatch(toastMsg("success", "Role created successfully"));
        } else {
          dispatch(
            toastMsg(
              "error",
              response.data.error.message || "Something went wrong"
            )
          );
        }
      }

      dispatch(hideLoadingIndicator());
      return response;
    })
    .catch(error => {
      dispatch(hideLoadingIndicator());
      handleFetchError(error, dispatch);

      return error.response;
    });
};

const removeUserManagementData = data => ({ type: DELETE_ROLE, data });

export const deleteRoleManagementData = id => {
  let apiURL = `${ENV_CONFIG.configBaseURL}/roles/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return deleteWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        if (response.data.code === 200) {
          dispatch(removeUserManagementData(response.data.data));
          dispatch(toastMsg("success", SUCCESS_MESSAGES["roleDeleted"]));
        } else {
          dispatch(
            toastMsg(
              "error",
              (response.data.error && response.data.error.detail) ||
                (response.data.error && response.data.error.message) ||
                ERROR_MESSAGES["roleDelete"]
            )
          );
        }
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        dispatch(toastMsg("error", ERROR_MESSAGES["roleDelete"]));
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
