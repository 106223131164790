import React, { useState, useEffect, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import { compose } from "redux";
import { connect } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import {
  NAVIGATION,
  NAVIGATION_MENUS_SLUGS,
  PAGES_PANEL,
  ITEMS_COUNT_LIMIT
} from "../../constants";
import { getActivePageRoute, getNewAdded, getPageRoute } from "../../util";
import { Paper, LinearProgress, Backdrop } from "../../materialUi/components"
import FoldersContainer from "./foldersContainer";
import PageContent from "./pageContent";
import CountryDropDown from "../countryDropdownMUI";
import Subheader from "../../component/subheader"
import {
  selectActivePage,
  selectNewAddedPage,
  selectPagesFoldersShape,
  selectCountryId,
  selectPagesContent,
} from "../../selectors";
import { changeTab } from "../../actions/common.action";
import {
  setActivePage,
  getPages,
  setPagesRoute,
  updateOpenedFolders,
  updateOpenedContainers,
  getPageData,
  clonePage,
  resetPagesPage,
  setNewAddedPage
} from "../../actions/pagesPage.action";
import { usePrev } from "../../hooks";

const getPageFromStructure = (pagesContent, pageId) =>
  pagesContent.pages[pageId];

const PagesPage = ({
  changeTab,
  pagesFoldersShape,
  activePage,
  pagesContent,
  getPages,
  setActivePage,
  history,
  location,
  setPagesRoute,
  updateOpenedFolders,
  updateOpenedContainers,
  getPageData,
  clonePage,
  countryId,
  resetPagesPage,
  setNewAddedPage,
  newAddedPageId,
  user
}) => {
  let { pageId } = queryString.parse(location.search);
  const {
    permissions: {
      [NAVIGATION_MENUS_SLUGS.PAGES]: { accessRights = {} } = {}
    } = {}
  } = user || {};
  const [currentTab, setCurrentTab] = useState(
    pageId
      ? PAGES_PANEL.PAGES_UPDATE_PAGE_DISPLAY
      : PAGES_PANEL.CATALOG_ADD_PAGE_DISPLAY
  );
  // const [updatePageIndex, setUpdatePageIndex] = useState(0);
  const prevPages = usePrev(pagesFoldersShape.pages);
  const breadCrumArr = [];
  if (activePage?.title) {
    breadCrumArr.push(activePage.title);
  }
  const updateActivePageFlow = pageId => {
    const page = getPageFromStructure(pagesFoldersShape, pageId);

    if (page) {
      setActivePage(page);
      getPageData(page.id);
      const pageRouteObj = getPageRoute(pagesFoldersShape, page.id);
      setPagesRoute(pageRouteObj);
      updateOpenedContainers({
        container: pageRouteObj.container,
        keepOpen: true
      });
      updateOpenedFolders({ folder: pageRouteObj.folder, keepOpen: true });
    }
  };

  const updateActivePageFlowAndNavigation = newAddedPageId => {
    history.push(getActivePageRoute(newAddedPageId, history));
    updateActivePageFlow(newAddedPageId);
    setCurrentTab(PAGES_PANEL.PAGES_UPDATE_PAGE_DISPLAY);
  };

  useEffect(() => {
    changeTab(NAVIGATION.PAGES);
    getPages();
    // eslint-disable-next-line
  }, [countryId]);

  useEffect(() => {
    if (!activePage) {
      if (pageId) {
        updateActivePageFlow(pageId);
        return;
      }

      if (!isEmpty(pagesFoldersShape.homePage)) {
        updateActivePageFlowAndNavigation(pagesFoldersShape.homePage.id);
      }
    }
    // eslint-disable-next-line
  }, [pagesFoldersShape]);

  useEffect(() => {
    if (activePage) {
      setPagesRoute(getPageRoute(pagesFoldersShape, activePage.id));
    }
    // eslint-disable-next-line
  }, [activePage]);

  // eslint-disable-next-line
  useEffect(() => () => resetPagesPage(), []);

  useEffect(() => {
    if (!prevPages) return;
    const pagesIds = Object.keys(pagesFoldersShape.pages);
    const oldPagesIds = Object.keys(prevPages);
    const newPageAdded =
      oldPagesIds.length && pagesIds.length > oldPagesIds.length;
    if (newPageAdded) {
      const newPageId = getNewAdded(pagesIds, oldPagesIds);
      setNewAddedPage(newPageId);
    }
    // eslint-disable-next-line
  }, [pagesFoldersShape.pages]);

  useEffect(() => {
    if (newAddedPageId) {
      if ((activePage || {}).id !== newAddedPageId) {
        updateActivePageFlowAndNavigation(newAddedPageId);
      }
      setTimeout(() => {
        setNewAddedPage(null);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [newAddedPageId]);

  const onDragEnd = useCallback(
    result => {
      const { destination, source, draggableId } = result;
      const pageId = draggableId.replace("draggable-", "");
      const destinationFolderId = destination.droppableId.replace(
        "droppable-",
        ""
      );
      const sourceFolderId = source.droppableId.replace(
        "droppable-",
        ""
      );
      const isSameFolder = destination.droppableId === source.droppableId;
      if (
        !pagesFoldersShape.folders[sourceFolderId].variant &&
        pagesFoldersShape.folders[destinationFolderId].scheduled &&
        !isSameFolder
      ) {
        clonePage(pageId, destinationFolderId, { isScheduler: true, isVariant: false });
      }
      else if (
        !pagesFoldersShape.folders[sourceFolderId].scheduled &&
        pagesFoldersShape.folders[destinationFolderId].variant &&
        !isSameFolder
      ) {
        clonePage(pageId, destinationFolderId, { isScheduler: false, isVariant: true });
      }
    },
    // eslint-disable-next-line
    [pagesFoldersShape]
  );
  if (!Object.keys(pagesContent).length) {
    return <Backdrop
      sx={{ opacity: "0.5", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <LinearProgress sx={{ width: "10%" }} />
    </Backdrop>

  }
  return (
    <div className="row" style={{ "backgroundColor": "rgb(242, 245, 249)", "marginLeft": 0, "marginRight": 0, "paddingTop": "10px" }}>
      <div className="component-header">
        <div className="col-sm-3" style={{ "paddingLeft": "10px", "paddingRight": "5px" }}>
          <Paper
            sx={{
              padding: "20px 15px 15px 15px",
            }}
            variant="outlined"
          >
            <CountryDropDown changeTab={changeTab} />
            <div className="side-bar">
              <div className="pages-containers-wrapper">
                <DragDropContext onDragEnd={onDragEnd}>
                  {pagesFoldersShape.pageContainers.map(container => {
                    const folders = container.folderIds.map(
                      folderId => pagesFoldersShape.folders[folderId]
                    );
                    return (
                      <FoldersContainer
                        key={container.id}
                        container={container}
                        folders={folders}
                        pages={pagesFoldersShape.pages}
                        setCurrentTab={setCurrentTab}
                        folderCreationDisabled={folders.length >= ITEMS_COUNT_LIMIT}
                        accessRights={accessRights}
                      />
                    );
                  })}
                </DragDropContext>
              </div>
            </div>
          </Paper>
        </div>
        <div className="col-sm-9" style={{ "paddingLeft": "5px", "paddingRight": "10px" }}>
          <Subheader showCountry={true} breadcrumArr={breadCrumArr} />
          <PageContent currentTab={currentTab} accessRights={accessRights} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  countryId: selectCountryId(state),
  pagesFoldersShape: selectPagesFoldersShape(state),
  activePage: selectActivePage(state),
  newAddedPageId: selectNewAddedPage(state),
  pagesContent: selectPagesContent(state)
});

const mapDispatchToProps = {
  changeTab,
  setActivePage,
  getPages,
  getPageData,
  setPagesRoute,
  updateOpenedContainers,
  updateOpenedFolders,
  clonePage,
  resetPagesPage,
  setNewAddedPage
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PagesPage);
