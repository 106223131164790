import React, { useState, Fragment } from "react";
import cn from "classnames";
import { _validateRedirectionUrl } from "../../util";
import { LANGUAGE, CAROUSEL_LIST_MESSAGES } from "../../constants";
import ConfirmModal from "../modal/ConfirmModal";
import ImageSelectionModal from "../modal/imageSelectionModal";
import CarouselListForm from "./carouselListForm";

const CarousalList = ({
  arabicListItem,
  countryId,
  deleteCarousalItem,
  englishListItem,
  keyIndex,
  megaMenuCarousalHandler,
  storeId
}) => {
  const [showContent, setShowContent] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [imageSelectionModal, setImageSelectionModal] = useState(false);
  const [language, setLanguage] = useState(null);

  const toggleShowContent = () => setShowContent(prevState => !prevState);

  const handleDelete = () => setConfirmModal(true);

  const hideDeleteModal = () => setConfirmModal(false);

  const deleteCarousal = () => {
    deleteCarousalItem(keyIndex);
    hideDeleteModal();
  };

  const handleImageSelectionModal = (_modalName, lang) => {
    setImageSelectionModal(prevState => !prevState);
    setLanguage(lang);
  };

  const handleUpdateURL = (key, value, _brandId, language) => {
    megaMenuCarousalHandler(key, value, keyIndex, language);
    setImageSelectionModal(prevState => !prevState);
    setLanguage(language);
  };

  const handleCarouselListUrlChange = (name, value, keyIndex) => {
    const validatedValue = _validateRedirectionUrl(value) ? value : `/${value}`;
    megaMenuCarousalHandler(name, validatedValue, keyIndex, LANGUAGE.ENGLISH);
    megaMenuCarousalHandler(name, validatedValue, keyIndex, LANGUAGE.ARABIC);
  };

  const handleCarouselListChange = (name, value, keyIndex, language) => {
    megaMenuCarousalHandler(name, value, keyIndex, language);
  };

  return (
    <Fragment>
      {confirmModal && (
        <ConfirmModal
          modalName={{}}
          onHide={hideDeleteModal}
          confirm={deleteCarousal}
          message={CAROUSEL_LIST_MESSAGES.DELETE_ITEM}
        />
      )}
      {imageSelectionModal && (
        <ImageSelectionModal
          title="Upload Carousal Image"
          modalName="imageSelectionModal"
          keyName="imageUrl"
          brandId=""
          storeId={storeId}
          countryId={countryId}
          language={language}
          containerName="imageList"
          onHide={handleImageSelectionModal}
          onSuccess={handleUpdateURL}
        />
      )}
      <div className="wrapper-box">
        <div className="heading-wrapper flex open">
          <h3>{`Item ${keyIndex + 1}`}</h3>
          <span
            onClick={toggleShowContent}
            className={cn("toggle-banner", {
              "minus-banner": showContent,
              "plus-banner": !showContent
            })}
          />
          <span className="delete-btn" onClick={handleDelete} />
        </div>
        {showContent && (
          <div className="data-wrapper">
            <CarouselListForm
              handleCarouselListChange={handleCarouselListChange}
              handleCarouselListUrlChange={handleCarouselListUrlChange}
              handleModal={handleImageSelectionModal}
              listItem={englishListItem}
              keyIndex={keyIndex}
              language={LANGUAGE.ENGLISH}
            />
            <CarouselListForm
              handleCarouselListChange={handleCarouselListChange}
              handleCarouselListUrlChange={handleCarouselListUrlChange}
              handleModal={handleImageSelectionModal}
              listItem={arabicListItem}
              keyIndex={keyIndex}
              language={LANGUAGE.ARABIC}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CarousalList;
