import React from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import {
  FOOTER_ADD_ATTRIBUTE_FORM_FONT_COLOR_OPTIONS,
  FOOTER_ADD_ATTRIBUTE_FORM_PLACEHOLDERS,
  LANGUAGE
} from "../../constants";
import InputText from "../../component/InputText";
import InputCheckbox from "../../component/InputCheckbox";
import InputRadioGroup from "../../component/InputRadioGroup";

const AddAttributeForm = ({
  content,
  handleTextChange,
  handleUrlChange,
  language
}) => (
  <div className={`${language}-wrapper`}>
    <h4>{upperFirst(language)} Version</h4>
    <div className={`${language}-content`}>
      <InputText
        label="Sub Title"
        language={language}
        name="title"
        placeholder={FOOTER_ADD_ATTRIBUTE_FORM_PLACEHOLDERS[language].title}
        required
        onChange={e =>
          handleTextChange(language, e.target.name, e.target.value)
        }
        value={get(content, "title", "")}
      />
      <InputText
        label="Redirection Url (must start with '/')"
        language={language}
        disabled={language === LANGUAGE.ARABIC}
        name="redirectionLink"
        placeholder={
          FOOTER_ADD_ATTRIBUTE_FORM_PLACEHOLDERS[language].redirectionLink
        }
        onChange={e => handleUrlChange(e.target.value)}
        value={get(content, "redirectionLink", "")}
        disableWhitespaces
        required
      />
      <InputRadioGroup
        label="Font Color"
        name="color"
        language={language}
        required
        options={FOOTER_ADD_ATTRIBUTE_FORM_FONT_COLOR_OPTIONS}
        onChange={(option, e) =>
          handleTextChange(language, e.target.name, option)
        }
        value={get(content, "color.value")}
      />
      <InputCheckbox
        name="bold"
        label="Bold"
        onChange={e =>
          handleTextChange(language, e.target.name, e.target.checked)
        }
        value={get(content, "bold")}
      />
    </div>
  </div>
);

export default AddAttributeForm;
