import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import Button from "../../component/button";
import { CopyColumnContent } from "./copyColumnContent";
import {
  updateRecords,
  handleEditAttribute
} from "../../actions/megaMenu.action";
import {
  updateTreeDataValue,
  clearTreeDataValue,
  updateContentOnTreeChange,
  deleteAttribute
} from "../../actions/sortableTree.action";
import { MEGA_MENU_ITEM_MESSAGES } from "../../constants";
import ConfirmModal from "../modal/ConfirmModal";
import CustomSortableTree from "../customSortableTree";
import AddAttributeModal from "./addMenuItemModal";

class MegaMenuItem extends Component {
  state = {
    openModal: false,
    confirmModal: false,
    selectedAttribData: {},
    confirmModalMessage: "",
    isEdit: false,
    isL3: false
  };

  componentDidMount() {
    const { updateRecords, columnName } = this.props;
    updateRecords(columnName);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.treeData, nextProps.treeData)) {
      this.props.updateContentOnTreeChange(
        nextProps.treeData,
        nextProps.columnName
      );
    }
  }

  updateTreeDataValue = treeData => {
    this.props.updateTreeDataValue(treeData);
  };

  closeConfirmModal = () =>
    this.setState({ confirmModal: false, selectedAttribData: {} });

  openConfirmModal = selectedAttribData => {
    let confirmModalMessage = "";
    if (selectedAttribData.node.children.length) {
      confirmModalMessage = MEGA_MENU_ITEM_MESSAGES.DELETE_RECORD_WITH_NODES;
    } else {
      confirmModalMessage = MEGA_MENU_ITEM_MESSAGES.DELETE_RECORD;
    }
    this.setState({
      selectedAttribData,
      confirmModal: true,
      confirmModalMessage
    });
  };

  openEditModal = selectedAttribData => {
    const { node, parentNode } = selectedAttribData;
    this.props.handleEditAttribute(node);
    this.setState({ openModal: true, isEdit: true, isL3: !!parentNode });
  };

  handleConfirmModal = columnName => {
    this.props.deleteAttribute(
      this.state.selectedAttribData,
      columnName,
      this.props.treeData
    );
    this.setState({ confirmModal: false });
  };

  handleModal = value => this.setState({ openModal: value, isEdit: false });

  handleCopyColumnData = copyToColumnKey => {
    const {
      updateContentOnTreeChange,
      treeData,
      columnName,
      toogleShowContent
    } = this.props;
    updateContentOnTreeChange(treeData, copyToColumnKey);
    updateContentOnTreeChange([], columnName);
    toogleShowContent();
  };

  render() {
    const {
      openModal,
      confirmModal,
      confirmModalMessage,
      isEdit,
      isL3
    } = this.state;
    const { columnName, treeData, showCopyDropdown } = this.props;

    return (
      <div className="mega-menu-col">
        {openModal && (
          <AddAttributeModal
            modalName="Menu Attributes"
            btnName="Save"
            onHide={this.handleModal}
            columnName={columnName}
            isEdit={isEdit}
            isL3={isL3}
          />
        )}
        {confirmModal && (
          <ConfirmModal
            modalName={{}}
            onHide={this.closeConfirmModal}
            confirm={() => this.handleConfirmModal(columnName)}
            message={confirmModalMessage}
          />
        )}
        <div className="column-actions">
          <Button name="Add Menu Item" onClick={() => this.handleModal(true)} />
          {showCopyDropdown && (
            <CopyColumnContent
              listData={[
                { name: "Column 1", key: "col1" },
                { name: "Column 2", key: "col2" },
                { name: "Column 3", key: "col3" }
              ]}
              currentColumnName={columnName}
              handleSubmit={this.handleCopyColumnData}
            />
          )}
        </div>
        <CustomSortableTree
          treeData={treeData || []}
          columnName={columnName}
          updateTreeDataValue={this.updateTreeDataValue}
          openEditModal={this.openEditModal}
          openConfirmModal={this.openConfirmModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  treeData: state.megaMenu.treeData,
  updateArabicContent: state.megaMenu.updateArabicContent,
  updateEnglishContent: state.megaMenu.updateEnglishContent
});

const mapDispatchToProps = {
  clearTreeDataValue,
  deleteAttribute,
  handleEditAttribute,
  updateContentOnTreeChange,
  updateRecords,
  updateTreeDataValue
};

export default connect(mapStateToProps, mapDispatchToProps)(MegaMenuItem);
