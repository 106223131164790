import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import RightPanel from "./rightPanel";
import SideBar from "../sideBar";
import {
  fetchUrlData,
  resetUrlPage,
  setUrlPage,
  deleteUrlData,
  updateToggleData,
  setRollbackHistory,
  getAllRollbackHistory,
  setPageRollback,
} from "../../actions/urlCreation.action";
import { changeTab } from "../../actions/common.action";
import Subheader from "../../component/subheader"
import { NAVIGATION, NAVIGATION_MENUS_SLUGS, URL_PAGE_PANEL } from "../../constants";
import { TextField, IconButton, InputAdornment, TablePagination, Box, Paper, Typography } from "../../materialUi/components"
import { SearchIcon, TuneIcon } from "../../materialUi/icons"
class UrlCreation extends Component {
  state = {
    currentTab: URL_PAGE_PANEL.URL_EDIT_PAGE_DISPLAY,
    searchText: "",
    pageNumber: 0,
    rowsPerPage: 15,
  };

  UNSAFE_componentWillMount() {
    this.props.changeTab(NAVIGATION.CREATE_URL);
  }

  componentDidMount() {
    const { pageNumber, rowsPerPage, searchText } = this.state;
    this.loadData(pageNumber, rowsPerPage, searchText);
  }


  loadData = (pageNumber, rowsPerPage, searchText) => {
    const { fetchUrlData } = this.props;
    const limit = rowsPerPage;
    const skip = pageNumber * rowsPerPage;
    fetchUrlData(limit, skip, searchText).then(res => {
      if (res.data && res.data.code && res.data.code === 200) {
        this.setState({
          pageNumber,
          rowsPerPage,
          searchText,
          currentTab: URL_PAGE_PANEL.URL_EDIT_PAGE_DISPLAY,
        });
      }
    });
  };

  loadFistPageData = () => {
    const { rowsPerPage } = this.state;
    this.setState({ pageNumber: 0, searchText: "", rowsPerPage });
    this.loadData(0, rowsPerPage, "");
  }

  handleSideNavPageClick = data => {
    const { setUrlPage } = this.props;
    const { currentTab } = this.state;
    setUrlPage(data);
    if (currentTab !== URL_PAGE_PANEL.URL_EDIT_PAGE_DISPLAY) {
      this.setState({
        currentTab: URL_PAGE_PANEL.URL_EDIT_PAGE_DISPLAY,
      })
    }
  };

  pressEnter = e => {
    const { rowsPerPage, searchText } = this.state;
    e.preventDefault();
    if (e.key === "Enter") {
      this.setState({ pageNumber: 0, rowsPerPage, searchText });
      this.loadData(0, rowsPerPage, searchText);
    }
  };

  searchTextChangeHandler = e => {
    this.setState(
      {
        searchText: e.target.value || ""
      }
    );
  };

  updateToggle = (value, urlObj, index) => {
    const { updateToggleData } = this.props;
    updateToggleData(urlObj._id, {
      url: urlObj.url,
      enabled: value
    }, index)
  };

  deleteUrl = async (id, url, index) => {
    const { deleteUrlData } = this.props;
    await deleteUrlData(id, url, index);
  };

  changeUrlTab = () => {
    const { currentTab } = this.state;
    const { resetUrlPage } = this.props;
    if (currentTab !== URL_PAGE_PANEL.URL_ADD_PAGE_DISPLAY) {
      this.setState({
        currentTab: URL_PAGE_PANEL.URL_ADD_PAGE_DISPLAY,
      });
      resetUrlPage();
    }
  };

  closeRollbackModal = () => {
    const { setRollbackHistory } = this.props;
    setRollbackHistory([]);
  };

  getRollbackHistory = (pageId, fromDate, toDate) => {
    const { getAllRollbackHistory } = this.props;
    getAllRollbackHistory(pageId, fromDate, toDate)
  }

  onConfirmRollback = (id, index) => {
    const { setPageRollback } = this.props;
    setPageRollback(id, index);
  }

  handleChangeRowsPerPage = (event) => {
    const { searchText } = this.state;
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), pageNumber: 0 });
    this.loadData(0, parseInt(event.target.value, 10), searchText)
  };

  handleChangePage = (event, pageNumber) => {
    const { rowsPerPage, searchText } = this.state;
    this.setState({ pageNumber });
    this.loadData(pageNumber, rowsPerPage, searchText);
  }

  render() {
    const {
      user,
      urlList,
      activePage,
      initialActivePage,
      rollbackHistory,
      urlListCount,
    } = this.props;
    const {
      currentTab,
      searchText,
      rowsPerPage,
      pageNumber,
    } = this.state;
    const {
      permissions: {
        [NAVIGATION_MENUS_SLUGS.URL_CREATION]: { accessRights = {} } = {}
      } = {}
    } = user || {};
    return (
      <div className="row" style={{ "backgroundColor": "rgb(242, 245, 249)", "marginLeft": 0, "marginRight": 0, "paddingTop": "10px" }}>
        <div className="component-header">
          <div className="col-sm-3" style={{ "paddingLeft": "10px", "paddingRight": "5px" }}>
            <Paper
              sx={{
                padding: "20px 15px 15px 15px",
              }}
              variant="outlined"
            >
              <TextField
                label="Search URL, Filter Condition"
                id="outlined-start-adornment"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment:
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>,
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <TuneIcon />
                      </IconButton>
                    </InputAdornment>
                }}
                value={searchText}
                onChange={this.searchTextChangeHandler}
                onKeyUp={this.pressEnter}
                placeholder="Search..."
                sx={{
                  marginBottom: "15px",
                }}
              />
              <SideBar
                currentTab={currentTab}
                urlChangeTabHandler={this.changeUrlTab}
                urlData={urlList || []}
                handlePageClick={this.handleSideNavPageClick}
                accessRights={accessRights}
                currentActiveUrlPageId={activePage._id}
                deleteUrlHandler={this.deleteUrl}
                updateToggleUrlHandler={this.updateToggle}
                closeRollbackModal={this.closeRollbackModal}
                onConfirmRollback={this.onConfirmRollback}
                rollbackHistory={rollbackHistory}
                userRole={user.role}
                getRollbackHistory={this.getRollbackHistory}

              />
              {urlList.length !== 0 && (
                <TablePagination
                  component="div"
                  count={urlListCount}
                  page={pageNumber}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                      "marginTop": "1em",
                      "marginBottom": "1em"
                    },
                  }}
                  rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
                />
              )
              }
            </Paper>
          </div>
          <div className="col-sm-9" style={{ "paddingLeft": "5px", "paddingRight": "10px" }}>
            <Subheader showCountry={false} breadcrumArr={activePage.url ? [activePage.url] : []} />
            <RightPanel
              currentTab={currentTab}
              accessRights={accessRights}
              activePage={activePage}
              initialActivePage={initialActivePage}
              loadFirstPageDataHandler={this.loadFistPageData}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
  urlList: state.urlCreation.urlList,
  activePage: state.urlCreation.activePage,
  initialActivePage: state.urlCreation.initialActivePage,
  urlListCount: state.urlCreation.urlListCount,
  rollbackHistory: state.urlCreation.rollbackHistory,
});

const mapDispatchToProps = dispatch => ({
  fetchUrlData: bindActionCreators(fetchUrlData, dispatch),
  changeTab: bindActionCreators(changeTab, dispatch),
  setUrlPage: bindActionCreators(setUrlPage, dispatch),
  deleteUrlData: bindActionCreators(deleteUrlData, dispatch),
  updateToggleData: bindActionCreators(updateToggleData, dispatch),
  resetUrlPage: bindActionCreators(resetUrlPage, dispatch),
  setRollbackHistory: bindActionCreators(setRollbackHistory, dispatch),
  getAllRollbackHistory: bindActionCreators(getAllRollbackHistory, dispatch),
  setPageRollback: bindActionCreators(setPageRollback, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UrlCreation);
