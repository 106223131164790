import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import ReactDiffViewer from 'react-diff-viewer';
import { HomeIcon, KeyboardArrowRightIcon } from "../../materialUi/icons";
import moment from "moment";
import { getRollbackCompareVersionData } from "../../actions/pagesPage.action";
import { getRollbackCompareVersionData as getConfigRollbackCompareVersionData } from "../../actions/configurationsPage.action";
import { getRollbackCompareVersionData as getUrlRollbackCompareVersionData } from "../../actions/urlCreation.action";
import { getRollbackCompareVersionData as getSectionRollbackCompareVersionData } from "../../actions/sectionsPage.action";
import { snackbar } from "../../actions/common.action";
import {
  Button, TextField, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Typography, Divider, BasicTabs,
  BasicPopover, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Select, InputLabel, FormControl,
  MenuItem,
} from '../../materialUi/components';
import { DateTimePicker } from "../../materialUi/datePicker";
import { ROLLBACK_TAB_LIST, NAVIGATION } from "../../constants"

function RollbackModal({ pageId, onHide, history, route, open, dialogContentText, handleClose, onConfirmRollback, getRollbackHistory, pageType }) {
  const dispatch = useDispatch();

  const handleRollbackPage = id => {
    onConfirmRollback(id)
    onClose();
  };

  const [_open, setOpen] = useState(open);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState(-1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCompareVersion1, setSelectedCompareVersion1] = useState('');
  const [selectedCompareVersion2, setSelectedCompareVersion2] = useState('');
  const [compareVersion1String, setCompareVersion1String] = useState('');
  const [compareVersion2String, setCompareVersion2String] = useState('');
  useEffect(() => {
    setOpen(open);
    setSelectedTabIndex(0);
    setSelectedCompareVersion1('');
    setSelectedCompareVersion2('');
    setCompareVersion1String('');
    setCompareVersion2String('');
  }, [open]);

  useEffect(() => {
    setSelectedHistoryIndex(-1);
    setAnchorEl(null);
  }, [selectedTabIndex, open]);

  useEffect(() => {
    setSelectedCompareVersion1('');
    setSelectedCompareVersion2('');
    setCompareVersion1String('');
    setCompareVersion2String('');
  }, [history])

  const onClose = () => {
    setOpen(false);
    handleClose();
  }

  const handleLogSubmit = () => {
    if (moment(fromDate) > moment(toDate)) {
      return dispatch(snackbar({ open: true, severity: "error", text: "From Date should be lesser or equal to To Date " }));
    }
    if (moment(fromDate) > moment()) {
      return dispatch(snackbar({ open: true, severity: "error", text: "From Date should be lesser or equal to Current Date" }))
    }
    getRollbackHistory(pageId, fromDate, toDate);
  }

  const handleCompare = async () => {
    let compareFunction;
    switch (pageType) {
      case NAVIGATION.PAGES:
        compareFunction = getRollbackCompareVersionData
        break;
      case NAVIGATION.CONFIGURATIONS:
        compareFunction = getConfigRollbackCompareVersionData
        break;
      case NAVIGATION.CREATE_URL:
        compareFunction = getUrlRollbackCompareVersionData
        break;
      case NAVIGATION.SECTIONS:
        compareFunction = getSectionRollbackCompareVersionData
        break;
      default:
        compareFunction = () => { };

    }
    let response = await dispatch(compareFunction(pageId, [selectedCompareVersion1, selectedCompareVersion2]));
    let v1Content = null;
    let v2Content = null;

    if (response && response.length) {
      response.forEach((res) => {
        if (res?.version === selectedCompareVersion1) {
          v1Content = res.content
        } else if (res?.version === selectedCompareVersion2) {
          v2Content = res.content
        }
      })
    }
    if (v1Content && v2Content) {
      setCompareVersion1String(JSON.stringify(v1Content, null, 1));
      setCompareVersion2String(JSON.stringify(v2Content, null, 1));
    }
  }

  return (
    <Dialog open={_open} onClose={onClose} maxWidth={"xl"} fullWidth>
      <DialogTitle sx={{ "display": "flex" }}>
        <HomeIcon fontSize="large" />
        <KeyboardArrowRightIcon fontSize="large" />
        <Typography sx={{ "marginTop": "5px" }}>
          {route[0] || ""}
        </Typography>
        <KeyboardArrowRightIcon fontSize="large" />
        <Typography sx={{ "marginTop": "5px" }}>
          {route[1] || ""}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText sx={{ "marginBottom": "16px" }}>
          {dialogContentText}
        </DialogContentText>
        <div>
          <DateTimePicker
            label="From Date"
            value={moment(fromDate)}
            onChange={(val) => {
              setFromDate(moment(val).format())
            }}
            renderInput={(params) => <TextField {...params} />
            }
            disableFuture
            sx={{ "minWidth": "30%", "marginRight": "20px" }}
          />
          <DateTimePicker
            label="To Date"
            value={moment(toDate)}
            onChange={(val) => {
              setToDate(moment(val).format())
            }}
            renderInput={(params) => <TextField {...params} />}
            sx={{ "minWidth": "30%", "marginLeft": "20px", "marginRight": "20px" }}
            maxDate={moment()}
          />

          <Button sx={{ "marginTop": "8px", "marginBottom": "8px" }} variant="contained" onClick={handleLogSubmit} disabled={!fromDate || !toDate}>Submit</Button>
        </div>
        <Divider variant="fullWidth" sx={{ "marginTop": "16px", "marginBottom": "16px" }} />
        <BasicTabs
          value={selectedTabIndex}
          handleChange={(newValue) => setSelectedTabIndex(newValue)}
          tabList={ROLLBACK_TAB_LIST} />
        {selectedTabIndex === 0 &&
          <Paper sx={{ width: '100%', overflow: 'hidden', "marginTop": "20px" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table aria-label="sticky table" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      key={"index"}
                      align={"left"}
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      {"S.No"}
                    </TableCell>
                    <TableCell
                      key={"version"}
                      align={"left"}
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      {"Version"}
                    </TableCell>
                    <TableCell
                      key={"name"}
                      align={"left"}
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      {"Updated By"}
                    </TableCell>
                    <TableCell
                      key={"createdAt"}
                      align={"left"}
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      {"Updated On"}
                    </TableCell>
                    <TableCell
                      key={"action"}
                      align={"left"}
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      {"Action"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history && history.map((item, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ "maxHeight": "15px" }}>
                      <TableCell
                        key={"index"}
                        align={"left"}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        key={"version"}
                        align={"left"}
                      >
                        {item.version}
                      </TableCell>
                      <TableCell
                        key={"name"}
                        align={"left"}
                      >
                        {item.createdBy.name}
                      </TableCell>
                      <TableCell
                        key={"createdAt"}
                        align={"left"}
                      >
                        {moment(item.createdAt).format(
                          "Do MMM YYYY, h:mm:ss a"
                        )}
                      </TableCell>
                      <TableCell
                        key={"action"}
                        align={"left"}
                      >
                        {item.isCurrent ?
                          <Typography sx={{ "fontWeight": "500" }}>
                            Current version
                          </Typography>
                          :
                          <Button
                            onClick={(ev) => {
                              setSelectedHistoryIndex(index);
                              setAnchorEl(ev.currentTarget);
                            }}>
                            Rollback
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            {(!history || !history.length) ?
              <Typography sx={{ "textAlign": "center", "marginTop": "20px", "marginBottom": "20px" }}>
                No Records Found
              </Typography> : null
            }
          </Paper>
        }
        {selectedTabIndex === 0 &&
          Boolean(anchorEl) && selectedHistoryIndex > -1 &&
          <BasicPopover
            open={Boolean(anchorEl)}
            id={history[selectedHistoryIndex]._id}
            anchorEl={anchorEl}
            handleCancel={() => {
              setAnchorEl(null);
              setSelectedHistoryIndex(-1);
            }}
            handleSave={() => {
              handleRollbackPage(history[selectedHistoryIndex]._id)
              setAnchorEl(null);
              setSelectedHistoryIndex(-1);
            }}
            text={"Are you sure you want to Rollback ?"}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          />
        }
        {selectedTabIndex === 1 &&
          <div style={{ "marginTop": "20px", "marginBottom": "20px" }}>
            <FormControl sx={{ "marginRight": "20px", minWidth: "30%" }}>
              <InputLabel id="demo-simple-select-label">Select Version</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCompareVersion1}
                label="Version 1"
                onChange={(ev) => {
                  setSelectedCompareVersion1(ev.target.value)
                }}
              >
                {history && history.map((item, index) => (
                  <MenuItem value={item.version} key={item._id} disabled={item.version === selectedCompareVersion2}>
                    {`${item.version} > ${item.createdBy?.name} > ${moment(item.createdAt).format("Do MMM YYYY, h:mm:ss a")}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ "marginLeft": "20px", "marginRight": "20px", minWidth: "30%" }}>
              <InputLabel id="demo-simple-select-label">Select Version</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCompareVersion2}
                label="Version 2"
                onChange={(ev) => {
                  setSelectedCompareVersion2(ev.target.value)
                }}
              >
                {history && history.map((item, index) => (
                  <MenuItem value={item.version} key={item._id} disabled={item.version === selectedCompareVersion1}>
                    <Typography>
                      {`${item.version} > ${item.createdBy?.name} > ${moment(item.createdAt).format("Do MMM YYYY, h:mm:ss a")}`}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button sx={{ "marginTop": "8px", "marginBottom": "8px" }} variant="contained" onClick={handleCompare} disabled={!selectedCompareVersion1 || !selectedCompareVersion2}>Compare</Button>
          </div>
        }
        {selectedTabIndex === 1 && compareVersion1String && compareVersion2String &&
          <ReactDiffViewer oldValue={compareVersion1String} newValue={compareVersion2String} splitView={true} />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default RollbackModal;
