import { cloneDeep, isEqual, isEmpty } from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreatableSelect from "react-select/creatable";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import moment from "moment";
import { ENV_CONFIG } from "../../config/env";
import {
  FLASH_SALE_PAGE_MESSAGES,
  FLASH_SALE_PLACEHOLDERS
} from "../../constants";
import InvalidImage from "../../images/invalid-image.jpg";
import CSVFile from "../../files/sample_flash_sale.csv";
import {
  Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TextField,
  TableRow, IconButton, Button,
  BasicPopover, Tooltip, Alert,
  Box, Checkbox, AntSwitch, AppBar, Tabs,
  Tab, Typography, Stack
} from '../../materialUi/components';
import { DateTimePicker } from "../../materialUi/datePicker";
import { DeleteIcon, UploadIcon, DownloadIcon, InfoIcon } from '../../materialUi/icons';
import EnhancedProductTableToolbar from "./enhancedProductTableToolbar";
import ConfirmModal from "../modal/ConfirmModal";
import CustomLoadingIndicator from "../../component/customLoadingIndicator";
import {
  setSelectedSaleContentIndex,
  updatePageContent,
  fetchProductDetailsByCountryId,
  fetchProductListOfSales,
  deleteProductFromSale,
  deleteAllProductsFromSale,
  multiDeleteProductsFromSale,
  deleteProducts,
} from "../../actions/flashSale.action";
import { snackbar } from "../../actions/common.action";


class UpdatePageContent extends Component {
  constructor(props) {
    super(props);
    this.deleteFlashSaleTableRef = React.createRef();
    this.inputFileRef = React.createRef();
    this.state = {
      pids: {},
      showModal: { saleSwitchConfirmPopup: false },
      tempSelectedSaleContentIndex: 0,
      invalidPidData: [],
      anchorEl: null,
      anchorE2: null,
      anchorE3: null,
      selectedProductIndex: -1,
      selectedProducts: {}
    };
  }

  componentDidUpdate(prevProps) {
    const {
      pageContent = {},
      selectedSaleContentIndex,
      countryId,
      fetchProductListOfSales
    } = this.props;
    const {
      pageContent: prevPageContent = {},
      selectedSaleContentIndex: prevSelectedSaleContentIndex
    } = prevProps;
    if (pageContent._id !== prevPageContent._id) {
      this.setState({ pids: {}, selectedProductIndex: -1, anchorEl: null, anchorE2: null, anchorE3: null, selectedProducts: {} });
      this.resetInvalidPid();
    }
    if (
      selectedSaleContentIndex !== prevSelectedSaleContentIndex ||
      pageContent._id !== prevPageContent._id
    ) {
      this.setState({ selectedProductIndex: -1, anchorEl: null, anchorE2: null, anchorE3: null, selectedProducts: {} });
      this.resetInvalidPid();
      if (
        pageContent?.sales?.[selectedSaleContentIndex]?.productsCount
      ) {
        const productsCount =
          pageContent.sales[selectedSaleContentIndex].productsCount || 0;
        const productLength = pageContent.sales[selectedSaleContentIndex]
          .products
          ? pageContent.sales[selectedSaleContentIndex].products.length
          : 0;
        if (productsCount !== productLength) {
          fetchProductListOfSales(
            pageContent._id,
            pageContent.slug,
            pageContent.sales[selectedSaleContentIndex].slug,
            countryId
          );
        }
      }
    }
  }

  handleChange = (key, value, salesIndex) => {
    const {
      pageContent,
      pageContent: { sales = [] } = {},
      updatePageContent
    } = this.props;
    if (sales[salesIndex]) {
      const updatedPageContent = cloneDeep(pageContent);
      updatedPageContent.sales[salesIndex][key] = value;
      updatePageContent(updatedPageContent);
    }
  };
  salesChangeHandler = salesIndex => {
    const {
      selectedSaleContentIndex,
      setSelectedSaleContentIndex,
      pageContent,
      initialPageContent
    } = this.props;
    if (selectedSaleContentIndex !== salesIndex) {
      if (isEqual(initialPageContent, pageContent)) {
        return setSelectedSaleContentIndex(salesIndex);
      }
      this.setState(prevState => {
        return {
          showModal: { ...prevState.showModal, saleSwitchConfirmPopup: true },
          tempSelectedSaleContentIndex: salesIndex
        };
      });
    }
  };

  handleDatePicker = (e, type) => {
    const timeStamp = Date.parse(e);
    const {
      pageContent,
      pageContent: { sales = [] } = {},
      selectedSaleContentIndex,
      updatePageContent
    } = this.props;
    if (sales[selectedSaleContentIndex]) {
      const updatedPageContent = cloneDeep(pageContent);
      updatedPageContent.sales[selectedSaleContentIndex][type] = timeStamp;
      updatePageContent(updatedPageContent);
    }
  };

  discountPriceHandler = (key, value, id, index, currentPrice = 0) => {
    const parsedValue = value ? parseFloat(value) : 0;
    const {
      pageContent,
      pageContent: { sales = [] } = {},
      selectedSaleContentIndex,
      updatePageContent,
      snackbar,
    } = this.props;
    if (parsedValue > currentPrice) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.invalid_dis_price });
      return;
    }
    if (parsedValue < 0) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.invalid_min_discount_price });
      return;
    }
    if (
      sales[selectedSaleContentIndex]?.["products"]?.[index]?.["id"] === id
    ) {
      const updatedPageContent = cloneDeep(pageContent);
      updatedPageContent.sales[selectedSaleContentIndex]["products"][index][
        key
      ] = parsedValue;
      updatePageContent(updatedPageContent);
    }
  };

  deleteActionBtnHandler = (index, id, objectID = "") => {
    const {
      pageContent,
      pageContent: { sales = [] } = {},
      selectedSaleContentIndex,
      updatePageContent,
      deleteProductFromSale
    } = this.props;
    const pageId = pageContent._id || "";
    const pageSlug = pageContent.slug || "";
    const saleIndex = selectedSaleContentIndex;
    const saleSlug = sales[selectedSaleContentIndex]
      ? sales[selectedSaleContentIndex].slug
      : "";
    const productIndex = index;
    if (objectID) {
      return deleteProductFromSale(id, objectID, {
        pageId,
        pageSlug,
        saleSlug,
        saleIndex,
        productIndex
      });
    }
    if (
      sales[selectedSaleContentIndex]?.["products"]?.[index]?.["id"] === id
    ) {
      const updatedPageContent = cloneDeep(pageContent);
      updatedPageContent.sales[selectedSaleContentIndex]["products"].splice(
        index,
        1
      );
      updatePageContent(updatedPageContent);
    }
  };

  pidsHandler = (key, value) => {
    const { selectedSaleContentIndex } = this.props;
    const regex = new RegExp(/^[1-9]\d*$/);
    if (key === "inputValue" && !regex.test(value)) {
      //Only integer value is allowed

      if (value && value.includes(",")) {
        //For Copy paste case
        let valueArr = value.split(",");
        let filteredValueArr = valueArr.filter(val => regex.test(val));
        let finalValueArr = filteredValueArr.map(val => ({
          label: val,
          value: val
        }));
        this.setState(prevState => {
          const currentSalePrevValues = prevState.pids[
            selectedSaleContentIndex
          ] || { value: [], inputValue: "" };
          return {
            pids: {
              ...prevState.pids,
              [selectedSaleContentIndex]: {
                value: [...currentSalePrevValues.value, ...finalValueArr],
                inputValue: ""
              }
            }
          };
        });
      }
      return;
    }
    this.setState(prevState => {
      const currentSalePrevValues = prevState.pids[
        selectedSaleContentIndex
      ] || { value: [], inputValue: "" };
      return {
        pids: {
          ...prevState.pids,
          [selectedSaleContentIndex]: { ...currentSalePrevValues, [key]: value }
        }
      };
    });
  };

  handleKeyDown = event => {
    const { selectedSaleContentIndex } = this.props;
    const {
      pids: { [selectedSaleContentIndex]: { inputValue = "" } = {} }
    } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case ",":
      case "Enter":
      case "Tab":
        this.setState(prevState => {
          const currentSalePrevValues = prevState.pids[
            selectedSaleContentIndex
          ] || { value: [], inputValue: "" };
          return {
            pids: {
              ...prevState.pids,
              [selectedSaleContentIndex]: {
                value: [
                  ...currentSalePrevValues.value,
                  {
                    label: currentSalePrevValues.inputValue,
                    value: currentSalePrevValues.inputValue
                  }
                ],
                inputValue: ""
              }
            }
          };
        });
        event.preventDefault();
    }
  };

  processDataHandler = async () => {
    const {
      selectedSaleContentIndex,
      fetchProductDetailsByCountryId,
      countryId,
      pageContent,
      updatePageContent,
      snackbar,
    } = this.props;
    const {
      pids: { [selectedSaleContentIndex]: { value = [] } = {} } = {}
    } = this.state;
    const existingProductIdMapping = {};
    const { sales = [] } = pageContent || {};
    const currentSalesObj = sales[selectedSaleContentIndex] || {};
    const { products: existingProducts = [] } = currentSalesObj || {};
    existingProducts.forEach(({ id }) => {
      existingProductIdMapping[id] = 1;
    });
    const discountPriceByPid = new Map();
    const productIds = value.map(item => {
      if (item.discountPrice) {
        discountPriceByPid.set(Number(item.value), Number(item.discountPrice));
      }
      return parseInt(item.value);
    });

    for (const i in productIds) {
      if (existingProductIdMapping[productIds[i]]) {
        snackbar({ open: true, severity: "error", text: `Product Id ${productIds[i]} already exist in table.` });
        return;
      }
    }
    const response = await fetchProductDetailsByCountryId(
      countryId,
      productIds
    );
    if (
      response?.status === 200 &&
      response?.data?.products?.length
    ) {
      const pidsWithWrongDiscountPrice = [];
      const productsWithDiscountPrice = response.data.products.map(product => {
        if (discountPriceByPid.has(product.id)) {
          if (
            discountPriceByPid.get(product.id) > 0 &&
            discountPriceByPid.get(product.id) < Number(product.currentPrice)
          ) {
            product.discountPrice = discountPriceByPid.get(product.id);
          } else {
            product.discountPrice = Number(product.currentPrice);
            pidsWithWrongDiscountPrice.push(product.id);
          }
        } else {
          product.discountPrice = Number(product.currentPrice);
        }
        return product;
      });

      if (!isEmpty(pidsWithWrongDiscountPrice)) {
        snackbar({
          open: true,
          severity: "error",
          text: `These Pids have wrong discount prices ${pidsWithWrongDiscountPrice.toString()}.`
        });

      }
      const updatedPageContent = cloneDeep(pageContent);
      if (!updatedPageContent.sales[selectedSaleContentIndex].products)
        updatedPageContent.sales[selectedSaleContentIndex].products = [];

      updatedPageContent.sales[selectedSaleContentIndex].products = [
        ...cloneDeep(productsWithDiscountPrice),
        ...cloneDeep(
          updatedPageContent.sales[selectedSaleContentIndex].products
        )
      ];
      updatePageContent(updatedPageContent);
    }
    let invalidPidsArr = [];
    if (
      response?.status === 200 &&
      response?.data?.wrongPids?.length
    ) {
      const headers = ["pid", "discountPrice"];
      const wrongPidData = response.data.wrongPids.reduce((prev, currPid) => {
        prev.push([currPid, discountPriceByPid.get(currPid) || ""]);
        return prev;
      }, []);
      const csvData = [headers, ...wrongPidData];
      this.setState({ invalidPidData: csvData });
    }
    this.setState(prevState => {
      return {
        pids: {
          ...prevState.pids,
          [selectedSaleContentIndex]: {
            value: [],
            inputValue: ""
          }
        }
      };
    });
  };

  getSalesTab = () => {
    const {
      pageContent: { sales = [] } = {},
      selectedSaleContentIndex
    } = this.props;
    return (
      <Box>
        <AppBar position="static" sx={{ bgcolor: "#f5f5f5" }} elevation={0}>
          <Tabs
            value={selectedSaleContentIndex}
            onChange={(_, value) => {
              this.salesChangeHandler(value)
            }}
            variant="fullWidth"
            TabIndicatorProps={{ sx: { backgroundColor: "#000000" } }}
          >
            {sales.map((data, index) =>
              <Tab
                key={data.slug}
                label={data.name + `( ${data.productsCount || 0} Products )`}
                sx={{
                  bgcolor: selectedSaleContentIndex === index ? "#FFD100" : "",
                  "color": "#000000",
                  '&.Mui-selected': {
                    color: '#000000',
                  }
                }} />
            )}
          </Tabs>
        </AppBar>
      </Box>)
  };

  handleCsvData = results => {
    const { selectedSaleContentIndex, snackbar } = this.props;
    let pidsArray = [];
    if (results?.data) {
      if (results.data.length) {
        results.data.forEach(csvData => {
          const pid = csvData.pid + "";
          if (pid) {
            pidsArray.push({
              label: pid,
              value: pid,
              discountPrice: csvData.discountPrice
            });
          }
        });
      } else {
        snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.no_data_found_csv });
        return;
      }
    } else {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.err_parse_csv });
      return;
    }
    if (!pidsArray.length) {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.no_rel_data_found_csv });
      return;
    } else {
      this.setState(prevState => {
        const currentSalePrevValues = prevState.pids[
          selectedSaleContentIndex
        ] || { value: [], inputValue: "" };
        return {
          pids: {
            ...prevState.pids,
            [selectedSaleContentIndex]: {
              value: [...currentSalePrevValues.value, ...pidsArray],
              inputValue: ""
            }
          }
        };
      });
    }
  };

  handleFiles = event => {
    const { snackbar } = this.props;
    if (event?.target?.files?.[0]) {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: this.handleCsvData
      });
    } else {
      snackbar({ open: true, severity: "error", text: FLASH_SALE_PAGE_MESSAGES.err_parse_csv });
    }
    event.target.value = "";
  };

  hideModal = modalName => {
    this.setState(prevState => {
      return { showModal: { ...prevState.showModal, [modalName]: false } };
    });
  };

  confirmModal = modalName => {
    const {
      initialPageContent,
      updatePageContent,
      setSelectedSaleContentIndex
    } = this.props;
    const { tempSelectedSaleContentIndex } = this.state;
    if (modalName === "saleSwitchConfirmPopup") {
      updatePageContent(cloneDeep(initialPageContent));
      setSelectedSaleContentIndex(tempSelectedSaleContentIndex);
    }
  };

  resetInvalidPid = () => {
    this.setState({ invalidPidData: [] });
  }

  handleClearAll = () => {
    const {
      pageContent,
      selectedSaleContentIndex,
      deleteAllProductsFromSale,
      countryId,
    } = this.props;
    deleteAllProductsFromSale(pageContent._id, pageContent.slug, pageContent.sales[selectedSaleContentIndex].slug, [], countryId)
  }

  handleMultiDelete = (products = []) => {
    const {
      pageContent,
      pageContent: { sales = [] } = {},
      selectedSaleContentIndex,
      multiDeleteProductsFromSale,
      countryId,
      deleteProducts,
    } = this.props;
    const { selectedProducts } = this.state;
    const pageId = pageContent._id || "";
    const pageSlug = pageContent.slug || "";
    const saleIndex = selectedSaleContentIndex;
    const saleSlug = sales[selectedSaleContentIndex]
      ? sales[selectedSaleContentIndex].slug
      : "";
    const pIds = [];
    const objectIds = [];
    products.forEach((product) => {
      if (selectedProducts[product.id]) {
        pIds.push(product.id);
      }
      if (selectedProducts[product.id] && product.objectID) {
        objectIds.push(product.objectID);
      }
    });
    if (!objectIds.length) {
      deleteProducts(pIds, { pageId, pageSlug, saleSlug, saleIndex, countryId })
    } else {
      multiDeleteProductsFromSale(objectIds, pIds, { pageId, pageSlug, saleSlug, saleIndex, countryId });
    }
    this.setState({ selectedProducts: {} })

  }

  handleSelectAllClick = (event, products) => {
    if (event.target.checked) {
      const newSelectedProducts = {};
      products.forEach((product) => {
        newSelectedProducts[product.id] = true;
      })
      this.setState({ selectedProducts: newSelectedProducts });
      return;
    }
    this.setState({ selectedProducts: {} });
  };

  handleClickProduct = (id) => {
    const { selectedProducts } = this.state;
    const newSelectedProducts = cloneDeep(selectedProducts);
    if (newSelectedProducts[id] === true) {
      delete newSelectedProducts[id];
    } else {
      newSelectedProducts[id] = true;
    }
    this.setState({ selectedProducts: newSelectedProducts });
  }

  render() {
    const {
      pageContent,
      selectedSaleContentIndex,
      showCustomLoader
    } = this.props;
    const { pids, showModal, invalidPidData, anchorEl, anchorE2, anchorE3, selectedProductIndex, selectedProducts } = this.state;
    const { sales = [] } = pageContent || {};
    const tabContent = sales[selectedSaleContentIndex] || {};
    const products = tabContent["products"] || [];
    const numSelected = Object.keys(selectedProducts).length;
    return (
      <div>
        {showModal["saleSwitchConfirmPopup"] && (
          <ConfirmModal
            modalName="saleSwitchConfirmPopup"
            onHide={() => {
              this.hideModal("saleSwitchConfirmPopup");
            }}
            confirm={() => {
              this.confirmModal("saleSwitchConfirmPopup");
              this.hideModal("saleSwitchConfirmPopup");
            }}
            message={FLASH_SALE_PAGE_MESSAGES.confirm_popup}
          />
        )}
        <div className="dynamicBannerModule">
          <div className="wrapper-box">
            <div className="data-wrapper">
              <div className="st st-theme-default">
                {this.getSalesTab()}
                <div className="tab-content">
                  <div className="flash-sale-content">
                    <div className="tab-pane active">
                      <Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>Enabled</Typography>
                          <AntSwitch
                            checked={tabContent.enabled || false}
                            onChange={(e) => {
                              this.handleChange("enabled", e.target.checked, selectedSaleContentIndex);
                            }}
                          />
                        </Stack>
                      </Box>
                      <Box sx={{ "marginTop": "20px" }}>
                        <TextField
                          autoFocus
                          id="en_title"
                          label="Tab Title(English)"
                          type="text"
                          placeholder={FLASH_SALE_PLACEHOLDERS.page_title_en}
                          value={tabContent.en_title || ""}
                          name="en_title"
                          onChange={e =>
                            this.handleChange(
                              e.target.name,
                              e.target.value,
                              selectedSaleContentIndex
                            )
                          }
                          sx={{ width: "45%" }}
                          size="small"
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          autoFocus
                          id="ar_title"
                          label="Tab Title(Arabic)"
                          type="text"
                          placeholder={FLASH_SALE_PLACEHOLDERS.page_title_ar}
                          value={tabContent.ar_title || ""}
                          name="ar_title"
                          onChange={e =>
                            this.handleChange(
                              e.target.name,
                              e.target.value,
                              selectedSaleContentIndex
                            )
                          }
                          dir="rtl"
                          sx={{ width: "45%", float: "right" }}
                          size="small"
                          InputLabelProps={{ shrink: true }}

                        />
                      </Box>
                      <Box sx={{ "marginTop": "20px" }}>
                        <TextField
                          autoFocus
                          id="en_subtitle"
                          label="Tab Subtitle(English)"
                          type="text"
                          placeholder={FLASH_SALE_PLACEHOLDERS.page_subtitle_en}
                          value={tabContent.en_subtitle || ""}
                          name="en_subtitle"
                          onChange={e =>
                            this.handleChange(
                              e.target.name,
                              e.target.value,
                              selectedSaleContentIndex
                            )
                          }
                          sx={{ width: "45%" }}
                          size="small"
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          autoFocus
                          id="ar_subtitle"
                          label="Tab Subtitle(Arabic)"
                          type="text"
                          placeholder={FLASH_SALE_PLACEHOLDERS.page_subtitle_ar}
                          value={tabContent.ar_subtitle || ""}
                          name="ar_subtitle"
                          onChange={e =>
                            this.handleChange(
                              e.target.name,
                              e.target.value,
                              selectedSaleContentIndex
                            )
                          }
                          dir="rtl"
                          sx={{ width: "45%", float: "right" }}
                          size="small"
                          InputLabelProps={{ shrink: true }}

                        />
                      </Box>
                      <Box sx={{ "marginTop": "20px" }}>
                        <DateTimePicker
                          label="From Date"
                          value={moment(tabContent.from || "")}
                          onChange={(val) => {
                            this.handleDatePicker(moment(val).format(), "from")
                          }}
                          disablePast
                          sx={{ "width": "45%" }}
                          slotProps={{ textField: { size: 'small', error: false } }}
                        />
                        <DateTimePicker
                          label="To Date"
                          value={moment(tabContent.to || "")}
                          onChange={(val) => {
                            this.handleDatePicker(moment(val).format(), "to")
                          }}
                          disablePast
                          sx={{ "width": "45%", float: "right" }}
                          slotProps={{ textField: { size: 'small', error: false } }}
                          minDateTime={moment(tabContent.from || "")}
                        />
                      </Box>
                      <input
                        type="file"
                        name="file"
                        accept=".csv"
                        onChange={this.handleFiles}
                        style={{ display: "none" }}
                        ref={this.inputFileRef}
                      />
                      <Box>
                        <div
                          className="form-group"
                          style={{ display: "inline-block", width: "80%" }}
                        >
                          <label>Pids</label>
                          <CreatableSelect
                            components={{
                              DropdownIndicator: null,
                            }}
                            inputValue={
                              pids[selectedSaleContentIndex]
                                ? pids[selectedSaleContentIndex].inputValue
                                : ""
                            }
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={newValue =>
                              this.pidsHandler("value", newValue || [])
                            }
                            onInputChange={newValue =>
                              this.pidsHandler("inputValue", newValue || "")
                            }
                            onKeyDown={this.handleKeyDown}
                            placeholder={FLASH_SALE_PLACEHOLDERS.page_pids_sel}
                            value={
                              pids[selectedSaleContentIndex]
                                ? pids[selectedSaleContentIndex].value
                                : []
                            }
                          />
                        </div>
                        <Tooltip title="Upload CSV" placement="top" arrow>
                          <IconButton
                            onClick={() => {
                              this.inputFileRef.current.click();
                            }}>
                            <UploadIcon sx={{ color: "black" }} fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Download sample CSV" placement="top" arrow>
                          <a href={CSVFile}
                            download="Sample_FS_pids_format.csv"
                          >
                            <IconButton>
                              <InfoIcon sx={{ color: "black" }} fontSize="medium" />
                            </IconButton>
                          </a>
                        </Tooltip>
                        <Button
                          variant="contained"
                          onClick={this.processDataHandler}
                          disabled={
                            !(
                              pids[selectedSaleContentIndex]?.value?.length
                            )
                          }
                          sx={{ background: "#000000", marginLeft: "18px", '&:hover': { background: "#000000" } }}
                        >
                          Process Data
                        </Button>
                      </Box>
                      {
                        Boolean(anchorE2) &&
                        <BasicPopover open={Boolean(anchorE2)} id={"deleteSelected"} anchorEl={anchorE2}
                          handleCancel={() => this.setState({ anchorE2: null })}
                          handleSave={() => {
                            // this.handleClearAll();
                            this.handleMultiDelete(products);
                            this.setState({ anchorE2: null });
                          }}
                          text={"Are you sure you want to delete All Selected Products.Changes cannot be reverted back?"}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        />
                      }
                      {
                        Boolean(anchorE3) &&
                        <BasicPopover open={Boolean(anchorE3)} id={"clearAll"} anchorEl={anchorE3}
                          handleCancel={() => this.setState({ anchorE3: null })}
                          handleSave={() => {
                            this.handleClearAll();
                            this.setState({ anchorE3: null });
                          }}
                          text={"Are you sure you want to delete All Products.Changes cannot be reverted back?"}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        />
                      }
                      <Button
                        variant="text"
                        disabled={
                          !(products?.length)
                        }
                        onClick={(ev) => this.setState({ anchorE3: ev.currentTarget })}
                        sx={{ float: "right", marginTop: "25px" }}
                      >
                        Clear All
                      </Button>
                      {!!invalidPidData.length &&
                        <div style={{ "display": "flex", "marginTop": "10px" }}>
                          <Alert severity="error">Please Download Invalid Pids from here.</Alert>
                          <CSVLink
                            data={invalidPidData}
                            onClick={this.resetInvalidPid}
                            filename={"invalid-pids.csv"}
                          >
                            <Tooltip title="Download CSV" arrow placement="top">
                              <IconButton>
                                <DownloadIcon sx={{ color: "black" }} fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          </CSVLink>
                        </div>
                      }
                      <div style={{ "display": "flex", "marginBottom": "10px", "marginTop": "10px" }}>
                        <Alert severity="warning" variant="outlined">Products might take sometime to sync. Please refresh the page.</Alert>
                      </div>
                      {
                        Boolean(anchorEl) &&
                        <BasicPopover open={Boolean(anchorEl)} id={selectedProductIndex} anchorEl={anchorEl}
                          handleCancel={() => this.setState({ anchorEl: null, selectedProductIndex: -1 })}
                          handleSave={() => {
                            this.deleteActionBtnHandler(
                              selectedProductIndex,
                              products[selectedProductIndex].id,
                              products[selectedProductIndex].objectID
                            );
                            this.setState({ anchorEl: null, selectedProductIndex: -1 });
                          }}
                          text={"Are you sure you want to delete the item?"}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        />
                      }
                      {
                        <Box sx={{ width: '100%' }}>
                          <Paper sx={{ width: '100%' }}>
                            {
                              !!numSelected &&
                              <EnhancedProductTableToolbar
                                numSelected={numSelected}
                                deleteHandler={(ev) => this.setState({ anchorE2: ev.currentTarget })}
                              />
                            }
                            <TableContainer sx={{ maxHeight: 440 }}>
                              <Table aria-label="sticky table" size="small" stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        indeterminate={numSelected > 0 && numSelected < products.length}
                                        checked={products.length > 0 && numSelected === products.length}
                                        onChange={(event) => this.handleSelectAllClick(event, products)}
                                        inputProps={{
                                          'aria-label': 'select all desserts',
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      key={"index"}
                                      align={"left"}
                                      style={{ backgroundColor: "#f1f1f1" }}
                                    >
                                      {"Sl.No"}
                                    </TableCell>
                                    <TableCell
                                      key={"id"}
                                      align={"left"}
                                      style={{ backgroundColor: "#f1f1f1" }}
                                    >
                                      {"Pid"}
                                    </TableCell>
                                    <TableCell
                                      key={"captionImageURL"}
                                      align={"left"}
                                      style={{ backgroundColor: "#f1f1f1" }}
                                    >
                                      {"Image"}
                                    </TableCell>
                                    <TableCell
                                      key={"en_title"}
                                      align={"left"}
                                      style={{ width: "600px", backgroundColor: "#f1f1f1" }}
                                    >
                                      {"Title"}
                                    </TableCell>
                                    <TableCell
                                      key={"currentPrice"}
                                      align={"left"}
                                      style={{ backgroundColor: "#f1f1f1" }}
                                    >
                                      {"Price"}
                                    </TableCell>
                                    <TableCell
                                      key={"discountPrice"}
                                      align={"left"}
                                      style={{ width: "120px", backgroundColor: "#f1f1f1" }}
                                    >
                                      {"Discounted Price"}
                                    </TableCell>
                                    <TableCell
                                      key={"actions"}
                                      align={"left"}
                                      style={{ backgroundColor: "#f1f1f1" }}
                                    >
                                      {"Actions"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>

                                  {products && products.map((item, index) => {
                                    const isItemSelected = selectedProducts[item.id] || false;
                                    const labelId = `enhanced-table-checkbox-${item.id}`;
                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        selected={isItemSelected}
                                        style={{ "maxHeight": "15px" }}>
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                              'aria-labelledby': labelId,
                                            }}
                                            onChange={() => this.handleClickProduct(item.id)}
                                          />
                                        </TableCell>
                                        <TableCell
                                          key={"index"}
                                          align={"left"}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          key={"id"}
                                          align={"left"}
                                        >
                                          {item.id}
                                        </TableCell>
                                        <TableCell
                                          key={"captionImageURL"}
                                          align={"left"}
                                        >
                                          <object
                                            className="flash-sale-img"
                                            data={`${ENV_CONFIG.productImageBaseUrl}${item.captionImageURL}`}
                                            type="image/jpg"
                                          >
                                            <img
                                              className="flash-sale-img"
                                              src={InvalidImage}
                                              alt="Image not found"
                                            />
                                          </object>
                                        </TableCell>
                                        <TableCell
                                          key={"en_title"}
                                          align={"left"}
                                        >
                                          {item.en_title}
                                        </TableCell>
                                        <TableCell
                                          key={"currentPrice"}
                                          align={"left"}
                                        >
                                          {item.currentPrice || 0}
                                        </TableCell>
                                        <TableCell
                                          key={"discountPrice"}
                                          align={"left"}
                                        >
                                          <TextField
                                            name="discountPrice"
                                            type="Number"
                                            value={
                                              item.discountPrice ===
                                                null ||
                                                item.discountPrice ===
                                                undefined
                                                ? item.currentPrice || 0
                                                : item.discountPrice === 0
                                                  ? ""
                                                  : item.discountPrice
                                            }
                                            onChange={e =>
                                              this.discountPriceHandler(
                                                e.target.name,
                                                e.target.value,
                                                item.id,
                                                index,
                                                item.currentPrice
                                              )
                                            }
                                            size="small"
                                            variant="standard"
                                          />
                                        </TableCell>
                                        <TableCell key={"actions"} align={"left"}>
                                          <Tooltip title="Delete" placement="top" arrow>
                                            <IconButton
                                              aria-label="delete"
                                              onClick={(ev) => this.setState({ anchorEl: ev.currentTarget, selectedProductIndex: index })}
                                              disabled={Object.values(selectedProducts).some((val) => val === true)}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  }
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Paper>
                        </Box>
                      }
                      {
                        (!tabContent.productsCount && !(tabContent.products?.length) && !showCustomLoader) &&
                        <h3 style={{ "textAlign": "center" }}>No Products Found</h3>
                      }
                      <CustomLoadingIndicator
                        showLoader={showCustomLoader}
                        countdown={10}
                        id={tabContent._id}
                      />
                    </div >
                  </div >
                </div >
              </div >
            </div >
          </div >
        </div >
      </div >
    );
  }
}

const mapStateToProps = state => ({
  selectedSaleContentIndex: state.flashSaleReducer.selectedSaleContentIndex,
  showCustomLoader: state.common.showCustomLoader
});

const mapDispatchToProps = dispatch => ({
  snackbar: bindActionCreators(snackbar, dispatch),
  setSelectedSaleContentIndex: bindActionCreators(
    setSelectedSaleContentIndex,
    dispatch
  ),
  updatePageContent: bindActionCreators(updatePageContent, dispatch),
  fetchProductDetailsByCountryId: bindActionCreators(
    fetchProductDetailsByCountryId,
    dispatch
  ),
  fetchProductListOfSales: bindActionCreators(
    fetchProductListOfSales,
    dispatch
  ),
  deleteProductFromSale: bindActionCreators(deleteProductFromSale, dispatch),
  deleteAllProductsFromSale: bindActionCreators(deleteAllProductsFromSale, dispatch),
  multiDeleteProductsFromSale: bindActionCreators(multiDeleteProductsFromSale, dispatch),
  deleteProducts: bindActionCreators(deleteProducts, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePageContent);
