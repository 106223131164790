import React from "react";
import { ChromePicker } from "react-color";

export const ColorPicker = ({
  onClick,
  background,
  onChange,
  displayPicker
}) => {
  return (
    <div className="pickerWrapper">
      <div className="picker-swatch">
        <div
          onClick={onClick}
          className="color"
          style={{
            background
          }}
        />
      </div>
      {displayPicker && (
        <div className="chromePicker">
          <div className="cover" onClick={onClick} />
          <ChromePicker disableAlpha color={background} onChange={onChange} />
        </div>
      )}
    </div>
  );
};
