import React, { Fragment, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import cn from "classnames";
import Popup from "reactjs-popup";
import Accordion from "../accordion";
import {
  cloneModule,
  moduleDataChange
} from "../../actions/dynamicBanner.action";
import CustomModal from "../../containers/modal/ConfirmModal";
import { toastMsg } from "../../actions/common.action";
import { deviceTypes, MESSAGES } from "../../constants";
import { CloneCountryPopupContent } from "../../containers/pagesPage/popupContent";
import { cloneDynamicModulePage } from "../../actions/pagesPage.action";
export const DynamicBannerAccordion = ({
  handleDeleteSection,
  handleOpen,
  handleToggle,
  sliderId = "dynamic_banner",
  isEnabled,
  isOpen,
  content,
  children,
  accordionTitle,
  keyIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  sectionTitle,
  accessRights
}) => {
  const dispatch = useDispatch();
  const popupRef = useRef(null);
  const [deleteBannerModalVisible, setDeleteBannerModalVisible] = useState(
    false
  );
  const [cloneBannerModalVisible, setCloneBannerModalVisible] = useState(false);
  const countryList = useSelector(store => store.store.countryList);

  const handleModuleDataChange = (name, value) =>
    dispatch(
      moduleDataChange({
        path: [keyIndex],
        name,
        value,
        pageType,
        updateCatalogPageLocation,
        updateCatalogPageType
      })
    );

  const handleModuleClone = () => {
    dispatch(
      cloneModule({
        path: [keyIndex],
        pageType,
        updateCatalogPageLocation,
        updateCatalogPageType
      })
    );
    dispatch(toastMsg("success", "Cloned successfully"));
  };

  const handleDeleteBanner = () => setDeleteBannerModalVisible(true);
  const handleShowCloneBanner = () => setCloneBannerModalVisible(true);

  const schedulerData = useMemo(() => {
    const newSchedulerData = Object.keys(deviceTypes).map(device => ({
      parent:
        content && content[device] && content[device].schedulers
          ? content[device].schedulers.map(parentScheduler => {
            return parentScheduler;
          })
          : [],

      child:
        content[device] &&
          content[device].rows &&
          content[device].rows.length &&
          content[device].rows.find(
            childScheduler =>
              childScheduler.schedulers && childScheduler.schedulers.length
          )
          ? content[device].rows.map(childScheduler => {
            return childScheduler;
          })
          : []
    }));

    return !!newSchedulerData.find(scheduler => {
      return scheduler.parent.length || scheduler.child.length;
    });
  }, [content]);

  const handleCloneDynamicModule = (moduleId, destinationPageId) => {
    dispatch(cloneDynamicModulePage(moduleId, destinationPageId));
  };

  const renderCloneCountrytoButton = () => (
    <Popup
      ref={popupRef}
      trigger={
        <span
          className="clone-btn-country"
          data-tip={accessRights.UPDATE ? "Copy banner to other country" : MESSAGES.TOOLTIP_UPDATE}
          style={{ opacity: accessRights.UPDATE ? "1" : "0.3" }}
          data-for={accordionTitle}
        />
      }
      position="top center"
      closeOnDocumentClick={false}
      style={{ width: "300px" }}
      disabled={!accessRights.UPDATE}
    >
      <CloneCountryPopupContent
        onConfirm={(moduleId, destinationPageId) => {
          handleCloneDynamicModule(moduleId, destinationPageId);
          popupRef.current.close();
        }}
        onCancel={() => popupRef.current.close()}
        modules={content ? content : []}
        countryList={countryList}
      />
    </Popup>
  );
  return (
    <Fragment>
      {deleteBannerModalVisible && (
        <CustomModal
          modalName="row-confirmation-modal"
          onHide={() => setDeleteBannerModalVisible(false)}
          confirm={() => {
            handleDeleteSection();
            setDeleteBannerModalVisible(false);
          }}
          message={`Are you sure you want to delete ${accordionTitle}? You can always undo your changes before saving`}
        />
      )}
      {cloneBannerModalVisible && (
        <CustomModal
          modalName="row-confirmation-modal"
          onHide={() => setCloneBannerModalVisible(false)}
          confirm={() => {
            handleModuleClone();
            setCloneBannerModalVisible(false);
          }}
          message={`Are you sure you want to clone ${accordionTitle}? You can always undo your changes before saving`}
        />
      )}
      <Accordion
        sectionTitle={sectionTitle}
        pageType={pageType}
        accordionId={sliderId}
        title={accordionTitle}
        headingWrapperClassName="dynamic-module-heading"
        handleDelete={handleDeleteBanner}
        handleToggle={handleToggle}
        handleOpen={handleOpen}
        isToggled={isEnabled}
        isOpen={isOpen}
        isControlled={Boolean(handleOpen)}
        required
        className={cn({
          "banner-data-wrapper": get(content, "desktop.rows", []).some(
            item => item.bannerType === "Custom"
          )
        })}
        content={content}
        background={content.backgroundColor || "#3f3f3f"}
        changeModuleBackground={value =>
          handleModuleDataChange("backgroundColor", value.hex)
        }
        keyIndex={keyIndex}
        withDragNDrop
        withTitleEditing
        handleModuleTitleChange={value =>
          handleModuleDataChange("title", value)
        }
        clone={handleShowCloneBanner}
        schedulersList={content}
        schedulerData={schedulerData}
        renderCloneCountrytoButton={renderCloneCountrytoButton}
      >
        {children}
      </Accordion>
    </Fragment>
  );
};
