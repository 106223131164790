import React from "react";
import GateKeeper from "./containers/gatekeeper";
import "./style/index.scss";
import "./materialUi/style/index.scss";
import { ThemeProvider, theme } from "./materialUi/themes/index"

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div id="app">
        <GateKeeper />
      </div>
    </ThemeProvider>
  );
};

export default App;
