export const UPDATE_SIZE_CHART_DATA = "UPDATE_SIZE_CHART_DATA";
export const RESET_SIZE_CHART_CONTENT = "RESET_SIZE_CHART_CONTENT";
export const ENABLE_SIZE_CHART_SUBMIT_BUTTON =
  "ENABLE_SIZE_CHART_SUBMIT_BUTTON";
export const DISABLE_SIZE_CHART_SUBMIT_BUTTON =
  "DISABLE_SIZE_CHART_SUBMIT_BUTTON";
export const TOGGLE_SIZE_CHART_RESET_BUTTON = "TOGGLE_SIZE_CHART_RESET_BUTTON";
export const HANDLE_SIZE_CHART_META_TEXT_CHANGE =
  "HANDLE_SIZE_CHART_META_TEXT_CHANGE";
export const HANDLE_SIZE_CHART_TOGGLE_CHANGE =
  "HANDLE_SIZE_CHART_TOGGLE_CHANGE";
export const HTML_SIZE_CHART_CONTENT_TEXT = "HTML_SIZE_CHART_CONTENT_TEXT";
export const HANDLE_META_SIZE_CHART_TEXT_CHANGE =
  "HANDLE_META_SIZE_CHART_TEXT_CHANGE";
export const CHANGE_SIZE_CHART_CATEGORY = "CHANGE_SIZE_CHART_CATEGORY";
export const CHANGE_SIZE_CHART_NAV = "CHANGE_SIZE_CHART_NAV";
export const CHANGE_SELECTED_BRAND = "CHANGE_SELECTED_BRAND";
export const CHANGE_SELECTED_SUB_CATEGORY = "CHANGE_SELECTED_SUB_CATEGORY";
export const CHANGE_SELECTED_GENERIC_CATEGORY =
  "CHANGE_SELECTED_GENERIC_CATEGORY";
export const FETCH_BRANDS_LIST = "FETCH_BRANDS_LIST";
export const FETCH_SUB_CATEGORY_LIST = "FETCH_SUB_CATEGORY_LIST";
export const HANDLE_SIZE_CHART_INPUT_TEXT_CHANGE =
  "HANDLE_SIZE_CHART_INPUT_TEXT_CHANGE";
export const UPDATE_BRANDS_CONTENT = "UPDATE_BRANDS_CONTENT";
export const SET_BRAND_TEXT_VALUE = "SET_BRAND_TEXT_VALUE";
export const CHANGE_CK_EDITOR_TEXT = "CHANGE_CK_EDITOR_TEXT";
export const UPDATE_SUB_CATEGORY_DATA = "UPDATE_SUB_CATEGORY_DATA";
export const CLEAR_SUB_CATEGORY_DATA = "CLEAR_SUB_CATEGORY_DATA";
export const HANDLE_GENERAL_CHANGE = "HANDLE_GENERAL_CHANGE";
export const HANDLE_GENERIC_SIZE_CHART_INPUT_TEXT_CHANGE =
  "HANDLE_GENERIC_SIZE_CHART_INPUT_TEXT_CHANGE";
export const HANDLE_GENERIC_SIZE_CHART_CHANGE =
  "HANDLE_GENERIC_SIZE_CHART_CHANGE";
export const RESET_SIZE_CHART_GENERIC_CONTENT =
  "RESET_SIZE_CHART_GENERIC_CONTENT";
export const CHANGE_SELECTED_GENERIC_SUB_CATEGORY =
  "CHANGE_SELECTED_GENERIC_SUB_CATEGORY";
export const HANDLE_RESET = "HANDLE_RESET";
export const CLEAR_SELECTED_GENERIC_SUB_CATEGORY =
  "CLEAR_SELECTED_GENERIC_SUB_CATEGORY";
export const HANDLE_SELECTED_GENERIC_SUB_CATEGORY_INPUT_TEXT_CHANGE =
  "HANDLE_SELECTED_GENERIC_SUB_CATEGORY_INPUT_TEXT_CHANGE";
export const HANDLE_SELECTED_GENERIC_SUB_CATEGORY_CATEGORIES_CHANGE =
  "HANDLE_SELECTED_GENERIC_SUB_CATEGORY_CATEGORIES_CHANGE";
