import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastMsg } from "../../actions/common.action";
import { ERROR_MESSAGES } from "../../constants";

const customStyles = {
  content: {
    width: "390px",
    height: "395px",
    position: "absolute",
    top: "20%",
    left: "35%",
    right: "40px",
    bottom: "40px",
    borderRadius: "none",
    background: "rgb(255, 255, 255)",
    overflow: "auto"
  },

  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

class EditRoleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: (props.role && props.role.name) || "",
      slug: (props.role && props.role.slug) || "",
      description: (props.role && props.role.description) || "",
      disableSubmit: true
    };
  }

  /**
   * validate field (name, password, & email)
   */
  checkIfExists = (e, action) => {
    e.preventDefault();
    const { name = "", slug = "", description = "" } = this.state;
    const { toastMsg } = this.props;
    if (!(name && slug)) {
      toastMsg("error", "Please fill all input fields.");
    } else if (name !== name.trim()) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} Role Name`);
    } else if (description !== description.trim()) {
      toastMsg("error", `${ERROR_MESSAGES.extraSpace} Description`);
    } else {
      action(name, slug, description);
    }
  };

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const { name, slug, description } = this.state;
      const isInValid = !(name && slug);
      this.setState({
        disableSubmit: isInValid
      });
    });
  };

  render() {
    const { modalName, onHide, role, editRole } = this.props;
    const { name, slug, description, disableSubmit } = this.state;
    return (
      <div>
        <Modal
          isOpen={true}
          ariaHideApp={false}
          onRequestClose={() => onHide(modalName)}
          style={customStyles}
          closeTimeoutMS={2000}
        >
          <form
            onSubmit={e => {
              this.checkIfExists(e, editRole);
            }}
          >
            <div className="close-btn">
              <span onClick={() => onHide(modalName)} className="close-icon" />
            </div>
            <span className="modal-heading">Edit Role</span>
            <input
              id="name"
              type="text"
              name="name"
              className="form-control m-b-20 no-border-radius column-height marginTop-20"
              placeholder="Enter role name"
              value={name}
              onChange={this.changeHandler}
            />
            <input
              id="slug"
              type="text"
              name="slug"
              className="form-control m-b-20 no-border-radius column-height"
              placeholder="Slug"
              value={slug}
              disabled={true}
              onChange={this.changeHandler}
            />
            <textarea
              id="description"
              name="description"
              className="form-control m-b-20 no-border-radius column-height"
              placeholder="Description"
              value={description}
              onChange={this.changeHandler}
              style={{ height: "100px" }}
            />
            <button
              className={`confirm-btn marginTop-20 ${disableSubmit ? "btn-disabled" : ""
                }`}
              disabled={disableSubmit || false}
              onClick={e => {
                this.checkIfExists(e, editRole);
              }}
            >
              <span className="btn-text">Update Role</span>
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toastMsg: bindActionCreators(toastMsg, dispatch)
});

export default connect(null, mapDispatchToProps)(EditRoleModal);
