import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { login } from "../../actions/user.action";
import { toastMsg } from "../../actions/common.action";
import { parseQueryParams } from "../../util/storeHelper";
import Button from "../../component/button";
import { validateEmail } from "../../util/storeHelper";

const Login = ({ location }) => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    email: "",
    password: ""
  });
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    if (location.search) {
      const errorMsg = parseQueryParams(location.search);
      dispatch(toastMsg("error", errorMsg.message));
    }
  }, []);

  useEffect(() => {
    const { email, password } = formState;
    const isInValid = !(password && email);
    setDisableSubmit(isInValid);
  }, [formState]);

  const handleTextChange = e => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const checkIfExists = e => {
    e.preventDefault();
    const { email, password } = formState;
    if (!(email && password)) {
      dispatch(toastMsg("error", "Please fill all input fields."));
    } else if (!validateEmail(email)) {
      dispatch(toastMsg("error", "Invalid user email."));
    } else {
      dispatch(
        login({
          username: email,
          password: password
        })
      );
    }
  };

  return (
    <div className="login-wrapper row ">
      <div className="left-login-partition col-sm-5">
        <div className="left-container">
          <div className="left-image">
            <div className="left-content">
              <h2>
                Welcome <b>to Brands For Less</b>
              </h2>
              <p className="login-content">
                Login to your account to access the content management
                systems.You can login as an admin or super admin
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="right-login-wrapper col-sm-7">
        <div className="login-box">
          <div className="login-logo marginField">
            <b>Login</b>
          </div>
          <div className="login-box-body">
            <form
              onSubmit={e => {
                checkIfExists(e);
              }}
            >
              <div>
                <span className="email-icon" />
                <input
                  id="login-email"
                  type="text"
                  name="email"
                  className="form-control marginField no-border-radius left-padding input-height"
                  placeholder="Your Email Address"
                  value={formState.email}
                  onChange={handleTextChange}
                />
              </div>
              <div>
                <div className="position-relative">
                  <span className="pwd-icon" />
                  <input
                    type={showPass ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Password"
                    className="form-control marginField no-border-radius left-padding input-height"
                    onChange={handleTextChange}
                    value={formState.password}
                  />
                  <span
                    className="pwd-show-btn"
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? "HIDE" : "SHOW"}
                  </span>
                </div>
              </div>
              <div>
                <Button
                  customClass={classnames({
                    "btn-disabled": disableSubmit
                  })}
                  name="Sign In"
                  onClick={e => {
                    checkIfExists(e);
                  }}
                  width="100%"
                  height="41px"
                  disabled={disableSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
