import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    width: "550px",
    height: "200px",
    borderRadius: "none",
    position: "absolute",
    top: "20%",
    left: "35%",
    right: "40px",
    bottom: "40px",
    background: "rgb(255, 255, 255)",
    overflow: "auto"
  },

  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

const ConfirmModal = ({ modalName, onHide, confirm, message }) => {
  return (
    <div>
      <Modal
        isOpen={true}
        ariaHideApp={false}
        onRequestClose={() => onHide(modalName)}
        style={customStyles}
        closeTimeoutMS={2000}
      >
        <div className="close-btn">
          <span onClick={() => onHide(modalName)} className="close-icon" />
        </div>
        <span className="modal-text">{message}</span>
        <button className="action-btn marginTop-20 m-l-20" onClick={confirm}>
          <span className="btn-text">Yes</span>
        </button>
        <button
          className="n-action-btn marginTop-20 m-l-20"
          onClick={() => onHide(modalName)}
        >
          <span className="n-btn-text">No</span>
        </button>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
