import {
  SESSION_TERMINATED,
  UPDATE_STORE_LIST,
  SET_SELECTED_STORE_STATE,
  CHANGE_SELECTED_STORE_STATE,
  UPDATE_COUNTRY_ID,
  // UPDATE_SUB_CATEGORIES_DATA,
  CHANGE_BREAD_CRUMB,
  SET_BRANDS_DATA,
  COUNTRY_LIST
} from "../constants/actions";

const initialState = {
  storeList: [],
  countryId: 0,
  storeId: 2,
  selectedStoreName: "",
  selectedCountryName: "",
  subCategories: {},
  brands: []
};

const updateStoreSettings = (state, { result }) => ({
  ...state,
  storeList: result
});

const setSelectedStoreState = (state, { name, id }) => ({
  ...state,
  [name]: Number.parseInt(id)
});

const changeSelectedStoreState = (state, { storeId, countryId }) => ({
  ...state,
  storeId,
  countryId
});

const updateCountryId = (state, { value }) => ({
  ...state,
  countryId: Number.parseInt(value)
});

const changeBreadCrumb = (
  state,
  { selectedStoreName, selectedCountryName }
) => ({ ...state, selectedStoreName, selectedCountryName });

// const updateSubCategoriesData = (state, { result }) => {
//   const key = `${result.storeId}_${result.categoryId}`;
//   return { ...state, subCategories: { ...state.subCategories, [key]: result } };
// };

const setBrandsData = (state, { brands }) => {
  return {
    ...state,
    brands
  };
};

const setCountryList = (state, { countryList }) => {
  return {
    ...state,
    countryList
  };
};

const homepageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_TERMINATED:
      return state;
    case UPDATE_STORE_LIST:
      return updateStoreSettings(state, action);
    case SET_SELECTED_STORE_STATE:
      return setSelectedStoreState(state, action);
    case SET_BRANDS_DATA:
      return setBrandsData(state, action);
    case CHANGE_SELECTED_STORE_STATE:
      return changeSelectedStoreState(state, action);
    case UPDATE_COUNTRY_ID:
      return updateCountryId(state, action);
    // case UPDATE_SUB_CATEGORIES_DATA:
    //   return updateSubCategoriesData(state, action);
    case CHANGE_BREAD_CRUMB:
      return changeBreadCrumb(state, action);
    case COUNTRY_LIST:
      return setCountryList(state, action);
    default:
      return state;
  }
};

export default homepageReducer;
