import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import Toggle from "react-toggle";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import isSameDay from "date-fns/isSameDay";
import Popup from "reactjs-popup";

import { CustomSelect } from "../../component";
import ExpandableList from "../../component/ExpendableList";
import SchedulerDatePicker from "../../component/scheduler/SchedulerDatePicker";
import PopupContent from "../pagesPage/popupContent";
import {
  getMappedSchedulers,
  getSchedulerTimeString,
  validateTimeOrdering,
  getNewSchedulerSetting
} from "../../util/scheduler";

import HomeIcon from "../../images/home-icon.svg";
import ClockBlack from "../../images/clock-black.svg";
import DeleteScheduler from "../../images/dustbin.png";
import { ITEMS_COUNT_LIMIT } from "../../constants";

const customStyles = {
  content: {
    width: "770px",
    padding: "0px",
    position: "absolute",
    top: "20%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "none",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    transform: "translateX(-50%)"
  },
  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

const PageSchedulerModal = ({
  onHide,
  pageSchedulers,
  onCancel,
  onSave,
  pagesRoute,
  scheduledPages
}) => {
  const [schedulersSettings, setSchedulersSettings] = useState(
    getMappedSchedulers(pageSchedulers)
  );
  const popupRef = useRef();

  const handleDatePicker = (e, type, keyIndex, id) => {
    let timeStamp = Date.parse(e);

    if (
      type === "to" &&
      !schedulersSettings[id].to &&
      schedulersSettings[id].from
    ) {
      const fromDate = new Date(schedulersSettings[id].from);
      const fromHours = fromDate.getHours();
      const fromMinutes = fromDate.getMinutes();
      const toHours = e.getHours();
      const toMinutes = e.getMinutes();

      if (toHours === 0 && toMinutes === 0) {
        e.setHours(fromHours, fromMinutes + 1);
        timeStamp = Date.parse(e);
      }
    }

    if (!validateTimeOrdering(timeStamp, type, schedulersSettings[id])) return;

    const newSettings = {
      ...schedulersSettings,
      [id]: {
        ...schedulersSettings[id],
        [type]: timeStamp,
        saved: false
      }
    };

    setSchedulersSettings(newSettings);
  };

  const handleScheduledPageChange = ({ value }, id) => {
    setSchedulersSettings({
      ...schedulersSettings,
      [id]: {
        ...schedulersSettings[id],
        scheduledPage: value,
        saved: false
      }
    });
  };

  const handleScheduledEnabling = (checked, id) => {
    setSchedulersSettings({
      ...schedulersSettings,
      [id]: { ...schedulersSettings[id], enabled: checked }
    });
  };

  const validateScheduler = scheduler => {
    if (!schedulersSettings[scheduler.id]) return false;
    const { to, from, scheduledPage } = schedulersSettings[scheduler.id];
    return to && from && scheduledPage;
  };

  const handleSaveSchedular = id => {
    setSchedulersSettings({
      ...schedulersSettings,
      [id]: { ...schedulersSettings[id], saved: true }
    });
  };

  const getSavedSchedulers = () => filter(schedulersSettings, "saved");

  const onAddSchedulerClick = () => {
    setSchedulersSettings({
      ...schedulersSettings,
      ...getNewSchedulerSetting(schedulersSettings)
    });
  };

  useEffect(() => {
    setSchedulersSettings(getMappedSchedulers(pageSchedulers));
  }, [pageSchedulers]);

  const schedulerAmountInLimit =
    Object.keys(schedulersSettings).length < ITEMS_COUNT_LIMIT;

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      onRequestClose={() => onHide()}
      onAddSchedularClick
      style={customStyles}
      closeTimeoutMS={2000}
    >
      <div className="modal-scheduler-container">
        <div className="scheduler-header">
          <div className="page-breadcrumbs">
            <img src={HomeIcon} alt="page breadcrumbs" />
            <span className="main-breadcrumb">
              {pagesRoute.folder.title} &gt;&nbsp;
            </span>
            <span>{pagesRoute.page.title}</span>
          </div>
          <button
            className="btn scheduler-add"
            onClick={onAddSchedulerClick}
            disabled={!schedulerAmountInLimit}
          >
            <img src={ClockBlack} alt="clock" />
            <span className="btn-text">ADD SCHEDULER</span>
          </button>
        </div>
        <div className="scheduler-body">
          {!isEmpty(schedulersSettings) ? (
            Object.values(schedulersSettings).map(
              (scheduler, index, schedulersSettingsArr) => {
                if (!scheduler) return null;

                const schedulerTo = scheduler.to;
                const schedulerFrom = scheduler.from;
                const currentDate = new Date();
                const currentTime = currentDate.getTime();
                const daysAreSame = isSameDay(
                  schedulerTo || currentDate,
                  schedulerFrom || currentDate
                );
                const minTime = setHours(setMinutes(currentDate, 0), 0);
                const maxTime = setHours(setMinutes(currentDate, 59), 23);
                const isListOpened =
                  schedulersSettingsArr.length === 1 && !scheduler.saved;
                return (
                  <ExpandableList
                    key={scheduler.id}
                    opened={isListOpened}
                    header={() => (
                      <div className="header">
                        <div className="indication-wrapper">
                          <div className="expended-indicator" />
                          <span className="scheduler-name">
                            {scheduler.name}
                          </span>
                        </div>
                        <div className="activity-range-wrapper">
                          <span className="schedular-start-time">
                            <span>From: </span>
                            {getSchedulerTimeString(scheduler, "from")}
                          </span>
                          <span className="schedular-end-time">
                            <span>To: </span>
                            {getSchedulerTimeString(scheduler, "to")}
                          </span>
                        </div>
                        <div
                          className="buttons-wrapper"
                          onClick={e => e.stopPropagation()}
                        >
                          <Toggle
                            checked={scheduler.enabled}
                            onChange={e => {
                              handleScheduledEnabling(
                                e.target.checked,
                                scheduler.id
                              );
                            }}
                            icons={false}
                            className="custom-toggle"
                            disabled={!scheduler.saved}
                          />

                          <Popup
                            ref={popupRef}
                            trigger={
                              <button className="btn">
                                <img
                                  src={DeleteScheduler}
                                  alt="Delete Scheduler"
                                />
                              </button>
                            }
                            position="left center"
                          >
                            <PopupContent
                              onConfirm={() => {
                                const newSettings = { ...schedulersSettings };
                                delete newSettings[scheduler.id];
                                setSchedulersSettings(newSettings);
                                popupRef.current.close();
                              }}
                              onCancel={() => popupRef.current.close()}
                            />
                          </Popup>
                        </div>
                      </div>
                    )}
                    list={toggleContent => (
                      <ul className="scheduler-content">
                        <li>
                          <div className="schedular-calendars">
                            <SchedulerDatePicker
                              keyIndex={index}
                              handleDatePicker={handleDatePicker}
                              type="from"
                              label="From"
                              id={scheduler.id}
                              value={schedulerFrom}
                              maxDate={schedulerTo}
                              {...(schedulerTo && {
                                minTime: minTime,
                                maxTime: daysAreSame ? schedulerTo : maxTime
                              })}
                            />
                            <SchedulerDatePicker
                              keyIndex={index}
                              handleDatePicker={handleDatePicker}
                              type="to"
                              label="To"
                              id={scheduler.id}
                              value={schedulerTo}
                              minDate={schedulerFrom || currentTime}
                              maxTime={maxTime}
                              minTime={
                                daysAreSame
                                  ? schedulerFrom || currentTime
                                  : minTime
                              }
                              showTimeSelect={!!schedulerTo}
                            />
                          </div>
                          <CustomSelect
                            className="banner-control-select"
                            options={scheduledPages}
                            onChange={e =>
                              handleScheduledPageChange(e, scheduler.id)
                            }
                            value={scheduler.scheduledPage}
                            name={scheduler.id}
                            placeholder="Select Scheduled Page"
                          />
                          <button
                            className="scheduler-save"
                            onClick={() => {
                              handleSaveSchedular(scheduler.id);
                              toggleContent();
                            }}
                            disabled={!validateScheduler(scheduler)}
                          >
                            Save
                          </button>
                        </li>
                      </ul>
                    )}
                  />
                );
              }
            )
          ) : (
            <div className="no-schedulers text-center">No Schedulers</div>
          )}
        </div>
        <div className="scheduler-footer">
          <button className="btn btn-cancel" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="btn btn-save"
            onClick={() => onSave(getSavedSchedulers())}
          >
            Save & Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PageSchedulerModal;
