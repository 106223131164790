import find from "lodash/find";
import get from "lodash/get";

export const findMegaMenuItem = (content, updateContent) =>
  find(
    content,
    item =>
      get(item, "uid", "undefined1") === get(updateContent, "uid", "undefined2")
  );

export const findMegaMenuItemHeaderTitle = (content, updateContent) => {
  const megaMenuItem = findMegaMenuItem(content, updateContent);
  return get(megaMenuItem, "headerTitle");
};
