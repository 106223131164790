import React, { Fragment } from "react";
import get from "lodash/get";
import map from "lodash/map";
import upperFirst from "lodash/upperFirst";
import {
  ADD_MEGA_MENU_ITEM_TOOLTIPS,
  ADD_MEGA_MENU_ITEM_PLACEHOLDERS,
  LANGUAGE
} from "../../constants";
import Image from "../../component/Image";
import InputText from "../../component/InputText";
import infoIcon from "../../images/icon-info.svg";
import { inputDirHelper } from "../../util/input";

const AddMegaMenuItemForm = ({
  handleMegaMenuPositionChange,
  handleMegaMenuRedirectionLinkChange,
  handleMegaMenuTextChange,
  handleMegaMenuUrlChange,
  handleModal,
  isEdit,
  language,
  menuData,
  menuItemList
}) => (
  <div className={`${language}-wrapper`}>
    <h4>{upperFirst(language)} Version</h4>
    <div className={`${language}-content`}>
      <InputText
        label="Menu Item Name"
        language={language}
        name="headerTitle"
        value={get(menuData, "headerTitle", "")}
        placeholder={ADD_MEGA_MENU_ITEM_PLACEHOLDERS[language].headerTitle}
        onChange={e =>
          handleMegaMenuTextChange(e.target.name, e.target.value, language)
        }
        required
      />
      <InputText
        label="Redirection Link (must start with '/')"
        language={language}
        disabled={language === LANGUAGE.ARABIC}
        name="redirectionLink"
        className="form-control date-calender"
        value={get(menuData, "redirectionLink", "")}
        placeholder={ADD_MEGA_MENU_ITEM_PLACEHOLDERS[language].redirectionLink}
        onChange={e => {
          if (language !== LANGUAGE.ARABIC) {
            handleMegaMenuRedirectionLinkChange(e.target.name, e.target.value);
          }
        }}
        disableWhitespaces
        required
      />
      <div className="form-group marginBottom  positionToolTip">
        <label>
          <span className="required" />
          Position
        </label>
        <select
          dir={inputDirHelper(language)}
          disabled={language === LANGUAGE.ARABIC}
          className="form-control"
          name="pos"
          value={get(menuData, "pos", "")}
          onChange={e => {
            if (language !== LANGUAGE.ARABIC) {
              handleMegaMenuPositionChange(e.target.name, e.target.value);
            }
          }}
        >
          <option value="">
            {ADD_MEGA_MENU_ITEM_PLACEHOLDERS[language].selectOption}
          </option>
          {map(
            [...menuItemList, ...(isEdit ? [] : [{ uid: "newPosition" }])],
            (item, index) => (
              <option key={item.uid} value={index + 1}>
                {index + 1}
              </option>
            )
          )}
        </select>
        <span className="info img-info">
          <Image src={infoIcon} alt="info icon" />
          <p className="tooltiptext">
            {ADD_MEGA_MENU_ITEM_TOOLTIPS.POSITION_INFO}
          </p>
        </span>
      </div>
      <div className="img-preview">
        <span className="info img-info category-img-info">
          <Image src={infoIcon} alt="info icon" />
          <p className="tooltiptext">
            {ADD_MEGA_MENU_ITEM_TOOLTIPS.IMAGE_SIZE_FORMAT_INFO}
          </p>
        </span>
        <div className="form-group preview hover-icon m-r-20">
          <span className="img-title">
            <span className="required" />
            Category Image
          </span>
          {get(menuData, "imageUrl", false) ? (
            <Fragment>
              <span>
                <span className="img-overlay" />
                <span
                  className="icon-close"
                  onClick={() =>
                    handleMegaMenuUrlChange("imageUrl", "", "", language, "")
                  }
                />
              </span>
              <span
                className="hover-camera-icon"
                onClick={() => handleModal("imageSelectionModal", language)}
              />
              <span className="uploaded-image">
                <Image
                  className="img-dimension"
                  alt="No Image"
                  src={get(menuData, "imageUrl", "")}
                />
              </span>
            </Fragment>
          ) : (
            <span
              className="camera-icon"
              onClick={() => handleModal("imageSelectionModal", language)}
            />
          )}
        </div>
        <span className="info hover-img-info mobile-app-img">
          <Image src={infoIcon} alt="info icon" />
          <p className="tooltiptext">
            {ADD_MEGA_MENU_ITEM_TOOLTIPS.IMAGE_SIZE_FORMAT_INFO}
          </p>
        </span>
        <div className="form-group preview icon-hover">
          <span className="hover-img-title">
            <span className="required" />
            Mobile App Image
          </span>
          {get(menuData, "hoverImageUrl", false) ? (
            <Fragment>
              <span>
                <span className="img-overlay" />
                <span
                  className="icon-close"
                  onClick={() =>
                    handleMegaMenuUrlChange(
                      "hoverImageUrl",
                      "",
                      "",
                      language,
                      ""
                    )
                  }
                />
              </span>
              <span
                className="hover-camera-icon"
                onClick={() =>
                  handleModal("hoverImageSelectionModal", language)
                }
              />
              <span className="uploaded-image">
                <Image
                  className="img-dimension"
                  alt="No Image"
                  src={get(menuData, "hoverImageUrl", "")}
                />
              </span>
            </Fragment>
          ) : (
            <span
              className="camera-icon"
              onClick={() => handleModal("hoverImageSelectionModal", language)}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

export default AddMegaMenuItemForm;
