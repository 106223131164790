import React, { Fragment } from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import {
  LANGUAGE,
  IMAGE_SIZE_AND_TYPE_TOOLTIP,
  IMAGE_LIST_ACCORDION_PLACEHOLDERS
} from "../../constants";
import infoIcon from "../../images/icon-info.svg";
import InputText from "../../component/InputText";

const ImageListForm = ({
  handleModal,
  handleRedirectionLinkChange,
  handleTextChange,
  handleUpdateURL,
  image,
  language
}) => {
  const placeholders = IMAGE_LIST_ACCORDION_PLACEHOLDERS[language];
  return (
    <div className={`${language}-wrapper`}>
      <h4>{upperFirst(language)} Version</h4>
      <div className={`${language}-content`}>
        <InputText
          label="Image Title"
          name="title"
          language={language}
          placeholder={placeholders.title}
          value={get(image, `${language}.title`, "")}
          onChange={e =>
            handleTextChange(e.target.name, e.target.value, language)
          }
          required
        />
        <InputText
          label="Image Sub Title"
          name="subTitle"
          language={language}
          placeholder={placeholders.subTitle}
          value={get(image, `${language}.subTitle`, "")}
          onChange={e =>
            handleTextChange(e.target.name, e.target.value, language)
          }
          required
        />
        <InputText
          label="GTM Tag"
          name="GtmTag"
          language={language}
          placeholder={placeholders.GtmTag}
          value={get(image, `${language}.GtmTag`, "")}
          onChange={e =>
            handleTextChange(e.target.name, e.target.value, language)
          }
          required
        />
        <InputText
          label="Redirection Link (must start with '/')"
          name="redirectionLink"
          language={language}
          disabled={language === LANGUAGE.ARABIC}
          placeholder={placeholders.redirectionLink}
          value={get(image, `${language}.redirectionLink`, "")}
          onChange={e =>
            handleRedirectionLinkChange(e.target.name, e.target.value)
          }
          disableWhitespaces
          required
        />

        <div className="form-group preview hover-icon">
          <span className="info img-info">
            <img src={infoIcon} alt="info icon" />
            <p className="tooltiptext">
              {IMAGE_SIZE_AND_TYPE_TOOLTIP}. Image size should be 360*478.
            </p>
          </span>
          <span className="img-title">
            <span className="required" />
            Image
          </span>
          {get(image, `${language}.imageLink`, "") ? (
            <Fragment>
              <span>
                <span className="img-overlay" />
                <span
                  className="icon-close"
                  onClick={() =>
                    handleUpdateURL("imageLink", "", "", language, "")
                  }
                />
              </span>
              <span
                className="hover-camera-icon"
                onClick={() => handleModal(language)}
              />
              <span>
                <img
                  className="img-dimension"
                  src={image[language].imageLink}
                />
              </span>
            </Fragment>
          ) : (
            <span
              className="camera-icon"
              onClick={() => handleModal(language)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageListForm;
