import React, { Fragment, useState, useEffect } from "react";
import InputText from "../../component/InputText";

function UpdateModulePopupContent({ onConfirm, onCancel, selectedModule }) {
  const [data, setData] = useState({
    moduleType: selectedModule.moduleType,
    moduleName: selectedModule.moduleName,
    moduleSlug: selectedModule.moduleSlug,
    title: selectedModule.title,
    _id: selectedModule._id
  });

  const onDataChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Fragment>
        <div>
          <p>Update Module</p>
          <InputText
            placeholder="Section Name"
            name="moduleName"
            value={data.moduleName}
            onChange={onDataChange}
          />
          <InputText
            placeholder="Slug"
            name="moduleSlug"
            value={data.moduleSlug}
            onChange={onDataChange}
            disabled
          />
        </div>
        <div className="popup-buttons">
          <button className="popup-confirm" onClick={() => onConfirm(data)}>
            <span className="btn-text">Yes</span>
          </button>
          <button className="popup-cancel" onClick={onCancel}>
            <span className="n-btn-text">No</span>
          </button>
        </div>
      </Fragment>
    </div>
  );
}

export default UpdateModulePopupContent;
