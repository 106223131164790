import React from "react";
import { connect } from "react-redux";
import {
  updateBannerDefaultText,
  deleteComponent,
  handleDateChange,
  schedulerTextChange,
  handleToggle,
  addNewScheduler,
  deleteScheduler
} from "../../actions/pagesPage.action";
import { SCHEDULER_TYPE } from "../../constants";
import BannerAccordion from "../../containers/bannerAccordion";

const UpdateCatalogBanner = ({
  addNewScheduler,
  content,
  deleteScheduler,
  position,
  type,
  updateBannerDefaultText,
  deleteComponent,
  handleDateChange,
  schedulerTextChange,
  handleToggle,
  isOpen,
  handleOpen
}) => {
  const handleAddNewScheduler = () => {
    addNewScheduler(position, SCHEDULER_TYPE.BANNER);
  };

  const deleteBanner = () => {
    deleteComponent({ index: position });
  };

  const handleDeleteScheduler = (_type, index) => {
    deleteScheduler(position, index);
  };

  const handleTextChange = (e, language) => {
    updateBannerDefaultText(e.target.name, e.target.value, position, language);
  };

  const handleSchedulerDateChange = (schedulerIndex, id, timeStamp) => {
    handleDateChange(id, schedulerIndex, timeStamp, position);
  };

  const handleToggleChange = (_type, keyIndex, value) => {
    handleToggle(position, keyIndex, value);
  };

  const handleSchedulerTextChange = (
    value,
    _type,
    language,
    schedulerIndex
  ) => {
    schedulerTextChange(value, position, language, schedulerIndex);
  };

  const bannerId = `pages_banner_${position}`;

  return (
    <BannerAccordion
      addNewScheduler={handleAddNewScheduler}
      bannerId={bannerId}
      content={content}
      deleteBanner={deleteBanner}
      deleteScheduler={handleDeleteScheduler}
      handleTextChange={handleTextChange}
      handleSchedulerDateChange={handleSchedulerDateChange}
      handleSchedulerTextChange={handleSchedulerTextChange}
      handleSchedulerToggleChange={handleToggleChange}
      keyIndex={position}
      type={type}
      isOpen={isOpen}
      handleOpen={handleOpen}
    />
  );
};

const mapDispatchToProps = {
  addNewScheduler,
  deleteScheduler,
  updateBannerDefaultText,
  deleteComponent,
  handleDateChange,
  schedulerTextChange,
  handleToggle
};

export default connect(null, mapDispatchToProps)(UpdateCatalogBanner);
