import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

const BasicPopover = ({ open, text, placement, id, handleCancel, handleSave, anchorEl, anchorOrigin, transformOrigin, ...props }) => {
  return (
    <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleCancel} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
      <Typography sx={{ p: 1.5 }}>
        {text}
      </Typography>
      <Divider variant="middle" />
      <Button size='small' variant="text" onClick={handleSave} style={{ "float": "left", "margin": "8px" }}>Yes</Button>
      <Button size='small' variant="text" onClick={handleCancel} style={{ "float": "right", "margin": "8px" }}>No</Button>
    </Popover>
  )
}

export default BasicPopover;