import {
  SESSION_TERMINATED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from "../constants/actions";

export const tokenGenerator = token => {
  return {
    type: LOGIN_SUCCESS,
    token
  };
};

export const terminateSession = () => {
  return {
    type: SESSION_TERMINATED
  };
};

export const logOutUser = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};
