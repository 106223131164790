import React, { useState } from "react";
import { cloneDeep } from "lodash";
import SortableTree from "react-sortable-tree";
import { IconButton, Tooltip, BasicPopover } from "../../materialUi/components"
import { EditIcon, DeleteIcon } from "../../materialUi/icons"
import get from "lodash/get";
import "react-sortable-tree/style.css";

const CustomSortableTree = ({
  treeData,
  columnName,
  updateTreeDataValue,
  openConfirmModal,
  openEditModal
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [row, setRow] = useState(null);
  return (
    <div className="custom-sortable-tree">
      {Boolean(anchorEl) &&
        <BasicPopover open={Boolean(anchorEl)} id={"column_tree"} anchorEl={anchorEl}
          handleCancel={() => {
            setAnchorEl(null);
            setRow(null)
          }}
          handleSave={() => {
            openConfirmModal(row)
            setAnchorEl(null);
            setRow(null);
          }}
          text={"Are you sure you want to delete the menu?"}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        />}
      <SortableTree
        maxDepth={4}
        treeData={treeData}
        onChange={treeData => updateTreeDataValue(cloneDeep(treeData), columnName)}
        generateNodeProps={rowInfo => {
          const nodeTitle = get(rowInfo, "node.title");
          return {
            buttons: [
              <Tooltip title={"Edit"} placement="top" arrow>
                <IconButton
                  key={`edit_${nodeTitle}`}
                  onClick={() => openEditModal(rowInfo)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>,
              <Tooltip title={"Delete"} placement="top" arrow>
                <IconButton
                  onClick={(ev) => {
                    setAnchorEl(ev.currentTarget)
                    setRow(rowInfo)
                  }}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ]
          };
        }}
      />
    </div>
  );
}

export default CustomSortableTree;
