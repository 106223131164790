import React, { useState } from "react";
import Button from "../../../component/button";
import { GreyCross } from "../../svg";
import {
    dynamicBannerItemsTypes,
} from "../../../constants";

const SliderRowType = ({
    handleAddRowItem
}) => {
    const [showAddItemTypeMenu, setShowAddItemTypeMenu] = useState(false);

    const handleShowAddItemTypeMenu = value => setShowAddItemTypeMenu(value);

    return (
        <div
            className="add-image"
            onMouseEnter={() => handleShowAddItemTypeMenu(true)}
            onMouseLeave={() => handleShowAddItemTypeMenu(false)}
        >
            {showAddItemTypeMenu ? (
                <div>
                    <Button
                        name="Slide"
                        onClick={() =>
                            handleAddRowItem(dynamicBannerItemsTypes.slide)
                        }
                    />
                    <Button
                        name="Video"
                        onClick={() =>
                            handleAddRowItem(dynamicBannerItemsTypes.video)
                        }
                    />
                </div>
            ) : (
                <GreyCross />
            )}
        </div>
    );
};

export default SliderRowType;