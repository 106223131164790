import React, { Component } from "react";
import CKEditor from "ckeditor4-react";
import {
  CK_CONFIG_ENGLISH,
  CK_CONFIG_ARABIC,
  CK_EDITOR_URL,
  STANDARD_CK_CONFIG
} from "../../constants";
import { ENV_CONFIG } from "../../config/env";
import CKEditorNotes from "./../../component/ckEditorNotes";
CKEditor.editorUrl = CK_EDITOR_URL.url;

class AccordionTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configEnglish: { language: "en" },
      configArabic: { language: "ar" }
    };
  }

  toggleContent = contentName =>
    this.setState(prevState => ({ [contentName]: !prevState[contentName] }));

  onChange = (evt, language) => {
    const { index, changeHandler } = this.props;

    let value = evt.editor.getData();
    changeHandler(index, language, "html", value);
  };

  onChangeText = (evt, language) => {
    const { index, changeHandler } = this.props;

    let value = evt.target.value;
    changeHandler(index, language, "title", value);
  };

  render() {
    const { defaultContent } = this.state;
    const { index, remove, item, storeId, countryId } = this.props;

    return (
      <div className="wrapper-box">
        <div className="heading-wrapper flex open">
          <h3>
            {(item && item.english && item.english.title) ||
              `Title ${index + 1}`}
          </h3>
          <span
            onClick={() => this.toggleContent("defaultContent")}
            className={`toggle-banner ${defaultContent ? "minus-banner" : "plus-banner"
              }`}
          />
          {index !== 0 && (
            <span className="delete-btn" onClick={() => remove(index)} />
          )}
        </div>
        {defaultContent && (
          <div className="data-wrapper">
            <div className="">
              <div className="english-content">
                <div className="form-group">
                  <label>
                    <span className="required" />
                    English Accordion Title
                  </label>
                  <input
                    name="title"
                    className="form-control date-calender"
                    type="text"
                    placeholder="Enter Title"
                    value={item.english.title}
                    onChange={e => {
                      this.onChangeText(e, "english");
                    }}
                  />
                  <label>
                    <span className="required" />
                    English Text Editor
                  </label>
                  <CKEditor
                    activeClass="p10"
                    config={{
                      ...CK_CONFIG_ENGLISH,
                      ...STANDARD_CK_CONFIG,
                      ...{
                        filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                          }/homepage/content/uploadCK?id=${storeId}&key=${"customImage"}&language=${"english"}&storeId=${storeId}&countryId=${countryId}&containerName=${"staticPage"}`
                      }
                    }}
                    data={item.english.html}
                    onChange={e => {
                      this.onChange(e, "english");
                    }}
                    onKey={e => {
                      this.onChange(e, "english");
                    }}
                  />
                  <CKEditorNotes display={[1, 2, 3, 4]} />
                  <label>
                    <span className="required" />
                    Arabic Accordion Title
                  </label>
                  <input
                    name="title"
                    className="form-control date-calender"
                    type="text"
                    dir="rtl"
                    placeholder="Enter Title"
                    value={item.arabic.title}
                    onChange={e => {
                      this.onChangeText(e, "arabic");
                    }}
                  />
                  <label>
                    <span className="required" />
                    Arabic Text Editor
                  </label>
                  <CKEditor
                    activeClass="p10"
                    config={{
                      ...CK_CONFIG_ARABIC,
                      ...STANDARD_CK_CONFIG,
                      ...{
                        filebrowserUploadUrl: `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo
                          }/homepage/content/uploadCK?id=${storeId}&key=${"customImage"}&language=${"arabic"}&storeId=${storeId}&countryId=${countryId}&containerName=${"staticPage"}`
                      }
                    }}
                    data={item.arabic.html}
                    onChange={e => {
                      this.onChange(e, "arabic");
                    }}
                    onKey={e => {
                      this.onChange(e, "arabic");
                    }}
                  />
                  <CKEditorNotes noMargin={true} display={[1, 2, 3, 4]} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AccordionTile;
