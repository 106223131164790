import React from "react";
import Modal from "react-modal";
import { getDateTime } from "../../util";
import _ from "lodash";
import cn from "classnames";
import { deviceTypes } from "../../constants";
const customStyles = {
  content: {
    width: "45%",
    maxHeight: "600px",
    minHeight: "auto",
    borderRadius: "none",
    position: "absolute",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },

  overlay: {
    zIndex: "5",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

const SchedulerModal = ({ onHide, schedulersData }) => {
  return (
    <div onClick={e => e.stopPropagation()}>
      <Modal
        isOpen={true}
        ariaHideApp={false}
        onRequestClose={onHide}
        style={customStyles}
        closeTimeoutMS={2000}
      >
        <div className="close-btn">
          <span onClick={onHide} className="close-icon" />
        </div>
        <span className="modal-text">
          <div className="scheduler-datas">
            {!_.isEmpty(schedulersData) ? (
              <div className="">
                <div className="scheduler-listing">
                  {Object.keys(deviceTypes).map((device, index) => {
                    return (
                      <div key={index} className={`${device}-schedulers`}>
                        <h3>{device}</h3>
                        <h4>Banner Schedulers</h4>
                        <ol>
                          {!_.isEmpty(schedulersData[device].schedulers) ? (
                            schedulersData[device].schedulers.map(
                              (parentScheduler, index) => (
                                <li
                                  className="wrapper-data"
                                  key={parentScheduler}
                                >
                                  <div className="wrap">
                                    <h5>
                                      {parentScheduler.title
                                        ? parentScheduler.title
                                        : `Scheduler - ${index + 1}`}
                                    </h5>
                                    <p
                                      className={cn(
                                        "active inline-block",
                                        !parentScheduler.enabled && "bg-red",
                                        parentScheduler.enabled && "bg-green"
                                      )}
                                    />
                                  </div>
                                  <h5>
                                    From: {getDateTime(parentScheduler.from)}
                                  </h5>
                                  <h5>To: {getDateTime(parentScheduler.to)}</h5>
                                </li>
                              )
                            )
                          ) : (
                            <h5 className="alignCenter">No Schedulers</h5>
                          )}
                        </ol>
                        <div>
                          <h4>Row Schedulers</h4>
                          <div className="wrapper-data">
                            {schedulersData[device].rows.length &&
                            schedulersData[device].rows.find(
                              childScheduler =>
                                childScheduler.schedulers &&
                                childScheduler.schedulers.length
                            ) ? (
                              schedulersData[device].rows.map(
                                (scheduler, index) => (
                                  <ol key={index}>
                                    <h4 className="childTitle">
                                      {scheduler.title
                                        ? scheduler.title
                                        : `Row - ${device}${index + 1}`}
                                    </h4>
                                    {scheduler.schedulers
                                      ? scheduler.schedulers.map(
                                          (childScheduler, childIndex) => (
                                            <li key={childIndex}>
                                              <div className="wrap">
                                                {/* <h5>{title}</h5> */}
                                                <h5>
                                                  {childScheduler.title
                                                    ? childScheduler.title
                                                    : `Scheduler - ${
                                                        childIndex + 1
                                                      }`}
                                                </h5>
                                                <p
                                                  className={cn(
                                                    "active inline-block",
                                                    !childScheduler.enabled &&
                                                      "bg-red",
                                                    childScheduler.enabled &&
                                                      "bg-green"
                                                  )}
                                                />
                                              </div>
                                              <h5>
                                                From:{" "}
                                                {getDateTime(
                                                  childScheduler.from
                                                )}
                                              </h5>
                                              <h5>
                                                To:{" "}
                                                {getDateTime(childScheduler.to)}
                                              </h5>
                                            </li>
                                          )
                                        )
                                      : null}
                                  </ol>
                                )
                              )
                            ) : (
                              <h5 className="alignCenter">No schedulers</h5>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </span>
      </Modal>
    </div>
  );
};

export default SchedulerModal;
