import React, { Component } from "react";
import Content from "./content";

class RightPanel extends Component {
  render() {
    const {
      currentTab,
      accessRights,
      pageContent,
      initialPageContent,
      countryId,
    } = this.props;

    return (
      <div className="right-wrapper">
        <Content
          currentTab={currentTab}
          accessRights={accessRights}
          pageContent={pageContent}
          initialPageContent={initialPageContent}
          countryId={countryId}
        />
      </div>
    );
  }
}

export default RightPanel;
