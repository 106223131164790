import { useState } from "react";

export const useOpenSingleAccordionFromList = (
  defaultState = { index: 0, show: false }
) => {
  const [openAccordionState, setOpenAccordionState] = useState(defaultState);

  const handleOpenAccordionState = index => {
    setOpenAccordionState(prevState => ({
      index,
      show: index === prevState.index ? !prevState.show : true
    }));
  };

  return [openAccordionState, handleOpenAccordionState];
};
