import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { InfoIcon, CameraAltIcon, DeleteIcon, VideoLibraryIcon, PlayArrowIcon, ContentCopyIcon } from "../../materialUi/icons";
import { Tooltip, Typography, Paper, Box } from "../../materialUi/components"
import { IMAGE_SIZE_AND_TYPE_TOOLTIP, MESSAGES } from "../../constants";
import { PreviewVideoModal } from './PreviewVideoModal';
import { snackbar } from "../../actions/common.action";


export const UploadVideo = ({
  videoURL,
  handeVideoSelectionModal,
  deleteVideo,
  language,
}) => {
  const dispatch = useDispatch();
  const [isPreviewVideoModalOpen, setIsPreviewVideoModalOpen] = useState(false);
  const handleSetPreviewVideoModalOpen = () =>
    setIsPreviewVideoModalOpen(prev => !prev);

  return (
    <>
      <PreviewVideoModal
        videoURL={videoURL}
        handleClose={handleSetPreviewVideoModalOpen}
        dialogContext={"Please Preview the below video"}
        open={isPreviewVideoModalOpen}
        title={"Video Preview"}
      />
      <div style={{ "display": "flex" }}>
        <Tooltip title={IMAGE_SIZE_AND_TYPE_TOOLTIP} placement="right" arrow>
          <InfoIcon fontSize="small" sx={{ marginTop: "10px" }} />
        </Tooltip>
        <Typography sx={{ p: 1 }}>
          Video
        </Typography>
      </div>
      <Box
        sx={{
          '& > :not(style)': {
            width: 128,
            height: 128,
            background: "#eee"
          },
        }}
      >
        <Paper elevation={3}
          className="mui-hover-icon"
        >
          {!videoURL && <VideoLibraryIcon fontSize="large" sx={{ "margin": "48px" }} onClick={handeVideoSelectionModal} />}
          {!!videoURL &&
            <>
              <span className="mui-img-overlay" />
              <PlayArrowIcon className="mui-icon-play" fontSize="medium" onClick={handleSetPreviewVideoModalOpen} />
              <Tooltip arrow title="Copy Video URL" placement="top">
                <span>
                  <CopyToClipboard
                    text={
                      videoURL.split('?')[0]
                    }
                    onCopy={() =>

                      dispatch(snackbar({ open: true, severity: "success", text: MESSAGES.URL_COPIED }))
                    }>
                    <ContentCopyIcon className="mui-icon-copy" fontSize="medium" disabled={
                      !videoURL
                    } />
                  </CopyToClipboard>
                </span>
              </Tooltip>
              <DeleteIcon className="mui-icon-close" fontSize="medium" onClick={deleteVideo} />
              <VideoLibraryIcon fontSize="large" onClick={handeVideoSelectionModal}
                className="mui-video-icon"
              />
              <PlayArrowIcon className="mui-icon-play-center" fontSize="large" />
            </>
          }
        </Paper>
      </Box>
    </>
  );
};