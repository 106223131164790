import React, { Component, Fragment } from "react";
import CountryDropDown from "../../containers/countryDropdown";
import { NEED_HELP_PANEL } from "../../constants";

class LeftPanel extends Component {
  render() {
    const { currentTab, changeTab, showContent, changeNav } = this.props;
    return (
      <div className="side-bar">
        <div className="apply-for-dropdown">
          <Fragment>
            <p>Select Country</p>
            <CountryDropDown changeTab={changeTab} />
          </Fragment>
        </div>
        <div className="nav-child">
          <Fragment>
            <span
              className={`open-nav ${
                showContent ? "minus-icon-nav" : "plus-icon-nav"
              }`}
              onClick={changeNav}
            >
              Need Help
            </span>
            {showContent && (
              <ul>
                <li
                  id={NEED_HELP_PANEL.ORDER_ISSUES}
                  className={`${
                    currentTab === NEED_HELP_PANEL.ORDER_ISSUES && "active"
                  }`}
                  onClick={e => {
                    changeTab(e, NEED_HELP_PANEL.ORDER_ISSUES);
                  }}
                >
                  Order Issues
                </li>
                <li
                  id={NEED_HELP_PANEL.LOYALTY_PROGRAM}
                  className={`${
                    currentTab === NEED_HELP_PANEL.LOYALTY_PROGRAM && "active"
                  }`}
                  onClick={e => {
                    changeTab(e, NEED_HELP_PANEL.LOYALTY_PROGRAM);
                  }}
                >
                  Loyalty Program
                </li>
                <li
                  id={NEED_HELP_PANEL.PAYMENT_AND_VOUCHERS}
                  className={`${
                    currentTab === NEED_HELP_PANEL.PAYMENT_AND_VOUCHERS &&
                    "active"
                  }`}
                  onClick={e => {
                    changeTab(e, NEED_HELP_PANEL.PAYMENT_AND_VOUCHERS);
                  }}
                >
                  Payment and Vouchers
                </li>
                <li
                  id={NEED_HELP_PANEL.TRACKING_SHIPPING_AND_DELIVERY}
                  className={`${
                    currentTab ===
                      NEED_HELP_PANEL.TRACKING_SHIPPING_AND_DELIVERY && "active"
                  }`}
                  onClick={e => {
                    changeTab(
                      e,
                      NEED_HELP_PANEL.TRACKING_SHIPPING_AND_DELIVERY
                    );
                  }}
                >
                  Tracking, Shipping and Delivery
                </li>
                <li
                  id={NEED_HELP_PANEL.PRODUCT_AND_STOCK}
                  className={`${
                    currentTab === NEED_HELP_PANEL.PRODUCT_AND_STOCK && "active"
                  }`}
                  onClick={e => {
                    changeTab(e, NEED_HELP_PANEL.PRODUCT_AND_STOCK);
                  }}
                >
                  Products and Stock
                </li>
                <li
                  id={NEED_HELP_PANEL.General}
                  className={`${
                    currentTab === NEED_HELP_PANEL.General && "active"
                  }`}
                  onClick={e => {
                    changeTab(e, NEED_HELP_PANEL.General);
                  }}
                >
                  General
                </li>
              </ul>
            )}
          </Fragment>
        </div>
      </div>
    );
  }
}

export default LeftPanel;
