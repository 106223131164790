import cloneDeep from "lodash/cloneDeep";
import {
  FLASH_SALE_V2_LIST,
  SET_ACTIVE_FLASH_SALE_V2_PAGE,
  SET_FLASH_SALE_V2_PAGE_CONTENT,
  SET_FLASH_PAGE_V2_SELECTED_SALE_INDEX,
  UPDATE_TOGGLE_FLASH_SALE_V2_DATA,
  UPDATE_FLASH_SALE_V2_PAGE_CONTENT,
  UPDATE_FLASH_SALE_V2_PAGE_NAME,
  UPDATE_FLASH_SALE_V2_PRODUCT_LIST,
  DELETE_FLASH_SALE_V2_PRODUCT,
} from "../constants/actions";

const initialState = {
  flashSaleList: [],
  activePage: {},
  flashSaleListCount: 0,
  pagesContent: {},
  initialPagesContent: {},
  selectedSaleContentIndex: 0,
};

const flashSaleList = (state, { data, count }) => {

  return {
    ...state,
    flashSaleList: data,
    flashSaleListCount: count,
    activePage: data.length ? cloneDeep(data[0]) : {},
  };
}




const setActiveFlashSalePage = (state, { data }) => ({
  ...state,
  activePage: cloneDeep(data),
});


const updateToggleData = (state, { id, data, index }) => {
  let flashSaleList = cloneDeep(state.flashSaleList) || [];
  let activePage = cloneDeep(state.activePage) || {};
  let { enabled = false } = data || {};
  if (flashSaleList[index] && flashSaleList[index]._id === id) {
    flashSaleList[index]["enabled"] = enabled;
  }
  if (activePage._id !== id) {//updated page is not active page
    return {
      ...state,
      flashSaleList,
    }
  } else {//updated page is active page
    let pagesContent = cloneDeep(state.pagesContent) || {};
    let initialPagesContent = cloneDeep(state.initialPagesContent) || {};
    activePage["enabled"] = enabled;
    pagesContent["enabled"] = enabled;
    initialPagesContent["enabled"] = enabled;
    return {
      ...state,
      flashSaleList,
      activePage,
      pagesContent,
      initialPagesContent,
    }
  }
}

const setPageContent = (state, { data }) => {
  return {
    ...state,
    pagesContent: cloneDeep(data),
    initialPagesContent: cloneDeep(data),
  }

};

const setSelectedSaleIndex = (state, { index }) => {
  return {
    ...state,
    selectedSaleContentIndex: index
  }
}

const updatePageContent = (state, { data }) => {

  return {
    ...state,
    pagesContent: cloneDeep(data),
  }

};

const updatePageName = (state, { data, index }) => {
  let flashSaleList = cloneDeep(state.flashSaleList) || [];
  let activePage = cloneDeep(state.activePage) || {};

  let { _id, name = "" } = data || {};
  if (flashSaleList[index] && flashSaleList[index]._id === _id) {
    flashSaleList[index]["name"] = name;
  }
  if (activePage._id !== _id) {//updated page is not active page
    return {
      ...state,
      flashSaleList,
    }
  } else {//updated page is active page
    let pagesContent = cloneDeep(state.pagesContent) || {};
    let initialPagesContent = cloneDeep(state.initialPagesContent) || {};
    activePage["name"] = name;
    pagesContent["name"] = name;
    initialPagesContent["name"] = name;
    return {
      ...state,
      flashSaleList,
      activePage,
      pagesContent,
      initialPagesContent,
    }
  }

}

const updateFlashSaleProductList = (state, { data, payload }) => {

  const { pageId, pageSlug, saleSlug } = payload || {};

  let pagesContent = cloneDeep(state.pagesContent) || {};
  let initialPagesContent = cloneDeep(state.initialPagesContent) || {};

  if (pagesContent._id === pageId) {
    const index = pagesContent.sales.findIndex((sale) => sale.slug === saleSlug);
    if (index > -1) {
      pagesContent.sales[index].products = [...cloneDeep(data)];
      initialPagesContent.sales[index].products = [...cloneDeep(data)];
    }
  }

  return {
    ...state,
    pagesContent,
    initialPagesContent,
  }
}


const deleteFlashSaleProducts = (state, { id = [], payload }) => {
  const { pageId, saleSlug, saleIndex, productIndex } = payload || {};

  let pagesContent = cloneDeep(state.pagesContent) || {};
  let initialPagesContent = cloneDeep(state.initialPagesContent) || {};
  const productIdMapping = {};
  id.map((id) => {
    productIdMapping[id] = id;
  });
  if (pagesContent && pagesContent._id === pageId && pagesContent.sales && pagesContent.sales[saleIndex] &&
    pagesContent.sales[saleIndex].slug === saleSlug
  ) {
    if (pagesContent.sales[saleIndex].products?.length) {
      pagesContent.sales[saleIndex].products = pagesContent.sales[saleIndex].products.filter((product, index) => {
        return product.id !== productIdMapping[product.id];
      });
      pagesContent.sales[saleIndex].productsCount = pagesContent.sales[saleIndex].products.length;
    }
    if (initialPagesContent.sales[saleIndex].products?.length) {
      initialPagesContent.sales[saleIndex].products = initialPagesContent.sales[saleIndex].products.filter((product, index) => {
        return product.id !== productIdMapping[product.id];
      });
      initialPagesContent.sales[saleIndex].productsCount = initialPagesContent.sales[saleIndex].products.length;
    }
  }

  return {
    ...state,
    pagesContent,
    initialPagesContent,
  }

}


const flashSaleV2Reducer = (state = initialState, action) => {
  switch (action.type) {
    case FLASH_SALE_V2_LIST:
      return flashSaleList(state, action);
    case SET_ACTIVE_FLASH_SALE_V2_PAGE:
      return setActiveFlashSalePage(state, action);
    case UPDATE_TOGGLE_FLASH_SALE_V2_DATA:
      return updateToggleData(state, action);
    case SET_FLASH_SALE_V2_PAGE_CONTENT:
      return setPageContent(state, action);
    case SET_FLASH_PAGE_V2_SELECTED_SALE_INDEX:
      return setSelectedSaleIndex(state, action);
    case UPDATE_FLASH_SALE_V2_PAGE_CONTENT:
      return updatePageContent(state, action);
    case UPDATE_FLASH_SALE_V2_PAGE_NAME:
      return updatePageName(state, action);
    case UPDATE_FLASH_SALE_V2_PRODUCT_LIST:
      return updateFlashSaleProductList(state, action);
    case DELETE_FLASH_SALE_V2_PRODUCT:
      return deleteFlashSaleProducts(state, action);
    default:
      return state;
  }
};

export default flashSaleV2Reducer;
