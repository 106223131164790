export const DELETE_LIST_ITEM = "DELETE_LIST_ITEM";
export const DELETE_ROW_IMAGE = "DELETE_ROW_IMAGE";
export const TOGGLE_LIST_ITEM = "TOGGLE_LIST_ITEM";
export const UPDATE_LIST_DATA = "UPDATE_LIST_DATA";
export const UPDATE_BANNER_SECTION_DATA = "UPDATE_BANNER_SECTION_DATA";
export const UPDATE_BANNER_SECTION_DATA_STYLES =
  "UPDATE_BANNER_SECTION_DATA_STYLES";
export const PUT_BANNER_DATA_STYLES = "PUT_BANNER_DATA_STYLES";
export const CHANGE_BANNER_IMAGE_HOVER_STYLE =
  "CHANGE_BANNER_IMAGE_HOVER_STYLE";
export const GENERATE_IMAGES = "GENERATE_IMAGES";
export const GENERATE_BANNER_ROW_IMAGES = "GENERATE_BANNER_ROW_IMAGES";
export const ADD_BANNER_ROW_ITEM = "ADD_BANNER_ROW_ITEM";
export const ADD_BANNER_SLIDER_ROW_IMAGES = "ADD_BANNER_SLIDER_ROW_IMAGES";
export const REORDER_BANNER_ROW_IMAGES = "REORDER_BANNER_ROW_IMAGES";
export const UPDATE_BANNER_ROW_IMAGE_DATA = "UPDATE_BANNER_ROW_IMAGE_DATA";
export const CLEAR_BANNER_ROW_IMAGE_DATA = "CLEAR_BANNER_ROW_IMAGE_DATA";
export const CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA =
  "CLEAR_BANNER_SCHEDULER_ROW_IMAGE_DATA";
export const ADD_BANNER_SCHEDULER = "ADD_BANNER_SCHEDULER";
export const HANDLE_DELETE_BANNER_SCHEDULER = "HANDLE_DELETE_BANNER_SCHEDULER";
export const HANDLE_DATA_CHANGE_BANNER_SCHEDULER =
  "HANDLE_DATA_CHANGE_BANNER_SCHEDULER";
export const HANDLE_ROW_SCHEDULER_TOGGLE = "HANDLE_ROW_SCHEDULER_TOGGLE";
export const GENERATE_BANNER_SCHEDULER_IMAGES =
  "GENERATE_BANNER_SCHEDULER_IMAGES";
export const UPDATE_SCHEDULER_BANNER_ROW_IMAGE_DATA =
  "UPDATE_SCHEDULER_BANNER_ROW_IMAGE_DATA";
export const COUNTDOWN_DATA_CHANGE = "COUNTDOWN_DATA_CHANGE";
export const MODULE_DATA_CHANGE = "MODULE_DATA_CHANGE";
export const ITEMS_PER_GROUP_CHANGE = "ITEMS_PER_GROUP_CHANGE";
export const PRODUCT_CAROUSEL_DATA_CHANGE = "PRODUCT_CAROUSEL_DATA_CHANGE";
export const ADD_SCHEDULER_DEVICE_LEVEL = "ADD_SCHEDULER_DEVICE_LEVEL";
export const DELETE_SCHEDULER_DEVICE_LEVEL = "DELETE_SCHEDULER_DEVICE_LEVEL";
export const TOGGLE_SCHEDULER_DEVICE_LEVEL = "TOGGLE_SCHEDULER_DEVICE_LEVEL";
export const SCHEDULER_DATE_CHANGE_DEVICE_LEVEL =
  "SCHEDULER_DATE_CHANGE_DEVICE_LEVEL";
export const SCHEDULER_DATA_CHANGE_DEVICE_LEVEL =
  "SCHEDULER_DATA_CHANGE_DEVICE_LEVEL";
export const COPY_CONTENT_TO_SCHEDULER = "COPY_CONTENT_TO_SCHEDULER";
export const COPY_PARENT_CONTENT_TO_SCHEDULER =
  "COPY_PARENT_CONTENT_TO_SCHEDULER";
export const CLONE_MODULE = "CLONE_MODULE";
export const CLONE_ROW = "CLONE_ROW";
export const CLONE_SCHEDULER = "CLONE_SCHEDULER";
export const CLONE_BANNER_SCHEDULER = "CLONE_BANNER_SCHEDULER";
export const CLONE_MOBILE = "CLONE_MOBILE";
export const CHANGE_DEVICE_TAB = "CHANGE_DEVICE_TAB";
export const CHANGE_PLATFORM_TAB = "CHANGE_PLATFORM_TAB";
export const UPDATE_ROW_TYPE_CUSTOM_DATA = "UPDATE_ROW_TYPE_CUSTOM_DATA";
export const ADD_ROW_TYPE_CUSTOM_FIELD = "ADD_ROW_TYPE_CUSTOM_FIELD";
export const UPDATE_ROW_TYPE_CUSTOM_MODULE = "UPDATE_ROW_TYPE_CUSTOM_MODULE";
export const REMOVE_ROW_TYPE_CUSTOM_FIELD = "REMOVE_ROW_TYPE_CUSTOM_FIELD";
export const APPLY_GENERAL_SPACING = "APPLY_GENERAL_SPACING";
export const ADD_ROW_TYPE_ACCORDION_FIELD = "ADD_ROW_TYPE_ACCORDION_FIELD";
export const UPDATE_ROW_TYPE_ACCORDION_DATA = "UPDATE_ROW_TYPE_ACCORDION_DATA";
export const UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA = "UPDATE_ROW_TYPE_ACCORDION_HEADER_DATA";
export const REMOVE_ROW_TYPE_ACCORDION_FIELD = "REMOVE_ROW_TYPE_ACCORDION_FIELD";
export const ADD_ROW_TYPE_BULLET_FIELD = "ADD_ROW_TYPE_BULLET_FIELD";
export const UPDATE_ROW_TYPE_BULLET_DATA = "UPDATE_ROW_TYPE_BULLET_DATA";
export const UPDATE_ROW_TYPE_BULLET_HEADER_DATA = "UPDATE_ROW_TYPE_BULLET_HEADER_DATA";
export const REMOVE_ROW_TYPE_BULLET_FIELD = "REMOVE_ROW_TYPE_BULLET_FIELD";
export const ADD_ROW_TYPE_TAB_FIELD = "ADD_ROW_TYPE_TAB_FIELD";
export const UPDATE_ROW_TYPE_TAB_DATA = "UPDATE_ROW_TYPE_TAB_DATA";
export const UPDATE_ROW_TYPE_TABS = "UPDATE_ROW_TYPE_TABS";
export const REMOVE_ROW_TYPE_TAB = "REMOVE_ROW_TYPE_TAB";
export const ADD_ROW_TYPE_COLUMN_FIELD = "ADD_ROW_TYPE_COLUMN_FIELD";
export const REMOVE_ROW_TYPE_COLUMN_FIELD = "REMOVE_ROW_TYPE_COLUMN_FIELD";
export const DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD = "DRAG_AND_DROP_ROW_TYPE_COLUMN_FIELD";
export const ADD_ROW_TYPE_COLUMN_MENU = "ADD_ROW_TYPE_COLUMN_MENU";
export const UPDATE_ROW_TYPE_COLUMN_MENU = "UPDATE_ROW_TYPE_COLUMN_MENU";



