import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";
import ReactTooltip from "react-tooltip";
import { BlockPicker } from "react-color";
import { Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import cn from "classnames";
import PropTypes from "prop-types";

import { Up, Down } from "./svg";
import { Clock } from "./svg/Clock";
import { SolidPencil } from "./svg/SolidPencil";
import { SolidCheckMark } from "./svg/SolidCheckMark";
import { toastMsg } from "../actions/common.action";
import { DragPins } from "./svg/DragPins";
import paint from "../images/paint.svg";
import note from "../images/note.svg";
import { URL_PAGE, FOOTER_PAGE, SECTIONS_PAGE } from "../constants";

import SchedulerModal from "../containers/modal/schedulerModal";

const Accordion = ({
  pageType,
  schedulersList,
  schedulerData,
  accordionId,
  children,
  className,
  contentWrapperClassName,
  defaultIsOpen,
  handleDelete,
  handleOpen,
  handleToggle,
  headingWrapperClassName,
  isControlled,
  isOpen,
  isToggled,
  noDataWrapper,
  required,
  title,
  withControlButtons,
  reorderRows,
  keyIndex,
  content,
  withDragNDrop,
  // withTitleEditing,
  handleModuleTitleChange,
  addScheduler,
  disableAddScheduler,
  background,
  changeModuleBackground,
  clone,
  renderCloneRow,
  renderCloneSectionsRow,
  sectionTitle,
  renderCloneCountrytoButton
}) => {

  const TOP_SECTION = "TOP_SECTION";
  const BOTTOM_SECTION = "BOTTOM_SECTION";
  const DYNAMIC_BANNER = "Dynamic banner";
  const DYNAMICBANNERMODULE = "dynamicBannerModule";

  const dispatch = useDispatch();

  const [showContent, setShowContent] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [schedulerDetails, setSchedulerDetails] = useState(false);

  const titleRef = useRef();

  useEffect(() => {
    setShowContent(defaultIsOpen);
  }, [accordionId, defaultIsOpen]);

  const handleChangeTitle = useCallback(() => {
    if (titleRef.current.value.length >= 50)
      return dispatch(
        toastMsg(
          "error",
          `The title should be less that 50 symbols, current length: ${titleRef.current.value.length}`
        )
      );

    setIsEditingTitle(false);

    handleModuleTitleChange(titleRef.current.value);
  }, [dispatch, handleModuleTitleChange]);

  const toggleShowContent = () => setShowContent(sc => !sc);
  const open = isControlled ? isOpen : showContent;

  // useEffect(() => {
  //   ReactTooltip.rebuild();
  // }, [keyIndex]);

  const handleKeyDown = useCallback(
    e => {
      switch (e.key) {
        case "Enter":
          handleChangeTitle();
          break;
        case "Escape":
          setIsEditingTitle(false);
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line
    [handleChangeTitle]
  );

  const stopPropagation = callback => e => {
    e.stopPropagation();
    callback && callback();
  };

  //color picker
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = e => {
    e.stopPropagation();
    setDisplayColorPicker(!displayColorPicker);
  };

  const hideSchedulerModal = e => {
    e.stopPropagation();
    setSchedulerDetails(false);
  };

  const renderHeading = ({ ref, draggableProps, dragHandleProps }) => (
    <div ref={ref} {...draggableProps}>
      <div
        className={cn("heading-wrapper flex open", headingWrapperClassName)}
        style={background ? { background } : {}}
        onClick={isControlled ? handleOpen : toggleShowContent}
      >
        <span
          onClick={
            isControlled
              ? stopPropagation(handleOpen)
              : stopPropagation(toggleShowContent)
          }
          className={cn("toggle-banner", {
            "minus-banner": open,
            "plus-banner": !open
          })}
        />
        {isEditingTitle ? (
          <Fragment>
            <input
              className="module-title-input"
              //style={{ width: `${title.length}ch` }}
              style={{ width: `${title}ch` }}
              name="moduleTitle"
              placeholder="Module title"
              defaultValue={sectionTitle ? sectionTitle : title}
              ref={titleRef}
              onClick={stopPropagation()}
              onKeyDown={handleKeyDown}
            />
            <SolidCheckMark
              onClick={stopPropagation(handleChangeTitle)}
              className="cursor-pointer m-l-20 m-r-20"
            />
          </Fragment>
        ) : (
          <Fragment>
            <h3 onClick={stopPropagation()}>
              {required && <span className="required" />}
              {sectionTitle ? sectionTitle : title}

              {_.isEmpty(sectionTitle) && pageType !== FOOTER_PAGE && (
                <SolidPencil
                  onClick={() => setIsEditingTitle(prev => !prev)}
                  className="cursor-pointer m-l-20"
                  data-tip="Edit module title"
                  data-for={title}
                />
              )}
            </h3>
            {pageType !== FOOTER_PAGE && addScheduler && !disableAddScheduler && (
              <button
                className="scheduler-row-add-btn"
                onClick={e => {
                  e.stopPropagation();
                  addScheduler();
                }}
              >
                <Clock />
                Add Row scheduler
              </button>
            )}
          </Fragment>
        )}
        {![SECTIONS_PAGE, URL_PAGE, FOOTER_PAGE].includes(pageType) && handleDelete && (
          <span
            data-tip={title ? `Remove ${title}` : "Remove"}
            data-for={title}
            className="delete-btn"
            onClick={stopPropagation(handleDelete)}
          />
        )}
        {schedulerDetails && (
          <SchedulerModal
            modalName="row-confirmation-modal"
            onHide={hideSchedulerModal}
            schedulersData={schedulersList}
            title={schedulersList.title}
          />
        )}
        {![SECTIONS_PAGE, URL_PAGE, FOOTER_PAGE].includes(pageType) &&
          renderCloneCountrytoButton &&
          renderCloneCountrytoButton()}
        {schedulerData && (
          <div
            className="scheduler-options"
            onClick={e => {
              e.stopPropagation();
              setSchedulerDetails(true);
            }}
            data-tip="Scheduler Details"
            data-for={title}
          >
            <img src={note} alt="Scheduler Details" />
          </div>
        )}

        {![SECTIONS_PAGE, URL_PAGE, FOOTER_PAGE].includes(pageType) && changeModuleBackground && (
          <div className="pickWrap color-picker-btn" data-tip="Choose Color" data-for={title}>
            <div className="swatch" onClick={handleClick}>
              <img src={paint} alt="Select Color" />
            </div>
            {displayColorPicker && (
              <div onClick={stopPropagation()}>
                <div className="cover" onClick={handleClick} />
                <div className="block-picker-wrapper">
                  <BlockPicker
                    color={background}
                    triangle="hide"
                    onChange={changeModuleBackground}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {![SECTIONS_PAGE, URL_PAGE, FOOTER_PAGE].includes(pageType)
          ? clone && (
            <span
              onClick={e => {
                e.stopPropagation();
                clone();
              }}
              data-tip={title ? `Clone ${title}` : "Clone"}
              data-for={title}
              className="clone-btn"
            />
          )
          : null}
        {addScheduler &&
          !disableAddScheduler &&
          renderCloneRow &&
          renderCloneRow()}
        {handleToggle && (
          <div onClick={stopPropagation()}>
            <Toggle
              checked={isToggled}
              className="custom-toggle"
              icons={false}
              onChange={handleToggle}
            />
          </div>
        )}
        {withControlButtons && (
          <div className="order-control-btns" onClick={stopPropagation()}>
            {keyIndex > 0 && (
              <Up data-tip="Move up" data-for={title} onClick={() => reorderRows("up")} />
            )}
            <Down data-tip="Move down" data-for={title} onClick={() => reorderRows("down")} />
          </div>
        )}
        {!((pageType === SECTIONS_PAGE && title === DYNAMIC_BANNER) ||
          (pageType === URL_PAGE && content && (content.slug === TOP_SECTION || content.slug === BOTTOM_SECTION))) &&
          !(pageType === FOOTER_PAGE && content?.type === DYNAMICBANNERMODULE) &&
          handleDelete && (
            <span
              data-tip={title ? `Remove ${title}` : "Remove"}
              data-for={title}
              className="delete-btn"
              onClick={stopPropagation(handleDelete)}
            />
          )}

        {dragHandleProps && (
          <div className="drag-pins" {...dragHandleProps}>
            <DragPins />
          </div>
        )}
        <ReactTooltip id={title} place="top" effect="solid" />
        {/* <ReactTooltip place="top" effect="solid" /> */}
      </div>
    </div>
  );

  return (
    <div className={cn("wrapper-box", className)}>
      {withDragNDrop ? (
        <Draggable
          draggableId={`${content.type}_${keyIndex}`}
          index={keyIndex}
          key={`${content.type}_${keyIndex}`}
        >
          {provided =>
            renderHeading({
              ref: provided.innerRef,
              ...provided
            })
          }
        </Draggable>
      ) : (
        renderHeading({})
      )}
      {open && (
        <div
          className={cn(contentWrapperClassName, {
            "data-wrapper": !noDataWrapper
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Accordion.propTypes = {
  accordionId: PropTypes.string,
  className: PropTypes.string,
  contentWrapperClassName: PropTypes.string,
  defaultIsOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  handleDelete: PropTypes.func,
  handleOpen: PropTypes.func,
  handleToggle: PropTypes.func,
  headingWrapperClassName: PropTypes.string,
  isControlled: PropTypes.bool,
  isOpen: PropTypes.bool,
  noDataWrapper: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isToggled: PropTypes.bool
};

Accordion.defaultProps = {
  accordionId: "",
  className: undefined,
  contentWrapperClassName: "",
  defaultIsOpen: false,
  handleDelete: undefined,
  handleOpen: () => { },
  headingWrapperClassName: "",
  isControlled: false,
  isOpen: undefined,
  noDataWrapper: false,
  isToggled: false
};

export default Accordion;
