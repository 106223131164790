import { updatePermissionsData } from "../actions/permissions.action";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import {
  LIST_PERMISSIONS,
  LIST_ROLES,
  LIST_PERMISSIONS_BY_ROLE,
  UPDATE_PERMISSIONS_DATA
} from "../constants/actions";

const initialState = {
  permissionsList: [],
  roleList: []
};

const permissionsList = (state, { data }) => {
  return { ...state, permissionsList: data };
};
const roleList = (state, { data }) => {
  return { ...state, roleList: data };
};
const permissionsByRole = (state, { data }) => {
  return { ...state, permissionsByRole: data };
};

const updateChildPermissions = (permissionObj, name, value, itemId) => {
  const { _id, children = [] } = permissionObj || {};

  if (_id === itemId) {
    permissionObj["accessRights"][name] = value;
    return;
  }
  for (let i = 0; i < children.length; i++) {
    updateChildPermissions(children[i], name, value, itemId);
  }
};

const updatePermissions = (
  state,
  { name, value, menuIndex, itemId, roleId, menuId }
) => {
  const content = cloneDeep(state.permissionsByRole) || {};
  let menu =
    content[menuIndex] && content[menuIndex]["_id"] === menuId
      ? content[menuIndex]
      : null;
  if (!menu) {
    return { ...state };
  }
  if (menuId === itemId) {
    menu["accessRights"][name] = value;
  } else {
    updateChildPermissions(menu, name, value, itemId);
  }
  return {
    ...state,
    permissionsByRole: content
  };
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PERMISSIONS:
      return permissionsList(state, action);
    case LIST_ROLES:
      return roleList(state, action);
    case LIST_PERMISSIONS_BY_ROLE:
      return permissionsByRole(state, action);
    case UPDATE_PERMISSIONS_DATA:
      return updatePermissions(state, action);
    default:
      return state;
  }
};

export default permissionsReducer;
