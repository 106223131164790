import React from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import {
  CATALOG_PAGE_CAROUSEL_TYPE_B_PLACEHOLDERS,
  LANGUAGE
} from "../../constants";

const ProductCarouselForm = ({
  content,
  handleTextChange,
  handleUrlChange,
  language
}) => {
  const placeholders = CATALOG_PAGE_CAROUSEL_TYPE_B_PLACEHOLDERS[language];
  const editUrlDisabled = language === LANGUAGE.ARABIC;

  return (
    <div className={`${language}-wrapper`}>
      <h4>{upperFirst(language)} Version</h4>
      <div className={`${language}-content`}>
        <div className="form-group">
          <label>Title</label>
          <input
            className="form-control"
            name="title"
            onChange={e =>
              handleTextChange(e.target.name, e.target.value, language)
            }
            placeholder={placeholders.title}
            type="text"
            value={get(content, `${language}.title`, "")}
          />
        </div>
        <div className="form-group">
          <label>
            <span className="required" />
            Search Url (must start with '/')
          </label>
          <input
            className="form-control"
            name="searchUrl"
            onChange={e => {
              if (!editUrlDisabled) {
                handleUrlChange(e.target.name, e.target.value);
              }
            }}
            placeholder={placeholders.searchUrl}
            type="text"
            value={get(content, `${language}.searchUrl`, "")}
            disabled={editUrlDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCarouselForm;
